import React from "react";
import style from "./InputField.module.css"

const InputField = (props) => {
    const {label, register,name, type,} = props;
return(
    <div className={`${style.formInput}`} style={props.styles}>
        <label className={style.label}>{label}
        <input className={label ? style.input : style.unlabel} {...register('inputs.'+name)} type={type}>
        </input> 
        </label>
    </div>
)
}

export default InputField;