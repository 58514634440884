/* eslint-disable eqeqeq */
import style from './Freightage.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '../../../components/common/modal/Modal';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import FreightageModal from '../../../components/common/modal/FreightageModal';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select} from 'antd';

const Freightage = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Freightage ID
    const [SysFreightageId, setSysFreightageId] = useState(0);
    const [TaxNoData, setTaxNoData] = useState([{key:0,value:'非課税'}]);
    const [FreightageCode, setFreightageCode] = useState("");
    const handleChangeFreightageCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setFreightageCode(str);
    };
    const onKeyDownFreightageCode = (event) => {
        if(event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(5, event.target.value)
            setFreightageCode(deg)
            document.getElementById('input2').focus();
            if(deg != "") {
                const data_r = {
                    userID: userData['id'],
                    freightage_code: deg
                };
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstFreightageEndpoint;
                axios
                .post(url, data_r, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if(t_data.length > 0) {
                        setFreightageData(t_data[0]);
                    }else{
                        setFreightageData([]);
                    }
                })
                .catch((error) => {
                });
            }else{
                setFreightageData([]);
            }
            event.preventDefault();
        }
    };
    
    const [FreightageName, setFreightageName] = useState("");
    const handleChangeFreightageName = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setFreightageName(str);
    };
    const [FreightageNameKana, setFreightageNameKana] = useState("");
    const handleChangeFreightageNameKana = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setFreightageNameKana(str);
    };
    const [FreightageAName, setFreightageAName] = useState("");
    const handleChangeFreightageAName = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setFreightageAName(str);
    };
    const [FreightageUnit, setFreightageUnit] = useState("");
    const handleChangeFreightageUnit = (event) => {
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        setFreightageUnit(str);
    };
    const [FreightageUnitPrice, setFreightageUnitPrice] = useState("0.00");
    const handleChangeFreightageUnitPrice = (event) => {
        setFreightageUnitPrice(event.target.value);
    };
    const [FreightageFractionType, setFreightageFractionType] = useState(0);
    const handleChangeFreightageFractionType = (event) => {
        setFreightageFractionType(event);
    };
    const [TaxNo, setTaxNo] = useState(null);
    const handleChangeTaxNo = (event) => {
        setTaxNo(event);
    };

    const setFreightageData = (result) => {
        if(result['sys_freightage_id']) {
            setSysFreightageId(result['sys_freightage_id']);
            setFreightageCode(result['freightage_code']);
            setFreightageName(result['freightage_name']);
            setFreightageAName(result['freightage_a_name']);
            setFreightageNameKana(result['freightage_name_kana']);
            setFreightageUnit(result['freightage_unit']);
            setFreightageUnitPrice(result['freightage_unit_price']);
            setFreightageFractionType(result['freightage_fraction_type'] | '');
            setTaxNo(result['tax_no']);
        }else{
            setSysFreightageId(0);
            setFreightageName("");
            setFreightageAName("");
            setFreightageNameKana("");
            setFreightageUnit("");
            setFreightageUnitPrice("0.00");
            setFreightageFractionType(null);
            setTaxNo(null);
        }
    }

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_master_flag_11'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            const data = {
                userID: userData['id']
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTaxEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(true);
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        let TaxNoDataAry = [];
                        TaxNoDataAry = [
                            {value:0, label:'非課税'},
                            {value:1, label:result['tax_1']},
                            {value:2, label:result['tax_2']},
                            {value:3, label:result['tax_3']},
                            {value:4, label:result['tax_4']},
                            {value:5, label:result['tax_5']},
                            {value:6, label:result['tax_6']},
                            {value:7, label:result['tax_7']},
                            {value:8, label:result['tax_8']},
                            {value:9, label:result['tax_9']}
                        ];
                        setTaxNoData(TaxNoDataAry);
                    }
                    /*
                    let url2 = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstFreightageEndpoint;
                    axios
                    .post(url2, data, {
                        headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                        },
                    }).then((response2) => {
                        if(response2.status == 200) {
                            if(response2.data.data.length>0) {
                                let result2 = response2.data.data[0];
                                setFreightageData(result2);
                            }
                        }
                    })
                    .catch((error) => {
                    });
                    */
                }
            })
            .catch((error) => {
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }
            });
            
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysFreightageId>0) {
            data['sys_freightage_id'] = SysFreightageId;
        }

        if(FreightageCode != "") {
            data['freightage_code'] = FreightageCode;
        }else{
            toast.error("コードを入力してください。", {autoClose:3000});       // please enter code
            setOpen(false);
            return;
        }
        if(FreightageName != "") {
            data['freightage_name'] = FreightageName;
        }else{
            toast.error("名称を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(FreightageAName != "") {
            data['freightage_a_name'] = FreightageAName;
        }
        if(FreightageNameKana != "") {
            data['freightage_name_kana'] = FreightageNameKana;
        }else{
            toast.error("フリガナを入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(FreightageUnit != "") {
            data['freightage_unit'] = FreightageUnit;
        }else{
            toast.error("単位を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(FreightageUnitPrice != "") {
            data['freightage_unit_price'] = FreightageUnitPrice;
        }else{
            toast.error("単価を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        data['freightage_fraction_type'] = FreightageFractionType;
        data['tax_no'] = TaxNo;
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setMstFreightageEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data == 1) {
                    toast.success("登録致しました。", {autoClose:3000});
                }else if(response.data.data == 2){
                    toast.error("すでに登録済みです。", {autoClose:3000});
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
                    
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else{
                toast.error("登録に失敗しました。", {autoClose:3000});
            }
        });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if(SysFreightageId>0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        if(SysFreightageId>0) {
            let data = {};
            data['userID'] = userData['id'];
            data['sys_freightage_id'] = SysFreightageId;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.delMstFreightageEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    setFreightageCode("");
                    setFreightageData([]);
                }
            })
            .catch((error) => {
            });

            setFreightageCode("");
            setFreightageData([]);
        }
    }
    // ========================== starts code for search ===============================
    const [SearchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState([]);
    
    const loadModalData = (event) => {
        event.preventDefault();
        let code = FreightageCode;
        setSearchValue(code);

        if(code != ""){
            const data = {
                userID: userData['id'],
                freightage_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstFreightageEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData(response.data.data);
                setShowModal(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData([]);
            setShowModal(true);
        }
    }
    
    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type2').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('registerBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    
    const onDataKeyDown = (e, param) => {
        if(e.keyCode == 13){
            switch(param){
                case 1 :
                    document.getElementById('input1').focus();
                    break;
                case 2 :
                    document.getElementById('input2').focus();
                    break;
                case 3 :
                    document.getElementById('input3').focus();
                    break;
                case 4 :
                    document.getElementById('input4').focus();
                    break;
                case 5 :
                    document.getElementById('input5').focus();
                    break;
                case 6 :
                    document.getElementById('input6').focus();
                    break;
                case 7 :
                    document.getElementById('type1').focus();
                    break;
                    default :
            }
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('registerBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            default:
                break;
        }
    }
        // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_220px = [gstyle.cs_input, gstyle.w220px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_select_245px = [gstyle.cs_select, gstyle.w245px];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <FreightageModal show={showModal} onClose={()=>setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} onChange={setSearchValue} 
                tableData={tableData} setTableData={setTableData}
                target={setFreightageData} />
            <Modal2 title="諸掛削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>諸掛マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownFreightageCode(e)} id='input1'
                                        value={FreightageCode} onChange={handleChangeFreightageCode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalData(e)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>名称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}  
                                        id='input2' onKeyDown={(e)=>onDataKeyDown(e, 3)}
                                        value={FreightageName} onChange={handleChangeFreightageName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>フリガナ</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input3' onKeyDown={(e)=>onDataKeyDown(e, 4)}
                                        value={FreightageNameKana} onChange={handleChangeFreightageNameKana}  />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>略称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input4' onKeyDown={(e)=>onDataKeyDown(e, 5)}
                                        value={FreightageAName} onChange={handleChangeFreightageAName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>単位</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input5' onKeyDown={(e)=>onDataKeyDown(e, 6)}
                                        value={FreightageUnit} onChange={handleChangeFreightageUnit} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>単価</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_220px.join(" ")}
                                        id='input6' onKeyDown={(e)=>onDataKeyDown(e, 7)}
                                        value={FreightageUnitPrice} onChange={handleChangeFreightageUnitPrice} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>端数区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            value={FreightageFractionType}
                                            placeholder="端数区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeFreightageFractionType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={[
                                                {value:0, label:'四捨五入'},
                                                {value:1, label:'切り上げ'},
                                                {value:2, label:'切り捨て'},
                                                {value:3, label:'荷主優先'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>税No</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            value={TaxNo}
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="税Noを選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeTaxNo}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={TaxNoData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='registerBut' onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} disabled={SysFreightageId>0?false:true}>削除</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Freightage;