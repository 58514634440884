/* eslint-disable eqeqeq */
import style from './ShipperBalanceSetting.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import IconSearch from '../../../components/common/icons/icon_search.png';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import InputDatepicker from '../../../components/common/input/InputDatepicker';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select} from 'antd';

const ShipperBalanceSetting = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [DeadlineDate, setDeadlineDate] = useState("");
    const handleChangeDeadlineDate = (date) => {
        setDeadlineDate(formatDate(date));
        getShipperBalanceSettinData(formatDate(date),ShipperCode,ShipperACode);
    };
    const [SelectShipperType, setSelectShipperType] = useState('0');
    const handleChangeSelectShipperType = (event) => {
        setSelectShipperType(event);
        if(event == 0) {
            setShipperData([]);
            getShipperBalanceSettinData(DeadlineDate,'','');
            document.getElementById('shipper_wrap').style.display='none';
        }else{
            document.getElementById('shipper_wrap').style.display='block';
            gridMainTable([]);
        }
    };

    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>6) {
            str = str.substring(0, 6);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setShipperCode(str);
    };
    const [ShipperACode, setShipperACode] = useState("");
    const handleChangeShipperACode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>3) {
            str = str.substring(0, 3);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setShipperACode(str);
    };
    const [ShipperName, setShipperName] = useState("");

    const onKeyDownShipperCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            if(param == 1) {
                document.getElementById('shipper_a_code').focus();
                getShipperData(event.target.value,ShipperACode);
            }else{
                getShipperData(ShipperCode,event.target.value);
            }
            event.preventDefault();
        }
    };

    const getShipperData = (code, acode) => {
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result);
                        getShipperBalanceSettinData(DeadlineDate,result['shipper_code'],result['shipper_a_code']);
                    }else{
                        setShipperData([]);
                        gridMainTable([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([]);
            gridMainTable([]);
        }
    }

    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let data = [];
        for(var i=0; i<result.length; i++) {
            let row = {};
            row = result[i];
            if(row['shipper_balance'] ==null ) {
                row['shipper_balance'] = "";
            }
            data[i] = row;
        }
        setMainTableData(data);
    }
    const formatDate = (date) => {
        let year = date.getFullYear();
        let month = '' + (date.getMonth()*1 + 1);
        let day = '' + date.getDate();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return  year + '-' + month + '-' + day;
    }
    const handlChangeData = (event, control_type, col , index) => {
        let value = '';
        value = event.target.value;
        var regexp = /^(\d{0,11})?$/;
        if(value != "" ) {
            if(!regexp.test(value)) {
                toast.error("整数 11 桁 の数字を入力する必要があります。", {autoClose:3000}); 
                value = value.substring(0, value.length-1);
            }
        }
        
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row[col] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }
 
    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_start_setting_flag_3'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            document.getElementById('shipper_wrap').style.display='none';
            getShipperBalanceSettinData('','','');
        }
	});

    const getShipperBalanceSettinData = (date, code, acode) => {
        csloading(true);
        let data = {};
        data['userID'] = userData['id'];
        if(date != "") {
            data['deadline_date'] = date;
        }else{
            data['deadline_date'] = formatDate(new Date());
        }
        if(SelectShipperType==1 && code != ""){
            data['shipper_code'] = code;
            if(acode != "") {
                data['shipper_a_code'] = acode;
            }
        }
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getShipperBalanceSettingsEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        }).then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data.length>0) {
                    let result = response.data.data;
                    gridMainTable(result);
                }else{
                    gridMainTable([]);
                }
            }else{
                gridMainTable([]);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }
        });
    }

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];

        let validate_flg = true;
        let vmsg = "";

        let shipperSaveData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            if(DeadlineDate != "") {
                row['deadline_date'] = DeadlineDate;
            }else{
                row['deadline_date'] = formatDate(new Date());
            }
            row['shipper_code'] = mainTableData[i]['shipper_code'];
            row['shipper_a_code'] = mainTableData[i]['shipper_a_code'];

            if(mainTableData[i]['shipper_balance'] != null && mainTableData[i]['shipper_balance'] != "") {
                row['shipper_balance'] = mainTableData[i]['shipper_balance'];
            }else{
                vmsg = "請求残高を入力してください。";       
                validate_flg = false;
                break;
            }

            shipperSaveData[i] = row;
        }
        
        if(validate_flg){
            data['data'] = shipperSaveData;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setShipperBalanceSettingsEndpoint;
            csloading(true);
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data == 1) {
                        toast.success("登録致しました。", {autoClose:3000});
                    }else if(response.data.data == 2){
                        toast.error("すでに登録済みです。", {autoClose:3000});
                    }else{
                        toast.error("登録に失敗しました。", {autoClose:3000});
                    }
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
            });
        }else{
            toast.error(vmsg, {autoClose:3000});  
        }
        setOpen(false);
    }
    // ========================== starts code for search ===============================
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event) => {
        event.preventDefault();

        setShipperSearchValue(ShipperCode);
        setShipperSearchValue2("");

        if(ShipperCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    const setShipperData = (data) => {
        if(data['shipper_code'] != null) {
            setShipperCode(data['shipper_code']);
            setShipperACode(data['shipper_a_code']);
            setShipperName(data['shipper_name']);
        }else{
            setShipperCode("");
            setShipperACode("");
            setShipperName("");
        }
    }

    const [type1Open, setType1Open] = useState(false);

    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onDataKeyDown = (e, id) =>{
        if(e.keyCode == 13){
            if(document.getElementById('shipper_code')){
                document.getElementById('shipper_code').focus();
            }
        }
    }
        
    const onSelectData = (e, index)=>{
        
        document.getElementById('registerBut').focus();
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            default:
            break;
        }
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];
    const style_select_245px = [gstyle.cs_select, gstyle.w245px];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_1 = [style.cs_th, gstyle.cs_col_1];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_1 = [style.cs_td, gstyle.cs_col_1];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>荷主残高設定処理</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 締切年月日, 荷主指定 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>締切年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={DeadlineDate} onChange={handleChangeDeadlineDate} focusId='input1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主指定</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                        value={SelectShipperType}
                                        open={type1Open}
                                        onSelect={(e)=>onSelectData(e, 1)}
                                        placeholder="荷主指定を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeSelectShipperType}
                                        onKeyDown={(e)=>onDataKeyDown(e, 2)}
                                        onFocus={(e) => onFocusType1(e, 1)}
                                        onBlur={(e) => onFocusType1(e, 2)}
                                        filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop:14, width:'100%'}}
                                        id="input1"
                                        options={[
                                            {value:"0", label:'自動表示'},
                                            {value:"1", label:'個別指定'},
                                        ]}
                                    />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")} id="shipper_wrap">
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        disabled={SelectShipperType==0?true:false}
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1)}
                                        value={ShipperCode} onChange={handleChangeShipperCode} id='shipper_code' />
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        disabled={SelectShipperType==0?true:false} id='shipper_a_code'
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2)}
                                        value={ShipperACode} onChange={handleChangeShipperACode} />
                                        <input type={'text'} className={gstyle.cs_input} disabled id="ShipperName"
                                        value={ShipperName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* Table */}
                    <div className={gstyle.hyfiled}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_1.join(" ")}>荷主コード</div>
                                    <div className={style_th_col_1.join(" ")}>補助コード</div>
                                    <div className={style.cs_th}>荷主名称</div>
                                    <div className={style_th_col_2.join(" ")}>請求残高</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['shipper_code']} 
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['shipper_a_code']}
                                        </div>
                                        <div className={style.cs_td} style={{textAlign:'left'}}>
                                            {data['shipper_name']}
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'number'} className={style.cs_tbl_input} 
                                                value={(data['shipper_balance'] != null)?data['shipper_balance']:''} 
                                                onChange={(e) => handlChangeData(e,1,'shipper_balance',index)} />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id="registerBut" onClick={handleOpen} >登録する</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default ShipperBalanceSetting;