/* eslint-disable eqeqeq */
import React, {useState, useEffect} from 'react';
import axios from "axios";
import style from './Modal.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import Modal from './Modal';
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { toast } from 'react-toastify';
import { Button } from 'antd';

const ItemTypeShipperModal = props => {

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
    });

    const userData = JSON.parse(useJwt.getUserData());

    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_select_40 = [gstyle.cs_select, gstyle.w40];
    const style_input_50 = [gstyle.cs_input, gstyle.w50, gstyle.cs_bl_n];

    const style_th_col_1 = [style.cs_th, gstyle.cs_col_1];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_td_col_1 = [style.cs_td, gstyle.cs_col_1];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];

    const [SearchFieldType, setSearchFieldType] = useState(0);
    const handleChangeSearchFieldType = (event) => {
        setSearchFieldType(event.target.value);
    }
    const handleChangeSearchValue = (event) => {
        props.onChange(event.target.value);
    }
        
    const handleKeyDown = (event) => {
        if(event.keyCode === 13){
            searchData();
        }
    }
    
    const searchData = () => {
        let data = {};
        data['userID'] = userData['id'];
        data['warehouse_id'] = props.warehouseId;
        if(SearchFieldType == 0) {
            data['item_type_code'] = props.SearchValue;
        } else if(SearchFieldType == 1) {
            data['item_type_name'] = props.SearchValue;
        } else {
            data['freightage_name_kana'] = props.SearchValue;
        }
        if(document.getElementById("ItemTypeFilterByLike").checked){
            data['filterByLike'] = 1;
        }else{
            data['filterByLike'] = 0;
        }
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemTypeByShipperEndpoint;
        setLoaded(true);
        props.setTableData([]);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            setLoaded(false);
            if(response.status == 200) {
                if(response.data.data.length > 0){
                //     let result = response.data.data[0];
                //     props.target(result,props.targetParam!=null?props.targetParam:'');
                //     props.onClose(true);
                // }else{
                    props.setTableData(response.data.data);
                }
                if(response.data.data.length == 0)  toast.error("検索結果はありません。", { autoClose: 3000 });
            }
        })
        .catch((error) => {
            setLoaded(false);
            toast.error("検索結果はありません。", { autoClose: 3000 });
        });
    }
    const handleChangeData = (event, data) => {
        event.preventDefault();
        props.target(data,props.targetParam!=null?props.targetParam:'');
        props.onClose(true);
    }

    return (
        <Modal title="品種検索" show={props.show} onClose={props.onClose} width={props.width}>
            <div className={gstyle.hyfiled}>
                <div style={{display: 'flex', alignItems:'center'}}>
                    <label className={style_label_wrap.join(" ")}>
                        <div className={gstyle.cs_flex}>
                            <select className={style_select_40.join(" ")} id="SearchFieldType"
                                value={SearchFieldType}
                                onChange={handleChangeSearchFieldType}>
                                    <option value="0">コード</option>
                                    <option value="1">名称</option>
                                </select>
                            <input type={'text'} className={style_input_50.join(" ")} 
                                value={props.SearchValue} onKeyDown={handleKeyDown}
                                onChange={handleChangeSearchValue} />
                        </div>
                    </label>
                    <Button onClick={searchData} loading={loaded} type={'primary'} style={{marginLeft: 30, width:120, height:35, borderRadius:'unset'}}>
                        { loaded ? '検索中' : '検索'}
                    </Button>
                    <label className={gstyle.cs_label}>
                        <div className={gstyle.cs_flex}>
                            <input type={"checkbox"} className={style.cs_input_check} id="ItemTypeFilterByLike" />
                            <span>部分検索</span>
                        </div>
                    </label>
                </div>
            </div>
            <div className={gstyle.hyfiled}>
                <div className={style.cs_tbl}>
                    <div className={style.cs_thead}>
                        <div className={style.cs_tr}>
                            {/* <div className={style_th_col_1.join(" ")}>ID</div> */}
                            <div className={style_th_col_2.join(" ")}>コード</div>
                            <div className={style.cs_th}>名称</div>
                        </div>
                    </div>
                    <div className={style.cs_tbody}>
                        { props.tableData.map((data) => (
                            <div className={style.cs_tr} key={data['sys_item_type_id']} 
                            onClick={(e)=>handleChangeData(e,data)}>
                                {/* <div className={style_td_col_1.join(" ")}>{data['sys_item_type_id']}</div> */}
                                <div className={style_td_col_2.join(" ")}>{data['item_type_code']}</div>
                                <div className={style.cs_td_left}>{data['item_type_name']}</div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default ItemTypeShipperModal;




