/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './MonthCarryForward.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import $ from 'jquery';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import icon_warning from '../../../components/common/icons/icon_warning.png'; 
import { Select } from 'antd';

const MonthCarryForward = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [showModalDel2, setShowModalDel2] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    // publish date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // prev and next date --------------------------------------------
    const [PrevDate, setPrevDate] = useState(useJwt.formatYearMonth(new Date()));
    const [NextDate, setNextDate] = useState(useJwt.formatYearMonth(new Date()));
    // prev start and end date ---------------------------------------
    const [PrevStartDate, setPrevStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePrevStartDate = (date) => {
        setPrevStartDate(useJwt.formatDate(date));
    };
    const [PrevEndDate, setPrevEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePrevEndDate = (date) => {
        setPrevEndDate(useJwt.formatDate(date));
        setPrevDate(useJwt.formatYearMonth(date));
    };
    // next start and end date ---------------------------------------
    const [NextStartDate, setNextStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeNextStartDate = (date) => {
        setNextStartDate(useJwt.formatDate(date));
    };
    const [NextEndDate, setNextEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeNextEndDate = (date) => {
        setNextEndDate(useJwt.formatDate(date));
        setNextDate(useJwt.formatYearMonth(date));
    };
    // rot delete type -----------------------------------------------
    const [RotDelType, setRotDelType] = useState(0);
    const handleChangeRotDelType = (event) => {
        setRotDelType(event);
    };
    const RotDelTypeData = [ { value:'0', label:'削除する' }, { value:'1', label:'削除しない' } ];
    // パスワード
    const [password, setPassword] = useState("");
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_carried_over_flag_1'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        setMainTableData(result);
        if(result.length>0){
            setOpend(true);
        }
    }
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

        
    const [type1Open, setType1Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('email').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onEmailKeyDown = ( e ) =>{
        if (e.keyCode == 13) {
            document.getElementById('viewBut').focus();
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_9 = [gstyle.cs_col, gstyle.cs_col_9];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap2 = [style.p2030];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_wrap4 = [gstyle.p00300015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <div className={gstyle.border}>
                <p className={gstyle.text}>月次繰越処理</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* Warning */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_2.join(" ")}></div>
                        <div className={style_col_8.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <div className={style.warning_wrap}>
                                    <img src={icon_warning} className={style.icon_warning} alt="icon" />
                                    <div>
                                        <p>この処理は 
                                            <span className={style.warning_date_span}>{PrevDate}</span> 
                                            以前のデータを削除し、
                                        </p>
                                        <p>
                                            <span className={style.warning_date_span}>{NextDate}</span> 
                                            の入力を可能にします。
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 発行年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 繰越前 入力可能範囲, 繰越後 入力可能範囲  */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>繰越前 入力可能範囲</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PrevStartDate} onChange={handleChangePrevStartDate} id='date2' focusId='date3' />
                                        <span className={style.m0010}> ~ </span>
                                        <InputDatepicker  value={PrevEndDate} onChange={handleChangePrevEndDate} id='date3' focusId='date4' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>繰越後 入力可能範囲</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={NextStartDate} onChange={handleChangeNextStartDate} id='date4' focusId='date5' />
                                        <span className={style.m0010}> ~ </span>
                                        <InputDatepicker  value={NextEndDate} onChange={handleChangeNextEndDate} id='date5' focusId='type1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* ロット削除区分, パスワード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>ロット削除区分</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type1Open}
                                            placeholder="ロット削除区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeRotDelType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={RotDelTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>パスワード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'password'} className={gstyle.cs_input} value={password}
                                        id='email' onKeyDown={onEmailKeyDown}
                                        onChange={handleChangePassword} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='viewBut' >処理</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>月次繰越処理</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'月次繰越処理_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default MonthCarryForward;