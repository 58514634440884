/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './BillingStatement.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import { Select } from 'antd';

const BillingStatement = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    // publish date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // date type -----------------------------------------------
    const [DateType, setDateType] = useState(0);
    const handleChangeDateType = (event) => {
        setDateType(event);
    };
    const DateTypeData = [{ value: '0', label: '締日指定' }, { value: '1', label: '期締範囲指定' },];
    // deadline day ---------------------------------------------
    const [DeadLineDay, setDeadLineDay] = useState(useJwt.formatDate(new Date()));
    const handleChangeDeadLineDay = (date) => {
        setDeadLineDay(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value, 6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value, 3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value, 6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value, 3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event, param1, param2) => {
        if (event.keyCode === 13) { // enter key
            getShipperData(event.target.value, param1, param2);
            event.preventDefault();

            if (param1 == 1) {
                if (param2 == 1) {
                    document.getElementById('shipper_a_code1').focus();
                } else {
                    document.getElementById('shipper_code2').focus();
                }
            } else {
                if (param2 == 1) {
                    document.getElementById('shipper_a_code2').focus();
                } else {
                    document.getElementById('type2').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if (param1 == 1) {
            if (param2 == 1) {
                code = value;
                acode = ShipperACode1;
            } else {
                code = ShipperCode1;
                acode = value;
            }
        } else {
            if (param2 == 1) {
                code = value;
                acode = ShipperACode2;
            } else {
                code = ShipperCode2;
                acode = value;
            }
        }


        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if (acode != "") {
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                }).then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setShipperData(result, param1);
                        } else {
                            setShipperData([], param1);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setShipperData([], param1);
        }
    }
    const setShipperData = (data, param) => {
        if (data['shipper_code'] != null) {
            if (param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            } else {
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        } else {
            if (param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            } else {
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // sub code type -----------------------------------------------
    const [SubCodeType, setSubCodeType] = useState(0);
    const handleChangeSubCodeType = (event) => {
        setSubCodeType(event);
    };
    // freightage print type ---------------------------------------
    const [FreightagePrintType, setFreightagePrintType] = useState(0);
    const handleChangeFreightagePrintType = (event) => {
        setFreightagePrintType(event);
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
    };
    const OrderTypeData = [{ value: '0', label: '自社品番順' }, { value: '1', label: '荷主品番順' }, { value: '2', label: '商品分類①' },
    { value: '3', label: '商品分類②' }, { value: '4', label: '商品分類③' }, { value: '5', label: '品種順' }];
    // category print type -----------------------------------------------
    const [CategoryPrintType, setCategoryPrintType] = useState(0);
    const handleChangeCategoryPrintType = (event) => {
        setCategoryPrintType(event);
    };
    // item print type -----------------------------------------------
    const [ItemPrintType, setItemPrintType] = useState(0);
    const handleChangeItemPrintType = (event) => {
        setItemPrintType(event);
    };
    // header print type -----------------------------------------------
    const [HeaderPrintType, setHeaderPrintType] = useState(0);
    const handleChangeHeaderPrintType = (event) => {
        setHeaderPrintType(event);
    };
    // total sum type -------------------------------------------------
    const [TotalSumType, setTotalSumType] = useState(0);
    const handleChangeTotalSumType = (event) => {
        setTotalSumType(event);
    };
    // input, output sum type -------------------------------------------------
    const [InOutSumType, setInOutSumType] = useState(0);
    const handleChangeInOutSumType = (event) => {
        setInOutSumType(event);
    };
    // round sum type -------------------------------------------------
    const [RoundSumType, setRoundSumType] = useState(0);
    const handleChangeRoundSumType = (event) => {
        setRoundSumType(event);
    };
    // quantity sum type -------------------------------------------------
    const [QtySumType, setQtySumType] = useState(0);
    const handleChangeQtySumType = (event) => {
        setQtySumType(event);
    };
    // tax rate ----------------------------------------------------------
    const [TaxRate, setTaxRate] = useState("0.000");
    const getTaxData = () => {
        let data = {};
        data['userID'] = userData['id'];
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstTaxEndpoint;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if (response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if (response.data.data.length > 0) {
                        setTaxRate(response.data.data[0]['tax_1']);
                    }
                }
                csloading(false);
            })
            .catch((error) => {
            });
    }
    // self company info ---------------------------------------------
    const [ConsignorName, setConsignorName] = useState("");
    const [ConsignorZip, setConsignorZip] = useState("");
    const [ConsignorAddress1, setConsignorAddress1] = useState("");
    const [ConsignorAddress2, setConsignorAddress2] = useState("");
    const [ConsignorAddress3, setConsignorAddress3] = useState("");
    const [ConsignorTel, setConsignorTel] = useState("");
    const [ConsignorFax, setConsignorFax] = useState("");
    // =================================================================================

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);
            if (menuPatternData['menu_invoice_flag_2'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            getTaxData();
            const data = {
                userID: userData['id'],
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMainSettingEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        var address = response.data.data['distination_address_1'] + response.data.data['distination_address_2'] + response.data.data['distination_address_3']
                        setConsignorName(response.data.data['company_name']);
                        setConsignorZip(response.data.data['distination_zip']);
                        setConsignorAddress1(address);
                        setConsignorAddress2(response.data.data['distination_address_2']);
                        setConsignorAddress3(response.data.data['distination_address_3']);
                        setConsignorTel(response.data.data['distination_tel']);
                        setConsignorFax(response.data.data['distination_fax']);
                    }
                })
                .catch((error) => {
                });
        }
    });
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();

        let data = {};
        data['userID'] = userData['id'];
        if (DateType == 0) {
            data['deadline_day'] = useJwt.getDay(DeadLineDay);
            data['deadline_year_month'] = useJwt.getYearMonth(DeadLineDay);
        } else {
            // data['start_slip_date'] = StartDate;
            // data['end_slip_date'] = EndDate;
            data['deadline_year_month'] = useJwt.getYearMonth(EndDate);
        }
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        data['sub_code_type'] = SubCodeType;
        data['prev_flg'] = 0;

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMonthInvoiceEndpoint;
        csloading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                console.log(response)
                let itemData = response.data.data.itemData;
                let freightageData = response.data.data.freightageData1;
                let depositData = response.data.data.depositData;
                if (itemData.length > 0) {
                    getItemRemainNum(itemData, freightageData, depositData);
                } else {
                    toast.error("検索結果はありません。", { autoClose: 3000 });
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });
    }
    const CloseSearchWidow = () => {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);
    const getItemRemainNum = (result, freightageData, depositData) => {
        let data = {};
        data['userID'] = userData['id'];
        if (DateType == 0) {
            data['deadline_day'] = useJwt.getDay(DeadLineDay);
            data['deadline_year_month'] = useJwt.getPreviousMonth(DeadLineDay);
        } else {
            data['deadline_year_month'] = useJwt.getPreviousMonth(EndDate);
        }
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        data['sub_code_type'] = SubCodeType;
        data['prev_flg'] = 1;

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMonthInvoiceEndpoint;
        csloading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let prev_result = response.data.data.itemData;
                let prev_invoiceData = response.data.data.invoiceData;
                // console.log("result", result);
                // console.log("prev_result", prev_result);
                // console.log("prev_invoiceData", prev_invoiceData);
                gridMainTable(result, prev_result, freightageData, depositData, prev_invoiceData);
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });
    }
    const gridMainTable = (result, prev_result, freightageData, depositData, prev_invoiceData) => {
        let data = [];
        let itemCodes = [];
        let header_info = [];
        console.log(depositData);

        for (var i = 0; i < result.length; i++) {
            let filter_code = '' + result[i]['shipper_code'];
            if (!itemCodes.includes(filter_code)) {
                itemCodes.push(filter_code);
                let row = {};
                row['shipper_code'] = result[i]['shipper_code'];
                row['shipper_a_code'] = result[i]['shipper_a_code'];
                row['shipper_name'] = result[i]['shipper_name'];
                row['shipper_address_1'] = result[i]['shipper_address_1'];
                row['shipper_address_2'] = result[i]['shipper_address_2'];
                row['shipper_address_3'] = result[i]['shipper_address_3'];
                row['shipper_zip'] = result[i]['shipper_zip'];
                row['shipper_tel'] = result[i]['shipper_tel'];
                row['shipper_fax'] = result[i]['shipper_fax'];
                row['storage_fee_type'] = result[i]['storage_fee_type'];
                row['cargo_handling_fee_type'] = result[i]['cargo_handling_fee_type'];
                header_info.push(row);
            }
        }
        for (var i = 0; i < itemCodes.length; i++) {
            let row = {};
            row['filter_code'] = itemCodes[i];
            row['header_info'] = header_info[i];
            row['item_info'] = [];
            row['freightage_info'] = [];
            let total_g = 0; // total 保管料
            let total_h = 0; // total 荷役料
            let total_i = 0; // total 諸掛合計
            let total_j = 0; // total 合計
            let total_k = 0; // total 消費税
            let total_l = 0; // total 当月御請求額
            for (var j = 0; j < result.length; j++) {
                let filter_code = '' + result[j]['shipper_code'];
                if (itemCodes[i] == filter_code) {
                    let tmp_row = result[j];

                    let prev_code = '' + tmp_row['shipper_code'] + '/' + tmp_row['item_code'];
                    if (SubCodeType == 1) {
                        prev_code += '/' + tmp_row['item_a_code'];
                    }
                    // get 前月末残
                    let prev_month_item_num = 0;
                    for (var k = 0; k < prev_result.length; k++) {
                        let prev_filter_code = '' + prev_result[k]['shipper_code'] + '/' + prev_result[k]['item_code'];
                        if (SubCodeType == 1) {
                            prev_filter_code += '/' + prev_result[k]['item_a_code'];
                        }
                        if (prev_code == prev_filter_code) {
                            let prev_e_1 = prev_result[k]['item_ary']['arrivalNum_1'];
                            let prev_e_2 = prev_result[k]['item_ary']['arrivalNum_2'];
                            let prev_e_3 = prev_result[k]['item_ary']['arrivalNum_3'];
                            let prev_f_1 = prev_result[k]['item_ary']['leavingNum_1'];
                            let prev_f_2 = prev_result[k]['item_ary']['leavingNum_2'];
                            let prev_f_3 = prev_result[k]['item_ary']['leavingNum_3'];
                            prev_month_item_num += (1 * prev_e_1 + 1 * prev_e_2 + 1 * prev_e_3 - prev_f_1 - prev_f_2 - prev_f_3);
                        }
                    }
                    // 前月末残
                    tmp_row['col_a'] = 1 * prev_month_item_num;
                    // 上期残
                    tmp_row['col_b'] = 1 * tmp_row['col_a'] + (1 * tmp_row['item_ary']['arrivalNum_1'] - 1 * tmp_row['item_ary']['leavingNum_1']);
                    // 中期残
                    tmp_row['col_c'] = 1 * tmp_row['col_b'] + (1 * tmp_row['item_ary']['arrivalNum_2'] - 1 * tmp_row['item_ary']['leavingNum_2']);
                    // 下期残
                    tmp_row['col_d'] = 1 * tmp_row['col_c'] + (1 * tmp_row['item_ary']['arrivalNum_3'] - 1 * tmp_row['item_ary']['leavingNum_3']);
                    // 入庫数
                    tmp_row['col_e'] = (1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_2'] + 1 * tmp_row['item_ary']['arrivalNum_3']);
                    // 出庫数
                    tmp_row['col_f'] = (1 * tmp_row['item_ary']['leavingNum_1'] + 1 * tmp_row['item_ary']['leavingNum_2'] + 1 * tmp_row['item_ary']['leavingNum_3']);
                    // 保管料 積数
                    if (tmp_row['storage_fee_type'] == 0) { // 前残＋入庫
                        if (tmp_row['deadline_times'] == 1) { // 1 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_a'] + 1 * tmp_row['col_b'] + 1 * tmp_row['col_c'];
                            tmp_row['col_storage_mult'] += 1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_2'] + 1 * tmp_row['item_ary']['arrivalNum_3'];
                        } else if (tmp_row['deadline_times'] == 2) { // 2 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_a'] + 1 * tmp_row['col_b'];
                            tmp_row['col_storage_mult'] += 1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_3'];
                        } else { // 3 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_a'];
                            tmp_row['col_storage_mult'] += 1 * tmp_row['item_ary']['arrivalNum_3'];
                        }
                    } else if (tmp_row['storage_fee_type'] == 1) { // 当残＋入庫
                        if (tmp_row['deadline_times'] == 1) { // 1 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_b'] + 1 * tmp_row['col_c'] + 1 * tmp_row['col_d'];
                            tmp_row['col_storage_mult'] += 1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_2'] + 1 * tmp_row['item_ary']['arrivalNum_3'];
                        } else if (tmp_row['deadline_times'] == 2) { // 2 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_b'] + 1 * tmp_row['col_d'];
                            tmp_row['col_storage_mult'] += 1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_3'];
                        } else { // 3 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_d'];
                            tmp_row['col_storage_mult'] += 1 * tmp_row['item_ary']['arrivalNum_3'];
                        }
                    } else if (tmp_row['storage_fee_type'] == 2) { // 前残
                        if (tmp_row['deadline_times'] == 1) { // 1 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_a'] + 1 * tmp_row['col_b'] + 1 * tmp_row['col_c'];
                        } else if (tmp_row['deadline_times'] == 2) { // 2 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_a'] + 1 * tmp_row['col_b'];
                        } else { // 3 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_a'];
                        }
                    } else if (tmp_row['storage_fee_type'] == 3) { // 当残
                        if (tmp_row['deadline_times'] == 1) { // 1 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_b'] + 1 * tmp_row['col_c'] + 1 * tmp_row['col_d'];
                        } else if (tmp_row['deadline_times'] == 2) { // 2 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_b'] + 1 * tmp_row['col_d'];
                        } else { // 3 期締
                            tmp_row['col_storage_mult'] = 1 * tmp_row['col_d'];
                        }
                    } else { // 計算無し
                        tmp_row['col_storage_mult'] = 0;
                    }
                    // 保管料 
                    tmp_row['col_storage_price'] = tmp_row['col_storage_mult'] * tmp_row['storage_fee_rate'];

                    // 荷役料 積数 
                    if (tmp_row['cargo_handling_fee_type'] == 0) { // 入庫＋出庫
                        if (tmp_row['deadline_times'] == 1) { // 1 期締
                            tmp_row['col_cargo_mult'] = 1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_2'] + 1 * tmp_row['item_ary']['arrivalNum_3'];
                            tmp_row['col_cargo_mult'] += 1 * tmp_row['item_ary']['leavingNum_1'] + 1 * tmp_row['item_ary']['leavingNum_2'] + 1 * tmp_row['item_ary']['leavingNum_3'];

                            tmp_row['col_cargo_price'] = tmp_row['arrival_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_2'] + 1 * tmp_row['item_ary']['arrivalNum_3']);
                            tmp_row['col_cargo_price'] += tmp_row['leaving_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['leavingNum_1'] + 1 * tmp_row['item_ary']['leavingNum_2'] + 1 * tmp_row['item_ary']['leavingNum_3']);
                        } else if (tmp_row['deadline_times'] == 1) { // 2 期締
                            tmp_row['col_cargo_mult'] = 1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_3'];
                            tmp_row['col_cargo_mult'] += 1 * tmp_row['item_ary']['leavingNum_1'] + 1 * tmp_row['item_ary']['leavingNum_3'];

                            tmp_row['col_cargo_price'] = tmp_row['arrival_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_3']);
                            tmp_row['col_cargo_price'] += tmp_row['leaving_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['leavingNum_1'] + 1 * tmp_row['item_ary']['leavingNum_3']);
                        } else { // 3 期締
                            tmp_row['col_cargo_mult'] = 1 * tmp_row['item_ary']['arrivalNum_3'];
                            tmp_row['col_cargo_mult'] += 1 * tmp_row['item_ary']['leavingNum_3'];

                            tmp_row['col_cargo_price'] = tmp_row['arrival_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['arrivalNum_3']);
                            tmp_row['col_cargo_price'] += tmp_row['leaving_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['leavingNum_3']);
                        }
                    } else if (tmp_row['cargo_handling_fee_type'] == 1) { // 入庫
                        if (tmp_row['deadline_times'] == 1) { // 1 期締
                            tmp_row['col_cargo_mult'] = 1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_2'] + 1 * tmp_row['item_ary']['arrivalNum_3'];
                            tmp_row['col_cargo_price'] = tmp_row['arrival_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_2'] + 1 * tmp_row['item_ary']['arrivalNum_3']);
                        } else if (tmp_row['deadline_times'] == 1) { // 2 期締
                            tmp_row['col_cargo_mult'] = 1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_3'];
                            tmp_row['col_cargo_price'] = tmp_row['arrival_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['arrivalNum_1'] + 1 * tmp_row['item_ary']['arrivalNum_3']);
                        } else { // 3 期締
                            tmp_row['col_cargo_mult'] = 1 * tmp_row['item_ary']['arrivalNum_3'];
                            tmp_row['col_cargo_price'] = tmp_row['arrival_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['arrivalNum_3']);
                        }
                    } else if (tmp_row['cargo_handling_fee_type'] == 1) { // 出庫
                        if (tmp_row['deadline_times'] == 1) { // 1 期締
                            tmp_row['col_cargo_mult'] = 1 * tmp_row['item_ary']['leavingNum_1'] + 1 * tmp_row['item_ary']['leavingNum_2'] + 1 * tmp_row['item_ary']['leavingNum_3'];
                            tmp_row['col_cargo_price'] = tmp_row['leaving_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['leavingNum_1'] + 1 * tmp_row['item_ary']['leavingNum_2'] + 1 * tmp_row['item_ary']['leavingNum_3']);
                        } else if (tmp_row['deadline_times'] == 1) { // 2 期締
                            tmp_row['col_cargo_mult'] = 1 * tmp_row['item_ary']['leavingNum_1'] + 1 * tmp_row['item_ary']['leavingNum_3'];
                            tmp_row['col_cargo_price'] = tmp_row['leaving_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['leavingNum_1'] + 1 * tmp_row['item_ary']['leavingNum_3']);
                        } else { // 3 期締
                            tmp_row['col_cargo_mult'] = 1 * tmp_row['item_ary']['leavingNum_3'];
                            tmp_row['col_cargo_price'] += tmp_row['leaving_cargo_handling_fee_rate'] * (1 * tmp_row['item_ary']['leavingNum_3']);
                        }
                    } else { // 計算無し
                        tmp_row['col_cargo_mult'] = 0;
                        tmp_row['col_cargo_price'] = 0;
                    }
                    row['item_info'].push(tmp_row);
                    total_g += 1 * tmp_row['col_storage_price'];
                    total_h += 1 * tmp_row['col_cargo_price'];
                }
            }
            for (var j = 0; j < freightageData.length; j++) {
                let filter_code = '' + freightageData[j]['shipper_code'];
                if (itemCodes[i] == filter_code) {
                    let tmp_row = freightageData[j];
                    row['freightage_info'].push(tmp_row);
                    total_i += 1 * tmp_row['freightage_price'];
                }
            }
            let deposit_price = 0;
            for (var j = 0; j < depositData.length; j++) {
                let filter_code = '' + depositData[j]['shipper_code'];
                if (itemCodes[i] == filter_code) {
                    deposit_price += 1 * depositData[j]['deposit_price'];
                }
            }
            let prev_invoice_price = 0;
            for (var j = 0; j < prev_invoiceData.length; j++) {
                let filter_code = '' + prev_invoiceData[j]['shipper_code'];
                if (itemCodes[i] == filter_code) {
                    prev_invoice_price += 1 * prev_invoiceData[j]['invoice_price'];
                }
            }

            row['header_info']['total_g'] = total_g;
            row['header_info']['total_h'] = total_h;
            row['header_info']['total_i'] = total_i;
            total_j = 1 * total_g + 1 * total_h + 1 * total_i;
            row['header_info']['total_j'] = total_j;
            total_k = ((1 * total_g + 1 * total_h) * TaxRate / 100 + 1 * total_i * TaxRate / 100).toFixed(0);
            row['header_info']['total_k'] = total_k;
            total_l = 1 * total_j + 1 * total_k;
            row['header_info']['total_l'] = total_l;
            row['header_info']['deposit_price'] = deposit_price;
            let invoice_price = prev_invoice_price - deposit_price + total_l;
            row['header_info']['invoice_price'] = invoice_price;
            data.push(row);
        }
        // console.log("data:",data);
        setInvoiceData(data);
        setMainTableData(data);
        if (data.length > 0) {
            setOpend(true);
        }
    }
    const setInvoiceData = (result) => {
        let data = {};
        data['userID'] = userData['id'];
        if (DateType == 0) {
            data['deadline_year_month'] = useJwt.getYearMonth(DeadLineDay);
        } else {
            data['deadline_year_month'] = useJwt.getYearMonth(EndDate);
        }
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstInvoiceEndpoint;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if (response.status == 200) {
                    let old_invoice_data = [];
                    if (response.data.data.length > 0) {
                        old_invoice_data = response.data.data;
                    }
                    let invoice_data = [];
                    for (var i = 0; i < result.length; i++) {
                        let row = {};
                        row['apply_date'] = data['deadline_year_month'];
                        row['shipper_code'] = result[i]['header_info']['shipper_code'];
                        row['shipper_name'] = result[i]['header_info']['shipper_name'];
                        row['storage_price'] = result[i]['header_info']['total_g'];
                        row['cargo_price'] = result[i]['header_info']['total_h'];
                        row['freightage_price'] = result[i]['header_info']['total_i'];
                        row['tax_price'] = result[i]['header_info']['total_k'];
                        row['total_price'] = result[i]['header_info']['total_l'];
                        row['deposit_price'] = result[i]['header_info']['deposit_price'];
                        row['invoice_price'] = result[i]['header_info']['invoice_price'];

                        for (var j = 0; j < old_invoice_data.length; j++) {
                            if (result[i]['header_info']['shipper_code'] == old_invoice_data[j]['shipper_code']) {
                                row['sys_invoice_id'] = old_invoice_data[j]['sys_invoice_id'];
                            }
                        }
                        invoice_data.push(row);
                    }

                    if (invoice_data.length > 0) {
                        data['data'] = invoice_data;
                        let set_url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstInvoiceEndpoint;
                        axios
                            .post(set_url, data, {
                                headers: {
                                    Authorization: "Bearer " + useJwt.getToken(),
                                },
                            })
                            .then((response) => {
                            })
                            .catch((error) => {
                            });
                    }
                    let delIds = [];
                    for (var j = 0; j < old_invoice_data.length; j++) {
                        let del_flg = true;
                        for (var i = 0; i < result.length; i++) {
                            if (result[i]['filter_code'] == old_invoice_data[j]['shipper_code']) {
                                del_flg = false;
                            }
                        }
                        if (del_flg) {
                            delIds.push(old_invoice_data[j]['sys_invoice_id']);
                        }
                    }
                    if (delIds.length > 0) {
                        let del_data = {};
                        del_data['userID'] = userData['id'];
                        del_data['delIds'] = delIds;
                        let del_url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.delMstInvoiceEndpoint;
                        axios
                            .post(del_url, del_data, {
                                headers: {
                                    Authorization: "Bearer " + useJwt.getToken(),
                                },
                            })
                            .then((response) => {
                            })
                            .catch((error) => {
                            });
                    }
                }
            })
            .catch((error) => {
            });
    }

    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if (param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setShipperTableData(response.data.data);
                    setShowModalShipper(true);
                })
                .catch((error) => {
                });
        } else {
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if (text === "New, Updated Text!" && typeof onBeforeGetContentResolve.current === "function") {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);
    const [type5Open, setType5Open] = useState(false);
    const [type6Open, setType6Open] = useState(false);
    const [type7Open, setType7Open] = useState(false);
    const [type8Open, setType8Open] = useState(false);
    const [type9Open, setType9Open] = useState(false);
    const [type10Open, setType10Open] = useState(false);
    const [type11Open, setType11Open] = useState(false);

    const onType1KeyDown = (event) => {
        if (event.keyCode == 13) {
            setTimeout(() => {
                if (document.getElementById('date2')) {
                    document.getElementById('date2').focus();
                } else {
                    document.getElementById('date3').focus();
                }
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if (type == 1) {
            setType1Open(true);
        } else {
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type3').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if (type == 1) {
            setType2Open(true);
        } else {
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type4').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if (type == 1) {
            setType3Open(true);
        } else {
            setType3Open(false);
        }
    }

    const onType4KeyDown = (event) => {
        if (event.keyCode == 13) {
            setTimeout(() => {
                if (document.getElementById('type5'))
                    document.getElementById('type5').focus();
                else
                    document.getElementById('type6').focus();
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType4 = (e, type) => {
        if (type == 1) {
            setType4Open(true);
        } else {
            setType4Open(false);
        }
    }

    const onType5KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type6').focus();
            event.preventDefault();
        }
    }
    const onFocusType5 = (e, type) => {
        if (type == 1) {
            setType5Open(true);
        } else {
            setType5Open(false);
        }
    }

    const onType6KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type7').focus();
            event.preventDefault();
        }
    }
    const onFocusType6 = (e, type) => {
        if (type == 1) {
            setType6Open(true);
        } else {
            setType6Open(false);
        }
    }

    const onType7KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type8').focus();
            event.preventDefault();
        }
    }
    const onFocusType7 = (e, type) => {
        if (type == 1) {
            setType7Open(true);
        } else {
            setType7Open(false);
        }
    }

    const onType8KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type9').focus();
            event.preventDefault();
        }
    }
    const onFocusType8 = (e, type) => {
        if (type == 1) {
            setType8Open(true);
        } else {
            setType8Open(false);
        }
    }

    const onType9KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type10').focus();
            event.preventDefault();
        }
    }
    const onFocusType9 = (e, type) => {
        if (type == 1) {
            setType9Open(true);
        } else {
            setType9Open(false);
        }
    }

    const onType10KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type11').focus();
            event.preventDefault();
        }
    }
    const onFocusType10 = (e, type) => {
        if (type == 1) {
            setType10Open(true);
        } else {
            setType10Open(false);
        }
    }

    const onType11KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType11 = (e, type) => {
        if (type == 1) {
            setType11Open(true);
        } else {
            setType11Open(false);
        }
    }
    const onSelectData = (e, index) => {
        document.getElementById('searchBut').focus();

        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            case 4:
                setType4Open(false);
                break;
            case 5:
                setType5Open(false);
                break;
            case 6:
                setType6Open(false);
                break;
            case 7:
                setType7Open(false);
                break;
            case 8:
                setType8Open(false);
                break;
            case 9:
                setType9Open(false);
                break;
            case 10:
                setType10Open(false);
                break;
            case 11:
                setType11Open(false);
                break;

            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ShipperModal show={showModalShipper} onClose={() => setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue}
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>請求明細書</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 締日区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker value={PublishDate} onChange={handleChangePublishDate} focusId='type1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>締日区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e) => onSelectData(e, 1)}
                                            placeholder="締日区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeDateType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type1"
                                            options={DateTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 締切年月日 */}
                    {DateType == 0 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>締切年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker value={DeadLineDay} onChange={handleChangeDeadLineDay} id='date2' focusId='shipper_code1' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 年月日 */}
                    {DateType == 1 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker value={StartDate} onChange={handleChangeStartDate} id='date3' focusId='date4' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker value={EndDate} onChange={handleChangeEndDate} id='date4' focusId='shipper_code1' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownShipperCode(e, 1, 1)} id='shipper_code1'
                                            value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                            onKeyDown={(e) => onKeyDownShipperCode(e, 1, 2)} id='shipper_a_code1'
                                            value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadShipperModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownShipperCode(e, 2, 1)} id='shipper_code2'
                                            value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                            onKeyDown={(e) => onKeyDownShipperCode(e, 2, 2)} id='shipper_a_code2'
                                            value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadShipperModalData(e, 2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  補助区分, 諸掛印字, 出力順位, 分類・品種計 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>補助区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e) => onSelectData(e, 2)}
                                            placeholder="補助区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeSubCodeType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type2"
                                            options={[
                                                { value: '0', label: '一括' },
                                                { value: '1', label: '補助別' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>諸掛印字</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e) => onSelectData(e, 3)}
                                            placeholder="諸掛印字を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeFreightagePrintType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type3"
                                            options={[
                                                { value: '0', label: '印字有り' },
                                                { value: '1', label: '印字無し' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type4Open}
                                            onSelect={(e) => onSelectData(e, 4)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType4KeyDown}
                                            onFocus={(e) => onFocusType4(e, 1)}
                                            onBlur={(e) => onFocusType4(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type4"
                                            options={OrderTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        {OrderType > 1 &&
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>分類・品種計</span>
                                        <div className={gstyle.cs_flex}>
                                            <Select
                                                showSearch
                                                open={type5Open}
                                                onSelect={(e) => onSelectData(e, 5)}
                                                placeholder="分類・品種計を選択します。"
                                                optionFilterProp="children"
                                                onChange={handleChangeCategoryPrintType}
                                                onKeyDown={onType5KeyDown}
                                                onFocus={(e) => onFocusType5(e, 1)}
                                                onBlur={(e) => onFocusType5(e, 2)}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                style={{ marginTop: 14, width: '100%' }}
                                                id="type5"
                                                options={[
                                                    { value: '0', label: '印字有り' },
                                                    { value: '1', label: '印字無し' },
                                                ]}
                                            />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                    {/* 品番印字区分, ヘッダー印字 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番印字区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type6Open}
                                            onSelect={(e) => onSelectData(e, 6)}
                                            placeholder="品番印字区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeItemPrintType}
                                            onKeyDown={onType6KeyDown}
                                            onFocus={(e) => onFocusType6(e, 1)}
                                            onBlur={(e) => onFocusType6(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type6"
                                            options={[
                                                { value: '0', label: '自社品番' },
                                                { value: '1', label: '荷主品番' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>ヘッダー印字</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type7Open}
                                            onSelect={(e) => onSelectData(e, 7)}
                                            placeholder="ヘッダー印字を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeHeaderPrintType}
                                            onKeyDown={onType7KeyDown}
                                            onFocus={(e) => onFocusType7(e, 1)}
                                            onBlur={(e) => onFocusType7(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type7"
                                            options={[
                                                { value: '0', label: '２頁以降印字無し' },
                                                { value: '1', label: '２頁以降印字有り' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 計区分, 入出庫数計, 積数計, 扱数量計 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>計区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type8Open}
                                            onSelect={(e) => onSelectData(e, 8)}
                                            placeholder="計区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeTotalSumType}
                                            onKeyDown={onType8KeyDown}
                                            onFocus={(e) => onFocusType8(e, 1)}
                                            onBlur={(e) => onFocusType8(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type8"
                                            options={[
                                                { value: '0', label: '小計のみ印字' },
                                                { value: '1', label: '合計のみ印字' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>入出庫数計</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type9Open}
                                            onSelect={(e) => onSelectData(e, 9)}
                                            placeholder="入出庫数計を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeInOutSumType}
                                            onKeyDown={onType9KeyDown}
                                            onFocus={(e) => onFocusType9(e, 1)}
                                            onBlur={(e) => onFocusType9(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type9"
                                            options={[
                                                { value: '0', label: '印字なし' },
                                                { value: '1', label: '印字有り (上・下段)' },
                                                { value: '2', label: '印字有り (上段のみ)' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>積数計</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type10Open}
                                            onSelect={(e) => onSelectData(e, 10)}
                                            placeholder="積数計を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeRoundSumType}
                                            onKeyDown={onType10KeyDown}
                                            onFocus={(e) => onFocusType10(e, 1)}
                                            onBlur={(e) => onFocusType10(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type10"
                                            options={[
                                                { value: '0', label: '印字なし' },
                                                { value: '1', label: '印字有り (上・下段)' },
                                                { value: '2', label: '印字有り (上段のみ)' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>扱数量計</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type11Open}
                                            onSelect={(e) => onSelectData(e, 11)}
                                            placeholder="扱数量計を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeQtySumType}
                                            onKeyDown={onType11KeyDown}
                                            onFocus={(e) => onFocusType11(e, 1)}
                                            onBlur={(e) => onFocusType11(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type11"
                                            options={[
                                                { value: '0', label: '印字なし' },
                                                { value: '1', label: '印字有り (上・下段)' },
                                                { value: '2', label: '印字有り (上段のみ)' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >発行</button>
                    </div>
                </div>
            </div>
            {opened && (
                <NewWindow onUnload={() => CloseSearchWidow()}
                    features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                    <div className={style.nw_container}>
                        <div ref={componentRef}>
                            {/* Title, 発行年月日 */}
                            <div className={style_row.join(" ")}>
                                <div className={style_col_2.join(" ")}></div>
                                <div className={style_col_8.join(" ")}>
                                    <div className={style.nw_title_wrap}>
                                        <h2>請求明細書</h2>
                                    </div>
                                </div>
                                <div className={style_col_2.join(" ")}>
                                    <div className={style.publish_wrap}>
                                        <p>{PublishDate}</p>
                                    </div>
                                </div>
                            </div>
                            {mainTableData.map((data, index) => (
                                <div key={index}>
                                    {/* header  */}
                                    <div className={style_row.join(" ")}>
                                        <div className={style_col_3.join(" ")}>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            〒 {data['header_info']['shipper_zip']}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {data['header_info']['shipper_address_1'] + " " + data['header_info']['shipper_address_2'] + " " + data['header_info']['shipper_address_3']}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {data['header_info']['shipper_name']}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className={style_col_2.join(" ")}>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            御中
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className={style_col_3.join(" ")}>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div>
                                                        <p>
                                                            {
                                                                DateType == 0 ? useJwt.formatDate2(new Date(DeadLineDay)) + " 締"
                                                                    : '自 ' + useJwt.formatDate2(new Date(StartDate))
                                                            }
                                                        </p>
                                                        <p>
                                                            {
                                                                DateType == 1 && '至 ' + useJwt.formatDate2(new Date(EndDate))
                                                            }
                                                        </p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className={style_col_4.join(" ")}>
                                            <div className={style_input_wrap.join(" ")}>

                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {'〒 ' + ConsignorZip }
                                                        </span>
                                                    </div>
                                                </label>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {ConsignorAddress1}
                                                        </span>
                                                    </div>
                                                </label>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {ConsignorName}
                                                        </span>
                                                    </div>
                                                </label>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            TEL:
                                                        </span>
                                                        <span className={style.cs_span_3}>
                                                            {ConsignorTel}
                                                        </span>
                                                    </div>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            FAX:
                                                        </span>
                                                        <span className={style.cs_span_3}>
                                                            {ConsignorFax}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* table */}
                                    <div className={style_row.join(" ")}>
                                        <div className={style_col_2.join(" ")}>
                                        </div>
                                        <div className={style_col_8.join(" ")}>
                                            <div className={style_input_wrap3.join(" ")}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>保管料</th>
                                                            <th>荷役料</th>
                                                            <th>諸掛合計</th>
                                                            <th>合　計</th>
                                                            <th>消費税</th>
                                                            <th>当月御請求額</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className={style.td_right}>￥{useJwt.numberWithCommas(data['header_info']['total_g'])}</td>
                                                            <td className={style.td_right}>￥{useJwt.numberWithCommas(data['header_info']['total_h'])}</td>
                                                            <td className={style.td_right}>￥{useJwt.numberWithCommas(data['header_info']['total_i'])}</td>
                                                            <td className={style.td_right}>￥{useJwt.numberWithCommas(data['header_info']['total_j'])}</td>
                                                            <td className={style.td_right}>￥{useJwt.numberWithCommas(data['header_info']['total_k'])}</td>
                                                            <td className={style.td_right}>￥{useJwt.numberWithCommas(data['header_info']['total_l'])}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className={style_col_2.join(" ")}>
                                        </div>
                                    </div>
                                    <div className={style_row.join(" ")}>
                                        <div className={style_col_12.join(" ")}>
                                            <div className={style_input_wrap3.join(" ")}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th rowSpan={2}>品名・規格</th>
                                                            <th rowSpan={2}>前月末残</th>
                                                            <th rowSpan={2}>上期残</th>
                                                            <th rowSpan={2}>中期残</th>
                                                            <th rowSpan={2}>下期残</th>
                                                            <th rowSpan={2}>入庫数</th>
                                                            <th rowSpan={2}>出庫数</th>
                                                            <th colSpan={2}>保管料</th>
                                                            <th colSpan={2}>荷役料</th>
                                                        </tr>
                                                        <tr>
                                                            <th>積数</th>
                                                            <th>料率/金額</th>
                                                            <th>扱数量</th>
                                                            <th>金額</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data['item_info'].map((data1, index1) => (
                                                            <React.Fragment key={index1}>
                                                                <tr>
                                                                    <td style={{textAlign:'left'}}>
                                                                        <p>{SubCodeType == 1 ?
                                                                            data1['item_code'] + ' ' + data1['item_a_code']
                                                                            : data1['item_code'] + ' ' + '000'}
                                                                        </p>
                                                                        <p>{data1['item_name']}</p>
                                                                    </td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['col_a'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['col_b'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['col_c'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['col_d'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['col_e'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['col_f'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['col_storage_mult'])}</td>
                                                                    <td className={style.td_right}>
                                                                        <p>{useJwt.numberWithCommas(data1['storage_fee_rate'])}</p>
                                                                        <p>￥{useJwt.numberWithCommas(data1['col_storage_price'])}</p>
                                                                    </td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['col_cargo_mult'])}</td>
                                                                    <td className={style.td_right}>
                                                                        <p>￥{useJwt.numberWithCommas(data1['col_cargo_price'])}</p>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))}
                                                        <tr>
                                                            <td>【 小 計 】</td>
                                                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_g'])}</td>
                                                            <td></td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_h'])}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>＜諸掛請求分＞</td>
                                                            <td></td><td></td><td></td>
                                                            <td></td><td></td><td></td>
                                                            <td></td><td></td><td></td>
                                                            <td></td>
                                                        </tr>
                                                        {data['freightage_info'].map((data1, index1) => (
                                                            <React.Fragment key={index1}>
                                                                <tr>
                                                                    <td className={style.td_left}>{data1['freightage_name']}</td>
                                                                    <td></td><td></td><td></td>
                                                                    <td></td><td></td><td></td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['freightage_num'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['freightage_price'])}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))}
                                                        <tr>
                                                            <td>【 小 計 】</td>
                                                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_i'])}</td>
                                                            <td></td><td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <ReactToPrint
                                        content={reactToPrintContent}
                                        documentTitle={'請求明細書_' + useJwt.formatDateTime(new Date())}
                                        onAfterPrint={handleAfterPrint}
                                        onBeforeGetContent={handleOnBeforeGetContent}
                                        onBeforePrint={handleBeforePrint}
                                        removeAfterPrint
                                        trigger={reactToPrintTrigger}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </NewWindow>
            )}
        </div>
    )
}

export default BillingStatement;