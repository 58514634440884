import gstyle from '../../../css/GeneralStyle.module.css';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import * as useJwt from '../../../auth/jwt/useJwt.js';
import style from "./CalendarItem.module.css";
import moment from 'moment';
import React, { useState, useEffect, Component } from 'react';
import axios from "axios";
import { Button, Modal, Row, Col } from 'antd';
import $ from 'jquery';
import { toast } from 'react-toastify';
import CalendarItem from '../../../components/common/calendar/ShipperBusScheduleItem';

export default class ShipperBusScheduleLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      busData: [],
      startDate: "",
      start_date_time: "",
      end_date_time: "",
    };
    this.elementRef = React.createRef();

    var isSave = false;
    let bodyElementX = "";

    let originalWidth = 0;

    let oldPageX = 0;

    let oldStartPoint = 0;

    let oldWidth = 0;

    var startX = 0;

    var startY = 0;

    let move_active = false;

    let originalElementX = 0;

    let originalMouseX = 0;

    let ref = "";

    var toSide = "";


    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.showModal = this.showModal.bind(this);
    this.startResize = this.startResize.bind(this);
    this.stopResize = this.stopResize.bind(this);
    this.resizeFrame = this.resizeFrame.bind(this);
    this.dragMoveFrame = this.dragMoveFrame.bind(this);
    this.onDragHandle = this.onDragHandle.bind(this);
    this.onDragEndHandle = this.onDragEndHandle.bind(this);
    this.dragLeaving = this.dragLeaving.bind(this);
  }

  showModal() {
    this.setState({ isModalOpen: true });
  };
  handleOk() {
    this.setState({ isModalOpen: false });
  };
  handleCancel() {
    this.setState({ isModalOpen: false });
  };

  componentDidMount() {
    let userData = JSON.parse(useJwt.getUserData());
    let itemList = [];
    let subItemList = {};

    let tempItemNum = 0;
    let tempItemName = "";

  }

  componentWillUnmount() {
    this.setState({
      width: 0
    })
  }

  componentWillReceiveProps(nextProps) {
    let userData = JSON.parse(useJwt.getUserData());
    let itemList = [];
    let subItemList = {};

    let tempItemNum = 0;
    let tempItemName = "";

  }

  onDragHandle(e) {
    var data = e.target.getAttribute("data-set");
    var temp = JSON.parse(data);
    temp['page_start_x'] = e.screenX;

    this.oldPageX = e.screenX;
    this.oldStartPoint = this.state.startPoint;
    this.startX = e.screenX;
    this.startY = e.screenY;

    this.move_active = true;

    e.dataTransfer?.setData("text", JSON.stringify(temp));
  }

  onDragEndHandle(e) {

    e.preventDefault();

    // var currentY = e.screenY;

    // if (Math.abs(this.startY - currentY) > 20) return;

    // let start_ms = Math.round(this.state.startPoint / this.state.unit);
    // let length_ms = Math.round(this.state.width / this.state.unit);

    // var start_date = "";
    // var end_date = "";

    // start_date = moment(this.state.startDate, "YYYY-MM-DD HH:mm:ss").add(start_ms, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    // end_date = moment(start_date, "YYYY-MM-DD HH:mm:ss").add(length_ms, 'seconds').format('YYYY-MM-DD HH:mm:ss');

    // let data = {
    //   userID: this.state.user_id,
    //   id: this.state.sys_schedule_id,
    //   start_date_time: start_date,
    //   end_date_time: end_date,
    //   bus_id: this.state.bus_id
    // };

    // if (this.move_active) {

    //   let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setDriverScheduleEndpoint;
    //   axios
    //     .post(url, data, {
    //       headers: {
    //         Authorization: "Bearer " + useJwt.getToken(),
    //       },
    //     })
    //     .then((response) => {
    //       if (response.status == 200) {
    //         this.setState({ start_date_time: start_date });
    //         this.setState({ end_date_time: end_date });

    //         let temp_cel_data = this.state.cel_data;
    //         temp_cel_data['start_date_time'] = start_date;
    //         temp_cel_data['end_date_time'] = end_date;
    //         this.setState({ cel_data: temp_cel_data });
    //         this.isSave = false;
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 302) {
    //         this.setState({ startPoint: this.oldStartPoint });
    //         toast.error("時間が重複しています。 作業時間を再設定してください。登録致しました。", { autoClose: 3000 })
    //       }
    //     });
    // }

    // this.move_active = false;

    // e.target.style.backgroundColor = "";
  }
  handleMouseDown(e, toSide) {
    e.preventDefault();

    // let body = e.target;

    // const ref = this.elementRef.current;
    // const bodyRef = this.elementRef.current.parentElement;

    // let bodyElementX = bodyRef.getBoundingClientRect().left;

    // const mminStep = 20;
    // let originalWidth = parseFloat(
    //   getComputedStyle(ref, null).getPropertyValue("width").replace("px", "") //current widget width
    // );

    // let originalElementX = ref.getBoundingClientRect().left; //widget start x-point

    // let originalMouseX = e.pageX; //mouse x-point

  };

  handleMouseOut(e, idx) {
    // if (document.getElementById("rItem_" + idx) != null) {
    //   document.getElementById("rItem_" + idx).style.opacity = 0;
    // }

    // if (document.getElementById("lItem_" + idx) != null) {
    //   document.getElementById("lItem_" + idx).style.opacity = 0;
    // }
  }

  handleMouseOver(e, idx) {
    // if (document.getElementById("rItem_" + idx) != null) {
    //   document.getElementById("rItem_" + idx).style.opacity = 1;
    // }

    // if (document.getElementById("lItem_" + idx) != null) {
    //   document.getElementById("lItem_" + idx).style.opacity = 1;
    // }
  }

  startResize(e, side) {
    // this.setState({ active: true, x: e.clientX });
    // this.toSide = side;
    // this.oldStartPoint = this.state.startPoint;
    // this.oldWidth = this.state.width;
    // this.ref = this.elementRef.current;
    // const bodyRef = this.elementRef.current.parentElement;

    // this.bodyElementX = bodyRef.getBoundingClientRect().left;

    // const mminStep = 20;
    // this.originalWidth = parseFloat(
    //   getComputedStyle(this.ref, null).getPropertyValue("width").replace("px", "") //current widget width
    // );

    // this.originalElementX = this.ref.getBoundingClientRect().left; //widget start x-point

    // this.originalMouseX = e.pageX; //mouse x-point

  };

  dragMoveFrame(e) {

    // if (this.move_active) {
    //   document.body.style.cursor = "move";

    //   var documentWidth = $('#row_' + this.state.bus_id).width();

    //   var oldX = this.oldPageX;
    //   var currentX = e.screenX;

    //   var calcX = oldX - currentX;

    //   if (this.state.startPoint - calcX < 0) {
    //     this.setState({ startPoint: 0 })
    //   } else {
    //     if (documentWidth < (this.state.startPoint - calcX) + this.state.width) {
    //       this.setState({ startPoint: documentWidth - this.state.width });
    //       this.oldPageX = e.screenX;
    //     } else
    //       this.setState({ startPoint: this.state.startPoint - calcX })
    //     this.oldPageX = e.screenX;
    //   }
    // }
  }

  dragLeaving(e) {
    // this.move_active = false;
  }

  resizeFrame(e, toSide) {
    // const active = this.state.active;
    // const x = this.state.x;
    // if (active) {
    //   if (this.toSide === "right") {
    //     let newWidth = e.pageX - this.ref.getBoundingClientRect().left; //current mouse point -  rect start 

    //     if (newWidth >= 20) {
    //       this.setState({ width: newWidth });
    //     }
    //   } else if (this.toSide === "left") {
    //     let newWidth = this.originalWidth - (e.pageX - this.originalMouseX);

    //     let start_point = (this.originalElementX - (this.originalMouseX - e.pageX) - this.bodyElementX);

    //     if (newWidth > 20 && start_point > -1) {
    //       this.setState({ width: newWidth });
    //       this.setState({ startPoint: start_point })
    //     }
    //   }
    // }
  };

  stopResize(e) {
    // this.setState({ active: false, x: this.state.x });

    // document.body.style.cursor = "auto";

    // let start_ms = Math.round(this.state.startPoint / this.state.unit);
    // let length_ms = Math.round(this.state.width / this.state.unit);

    // var start_date = "";
    // var end_date = "";

    // if (this.state.length_flag == 3) {
    //   start_date = this.state.start_date_time;
    //   end_date = this.state.end_date_time;
    // } else if (this.state.length_flag == 2) {
    //   start_date = this.state.start_date_time;
    //   end_date = moment(this.state.startDate, "YYYY-MM-DD HH:mm:ss").add(length_ms, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    // } else {
    //   start_date = moment(this.state.startDate, "YYYY-MM-DD HH:mm:ss").add(start_ms, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    //   end_date = moment(start_date, "YYYY-MM-DD HH:mm:ss").add(length_ms, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    // }

    // let data = {
    //   userID: this.state.user_id,
    //   id: this.state.sys_schedule_id,
    //   start_date_time: start_date,
    //   end_date_time: end_date,
    //   bus_id: this.state.bus_id
    // };

    // if (this.state.active) {

    //   let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setDriverScheduleEndpoint;
    //   axios
    //     .post(url, data, {
    //       headers: {
    //         Authorization: "Bearer " + useJwt.getToken(),
    //       },
    //     })
    //     .then((response) => {
    //       if (response.status == 200) {
    //         this.setState({ start_date_time: start_date });
    //         this.setState({ end_date_time: end_date });

    //         let temp_cel_data = this.state.cel_data;
    //         temp_cel_data['start_date_time'] = start_date;
    //         temp_cel_data['end_date_time'] = end_date;
    //         this.setState({ cel_data: temp_cel_data });
    //         this.isSave = false;
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 302) {
    //         this.setState({ startPoint: this.oldStartPoint });
    //         this.setState({ width: this.oldWidth });
    //         toast.error("時間が重複しています。 作業時間を再設定してください。登録致しました。", { autoClose: 3000 })
    //       }
    //     });
    // }
  };

  render() {
    return (
      <div className={style.cs_flex} onMouseMove={this.resizeFrame} onDragLeave={this.dragLeaving} onDragOver={this.dragMoveFrame} onMouseUp={this.stopResize} style={{ zIndex: 1 }}>
        {this.props.timeData?.map((time_data, index) => (
          <div className={style.time_line_item} style={{ width: (time_data - 1) + "px", height: "200px" }} key={"time_" + index}></div>
        ))}
        {this.props.busData?.map((sub_data, index) => (
          <CalendarItem timeData={this.props.timeData} itemData={sub_data} startTime={this.props.startTime} shipperId={this.props.shipperId} ></CalendarItem>
        ))}
      </div>
    )
  }
}
