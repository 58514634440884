import React from 'react';
import { useState, useEffect } from 'react';
import style from "./Driver.module.css";
import {Button} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import * as useJwt  from '../../auth/jwt/useJwt.js';

function WorkEnd() {

  let urlParams = useLocation();
  const navigate = useNavigate();

  //style
  const style_header = [style.content_flex_column, style.title, style.content_align_center, style.mt70];
  const style_content = [style.content_flex_column, style.mt30];
  const style_content_but = [style.content_flex_column, style.mt70, style.but_gap, style.content_align_center];

  return (
    <div className={style.content_flex_column}>
      <div className={style_header.join(" ")}>
        <label>{urlParams.state.companyName}予約システム</label>
        <label className={style.sub_title}>{urlParams.state.warehouseName}</label>
      </div>

      <div className={style_content.join(" ")}>
        <label style={{textAlign:'center', fontSize:20}}>お疲れ様でした。</label>
      </div>

    </div>
  );
}

export default WorkEnd;
