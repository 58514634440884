/* eslint-disable import/no-anonymous-default-export */
export default {
  // Endpoints
  backendUrl: 'https://admin.logistock.jp',
  // backendUrl: 'http://127.0.0.1:8000',
  frontendUrl: 'https://logistock.new-challenge.jp',
  warehouseLoginEndpoint: '/api/login/warehouse',
  shipperLoginEndpoint: '/api/login/shipper',
  warehousePasswordForgetEndpoint: '/api/warehouse/forget',
  shipperPasswordForgetEndpoint: '/api/shipper/forget',
  passwordChangeEndpoint: '/api/password/change',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  userDataKeyName: 'userData',
  shipperUserDataKeyName: 'shipperUserData',
  menuPatternDataKeyName: 'menuPatternData',
  googleApiKey: 'AIzaSyAs1hJrgUDKfJ9OvJ16rREoVJifY7Md-IU',

  // backend Endpoints =========================================================
  // 日次業務系
  setArrivalSlipItemsEndpoint: '/api/set-arrival-slip',
  searchArrivalSlipEndpoint: '/api/get-arrival-slip',
  setLeavingSlipItemsEndpoint: '/api/set-leaving-slip',
  searchLeavingSlipEndpoint: '/api/get-leaving-slip',
  setFreightageSlipItemsEndpoint: '/api/set-freightage-slip',
  setDepositsEndpoint: '/api/set-deposits',
  delDepositsEndpoint: '/api/del-deposits',
  setStockMoveSlipItemsEndpoint: '/api/set-stock-moves',
  searchStockMoveSlipEndpoint: '/api/get-stock-move-slip',
  setChangeItemsSlipItemsEndpoint: '/api/set-change-items',
  getArrivalSlipItemsEndpoint: '/api/get-arrival-items',
  getLeavingSlipItemsEndpoint: '/api/get-leaving-items',
  getFreightageSlipItemsEndpoint: '/api/get-freightage-items',
  getDepositsEndpoint: '/api/get-deposit-items',
  getStockMoveSlipItemsEndpoint: '/api/get-stock-move-items',
  getChangeItemsSlipItemsEndpoint: '/api/get-change-items',
  getArrivalSlipItemsBySlipNoEndpoint: '/api/get-arrival-slip-items',
  getPickingListEndpoint: '/api/get-picking-list',
  getMstConsignorFirstRowEndpoint: '/api/get-mst-consignor-first',
  getLeavingItemsEndpoint: '/api/get-leaving-list',
  getInvoiceItemsEndpoint: '/api/get-invoice-items',
  getTagItemsEndpoint: '/api/get-tag-items',
  // 請求管理
  getMonthInvoiceEndpoint: '/api/get-month-invoice',
  getFreightageInvoiceEndpoint: '/api/get-freightage-invoice',
  getMonthInvoiceQtyEndpoint: '/api/get-month-invoice-qty',
  // 在庫管理
  getStockHistoryByItemEndpoint: '/api/get-stock-history',
  getStockListByItemEndpoint: '/api/get-stock-list',
  getStockHistoryByDateEndpoint: '/api/get-stock-history-date',
  getStockDailyReportEndpoing: '/api/get-stock-history-daily',
  getStockListByShipperEndpoint: '/api/get-stock-report',
  getStockListByTypeEndpont: '/api/get-stock-report-type',
  getStockListByWarehouseEndpoint: '/api/get-stock-report-warehouse',
  getStockListByWarehouse2Endpoint: '/api/get-stock-report-warehouse2',
  // 導入時設定
  getMainSettingEndpoint: '/api/get-main-settings',
  setMainSettingEndpoint: '/api/set-main-settings',
  getInvoiceSettingEndpoint: '/api/get-invoice-settings',
  setInvoiceSettingEndpoint: '/api/set-invoice-settings',
  getShipperBalanceSettingsEndpoint: '/api/get-shipper-balance-settings',
  setShipperBalanceSettingsEndpoint: '/api/set-shipper-balance-settings',
  getStockBalanceSettingsEndpoint: '/api/get-stock-balance-settings',
  setStockBalanceSettingsEndpoint: '/api/set-stock-balance-settings',
  getMenuPatternEndpoint: '/api/get-menu-patterns',
  setMenuPatternEndpoint: '/api/set-menu-patterns',
  // マスタ設定
  getMstFreightageEndpoint: '/api/get-mst-freightage',
  setMstFreightageEndpoint: '/api/set-mst-freightage',
  delMstFreightageEndpoint: '/api/del-mst-freightage',
  getMstSummaryEndpoint: '/api/get-mst-summary',
  setMstSummaryEndpoint: '/api/set-mst-summary',
  delMstSummaryEndpoint: '/api/del-mst-summary',
  getMstCategoryEndpoint: '/api/get-mst-category',
  setMstCategoryEndpoint: '/api/set-mst-category',
  delMstCategoryEndpoint: '/api/del-mst-category',
  getMstWarehouseEndpoint: '/api/get-mst-warehouse',
  getShipperWarehouseEndpoint: '/api/shipper/get-mst-warehouse',
  setMstWarehouseEndpoint: '/api/set-mst-warehouse',
  delMstWarehouseEndpoint: '/api/del-mst-warehouse',
  getMstAreaEndpoint: '/api/get-mst-area',
  setMstAreaEndpoint: '/api/set-mst-area',
  delMstAreaEndpoint: '/api/del-mst-area',
  getMstConsignorEndpoint: '/api/get-mst-consignor',
  setMstConsignorEndpoint: '/api/set-mst-consignor',
  delMstConsignorEndpoint: '/api/del-mst-consignor',
  getMstItemEndpoint: '/api/get-mst-item',
  getMstItemStockEndpoint:'/api/get-mst-item-stock',
  getShipperMstItemEndpoint: '/api/shipper/get-mst-item',
  setMstItemEndpoint: '/api/set-mst-item',
  setShipperMstItemEndpoint: '/api/shipper/set-mst-item',
  delMstItemEndpoint: '/api/del-mst-item',
  getMstShipperEndpoint: '/api/get-mst-shipper',
  setMstShipperEndpoint: '/api/set-mst-shipper',
  setMstShipperUserMatchEndpoint: '/api/set-mst-shipper-user-match',
  setMstShipperUserEndpoint: '/api/set-mst-shipper-user',
  setMstShipperTokenSendEndpoint: '/api/send-token-shipper',
  getMstShipperByWarehouseEndpoint: '/api/get-mst-shipper-warehouse',
  // setMstShipperNextEndpoint: '/api/set-mst-shipper-next',
  delMstShipperEndpoint: '/api/del-mst-shipper',
  getMstShipperItemsEndpoint: '/api/get-mst-shipper-items',
  setMstShipperItemsEndpoint: '/api/set-mst-shipper-items',
  delMstShipperItemsEndpoint: '/api/del-mst-shipper-items',
  getMstItemTypeEndpoint: '/api/get-mst-item-type',
  getMstItemTypeByShipperEndpoint: '/api/shipper/get-mst-item-type',
  setMstItemTypeEndpoint: '/api/set-mst-item-type',
  delMstItemTypeEndpoint: '/api/del-mst-item-type',
  getMstRotEndpoint: '/api/get-mst-rot',
  getMstRotStockEndpoint: '/api/get-mst-rot-stock',
  getMstItemStockByShipper: '/api/get-stock-warehouse',
  getShipperMstRotEndpoint: '/api/shipper/get-mst-rot',
  setMstRotEndpoint: '/api/set-mst-rot',
  delMstRotEndpoint: '/api/del-mst-rot',
  getMstRotStandardEndpoint: '/api/get-mst-rot-standard',
  setMstRotStandardEndpoint: '/api/set-mst-rot-standard',
  delMstRotStandardEndpoint: '/api/del-mst-rot-standard',
  getMstRotUnitEndpoint: '/api/get-mst-rot-unit',
  setMstRotUnitEndpoint: '/api/set-mst-rot-unit',
  delMstRotUnitEndpoint: '/api/del-mst-rot-unit',
  getMstDistinationEndpoint: '/api/get-mst-distination',
  setMstDistinationEndpoint: '/api/set-mst-distination',
  delMstDistinationEndpoint: '/api/del-mst-distination',
  getMstCarrierEndpoint: '/api/get-mst-carrier',
  getMstCarrierByDriverEndpoint : '/api/get-driver-carrier',
  getMstShipperCarrierEndpoint: '/api/get-mst-shipper-carrier',
  setMstCarrierEndpoint: '/api/set-mst-carrier',
  delMstCarrierEndpoint: '/api/del-mst-carrier',
  getMstLocationEndpoint: '/api/get-mst-location',
  setMstLocationEndpoint: '/api/set-mst-location',
  delMstLocationEndpoint: '/api/del-mst-location',
  getMstTableEndpoint: '/api/get-mst-table',
  setMstTableEndpoint: '/api/set-mst-table',
  delMstTableEndpoint: '/api/del-mst-table',
  getMstTaxEndpoint: '/api/get-mst-tax',
  getMstTaxByShipperEndpoint: '/api/shipper/get-mst-tax',
  setMstTaxEndpoint: '/api/set-mst-tax',
  setShipperItemCodeEndpoint: '/api/set-shipper-item-code',
  setPriceRevisionEndpoint: '/api/set-price-revision',
  setMstItemsEndpoint: '/api/set-mst-items',
  getMstInvoiceEndpoint: '/api/get-mst-invoice',
  setMstInvoiceEndpoint: '/api/set-mst-invoice',
  delMstInvoiceEndpoint: '/api/del-mst-invoice',

  //入出庫登録
  setScheduleRegisterPoint: '/api/set-schedule',
  getSchedulListWorkerEndPoint: '/api/warehouse-get-scheduleListWorker',
  getInSchedulListTimeEndPoint: '/api/get-inSchedulelistTime',
  getOutSchedulListTimeEndPoint: '/api/get-outSchedulelistTime',

  //native
  getSchedulListWorkerByIdEndPoint: '/api/native/get-schedulelistWorker',


  //user
  getMstWorkerListEndPoint: '/api/get-mst-worker',
  getMstWorkerByIdEndPoint: '/api/get-mst-worker/id',
  getMstUserListEndPoint: '/api/get-mst-users',
  getMstUserEndPoint: '/api/get-mst-user',
  setMstUserEndPoint: '/api/set-mst-user',
  setMstShipperUserEndPoint: '/api/register/shipper/user',
  setMstWarehouseUserEndPoint: '/api/set-mst-warehouse-user',
  getCompanyEndPoint: '/api/get-mst-company',
  getOfficeEndPoint: '/api/get-mst-office',
  getDepartmentEndPoint: '/api/get-mst-department',
  getDefalutWorkerEndPoint: '/api/get-user-workers',
  setDefalutWorkerEndPoint: '/api/set-user-worker',
  delDefalutWorkerEndPoint: '/api/del-user-worker',

  //mst import csv
  importShipperCsv: '/api/csv/shipper',
  importShipperItemCsv: '/api/csv/shipper/item',
  importShipperItemCheckCsv: '/api/csv/shipper/item/check',
  importCarrierCsv: '/api/csv/carrier',
  importCategoryCsv: '/api/csv/category',
  importItemTypeCsv: '/api/csv/itemType',
  importItemCsv: '/api/csv/item',
  totalItemDelete: '/api/item/total/delete',
  importStockItemCsv: '/api/csv/stock/items',
  importShipperItemCodeCsv: '/api/csv/shipperItemCode',
  importConsignorCsv: '/api/csv/consignor',
  importFreightageCsv: '/api/csv/freightage',
  importAreaCsv: '/api/csv/area',
  importSummaryCsv: '/api/csv/summary',
  importDestinationCsv: '/api/csv/destination',
  importPriceDayCsv: '/api/priceDay/csv',
  importPriceItemCsv: '/api/priceItem/csv',
  importItemsCsv: '/api/csv/items',
  importRotCsv: '/api/csv/rot',
  importWarehouseCsv: '/api/csv/warehouse',
  importLocationCsv: '/api/csv/location',
  getInoutCommandWarehouseEndPoint: '/api/get-inout-command-warehouse',

  setMstWarehouseUserEndpoint: '/api/set-mst-warehouse-user',
  getMstWarehouseUserEndpoint: '/api/get-mst-warehouse-user',
  getMstWarehouseUserByShipperEndpoint: '/api/get-mst-warehouse-user-shipper',
  getMstWarehouseShipperEndpoint: '/api/shipper/get-mst-warehouse',

  setWarehouseSubscriptionEndpoint: '/api/set-warehouse-subscription',
  setInoutCommandEndpoint: '/api/set-inout-command',
  getInoutCommandEndpoint: '/api/get-inout-command',
  delInoutCommandEndpoint: '/api/del-inout-command',
  getShipperMenuPatternEndpoint: '/api/get-shipper-menu-patterns',

  getBrokenReportEndPoint: '/api/get-broken-report',

  getScheduleList: '/api/get-schedule-code',
  getSubscriptionList: '/api/get-subscription',
  updateSubscriptionList: '/api/update-subscription',

  //bus setting
  setBusSettingEndpoint : '/api/set-bus-setting',
  getBusSettingEndpoint : '/api/get-bus-setting',
  delBusSettingEndpoint : '/api/del-bus-setting',

  //driver
  setDriverEndpoint : '/api/set-driver',
  getDriverEndpoint : '/api/get-driver',
  getShipperDriverEndpoint : '/api/get-shipper-driver',
  delDriverEndpoint : '/api/del-driver',
  getScheduleListByWarehouseEndpoint : '/api/get-warehouse-schedule',

  //driver schedule
  setDriverScheduleEndpoint : '/api/set-driver-schedule',
  getDriverWarehouseByIdEndpoint : '/api/get-driver-warehouse',
  getDriverScheduleConfirm : '/api/get-driver-schedule-confirm',
  getBusStatusEndpoint : '/api/get-bus-status',
  getDriverScheduleById: '/api/get-driver-schedule-wait',
  setDriverScheduleTime : '/api/set-driver-schedule-time',
  getDriverScheduleNotification : '/api/get-driver-schedule-notification',
  getDriverScheduleByBus : '/api/get-driver-schedule-bus',
  getShipperDriverScheduleByBus : '/api/shipper/get-driver-schedule-bus',
  getInOutCommandByDriver : '/api/get-inout-command-driver',
  getBadgeCount : '/api/get-badge-count',
  getDriverScheduleList : '/api/get-driver-schedule-list',
  deleteDriverSchedule : '/api/delete-driver-schedule',

  //shipper job
  setMstCarrierShipperEndpoint: '/api/set-mst-carrier-shipper',
  getMstCarrierShipperEndpoint: '/api/get-mst-carrier-shipper',
  getShipperCarrierDriverEndpoint : '/api/get-shipper-carrier-driver',
  delShipperDriverEndpoint : '/api/del-shipper-driver',
  getMstWarehouseCompanyEndpoint: '/api/get-mst-warehouse-company',
  getShipperStockTotalEndpoint: '/api/shipper/get-stock-total',

  //管理業務
  getStockByItemType:'/api/get-stock-item-type-month',
  getStockWarehouseMonth:'/api/get-stock-warehouse-month',
  getShipperStockBySlip: '/api/get-stock-shipper-month',
  getShipperStockBySlipDay: '/api/get-stock-shipper-day',
  getWarehouseStockBySlip: '/api/get-in-out-warehouse-month',
  getWarehouseStockBySlipDay: '/api/get-in-out-warehouse-day',

  //stock delete
  deleteStockEndpoint : '/api/stock/delete',

  setShipperContactEmail: '/api/shipper-contact-email',
  delShipperContactEmail: '/api/delete/shipper-contact-email',

  // inout history
  getInoutHistoryEndpoint : '/api/get-inout-history',
}
