
import React from 'react';
import { Button } from 'antd';

import './Modal.css';

const Modal = props => {
    const modal_width = props.width ? props.width : '500px';
    var contentStyle = {
        width: modal_width
    };
    return (
        <div className={`modal ${props.show ? 'show' : ''}`} onClick={props.onClose} style={{ zIndex: 1001 }} >
            <div className="modal-content" onClick={e => e.stopPropagation()} style={contentStyle}>
                <div className="modal-header">
                    <h4 className="modal-title">{props.title}</h4>
                </div>
                <div className="modal-body">{props.children}</div>
                <div className="modal-footer" style={{display:'flex', justifyContent:'end'}}>
                    <Button onClick={props.onClose} type={'primary'} style={{ marginLeft: 30, width: 100, height: 30 }} danger>
                    閉じる
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default Modal;




