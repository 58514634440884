import React from 'react';
import style from "./Driver.module.css";
import gstyle from '../../css/GeneralStyle.module.css';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import * as useJwt  from '../../auth/jwt/useJwt.js';

import {Button} from "antd-mobile";
import ClipLoader from 'react-spinners/ClipLoader';

const DriverWait = (props) => {

  let urlParams = useLocation();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const [bus, setBus] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);

  useEffect(()=>{
    let urlData = urlParams.state.data;
    setBus(urlParams.state.data.bus_name);
    let data = {
      bus_id : urlData.bus_id,
      driver_id : urlData.driver_id,
    }

    csLoading(true);

    let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getBusStatusEndpoint;
    axios
      .post(url, data)
      .then((response) => {
        let data = response.data;
        if(data == "1"){
          setWaiting(false);
        }else{
          setWaiting(true);
          setScheduleData(data);
        }
        setLoaded(true);
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
      });
  }, [props])
  
  const csLoading = (flag) => {
    if(flag) {
        document.getElementById('csLoader').style.display="flex";
    }else{
        document.getElementById('csLoader').style.display="none";
    }
  }
  
  const handleNext = (e) => {
    navigate("/driver/working", {
      state: {
        data : scheduleData,
        companyName: urlParams.state.companyName,
        warehouseName : urlParams.state.warehouseName,
      },
      replace: false,
    });
}

  //style
  const style_header = [style.content_flex_column, style.title, style.content_align_center, style.mt70];
  const style_content = [style.content_flex_column, style.mt30];
  const style_content_but = [style.content_flex_column, style.mt70, style.but_gap, style.content_align_center];

  return (
    <div className={style.content_flex_column}>
      <div className={gstyle.loader_container} style={{display:'flex'}} id="csLoader">
          <ClipLoader color={'#fff'} size={60} />
      </div>
      {
        loaded && 
        <>
          <div className={style_header.join(" ")}>
            <label>{urlParams.state.companyName}予約システム</label>
            <label className={style.sub_title}>{urlParams.state.warehouseName}</label>
          </div>

          <div className={style_content.join(" ")}>
          {
            waiting ?
            <>
              <label style={{textAlign:'center', fontSize:16}}>お待たせ致しました。</label>
              <label style={{textAlign:'center', fontSize:16}}>{bus}番が空きましたので、そちらで<br/>荷下ろしをお願いします。</label>
            </>
            :
            <>
              <label style={{textAlign:'center', fontSize:16}}>倉庫に空きがありません。</label>
              <label style={{textAlign:'center', fontSize:16}}>しばらくお待ちください。</label>
            </>
          }
          </div>

          <div className={style_content_but.join(" ")}>
            {
              waiting && 
              <Button className={style.button} size='small' color='primary' fill='outline' onClick={(e) => handleNext(e)}>次へ</Button>
            }
          </div>
        </>
      }

    </div>
  );
}

export default DriverWait;
