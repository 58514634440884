import { Menu } from "antd";

import style from './Sidebar.module.css';
import './Sidebar.css';
import Logo from './logo.png';
import LogoShort from './logo_short.png';
import { Link } from 'react-router-dom'
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import { useNavigate } from "react-router-dom";

var isFlag = false;
var isFocus = true;
  
const App = ({isSidebarOpen, setIsSidebarOpen}) => {

    const handleClick = () => {
      setIsSidebarOpen(!isSidebarOpen);
      isFlag = isSidebarOpen;
    };
    const navigate = useNavigate();
    const logout = (event) => {
        useJwt.shipperLogout();
        navigate("/login");
    }
    
    const changeFocus = (e) =>{
      e.preventDefault();
      console.log(isFlag)
      if(isFlag == true && isFocus == true){
        setIsSidebarOpen(!isSidebarOpen);
        isFocus = false;
      }
    }
  
    const changeFocusOut = (e) =>{
  
      if(e.clientX < 185)  return ;
      if(isFlag){
        setIsSidebarOpen(!isSidebarOpen);
        isFocus = true;
      }
    }
    function getItem(label, key, icon, children, type) {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }
    const items = [
        getItem(<Link to='/shipper/warehouse/subscription' className={style.link}>倉庫会社申請</Link>, "1", <i className="pointer material-icons">home_work</i>), 
        getItem(<Link to='/shipper/inout/command' className={style.link}>入出庫指示</Link>, "2", <i className="pointer material-icons">inventory</i>), 
        getItem(<Link to='/shipper/stocks-by-warehouse' className={style.link}>倉庫会社別在庫</Link>, "3", <i className="pointer material-icons">factory</i>), 
        getItem(<Link to='/shipper/carrier' className={style.link}>運送業者登録</Link>, "4", <i className="pointer material-icons">local_shipping</i>), 
        getItem(<Link to='/shipper/item' className={style.link}>商品マスタ</Link>, "5", <i className="pointer material-icons">dataset</i>), 
        getItem(<Link to='/shipper/schedule/view' className={style.link}>スケジュール表</Link>, "6", <i className="pointer material-icons">calendar_month</i>), 
        
        getItem(<a onClick={logout}>ログアウト</a>, "7", <i className="pointer material-icons">logout</i>),
    
    ];
      return (
        <div>
        <div className={style.logo_area} style={{ display: 'flex' }}>
        <div style={{display: 'flex', justifyContent:'center', minWidth: 55, alignItems:'center'}} id="logo">
          <img src={LogoShort} className={style.logo_close} alt="logo"/>
        </div>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <img src={Logo} className={style.logo} alt="logo"/>
          </div>
          <div className={isSidebarOpen ? style.left_area : style.left_area_moved}>
            <div className={isSidebarOpen ? style.btn_trigger_clicked : style.btn_trigger} onClick={handleClick}>
              <span className={style.bar1}></span>
              <span className={style.bar2}></span>
              <span className={style.bar3}></span>
            </div>
          </div>
        </div>
        <Menu
          defaultSelectedKeys={["1"]}
          // defaultOpenKeys={["sub1"]}
          mode={"inline"}
          theme="dark"
          inlineCollapsed={!isSidebarOpen}
          items={items}
          onMouseOver={changeFocus}
          onMouseOut={changeFocusOut}
        />
      </div>
      );
  };
  export default App;