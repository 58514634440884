/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './StockListByShipper.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import CategoryModal from '../../../components/common/modal/CategoryModal';
import ItemTypeModal from '../../../components/common/modal/ItemTypeModal';
import StartTableModal from '../../../components/common/modal/TableModal';
import EndTableModal from '../../../components/common/modal/TableModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import { Select } from 'antd';

const StockListByShipper = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);
    const [pdfOpened, setPdfOpened] = useState(false);


    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    // slip date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // date type -----------------------------------------------
    const [DateType, setDateType] = useState(0);
    const handleChangeDateType = (event) => {
        setDateType(event);
    };
    const DateTypeData = [{ value: '0', label: '締日指定' }, { value: '1', label: '日付範囲指定' },];
    // deadline day ---------------------------------------------
    const [DeadLineDay, setDeadLineDay] = useState(useJwt.formatDate(new Date()));
    const handleChangeDeadLineDay = (date) => {
        setDeadLineDay(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartSlipDate, setStartSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartSlipDate = (date) => {
        setStartSlipDate(useJwt.formatDate(date));
    };
    const [EndSlipDate, setEndSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndSlipDate = (date) => {
        setEndSlipDate(useJwt.formatDate(date));
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
    };
    const OrderTypeData = [{ value: '0', label: '品番順' }, { value: '1', label: '商品分類①' },
    { value: '2', label: '商品分類②' }, { value: '3', label: '商品分類③' }, { value: '4', label: '品種順' }];
    // category --------------------------------------------------------
    const [CategoryCode1, setCategoryCode1] = useState("");
    const [CategoryName1, setCategoryName1] = useState("");
    const handleChangeCategoryCode1 = (event) => {
        setCategoryCode1(useJwt.validateNum(event.target.value, 3));
    };
    const [CategoryCode2, setCategoryCode2] = useState("");
    const [CategoryName2, setCategoryName2] = useState("");
    const handleChangeCategoryCode2 = (event) => {
        setCategoryCode2(useJwt.validateNum(event.target.value, 3));
    };
    const onKeyDownCategoryCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getCategoryData(event.target.value, param);
            event.preventDefault();

            if (param == 1) {
                document.getElementById('category_code2').focus();
            } else {
                document.getElementById('item_type_code1').focus();
            }
        }
    };
    const getCategoryData = (code, param) => {
        if (code != "") {
            const data_r = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
            axios
                .post(url, data_r, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    let t_data = response.data;
                    if (t_data.length > 0) {
                        setCategoryData(t_data[0], param);
                    } else {
                        setCategoryData([], param);
                    }
                })
                .catch((error) => {
                });
        } else {
            setCategoryData([], param);
        }
    }
    const setCategoryData = (data, param) => {
        if (data['category_code']) {
            if (param == 1) {
                setCategoryCode1(data['category_code']);
                setCategoryName1(data['category_name']);
            } else {
                setCategoryCode2(data['category_code']);
                setCategoryName2(data['category_name']);
            }
        } else {
            if (param == 1) {
                setCategoryCode1("");
                setCategoryName1("");
            } else {
                setCategoryCode2("");
                setCategoryName2("");
            }
        }
    }
    // item type ---------------------------------------------------
    const [ItemTypeCode1, setItemTypeCode1] = useState("");
    const handleChangeItemTypeCode1 = (event) => {
        setItemTypeCode1(useJwt.validateNum(event.target.value, 3));
    };
    const [ItemTypeName1, setItemTypeName1] = useState("");
    const [ItemTypeCode2, setItemTypeCode2] = useState("");
    const handleChangeItemTypeCode2 = (event) => {
        setItemTypeCode2(useJwt.validateNum(event.target.value, 3));
    };
    const [ItemTypeName2, setItemTypeName2] = useState("");
    const onKeyDownItemTypeCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getItemTypeData(event.target.value, param);
            event.preventDefault();

            if (param == 1) {
                document.getElementById('item_type_code2').focus();
            } else {
                document.getElementById('type3').focus();
            }
        }
    };
    const getItemTypeData = (code, param) => {
        if (code != "") {
            const data_r = {
                userID: userData['id'],
                item_type_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
                .post(url, data_r, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    let t_data = response.data.data;
                    if (t_data.length > 0) {
                        setItemTypeData(t_data[0], param);
                    } else {
                        setItemTypeData([], param);
                    }
                })
                .catch((error) => {
                });
        } else {
            setItemTypeData([], param);
        }
    }
    const setItemTypeData = (data, param) => {
        if (data['item_type_code']) {
            if (param == 1) {
                setItemTypeCode1(data['item_type_code']);
                setItemTypeName1(data['item_type_name']);
            } else {
                setItemTypeCode2(data['item_type_code']);
                setItemTypeName2(data['item_type_name']);
            }
        } else {
            if (param == 1) {
                setItemTypeCode1("");
                setItemTypeName1("");
            } else {
                setItemTypeCode2("");
                setItemTypeName2("");
            }
        }
    }
    // warehouse type -----------------------------------------------
    const [WarehouseType, setWarehouseType] = useState(0);
    const handleChangeWarehouseType = (event) => {
        setWarehouseType(event);
    };
    const WarehouseTypeData = [{ value: '0', label: '全倉庫' }, { value: '1', label: '倉庫別' }];
    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value, 2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value, 2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value, param);
            event.preventDefault();

            if (param == 1) {
                document.getElementById('warehouse_code2').focus();
            } else {
                document.getElementById('report1').focus();
            }
        }
    };
    const getWarehouseData = (code, param) => {
        if (code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWarehouseData(result, param);
                        } else {
                            setWarehouseData([], param);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setWarehouseData([], param);
        }
    }
    const setWarehouseData = (data, param) => {
        if (data['warehouse_code']) {
            if (param == 1) {
                setWarehouseCode1(data['warehouse_code']);
                setWarehouseName1(data['warehouse_name']);
            } else {
                setWarehouseCode2(data['warehouse_code']);
                setWarehouseName2(data['warehouse_name']);
            }
        } else {
            if (param == 1) {
                setWarehouseCode1("");
                setWarehouseName1("");
            } else {
                setWarehouseCode2("");
                setWarehouseName2("");
            }
        }
    }
    // location ------------------------------------------------
    const [LocationNo1, setLocationNo1] = useState("");
    const handleChangeLocationNo1 = (event) => {
        setLocationNo1(event.target.value);
    };
    const [LocationData1, setLocationData1] = useState([]);
    const [LocationNo2, setLocationNo2] = useState("");
    const handleChangeLocationNo2 = (event) => {
        setLocationNo2(event.target.value);
    };
    const [LocationData2, setLocationData2] = useState([]);

    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value, 6));
        setItemData([], 1);
        setItemData([], 2);
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value, 3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value, 6));
        setItemData([], 1);
        setItemData([], 2);
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value, 3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event, param1, param2) => {
        if (event.keyCode === 13) { // enter key
            getShipperData(event.target.value, param1, param2);
            event.preventDefault();

            if (param1 == 1) {
                if (param2 == 1) {
                    document.getElementById('shipper_a_code1').focus();
                } else {
                    document.getElementById('shipper_code2').focus();
                }
            } else {
                if (param2 == 1) {
                    document.getElementById('shipper_a_code2').focus();
                } else {
                    if (document.getElementById('item_code1')) {
                        document.getElementById('item_code1').focus();
                    } else {
                        if (document.getElementById('category_code1')) {
                            document.getElementById('category_code1').focus();
                        } else {
                            document.getElementById('item_type_code1').focus();
                        }
                    }
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if (param1 == 1) {
            if (param2 == 1) {
                code = value;
                if (ShipperACode1 != "") {
                    acode = ShipperACode1;
                } else {
                    acode = "000";
                }
            } else {
                code = ShipperCode1;
                if (value != "") {
                    acode = value;
                } else {
                    acode = "000";
                }
            }
        } else {
            if (param2 == 1) {
                code = value;
                if (ShipperACode2 != "") {
                    acode = ShipperACode2;
                } else {
                    acode = "000";
                }
            } else {
                code = ShipperCode2;
                if (value != "") {
                    acode = value;
                } else {
                    acode = "000";
                }
            }
        }


        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if (acode != "") {
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                }).then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setShipperData(result, param1);
                        } else {
                            setShipperData([], param1);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setShipperData([], param1);
        }
    }
    const setShipperData = (data, param) => {
        if (data['shipper_code'] != null) {
            if (param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            } else {
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        } else {
            if (param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            } else {
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // item ----------------------------------------------------
    const [ItemCode1, setItemCode1] = useState("");
    const handleChangeItemCode1 = (event) => {
        setItemCode1(useJwt.validateString(event.target.value, 15));
    };
    const [ItemACode1, setItemACode1] = useState("");
    const handleChangeItemACode1 = (event) => {
        setItemACode1(useJwt.validateNum(event.target.value, 3));
    };
    const [ItemName1, setItemName1] = useState("");
    const [ItemCode2, setItemCode2] = useState("");
    const handleChangeItemCode2 = (event) => {
        setItemCode2(useJwt.validateString(event.target.value, 15));
    };
    const [ItemACode2, setItemACode2] = useState("");
    const handleChangeItemACode2 = (event) => {
        setItemACode2(useJwt.validateNum(event.target.value, 3));
    };
    const [ItemName2, setItemName2] = useState("");
    const onKeyDownItemCode = (event, param1, param2) => {
        if (event.keyCode === 13) { // enter key
            getItemData(event.target.value, param1, param2);
            event.preventDefault();

            if (param1 == 1) {
                if (param2 == 1) {
                    document.getElementById('item_a_code1').focus();
                } else {
                    document.getElementById('item_code2').focus();
                }
            } else {
                if (param2 == 1) {
                    document.getElementById('item_a_code2').focus();
                } else {
                    document.getElementById('category_code1').focus();
                }
            }
        }
    };
    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if (param1 == 1) {
            if (param2 == 1) {
                code = value;
                acode = ItemACode1;
            } else {
                code = ItemCode1;
                acode = value;
            }
        } else {
            if (param2 == 1) {
                code = value;
                acode = ItemACode2;
            } else {
                code = ItemCode2;
                acode = value;
            }
        }

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if (acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setItemData(result, param1);
                        } else {
                            setItemData([], param1);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setItemData([], param1);
        }
    }
    const setItemData = (data, param) => {
        if (data['item_code']) {
            if (param == 1) {
                setItemCode1(data['item_code']);
                setItemACode1(data['item_a_code']);
                setItemName1(data['item_name']);
            } else {
                setItemCode2(data['item_code']);
                setItemACode2(data['item_a_code']);
                setItemName2(data['item_name']);
            }
        } else {
            if (param == 1) {
                setItemCode1("");
                setItemACode1("");
                setItemName1("");
            } else {
                setItemCode2("");
                setItemACode2("");
                setItemName2("");
            }
        }
    }
    // ReportLabel -----------------------------------
    const [ReportLabel1, setReportLabel1] = useState("貴社製品を下記の通り");
    const handleChangeReportLabel1 = (event) => {
        setReportLabel1(useJwt.validateString(event.target.value, 50));
    };
    const [ReportLabel2, setReportLabel2] = useState("保管していることを報告致します");
    const handleChangeReportLabel2 = (event) => {
        setReportLabel2(useJwt.validateString(event.target.value, 50));
    };
    // sub code type -----------------------------------------------
    const [SubCodeType, setSubCodeType] = useState(0);
    const handleChangeSubCodeType = (event) => {
        setSubCodeType(event);
    };
    // rot type -----------------------------------------------
    const [RotType, setRotType] = useState(0);
    const handleChangeRotType = (event) => {
        setRotType(event);
    };
    // filter type -----------------------------------------------
    const [FilterType, setFilterType] = useState(0);
    const handleChangeFilterType = (event) => {
        setFilterType(event);
    };
    // item order type -----------------------------------------------
    const [ItemOrderType, setItemOrderType] = useState(0);
    const handleChangeItemOrderType = (event) => {
        setItemOrderType(event);
    };
    // item print type -----------------------------------------------
    const [ItemPrintType, setItemPrintType] = useState(0);
    const handleChangeItemPrintType = (event) => {
        setItemPrintType(event);
    };
    // self company info ---------------------------------------------
    const [ConsignorName, setConsignorName] = useState("");
    const [ConsignorZip, setConsignorZip] = useState("");
    const [ConsignorAddress1, setConsignorAddress1] = useState("");
    const [ConsignorAddress2, setConsignorAddress2] = useState("");
    const [ConsignorAddress3, setConsignorAddress3] = useState("");
    const [ConsignorTel, setConsignorTel] = useState("");
    const [ConsignorFax, setConsignorFax] = useState("");
    // =================================================================================

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);
            if (menuPatternData['menu_stock_flag_5'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            const data = {
                userID: userData['id'],
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMainSettingEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        var address = response.data.data['distination_address_1'] + response.data.data['distination_address_2'] + response.data.data['distination_address_3']
                        setConsignorName(response.data.data['company_name']);
                        setConsignorZip(response.data.data['distination_zip']);
                        setConsignorAddress1(address);
                        setConsignorAddress2(response.data.data['distination_address_2']);
                        setConsignorAddress3(response.data.data['distination_address_3']);
                        setConsignorTel(response.data.data['distination_tel']);
                        setConsignorFax(response.data.data['distination_fax']);
                    }
                })
                .catch((error) => {
                });
        }
    });
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();

        let data = {};
        data['userID'] = userData['id'];
        data['end_slip_date'] = EndSlipDate;
        data['output_order'] = OrderType;
        data['filter_warehouse'] = WarehouseType;
        if (WarehouseType == 1) {
            data['start_warehouse_code'] = WarehouseCode1;
            data['end_warehouse_code'] = WarehouseCode2;
        }
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        data['start_item_code'] = ItemCode1;
        data['end_item_code'] = ItemCode2;
        data['start_item_a_code'] = ItemACode1;
        data['end_item_a_code'] = ItemACode2;
        if (LocationNo1 != "") {
            data['start_table_code'] = LocationNo1;
        }
        if (LocationNo2 != "") {
            data['end_table_code'] = LocationNo2;
        }
        data['start_category_code'] = CategoryCode1;
        data['end_category_code'] = CategoryCode2;
        data['start_item_type_code'] = ItemTypeCode1;
        data['end_item_type_code'] = ItemTypeCode2;
        data['sub_code_type'] = SubCodeType;
        data['rot_type'] = RotType;
        data['item_order_type'] = ItemOrderType;

        console.log('filter', data);
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getStockListByShipperEndpoint;
        csloading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let result = response.data.data;
                // console.log(result);
                if (result.length > 0) {
                    gridMainTable(result);
                } else {
                    toast.error("検索結果はありません。", { autoClose: 3000 });
                    gridMainTable([]);
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });

    }
    const CloseSearchWidow = () => {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);
    const [pdfTableData, setPdfTableData] = useState([]);

    const gridMainTable = (result) => {

        let data = [];
        let t_data = [];
        let pdf_data = [];
        let itemCodes = [];
        let header_info = [];

        if (WarehouseType > 0) {
            let start = 0;
            let end = 0;
            if (WarehouseCode1 != "") start = parseInt(WarehouseCode1);
            if (WarehouseCode2 != "") {
                end = parseInt(WarehouseCode2);
            } else {
                end = 100;
            }

            for (var w = start; w <= end; w++) {

                for (var i = 0; i < result.length; i++) {
                    if (result[i]['warehouse_code'] == w || result[i]['warehouse_code'] == '0' + w) {

                        let filter_code = '' + result[i]['shipper_code'] + '-' + result[i]['warehouse_code'];

                        if (!itemCodes.includes(filter_code)) {
                            itemCodes.push(filter_code);
                            let row = {};
                            row['shipper_code'] = result[i]['shipper_code'];
                            row['shipper_a_code'] = result[i]['shipper_a_code'];
                            row['shipper_name'] = result[i]['shipper_name'];
                            row['shipper_honor'] = result[i]['shipper_honor'];
                            row['shipper_address_1'] = result[i]['shipper_address_1'];
                            row['shipper_address_2'] = result[i]['shipper_address_2'];
                            row['shipper_address_3'] = result[i]['shipper_address_3'];
                            row['shipper_zip'] = result[i]['shipper_zip'];
                            row['shipper_tel'] = result[i]['shipper_tel'];
                            row['shipper_fax'] = result[i]['shipper_fax'];
                            row['start_date'] = result[i]['start_date'];
                            row['end_date'] = result[i]['end_date'];
                            row['warehouse_code'] = result[i]['warehouse_code'];
                            row['warehouse_name'] = result[i]['warehouse_name'];
                            header_info.push(row);
                        }
                    }
                }
            }
            for (var i = 0; i < itemCodes.length; i++) {
                let row = {};
                row['filter_code'] = itemCodes[i];
                row['header_info'] = header_info[i];
                row['item_info'] = [];
                for (var j = 0; j < result.length; j++) {
                    let filter_code = '' + result[j]['shipper_code'] + '-' + result[j]['warehouse_code'];
                    if (itemCodes[i] == filter_code) {
                        let tmp_row = result[j];
                        row['item_info'].push(tmp_row);
                    }
                }
                data.push(row);
            }

            for (var i = 0; i < data.length; i++) {
                let row = {};
                row['filter_code'] = data[i]['filter_code'];
                row['header_info'] = data[i]['header_info'];
                row['item_info'] = [];
                row['csv_item_info'] = [];

                let total_label = "【 合 計 】";
                let total_num = 0;
                let total_volume = 0;
                let total_weight = 0;

                let tempInfo = [];
                let csvTempInfo = [];
                for (var j = 0; j < data[i]['item_info'].length; j++) {
                    total_num += (1 * data[i]['item_info'][j]['stock_ary']['stockNum']);
                    total_volume += (1 * data[i]['item_info'][j]['stock_ary']['stockVolume']);
                    total_weight += (1 * data[i]['item_info'][j]['stock_ary']['stockWeight']);

                    let tmp_row = data[i]['item_info'][j];
                    let csvTemp = {};
                    csvTemp['品番'] = data[i]['item_info'][j]['item_code']
                    csvTemp['補助'] = data[i]['item_info'][j]['item_a_code']
                    csvTemp['品名'] = data[i]['item_info'][j]['item_name']
                    csvTemp['入数'] = data[i]['item_info'][j]['item_quantity']
                    csvTemp['単位重量'] = data[i]['item_info'][j]['unit_weight']
                    csvTemp['単位容積'] = data[i]['item_info'][j]['unit_volume']
                    csvTemp['数量'] = data[i]['item_info'][j]['stock_ary']['stockNum']
                    csvTemp['単位'] = data[i]['item_info'][j]['item_unit']
                    csvTemp['在庫重量'] = data[i]['item_info'][j]['stock_ary']['stockWeight']
                    csvTemp['数量'] = data[i]['item_info'][j]['stock_ary']['stockVolume']
                    csvTempInfo.push(csvTemp);

                    tmp_row['total_flg'] = 0;
                    tmp_row['total_label'] = "";
                    row['item_info'].push(tmp_row);
                    tempInfo.push(tmp_row)

                    if (j % 15 == 0 && j > 0) {
                        let temp = row;

                        let buff_temp = {
                            ...temp,
                            pdf_info: tempInfo,
                        }

                        pdf_data.push(buff_temp);
                        tempInfo = [];
                    }
                }
                row['pdf_info'] = tempInfo;
                row['csv_item_info'] = csvTempInfo;

                row['total_label'] = total_label;
                row['stockNum'] = total_num;
                row['stockVolume'] = total_volume;
                row['stockWeight'] = total_weight;
                t_data.push(row);
                pdf_data.push(row);
            }
        } else {
            for (var i = 0; i < result.length; i++) {
                let filter_code = '' + result[i]['shipper_code'];
                // filter_code += '-'+result[i]['shipper_a_code'];
                // filter_code += '-'+result[i]['item_code']+'-'+result[i]['item_a_code'];
                // if(WarehouseType>0) {
                //     filter_code += '-'+result[i]['warehouse_code'];
                // }
                // if(RotType>0) {
                //     filter_code += '-'+result[i]['rot_code'];
                // }
                if (!itemCodes.includes(filter_code)) {
                    itemCodes.push(filter_code);
                    let row = {};
                    row['shipper_code'] = result[i]['shipper_code'];
                    row['shipper_a_code'] = result[i]['shipper_a_code'];
                    row['shipper_name'] = result[i]['shipper_name'];
                    row['shipper_honor'] = result[i]['shipper_honor'];
                    row['shipper_address_1'] = result[i]['shipper_address_1'];
                    row['shipper_address_2'] = result[i]['shipper_address_2'];
                    row['shipper_address_3'] = result[i]['shipper_address_3'];
                    row['shipper_address'] = result[i]['shipper_address_1'] + (result[i]['shipper_address_2'] ? result[i]['shipper_address_2'] : '') + (result[i]['shipper_address_3'] ? result[i]['shipper_address_3'] : '')
                    row['shipper_zip'] = result[i]['shipper_zip'];
                    row['shipper_tel'] = result[i]['shipper_tel'];
                    row['shipper_fax'] = result[i]['shipper_fax'];
                    row['start_date'] = result[i]['start_date'];
                    row['end_date'] = result[i]['end_date'];
                    row['warehouse_code'] = result[i]['warehouse_code'];
                    header_info.push(row);
                }
            }
            for (var i = 0; i < itemCodes.length; i++) {
                let row = {};
                row['filter_code'] = itemCodes[i];
                row['header_info'] = header_info[i];
                row['item_info'] = [];
                for (var j = 0; j < result.length; j++) {
                    let filter_code = '' + result[j]['shipper_code'];
                    // filter_code += '-'+result[j]['shipper_a_code'];
                    // filter_code += '-'+result[j]['item_code']+'-'+result[j]['item_a_code'];
                    // if(WarehouseType>0) {
                    //     filter_code += '-'+result[j]['warehouse_code'];
                    // }
                    // if(RotType>0) {
                    //     filter_code += '-'+result[j]['rot_code'];
                    // }
                    if (itemCodes[i] == filter_code) {
                        let tmp_row = result[j];
                        row['item_info'].push(tmp_row);
                    }
                }
                data.push(row);
            }

            for (var i = 0; i < data.length; i++) {
                let row = {};
                row['filter_code'] = data[i]['filter_code'];
                row['header_info'] = data[i]['header_info'];
                row['item_info'] = [];
                let order_code = "";
                let sub_total_num = 0;
                let sub_total_volume = 0;
                let sub_total_weight = 0;
                let sub_total_label = "";
                let total_label = "【 合 計 】";
                let total_num = 0;
                let total_volume = 0;
                let total_weight = 0;
                if (OrderType == 1) {
                    order_code = data[i]['item_info'][0]['category_code'];
                    sub_total_label = "【分 類 計】";
                } else if (OrderType == 2) {
                    order_code = data[i]['item_info'][0]['category_code_2'];
                    sub_total_label = "【分 類 計】";
                } else if (OrderType == 3) {
                    order_code = data[i]['item_info'][0]['category_code_3'];
                    sub_total_label = "【分 類 計】";
                } else if (OrderType == 4) {
                    order_code = data[i]['item_info'][0]['item_type_code'];
                    sub_total_label = "【品 種 計】";
                }
                for (var j = 0; j < data[i]['item_info'].length; j++) {
                    if (OrderType > 0) {
                        if (j > 0) {
                            let t_order_code = "";
                            if (OrderType == 1) {
                                t_order_code = data[i]['item_info'][j]['category_code'];
                            } else if (OrderType == 2) {
                                t_order_code = data[i]['item_info'][j]['category_code_2'];
                            } else if (OrderType == 3) {
                                t_order_code = data[i]['item_info'][j]['category_code_3'];
                            } else if (OrderType == 4) {
                                t_order_code = data[i]['item_info'][j]['item_type_code'];
                            }
                            if (order_code != "" && order_code != t_order_code) {
                                let sub_total_row = {};
                                sub_total_row['total_flg'] = 1;
                                sub_total_row['total_label'] = sub_total_label;
                                sub_total_row['stockNum'] = sub_total_num;
                                sub_total_row['stockVolume'] = sub_total_volume;
                                sub_total_row['stockWeight'] = sub_total_weight;
                                sub_total_num = 0;
                                sub_total_volume = 0;
                                sub_total_weight = 0;
                                row['item_info'].push(sub_total_row);
                            }
                        }
                        sub_total_num += (1 * data[i]['item_info'][j]['stock_ary']['stockNum']);
                        sub_total_volume += (1 * data[i]['item_info'][j]['stock_ary']['stockVolume']);
                        sub_total_weight += (1 * data[i]['item_info'][j]['stock_ary']['stockWeight']);
                    }

                    total_num += (1 * data[i]['item_info'][j]['stock_ary']['stockNum']);
                    total_volume += (1 * data[i]['item_info'][j]['stock_ary']['stockVolume']);
                    total_weight += (1 * data[i]['item_info'][j]['stock_ary']['stockWeight']);
                    if (OrderType == 1) {
                        order_code = data[i]['item_info'][j]['category_code'];
                    }

                    let tmp_row = data[i]['item_info'][j];
                    tmp_row['total_flg'] = 0;
                    tmp_row['total_label'] = "";
                    row['item_info'].push(tmp_row);

                    if (OrderType > 0) {
                        if (j == (data[i]['item_info'].length - 1)) {
                            let sub_total_row = {};
                            sub_total_row['total_flg'] = 1;
                            sub_total_row['total_label'] = sub_total_label;
                            sub_total_row['stockNum'] = sub_total_num;
                            sub_total_row['stockVolume'] = sub_total_volume;
                            sub_total_row['stockWeight'] = sub_total_weight;
                            sub_total_num = 0;
                            sub_total_volume = 0;
                            sub_total_weight = 0;
                            row['item_info'].push(sub_total_row);
                        }
                    }
                }
                row['total_label'] = total_label;
                row['stockNum'] = total_num;
                row['stockVolume'] = total_volume;
                row['stockWeight'] = total_weight;
                t_data.push(row);
            }

            for (var i = 0; i < t_data.length; i++) {
                let row = {};
                row['header_info'] = t_data[i]['header_info'];
                row['item_info'] = [];
                row['csv_item_info'] = [];

                let tempInfo = [];
                let csvTempInfo = [];
                for (var j = 0; j < t_data[i]['item_info'].length; j++) {
                    tempInfo.push(t_data[i]['item_info'][j])
                    let csvTemp = {};
                    csvTemp['品番'] = t_data[i]['item_info'][j]['item_code']
                    csvTemp['補助'] = t_data[i]['item_info'][j]['item_a_code']
                    csvTemp['品名'] = t_data[i]['item_info'][j]['item_name']
                    csvTemp['入数'] = t_data[i]['item_info'][j]['item_quantity']
                    csvTemp['単位重量'] = t_data[i]['item_info'][j]['unit_weight']
                    csvTemp['単位容積'] = t_data[i]['item_info'][j]['unit_volume']
                    csvTemp['数量'] = t_data[i]['item_info'][j]['stock_ary']['stockNum']
                    csvTemp['単位'] = t_data[i]['item_info'][j]['item_unit']
                    csvTemp['在庫重量'] = t_data[i]['item_info'][j]['stock_ary']['stockWeight']
                    csvTemp['在庫容積'] = t_data[i]['item_info'][j]['stock_ary']['stockVolume']
                    csvTempInfo.push(csvTemp);

                    if (j % 15 == 0 && j > 0) {
                        let temp = row;

                        let buff_temp = {
                            ...temp,
                            pdf_info: tempInfo,
                        }

                        pdf_data.push(buff_temp);
                        tempInfo = [];
                    }
                }
                row['pdf_info'] = tempInfo;
                t_data[i]['csv_item_info'] = csvTempInfo;

                row['total_label'] = t_data[i]['total_label'];
                row['stockNum'] = t_data[i]['stockNum'];
                row['stockVolume'] = t_data[i]['stockVolume'];
                row['stockWeight'] = t_data[i]['stockWeight'];
                pdf_data.push(row);
            }
        }
        console.log('aaa = ', t_data)
        setMainTableData(t_data);
        setPdfTableData(pdf_data);
        if (t_data.length > 0) {
            setOpend(true);
        }
    }
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------- Category Modal ------------------------------------------
    const [showModalCategory, setShowModalCategory] = useState(false);
    const [CategorySearchValue, setCategorySearchValue] = useState("");
    const [CategoryTableData, setCategoryTableData] = useState([]);
    const [mstType, setMstType] = useState(2);
    const [categoryType, setCategoryType] = useState(0);
    const loadCategoryCodeModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = CategoryCode1;
        if (param == 2) {
            code = CategoryCode2;
        }

        if (OrderType == 1) setCategoryType(0);
        else if (OrderType == 2) setCategoryType(1);
        else setCategoryType(2);

        setCategorySearchValue(code);
        if (code != "") {
            const data = {
                userID: userData['id'],
                category_code: code,
                mst_type: 2,
                category_type: OrderType - 1,
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setCategoryTableData(response.data);
                    setShowModalCategory(true);
                })
                .catch((error) => {
                });
        } else {
            setCategoryTableData([]);
            setShowModalCategory(true);
        }
    }
    // ----------------------------- item type modal -----------------------------------
    const [showModalItemType, setShowModalItemType] = useState(false);
    const [ItemTypeSearchValue, setItemTypeSearchValue] = useState("");
    const [ItemTypeTableData, setItemTypeTableData] = useState([]);
    const loadItemTypeModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemTypeCode1;
        if (param == 2) {
            code = ItemTypeCode2;
        }
        setItemTypeSearchValue(code);

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            if (code != "") {
                data['item_type_code'] = code;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setItemTypeTableData(response.data.data);
                    setShowModalItemType(true);
                })
                .catch((error) => {
                });
        } else {
            setItemTypeTableData([]);
            setShowModalItemType(true);
        }
    }
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);

    const loadWarehouseModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode1;
        if (param == 2) {
            code = WarehouseCode2;
        }
        setWarehouseSearchValue(code);

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            if (code != "") {
                data['warehouse_code'] = code;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWarehouseTableData(response.data.data);
                    setShowModalWarehouse(true);
                })
                .catch((error) => {
                });
        } else {
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if (param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setShipperTableData(response.data.data);
                    setShowModalShipper(true);
                })
                .catch((error) => {
                });
        } else {
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemCode1;
        let acode = ItemACode1;
        if (param == 2) {
            code = ItemCode2;
            acode = ItemACode2;
        }
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode1);
        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode1;
            data['item_code'] = code;
            if (acode != "") {
                data['item_a_code'] = acode;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setItemTableData(response.data.data);
                    setShowModalItem(true);
                })
                .catch((error) => {
                });
        } else {
            setItemTableData([]);
            setShowModalItem(true);
        }
    }

    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if (text === "New, Updated Text!" && typeof onBeforeGetContentResolve.current === "function") {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);


    // -----------------------------start Table modal -------------------------------------
    const [showModalStartTable, setShowModalStartTable] = useState(false);
    const [startTableSearchValue, setStartTableSearchValue] = useState("");
    const [startTableSearchValue2, setStartTableSearchValue2] = useState("");
    const [startTableData, setStartTableData] = useState([]);
    const loadStartTableModalData = (event, idx, table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value = idx;
        var warehouse_code = WarehouseCode1;

        if (warehouse_code == "") {
            toast.error("倉庫コードを入力してください。", { autoClose: 3000 });
            return;
        }
        setStartTableSearchValue(table_code);
        setStartTableSearchValue2(warehouse_code);

        if (warehouse_code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if (table_code != "") data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstTableEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setStartTableData(response.data.data);
                    setShowModalStartTable(true);
                })
                .catch((error) => {
                });
        } else {
            setStartTableData([]);
            setShowModalStartTable(true);
        }
    }
    const setStartTableCode = (data) => {

        setLocationData1(data);
        setLocationNo1(data['table_code']);
    }

    // -----------------------------end Table modal -------------------------------------
    const [showModalEndTable, setShowModalEndTable] = useState(false);
    const [endTableSearchValue, setEndTableSearchValue] = useState("");
    const [endTableSearchValue2, setEndTableSearchValue2] = useState("");
    const [endTableData, setEndTableData] = useState([]);
    const loadEndTableModalData = (event, idx, table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value = idx;
        var warehouse_code = WarehouseCode1;

        if (warehouse_code == "") {
            toast.error("倉庫コードを入力してください。", { autoClose: 3000 });
            return;
        }
        setEndTableSearchValue(table_code);
        setEndTableSearchValue2(warehouse_code);

        if (warehouse_code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if (table_code != "") data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstTableEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setEndTableData(response.data.data);
                    setShowModalEndTable(true);
                })
                .catch((error) => {
                });
        } else {
            setEndTableData([]);
            setShowModalEndTable(true);
        }
    }
    const setEndTableCode = (data) => {
        setLocationData2(data);
        setLocationNo2(data['table_code']);
    }


    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);
    const [type5Open, setType5Open] = useState(false);
    const [type6Open, setType6Open] = useState(false);
    const [type7Open, setType7Open] = useState(false);
    const [type8Open, setType8Open] = useState(false);

    const onType1KeyDown = (event) => {
        if (event.keyCode == 13) {
            setTimeout(() => {
                if (document.getElementById('date2')) {
                    document.getElementById('date2').focus();
                } else {
                    document.getElementById('date3').focus();
                }
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if (type == 1) {
            setType1Open(true);
        } else {
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('shipper_code1').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if (type == 1) {
            setType2Open(true);
        } else {
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) => {
        if (event.keyCode == 13) {
            setTimeout(() => {
                if (document.getElementById('warehouse_code1')) {
                    document.getElementById('warehouse_code1').focus();
                } else {
                    document.getElementById('report1').focus();
                }
            })
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if (type == 1) {
            setType3Open(true);
        } else {
            setType3Open(false);
        }
    }

    const onType4KeyDown = (event) => {
        if (event.keyCode == 13) {
            setTimeout(() => {
                if (document.getElementById('type5'))
                    document.getElementById('type5').focus();
                else
                    document.getElementById('type6').focus();
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType4 = (e, type) => {
        if (type == 1) {
            setType4Open(true);
        } else {
            setType4Open(false);
        }
    }

    const onType5KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type6').focus();
            event.preventDefault();
        }
    }
    const onFocusType5 = (e, type) => {
        if (type == 1) {
            setType5Open(true);
        } else {
            setType5Open(false);
        }
    }

    const onType6KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type7').focus();
            event.preventDefault();
        }
    }
    const onFocusType6 = (e, type) => {
        if (type == 1) {
            setType6Open(true);
        } else {
            setType6Open(false);
        }
    }

    const onType7KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type8').focus();
            event.preventDefault();
        }
    }
    const onFocusType7 = (e, type) => {
        if (type == 1) {
            setType7Open(true);
        } else {
            setType7Open(false);
        }
    }

    const onType8KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType8 = (e, type) => {
        if (type == 1) {
            setType8Open(true);
        } else {
            setType8Open(false);
        }
    }

    const onReportKeyDown = (e, param) => {
        if (e.keyCode == 13) {
            if (param == 1) {
                document.getElementById('report2').focus();
            } else {
                document.getElementById('type4').focus();
            }
        }
    }

    const onSelectData = (e, index) => {
        document.getElementById('focus').focus();

        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            case 4:
                setType4Open(false);
                break;
            case 5:
                setType5Open(false);
                break;
            case 6:
                setType6Open(false);
                break;
            case 7:
                setType7Open(false);
                break;
            case 8:
                setType8Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_1 = [gstyle.cs_col, gstyle.cs_col_1];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_245px = [gstyle.cs_input, gstyle.w245px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <CategoryModal show={showModalCategory} onClose={() => setShowModalCategory(false)} width={'70%'}
                SearchValue={CategorySearchValue} onChange={setCategorySearchValue}
                tableData={CategoryTableData} setTableData={setCategoryTableData} mstType={mstType} categoryType={categoryType}
                target={setCategoryData} targetParam={TargetParam} />
            <ItemTypeModal show={showModalItemType} onClose={() => setShowModalItemType(false)} width={'70%'}
                SearchValue={ItemTypeSearchValue} onChange={setItemTypeSearchValue}
                tableData={ItemTypeTableData} setTableData={setItemTypeTableData}
                target={setItemTypeData} targetParam={TargetParam} />
            <WarehouseModal show={showModalWarehouse} onClose={() => setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue}
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={() => setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue}
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ItemModal show={showModalItem} onClose={() => setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue}
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3}
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
            <StartTableModal show={showModalStartTable} onClose={() => setShowModalStartTable(false)} width={'70%'}
                SearchValue={startTableSearchValue} SearchValue2={startTableSearchValue2} onChange={setStartTableSearchValue}
                tableData={startTableData} setTableData={setStartTableData}
                target={setStartTableCode} />
            <EndTableModal show={showModalEndTable} onClose={() => setShowModalEndTable(false)} width={'70%'}
                SearchValue={endTableSearchValue} SearchValue2={endTableSearchValue2} onChange={setEndTableSearchValue}
                tableData={endTableData} setTableData={setEndTableData}
                target={setEndTableCode} />

            <div className={gstyle.border}>
                <p className={gstyle.text}>在庫報告書</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 締日区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker value={PublishDate} onChange={handleChangePublishDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>在庫検索年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker value={EndSlipDate} onChange={handleChangeEndSlipDate} id='date2' focusId='type2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 出力順位 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e) => onSelectData(e, 2)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type2"
                                            options={OrderTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownShipperCode(e, 1, 1)} id='shipper_code1'
                                            value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                            onKeyDown={(e) => onKeyDownShipperCode(e, 1, 2)} id='shipper_a_code1'
                                            value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadShipperModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownShipperCode(e, 2, 1)} id='shipper_code2'
                                            value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                            onKeyDown={(e) => onKeyDownShipperCode(e, 2, 2)} id='shipper_a_code2'
                                            value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadShipperModalData(e, 2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 商品 */}
                    {ShipperCode1 != "" && ShipperCode1 == ShipperCode2 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")}
                                                onKeyDown={(e) => onKeyDownItemCode(e, 1, 1)} id='item_code1'
                                                value={ItemCode1} onChange={handleChangeItemCode1} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")}
                                                onKeyDown={(e) => onKeyDownItemCode(e, 1, 2)} id='item_a_code1'
                                                value={ItemACode1} onChange={handleChangeItemACode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                value={ItemName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e) => loadItemModalData(e, 1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")}
                                                onKeyDown={(e) => onKeyDownItemCode(e, 2, 1)} id='item_code2'
                                                value={ItemCode2} onChange={handleChangeItemCode2} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")}
                                                onKeyDown={(e) => onKeyDownItemCode(e, 2, 2)} id='item_a_code2'
                                                value={ItemACode2} onChange={handleChangeItemACode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                value={ItemName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e) => loadItemModalData(e, 2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 分類 */}
                    {
                        OrderType > 0 && OrderType < 4 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始分類</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")}
                                                onKeyDown={(e) => onKeyDownCategoryCode(e, 1)} id='category_code1'
                                                value={CategoryCode1} onChange={handleChangeCategoryCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                value={CategoryName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e) => loadCategoryCodeModalData(e, 1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了分類</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")}
                                                onKeyDown={(e) => onKeyDownCategoryCode(e, 2)} id='category_code2'
                                                value={CategoryCode2} onChange={handleChangeCategoryCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                value={CategoryName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e) => loadCategoryCodeModalData(e, 2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 品種 */}
                    <div className={style_row.join(" ")}>
                        <input type='text' style={{ width: 0, outline: 'white', border: 'none' }} id="focus" />
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始品種</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownItemTypeCode(e, 1)} id='item_type_code1'
                                            value={ItemTypeCode1} onChange={handleChangeItemTypeCode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemTypeName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadItemTypeModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了品種</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownItemTypeCode(e, 2)} id='item_type_code2'
                                            value={ItemTypeCode2} onChange={handleChangeItemTypeCode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemTypeName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadItemTypeModalData(e, 2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e) => onSelectData(e, 3)}
                                            placeholder="倉庫区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeWarehouseType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type3"
                                            options={WarehouseTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫 */}
                    {WarehouseType == 1 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始倉庫</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")}
                                                onKeyDown={(e) => onKeyDownWarehouseCode(e, 1)} id='warehouse_code1'
                                                value={WarehouseCode1} onChange={handleChangeWarehouseCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                value={WarehouseName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e) => loadWarehouseModalData(e, 1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了倉庫</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")}
                                                onKeyDown={(e) => onKeyDownWarehouseCode(e, 2)} id='warehouse_code2'
                                                value={WarehouseCode2} onChange={handleChangeWarehouseCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                value={WarehouseName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e) => loadWarehouseModalData(e, 2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 棚 */}
                    {WarehouseCode1 != "" && WarehouseCode1 == WarehouseCode2 && <>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始棚コード</span>
                                        <div className={gstyle.cs_flex} style={{ marginTop: 14 }}>
                                            <input type={'num'} className={style.cs_tbl_input}
                                                value={LocationNo1} id='location_code1'
                                                onChange={(e) => handleChangeLocationNo1(e)} />
                                            <div className={gstyle.icon_search_wrap}
                                                onClick={(e) => loadStartTableModalData(e)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了棚コード</span>
                                        <div className={gstyle.cs_flex} style={{ marginTop: 14 }}>
                                            <input type={'num'} className={style.cs_tbl_input}
                                                value={LocationNo2} id='location_code2'
                                                onChange={(e) => handleChangeLocationNo2(e)} />
                                            <div className={gstyle.icon_search_wrap}
                                                onClick={(e) => loadEndTableModalData(e)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>}
                    {/* 報告文１,2 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>報告文 1</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_245px.join(" ")}
                                            value={ReportLabel1} onChange={handleChangeReportLabel1} id='report1'
                                            onKeyDown={(e) => onReportKeyDown(e, 1)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>報告文 2</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_245px.join(" ")}
                                            value={ReportLabel2} onChange={handleChangeReportLabel2} id='report2'
                                            onKeyDown={(e) => onReportKeyDown(e, 2)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  補助区分, 品番区分, 打出区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>補助区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type4Open}
                                            onSelect={(e) => onSelectData(e, 4)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeSubCodeType}
                                            onKeyDown={onType4KeyDown}
                                            onFocus={(e) => onFocusType4(e, 1)}
                                            onBlur={(e) => onFocusType4(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type4"
                                            options={[
                                                { value: '0', label: '一括' },
                                                { value: '1', label: '補助別' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type5Open}
                                            onSelect={(e) => onSelectData(e, 5)}
                                            placeholder="品番区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeRotType}
                                            onKeyDown={onType5KeyDown}
                                            onFocus={(e) => onFocusType5(e, 1)}
                                            onBlur={(e) => onFocusType5(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type5"
                                            options={[
                                                { value: '0', label: '品番集計' },
                                                { value: '1', label: 'ロット別' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>打出区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type6Open}
                                            onSelect={(e) => onSelectData(e, 6)}
                                            placeholder="打出区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeFilterType}
                                            onKeyDown={onType6KeyDown}
                                            onFocus={(e) => onFocusType6(e, 1)}
                                            onBlur={(e) => onFocusType6(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type6"
                                            options={[
                                                { value: '0', label: '発生又は在庫有り' },
                                                { value: '1', label: '発生有りのみ' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  品番順位, 品番印字区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番順位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type7Open}
                                            onSelect={(e) => onSelectData(e, 7)}
                                            placeholder="品番順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeItemOrderType}
                                            onKeyDown={onType7KeyDown}
                                            onFocus={(e) => onFocusType7(e, 1)}
                                            onBlur={(e) => onFocusType7(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type7"
                                            options={[
                                                { value: '0', label: '自社品番順' },
                                                { value: '1', label: '荷主品番順' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番印字区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type8Open}
                                            onSelect={(e) => onSelectData(e, 8)}
                                            placeholder="品番印字区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeItemPrintType}
                                            onKeyDown={onType8KeyDown}
                                            onFocus={(e) => onFocusType8(e, 1)}
                                            onBlur={(e) => onFocusType8(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="type8"
                                            options={[
                                                { value: '0', label: '自社品番' },
                                                { value: '1', label: '荷主品番' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>


                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
                <NewWindow onUnload={() => CloseSearchWidow()}
                    features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                    <div className={style.nw_container}>
                        <div >
                            {mainTableData.map((data, index) => (
                                <div key={index}>
                                    {/* Title, 発行年月日 */}
                                    <div className={style_row.join(" ")}>
                                        <div className={style_col_2.join(" ")}></div>
                                        <div className={style_col_8.join(" ")}>
                                            <div className={style.nw_title_wrap} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <h2>在庫報告書</h2>
                                            </div>
                                        </div>
                                        <div className={style_col_2.join(" ")}>
                                            <div className={style.publish_wrap}>
                                                {/* <p>{PublishDate}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* header  */}
                                    <div className={style_row.join(" ")}>
                                        <div className={style_col_4.join(" ")}>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            〒 {data['header_info']['shipper_zip']}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {data['header_info']['shipper_address']}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {data['header_info']['shipper_name']}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {data['header_info']['shipper_honor'] == 0 ? '御中' : data['header_info']['shipper_honor'] == 1 ? '様' : ''}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className={style_col_4.join(" ")} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div className={style_input_wrap.join(" ")} style={{ marginTop: 10 }}>
                                                {
                                                    WarehouseType > 0 &&
                                                    <div style={{ fontSize: 16 }}>
                                                        {data['header_info']['warehouse_code']}
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {data['header_info']['warehouse_name']}
                                                    </div>
                                                }
                                            </div>

                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {
                                                                DateType == 0 ? useJwt.formatDate2(new Date(DeadLineDay)) + " 現在"
                                                                    : useJwt.formatDate2(new Date(EndSlipDate)) + " 現在"
                                                            }
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {ReportLabel1}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {ReportLabel2}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className={style_col_1.join(" ")}></div>
                                        <div className={style_col_3.join(" ")}>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {ConsignorName}
                                                        </span>
                                                    </div>
                                                </label>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            〒 {ConsignorZip}
                                                        </span>
                                                    </div>
                                                </label>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {
                                                                ConsignorAddress1 ? ConsignorAddress1 : ''
                                                            }
                                                        </span>
                                                    </div>
                                                </label>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            TEL:
                                                        </span>
                                                        <span className={style.cs_span_3}>
                                                            {ConsignorTel}
                                                        </span>
                                                    </div>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            FAX:
                                                        </span>
                                                        <span className={style.cs_span_3}>
                                                            {ConsignorFax}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style_row.join(" ")}>
                                        <div className={style_col_12.join(" ")}>
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex_3}>
                                                        <span className={style.cs_span_2}>
                                                            {'(自 ' + useJwt.formatDate2(new Date(data['header_info']['end_date'])) + " ~ 至 " + useJwt.formatDate2(new Date(data['header_info']['end_date'])) + ')'}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* table */}
                                    <div className={style_row.join(" ")}>
                                        <div className={style_col_12.join(" ")}>
                                            <div className={style_input_wrap3.join(" ")}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>品番</th>
                                                            <th>補助</th>
                                                            <th>品名</th>
                                                            <th>入数</th>
                                                            <th>単位重量</th>
                                                            <th>単位容積</th>
                                                            <th>数量</th>
                                                            <th>単位</th>
                                                            <th>在庫重量</th>
                                                            <th>在庫容積</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data['item_info'].map((data1, index1) => (
                                                            (data1['total_flg'] == 0 ?
                                                                <tr>
                                                                    <td>{data1['item_code']}</td>
                                                                    <td>{SubCodeType == 1 ? data1['item_a_code'] : '000'}</td>
                                                                    <td className={style.td_left}>{data1['item_name']}</td>
                                                                    <td>{data1['item_quantity']}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['unit_weight'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['unit_volume'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['stock_ary']['stockNum'])}</td>
                                                                    <td>{data1['item_unit']}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['stock_ary']['stockWeight'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['stock_ary']['stockVolume'])}</td>
                                                                </tr>
                                                                : data1['stockNum'] > 0 &&
                                                                <tr className={gstyle.bg_tbl_total}>
                                                                    <td colSpan={6}>{data1['total_label']}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['stockNum'])}</td>
                                                                    <td></td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['stockWeight'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['stockVolume'])}</td>
                                                                </tr>
                                                            )
                                                        ))}
                                                        {
                                                            data['stockNum'] &&
                                                            <tr className={gstyle.bg_tbl_total}>
                                                                <td colSpan={6}>{data['total_label']}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data['stockNum'])}</td>
                                                                <td></td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data['stockWeight'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data['stockVolume'])}</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        data['csv_item_info'] &&
                                        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 30px' }}>
                                            <CSVLink data={data['csv_item_info']} filename={'在庫報告書_' + useJwt.formatDateTime(new Date()) + '.csv'}
                                                className={style.btn_csv} style={{ marginLeft: 0 }}>ﾌｧｲﾙ</CSVLink>
                                        </div>
                                    }
                                </div>
                            ))}

                        </div>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <ReactToPrint
                                        content={reactToPrintContent}
                                        documentTitle={'在庫報告書_' + useJwt.formatDateTime(new Date())}
                                        onAfterPrint={handleAfterPrint}
                                        onBeforeGetContent={handleOnBeforeGetContent}
                                        onBeforePrint={handleBeforePrint}
                                        removeAfterPrint
                                        trigger={reactToPrintTrigger}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </NewWindow>
            )}

            {pdfTableData && (
                <div className={style.nw_container} style={{ display: 'none' }}>
                    <div ref={componentRef}>
                        {pdfTableData.map((data, index) => (
                            <div key={index} style={{ marginBottom: 500 }}>
                                {/* Title, 発行年月日 */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_2.join(" ")}></div>
                                    <div className={style_col_8.join(" ")}>
                                        <div className={style.nw_title_wrap} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <h2>在庫報告書</h2>
                                        </div>
                                    </div>
                                    <div className={style_col_2.join(" ")}>
                                        <div className={style.publish_wrap}>
                                            {/* <p>{PublishDate}</p> */}
                                        </div>
                                    </div>
                                </div>
                                {/* header  */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_4.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        〒 {data['header_info']['shipper_zip']}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {data['header_info']['shipper_address']}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {data['header_info']['shipper_name']}
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {data['header_info']['shipper_honor'] == 0 ? '御中' : data['header_info']['shipper_honor'] == 1 ? '様' : ''}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={style_col_4.join(" ")} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div className={style_input_wrap.join(" ")} style={{ marginTop: 10 }}>
                                            {
                                                WarehouseType > 0 &&
                                                <div style={{ fontSize: 16 }}>
                                                    {data['header_info']['warehouse_code']}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {data['header_info']['warehouse_name']}
                                                </div>
                                            }
                                        </div>

                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {
                                                            DateType == 0 ? useJwt.formatDate2(new Date(DeadLineDay)) + " 現在"
                                                                : useJwt.formatDate2(new Date(EndSlipDate)) + " 現在"
                                                        }
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {ReportLabel1}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {ReportLabel2}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={style_col_1.join(" ")}></div>
                                    <div className={style_col_3.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {ConsignorName}
                                                    </span>
                                                </div>
                                            </label>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        〒 {ConsignorZip}
                                                    </span>
                                                </div>
                                            </label>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {
                                                            ConsignorAddress1 ? ConsignorAddress1 : ''
                                                        }
                                                    </span>
                                                </div>
                                            </label>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        TEL:
                                                    </span>
                                                    <span className={style.cs_span_3}>
                                                        {ConsignorTel}
                                                    </span>
                                                </div>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        FAX:
                                                    </span>
                                                    <span className={style.cs_span_3}>
                                                        {ConsignorFax}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex_3}>
                                                    <span className={style.cs_span_2}>
                                                        {'(自 ' + useJwt.formatDate2(new Date(data['header_info']['start_date'])) + " ~ 至 " + useJwt.formatDate2(new Date(data['header_info']['end_date'])) + ')'}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* table */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap3.join(" ")}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>品番</th>
                                                        <th>補助</th>
                                                        <th>品名</th>
                                                        <th>入数</th>
                                                        <th>単位重量</th>
                                                        <th>単位容積</th>
                                                        <th>数量</th>
                                                        <th>単位</th>
                                                        <th>在庫重量</th>
                                                        <th>在庫容積</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data['pdf_info'].map((data1, index1) => (
                                                        (data1['total_flg'] == 0 ?
                                                            <tr>
                                                                <td>{data1['item_code']}</td>
                                                                <td>{SubCodeType == 1 ? data1['item_a_code'] : '000'}</td>
                                                                <td className={style.td_left}>{data1['item_name']}</td>
                                                                <td>{data1['item_quantity']}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['unit_weight'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['unit_volume'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['stock_ary']['stockNum'])}</td>
                                                                <td>{data1['item_unit']}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['stock_ary']['stockWeight'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['stock_ary']['stockVolume'])}</td>
                                                            </tr>
                                                            : data1['stockNum'] > 0 &&
                                                            <tr className={gstyle.bg_tbl_total}>
                                                                <td colSpan={6}>{data1['total_label']}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['stockNum'])}</td>
                                                                <td></td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['stockWeight'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['stockVolume'])}</td>
                                                            </tr>
                                                        )
                                                    ))}
                                                    {
                                                        data['stockNum'] &&
                                                        <tr className={gstyle.bg_tbl_total}>
                                                            <td colSpan={6}>{data['total_label']}</td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(data['stockNum'])}</td>
                                                            <td></td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(data['stockWeight'])}</td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(data['stockVolume'])}</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            )}
        </div>
    )
}

export default StockListByShipper;