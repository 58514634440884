/* eslint-disable eqeqeq */
import style from './Command.module.css';
import gstyle from '../../css/GeneralStyle.module.css';
// import './CommandTbl.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import WarehouseModal from '../../components/common/modal/WarehouseUserModal';
import Modal2 from '../../components/common/modal/Modal2';
import InoutModal from '../../components/common/modal/InoutModal';
import IconSearch from '../../components/common/icons/icon_search.png';
import InputDatepicker from '../../components/common/input/InputDatepicker';
import $ from 'jquery';

const Command = () => {

    const authentication = useJwt.isShipperUserLoggedIn();
    const userData = JSON.parse(useJwt.getShipperUserData());

    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [showModalDel2, setShowModalDel2] = useState(false);
    const [inoutShowModal, setInoutShowModal] = useState(false);

    const csLoading = (flag) => {
        if (flag) {
            document.getElementById('csLoader').style.display = "flex";
        } else {
            document.getElementById('csLoader').style.display = "none";
        }
    }

    const [open, setOpen] = React.useState(false);

    // command date
    const [CommandDate, setCommandDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeCommandDate = (date) => {
        setCommandDate(useJwt.formatDate(date));
        getInoutCommand(useJwt.formatDate(date), WarehouseCode, shipperId);
    };

    // ====================================== table ====================================
    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let data = [];
        for (var i = 0; i < result.length; i++) {
            let row = {};
            row = result[i];
            data[i] = row;
        }
        setMainTableData(data);
    }
    const handleChangeItemData = (event, control_type, col, index) => {
        let value = '';
        if (control_type == 2) {
            value = useJwt.formatDate(event);
        } else {
            value = event.target.value;
        }

        if (col == 'item_code' || col == 'rot_code') {
            value = useJwt.validateString(value, 13);
        } else if (col == 'item_a_code') {
            value = useJwt.validateNum(value, 3);
        } else if (col == 'shipper_item_code') {
            value = useJwt.validateString(value, 13);
        } else if (col == 'item_num') {
            value = useJwt.validateDecimal(value, 6, 3);
        }

        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row[col] = value;
                if (col == 'item_code' || col == 'item_a_code') {
                    row['shipper_item_code'] = "";
                    row['rot_code'] = "";
                }
            }
            data[i] = row;
        }
        gridMainTable(data);
    }
    const allCheckData = (event) => {
        $('#tbodyWrap input[type="checkbox"]').prop('checked', event.target.checked);
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };
    const inoutAdd = () => {
        if (WarehouseName == "") {
            toast.error("倉庫会社をお選びください。", { autoClose: 3000 });
        } else {
            setInoutShowModal(true)
        }
    }

    const deleteItem = (event) => {
        setShowModalDel2(false);
        let remainIndexs = [];
        var delList = ""
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (!checkboxes[i].checked) {
                remainIndexs.push(i);
            } else {
                if (delList != "") delList = delList + ","
                delList = delList + mainTableData[i]['id']
            }
        }

        let data = {};
        data['userID'] = userData['id'];
        data['delList'] = delList
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.delInoutCommandEndpoint;
        csLoading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if (response.status == 200) {
                    useJwt.setToken(response.data.token);
                    toast.success("削除しました。", { autoClose: 3000 });
                    let remainData = [];
                    for (var i = 0; i < mainTableData.length; i++) {
                        for (var j = 0; j < remainIndexs.length; j++) {
                            if (i == remainIndexs[j]) {
                                let row = {};
                                row = mainTableData[i];
                                remainData.push(row);
                            }
                        }
                    }
                    gridMainTable(remainData);
                    $('input[type="checkbox"]').prop('checked', false);
                }
                csLoading(false);
            })
            .catch((error) => {
                csLoading(false);
                if (error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }
            });
        setOpen(false);

    }
    // =================================================================================

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);
        }
    });

    const loadModalDelData2 = (event) => {
        event.preventDefault();
        setShowModalDel2(true);
    }

    // ========================== starts code for search ===============================
    const setInoutData = (data) => {
        let tempData = mainTableData
        tempData.push(data)
        setMainTableData(tempData)
    }

    // warehouse --------------------------------------------------
    const [WarehouseCode, setWarehouseCode] = useState("");
    const [WarehouseName, setWarehouseName] = useState("");
    const [shipperId, setShipperId] = useState("")
    const [shipperCode, setShipperCode] = useState("")
    const setWarehouseData = (data, param) => {
        if (data['id']) {
            setWarehouseCode(data['id']);
            setWarehouseName(data['company_name']);
            setShipperId(data['shipper_id'])
            setShipperCode(data['shipper_code'])
            getInoutCommand(CommandDate, data['id'], data['shipper_id']);

        } else {
            setWarehouseCode("");
            setWarehouseName("");
            setShipperId("")
            setShipperCode("")
            setMainTableData([])
        }
    }

    const [TargetParam, setTargetParam] = useState(1);

    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const [warehouseUrl, setWarehouseUrl] = useState("");

    const loadWarehouseModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let wName = WarehouseName;
        let code = WarehouseCode
        setWarehouseSearchValue(wName);
        setWarehouseUrl(jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseUserByShipperEndpoint);

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_user_id'] = userData['id'];
            if (wName != "") {
                data['id'] = code;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseUserByShipperEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWarehouseTableData(response.data.data);
                    setShowModalWarehouse(true);
                })
                .catch((error) => {
                });
        } else {
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }

    const getInoutCommand = (date, wId, sId) => {
        if (wId == "") return false;

        let data = {};
        data['userID'] = userData['id'];
        data['command_date'] = date;
        data['warehouse_id'] = wId;
        data['shipper_id'] = sId;

        csLoading(true)
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getInoutCommandEndpoint;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                csLoading(false)
                setMainTableData(response.data.data);
            })
            .catch((error) => {
                csLoading(false)
            });
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_col_1 = [gstyle.cs_col, gstyle.cs_col_1];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_345px = [gstyle.cs_input, gstyle.w445px];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_th_w3p = [style.cs_th, style.w3p];
    const style_th_w7p = [style.cs_th, style.w7p];
    const style_th_w8p = [style.cs_th, style.w8p];
    const style_th_w10p = [style.cs_th, style.w10p];
    const style_th_w15p = [style.cs_th, style.w15p];
    const style_th_w20p = [style.cs_th, style.w20p];

    const style_td_w3p = [style.cs_td, style.w3p];
    const style_td_w7p = [style.cs_td, style.w7p];
    const style_td_w8p = [style.cs_td, style.w8p];
    const style_td_w10p = [style.cs_td, style.w10p];
    const style_td_w15p = [style.cs_td, style.w15p];
    const style_td_w20p = [style.cs_td, style.w20p];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csLoader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <InoutModal show={inoutShowModal} onClose={() => setInoutShowModal(false)} width={'80%'}
                warehouseId={WarehouseCode} shipperId={shipperId} shipperCode={shipperCode} commandDate={CommandDate}
                target={setInoutData} />
            <WarehouseModal show={showModalWarehouse} onClose={() => setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} url={warehouseUrl} onChange={setWarehouseSearchValue}
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} />
            <Modal2 title="入出庫指示削除" show={showModalDel2} onClose={() => setShowModalDel2(false)} width={'40%'}
                onConfirm={deleteItem}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>入出庫指示</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 年月日, 伝票No, 問合せNo */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_2.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker value={CommandDate} onChange={handleChangeCommandDate} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_1.join(" ")}>

                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫会社</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_345px.join(" ")} style={{ margin: '15px 0px' }} disabled
                                            value={WarehouseName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadWarehouseModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        { /* 追加, 削除 */}
                        <div className={style_col_3.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")}>
                                <button className={gstyle.btn_new} onClick={inoutAdd} >追加</button>
                                <button className={gstyle.btn_delete2} onClick={loadModalDelData2} >削除</button>
                            </div>
                        </div>
                    </div>
                    {/* table */}
                    <div className={style.tbl_wrap}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")} style={{ width: '5%' }}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_w7p.join(" ")}>入出庫区分</div>
                                    <div className={style_th_w10p.join(" ")}>荷主品番</div>
                                    <div className={style_th_w20p.join(" ")}>品名</div>
                                    <div className={style_th_w15p.join(" ")}>ロットNo</div>
                                    <div className={style_th_w10p.join(" ")}>ロット名</div>
                                    <div className={style_th_w3p.join(" ")}>単位</div>
                                    <div className={style_th_w15p.join(" ")}>運送会社名</div>
                                    <div className={style_th_w10p.join(" ")}>車種</div>
                                    <div className={style_th_w10p.join(" ")}>入出庫時間</div>
                                    <div className={style_th_w8p.join(" ")}>数量</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {mainTableData.map((data, index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                                id={'chk_' + index} onChange={handleChangeChk} />
                                        </div>
                                        <div className={style_td_w7p.join(" ")}>
                                            {data['inout_type'] == "1" ? '入庫' : '出庫'}
                                        </div>
                                        <div className={style_td_w10p.join(" ")}>
                                            {data['shipper_item_code'] ? data['shipper_item_code'] : ''}
                                        </div>
                                        <div className={style_td_w20p.join(" ")}>
                                            {data['item_name'] ? data['item_name'] : ''}
                                        </div>
                                        <div className={style_td_w15p.join(" ")}>
                                            {data['rot_code'] ? data['rot_code'] : ''}
                                        </div>
                                        <div className={style_td_w10p.join(" ")}>
                                            {data['rot_name'] ? data['rot_name'] : ''}
                                        </div>
                                        <div className={style_td_w3p.join(" ")}>
                                            {data['item_unit'] ? data['item_unit'] : ''}
                                        </div>
                                        <div className={style_td_w15p.join(" ")}>
                                            {data['carrier_name'] ? data['carrier_name'] : ''}
                                        </div>
                                        <div className={style_td_w10p.join(" ")}>
                                            {data['car_type'] == "1" ? '4t' : data['car_type'] == "2" ? '10t' : 'トレーラー'}
                                        </div>
                                        <div className={style_td_w10p.join(" ")}>
                                            {data['start_date'] ? data['start_date'] : ''}
                                        </div>
                                        <div className={style_td_w8p.join(" ")}>
                                            {data['item_num'] ? data['item_num'] : ''}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Command;