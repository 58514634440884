/* eslint-disable eqeqeq */
import style from "./CsvImport.module.css";
import gStyle from "../../css/GeneralStyle.module.css";
import React, { useState, useEffect } from "react";
import * as useJwt from "../../auth/jwt/useJwt.js";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import ShipperModal from '../../components/common/modal/ShipperByWarehouseModal';
import { Modal, Button, Radio, Popconfirm } from "antd";
import IconSearch from '../../components/common/icons/icon_search.png';

import CsvImportDataCheckComponent from "../../components/common/CsvImportDataCheckInput.tsx";
import axios from "axios";

const ItemImport = () => {
  const userData = JSON.parse(useJwt.getUserData());

  const [importErrorData, setImportErrorData] = useState([]);
  const [loadFlag, setLoadFlag] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // shipper ---------------------------------------------------
  const [ShipperCode, setShipperCode] = useState("");
  const handleChangeShipperCode = (event) => {
    setShipperCode(useJwt.validateNum(event.target.value, 6));
  };
  const [ShipperACode, setShipperACode] = useState("");
  const handleChangeShipperACode = (event) => {
    setShipperACode(useJwt.validateNum(event.target.value, 3));
  };
  const [ShipperName, setShipperName] = useState("");

  const onKeyDownShipperCode = (event, param1, param2) => {
    if (event.keyCode === 13) { // enter key
      getShipperData(event.target.value, param1, param2);
      event.preventDefault();
    }
  };
  const getShipperData = (value, param1, param2) => {
    let code;
    let acode;
    if (param1 == 1) {
      if (param2 == 1) {
        code = value;
        if (ShipperACode != "") {
          acode = ShipperACode;
        } else {
          acode = "000";
        }
      } else {
        code = ShipperCode;
        if (value != "") {
          acode = value;
        } else {
          acode = "000";
        }
      }
    } else {
      if (param2 == 1) {
        code = value;
        if (ShipperACode != "") {
          acode = ShipperACode;
        } else {
          acode = "000";
        }
      } else {
        code = ShipperCode;
        if (value != "") {
          acode = value;
        } else {
          acode = "000";
        }
      }
    }

    if (code != "") {
      let data = {};
      data['userID'] = userData['id'];
      data['shipper_code'] = code;
      if (acode != "") {
        data['shipper_a_code'] = acode;
      }
      let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        }).then((response) => {
          if (response.status == 200) {
            // useJwt.setToken(response.data.token);
            if (response.data.data.length > 0) {
              let result = response.data.data[0];
              setShipperData(result, param1);
              document.getElementById('warehouse_input').focus();
            } else {
              setShipperData([], param1);
              if (param2 == 2) {
                document.getElementById('warehouse_input').focus();
              } else {
                document.getElementById('shipper_a_input').focus();
              }
            }
          }
        })
        .catch((error) => {
          if (param2 == 2) {
            document.getElementById('warehouse_input').focus();
          } else {
            document.getElementById('shipper_a_input').focus();
          }
        });
    } else {
      setShipperData([], param1);
      if (param2 == 2) {
        document.getElementById('warehouse_input').focus();
      } else {
        document.getElementById('shipper_a_input').focus();
      }
    }
  }
  const setShipperData = (data, param) => {
    if (data['shipper_code'] != null) {
      setShipperCode(data['shipper_code']);
      setShipperACode(data['shipper_a_code']);
      setShipperName(data['shipper_name']);
    } else {
      setShipperCode("");
      setShipperACode("");
      setShipperName("");
    }
  }

  // ----------------------------- shipper modal -------------------------------------
  const [showModalShipper, setShowModalShipper] = useState(false);
  const [ShipperSearchValue, setShipperSearchValue] = useState("");
  const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
  const [ShipperTableData, setShipperTableData] = useState([]);
  const [TargetParam, setTargetParam] = useState(1);

  const loadShipperModalData = (event, param) => {
    event.preventDefault();
    setTargetParam(param);
    let code = ShipperCode;
    setShipperSearchValue(code);
    setShipperSearchValue2("");

    if (code != "") {
      let data = {};
      data['userID'] = userData['id'];
      data['shipper_code'] = code;

      let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          setShipperTableData(response.data.data);
          setShowModalShipper(true);
        })
        .catch((error) => {
        });
    } else {
      setShipperTableData([]);
      setShowModalShipper(true);
    }
  }

  let itemCsvHeader = [
    "ｺｰﾄﾞ",
    "品番",
    "ﾌﾘｶﾞﾅ",
    "品名略称",
    "入数",
    "単位重量",
    "在庫計算",
    "税No",
    "分類2",
    "品種",
    "荷主名",
    "品名",
    "単位",
    "商品区分",
    "請求端数計算",
    "単位容積",
    "請求計算",
    "分類1",
    "分類3",
    "価格改定日",
    "保管料率",
    "入庫荷役",
    "出庫荷役",
    "在庫評価単価"
  ];

  useEffect(() => {
    if (importErrorData[0] == "success" && loadFlag == false) {
      toast.success("インポート致しました。", { autoClose: 3000 });
      setImportErrorData([])
    }
    csLoading(loadFlag);
  }, [loadFlag, importErrorData]);

  const csLoading = (flag) => {
    if (flag) {
      document.getElementById("csLoader").style.display = "flex";
    } else {
      document.getElementById("csLoader").style.display = "none";
    }
  };

  const totalDelete = (e, val) => {
    let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.totalItemDelete
    let data = {};
    data["userID"] = userData["id"];

    if (val === 2) {
      data['shipper_code'] = ShipperCode;
    }

    axios.post(url, data, {
      headers: {
        Authorization: "Bearer " + useJwt.getToken(),
      }
    }).then((res) => {
      console.log(res);
      // eslint-disable-next-line no-const-assign
      setModalOpen(false)
      toast.success("削除しました。", { autoClose: 3000 });
    })
      .catch((error) => {
        setModalOpen(false)
        toast.error("削除できませんでした。", { autoClose: 3000 });
      });
  }

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const style_input_100px = [gStyle.cs_input, gStyle.w100px];
  const style_input_100px_bl_n = [gStyle.cs_input, gStyle.w100px, gStyle.cs_bl_n];
  const style_input_bl_n = [gStyle.cs_input, style.job_time];

  return (
    <div className={gStyle.display}>
      <ShipperModal show={showModalShipper} onClose={() => setShowModalShipper(false)} width={'70%'}
        SearchValue={ShipperSearchValue} onChange={setShipperSearchValue}
        SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
        tableData={ShipperTableData} setTableData={setShipperTableData}
        target={setShipperData} targetParam={TargetParam} />
      <div className={gStyle.loader_container} id="csLoader">
        <ClipLoader color={"#fff"} size={60} />
      </div>
      <ToastContainer />
      <div className={gStyle.border}>
        <p className={gStyle.text}>商品CSVインポート</p>
      </div>
      <div className={gStyle.filed}>
        <div className={style.cs_card}>
          <div style={{ display: "flex", alignItems: 'center', width: '100%' }}>
            <CsvImportDataCheckComponent
              url={jwtDefaultConfig.importItemCsv}
              setErrorData={setImportErrorData}
              setLoadFlag={setLoadFlag}
              csvHeader={itemCsvHeader}
            />
            <button onClick={(e) => setModalOpen(true)} style={{ color: 'white', backgroundColor: "red", width: 100, border: "none", height: 40 }}>全削除</button>

          </div>
          <div className={style.error_tag}>
            {importErrorData?.map((value, index) => (
              value != 'success' &&
              <p className={style.error_content} key={index}>
                {value}
              </p>
            ))}
          </div>
        </div>
      </div>
      <Modal
        centered
        closable={false}
        open={modalOpen}
        width={600}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'center', gap: 30 }}>
            <Popconfirm
              title="削除"
              description="商品マスター削除しますか？"
              okText="削除"
              cancelText="閉じる"
              placement="top"
              style={{ width: 300 }}
              onConfirm={(e) => totalDelete(e, value)}
            >
              <Button type="primary" danger>削除</Button>
            </Popconfirm>
            <Button type="primary" onClick={(e) => setModalOpen(false)}>閉じる</Button>
          </div>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 20 }}>
          <Radio.Group onChange={onChange} value={value}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
              <Radio value={1} style={{ height: 35, lineHeight: 2.5 }}>全削除</Radio>
              <Radio value={2} style={{ height: 35, lineHeight: 2.5 }}>
                <div style={{ display: 'flex' }}>
                  荷主別削除&nbsp;&nbsp;&nbsp;
                  {value === 2 ? (
                    <div className={gStyle.cs_flex}>
                      <input type={'number'} className={style_input_100px.join(" ")} style={{ margin: 0 }}
                        onKeyDown={(e) => onKeyDownShipperCode(e, 1, 1)} id="shipper_input"
                        value={ShipperCode} onChange={handleChangeShipperCode} />
                      <input type={'number'} className={style_input_100px_bl_n.join(" ")} style={{ margin: 0 }}
                        onKeyDown={(e) => onKeyDownShipperCode(e, 1, 2)} id="shipper_a_input"
                        value={ShipperACode} onChange={handleChangeShipperACode} />
                      <input type={'text'} className={style_input_bl_n.join(" ")} disabled style={{ margin: 0 }}
                        value={ShipperName} />
                      <div className={gStyle.icon_search_wrap} onClick={(e) => loadShipperModalData(e, 1)}>
                        <img src={IconSearch} className={gStyle.icon_search} alt="icon" />
                      </div>
                    </div>
                  ) : null}
                </div>
              </Radio>
            </div>
          </Radio.Group>
        </div>
      </Modal>

    </div>
  );
};

export default ItemImport;
