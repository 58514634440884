/*====================================================================================================
	単位変更処理 -> AdobeXD 24ページ
	作成日:2022/08/26		最終更新日:2022/09/01
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

import React from 'react';
import InputTableText from '../../../../common/tenjin_tokubuchi/input_table_text/InputTableText';
import style from "./Text.module.css";

const WarehouseText = (props)=> {
    return (
        <InputTableText text={`${style.text}` } tab_index={props.tab_index} name={"WarehouseText_" + String(props.name_id)} />
    );
}

export default WarehouseText;