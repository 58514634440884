/* eslint-disable eqeqeq */
import style from './Bus.module.css';
import gstyle from '../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal2 from '../../components/common/modal/Modal2';
import IconSearch from '../../components/common/icons/icon_search.png';
import WarehouseModal from '../../components/common/modal/WarehouseModal';
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select} from 'antd';

const BusSetting = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const Option = Select.Option;
    const children = [];
    children.push(<Option key={1} value="1">4t</Option>);
    children.push(<Option key={2} value="2">10t</Option>);
    children.push(<Option key={3} value="3">トレーラー</Option>);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        for(var i = 0; i<mainTableData.length; i++){

            if(mainTableData[i]['bus_name'] == ""){
                toast.error("バースを入力してください。", {autoClose:3000});
                return;
            }

        }
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Warehouse ID
    const [warehouseId, setWarehouseId] = useState(0);
    const [WarehouseCode, setWarehouseCode] = useState("");
    const handleChangeWarehouseCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>2) {
            str = str.substring(0, 2);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setWarehouseCode(str);
    };
    const onKeyDownWarehouseCode = (event) => {
        if(event.keyCode === 13) { // enter key
            if(event.target.value != "") {
                const data_r = {
                    userID: userData['id'],
                    warehouse_code: event.target.value,
                    warehouse_company_id: userData['id']
                };
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
                axios
                .post(url, data_r, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if(t_data.length > 0) {
                        setWarehouseData(t_data[0]);
                    }else{
                        setWarehouseData([]);
                    }
                })
                .catch((error) => {
                });
            }else{
                setWarehouseData([]);
            }
            event.preventDefault();
        }
    };
    
    const [WarehouseName, setWarehouseName] = useState("");
    const handleChangeWarehouseName = (event) => {
        let str = event.target.value;
        if(str.length>10) {
            str = str.substring(0, 10);
        }
        setWarehouseName(str);
    };
    const [WarehouseAName, setWarehouseAName] = useState("");
    const handleChangeWarehouseAName = (event) => {
        let str = event.target.value;
        if(str.length>5) {
            str = str.substring(0, 5);
        }
        setWarehouseAName(str);
    };

    const setWarehouseData = (result) => {
        if(result['sys_warehouse_id']) {
            setWarehouseId(result['sys_warehouse_id']);
            setWarehouseCode(result['warehouse_code']);
            setWarehouseName(result['warehouse_name']);
            setWarehouseAName(result['warehouse_a_name']);
            let filterData = {};
            filterData['warehouse_id'] = result['sys_warehouse_id'];
            getBusData(filterData);
        }else{
            setWarehouseId(0);
            setWarehouseName("");
            setWarehouseAName("");
            setWarehouseName("");
            gridMainTable([]);
        }
    }

    const getBusData = (result) => {
        let data = {};
        data['userID'] = userData['id'];
        data['warehouse_id'] = result['warehouse_id'];
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getBusSettingEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        }).then((response) => {
            csloading(false);

            if(response.status == 200) {
                if(response.data.data.length>0) {
                    let result = response.data.data;
                    gridMainTableJson(result);
                    var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
                    for (let i = 0; i < checkboxes.length; i++) {
                        checkboxes[i].checked = false;
                    }
                }else{
                    gridMainTable([]);
                }
            }
        })
        .catch((error) => {
            csloading(false);
        });
    }

    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        setMainTableData([]);
        setMainTableData(result);
    }
    const gridMainTableJson = (result) => {
        setMainTableData([]);
        let tempList = [];
        for(var i = 0; i < result.length; i++){
            let temp = {};
            temp['id'] = result[i]['id'];
            temp['warehouse_id'] = result[i]['warehouse_id'];
            temp['bus_name'] = result[i]['bus_name'];
            temp['standard'] = JSON.parse(result[i]['standard']);
            tempList.push(temp);
        }
        setMainTableData(tempList);
    }

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['bus_flag_1'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const newRecord = (event) => {
        if(warehouseId == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000}); 
            return;
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            data[i] = row;
        }
        let row = {};
        row['id'] = 0;
        row['warehouse_id'] = warehouseId;
        row['busName'] = "";
        row['standard'] = [];

        data[i] = row; 
        gridMainTable(data);
    }

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];

        let busData = [];
        for(var i = 0; i<mainTableData.length; i++){
            let row = {};
            if(mainTableData[i]['id'] != 0) {
                row['id'] = mainTableData[i]['id'];
            }

            row['warehouse_id'] = mainTableData[i]['warehouse_id'];
            row['bus_name'] = mainTableData[i]['bus_name'];
            row['standard'] = JSON.stringify(mainTableData[i]['standard']);

            busData[i] = row;

        }

        data['item'] = busData;

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setBusSettingEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data == 1) {
                    toast.success("登録致しました。", {autoClose:3000});
                    let filterData = {};
                    filterData['warehouse_id'] = warehouseId;
                    getBusData(filterData);
                }else if(response.data.data == 2){
                    toast.error("すでに登録済みです。", {autoClose:3000});
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
                    
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else{
                toast.error("登録に失敗しました。", {autoClose:3000});
            }
        });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if(warehouseId>0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        let delIds = [];
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(checkboxes[i].checked){
                let id = document.getElementById('id_'+i).value;
                if(id>0){
                    delIds.push(id);
                }
            }else{
                remainIndexs.push(i);
            }
        }
        
        if(delIds.length>0) {
            let data = {};
            data['userID'] = userData['id'];
            data['delIds'] = delIds;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.delBusSettingEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    let filterData = {};
                    filterData['warehouse_id'] = warehouseId;
                    getBusData(filterData);
                    
                }
            })
            .catch((error) => {
            });
        }else{
            let remainData = [];
            for(var i=0; i<mainTableData.length; i++) {
                for(var j=0; j<remainIndexs.length; j++){
                    if(i==remainIndexs[j]){
                        let row = {};
                        row = mainTableData[i];
                        remainData.push(row);
                    }
                }
            }
            gridMainTable(remainData);
            $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', false);
        }
    }
    // ========================== starts code for search ===============================
    // ----------------------- Warehouse Modal -----------------------------------------
    const [SearchValue, setSearchValue] = useState("");
    const [SearchValueWarehouse, setSearchValueWarehouse] = useState("");
    const [tableData, setTableData] = useState([]);
    const loadModalData = (event) => {
        event.preventDefault();
        let code = WarehouseCode;
        setSearchValue(code);

        if(code != ""){
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData(response.data.data);
                setShowModal(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData([]);
            setShowModal(true);
        }
    }
    
    const allCheckData = (event) => {
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', event.target.checked);
    }

    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };

    const handleChangeBusName = (event, index) => {
        let value = event.target.value;

        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row['bus_name'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);

    }

    const handleChangeStandard = (value, index) => {
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row['standard'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w140px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_3 = [style.cs_th, gstyle.cs_col_3, style.td_item_align];
    const style_th_col_9 = [style.cs_th, style.wp70, style.td_item_align];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p, style.td_item_align];
    const style_td_col_3 = [style.cs_td, gstyle.cs_col_3, style.td_item_align];
    const style_td_col_9 = [style.cs_td, style.wp70, style.td_item_align];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p, style.td_item_align];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <WarehouseModal show={showModal} onClose={()=>setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} SearchValueWarehouse={SearchValueWarehouse} onChange={setSearchValue} 
                tableData={tableData} setTableData={setTableData}
                target={setWarehouseData} />
            <Modal2 title="バース削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>バース設定</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* コード */}
                    <div className={style_row.join(" ")} style={{marginLeft:55}}>
                        <div className={style_col_2.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownWarehouseCode(e)}
                                        value={WarehouseCode} onChange={handleChangeWarehouseCode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalData(e)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")} style={{marginLeft:50}}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫名称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}  
                                        value={WarehouseName} onChange={handleChangeWarehouseName} disabled/>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫略称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        value={WarehouseAName} onChange={handleChangeWarehouseAName} disabled/>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")} style={{marginTop:33}}>
                            <Button type="primary" style={{width:100}} onClick={newRecord} >追 加</Button>
                        </div>
                    </div>
                    <div className={gstyle.hyfiled} style={{marginTop:30, justifyContent:'center'}}>
                        <div style={{minWidth:800}}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_col_3.join(" ")}>バース</div>
                                    <div className={style_th_col_9.join(" ")}>バース規格</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData?.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                            id={'chk_'+index} onChange={handleChangeChk} />
                                            <input type="hidden" id={'id_'+index} value={data['id']} />
                                        </div>
                                        <div className={style_td_col_3.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['bus_name']} 
                                                onChange={(e) => handleChangeBusName(e, index)} />
                                        </div>
                                        <div className={style_td_col_9.join(" ")}>
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="バースタイプを選択してください。"
                                                defaultValue={data['standard']}
                                                onChange={(value) => handleChangeStandard(value, index)}
                                            >
                                                {children}
                                            </Select> 
                                         </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData}>削除</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default BusSetting;