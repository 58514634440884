/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './ChangeAmountSlip.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import RotModal from '../../../components/common/modal/RotModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import Modal2 from '../../../components/common/modal/Modal2';
import ArrivalTableModal from '../../../components/common/modal/TableModal';
import LeavingTableModal from '../../../components/common/modal/TableModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import InputDatepickerTable from '../../../components/common/input/InputDatepickerTable';
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Popover} from 'antd';

const ChangeAmountSlip = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [showModalDel2, setShowModalDel2] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // sys_change_amount_id
    const [SysChangeAmountId, setSysChangeAmountId] = useState(0);
    // change_amount_date
    const [SlipDate, setSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeSlipDate = (date) => {
        setSlipDate(useJwt.formatDate(date));
    };
    // slip no -------------------------------------------------
    const [SlipNo, setSlipNo] = useState("");
    const handleChangeSlipNo = (event) => {
        setSlipNo(useJwt.validateNum(event.target.value,6));
    };
    // shipper ---------------------------------------------------
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        setShipperCode(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode, setShipperACode] = useState("");
    const handleChangeShipperACode = (event) => {
        setShipperACode(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName, setShipperName] = useState("");
    const onKeyDownShipperCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            if(param == 1) {
                getShipperData(event.target.value,ShipperACode);
                document.getElementById('shipper_a_code').focus();
            }else{
                getShipperData(ShipperCode,event.target.value);
                document.getElementById('addRow').focus();
            }
            event.preventDefault();
        }
    };
    const setShipperData = (result) => {
        if(result['shipper_code'] != null) {
            setShipperCode(result['shipper_code']);
            setShipperACode(result['shipper_a_code']);
            setShipperName(result['shipper_name']);
        }else{
            setShipperCode("");
            setShipperACode("");
            setShipperName("");
        }
        gridLeavingTable([]);
        gridArrivalTable([]);
    }
    const getShipperData = (code, acode) => {
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }else{
                data['shipper_a_code'] = "000";
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result);
                    }else{
                        setShipperData([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([]);
        }
    }
   
    // ====================================== table ====================================
    const [LeavingTableData, setLeavingTableData] = useState([]);
    const [ArrivalTableData, setArrivalTableData] = useState([]);

    const gridLeavingTable = (result) => {
        setLeavingTableData(result);
    }
    const gridArrivalTable = (result) => {
        setArrivalTableData(result);
    }
    const handleChangeItemData = (event, control_type, col , index, change_type) => {
        let value = '';
        if(control_type==2){
            value = useJwt.formatDate(event);
        }else{
            value = event.target.value;
        }

        // if(col == 'item_num' && change_type == 'arrival'){
        //     var weight = $('.arrival_table_weight').eq(index).val();
        //     var rotWeight = $('.arrival_rot_weight').eq(index).val();

        //     if(parseFloat(weight) + parseFloat(rotWeight * value) > 1){
        //         var i = 1;
        //         while(parseFloat(weight) + parseFloat(rotWeight * i) <= 1){
        //             i++;
        //         }
        //         toast.error("数量を超えました。設定した棚に" + (i - 1) +"個以上入庫できません。", {autoClose:3000});
        //         $('.arrival_item_num').eq(index).val("");
        //         return;
        //     }
        // }
        if(col == 'item_num' && change_type == 'leaving'){
            var stock_num = $('.stock_num').eq(index).val();

            if(parseFloat(stock_num) < value){
                toast.error("在庫数量を超えています", {autoClose:3000});
                value="";
            }
        }

        if(col == 'item_code' || col == 'rot_code'){
            value = useJwt.validateString(value,15);
        }else if(col == 'item_a_code'){
            value = useJwt.validateNum(value,3);
        }else if(col == 'shipper_item_code'){
            value = useJwt.validateString(value,15);
        }else if(col == 'warehouse_code'){
            value = useJwt.validateNum(value,2);
        }else if(col == 'table_code'){
            value = useJwt.validateString(value,8);
        }else if(col == 'item_unit') {
            value = useJwt.validateString(value,4);
        }else if(col == 'item_num' || col == 'case_num' || col == 'bara_num') {
            value = useJwt.validateDecimal(value,6,3);
        }else if(col == 'remark') {
            value = useJwt.validateString(value,10);
        }
        let data = [];
        if(change_type == 'leaving'){
            for(var i=0; i<LeavingTableData.length; i++) {
                let row = {};
                row = LeavingTableData[i];
                if(i == index){
                    row[col] = value;
                    if(col == 'item_code' || col == 'item_a_code') {
                        row['shipper_item_code'] = "";
                        row['rot_code'] = "";
                        row['case_num'] = "";
                        row['bara_num'] = "";
                    }
                    if(col == 'item_code' || col == 'item_a_code' || col == 'rot_code' || col == 'warehouse_code'){
                        getItemRemainNum('leaving',index, SlipDate, ShipperCode, row['item_code']
                        , row['item_a_code'], row['rot_code'], row['warehouse_code']);
                    }
                    if(col == 'item_num'){
                        let arrival_item_num = row[col] * row['item_quantity'];
                        ArrivalTableData[i]['item_num'] = arrival_item_num;
                    }
                }
                data[i] = row;
            }
            gridLeavingTable(data);
        }else{
            for(var i=0; i<ArrivalTableData.length; i++) {
                let row = {};
                row = ArrivalTableData[i];
                if(i == index){
                    row[col] = value;
                    if(col == 'item_code' || col == 'item_a_code') {
                        row['shipper_item_code'] = "";
                        row['rot_code'] = "";
                        row['case_num'] = "";
                        row['bara_num'] = "";
                    }
                    if(col == 'item_code' || col == 'item_a_code' || col == 'rot_code' || col == 'warehouse_code'){
                        getItemRemainNum('arrival',index, SlipDate, ShipperCode, row['item_code']
                        , row['item_a_code'], row['rot_code'], row['warehouse_code']);
                    }
                }
                data[i] = row;
            }
            gridArrivalTable(data);
        }
        
    }
    const allCheckData = (event) => {
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', event.target.checked);
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };
    const newRecord = (event) => {
        var row = {};
        row['item_code'] = "";      row['item_a_code'] = "";    row['shipper_item_code'] = "";
        row['item_name'] = "";      row['rot_code'] = "";       row['rot_name'] = "";
        row['warehouse_code'] = ""; row['warehouse_name'] = ""; row['table_code'] = "";
        row['location_data'] = [];  row['item_unit'] = 0;       row['unit_weight'] = 0; 
        row['unit_volume'] = 0;     row['item_quantity'] = 0;   row['case_num'] = 0; 
        row['bara_num'] = 0;        row['item_num'] = 0;        row['item_deadline_date'] = useJwt.formatDate(new Date());
        row['remark'] = "";          row['remain_qty'] = 0;
        row['warehouse_type'] = 0;  row['stock_num'] = 0;
        let data = [];
        for(var i=0; i<LeavingTableData.length; i++) {
            let row1 = {};
            row1 = LeavingTableData[i];
            data[i] = row1;
        }
        data[i] = row; 
        gridLeavingTable(data);

        let row2 = {};
        row2['item_code'] = "";     row2['item_a_code'] = "";   row2['shipper_item_code'] = "";
        row2['item_name'] = "";     row2['rot_code'] = "";      row2['rot_name'] = "";
        row2['warehouse_code'] =""; row2['warehouse_name'] = "";row2['table_code'] = "";
        row2['location_data'] = []; row2['item_unit'] = 0;      row2['unit_weight'] = 0;
        row2['unit_volume'] = 0;    row2['item_quantity'] = 0;  row2['case_num'] = 0;
        row2['bara_num'] = 0;       row2['item_num'] = 0;       row2['item_deadline_date'] = useJwt.formatDate(new Date());
        row2['remark'] = "";         row2['remain_qty'] = 0;
        row2['warehouse_type'] = 0;
        let data2 = [];
        for(var i=0; i<ArrivalTableData.length; i++) {
            let row3 = {};
            row3 = ArrivalTableData[i];
            data2[i] = row3;
        }
        data2[i] = row2; 
        gridArrivalTable(data2);
        
        setTimeout(()=>{
            let endRow = LeavingTableData.length;
            document.getElementById('table1_warehouse_code'+endRow).focus();
        },10)

    }
    const deleteItem = (event) => {
        setShowModalDel2(false);
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked){
                remainIndexs.push(i);
            }
        }
        
        let remainData = [];
        for(var i=0; i<LeavingTableData.length; i++) {
            for(var j=0; j<remainIndexs.length; j++){
                if(i==remainIndexs[j]){
                    let row = {};
                    row = LeavingTableData[i];
                    remainData.push(row);
                }
            }
        }

        let remainData2 = [];
        for(var i=0; i<ArrivalTableData.length; i++) {
            for(var j=0; j<remainIndexs.length; j++){
                if(i==remainIndexs[j]){
                    let row = {};
                    row = ArrivalTableData[i];
                    remainData2.push(row);
                }
            }
        }
        gridLeavingTable(remainData);
        gridArrivalTable(remainData2);
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', false);
    }
    // init, save, delete, =========================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_daily_flag_4'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	}, [authentication, navigate, loaded, menuPatternData]);

    const getItemRemainNum = (change_type, index,date, shipper_code, item_code, item_a_code, rot_code, warehouse_code) => {
        let data = {};
        data['userID'] = userData['id'];
        data['end_slip_date'] = date;
        if(shipper_code != ""){
            data['shipper_code'] = shipper_code;
        }
        if(ShipperACode != ""){
            data['shipper_a_code'] = ShipperACode;
        }
        if(item_code != ""){
            data['item_code'] = item_code;
        }
        if(item_a_code != ""){
            data['item_a_code'] = item_a_code;
        }
        if(rot_code != ""){
            data['rot_code'] = rot_code;
        }
        if(warehouse_code != ""){
            data['start_warehouse_code'] = warehouse_code;
            data['end_warehouse_code'] = warehouse_code;
        }

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockHistoryByItemEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            let item_remain_num = 0;
            if(result.length>0) {
                let total_in_num = 0;
                let total_out_num = 0;
                for(var i=0; i<result.length; i++) {
                    if(result[i]['arrival_num'] != "") {
                        total_in_num += (1*result[i]['arrival_num']);
                    }else if(result[i]['leaving_num'] != "") {
                        total_out_num += (1*result[i]['leaving_num']);
                    }
                }
                item_remain_num = total_in_num-total_out_num;
            }
            let data1 = [];
            if(change_type== 'leaving'){
                for(var j=0; j<LeavingTableData.length; j++) {
                    let row = {};
                    row = LeavingTableData[j];
                    if(j == index){
                        row['remain_qty'] = item_remain_num.toFixed(3);
                    }
                    data1[j] = row;
                }
                gridLeavingTable(data1);
            }else{
                for(var j=0; j<ArrivalTableData.length; j++) {
                    let row = {};
                    row = ArrivalTableData[j];
                    if(j == index){
                        row['remain_qty'] = item_remain_num.toFixed(3);
                    }
                    data1[j] = row;
                }
                gridArrivalTable(data1);
            }
        })
        .catch((error) => {
        });
    }

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysChangeAmountId>0) {
            data['sys_change_amount_id'] = SysChangeAmountId;
        }

        if(SlipDate != "") {
            data['change_amount_date'] = SlipDate;
        }else{
            toast.error("年月日を入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }
        if(SlipNo != "") {
            data['slip_no'] = SlipNo;
        }else{
            toast.error("伝票Noを入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }
        if(ShipperCode != "") {
            data['shipper_code'] = ShipperCode;
        }else{
            toast.error("荷主コードを入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }
        data['shipper_a_code'] = ShipperACode;

        // item data --------------------------------------
        let validate_flg = true;
        let vmsg = "";
        let itemData = [];
        let j = 0;

        for(var i=0; i<LeavingTableData.length; i++) {
            let row = {};
            row['row_index'] = i;
            if(LeavingTableData[i]['item_code'] != "") {
                row['item_code'] = LeavingTableData[i]['item_code'];
            }else{
                vmsg = "品番コードを入力してください。";       
                validate_flg = false;
                break;
            }
            row['item_a_code'] = LeavingTableData[i]['item_a_code'];
            row['shipper_item_code'] = LeavingTableData[i]['shipper_item_code'];
            row['rot_code'] = LeavingTableData[i]['rot_code'];
            if(LeavingTableData[i]['warehouse_code'] != "") {
                row['warehouse_code'] = LeavingTableData[i]['warehouse_code'];
            }else{
                vmsg = "倉庫コードを入力してください。";       
                validate_flg = false;
                break;
            }

            if(LeavingTableData[i]['table_code'] != "") {
                row['table_code'] = LeavingTableData[i]['table_code'];
            }else if(LeavingTableData[i]['warehouse_type'] != 0){
                vmsg = "変更元棚コードを入力してください。";       
                validate_flg = false;
                break;
            }
            if(LeavingTableData[i]['case_num'] != "") {
                row['case_num'] = LeavingTableData[i]['case_num'];
            }else{
                row['case_num'] = '';
            }
            if(LeavingTableData[i]['bara_num'] != "") {
                row['bara_num'] = LeavingTableData[i]['bara_num'];
            }else{
                row['bara_num'] = '';
            }
            if(LeavingTableData[i]['item_num'] != "") {
                if((LeavingTableData[i]['item_num']*1)>(LeavingTableData[i]['remain_qty'])){
                    vmsg = "数量が現在在庫数より大きいです。";       
                    validate_flg = false;
                    break;
                }else{
                    row['item_num'] = LeavingTableData[i]['item_num'];
                }
            }else{
                vmsg = "数量を入力してください。";       
                validate_flg = false;
                break;
            }
            if(LeavingTableData[i]['item_deadline_date'] != "") {
                row['item_deadline_date'] = LeavingTableData[i]['item_deadline_date'];
            }else{
                vmsg = "商品期限を入力してください。";       
                validate_flg = false;
                break;
            }
            row['remark'] = LeavingTableData[i]['remark'];
            row['change_item_flag'] = 0;
            
            itemData[i] = row;
            j++;
        }
        for(var i=0; i<ArrivalTableData.length; i++) {
            let row = {};
            row['row_index'] = i;
            if(ArrivalTableData[i]['item_code'] != "") {
                row['item_code'] = ArrivalTableData[i]['item_code'];
            }else{
                vmsg = "品番コードを入力してください。";       
                validate_flg = false;
                break;
            }
            row['item_a_code'] = ArrivalTableData[i]['item_a_code'];
            row['shipper_item_code'] = ArrivalTableData[i]['shipper_item_code'];
            row['rot_code'] = ArrivalTableData[i]['rot_code'];
            if(ArrivalTableData[i]['warehouse_code'] != "") {
                row['warehouse_code'] = ArrivalTableData[i]['warehouse_code'];
            }else{
                vmsg = "倉庫コードを入力してください。";       
                validate_flg = false;
                break;
            }
            if(ArrivalTableData[i]['table_code'] != "") {
                row['table_code'] = ArrivalTableData[i]['table_code'];
            }else if(row['warehouse_type'] != 0){
                vmsg = "変更先棚コードを入力してください。";       
                validate_flg = false;
                break;
            }
            if(ArrivalTableData[i]['case_num'] != "") {
                row['case_num'] = ArrivalTableData[i]['case_num'];
            }else{
                row['case_num'] = '';
            }
            if(ArrivalTableData[i]['bara_num'] != "") {
                row['bara_num'] = ArrivalTableData[i]['bara_num'];
            }else{
                row['bara_num'] = '';
            }
            if(ArrivalTableData[i]['item_num'] != "") {
                row['item_num'] = ArrivalTableData[i]['item_num'];
            }else{
                vmsg = "数量を入力してください。";       
                validate_flg = false;
                break;
            }
            if(ArrivalTableData[i]['item_deadline_date'] != "") {
                row['item_deadline_date'] = ArrivalTableData[i]['item_deadline_date'];
            }else{
                vmsg = "商品期限を入力してください。";       
                validate_flg = false;
                break;
            }
            row['remark'] = ArrivalTableData[i]['remark'];
            row['change_item_flag'] = 1;
            
            itemData[j] = row;
            j++
        }
        if(validate_flg){
            data['items'] = itemData;
            if(SysChangeAmountId == 0) {
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setChangeItemsSlipItemsEndpoint;
                csloading(true);
                axios
                .post(url, data, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    if(response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if(response.data.data == 1) {
                            toast.success("登録致しました。", {autoClose:3000});
                        }else if(response.data.data == 2){
                            toast.error("すでに登録済みです。", {autoClose:3000});
                        }else{
                            toast.error("登録に失敗しました。", {autoClose:3000});
                        }
                    }
                    csloading(false);
                })
                .catch((error) => {
                    csloading(false);
                    if(error.response && error.response.status && error.response.status === 401) {
                        useJwt.logout();
                        navigate("/login");
                    }else{
                        toast.error(error.response.data.message, {autoClose:3000});
                    }
                });
            }else{
                toast.error("この伝票はすでに登録されています。", {autoClose:3000});
            }
        }else{
            toast.error(vmsg, {autoClose:3000});  
        }
        setOpen(false);
    }
    
    const loadModalDelData2 = (event) => {
        event.preventDefault();
        setShowModalDel2(true);
    }
    const deleteData = (event) => {
        event.preventDefault();
    }
    // ========================== starts code for search ===============================
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event) => {
        event.preventDefault();
        setShipperSearchValue(ShipperCode);
        setShipperSearchValue2(ShipperACode);
        if(ShipperCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const [ChangeItemType, setChangeItemType] = useState("leaving");
    const [ItemSearchWarehouse, setItemSearchWarehouse] = useState("");
    const [ItemSearchInOutFlag, setItemSearchInOutFlag] = useState("2");
    const loadItemModalData = (event,change_type,id, code, acode) => {
        setChangeItemType(change_type);
        document.getElementById('selectedId').value=id;
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode);

        if(change_type == "leaving"){
            var warehouse_code = $(".leaving_warehouse_code").eq(id).val();
            setItemSearchWarehouse(warehouse_code);
            setItemSearchInOutFlag("1");
        }else{
            setItemSearchInOutFlag("0");
        }
        if(code != "" && ShipperCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            data['shipper_code'] = ShipperCode;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            if(change_type == "leaving"){
                url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemStockEndpoint;
            }
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            if(ShipperCode != ""){
                setShowModalItem(true);
            }else{
                setShowModalItem(false);
            }
        }
    }
    const setItemData = (data, change_type) => {
        let item_code = "";
        let item_a_code = "";
        let item_name = "";
        let item_unit = "";
        let unit_weight = 0;
        let unit_volume = 0;
        let item_quantity = 0;
        let stock_num = 0;
        if(data['item_code'] && data['shipper_code'] == ShipperCode) {
            item_code = data['item_code'];
            item_a_code = data['item_a_code'];
            item_name = data['item_name'];
            item_unit = data['item_unit'];
            unit_weight = data['unit_weight'];
            unit_volume = data['unit_volume'];
            item_quantity = data['item_quantity'];
            stock_num = data['amount'];
        }
        
        let itemData = [];
        if(change_type == 'leaving') {
            for(var i=0; i<LeavingTableData.length; i++) {
                let row = {};
                row = LeavingTableData[i];
                if(i==document.getElementById('selectedId').value){
                    row['item_code'] = item_code;
                    row['item_a_code'] = item_a_code;
                    row['item_name'] = item_name;
                    row['item_unit'] = item_unit;
                    row['unit_weight'] = unit_weight;
                    row['unit_volume'] = unit_volume;
                    row['item_quantity'] = item_quantity;
                    getItemRemainNum('leaving',i, SlipDate, ShipperCode, row['item_code']
                        , row['item_a_code'], row['rot_code'], row['warehouse_code']);
                    row['stock_num'] = stock_num;
                }
                itemData[i] = row;
            }
            gridLeavingTable(itemData);
        }else{
            for(var i=0; i<ArrivalTableData.length; i++) {
                let row = {};
                row = ArrivalTableData[i];
                if(i==document.getElementById('selectedId').value){
                    row['item_code'] = item_code;
                    row['item_a_code'] = item_a_code;
                    row['item_name'] = item_name;
                    row['item_unit'] = item_unit;
                    row['unit_weight'] = unit_weight;
                    row['unit_volume'] = unit_volume;
                    row['item_quantity'] = item_quantity;
                    getItemRemainNum('arrival',i, SlipDate, ShipperCode, row['item_code']
                        , row['item_a_code'], row['rot_code'], row['warehouse_code']);
                }
                itemData[i] = row;
            }
            gridArrivalTable(itemData);
        }
        
    }

    // -----------------------------arrival Table modal -------------------------------------
    const [arrivalShowModalTable, setArrivalShowModalTable] = useState(false);
    const [arrivalTableSearchValue, setArrivalTableSearchValue] = useState("");
    const [arrivalTableSearchValue2, setArrivalTableSearchValue2] = useState("");
    const [arrivalTableSearchValue3, setArrivalTableSearchValue3] = useState("");
    const [arrivalModalTableData, setArrivalModalTableData] = useState([]);
    const loadArrivalTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = $(".arrival_warehouse_code").eq(idx).val();

        var rot_weight = $(".arrival_rot_weight").eq(idx).val();

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setArrivalTableSearchValue(table_code);
        setArrivalTableSearchValue2(warehouse_code);
        setArrivalTableSearchValue3(rot_weight);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;
            data['weight'] = rot_weight;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setArrivalModalTableData(response.data.data);
                setArrivalShowModalTable(true);
            })
            .catch((error) => {
            });
        }else{
            setArrivalModalTableData([]);
            setArrivalShowModalTable(true);
        }
    }
    const setArrivalTableCode = (data) => {
        let itemData = [];
        for(var i=0; i<ArrivalTableData.length; i++) {
            let row = {};
            row = ArrivalTableData[i];
            if(i==document.getElementById('selectedId').value){
                if(data) {
                    row['table_code'] = data['table_code'];
                    row['weight'] = data['weight'];
                }else{
                    row['table_code'] = "";
                    row['weight'] = 0;
                }
            }
            itemData[i] = row;
        }
        gridArrivalTable(itemData);
    }


    // -----------------------------leaving Table modal -------------------------------------
    const [leavingShowModalTable, setLeavingShowModalTable] = useState(false);
    const [leavingTableSearchValue, setLeavingTableSearchValue] = useState("");
    const [leavingTableSearchValue2, setLeavingTableSearchValue2] = useState("");
    const [leavingTableSearchValue3, setLeavingTableSearchValue3] = useState("");
    const [leavingModalTableData, setLeavingModalTableData] = useState([]);
    const loadLeavingTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = $(".leaving_warehouse_code").eq(idx).val();
        // var rot_weight = $(".rot_weight").eq(idx).val();

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setLeavingTableSearchValue(table_code);
        setLeavingTableSearchValue2(warehouse_code);
        setLeavingTableSearchValue3(0);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['leaving_table_code'] = table_code;
            // data['leaving_weight'] = rot_weight;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLeavingModalTableData(response.data.data);
                setLeavingShowModalTable(true);
            })
            .catch((error) => {
            });
        }else{
            setLeavingModalTableData([]);
            setLeavingShowModalTable(true);
        }
    }
    const setLeavingTableCode = (data) => {
        let itemData = [];
        for(var i=0; i<LeavingTableData.length; i++) {
            let row = {};
            row = LeavingTableData[i];
            if(i==document.getElementById('selectedId').value){
                if(data) {
                    row['table_code'] = data['table_code'];
                    row['weight'] = data['weight'];
                }else{
                    row['table_code'] = "";
                    row['weight'] = 0;
                }
            }
            itemData[i] = row;
        }
        gridLeavingTable(itemData);
    }

    // ------------------------------ rot modal ---------------------------------------
    const [showModalRot, setShowModalRot] = useState(false);
    const [RotSearchValue, setRotSearchValue] = useState("");
    const [RotSearchValue2, setRotSearchValue2] = useState("");
    const [RotSearchValue3, setRotSearchValue3] = useState("");
    const [RotSearchValue4, setRotSearchValue4] = useState("");
    const [RotSearchWarehouse, setRotSearchWarehouse] = useState("");
    const [RotSearchInOutFlag, setRotSearchInOutFlag] = useState("");
    const [RotTableData, setRotTableData] = useState([]);
    const loadRotModalData = (event,change_type,id, rot_code, shipper_code, item_code,item_a_code) => {
        setChangeItemType(change_type);
        document.getElementById('selectedId').value=id;
        setRotSearchValue(rot_code);
        setRotSearchValue2(shipper_code);
        setRotSearchValue3(item_code);
        setRotSearchValue4(item_a_code);

        if(change_type == "leaving"){
            var warehouse_code = $(".leaving_warehouse_code").eq(id).val();
            setItemSearchWarehouse(warehouse_code);
            setRotSearchInOutFlag("1");
        }else{
            setRotSearchInOutFlag("0");
        }
        if(rot_code != "" && shipper_code != "" && item_code != "" && item_a_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['rot_code'] = rot_code;
            data['shipper_code'] = shipper_code;
            data['item_code'] = item_code;
            data['item_a_code'] = item_a_code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstRotEndpoint;
            if(change_type == "leaving"){
                url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstRotStockEndpoint;
            }
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setRotTableData(response.data.data);
                setShowModalRot(true);
            })
            .catch((error) => {
            });
        }else{
            setRotTableData([]);
            if(shipper_code != "" && item_code != "" && item_a_code != "") {
                setShowModalRot(true);
            }else{
                setShowModalRot(false);
            }
        }
    }
    const setRotData = (data,change_type) => {

        let itemData = [];
        if(change_type == 'leaving') {
            for(var i=0; i<LeavingTableData.length; i++) {
                let row = {};
                row = LeavingTableData[i];
                if(i==document.getElementById('selectedId').value){
                    if(data['shipper_code'] == ShipperCode 
                    && data['item_code'] == row['item_code'] && data['item_a_code']==row['item_a_code']) {
                        row['rot_code'] = data['rot_code'];
                        row['rot_name'] = data['rot_name'];

                        if(data['limit_amount'])    row['rot_weight'] = Math.round((1 / data['limit_amount']) * 10000) / 10000;
                        else    row['rot_weight'] = 0;
                        row['stock_num'] = data['amount'];
                    }else{
                        row['rot_code'] = "";
                        row['rot_name'] = "";
                        row['rot_weight'] = "";
                        row['stock_num'] = 0;
                    }
                    getItemRemainNum('leaving',i, SlipDate, ShipperCode, row['item_code']
                        , row['item_a_code'], row['rot_code'], row['warehouse_code']);
                }
                itemData[i] = row;
            }
            gridLeavingTable(itemData);
        }else{
            for(var i=0; i<ArrivalTableData.length; i++) {
                let row = {};
                row = ArrivalTableData[i];
                if(i==document.getElementById('selectedId').value){
                    if(data['shipper_code'] == ShipperCode 
                    && data['item_code'] == row['item_code'] && data['item_a_code']==row['item_a_code']) {
                        row['rot_code'] = data['rot_code'];
                        row['rot_name'] = data['rot_name'];
                        if(data['limit_amount'])    row['rot_weight'] = Math.round((1 / data['limit_amount']) * 10000) / 10000;
                        else    row['rot_weight'] = 0;

                    }else{
                        row['rot_code'] = "";
                        row['rot_name'] = "";
                        row['rot_weight'] = "";
                    }
                    getItemRemainNum('arrival',i, SlipDate, ShipperCode, row['item_code']
                        , row['item_a_code'], row['rot_code'], row['warehouse_code']);
                }
                itemData[i] = row;
            }
            gridArrivalTable(itemData);
        }
    }
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const loadWarehouseModalData = (event,change_type,id,warehouse_code) => {
        event.preventDefault();
        setChangeItemType(change_type);
        document.getElementById('selectedId').value=id;
        setWarehouseSearchValue(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(warehouse_code != "") {
                data['warehouse_code'] = warehouse_code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    const setWarehouseData = (data, change_type) => {
        let itemData = [];
        if(change_type == 'leaving') {
            for(var i=0; i<LeavingTableData.length; i++) {
                let row = {};
                row = LeavingTableData[i];
                if(i==document.getElementById('selectedId').value){
                    if(data['warehouse_code']) {
                        row['warehouse_code'] = data['warehouse_code'];
                        row['warehouse_name'] = data['warehouse_name'];
                        row['warehouse_type'] = data['warehouse_type'];
                    }else{
                        row['warehouse_code'] = "";
                        row['warehouse_name'] = "";
                        row['warehouse_type'] = "";
                    }
                    getItemRemainNum('leaving',i, SlipDate, ShipperCode, row['item_code']
                        , row['item_a_code'], row['rot_code'], row['warehouse_code']);
                }
                itemData[i] = row;
            }
            gridLeavingTable(itemData);
        }else{
            for(var i=0; i<ArrivalTableData.length; i++) {
                let row = {};
                row = ArrivalTableData[i];
                if(i==document.getElementById('selectedId').value){
                    if(data['warehouse_code']) {
                        row['warehouse_code'] = data['warehouse_code'];
                        row['warehouse_name'] = data['warehouse_name'];
                        row['warehouse_type'] = data['warehouse_type'];
                    }else{
                        row['warehouse_code'] = "";
                        row['warehouse_name'] = "";
                        row['warehouse_type'] = "";
                    }
                    getItemRemainNum('arrival',i, SlipDate, ShipperCode, row['item_code']
                        , row['item_a_code'], row['rot_code'], row['warehouse_code']);
                }
                itemData[i] = row;
            }
            gridArrivalTable(itemData);
        }
    }

    const onKeyDownSlip = (e) =>{
        if(e.keyCode == 13){
            document.getElementById('shipper_code').focus();
            e.preventDefault();
        }
    }
    
    const tableKeyDown = ( e, id, index ) =>{
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    const leavingTableEndKeyDown = ( e, id, index ) =>{
        let dataLength = LeavingTableData.length;

        if(dataLength <= index + 1)  return;
        
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }
    
    const arrivalTableEndKeyDown = ( e, id, index ) =>{
        let dataLength = ArrivalTableData.length;

        if(dataLength <= index + 1)  return;
        
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_total_input_wrap = [gstyle.p0015, gstyle.mt30];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];
    const style_tbl_input_bl_n = [style.cs_tbl_left_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_th_w4p = [style.cs_th, style.w4p];
    const style_th_w5p = [style.cs_th, style.w5p];
    const style_th_w7p = [style.cs_th, style.w7p];
    const style_th_w8p = [style.cs_th, style.w8p];
    const style_th_w9p = [style.cs_th, style.w9p];
    const style_th_w12p = [style.cs_th, style.w12p];
    const style_th_w15p = [style.cs_th, style.w15p];

    const style_td_w4p = [style.cs_td, style.w4p];
    const style_td_w5p = [style.cs_td, style.w5p];
    const style_td_w7p = [style.cs_td, style.w7p];
    const style_td_w8p = [style.cs_td, style.w8p];
    const style_td_w9p = [style.cs_td, style.w9p];
    const style_td_w12p = [style.cs_td, style.w12p];
    const style_td_w15p = [style.cs_td, style.w15p];

    const style_tbl_title = [style.cs_th, style.total_label, style.border_none];

    
    const style_td_w4p_text_right = [style.cs_td, style.w4p, style.td_number_right];
    const style_td_input_right = [style.cs_tbl_input, style.td_input_right]

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} warehouseCode={ItemSearchWarehouse} inOutFlag={ItemSearchInOutFlag}
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={ChangeItemType} />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={ChangeItemType} />
            <RotModal show={showModalRot} onClose={()=>setShowModalRot(false)} width={'70%'}
                SearchValue={RotSearchValue} onChange={setRotSearchValue} 
                SearchValue2={RotSearchValue2} SearchValue3={RotSearchValue3} SearchValue4={RotSearchValue4}
                tableData={RotTableData} setTableData={setRotTableData} warehouseCode={RotSearchWarehouse} inOutFlag={RotSearchInOutFlag}
                target={setRotData} targetParam={ChangeItemType} />
            <Modal2 title="単位変更処理削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <Modal2 title="単位変更処理削除" show={showModalDel2} onClose={()=>setShowModalDel2(false)} width={'40%'} 
                onConfirm={deleteItem}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <ArrivalTableModal show={arrivalShowModalTable} onClose={()=>setArrivalShowModalTable(false)} width={'70%'}
                SearchValue={arrivalTableSearchValue} SearchValue2={arrivalTableSearchValue2} SearchValue3={arrivalTableSearchValue3}  onChange={setArrivalTableSearchValue} 
                tableData={arrivalModalTableData} setTableData={setArrivalModalTableData}
                target={setArrivalTableCode} />
            <LeavingTableModal show={leavingShowModalTable} onClose={()=>setLeavingShowModalTable(false)} width={'70%'}
                SearchValue={leavingTableSearchValue} SearchValue2={leavingTableSearchValue2} SearchValue3={leavingTableSearchValue3}  onChange={setLeavingTableSearchValue} 
                tableData={leavingModalTableData} setTableData={setLeavingModalTableData}
                target={setLeavingTableCode} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>単位変更処理</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 年月日, 伝票No, 問合せNo */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={SlipDate} onChange={handleChangeSlipDate} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>伝票No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")} 
                                        value={SlipNo} onChange={handleChangeSlipNo} id="slip_number" onKeyDown={(e)=>onKeyDownSlip(e)}/>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1)} id={"shipper_code"}
                                        value={ShipperCode} onChange={handleChangeShipperCode}/>
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2)} id={"shipper_a_code"}
                                        value={ShipperACode} onChange={handleChangeShipperACode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="ShipperName"
                                        value={ShipperName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        { /* 追加, 削除 */}
                        <div className={style_col_4.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")}>
                                <button className={gstyle.btn_new} onClick={newRecord} id="addRow">追加</button>
                                <button className={gstyle.btn_delete2} onClick={loadModalDelData2} >削除</button>
                            </div>
                        </div>
                    </div>
                    {/* 変更元 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_2.join(" ")}>
                            <div className={style_total_input_wrap.join(" ")}>
                                <div className={style_tbl_title.join(" ")}>変更元</div>
                            </div>
                        </div>
                    </div>
                    {/* leaving table */}
                    <div className={style.tbl_wrap}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_w7p.join(" ")}>倉庫</div>
                                    <div className={style_th_w7p.join(" ")}>倉庫名</div>
                                    <div className={style_th_w15p.join(" ")}>品番</div>
                                    <div className={style_th_w5p.join(" ")}>荷主品番</div>
                                    <div className={style_th_w12p.join(" ")}>品名</div>
                                    <div className={style_th_w8p.join(" ")}>ロットNo</div>
                                    <div className={style_th_w8p.join(" ")}>ロット名</div>
                                    <div className={style_th_w7p.join(" ")}>棚コード</div>
                                    <div className={style_th_w4p.join(" ")}>単位</div>
                                    <div className={style_th_w4p.join(" ")}>入数</div>
                                    <div className={style_th_w4p.join(" ")}>ケース</div>
                                    <div className={style_th_w4p.join(" ")}>バラ</div>
                                    <div className={style_th_w5p.join(" ")}>数量</div>
                                    <div className={style_th_w7p.join(" ")}>商品期限</div>
                                    <div className={style_th_w5p.join(" ")}>備考</div>
                                    <div className={style_th_w9p.join(" ")}>現在在庫数</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  LeavingTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                            id={'chk_'+index} onChange={handleChangeChk} />
                                        </div>
                                        <div className={style_td_w7p.join(" ")}>
                                            <input type={'number'} className="leaving_warehouse_code" 
                                                style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                                padding: '0px 5px',color: '#565656',fontSize: 13,outline: 0}}
                                                value={data['warehouse_code']?data['warehouse_code']:''} id={'table1_warehouse_code'+index}
                                                onChange={(e) => handleChangeItemData(e,1,'warehouse_code',index,'leaving')} 
                                                onKeyDown={(e) => tableKeyDown(e, "table1_item_code"+index, index)}
                                                />
                                            <div className={gstyle.icon_search_wrap} 
                                                onClick={(e)=>loadWarehouseModalData(e,'leaving',index,data['warehouse_code']?data['warehouse_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                        <div className={style_td_w7p.join(" ")}>
                                            {data['warehouse_name']?data['warehouse_name']:''}
                                        </div>
                                        <div className={style_td_w15p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            value={data['item_code']?data['item_code']:''} id={'table1_item_code'+index}
                                            onChange={(e) => handleChangeItemData(e,1,'item_code',index,'leaving')} 
                                            onKeyDown={(e) => tableKeyDown(e, "table1_item_a_code"+index, index)}
                                            />
                                            <input type={'number'} className={style_tbl_input_bl_n.join(" ")} 
                                            value={data['item_a_code']?data['item_a_code']:''} id={'table1_item_a_code'+index}
                                            onChange={(e) => handleChangeItemData(e,1,'item_a_code',index,'leaving')} 
                                            onKeyDown={(e) => tableKeyDown(e, "table1_shipper_item_code"+index, index)}
                                            />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadItemModalData(e,'leaving',index,data['item_code']?data['item_code']:'',data['item_a_code']?data['item_a_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} id={'table1_shipper_item_code'+index}
                                            value={data['shipper_item_code']?data['shipper_item_code']:''} 
                                            onChange={(e) => handleChangeItemData(e,1,'shipper_item_code',index,'leaving')} 
                                            onKeyDown={(e) => tableKeyDown(e, "table1_rot_code"+index, index)}
                                            />
                                        </div>
                                        <div className={style_td_w12p.join(" ")} style={{justifyContent:'left'}}>
                                            {data['item_name']?data['item_name']:''}
                                        </div>
                                        <div className={style_td_w8p.join(" ")}>
                                            <input type={'text'} className="rot_code" 
                                                style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                                padding: '0px 5px',color: '#565656',fontSize: 13,outline: 0}} id={'table1_rot_code'+index}
                                                value={data['rot_code']?data['rot_code']:''} onKeyDown={(e) => tableKeyDown(e, "table1_table_code"+index, index)}
                                                onChange={(e) => handleChangeItemData(e,1,'rot_code',index,'leaving')} />
                                            <div className={gstyle.icon_search_wrap} 
                                                onClick={(e)=>loadRotModalData(e,'leaving',index,data['rot_code']?data['rot_code']:''
                                                ,data['shipper_code']?data['shipper_code']:ShipperCode
                                                ,data['item_code']?data['item_code']:''
                                                ,data['item_a_code']?data['item_a_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            <input type={'hidden'} className="leaving_rot_weight" value={data['rot_weight']?data['rot_weight']:0}/>
                                        </div>
                                        <div className={style_td_w8p.join(" ")} style={{justifyContent:'left'}}>
                                            {data['rot_name']?data['rot_name']:''}
                                        </div>
                                        <div className={style_td_w7p.join(" ")} style={{display:'flex'}}>
                                            <input type={'number'} className={style.cs_tbl_input} 
                                            value={data['table_code']?data['table_code']:''} id={'table1_table_code'+index}
                                            onChange={(e) => handleChangeItemData(e,1,'table_code',index,'leaving')} 
                                            onKeyDown={(e) => tableKeyDown(e, "table1_case_num"+index, index)}
                                            />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadLeavingTableModalData(e,index,data['table_code']?data['table_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            <input type={'hidden'} className="leaving_table_weight" value={data['weight']?data['weight']:0}/>
                                            <input type={'hidden'} className="stock_num" value={data['stock_num']?data['stock_num']:0}/>
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            {data['item_unit']?data['item_unit']:''}
                                        </div>
                                        <div className={style_td_w4p_text_right.join(" ")}>
                                            {data['item_quantity']?data['item_quantity']:''}
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'text'} className={style_td_input_right.join(" ")} 
                                            value={data['case_num']?data['case_num']:''} id={'table1_case_num'+index}
                                            onChange={(e) => handleChangeItemData(e,1,'case_num',index,'leaving')} 
                                            onKeyDown={(e) => tableKeyDown(e, "table1_bara_num"+index, index)}
                                            />
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'text'} className={style_td_input_right.join(" ")} 
                                            value={data['bara_num']?data['bara_num']:''} id={'table1_bara_num'+index}
                                            onChange={(e) => handleChangeItemData(e,1,'bara_num',index,'leaving')} 
                                            onKeyDown={(e) => tableKeyDown(e, "table1_item_num"+index, index)}
                                            />
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                        {
                                            data['stock_num'] > 0 
                                            ?
                                            <Popover content={useJwt.numberWithCommas(data['stock_num'])} title="現在残" trigger="focus">
                                            <input type={'text'} className="leaving_item_num" 
                                                style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                                padding: '0px 5px',color: '#565656',fontSize: 13,outline: 0, textAlign:'end'}} 
                                                value={data['item_num']?data['item_num']:''}  id={'table1_item_num'+index}
                                                onChange={(e) => handleChangeItemData(e,1,'item_num',index,'leaving')} 
                                                onKeyDown={(e) => tableKeyDown(e, "date"+index, index)}
                                                />
                                            </Popover>
                                            :
                                            <input type={'text'} className="leaving_item_num" 
                                                style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                                padding: '0px 5px',color: '#565656',fontSize: 13,outline: 0, textAlign:'end'}} 
                                                value={data['item_num']?data['item_num']:''}  id={'table1_item_num'+index}
                                                onChange={(e) => handleChangeItemData(e,1,'item_num',index,'leaving')} 
                                                onKeyDown={(e) => tableKeyDown(e, "date"+index, index)}
                                                />
                                        }
                                        </div>
                                        <div className={style_td_w7p.join(" ")}>
                                            <InputDatepickerTable  value={data['item_deadline_date']?data['item_deadline_date']:''} 
                                            onChange={(e) => handleChangeItemData(e,2,'item_deadline_date',index,'leaving')} id={'date'+index}
                                            focusId={'table1_remark'+index} />
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} id={'table1_remark'+index}
                                            value={data['remark']?data['remark']:''} 
                                            onKeyDown={(e) => leavingTableEndKeyDown(e, "table1_warehouse_code"+(index+1), index)}
                                            onChange={(e) => handleChangeItemData(e,1,'remark',index,'leaving')} />
                                        </div>
                                        <div className={style_td_w9p.join(" ")}>
                                            <input type={'text'} className={style_td_input_right.join(" ")} disabled
                                                value={data['remain_qty']?data['remain_qty']:0} />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* 変更先 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_2.join(" ")}>
                            <div className={style_total_input_wrap.join(" ")}>
                                <div className={style_tbl_title.join(" ")}>変更先</div>
                            </div>
                        </div>
                    </div>
                    {/* arrival table */}
                    <div className={style.tbl_wrap}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_w7p.join(" ")}>倉庫</div>
                                    <div className={style_th_w7p.join(" ")}>倉庫名</div>
                                    <div className={style_th_w15p.join(" ")}>品番</div>
                                    <div className={style_th_w5p.join(" ")}>荷主品番</div>
                                    <div className={style_th_w12p.join(" ")}>品名</div>
                                    <div className={style_th_w8p.join(" ")}>ロットNo</div>
                                    <div className={style_th_w8p.join(" ")}>ロット名</div>
                                    <div className={style_th_w7p.join(" ")}>棚コード</div>
                                    <div className={style_th_w4p.join(" ")}>単位</div>
                                    <div className={style_th_w4p.join(" ")}>入数</div>
                                    <div className={style_th_w4p.join(" ")}>ケース</div>
                                    <div className={style_th_w4p.join(" ")}>バラ</div>
                                    <div className={style_th_w5p.join(" ")}>数量</div>
                                    <div className={style_th_w7p.join(" ")}>商品期限</div>
                                    <div className={style_th_w5p.join(" ")}>備考</div>
                                    <div className={style_th_w9p.join(" ")}>現在在庫数</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody}>
                                {  ArrivalTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_w7p.join(" ")}>
                                            <input type={'number'} className="arrival_warehouse_code" 
                                                style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                                padding: '0px 5px',color: '#565656',fontSize: 13,outline: 0}}
                                                value={data['warehouse_code']?data['warehouse_code']:''} id={'table2_warehouse_code'+index}
                                                onChange={(e) => handleChangeItemData(e,1,'warehouse_code',index,'arrival')} 
                                                onKeyDown={(e) => tableKeyDown(e, 'table2_item_code'+index, index)}
                                                />
                                            <div className={gstyle.icon_search_wrap} 
                                                onClick={(e)=>loadWarehouseModalData(e,'arrival',index,data['warehouse_code']?data['warehouse_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                        <div className={style_td_w7p.join(" ")}>
                                            {data['warehouse_name']?data['warehouse_name']:''}
                                        </div>
                                        <div className={style_td_w15p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['item_code']?data['item_code']:''} id={'table2_item_code'+index}
                                                onChange={(e) => handleChangeItemData(e,1,'item_code',index,'arrival')} 
                                                onKeyDown={(e) => tableKeyDown(e, 'table2_item_a_code'+index, index)}
                                            />
                                            <input type={'number'} className={style_tbl_input_bl_n.join(" ")} 
                                            value={data['item_a_code']?data['item_a_code']:''} id={'table2_item_a_code'+index}
                                            onChange={(e) => handleChangeItemData(e,1,'item_a_code',index,'arrival')} 
                                            onKeyDown={(e) => tableKeyDown(e, 'table2_shipper_item_code'+index, index)}
                                            />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadItemModalData(e,'arrival',index,data['item_code']?data['item_code']:'',data['item_a_code']?data['item_a_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} id={'table2_shipper_item_code'+index}
                                            value={data['shipper_item_code']?data['shipper_item_code']:''} 
                                            onChange={(e) => handleChangeItemData(e,1,'shipper_item_code',index,'arrival')} 
                                            onKeyDown={(e) => tableKeyDown(e, 'table2_rot_code'+index, index)}
                                            />
                                        </div>
                                        <div className={style_td_w12p.join(" ")}>
                                            {data['item_name']?data['item_name']:''}
                                        </div>
                                        <div className={style_td_w8p.join(" ")}>
                                            <input type={'text'} className="rot_code" 
                                                style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                                padding: '0px 5px',color: '#565656',fontSize: 13,outline: 0}} 
                                                value={data['rot_code']?data['rot_code']:''} id={'table2_rot_code'+index}
                                                onChange={(e) => handleChangeItemData(e,1,'rot_code',index,'arrival')} 
                                                onKeyDown={(e) => tableKeyDown(e, 'table2_table_code'+index, index)}
                                                />
                                            <div className={gstyle.icon_search_wrap} 
                                                onClick={(e)=>loadRotModalData(e,'arrival',index,data['rot_code']?data['rot_code']:''
                                                ,data['shipper_code']?data['shipper_code']:ShipperCode
                                                ,data['item_code']?data['item_code']:''
                                                ,data['item_a_code']?data['item_a_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            <input type={'hidden'} className="arrival_rot_weight" value={data['rot_weight']?data['rot_weight']:0}/>
                                        </div>
                                        <div className={style_td_w8p.join(" ")}>
                                            {data['rot_name']?data['rot_name']:''}
                                        </div>
                                        <div className={style_td_w7p.join(" ")} style={{display:'flex'}}>
                                            <input type={'number'} className={style.cs_tbl_input} 
                                            value={data['table_code']?data['table_code']:''} id={'table2_table_code'+index}
                                            onChange={(e) => handleChangeItemData(e,1,'table_code',index,'arrival')} 
                                            onKeyDown={(e) => tableKeyDown(e, 'table2_case_num'+index, index)}
                                            />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadArrivalTableModalData(e,index,data['table_code']?data['table_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            <input type={'hidden'} className="arrival_table_weight" value={data['weight']?data['weight']:0}/>
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            {data['item_unit']?data['item_unit']:''}
                                        </div>
                                        <div className={style_td_w4p_text_right.join(" ")}>
                                            {data['item_quantity']?data['item_quantity']:''}
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'text'} className={style_td_input_right.join(" ")} 
                                            value={data['case_num']?data['case_num']:''} id={'table2_case_num'+index}
                                            onChange={(e) => handleChangeItemData(e,1,'case_num',index,'arrival')} 
                                            onKeyDown={(e) => tableKeyDown(e, 'table2_bara_num'+index, index)}
                                            />
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'text'} className={style_td_input_right.join(" ")} 
                                            value={data['bara_num']?data['bara_num']:''} id={'table2_bara_num'+index}
                                            onChange={(e) => handleChangeItemData(e,1,'bara_num',index,'arrival')} 
                                            onKeyDown={(e) => tableKeyDown(e, 'table2_item_num'+index, index)}
                                            />
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            <input type={'text'} className="arrival_item_num" 
                                                style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                                padding: '0px 5px',color: '#565656',fontSize: 13,outline: 0, textAlign:'end'}} 
                                                value={data['item_num']?data['item_num']:''} id={'table2_item_num'+index}
                                                onChange={(e) => handleChangeItemData(e,1,'item_num',index,'arrival')} 
                                                onKeyDown={(e) => tableKeyDown(e, 'date2'+index, index)}
                                                />
                                        </div>
                                        <div className={style_td_w7p.join(" ")}>
                                            <InputDatepickerTable  value={data['item_deadline_date']?data['item_deadline_date']:''} 
                                            onChange={(e) => handleChangeItemData(e,2,'item_deadline_date',index,'arrival')} focusId={'table2_remark'+index}
                                                id={'date2'+index}
                                            />
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            value={data['remark']?data['remark']:''} id={'table2_remark'+index}
                                            onChange={(e) => handleChangeItemData(e,1,'remark',index,'arrival')} 
                                            onKeyDown={(e) => arrivalTableEndKeyDown(e, 'table2_warehouse_code'+(index+1), index)}
                                            />
                                        </div>
                                        <div className={style_td_w9p.join(" ")}>
                                            <input type={'text'} className={style_td_input_right.join(" ")} disabled
                                                value={data['remain_qty']?data['remain_qty']:0} />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >登録する</button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default ChangeAmountSlip;