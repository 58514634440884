//====================================================================================================
//	在庫移動処理 -> AdobeXD 23ページ
//	作成日:2022/08/25		最終更新日:2022/08/29
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
import MyTextBox from "../../../common/tenjin_tokubuchi/my_text_box/MyTextBox";
import style from "./MoveInventoryDate.module.css";

const MoveInventoryDate = ()=> {
    return (
        <MyTextBox
            style_flex={`${style.flex}`}
            style_title={`${style.title}`}
            style_input_text={`${style.input_text}`}
            title_text={"在庫移動日"}
            name={"MoveInventory"}
            tab_index={1}
        />
    );
}

export default MoveInventoryDate;