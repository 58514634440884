import React from "react";
import style from "./TableInput.module.css";

const TableInput = (props) => {
    const {name,register} = props;
    return(
        <td className={style.td}>
            <input readOnly className={style.input}  {...register('tables.'+name)}  style={props.styles} />
        </td>
    )
}

export default TableInput;