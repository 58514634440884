//====================================================================================================
//	作成日:2022/08/25		最終更新日:2022/08/29
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import { useContext } from 'react';
import { RegisterContext } from '../register_context/RegisterContext';
import font from '../font/Font.module.css';
import style from './MyText.module.css';

const MyTextBox = ( props )=> {
    // registerのcontext
    const register = useContext( RegisterContext );

    // 適用するスタイルの準備
    const dtStyle =  props.style_title + " " + font.font;
    const inputStyle = props.style_input_text + " " + style.text  + " " + font.font;
    
    return (
        <div className={ props.style_flex }>
            <dt className={ dtStyle }>{ props.title_text }</dt>
            <dd>
                <input className={ inputStyle } type="text" tabIndex={props.tab_index} {...register(props.name)} />
            </dd>
        </div>
    );
}

export default MyTextBox;