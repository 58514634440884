//====================================================================================================
//	在庫移動処理 -> AdobeXD 23ページ
//	作成日:2022/08/25		最終更新日:2022/08/29
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
// ---------- テーブルのテキスト 上段 ----------
import style from './TableTextTop.module.css';
import ItemsNumberText from '../08_items_number/text/Text';
import ShipperItemsNumberText from '../09_shipper_items_number/text/Text';
import ItemsNameText from '../10_items_name/text/Text';
import LotNumberText from '../11_lot_number/text/Text';
import LotNameText from '../12_lot_name/text/Text';

const TableTextTop = (props)=> {
    return (
        <tr className={style.flex}>
            <ItemsNumberText tab_index={Number(props.tab_index)} name_id={props.name_id}/>
            <ShipperItemsNumberText tab_index={Number(Number(props.tab_index)) + 1} name_id={props.name_id} />
            <ItemsNameText tab_index={Number(props.tab_index) + 2} name_id={props.name_id} />
            <LotNumberText tab_index={Number(props.tab_index)+ 3} name_id={props.name_id} />
            <LotNameText tab_index={Number(props.tab_index) + 4} name_id={props.name_id} />
        </tr>
    )
}

export default TableTextTop;