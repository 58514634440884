/* eslint-disable eqeqeq */
import style from './Distination.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '../../../components/common/modal/Modal';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import DistinationModal from '../../../components/common/modal/DistinationModal';
import AreaModal from '../../../components/common/modal/AreaModal';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select } from 'antd';

const Distination = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Distination ID
    const [SysDistinationId, setSysDistinationId] = useState(0);
    const [DistinationCode, setDistinationCode] = useState("");
    const handleChangeDistinationCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>6) {
            str = str.substring(0, 6);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setDistinationCode(str);
    };
    const [DistinationACode, setDistinationACode] = useState("");
    const handleChangeDistinationACode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>3) {
            str = str.substring(0, 3);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setDistinationACode(str);
    };
    const onKeyDownDistinationCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(7, event.target.value)
            setDistinationCode(deg)
      
            if(param == 1) {
                getDistinationData(deg, DistinationACode);
                document.getElementById('input2').focus();
            }else{
                getDistinationData(DistinationCode, deg);
                document.getElementById('input3').focus();
            }
            event.preventDefault();
        }
    };

    const getDistinationData = (code,acode) => {
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = code;
            if(acode != "") {
                data['distination_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setDistinationData(t_data[0]);
                }else{
                    setDistinationData([]);
                }
            })
            .catch((error) => {
            });
        }else{
            setDistinationData([]);
        }
    }
    
    const [DistinationName, setDistinationName] = useState("");
    const handleChangeDistinationName = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setDistinationName(str);
    };
    const [DistinationNameKana, setDistinationNameKana] = useState("");
    const handleChangeDistinationNameKana = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setDistinationNameKana(str);
    };
    const [DistinationAName, setDistinationAName] = useState("");
    const handleChangeDistinationAName = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setDistinationAName(str);
    };

    const [DistinationHonor, setDistinationHonor] = useState(0);
    const handleChangeDistinationHonor = (event) => {
        setDistinationHonor(event);
    };

    const [DistinationZip1, setDistinationZip1] = useState("");
    const handleChangeDistinationZip1 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setDistinationZip1(str);
    };
    const [DistinationZip2, setDistinationZip2] = useState("");
    const handleChangeDistinationZip2 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setDistinationZip2(str);
    };
    const getAddress = (event) => {
        if(DistinationZip1 != "" && DistinationZip2 != "") {
            let googleApiUrl = "https://maps.googleapis.com/maps/api/geocode/json";
            let postcodeParam = "?components=postal_code:"+DistinationZip1+"-"+DistinationZip2+"&language=ja";
            let apiKey = "&key="+useJwt.getGoogleApiKey();
            let url = googleApiUrl + postcodeParam + apiKey;
            csloading(true);
            axios
            .get(url, "")
            .then((response1) => {
                if(response1.data.status == "OK") {
                    let address_components = response1.data.results[0]['address_components'];
                    let code_len = address_components.length;
                    let address1 = "";
                    if(address_components[code_len-2]['long_name']) {
                        address1 = address_components[code_len-2]['long_name'];
                    }
                    let address2 = "";
                    if(address_components[code_len-3]['long_name'] && address_components[code_len-3]['types'][0] != "postal_code") {
                        address2 = address_components[code_len-3]['long_name'];
                    }

                    let town = "";
                    if(code_len > 4){
                      town = address_components[code_len - 4]['long_name'];
                    }else{
                        town = "工業団地";
                    }

                    setDistinationAddress1(address1);
                    setDistinationAddress2(address2);
                    setDistinationTown(town);
                }else{ // ZERO_RESULTS
                    setDistinationAddress1("");
                    setDistinationAddress2("");
                    setDistinationTown("");
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });
        }
        event.preventDefault();
    }

    const [DistinationAddress1, setDistinationAddress1] = useState("");
    const handleChangeDistinationAddress1 = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setDistinationAddress1(str);
    };
    const [DistinationAddress2, setDistinationAddress2] = useState("");
    const handleChangeDistinationAddress2 = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setDistinationAddress2(str);
    };
    const [DistinationAddress3, setDistinationAddress3] = useState("");
    const handleChangeDistinationAddress3 = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setDistinationAddress3(str);
    };
    const [DistinationTown, setDistinationTown] = useState("");
    const handleChangeDistinationTown = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setDistinationTown(str);
    };

    const [DistinationTel1, setDistinationTel1] = useState("");
    const handleChangeDistinationTel1 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setDistinationTel1(str);
    };
    const [DistinationTel2, setDistinationTel2] = useState("");
    const handleChangeDistinationTel2 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setDistinationTel2(str);
    };
    const [DistinationTel3, setDistinationTel3] = useState("");
    const handleChangeDistinationTel3 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setDistinationTel3(str);
    };
    const [DistinationFax1, setDistinationFax1] = useState("");
    const handleChangeDistinationFax1 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setDistinationFax1(str);
    };
    const [DistinationFax2, setDistinationFax2] = useState("");
    const handleChangeDistinationFax2 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setDistinationFax2(str);
    };
    const [DistinationFax3, setDistinationFax3] = useState("");
    const handleChangeDistinationFax3 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setDistinationFax3(str);
    };
    const [DistinationEmail, setDistinationEmail] = useState("");
    const handleChangeDistinationEmail = (event) => {
        let str = event.target.value;
        setDistinationEmail(str);
    };
    const [AreaCode, setAreaCode] = useState("");
    const [AreaName, setAreaName] = useState("");
    const handleChangeAreaCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>6) {
            str = str.substring(0, 6);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setAreaCode(str);
    };
    const onKeyDownAreaCode = (event) => {
        if(event.keyCode === 13) { // enter key
            document.getElementById('input21').focus();
            getAreaData(event.target.value);
            event.preventDefault();
        }
    };
    const getAreaData = (code) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                area_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstAreaEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let t_data = response.data.data;
                if(t_data.length > 0) {
                    setAreaData(t_data[0]);
                }else{
                    setAreaData([]);
                }
                
            })
            .catch((error) => {
            });
        }else{
            setAreaData([]);
        }
    }

    const setDistinationData = (result) => {
        if(result['sys_distination_id']) {
            setSysDistinationId(result['sys_distination_id']);
            setDistinationCode(result['distination_code']);
            setDistinationACode(result['distination_a_code']);
            setDistinationName(result['distination_name']);
            setDistinationAName(result['distination_a_name']);
            setDistinationNameKana(result['distination_name_kana']);
            setDistinationHonor(result['distination_honor']);
            if(result['distination_zip'] != null){
                const postcode_ary = result['distination_zip'].split("-");
                if(postcode_ary[0]) {
                    setDistinationZip1(postcode_ary[0]);
                }
                if(postcode_ary[1]) {
                    setDistinationZip2(postcode_ary[1]);
                }
            }
            if(result['distination_address_1'] != null) {
                setDistinationAddress1(result['distination_address_1']);
            }else{
                setDistinationAddress1("");
            }
            if(result['distination_address_2'] != null) {
                setDistinationAddress2(result['distination_address_2']);
            }else{
                setDistinationAddress2("");
            }
            if(result['distination_address_3'] != null) {
                setDistinationAddress3(result['distination_address_3']);
            }else{
                setDistinationAddress3("");
            }
            if(result['distination_town'] != null) {
                setDistinationTown(result['distination_town']);
            }else{
                setDistinationTown("");
            }
            if(result['distination_tel'] != null){
                const phone_ary = result['distination_tel'].split("-");
                if(phone_ary[0]) {
                    setDistinationTel1(phone_ary[0]);
                }
                if(phone_ary[1]) {
                    setDistinationTel2(phone_ary[1]);
                }
                if(phone_ary[2]) {
                    setDistinationTel3(phone_ary[2]);
                }
            }
            if(result['distination_fax'] != null){
                const fax_ary = result['distination_fax'].split("-");
                if(fax_ary[0]) {
                    setDistinationFax1(fax_ary[0]);
                }
                if(fax_ary[1]) {
                    setDistinationFax2(fax_ary[1]);
                }
                if(fax_ary[2]) {
                    setDistinationFax3(fax_ary[2]);
                }
            }
            if(result['distination_email'] != null) {
                setDistinationEmail(result['distination_email']);
            }else{
                setDistinationEmail("");
            }
            if(result['area_code'] != null) {
                getAreaData(result['area_code']);
            }else{
                getAreaData("");
            }
        }else{
            setSysDistinationId(0);
            setDistinationName("");
            setDistinationAName("");
            setDistinationNameKana("");
            setDistinationHonor(0);
            setDistinationZip1(""); setDistinationZip2("");
            setDistinationAddress1("");setDistinationAddress2("");setDistinationAddress3("");setDistinationTown("");
            setDistinationTel1(""); setDistinationTel2(""); setDistinationTel3("");
            setDistinationFax1(""); setDistinationFax2(""); setDistinationFax3("");
            setDistinationEmail(""); setAreaData([]);
        }
    }

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_master_flag_5'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysDistinationId>0) {
            data['sys_distination_id'] = SysDistinationId;
        }

        if(DistinationCode != "") {
            data['distination_code'] = DistinationCode;
        }else{
            toast.error("コードを入力してください。", {autoClose:3000});       // please enter code
            setOpen(false);
            return;
        }
        data['distination_a_code'] = DistinationACode;
        if(DistinationName != "") {
            data['distination_name'] = DistinationName;
        }else{
            toast.error("名称を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        data['distination_a_name'] = DistinationAName;
        if(DistinationNameKana != "") {
            data['distination_name_kana'] = DistinationNameKana;
        }else{
            toast.error("フリガナを入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        data['distination_honor'] = DistinationHonor;
        if(DistinationZip1 != "" && DistinationZip2 != "") {
            data['distination_zip'] = DistinationZip1 + "-" + DistinationZip2;  //郵便番号
        }else{
            toast.error("郵便番号を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(DistinationAddress1 != "") {
            data['distination_address_1'] = DistinationAddress1;
        }else{
            toast.error("住所（都道府県）を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(DistinationAddress2 != "") {
            data['distination_address_2'] = DistinationAddress2;
        }else{
            toast.error("市区町村を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        data['distination_address_3'] = DistinationAddress3;
        data['distination_town'] = DistinationTown;
        if(DistinationTel1!= "" && DistinationTel2 != "" && DistinationTel3 != "") {
            data['distination_tel'] = DistinationTel1 + "-" + DistinationTel2 + "-" + DistinationTel3;  //電話番号
        }else{
            toast.error("電話番号を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(DistinationFax1!= "" && DistinationFax2 != "" && DistinationFax3 != "") {
            data['distination_fax'] = DistinationFax1 + "-" + DistinationFax2 + "-" + DistinationTel3;  //Fax番号
        }else{
            toast.error("FAX番号を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(DistinationEmail != "") {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(DistinationEmail.match(mailformat)){
                data['distination_email'] = DistinationEmail;
            }else{
                toast.error("正しいメールアドレスを入力してください。", {autoClose:3000});
                setOpen(false);
                return;
            }
        }else{
            toast.error("メールアドレスを入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(AreaCode != "") {
            data['area_code'] = AreaCode;
        }else{
            toast.error("地区コードを入力してください。", {autoClose:3000});       
            setOpen(false);
            return;
        }

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setMstDistinationEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data == 1) {
                    toast.success("登録致しました。", {autoClose:3000});
                }else if(response.data.data == 2){
                    toast.error("すでに登録済みです。", {autoClose:3000});
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
                    
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else{
                toast.error("登録に失敗しました。", {autoClose:3000});
            }
        });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if(SysDistinationId>0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        if(SysDistinationId>0) {
            let data = {};
            data['userID'] = userData['id'];
            data['sys_distination_id'] = SysDistinationId;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.delMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    setDistinationCode("");
                    setDistinationData([]);
                }
            })
            .catch((error) => {
            });

            setDistinationCode("");
            setDistinationData([]);
        }
    }
    // ========================== starts code for search ===============================
    const [SearchValue, setSearchValue] = useState("");
    const [SearchValue2, setSearchValue2] = useState("");
    const [tableData, setTableData] = useState([]);
    
    const loadModalData = (event) => {
        event.preventDefault();
        setSearchValue(DistinationCode);
        setSearchValue2(DistinationACode);

        if(DistinationCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = DistinationCode;
            if(DistinationACode != "") {
                data['distination_a_code'] = DistinationACode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData(response.data.data);
                setShowModal(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData([]);
            setShowModal(true);
        }
    }
    // ----------------------- Area Modal ------------------------------------------
    const [showModalArea, setShowModalArea] = useState(false);
    const [SearchValue3, setSearchValue3] = useState("");
    const [tableData3, setTableData3] = useState([]);
    const loadModalAreaData = (event) => {
        event.preventDefault();
        let code = AreaCode;
        setSearchValue3(code);

        if(code != ""){
            const data = {
                userID: userData['id'],
                area_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstAreaEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData3(response.data.data);
                setShowModalArea(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData3([]);
            setShowModalArea(true);
        }
    }
    const setAreaData = (data) => {
        if(data['area_code'] != null) {
            setAreaCode(data['area_code']);
            setAreaName(data['area_name']);
        }else{
            setAreaCode("");
            setAreaName("");
        }
    }

    const onDataKeyDown = (e, param) => {
        if(e.keyCode == 13){
            switch(param){
                case 1 :
                    document.getElementById('input1').focus();
                    break;
                case 2 :
                    document.getElementById('input2').focus();
                    break;
                case 3 :
                    document.getElementById('input3').focus();
                    break;
                case 4 :
                    document.getElementById('input4').focus();
                    break;
                case 5 :
                    document.getElementById('input5').focus();
                    break;
                case 6 :
                    document.getElementById('input6').focus();
                    break;
                case 7 :
                    document.getElementById('input7').focus();
                    break;
                case 8 :
                    document.getElementById('input8').focus();
                    break;
                case 9 :
                    document.getElementById('input9').focus();
                    break;
                case 10 :
                    document.getElementById('input10').focus();
                    break;
                case 11 :
                    document.getElementById('input11').focus();
                    break;
                case 12 :
                    document.getElementById('input12').focus();
                    break;
                case 13 :
                    document.getElementById('input13').focus();
                    break;
                case 14 :
                    document.getElementById('input14').focus();
                    break;
                case 15 :
                    document.getElementById('input15').focus();
                    break;
                case 16 :
                    document.getElementById('input16').focus();
                    break;
                case 17 :
                    document.getElementById('input17').focus();
                    break;
                case 18 :
                    document.getElementById('input18').focus();
                    break;
                case 19 :
                    document.getElementById('input19').focus();
                    break;
                case 20 :
                    document.getElementById('input20').focus();
                    break;
                    default :
            }
        }
    }

    const [type1Open, setType1Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('input7').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const selectData = (e, index, id) =>{
        switch(index){
          case 1:
            setType1Open(false);
            break;
        default:
        }
        document.getElementById('input7').focus();
      }
      
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_220px = [gstyle.cs_input, gstyle.w220px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_select_245px = [gstyle.cs_select, gstyle.w245px];
    const style_input_80px = [gstyle.cs_input, gstyle.w80px];
    const style_split_span = [style.split_span];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <DistinationModal show={showModal} onClose={()=>setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} onChange={setSearchValue} 
                SearchValue2={SearchValue2} onChange2={setSearchValue2}
                tableData={tableData} setTableData={setTableData}
                target={setDistinationData} />
            <AreaModal show={showModalArea} onClose={()=>setShowModalArea(false)} width={'70%'}
                SearchValue={SearchValue3} onChange={setSearchValue3} 
                tableData={tableData3} setTableData={setTableData3}
                target={setAreaData} />
            <Modal2 title="届け先削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>届け先マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownDistinationCode(e,1)} id='input1'
                                        value={DistinationCode} onChange={handleChangeDistinationCode} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownDistinationCode(e,2)} id='input2'
                                        value={DistinationACode} onChange={handleChangeDistinationACode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalData(e)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>名称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}  
                                        id='input3' onKeyDown={(e)=>onDataKeyDown(e, 4)}
                                        value={DistinationName} onChange={handleChangeDistinationName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>フリガナ</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input4' onKeyDown={(e)=>onDataKeyDown(e, 5)}
                                        value={DistinationNameKana} onChange={handleChangeDistinationNameKana}  />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>略称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input5' onKeyDown={(e)=>onDataKeyDown(e, 6)}
                                        value={DistinationAName} onChange={handleChangeDistinationAName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>敬称</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>selectData(e, 1)}
                                            placeholder="敬称を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeDistinationHonor}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="input6"
                                            options={[
                                                {value:'0', label:'御中'},
                                                {value:'1', label:'様'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>郵便番号</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input7' onKeyDown={(e)=>onDataKeyDown(e, 8)}
                                        value={DistinationZip1} onChange={handleChangeDistinationZip1} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input8' onKeyDown={(e)=>onDataKeyDown(e, 9)}
                                        value={DistinationZip2} onChange={handleChangeDistinationZip2} />
                                        <div className={style.postcode_btn_wrap}>
                                            <button className={style.postcode_btn} onClick={getAddress} >郵便検索</button>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>住所（都道府県）</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input9' onKeyDown={(e)=>onDataKeyDown(e, 10)}
                                        value={DistinationAddress1} onChange={handleChangeDistinationAddress1} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>市区町村</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input10' onKeyDown={(e)=>onDataKeyDown(e, 11)}
                                        value={DistinationAddress2} onChange={handleChangeDistinationAddress2} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>町名</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input11' onKeyDown={(e)=>onDataKeyDown(e, 12)}
                                        value={DistinationTown} onChange={handleChangeDistinationTown} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>丁目・番地</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input12' onKeyDown={(e)=>onDataKeyDown(e, 13)}
                                        value={DistinationAddress3} onChange={handleChangeDistinationAddress3} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>電話番号</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input13' onKeyDown={(e)=>onDataKeyDown(e, 14)}
                                        value={DistinationTel1} onChange={handleChangeDistinationTel1} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input14' onKeyDown={(e)=>onDataKeyDown(e, 15)}
                                        value={DistinationTel2} onChange={handleChangeDistinationTel2} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input15' onKeyDown={(e)=>onDataKeyDown(e, 16)}
                                        value={DistinationTel3} onChange={handleChangeDistinationTel3} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>FAX番号</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input16' onKeyDown={(e)=>onDataKeyDown(e, 17)}
                                        value={DistinationFax1} onChange={handleChangeDistinationFax1} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input17' onKeyDown={(e)=>onDataKeyDown(e, 18)}
                                        value={DistinationFax2} onChange={handleChangeDistinationFax2} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input18' onKeyDown={(e)=>onDataKeyDown(e, 19)}
                                        value={DistinationFax3} onChange={handleChangeDistinationFax3} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>メールアドレス</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input19' onKeyDown={(e)=>onDataKeyDown(e, 20)}
                                        value={DistinationEmail} onChange={handleChangeDistinationEmail} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>地区コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownAreaCode(e)} id='input20'
                                        value={AreaCode} onChange={handleChangeAreaCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={AreaName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalAreaData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='input21' onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} disabled={SysDistinationId>0?false:true}>削除</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Distination;