
import React, {useState, useEffect} from 'react';
import './Modal2.css';

const Modal2 = props => {
    const modal_width = props.width?props.width:'500px';
    var contentStyle = {
        width:modal_width
    };
    return (
        <div className={`modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()} style={contentStyle}>
                <div className="modal-header">
                    <h4 className="modal-title">{props.title}</h4>
                </div>
                <div className="modal-body">{props.children}</div>
                <div className="modal-footer">
                    <button className="close-btn" onClick={props.onClose}>閉じる</button>
                    <button className="close-btn" onClick={props.onConfirm}>確認</button>
                </div>
            </div>
        </div>
    )
}
export default Modal2;




