/* eslint-disable eqeqeq */
import style from "./Bus.module.css";
import HeadingText from "../../components/administrator/headingText/HeadingText";
import LinkButton from "../../components/administrator/linkButton/LinkButton";
import * as useJwt  from '../../auth/jwt/useJwt.js';

const Bus = () => {
  const menuPatternData = JSON.parse(useJwt.getMenuPatternData()); 
  const userData = JSON.parse(useJwt.getUserData());

  return (
    <>
      <label className='bodyLabel'>バース設定</label>
      <div className='contentBody'>
      <HeadingText color="#1188E8" title="バース" />
      <div className={style.links}>
        {	(menuPatternData['bus_flag_1'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/bus/setting" title="バース設定" />
				}
        {/* {	(menuPatternData['bus_flag_2'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/bus/driver" title="運送会社リスト"/>
				} */}
        {	(menuPatternData['bus_flag_3'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/bus/schedule/index" title="バース予約" />
				}
        {	(menuPatternData['bus_flag_4'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/bus/schedule/list" title="スケジュール表" />
        }
        {	(menuPatternData['bus_flag_5'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/bus/notification" title="通知" />
        }
      </div>
      </div>
    </>
  );
};

export default Bus;
