/* eslint-disable eqeqeq */
import style from './JobQRcode.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import WorkerModal from '../../../components/common/modal/WorkerModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { Button, Select, Radio } from 'antd';
import $ from 'jquery';
import { QRCodeCanvas } from "qrcode.react";
import { useReactToPrint } from "react-to-print";
import { Barcode } from '@progress/kendo-react-barcodes';

const JobQRcode = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();

    const handlePrint = useReactToPrint({
        content: () => QRRef.current,
    });

    const codePrint = () => {
        if (itemInformation.length == 0) {
            toast.error("作業スケジュールを選択してください。", { autoClose: 3000 });
            return;
        }

        handlePrint();
    }

    const [itemInformation, setItemInformation] = useState([]);

    const QRRef = useRef();


    const [loaded, setLoaded] = useState(false);

    const csLoading = (flag) => {
        if (flag) {
            document.getElementById('csLoader').style.display = "flex";
        } else {
            document.getElementById('csLoader').style.display = "none";
        }
    }

    // in and out set
    const [In, setIn] = useState("");
    const handleChangeIn = (event, param) => {
        setIn(param);
        setOut("");
        gridSMainTable([]);
    }

    const [Out, setOut] = useState("2");
    const handleChangeOut = (event, param) => {
        setIn("");
        setOut(param);
        gridSMainTable([]);
    }
    // start date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        var current_date = new Date();
        var diff_day = moment(current_date, "YYYY-MM-DD").diff(moment(date, "YYYY-MM-DD 00:00:00"), 'days');
        if (diff_day > 0) {
            toast.error("過去の日付は入力できません。", { autoClose: 3000 });
        } else {
            setStartDate(useJwt.formatDate(date));
        }
    };


    // worker --------------------------------------------------
    const [WorkerName, setWorkerName] = useState("");
    const [WorkerId, setWorkerId] = useState("");
    const handleChangeWorkerName = (event) => {
        setWorkerName(event.target.value);
    };

    const onKeyDownWorkerName = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getWorkerData(event.target.value);
            event.preventDefault();

            document.getElementById('shipper_code').focus();
        }
    };
    const getWorkerData = (name) => {
        if (name != "") {
            const data = {
                userID: userData['id'],
                name: name
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWorkerListEndPoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWorkerData(result);
                        } else {
                            setWorkerData([]);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setWorkerData([]);
        }
    }

    const setWorkerData = (data) => {
        if (data['user_family_nm_kanji']) {
            setWorkerId(data['id'])
            setWorkerName(data['user_family_nm_kanji'] + data['user_first_nm_kanji']);
        } else {
            setWorkerName("");
            setWorkerId("")
        }
    }
    // shipper ---------------------------------------------------
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        setShipperCode(useJwt.validateNum(event.target.value, 6));
    };
    const [ShipperACode, setShipperACode] = useState("");
    const handleChangeShipperACode = (event) => {
        setShipperACode(useJwt.validateNum(event.target.value, 3));
    };
    const [ShipperName, setShipperName] = useState("");

    const onKeyDownShipperCode = (event, param1, param2) => {
        if (event.keyCode === 13) { // enter key
            getShipperData(event.target.value, param1, param2);
            event.preventDefault();

            if (param2 == 1)
                document.getElementById('shipper_a_code').focus();
            else
                document.getElementById('searchBut').focus();
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if (param1 == 1) {
            if (param2 == 1) {
                code = value;
                if (ShipperACode != "") {
                    acode = ShipperACode;
                } else {
                    acode = "000";
                }
            } else {
                code = ShipperCode;
                if (value != "") {
                    acode = value;
                } else {
                    acode = "000";
                }
            }
        } else {
            if (param2 == 1) {
                code = value;
                if (ShipperACode != "") {
                    acode = ShipperACode;
                } else {
                    acode = "000";
                }
            } else {
                code = ShipperCode;
                if (value != "") {
                    acode = value;
                } else {
                    acode = "000";
                }
            }
        }

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if (acode != "") {
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                }).then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setShipperData(result, param1);
                        } else {
                            setShipperData([], param1);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setShipperData([], param1);
        }
    }
    const setShipperData = (data, param) => {
        if (data['shipper_code'] != null) {
            setShipperCode(data['shipper_code']);
            setShipperACode(data['shipper_a_code']);
            setShipperName(data['shipper_name']);
        } else {
            setShipperCode("");
            setShipperACode("");
            setShipperName("");
        }
    }

    // =================================================================================

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);
            if (menuPatternData['menu_daily_flag_26'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
    });
    // ----------------------------- Worker modal -------------------------------------
    const [showModalWorker, setShowModalWorker] = useState(false);
    const [WorkerSearchValue, setWorkerSearchValue] = useState("");
    const [WorkerTableData, setWorkerTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWorkerModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let name = WorkerName;
        setWorkerSearchValue(name);

        if (name != "") {
            let data = {};
            data['userID'] = userData['id'];
            if (name != "") {
                data['name'] = name;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWorkerListEndPoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWorkerTableData(response.data.data);
                    setShowModalWorker(true);
                })
                .catch((error) => {
                });
        } else {
            setWorkerTableData([]);
            setShowModalWorker(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode;
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setShipperTableData(response.data.data);
                    setShowModalShipper(true);
                })
                .catch((error) => {
                });
        } else {
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }

    const [commandDate, setCommandDate] = useState(useJwt.formatDate(new Date()))
    const handleChangeCommandDate = (date) => {
        setCommandDate(useJwt.formatDate(date));
    };

    const allScheduleCheckData = (event) => {
        $('#tbodyWrap input[type="checkbox"]').prop('checked', event.target.checked);

        QRGenerate();
    }
    const handleScheduleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allScheduleCheckBtn').checked = all_check_flg;
        QRGenerate();
    };

    const [sMainTableData, setSMainTableData] = useState([]);

    const gridSMainTable = (result) => {
        let data = [];
        for (var i = 0; i < result.length; i++) {
            let row = {};
            row = result[i];
            data[i] = row;
        }
        setSMainTableData(data);
    }

    const [codeType, setCodeType] = useState("1");
    const onChangeCodeType = (e, type) => {
        setCodeType(type);
    }

    const QRGenerate = () => {
        let data = [];
        var x = 0;

        var checkboxes = $('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            console.log(checkboxes)
            let temp = {};
            if (checkboxes[i].checked) {
                for (var j = 0; j < sMainTableData[i]['item_num']; j++) {
                    temp['code'] = ShipperCode + '&&' + ShipperACode + '&&' + sMainTableData[i]['item_code']
                        + '&&' + sMainTableData[i]['item_a_code'] + '&&' + sMainTableData[i]['rot_code'] + '&&' + sMainTableData[i]['warehouse_code'] + '&&' + sMainTableData[i]['table_code']
                        + '&&' + sMainTableData[i]['item_num'] + '&&' + userData['id'] + '&&' + (Out == "2" ? "1" : "0");

                    temp['name'] = sMainTableData[i]['item_name'];
                    data.push(temp);
                }
            }
        }
        console.log(data);
        if (data.length == 0) {
            setItemInformation([]);
        } else {
            setItemInformation(data);
        }
    }

    const commandView = () => {
        let data = {};
        data['userID'] = userData['id'];
        if (WorkerId != "") data['sys_user_id'] = WorkerId;
        data['schedule_date'] = commandDate;

        if (ShipperCode != "") {
            data['shipper_code'] = ShipperCode;
            data['shipper_a_code'] = ShipperACode;
        } else {
            toast.error("荷主を選択してください。", { autoClose: 3000 });
            return;
        }
        data['work_type'] = Out == "2" ? "1" : "0";

        csLoading(true);
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getScheduleList;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setSMainTableData(response.data.data)
                csLoading(false);
            })
            .catch((error) => {
                csLoading(false);
            });
    }
    const pageSizeData = [
        [{ x: 2, y: 4 }, { x: 10, y: 0 }, { x: 90, y: 60 }, { x: 10, y: 5 }, { x: 8 }],
        [{ x: 2, y: 5 }, { x: 20, y: 0 }, { x: 80, y: 45 }, { x: 10, y: 5 }, { x: 10 }],
        [{ x: 2, y: 6 }, { x: 10, y: 0 }, { x: 90, y: 35 }, { x: 10, y: 5 }, { x: 12 }],
        [{ x: 3, y: 6 }, { x: 10, y: 0 }, { x: 60, y: 35 }, { x: 5, y: 5 }, { x: 18 }],
        [{ x: 4, y: 6 }, { x: 10, y: 0 }, { x: 50, y: 35 }, { x: 5, y: 5 }, { x: 24 }],
        [{ x: 5, y: 6 }, { x: 10, y: 0 }, { x: 40, y: 35 }, { x: 5, y: 5 }, { x: 30 }],
    ];

    const [column, setColumn] = useState(pageSizeData[0][0]['x'])
    const [row, setRow] = useState(pageSizeData[0][0]['y'])
    const [pageLR, setPageLR] = useState(pageSizeData[0][1]['x'])
    const [pageTB, setPageTB] = useState(pageSizeData[0][1]['y'])
    const [qrWidth, setQrWidth] = useState(pageSizeData[0][2]['x'])
    const [qrHeight, setQrHeight] = useState(pageSizeData[0][2]['y'])
    const [btweenLR, setBtweenLR] = useState(pageSizeData[0][3]['x'])
    const [btweenTB, setBtweenTB] = useState(pageSizeData[0][3]['y'])
    const [limitCount, setLimitCount] = useState(pageSizeData[0][4]['x'])

    const [sizeKind, setSizeKind] = useState("0")
    const kindData = [
        { value: '0', label: 'ページ(2×4)' }, 
        { value: '1', label: 'ページ(2×5)' }, 
        { value: '2', label: 'ページ(2×6)' }, 
        { value: '3', label: 'ページ(3×6)' }, 
        { value: '4', label: 'ページ(4×6)' }, 
        { value: '5', label: 'ページ(5×6)' }
    ];

    const handleSizeKind = (event) => {
        setSizeKind(event);
        var sizeValueArray = pageSizeData[event]
        setColumn(sizeValueArray[0]['x'])
        setRow(sizeValueArray[0]['y'])

        setPageLR(sizeValueArray[1]['x'])
        setPageTB(sizeValueArray[1]['y'])

        setQrWidth(sizeValueArray[2]['x'])
        setQrHeight(sizeValueArray[2]['y'])

        setBtweenLR(sizeValueArray[3]['x'])
        setBtweenTB(sizeValueArray[3]['y'])

        setLimitCount(sizeValueArray[4]['x'])

    };

    const [changeFlag, setChangeFlage] = useState(1)
    const onInputChange = (event, param) => {
        if (param == 1) {
            setColumn(event.target.value)
            setLimitCount(event.target.value * row)
        }
        if (param == 2) {
            setRow(event.target.value)
            setLimitCount(event.target.value * column)

        }
        if (param == 3) setPageLR(event.target.value)
        if (param == 4) setPageTB(event.target.value)
        if (param == 5) setQrWidth(event.target.value)
        if (param == 6) setQrHeight(event.target.value)
        if (param == 7) setBtweenLR(event.target.value)
        if (param == 8) setBtweenTB(event.target.value)

    }

    const [layout, setLayout] = useState('left')
    const handleLayoutChange = (e) => {
        setLayout(e)
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row, style.job_row_align];
    const style_col_1 = [gstyle.cs_col, gstyle.cs_col_1];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, style.job_time];

    const style_th_w4p = [style.cs_th, style.w4p];
    const style_th_w5p = [style.cs_th, style.w5p];
    const style_th_w6p = [style.cs_th, style.w6p];
    const style_th_w7p = [style.cs_th, style.w7p];
    const style_th_w8p = [style.cs_th, style.w8p];
    const style_th_w85p = [style.cs_th, style.w85p];
    const style_th_w9p = [style.cs_th, style.w9p];
    const style_th_w10p = [style.cs_th, style.w10p];
    const style_th_w15p = [style.cs_th, style.w15p];

    const style_td_w4p = [style.cs_td, style.w4p];
    const style_td_w4p_right = [style.cs_td, style.w4p, style.td_text_right];
    const style_td_w5p = [style.cs_td, style.w5p];
    const style_td_w5p_left = [style.cs_td, style.w5p, style.td_text_left, style.td_line_break];
    const style_td_w6p = [style.cs_td, style.w6p];
    const style_td_w7p = [style.cs_td, style.w7p];
    const style_td_w7p_left = [style.cs_td, style.w7p, style.td_text_left, style.td_line_break];
    const style_td_w8p = [style.cs_td, style.w8p];
    const style_td_w85p = [style.cs_td, style.w85p];
    const style_td_w9p = [style.cs_td, style.w9p];
    const style_td_w10p = [style.cs_td, style.w10p, style.td_text_left, style.td_line_break];
    const style_td_w15p = [style.cs_td, style.w15p];

    const style_check_ml = [style.check_label, style.job_radio_margin];
    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_ck = [style.cs_th, style.w1p];
    const style_td_col_ck = [style.cs_td, style.w1p];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csLoader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WorkerModal show={showModalWorker} onClose={() => setShowModalWorker(false)} width={'70%'}
                SearchValue={WorkerSearchValue} onChange={setWorkerSearchValue}
                tableData={WorkerTableData} setTableData={setWorkerTableData}
                target={setWorkerData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={() => setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue}
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>商品QRコード印刷</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1} style={{ paddingTop: 10 }}>
                    {/* 入出 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_1.join(" ")}>

                        </div>
                        <div className={style_col_3.join(" ")} style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span className={style.cs_span}>入出区分</span>
                            </div>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <div className={style_date_wrap.join(" ")}>
                                        <label className={style.check_label}>
                                            <input type={'radio'} name='inout' onChange={(e) => handleChangeOut(e, 2)} checked={Out == "2" ? true : false} />
                                            <div>出庫</div>
                                        </label>

                                        <label className={style_check_ml.join(" ")}>
                                            <input type={'radio'} name='inout' onChange={(e) => handleChangeIn(e, 1)} checked={In == "1" ? true : false} />
                                            <div>入庫</div>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")} style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <span >年月日</span>
                            <div className={style_input_wrap.join(" ")}>
                                <InputDatepicker value={commandDate} onChange={handleChangeCommandDate} focusId="worker" />
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")} style={{ maxWidth: 485 }}>
                            <table class="input" id="setting">
                                <tbody>
                                    <tr>
                                        <td style={{ width: '30%', backgroundColor: '#d2eaff' }}>テンプレート</td>
                                        <td style={{ backgroundColor: 'aliceblue' }}>
                                            <Select
                                                optionFilterProp="children"
                                                onChange={handleSizeKind}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                style={{ width: '40%' }}
                                                value={sizeKind}
                                                options={kindData}
                                            />
                                            <Radio.Group value={layout} onChange={(e) => handleLayoutChange(e.target.value)} style={{ marginLeft: 10 }}>
                                                <Radio.Button value="left">左</Radio.Button>
                                                <Radio.Button value="center">中</Radio.Button>
                                                <Radio.Button value="flex-end">右</Radio.Button>
                                            </Radio.Group>

                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%', backgroundColor: '#d2eaff' }}>１ページのラベル数</td>
                                        <td style={{ width: 195, backgroundColor: 'aliceblue' }}>
                                            <input style={{ width: 50, height: 20 }} value={column} onChange={(e) => onInputChange(e, 1)} /> 列　×
                                            <input style={{ width: 50, height: 20 }} value={row} onChange={(e) => onInputChange(e, 2)} /> 段
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%', backgroundColor: '#d2eaff' }}>ページ余白</td>
                                        <td style={{ width: 195, backgroundColor: 'aliceblue' }}> 左右：
                                            <input style={{ width: 50, height: 20 }} value={pageLR} onChange={(e) => onInputChange(e, 3)} /> mm　　上下：
                                            <input style={{ width: 50, height: 20 }} value={pageTB} onChange={(e) => onInputChange(e, 4)} /> mm
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%', backgroundColor: '#d2eaff' }}>ラベルサイズ</td>
                                        <td style={{ width: 195, backgroundColor: 'aliceblue' }}> 横幅：
                                            <input style={{ width: 50, height: 20 }} value={qrWidth} onChange={(e) => onInputChange(e, 5)} /> mm　　縦高：
                                            <input style={{ width: 50, height: 20 }} value={qrHeight} onChange={(e) => onInputChange(e, 6)} /> mm
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%', backgroundColor: '#d2eaff' }}>ラベル内余白</td>
                                        <td style={{ width: 195, backgroundColor: 'aliceblue' }}> 左右：
                                            <input style={{ width: 50, height: 20 }} value={btweenLR} onChange={(e) => onInputChange(e, 7)} /> mm　　上下：
                                            <input style={{ width: 50, height: 20 }} value={btweenTB} onChange={(e) => onInputChange(e, 8)} /> mm
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={style_row.join(" ")} style={{ marginBottom: 30 }}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>作業者
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")} style={{ margin: 0 }}
                                            onKeyDown={(e) => onKeyDownWorkerName(e, 1, 1)} id="worker"
                                            value={WorkerName} onChange={handleChangeWorkerName} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled style={{ margin: 0 }}
                                            value={WorkerName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadWorkerModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>荷主
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} style={{ margin: 0 }}
                                            onKeyDown={(e) => onKeyDownShipperCode(e, 1, 1)} id="shipper_code"
                                            value={ShipperCode} onChange={handleChangeShipperCode} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} style={{ margin: 0 }}
                                            onKeyDown={(e) => onKeyDownShipperCode(e, 1, 2)} id="shipper_a_code"
                                            value={ShipperACode} onChange={handleChangeShipperACode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled style={{ margin: 0 }}
                                            value={ShipperName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadShipperModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_1.join(" ")} style={{ gap: 10, display: 'flex', marginTop: 15, marginLeft: 10 }}>
                            <Button type={"primary"} style={{ width: 100 }} onClick={commandView} id="searchBut">検索</Button>
                        </div>
                        <div className={style_col_2.join(" ")} style={{ display: 'flex', justifyContent: 'end', maxWidth: '18%', flex: '0 0 18%' }}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <div className={style_date_wrap.join(" ")} style={{ margin: 0, marginTop: 20 }}>
                                        <label className={style.check_label}>
                                            <input type={'radio'} name='code' checked={codeType == 1 ? true : false} value={In} onChange={(e) => onChangeCodeType(e, 1)} />
                                            <div>QRコード</div>
                                        </label>

                                        <label className={style_check_ml.join(" ")}>
                                            <input type={'radio'} name='code' id='out' onChange={(e) => onChangeCodeType(e, 2)} checked={codeType == 2 ? true : false} />
                                            <div>バーコード</div>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_1.join(" ")} style={{ gap: 10, display: 'flex', marginTop: 15, marginLeft: 10 }}>
                            <Button type={"primary"} style={{ width: 100, backgroundColor: '#ff6116' }} onClick={codePrint}>コード発行</Button>
                        </div>
                    </div>
                    <div className={style.tbl_wrap}>
                        <div className={style_item_tbl.join(" ")} style={{ margin: 0 }}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allScheduleCheckBtn"
                                            onChange={allScheduleCheckData} />
                                    </div>
                                    <div className={style_th_w5p.join(" ")}>開始時間</div>
                                    <div className={style_th_w5p.join(" ")}>終了時間</div>
                                    <div className={style_th_w7p.join(" ")}>品番</div>
                                    <div className={style_th_w4p.join(" ")}>荷主品番</div>
                                    <div className={style_th_w10p.join(" ")}>品名</div>
                                    <div className={style_th_w6p.join(" ")}>ロットNo</div>
                                    <div className={style_th_w5p.join(" ")}>ロット名</div>
                                    <div className={style_th_w7p.join(" ")}>倉庫</div>
                                    <div className={style_th_w7p.join(" ")}>倉庫名</div>
                                    <div className={style_th_w7p.join(" ")}>棚コード</div>
                                    <div className={style_th_w4p.join(" ")}>単位</div>
                                    <div className={style_th_w4p.join(" ")}>ケース数</div>
                                    <div className={style_th_w4p.join(" ")}>バラ数</div>
                                    <div className={style_th_w4p.join(" ")}>数量</div>
                                    <div className={style_th_w6p.join(" ")}>商品期限</div>
                                    <div className={style_th_w6p.join(" ")}>備考</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {sMainTableData?.map((data, index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                                id={'chk_' + index} onChange={handleScheduleChangeChk} />
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            {data['start_date_time'] ? data['start_date_time'] : ''}
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            {data['end_date_time'] ? data['end_date_time'] : ''}
                                        </div>
                                        <div className={style_td_w7p.join(" ")}>
                                            {data['item_code'] ? data['item_code'] : ''} {data['item_a_code'] ? data['item_a_code'] : ''}
                                        </div>
                                        <div className={style_td_w4p.join(" ")} >
                                            {data['shipper_item_code'] ? data['shipper_item_code'] : ''}
                                        </div>
                                        <div className={style_td_w10p.join(" ")} >
                                            {data['item_name'] ? data['item_name'] : ''}
                                        </div>
                                        <div className={style_td_w6p.join(" ")} >
                                            {data['rot_code'] ? data['rot_code'] : ''}
                                        </div>
                                        <div className={style_td_w5p_left.join(" ")} >
                                            {data['rot_name'] ? data['rot_name'] : ''}
                                        </div>
                                        <div className={style_td_w7p.join(" ")} >
                                            {data['warehouse_code'] ? data['warehouse_code'] : ''}
                                        </div>
                                        <div className={style_td_w7p_left.join(" ")} >
                                            {data['warehouse_name'] ? data['warehouse_name'] : ''}
                                        </div>
                                        <div className={style_td_w7p.join(" ")} >
                                            {data['table_code'] ? data['table_code'] : ''}
                                        </div>
                                        <div className={style_td_w4p.join(" ")} >
                                            {data['item_unit'] ? data['item_unit'] : ''}
                                        </div>
                                        <div className={style_td_w4p_right.join(" ")} >
                                            {data['case_num'] ? data['case_num'] : ''}
                                        </div>
                                        <div className={style_td_w4p_right.join(" ")} >
                                            {data['bara_num'] ? data['bara_num'] : ''}
                                        </div>
                                        <div className={style_td_w4p_right.join(" ")} >
                                            {data['item_num'] ? data['item_num'] : ''}
                                        </div>
                                        <div className={style_td_w6p.join(" ")} >
                                            {data['item_deadline_date'] ? data['item_deadline_date'] : ''}
                                        </div>
                                        <div className={style_td_w6p.join(" ")} >
                                            {data['remark'] ? data['remark'] : ''}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display:'none'}}>
                <div ref={QRRef} id="QRDiv">
                    <div>
                        <div style={{ marginTop: pageTB + "mm", marginLeft: pageLR + "mm", display: 'flex', flexWrap: 'wrap', maxWidth: '210mm' }}>
                            {itemInformation.map((item, index) => (
                                <>
                                    {
                                        (index % limitCount) == 0 &&
                                        <div style={{ width: '210mm', display: 'flex', marginTop: pageTB + "mm", pageBreakBefore: 'always' }}></div>
                                    }
                                    <div style={{ width: qrWidth + "mm", height: (parseInt(qrHeight) + 5) + 'mm', display: 'flex', marginTop: btweenTB + 'mm', justifyContent: layout }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                {item['name']}
                                            </div>
                                            <div >
                                                {
                                                    codeType == "1" ?
                                                        <QRCodeCanvas
                                                            id="qrCode"
                                                            value={item['code']}
                                                            style={{ width: qrHeight + 'mm', height: qrHeight + 'mm' }}
                                                            bgColor={"#ffffff"}
                                                            level={"H"}
                                                            key={index}
                                                            removeAfterPrint
                                                        />
                                                        :
                                                        <Barcode value={item['code']} format="CODE128" width={qrWidth} height={qrHeight} key={index} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default JobQRcode;