/* eslint-disable eqeqeq */
import style from './ItemCopy.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import IconSearch from '../../../components/common/icons/icon_search.png';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Table} from 'antd';

const ItemCopy = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1);
            event.preventDefault();
        }
    };
    const getShipperData = (value, param1) => {
        let code;
        code = value;
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            csloading(true);
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperName1(data['shipper_name']);
                getSourceItemData(data);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperName2("");
            }
        }
    }
    
    // item data ----------------------------
    const getSourceItemData = (result) => {
        if(result['shipper_code'] && result['shipper_code'] != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = result['shipper_code'];
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    if(response.data.data.length>0) {
                        let result = response.data.data;
                        gridMainTable(result);
                        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
                        for (let i = 0; i < checkboxes.length; i++) {
                            checkboxes[i].checked = false;
                        }
                    }else{
                        gridMainTable([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            gridMainTable([]);
        }
    }

    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        console.log(result);

        let totalArray = [];

        for (let index = 0; index < result.length; index++) {
            let temp = {};
            const element = result[index];
            temp['key'] = element.sys_item_id;
            temp['sys_item_id'] = element.sys_item_id;
            temp['shipper_code'] = element.shipper_code;
            temp['item_code'] = element.item_code;
            temp['arrival_cargo_handling_fee_rate'] = element.arrival_cargo_handling_fee_rate;
            temp['category_code_1'] = element.category_code_1;
            temp['category_code_2'] = element.category_code_2;
            temp['category_code_3'] = element.category_code_3;
            temp['create_user_id'] = element.create_user_id;
            temp['created_at'] = element.created_at;
            temp['deleted_at'] = element.deleted_at;
            temp['first_arrival_date'] = element.first_arrival_date;
            temp['invoice_cal_type'] = element.invoice_cal_type;
            temp['item_a_code'] = element.item_a_code;
            temp['item_a_name'] = element.item_a_name;
            temp['item_deadline_date'] = element.item_deadline_date;
            temp['item_fraction_type'] = element.item_fraction_type;
            temp['item_name'] = element.item_name;
            temp['item_name_kana'] = element.item_name_kana;
            temp['item_quantity'] = element.item_quantity;
            temp['item_set_flag'] = element.item_set_flag;
            temp['item_type'] = element.item_type;
            temp['item_type_code'] = element.item_type_code;
            temp['item_unit'] = element.item_unit;
            temp['leaving_cargo_handling_fee_rate'] = element.leaving_cargo_handling_fee_rate;
            temp['price_update_date'] = element.price_update_date;
            temp['price_update_end_date'] = element.price_update_end_date;
            temp['shipper_item_code'] = element.shipper_item_code;
            temp['stock_cal_price'] = element.stock_cal_price;
            temp['stock_cal_type'] = element.stock_cal_type;
            temp['storage_fee_rate'] = element.storage_fee_rate;
            temp['tax_no'] = element.tax_no;
            temp['unit_volume'] = element.unit_volume;
            temp['unit_volume_bara'] = element.unit_volume_bara;
            temp['unit_weight'] = element.item_name_kana;
            temp['unit_weight_bara'] = element.unit_weight_bara;
            temp['update_user_id'] = element.update_user_id;
            temp['updated_at'] = element.updated_at;

            totalArray.push(temp);
        }
        console.log(totalArray);
        setMainTableData(result);
    }

    const columns = [
        {
          title: '品番',
          dataIndex: 'item_code',
          key: 'item_code',
        },
        {
          title: '補助',
          dataIndex: 'item_a_code',
          key: 'age',
        },
        {
          title: '品名',
          dataIndex: 'item_name',
          key: 'item_name',
        },
      ];
    const allCheckData = (event) => {
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', event.target.checked);
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_master_flag_16'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];

        let validate_flg = true;
        let vmsg = "";
        if(ShipperCode2 == "") {
            validate_flg = false;
            vmsg = "複写先荷主コードを入力してください。";
        }
        let ItemCopyData = [];
        if(validate_flg) {
            for(var i=0; i<mainTableData.length; i++) {
                let row = {};
                let checked = document.getElementById('chk_'+i).checked
                if(checked && mainTableData[i]['sys_item_id'] != 0) {
                    row['shipper_code'] = ShipperCode2;
                    row['item_code'] = mainTableData[i]['item_code'];
                    row['item_a_code'] = mainTableData[i]['item_a_code'];
                    row['shipper_item_code'] = mainTableData[i]['shipper_item_code'];
                    row['item_name'] = mainTableData[i]['item_name'];
                    row['item_a_name'] = mainTableData[i]['item_a_name'];
                    row['item_name_kana'] = mainTableData[i]['item_name_kana'];
                    row['item_type'] = mainTableData[i]['item_type'];
                    row['item_set_flag'] = mainTableData[i]['item_set_flag'];
                    row['item_quantity'] = mainTableData[i]['item_quantity'];
                    row['item_fraction_type'] = mainTableData[i]['item_fraction_type'];
                    row['unit_weight'] = mainTableData[i]['unit_weight'];
                    row['unit_weight_bara'] = mainTableData[i]['unit_weight_bara'];
                    row['unit_volume'] = mainTableData[i]['unit_volume'];
                    row['unit_volume_bara'] = mainTableData[i]['unit_volume_bara'];
                    row['item_unit'] = mainTableData[i]['item_unit'];
                    row['stock_cal_type'] = mainTableData[i]['stock_cal_type'];
                    row['invoice_cal_type'] = mainTableData[i]['invoice_cal_type'];
                    row['tax_no'] = mainTableData[i]['tax_no'];
                    row['first_arrival_date'] = mainTableData[i]['first_arrival_date'];
                    row['item_deadline_date'] = mainTableData[i]['item_deadline_date'];
                    row['item_type_code'] = mainTableData[i]['item_type_code'];
                    row['category_code_1'] = mainTableData[i]['category_code_1'];
                    row['category_code_2'] = mainTableData[i]['category_code_2'];
                    row['category_code_3'] = mainTableData[i]['category_code_3'];
                    row['price_update_date'] = mainTableData[i]['price_update_date'];
                    row['price_update_end_date'] = mainTableData[i]['price_update_end_date'];
                    row['storage_fee_rate'] = mainTableData[i]['storage_fee_rate'];
                    row['arrival_cargo_handling_fee_rate'] = mainTableData[i]['arrival_cargo_handling_fee_rate'];
                    row['leaving_cargo_handling_fee_rate'] = mainTableData[i]['leaving_cargo_handling_fee_rate'];
                    row['stock_cal_price'] = mainTableData[i]['stock_cal_price'];

                    ItemCopyData.push(row);
                }
            }
        }
        if(validate_flg && ItemCopyData.length>0){
            data['data'] = ItemCopyData;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setMstItemsEndpoint;
            csloading(true);
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data == 1) {
                        toast.success("登録致しました。", {autoClose:3000});
                    }else if(response.data.data == 2){
                        toast.error("すでに登録済みです。", {autoClose:3000});
                    }else{
                        toast.error("登録に失敗しました。", {autoClose:3000});
                    }
                        
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
            });
        }else{
            // toast.error(vmsg, {autoClose:3000});  
        }
        setOpen(false);
    }
    
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];

    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_4 = [style.cs_th, gstyle.cs_col_4];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_4 = [style.cs_td, gstyle.cs_col_4];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>商品複写処理</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>複写元荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1)}
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>複写先荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2)}
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* table */ }
                    <div className={gstyle.hyfiled}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_col_2.join(" ")}>品番</div>
                                    <div className={style_th_col_2.join(" ")}>補助</div>
                                    <div className={style_th_col_4.join(" ")}>品名</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                            id={'chk_'+index} onChange={handleChangeChk} />
                                            <input type="hidden" id={'sys_item_id_'+index} value={data['sys_item_id']} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['item_code']}disabled/>
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['item_a_code']}disabled/>
                                        </div>
                                        <div className={style_td_col_4.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['item_name']}disabled/>
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* <Table dataSource={mainTableData} columns={columns} /> */}
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >複写</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default ItemCopy;