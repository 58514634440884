/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './StockDailyReport.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import StartTableModal from '../../../components/common/modal/TableModal';
import EndTableModal from '../../../components/common/modal/TableModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import {Select} from 'antd'

const StockDailyReport = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    // slip date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // deadline day ---------------------------------------------
    const [DeadLineDay, setDeadLineDay] = useState(useJwt.formatDate(new Date()));
    const handleChangeDeadLineDay = (date) => {
        setDeadLineDay(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartSlipDate, setStartSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartSlipDate = (date) => {
        setStartSlipDate(useJwt.formatDate(date));
    };
    const [EndSlipDate, setEndSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndSlipDate = (date) => {
        setEndSlipDate(useJwt.formatDate(date));
    };
    // output order type -----------------------------------------------
    const [WarehouseType, setWarehouseType] = useState(0);
    const handleChangeWarehouseType = (event) => {
        setWarehouseType(event);
    };
    const WarehouseTypeData = [ { value:'0', label:'全倉庫' }, { value:'1', label:'倉庫別' } ];
    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('warehouse_code2').focus();                    
            }else{
                document.getElementById('type3').focus();                    
            }
        }
    };
    const getWarehouseData = (code,param) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result,param);
                    }else{
                        setWarehouseData([],param);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setWarehouseData([],param);
        }
    }
    const setWarehouseData = (data,param) => {
        if(data['warehouse_code']) {
            if(param == 1) {
                setWarehouseCode1(data['warehouse_code']);
                setWarehouseName1(data['warehouse_name']);
            }else{
                setWarehouseCode2(data['warehouse_code']);
                setWarehouseName2(data['warehouse_name']);
            }
        }else{
            if(param == 1) {
                setWarehouseCode1("");
                setWarehouseName1("");
            }else{
                setWarehouseCode2("");
                setWarehouseName2("");
            }
        }
    }
    // location ------------------------------------------------
    const [LocationNo1, setLocationNo1] = useState("");
    const handleChangeLocationNo1 = (event) => {
        setLocationNo1(event.target.value);
    };
    const [LocationData1, setLocationData1] = useState([]);
    const [LocationNo2, setLocationNo2] = useState("");
    const handleChangeLocationNo2 = (event) => {
        setLocationNo2(event.target.value);
    };
    const [LocationData2, setLocationData2] = useState([]);

    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
        setItemData([],1);
        setItemData([],2);
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
        setItemData([],1);
        setItemData([],2);
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();
            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    setTimeout(()=>{
                        if(document.getElementById('item_code1')){
                            document.getElementById('item_code1').focus();
                        }else{
                            document.getElementById('type2').focus();
                        }
                    }, 10)
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                if(ShipperACode1 != ""){
                    acode = ShipperACode1;
                }else{
                    acode = "000";
                }
            }else{
                code = ShipperCode1;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }else{
            if(param2==1){
                code = value;
                if(ShipperACode2 != ""){
                    acode = ShipperACode2;
                }else{
                    acode = "000";
                }
            }else{
                code = ShipperCode2;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // item ----------------------------------------------------
    const [ItemCode1, setItemCode1] = useState("");
    const handleChangeItemCode1 = (event) => {
        setItemCode1(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode1, setItemACode1] = useState("");
    const handleChangeItemACode1 = (event) => {
        setItemACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName1, setItemName1] = useState("");
    const [ItemCode2, setItemCode2] = useState("");
    const handleChangeItemCode2 = (event) => {
        setItemCode2(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode2, setItemACode2] = useState("");
    const handleChangeItemACode2 = (event) => {
        setItemACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName2, setItemName2] = useState("");
    const onKeyDownItemCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getItemData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('item_a_code1').focus();
                }else{
                    document.getElementById('item_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('item_a_code2').focus();
                }else{
                    document.getElementById('type2').focus();
                }
            }
        }
    };

    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                if(ItemACode1 != ""){
                    acode = ItemACode1;
                }else{
                    acode = "000";
                }
            }else{
                code = ItemCode1;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }else{
            if(param2==1){
                code = value;
                if(ItemACode2 != ""){
                    acode = ItemACode2;
                }else{
                    acode = "000";
                }
            }else{
                code = ItemCode2;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode1;
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result,param1);
                    }else{
                        setItemData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([],param1);
        }
    }
    const setItemData = (data,param) => {
        if(data['item_code']) {
            if(param == 1) {
                setItemCode1(data['item_code']);
                setItemACode1(data['item_a_code']);
                setItemName1(data['item_name']);
            }else{
                setItemCode2(data['item_code']);
                setItemACode2(data['item_a_code']);
                setItemName2(data['item_name']);
            }
        }else{
            if(param == 1) {
                setItemCode1("");
                setItemACode1("");
                setItemName1("");
            }else{
                setItemCode2("");
                setItemACode2("");
                setItemName2("");
            }
        }
    }
    // page type -----------------------------------------------
    const [PageType, setPageType] = useState(0);
    const handleChangePageType = (event) => {
        setPageType(event);
    };
    const PageTypeData = [ { value:'0', label:'改頁無し' }, { value:'1', label:'改頁有り' } ];
    // rot type -----------------------------------------------
    const [RotType, setRotType] = useState(0);
    const handleChangeRotType = (event) => {
        setRotType(event);
    };
    const RotTypeData = [ { value:'0', label:'品番集計' }, { value:'1', label:'ロット別' } ];
    // filter type -----------------------------------------------
    const [FilterType, setFilterType] = useState(0);
    const handleChangeFilterType = (event) => {
        setFilterType(event);
    };
    const FilterTypeData = [ { value:'0', label:'発生又は在庫有り' }, { value:'1', label:'発生有りのみ' } ];
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_stock_flag_4'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        
        data['start_slip_date'] = StartSlipDate;
        data['end_slip_date'] = EndSlipDate;
       
        data['filter_warehouse'] = WarehouseType;
        if(WarehouseType == 1) {
            data['start_warehouse_code'] = WarehouseCode1;
            data['end_warehouse_code'] = WarehouseCode2;
        }
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        data['start_item_code'] = ItemCode1;
        data['end_item_code'] = ItemCode2;
        data['start_item_a_code'] = ItemACode1;
        data['end_item_a_code'] = ItemACode2;
        if(LocationNo1 != "") {
            data['start_table_code'] = LocationNo1;
        }
        if(LocationNo2 != "") {
            data['end_table_code'] = LocationNo2;
        }
        
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockDailyReportEndpoing;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            //console.log(result);
            if(result.length>0){
                getItemRemainNum(result);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                getItemRemainNum([]);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
        
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const getItemRemainNum = (ret) => {
        let data = {};
        data['userID'] = userData['id'];
        data['end_slip_date'] = useJwt.getPreviousDay(StartSlipDate);
        if(WarehouseType == 1) {
            data['start_warehouse_code'] = WarehouseCode1;
            data['end_warehouse_code'] = WarehouseCode2;
        }
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        data['start_item_code'] = ItemCode1;
        data['end_item_code'] = ItemCode2;
        data['start_item_a_code'] = ItemACode1;
        data['end_item_a_code'] = ItemACode2;
        if(LocationNo1 != "") {
            data['start_table_code'] = LocationNo1;
        }
        if(LocationNo2 != "") {
            data['end_table_code'] = LocationNo2;
        }
       
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockDailyReportEndpoing;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            // console.log("pre result",result);
            if(result.length>0) {
                let data = [];
                let itemCodes = [];
                for(var i=0; i<result.length; i++) {
                    let filter_code = ''+result[i]['shipper_code']+'-'+result[i]['shipper_a_code'];
                    filter_code += '-'+result[i]['item_code'];
                    // if(WarehouseType>0) {
                    //     filter_code += '-'+result[i]['warehouse_code'];
                    // }
                    if(RotType>0) {
                        filter_code += '-'+result[i]['rot_code'];
                    }else{
                        filter_code += '-'+result[i]['item_a_code'];
                    }
                    if(!itemCodes.includes(filter_code)){
                        itemCodes.push(filter_code);
                    }
                }
                for(var i=0; i<itemCodes.length; i++) {
                    let row = {};
                    row['filter_code'] = itemCodes[i];
                    let remainAry = [];
                    let remainPriceAry = [];
                    let cnt = 0;
                    for(var j=0;j<result.length;j++) {
                        let filter_code = ''+result[j]['shipper_code']+'-'+result[j]['shipper_a_code'];
                        filter_code += '-'+result[j]['item_code'];
                        // if(WarehouseType>0) {
                        //     filter_code += '-'+result[j]['warehouse_code'];
                        // }
                        if(RotType>0) {
                            filter_code += '-'+result[j]['rot_code'];
                        }else{
                            filter_code += '-'+result[j]['item_a_code'];
                        }
                        if(itemCodes[i] == filter_code) {

                            let tmp_row = result[j];
                            let remain_num = 0;
                            let remain_price = 0;
                            if(cnt>0){
                                if(tmp_row['arrival_num'] != "" && tmp_row['leaving_num'] != ""){
                                    remain_num += (remainAry[cnt-1]*1+1*tmp_row['arrival_num']-1*tmp_row['leaving_num']);
                                    remain_price += (remainPriceAry[cnt-1]*1+(1*tmp_row['arrival_num']-1*tmp_row['leaving_num'])*tmp_row['stock_cal_price'])
                                }else{
                                    if(tmp_row['arrival_num'] != ""){
                                        remain_num += (remainAry[cnt-1]*1+1*tmp_row['arrival_num']);
                                        remain_price += (remainPriceAry[cnt-1]*1+1*tmp_row['arrival_num']*tmp_row['stock_cal_price']);
                                    }else if(tmp_row['leaving_num'] != "") {
                                        remain_num += (remainAry[cnt-1]*1-1*tmp_row['leaving_num']);
                                        remain_price += (remainPriceAry[cnt-1]*1-1*tmp_row['leaving_num']*tmp_row['stock_cal_price']);
                                    }else{
                                        remain_num = remainAry[cnt-1];
                                        remain_price = remainPriceAry[cnt-1];
                                    }
                                }
                                tmp_row['remain_num'] = remain_num;
                            }else{
                                if(tmp_row['arrival_num'] != "" && tmp_row['leaving_num'] != ""){
                                    remain_num = 1*tmp_row['arrival_num']-1*tmp_row['leaving_num'];
                                    remain_price = remain_num*tmp_row['stock_cal_price'];
                                }else{
                                    if(tmp_row['arrival_num'] != ""){
                                        remain_num = 1*tmp_row['arrival_num'];
                                        remain_price = remain_num*tmp_row['stock_cal_price'];
                                    }else if(tmp_row['leaving_num'] != "") {
                                        remain_num = (0*1-tmp_row['leaving_num']);
                                        remain_price = remain_num*tmp_row['stock_cal_price'];
                                    }
                                }
                            }
                            // console.log("remain_num",remain_num);
                            // console.log("stock_cal_price",tmp_row['stock_cal_price']);
                            // console.log("remain_price",remain_price);
                            remainAry.push(remain_num);
                            remainPriceAry.push(remain_price);
                            cnt++;
                        }
                    }
                    // console.log("remainAry",remainAry);
                    // console.log("remainPriceAry",remainPriceAry);
                    row['remain_num'] = remainAry[remainAry.length-1];
                    row['remain_price'] = remainPriceAry[remainPriceAry.length-1];
                    data.push(row);
                }
                gridMainTable(ret,data);
            }else{
                gridMainTable(ret,[]);
            }
        })
        .catch((error) => {
        });
        
    }
    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result,prev_result) => {
        // console.log(result);
        // console.log(prev_result);
        let data = [];
        let itemCodes = [];
        let header_info = [];
        for(var i=0; i<result.length; i++) {
            let filter_code = ''+result[i]['shipper_code']+'-'+result[i]['shipper_a_code'];
            filter_code += '-'+result[i]['item_code'];
            // if(WarehouseType>0) {
            //     filter_code += '-'+result[i]['warehouse_code'];
            // }
            if(RotType>0) {
                filter_code += '-'+result[i]['rot_code'];
            }else{
                filter_code += '-'+result[i]['item_a_code'];
            }
            if(!itemCodes.includes(filter_code)){
                itemCodes.push(filter_code);
                let row = {};
                row['shipper_code'] = result[i]['shipper_code'];
                row['shipper_a_code'] = result[i]['shipper_a_code'];
                row['shipper_name'] = result[i]['shipper_name'];
                row['warehouse_code'] = result[i]['warehouse_code'];
                row['warehouse_name'] = result[i]['warehouse_name'];
                row['item_code'] = result[i]['item_code'];
                row['item_a_code'] = result[i]['item_a_code'];
                row['rot_code'] = result[i]['rot_code'];
                row['item_name'] = result[i]['item_name'];
                row['item_unit'] = result[i]['item_unit'];
                row['stock_cal_price'] = result[i]['stock_cal_price'];
                header_info.push(row);
            }
        }
        for(var i=0; i<itemCodes.length; i++) {
            let row = {};
            row = header_info[i];
            row['filter_code'] = itemCodes[i];
            
            let item_info = [];
            let cnt = 0;
            for(var j=0;j<result.length;j++) {
                let filter_code = ''+result[j]['shipper_code']+'-'+result[j]['shipper_a_code'];
                filter_code += '-'+result[j]['item_code'];
                // if(WarehouseType>0) {
                //     filter_code += '-'+result[j]['warehouse_code'];
                // }
                if(RotType>0) {
                    filter_code += '-'+result[j]['rot_code'];
                }else{
                    filter_code += '-'+result[j]['item_a_code'];
                }
                if(itemCodes[i] == filter_code) {

                    let tmp_row = result[j];
                    let real_row = {};
                    let remain_num = 0;
                    let remain_price = 0;
                    let stock_move_2 = 0;
                    real_row['arrival_num'] = tmp_row['arrival_num'];
                    real_row['leaving_num'] = tmp_row['leaving_num'];
                    real_row['stock_cal_price'] = tmp_row['stock_cal_price'];
                    // count 調整
                    if(tmp_row['input_type'] == 'stockmove' && tmp_row['move_type'] == 2) { // 調整
                        if(tmp_row['arrival_num'] != null && tmp_row['arrival_num'] > 0) {
                            stock_move_2 += (tmp_row['arrival_num']*1) ;
                        }
                        if(tmp_row['leaving_num'] != null && tmp_row['leaving_num'] > 0) {
                            stock_move_2 += (tmp_row['leaving_num']*1) ;
                        }
                    }
                    real_row['stock_move_2'] = stock_move_2;
                    // count remain num and price
                    if(cnt>0){
                        if(tmp_row['arrival_num'] != "" && tmp_row['leaving_num'] != ""){
                            remain_num += (item_info[cnt-1]['remain_num']*1+1*tmp_row['arrival_num']-1*tmp_row['leaving_num']);
                            remain_price += (item_info[cnt-1]['remain_price']*1+(1*tmp_row['arrival_num']-1*tmp_row['leaving_num'])*tmp_row['stock_cal_price']);
                        }else{
                            if(tmp_row['arrival_num'] != ""){
                                remain_num += (item_info[cnt-1]['remain_num']*1+1*tmp_row['arrival_num']);
                                remain_price += (item_info[cnt-1]['remain_price']*1+1*tmp_row['arrival_num']*tmp_row['stock_cal_price']);
                            }else if(tmp_row['leaving_num'] != "") {
                                remain_num += (item_info[cnt-1]['remain_num']*1-1*tmp_row['leaving_num']);
                                remain_price += (item_info[cnt-1]['remain_price']*1-1*tmp_row['leaving_num']*tmp_row['stock_cal_price']);
                            }else{
                                remain_num = item_info[cnt-1]['remain_num'];
                                remain_price = item_info[cnt-1]['remain_price'];
                            }
                        }
                        real_row['remain_num'] = remain_num;
                        real_row['remain_price'] = remain_price;
                    }else{
                        let prev_remain_num = 0;
                        let prev_remain_price = 0;
                        for(var m=0; m<prev_result.length;m++){
                            if(itemCodes[i] == prev_result[m]['filter_code']) {
                                prev_remain_num = prev_result[m]['remain_num'];
                                prev_remain_price = prev_result[m]['remain_price'];
                                break;
                            }
                        }
                        
                        if(tmp_row['arrival_num'] != "" && tmp_row['leaving_num'] != ""){
                            remain_num = 1*tmp_row['arrival_num']-1*tmp_row['leaving_num'];
                            remain_price = (1*tmp_row['arrival_num']-1*tmp_row['leaving_num'])*tmp_row['stock_cal_price'];
                        }else{
                            if(tmp_row['arrival_num'] != ""){
                                remain_num = 1*tmp_row['arrival_num'];
                                remain_price = 1*tmp_row['arrival_num']*tmp_row['stock_cal_price'];
                            }else if(tmp_row['leaving_num'] != "") {
                                remain_num = (0*1-tmp_row['leaving_num']);
                                remain_price = (0*1-tmp_row['leaving_num'])*tmp_row['stock_cal_price'];
                            }
                        }
                        real_row['remain_num'] = (prev_remain_num*1+remain_num);
                        real_row['remain_price'] = (prev_remain_price*1+remain_price);
                    }
                    // console.log("remain_price",real_row)
                    item_info.push(real_row);
                    cnt++;
                }
            }
            let total_a_num = 0;
            let total_l_num = 0;
            let total_s_num = 0;
            for(var j=0;j<item_info.length;j++) {
                if(item_info[j]['arrival_num'] != '') {
                    total_a_num += (1*item_info[j]['arrival_num']);
                }
                if(item_info[j]['leaving_num'] != '') {
                    total_l_num += (1*item_info[j]['leaving_num']);
                }
                if(item_info[j]['stock_move_2'] != '') {
                    total_s_num += (1*item_info[j]['stock_move_2']);
                }
            }
            row['arrival_num'] = total_a_num;
            row['leaving_num'] = total_l_num;
            row['stock_move_2'] = total_s_num;
            row['remain_num'] = item_info[item_info.length-1]['remain_num'];
            row['remain_price'] = item_info[item_info.length-1]['remain_price'];
            row['carry_forward_num'] = item_info[item_info.length-1]['remain_num'] - total_a_num + total_l_num;
            data.push(row);
        }
        // console.log(data);
        let t_data = getTotalAry(data);
        setMainTableData(t_data);
        if(t_data.length>0){
            setOpend(true);
        }
    }
    const getTotalAry = (result) => {
        let data = [];
        let itemCodes_1 = [];
        let header_info_1 = [];
        for(var i=0; i<result.length; i++) {
            let filter_code_1 = ''+result[i]['shipper_code']+'-'+result[i]['shipper_a_code'];
            if(!itemCodes_1.includes(filter_code_1)){
                itemCodes_1.push(filter_code_1);
                let row = {};
                row['shipper_code'] = result[i]['shipper_code'];
                row['shipper_a_code'] = result[i]['shipper_a_code'];
                row['shipper_name'] = result[i]['shipper_name'];
                header_info_1.push(row);
            }
        }
        for(var i=0; i<itemCodes_1.length; i++) {
            let row = {};
            row['header_info'] = header_info_1[i];
            row['filter_code'] = itemCodes_1[i];
            
            let item_info_1 = [];
            let item_info = [];
            let total_arrival = 0;
            let total_leaving = 0;
            let total_stock_move = 0;
            for(var j=0;j<result.length;j++) {
                let filter_code_1 = ''+result[j]['shipper_code']+'-'+result[j]['shipper_a_code'];
                if(itemCodes_1[i] == filter_code_1) {
                    if(result[j]['arrival_num'] != ''){
                        total_arrival += (result[j]['arrival_num']*1);
                    }
                    if(result[j]['leaving_num'] != ''){
                        total_leaving += (result[j]['leaving_num']*1);
                    }
                    if(result[j]['stock_move_2'] != ''){
                        total_stock_move += (result[j]['stock_move_2']*1);
                    }

                    let row_2 = result[j];
                    row_2['total_flg'] = 0;
                    row_2['total_label'] = "";
                    item_info_1.push(row_2);
                }
            }

            let itemCodes_2 = [];
            let header_info_2 = [];
            for(var k=0; k<item_info_1.length; k++) {
                let code_1_ary = item_info_1[k]['filter_code'].split("-");
                let filter_code_2 = ''+code_1_ary[0]+'-'+code_1_ary[1]+'-'+code_1_ary[2];
                if(!itemCodes_2.includes(filter_code_2)){
                    itemCodes_2.push(filter_code_2);
                    let row_2 = {};
                    row_2['item_code'] = item_info_1[k]['item_code'];
                    row_2['item_a_code'] = item_info_1[k]['item_a_code'];
                    row_2['item_name'] = item_info_1[k]['item_name'];
                    row_2['rot_code'] = item_info_1[k]['rot_code'];
                    header_info_2.push(row_2);
                }
            }
            for(var k=0; k<itemCodes_2.length; k++) {
                let row_2 = {};
                row_2['header_info'] = header_info_2[k];
                row_2['filter_code'] = itemCodes_2[k];
                row_2['total_flg'] = 0;
                row_2['total_label'] = "";
                
                let item_info_2 = [];
                let sub_total_arrival = 0;
                let sub_total_leaving = 0;
                let sub_total_stock_move = 0;
                for(var l=0;l<item_info_1.length;l++) {
                    let code_1_ary = item_info_1[l]['filter_code'].split("-");
                    let filter_code_2 = ''+code_1_ary[0]+'-'+code_1_ary[1]+'-'+code_1_ary[2];
                    if(itemCodes_2[k] == filter_code_2) {
                        if(item_info_1[l]['arrival_num'] != ''){
                            sub_total_arrival += (item_info_1[l]['arrival_num']*1);
                        }
                        if(item_info_1[l]['leaving_num'] != ''){
                            sub_total_leaving += (item_info_1[l]['leaving_num']*1);
                        }
                        if(item_info_1[l]['stock_move_2'] != ''){
                            sub_total_stock_move += (item_info_1[l]['stock_move_2']*1);
                        }

                        let row_3 = item_info_1[l];
                        row_3['total_flg'] = 0;
                        row_3['total_label'] = "";
                        item_info_2.push(row_3); 
                    }
                }
                let sub_total_row = {};
                sub_total_row['total_flg'] = 1;
                sub_total_row['total_label'] = "【品 番 計】";
                sub_total_row['arrival_num'] = sub_total_arrival;
                sub_total_row['leaving_num'] = sub_total_leaving;
                sub_total_row['stock_move_2'] = sub_total_stock_move;
                item_info_2.push(sub_total_row);

                row_2['item_info'] = item_info_2;
                item_info.push(row_2);
            }

            let total_row = {};
            total_row['total_flg'] = 1;
            total_row['total_label'] = "【荷 主 計】";
            total_row['arrival_num'] = total_arrival;
            total_row['leaving_num'] = total_leaving;
            total_row['stock_move_2'] = total_stock_move;
            item_info.push(total_row);

            row['item_info'] = item_info;
            data.push(row);
        }
        return data;
    }
    // ========================== starts code for search ===============================
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWarehouseModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode1;
        if(param==2){
            code = WarehouseCode2;
        }
        setWarehouseSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['warehouse_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemCode1;
        let acode = ItemACode1;
        if(param == 2) {
            code = ItemCode2;
            acode = ItemACode2;
        }
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode1);
        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode1;
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

    
    // -----------------------------start Table modal -------------------------------------
    const [showModalStartTable, setShowModalStartTable] = useState(false);
    const [startTableSearchValue, setStartTableSearchValue] = useState("");
    const [startTableSearchValue2, setStartTableSearchValue2] = useState("");
    const [startTableData, setStartTableData] = useState([]);
    const loadStartTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = WarehouseCode1;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setStartTableSearchValue(table_code);
        setStartTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setStartTableData(response.data.data);
                setShowModalStartTable(true);
            })
            .catch((error) => {
            });
        }else{
            setStartTableData([]);
            setShowModalStartTable(true);
        }
    }
    const setStartTableCode = (data) => {

        setLocationData1(data);
        setLocationNo1(data['table_code']);
    }
    
    // -----------------------------end Table modal -------------------------------------
    const [showModalEndTable, setShowModalEndTable] = useState(false);
    const [endTableSearchValue, setEndTableSearchValue] = useState("");
    const [endTableSearchValue2, setEndTableSearchValue2] = useState("");
    const [endTableData, setEndTableData] = useState([]);
    const loadEndTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = WarehouseCode1;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setEndTableSearchValue(table_code);
        setEndTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setEndTableData(response.data.data);
                setShowModalEndTable(true);
            })
            .catch((error) => {
            });
        }else{
            setEndTableData([]);
            setShowModalEndTable(true);
        }
    }
    const setEndTableCode = (data) => {
        setLocationData2(data);
        setLocationNo2(data['table_code']);
    }

    
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);
    const [type5Open, setType5Open] = useState(false);

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('warehouse_code1'))
                    document.getElementById('warehouse_code1').focus();
                else
                    document.getElementById('type3').focus();
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type4').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onType4KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type5').focus();
            event.preventDefault();
        }
    }
    const onFocusType4 = (e, type) => {
        if(type == 1){
            setType4Open(true);
        }else{
            setType4Open(false);
        }
    }

    const onType5KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType5 = (e, type) => {
        if(type == 1){
            setType5Open(true);
        }else{
            setType5Open(false);
        }
    }


    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            case 4:
                setType4Open(false);
                break;
            case 5:
                setType5Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_9 = [gstyle.cs_col, gstyle.cs_col_9];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap2 = [style.p2030];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_wrap4 = [gstyle.p00300015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];
    const style_select_245px = [gstyle.cs_select, gstyle.w245px];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} 
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
            <StartTableModal show={showModalStartTable} onClose={()=>setShowModalStartTable(false)} width={'70%'}
                SearchValue={startTableSearchValue} SearchValue2={startTableSearchValue2}  onChange={setStartTableSearchValue} 
                tableData={startTableData} setTableData={setStartTableData}
                target={setStartTableCode} />
            <EndTableModal show={showModalEndTable} onClose={()=>setShowModalEndTable(false)} width={'70%'}
                SearchValue={endTableSearchValue} SearchValue2={endTableSearchValue2}  onChange={setEndTableSearchValue} 
                tableData={endTableData} setTableData={setEndTableData}
                target={setEndTableCode} />

            <div className={gstyle.border}>
                <p className={gstyle.text}>在庫日報</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 締日区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={StartSlipDate} onChange={handleChangeStartSlipDate} id='date2' focusId='date3' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={EndSlipDate} onChange={handleChangeEndSlipDate} id='date3' focusId='shipper_code1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 商品 */}
                    {ShipperCode1 != "" && ShipperCode1 == ShipperCode2 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,1,1)} id='item_code1'
                                            value={ItemCode1} onChange={handleChangeItemCode1} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,1,2)} id='item_a_code1'
                                            value={ItemACode1} onChange={handleChangeItemACode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,2,1)} id='item_code2'
                                            value={ItemCode2} onChange={handleChangeItemCode2} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,2,2)} id='item_a_code2'
                                            value={ItemACode2} onChange={handleChangeItemACode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 倉庫区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="倉庫区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeWarehouseType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={WarehouseTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫 */}
                    {WarehouseType == 1 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始倉庫</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownWarehouseCode(e,1)} id='warehouse_code1'
                                                value={WarehouseCode1} onChange={handleChangeWarehouseCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了倉庫</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownWarehouseCode(e,2)} id='warehouse_code2'
                                                value={WarehouseCode2} onChange={handleChangeWarehouseCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 棚 */}
                    {WarehouseCode1 != "" && WarehouseCode1 == WarehouseCode2 && <>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始棚コード</span>
                                        <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                            <input type={'num'} className={style.cs_tbl_input} 
                                                value={LocationNo1} id='location_code1'
                                                onChange={(e) => handleChangeLocationNo1(e)} />
                                                <div className={gstyle.icon_search_wrap} 
                                                    onClick={(e)=>loadStartTableModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了棚コード</span>
                                        <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                            <input type={'num'} className={style.cs_tbl_input} 
                                                value={LocationNo2} id='location_code2'
                                                onChange={(e) => handleChangeLocationNo2(e)} />
                                                <div className={gstyle.icon_search_wrap} 
                                                    onClick={(e)=>loadEndTableModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>}
                    {/* 品番区分, 打出区分, 改頁区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="品番区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeRotType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={RotTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>打出区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type4Open}
                                            onSelect={(e)=>onSelectData(e, 4)}
                                            placeholder="打出区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeFilterType}
                                            onKeyDown={onType4KeyDown}
                                            onFocus={(e) => onFocusType4(e, 1)}
                                            onBlur={(e) => onFocusType4(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type4"
                                            options={FilterTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>改頁区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type5Open}
                                            onSelect={(e)=>onSelectData(e, 5)}
                                            placeholder="改頁区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangePageType}
                                            onKeyDown={onType5KeyDown}
                                            onFocus={(e) => onFocusType5(e, 1)}
                                            onBlur={(e) => onFocusType5(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type5"
                                            options={PageTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        {/* Title, 発行年月日 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_2.join(" ")}></div>
                            <div className={style_col_8.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>在庫日報</h2>
                                </div>
                            </div>
                            <div className={style_col_2.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {/* slip date */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_2}>
                                                {useJwt.formatDate2(new Date(StartSlipDate))} ~ {useJwt.formatDate2(new Date(EndSlipDate))}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* 倉庫区分, 品番区分, 打出区分, 改頁区分 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_2}>[倉庫区分]:</span>
                                            <span className={style.cs_span_2}>{WarehouseTypeData[WarehouseType]['value']}</span>
                                            <span className={style.cs_span_2}>[品番区分]:</span>
                                            <span className={style.cs_span_2}>{RotTypeData[RotType]['value']}</span>
                                            <span className={style.cs_span_2}>[打出区分]:</span>
                                            <span className={style.cs_span_2}>{FilterTypeData[FilterType]['value']}</span>
                                            <span className={style.cs_span_2}>[改頁区分]:</span>
                                            <span className={style.cs_span_2}>{PageTypeData[PageType]['value']}</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* table */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>荷主ｺｰﾄﾞ</th>
                                                <th>品番</th>
                                                <th>ﾛｯﾄNo</th>
                                                <th rowSpan={2}>単位</th>
                                                <th rowSpan={2}>繰越数量</th>
                                                <th rowSpan={2}>入庫数量</th>
                                                <th rowSpan={2}>出庫数量</th>
                                                <th rowSpan={2}>調整数量</th>
                                                <th rowSpan={2}>在庫数量</th>
                                                <th rowSpan={2}>在庫金額</th>
                                            </tr>
                                            <tr>
                                                <th>荷主名</th>
                                                <th colSpan={2}>品名</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {  mainTableData.map((data,index) => (
                                                <React.Fragment key={index}>
                                                    {  data['item_info'].map((data1,index1) => (
                                                        <React.Fragment key={index1}>
                                                            {data1['total_flg'] == 0?
                                                                <>
                                                                    {  data1['item_info'].map((data2,index2) => (
                                                                        <React.Fragment key={index2}>
                                                                            {data2['total_flg'] == 0?
                                                                            <>
                                                                                <tr>
                                                                                    <td rowSpan={index1==0 && index2==0?1:2}>{index1==0 && index2==0?data2['shipper_code']+"-"+data2['shipper_a_code']:""}</td>
                                                                                    <td>
                                                                                        {RotType>0?
                                                                                        (
                                                                                            data2['item_code']+"-000"
                                                                                        ):(
                                                                                            data2['item_code']+"-"+data2['item_a_code']
                                                                                        )
                                                                                        }
                                                                                    </td>
                                                                                    <td>{RotType>0?data2['rot_code']:""}</td>
                                                                                    <td rowSpan={2}>{data2['item_unit']}</td>
                                                                                    <td rowSpan={2} className={style.td_right}>{useJwt.numberWithCommas(data2['carry_forward_num'])}</td>
                                                                                    <td rowSpan={2} className={style.td_right}>{useJwt.numberWithCommas(data2['arrival_num'])}</td>
                                                                                    <td rowSpan={2} className={style.td_right}>{useJwt.numberWithCommas(data2['leaving_num'])}</td>
                                                                                    <td rowSpan={2} className={style.td_right}>{useJwt.numberWithCommas(data2['stock_move_2'])}</td>
                                                                                    <td rowSpan={2} className={style.td_right}>{useJwt.numberWithCommas(data2['remain_num'])}</td>
                                                                                    <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(data2['remain_price'])}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    {index1==0 && index2==0 && 
                                                                                        <td className={style.td_left}>{data2['shipper_name']}</td>
                                                                                    }
                                                                                    <td colSpan={2} className={style.td_left}>{data2['item_name']}</td>
                                                                                </tr>
                                                                            </>
                                                                            :<>{RotType>0 &&
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td colSpan={4} height="52">{data2['total_label']}</td>
                                                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data2['arrival_num'])}</td>
                                                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data2['leaving_num'])}</td>
                                                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data2['stock_move_2'])}</td>
                                                                                        <td colSpan={2}></td>
                                                                                    </tr>
                                                                                }
                                                                            </>}
                                                                        </React.Fragment>
                                                                    )) }
                                                                    
                                                                </>
                                                            :<>
                                                                <tr className={style.bg_tbl_total}>
                                                                    <td colSpan={5} height="52">{data1['total_label']}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['arrival_num'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['leaving_num'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['stock_move_2'])}</td>
                                                                    <td colSpan={2}></td>
                                                                </tr>
                                                            </>}
                                                            
                                                        </React.Fragment>
                                                    )) }
                                                </React.Fragment>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'在庫日報_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default StockDailyReport;