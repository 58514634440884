/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import style from "./Login.module.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import axios from "axios";
import $ from "jquery";
import * as useJwt from "../../auth/jwt/useJwt.js";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button } from "antd";

const ForgetPassword = (props) => {

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [token, setToken] = useState("");
  const [userFlag, setUserFlag] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [isRevealPasswordConfirm, setIsRevealPasswordConfirm] = useState(false);
  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };
  const togglePasswordConfirm = () => {
    setIsRevealPasswordConfirm((prevState) => !prevState);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangePasswordConfirm = (event) => {
    setPasswordConfirm(event.target.value);
  };

  useEffect(() => {
    setToken(params.token);
    setUserFlag(params.user);
  }, [props, params]);

  const sendPassword = (event) =>{
    $('#modal').css('opacity', 0);
    $('#modal').css('z-index', 0);

    if(password == ""){
      toast.error("パスワードを入力してください。", { autoClose: 3000 });
      return;
    }

    if(password != passwordConfirm){
      toast.error("パスワードをもう一度確認してください。", { autoClose: 3000 });
      return;
    }
    const data = {
      password: password,
      token: token,
      user_flag: userFlag
    };

    axios
      .post(useJwt.getPasswordChangeEndpoint(), data)
      .then((response) => {
        console.log(response.data);
        const data = {
          email: response.data.email,
          password: password,
        };
    
        if(userFlag == 1){
          axios
          .post(useJwt.getWarehouseLoginEndpoint(), data)
          .then((response) => {
            const userData = response.data.data;
            useJwt.setToken(response.data.token);
    
            useJwt.setUserData(JSON.stringify(userData));
    
            const data = {
              userID: userData["id"],
              sys_menu_pattern_id: userData["sys_menu_pattern_id"],
            };
            let url =
              jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperMenuPatternEndpoint;
            axios
              .post(url, data, {
                headers: {
                  Authorization: "Bearer " + useJwt.getToken(),
                },
              })
              .then((response2) => {
                if (response2.status == 200) {
                  if (response2.data.data.length > 0) {
                    let result = response2.data.data[0];
                    useJwt.setMenuPatternData(JSON.stringify(result));
                    navigate("/");
                  }
                }
              })
              .catch((error) => {});
          })
          .catch((error) => {
            // console.log(error.message);
            toast.error(error.message, { autoClose: 3000 });
          });
        }else{
          axios
          .post(useJwt.getShipperLoginEndpoint(), data)
          .then((response) => {
            const userData = response.data.data;
            useJwt.setToken(response.data.token);
    
            useJwt.setShipperUserData(JSON.stringify(userData));
    
            const data = {
              userID: userData["id"],
              sys_menu_pattern_id: userData["sys_menu_pattern_id"],
            };
            let url =
              jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperMenuPatternEndpoint;
            axios
              .post(url, data, {
                headers: {
                  Authorization: "Bearer " + useJwt.getToken(),
                },
              })
              .then((response2) => {
                if (response2.status == 200) {
                  if (response2.data.data.length > 0) {
                    let result = response2.data.data[0];
                    useJwt.setMenuPatternData(JSON.stringify(result));
                    navigate("/shipper");
                  }
                }
              })
              .catch((error) => {});
          })
          .catch((error) => {
            // console.log(error.message);
            toast.error(error.message, { autoClose: 3000 });
          });
        }
        })
      .catch((error) => {
        // console.log(error.message);
        toast.error(error.message, { autoClose: 3000 });
      });
  }
  return (
    <>
    <div className={style.background}>
      <div className={style.forgetForm}>
      <div className={style.title}>
          <span>パスワード再設定</span>
        </div>
        <div style={{marginTop: 60}}>
        <div className={style.subTitle}>パスワード</div>
        <div className={style.passwordField}>
              <input
                className={style.PasswordText}
                placeholder="Password"
                type={isRevealPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={handleChangePassword}
              />
              {isRevealPassword ? (
                <AiFillEyeInvisible
                  onClick={togglePassword}
                  role="presentation"
                  className={style.PasswordReveal}
                />
              ) : (
                <AiFillEye
                  onClick={togglePassword}
                  role="presentation"
                  className={style.PasswordReveal}
                />
              )}
            </div>
            <div className={style.subTitle}>パスワード確認</div>
            <div className={style.passwordField}>
              <input
                className={style.PasswordText}
                placeholder="Password"
                type={isRevealPassword ? "text" : "password"}
                name="password"
                value={passwordConfirm}
                onChange={handleChangePasswordConfirm}
              />
              {isRevealPasswordConfirm ? (
                <AiFillEyeInvisible
                  onClick={togglePasswordConfirm}
                  role="presentation"
                  className={style.PasswordReveal}
                />
              ) : (
                <AiFillEye
                  onClick={togglePasswordConfirm}
                  role="presentation"
                  className={style.PasswordReveal}
                />
              )}
            </div>

          <div className={style.submitButtonField} style={{marginTop:20}}>
            <button className={style.changePasswordButton} onClick={(e) => sendPassword(e)}>
            変  更
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
    </>
  );
};

export default ForgetPassword;
