/* eslint-disable eqeqeq */
import style from './JobRegistory.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import WorkerModal from '../../../components/common/modal/WorkerModal';
import SummaryModal from '../../../components/common/modal/SummaryModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import FreightageModal from '../../../components/common/modal/FreightageModal';
import ConsignorModal from '../../../components/common/modal/ConsignorModal';
import CarrierModal from '../../../components/common/modal/CarrierModal';
import DriverModal from '../../../components/common/modal/DriverModal';
import BusModal from '../../../components/common/modal/BusModal';
import DistinationModal from '../../../components/common/modal/DistinationModal';
import RotModal from '../../../components/common/modal/RotModal';
import AreaModal from '../../../components/common/modal/AreaModal';
import TableModal from '../../../components/common/modal/TableModal';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import InputDatepickerTable from '../../../components/common/input/InputDatepickerTable';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { Button, Popover, Radio } from 'antd';
import $ from 'jquery';
import { QRCodeCanvas } from "qrcode.react";
import { useReactToPrint } from "react-to-print";
import { Barcode } from '@progress/kendo-react-barcodes';
import { SearchOutlined, ScheduleFilled } from '@ant-design/icons';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const JobRegistory = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();

    // ========================== print =================================================

    const [itemInformation, setItemInformation] = useState([]);

    const QRRef = useRef();

    const [loaded, setLoaded] = useState(false);

    const csLoading = (flag) => {
        if (flag) {
            document.getElementById('csLoader').style.display = "flex";
        } else {
            document.getElementById('csLoader').style.display = "none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        if (WarehouseCode == "") {
            toast.error("倉庫を選択してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }

        var length_ms = moment(EndDate + EndTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(StartDate + StartTime, 'YYYY-MM-DD HH:mm:ss'), 'seconds');

        if (StartDate == "") {
            toast.error("開始予定時間を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (StartTime == "") {
            toast.error("開始予定時間を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (EndDate == "") {
            toast.error("終了予定時間を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (EndTime == "") {
            toast.error("終了予定時間を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }

        if (length_ms < 0) {
            toast.error("時間の設定が正しくありません。", { autoClose: 3000 });
            setOpen(false);
            return;
        }

        if (inout == 2) {
            if (CarrierCode == "") {
                toast.error("運送業者コードを入力してください。", { autoClose: 3000 });
                return;
            }

            // if(CarrierStoolCode == "") {
            //     toast.error("運送便を入力してください。", {autoClose:3000}); 
            //     return;
            // }

            if (DistinationCode == "") {
                toast.error("届け先コードを入力してください。", { autoClose: 3000 });
                return;
            }

            if (AreaCode == "") {
                toast.error("地区コードを入力してください。", { autoClose: 3000 });
                return;
            }
        }
        if (busId == "") {
            toast.error("バースを選択してください。", { autoClose: 3000 });
            return;
        }


        if(sMainTableData.length == 0){
            toast.error("指示しようとしている商品リストはありません。", { autoClose: 3000 });
            return;
        }
        // item data --------------------------------------
        let validate_flg = true;
        let vmsg = "";

        for (var i = 0; i < sMainTableData.length; i++) {
            let row = {};
            // if(sMainTableData[i]['sys_item_id'] != 0) {
            //     row['sys_item_id'] = sMainTableData[i]['sys_item_id'];
            // }

            if (sMainTableData[i]['item_code'] == "") {
                vmsg = "品番コードを入力してください。";
                validate_flg = false;
                break;
            }

            if (sMainTableData[i]['warehouse_type'] != 0) {
                if (sMainTableData[i]['table_code'] == "") {
                    vmsg = "棚を入力してください。";
                    validate_flg = false;
                    break;
                }
            }

            if (sMainTableData[i]['item_num'] == 0) {
                vmsg = "数量を入力してください。";
                validate_flg = false;
                break;
            }
            if (sMainTableData[i]['item_deadline_date'] == "") {
                vmsg = "入庫日を入力してください。";
                validate_flg = false;
                break;
            }

        }

        if (!validate_flg) {
            toast.error(vmsg, { autoClose: 3000 });
            return;
        }

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const newRecord = (event) => {
        let data = [];
        for (var i = 0; i < sMainTableData.length; i++) {
            let row = {};
            row = sMainTableData[i];
            data[i] = row;
        }

        if (emptyData == "") {
            if (ShipperCode == "") {
                toast.error("荷主を入力してください。", { autoClose: 3000 });
                setOpen(false);
                return;
            }
            if (ShipperACode == "") {
                toast.error("荷主を入力してください。", { autoClose: 3000 });
                setOpen(false);
                return;
            }
            if (WarehouseCode == "") {
                toast.error("倉庫を選択してください。", { autoClose: 3000 });
                setOpen(false);
                return;
            }
            let row = {};
            row['item_code'] = "";
            row['item_a_code'] = "";
            row['shipper_item_code'] = "";
            row['item_name'] = "";
            row['shipper_code'] = ShipperCode;
            row['shipper_a_code'] = ShipperACode;
            row['rot_code'] = "";
            row['rot_name'] = "";
            row['rot_weight'] = 0;
            row['warehouse_code'] = "";
            row['warehouse_type'] = "";
            row['warehouse_name'] = "";
            row['table_code'] = 0;
            row['item_unit'] = "";
            row['unit_weight'] = 0;
            row['unit_volume'] = 0;
            row['case_num'] = 0;
            row['bara_num'] = 0;
            row['item_num'] = 0;
            row['stock_num'] = 0;
            row['rot_id'] = '';
            row['item_deadline_date'] = useJwt.formatDate(new Date());
            row['remark'] = "";
            row['driver_id'] = ""
            row['transport_id'] = ""

            data[i] = row;

        } else {
            let row = emptyData
            row['item_num'] = ""
            data[i] = row;
        }
        gridSMainTable(data);
        // var length_ms = moment(EndDate + EndTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(StartDate + StartTime, 'YYYY-MM-DD HH:mm:ss'), 'seconds');

        // if (StartDate == "") {
        //     toast.error("開始予定時間を入力してください。", { autoClose: 3000 });
        //     setOpen(false);
        //     return;
        // }
        // if (StartTime == "") {
        //     toast.error("開始予定時間を入力してください。", { autoClose: 3000 });
        //     setOpen(false);
        //     return;
        // }
        // if (EndDate == "") {
        //     toast.error("終了予定時間を入力してください。", { autoClose: 3000 });
        //     setOpen(false);
        //     return;
        // }
        // if (EndTime == "") {
        //     toast.error("終了予定時間を入力してください。", { autoClose: 3000 });
        //     setOpen(false);
        //     return;
        // }

        // if (length_ms < 0) {
        //     toast.error("時間の設定が正しくありません。", { autoClose: 3000 });
        //     setOpen(false);
        //     return;
        // }


        setTimeout(() => {
            let endRow = sMainTableData.length;
            document.getElementById('table_shipper_input' + endRow).focus();
        }, 10)

    }

    // in and out set
    const [In, setIn] = useState("");
    const handleChangeIn = (event, param) => {
        setIn(param);
        setOut("");
        gridMainTable([]);
        gridSMainTable([]);
        setStartTime("");
        setEndTime("");
        document.getElementById('allCheckBtn').checked = false;
        document.getElementById('allScheduleCheckBtn').checked = false;
        setItemInformation([]);
    }

    const [Out, setOut] = useState("2");
    const [inout, setInout] = useState("")
    const handleClickInout = (e) => {
        setInout(e.target.value)
    }
    const handleChangeOut = (event, param) => {
        setIn("");
        setOut(param);
        gridMainTable([]);
        gridSMainTable([]);
        setStartTime("");
        setEndTime("");
        document.getElementById('allCheckBtn').checked = false;
        document.getElementById('allScheduleCheckBtn').checked = false;
        setItemInformation([]);
    }
    // start date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        var current_date = new Date();
        var diff_day = moment(current_date, "YYYY-MM-DD").diff(moment(date, "YYYY-MM-DD 00:00:00"), 'days');
        if (diff_day > 0) {
            toast.error("過去の日付は入力できません。", { autoClose: 3000 });
        } else {
            setStartDate(useJwt.formatDate(date));
        }
    };
    // start time ---------------------------------------
    const [StartTime, setStartTime] = useState("");
    const handleChangeStartTime = (event) => {
        setStartTime(event.target.value);
    };

    // end date ---------------------------------------
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));

    const handleChangeEndDate = (date) => {
        var current_date = new Date();
        var diff_day = moment(current_date, "YYYY-MM-DD").diff(moment(date, "YYYY-MM-DD 00:00:00"), 'days');
        if (diff_day > 0) {
            toast.error("過去の日付は入力できません。", { autoClose: 3000 });
        } else {
            setEndDate(useJwt.formatDate(date));
        }
    };
    // end time ---------------------------------------
    const [EndTime, setEndTime] = useState("");
    const handleChangeEndTime = (event) => {
        setEndTime(event.target.value);
    };

    // waerhouse in worker --------------------------------------------------
    const [WarehouseCode, setWarehouseCode] = useState("");
    const [WarehouseId, setWarehouseId] = useState("");
    const [WarehouseName, setWarehouseName] = useState("");
    const [WarehouseType, setWarehouseType] = useState("");

    const handleChangeWarehouseCode = (event) => {
        setWarehouseCode(useJwt.validateNum(event.target.value, 2));
    };

    // worker --------------------------------------------------
    const [WorkerName, setWorkerName] = useState("");
    const [WorkerId, setWorkerId] = useState("0");
    const handleChangeWorkerName = (event) => {
        setWorkerName(event.target.value);
    };

    const onKeyDownWorkerName = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getWorkerData(event.target.value);
            event.preventDefault();
            document.getElementById('start_time_input').focus();
        }
    };

    const onKeyDownStartTime = (event) => {
        if (event.keyCode === 13) {
            setStartTime(event.target.value);
            event.preventDefault();
            document.getElementById('end_time_input').focus();
        }
    }

    const onKeyDownEndTime = (event) => {
        if (event.keyCode === 13) {
            setEndTime(event.target.value);
            event.preventDefault();
            document.getElementById('summary_input').focus();
        }
    }
    const getWorkerData = (name) => {
        if (name != "") {
            const data = {
                userID: userData['id'],
                name: name
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWorkerListEndPoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        // useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWorkerData(result);
                        } else {
                            setWorkerData([]);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setWorkerData([]);
        }
    }
    const setWorkerData = (data) => {
        if (data['user_family_nm_kanji']) {
            setWorkerId(data['id'])
            setWorkerName(data['user_family_nm_kanji'] + ' ' + data['user_first_nm_kanji']);
        } else {
            setWorkerName("");
            setWorkerId("0");
        }
    }
    // shipper ---------------------------------------------------
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        setShipperCode(useJwt.validateNum(event.target.value, 6));
        setItemData([], 1);
        setItemData([], 2);
        gridSMainTable([]);
        gridMainTable([]);
    };
    const [ShipperACode, setShipperACode] = useState("");
    const handleChangeShipperACode = (event) => {
        setShipperACode(useJwt.validateNum(event.target.value, 3));
    };
    const [ShipperName, setShipperName] = useState("");
    const [ShipperId, setShipperId] = useState("");

    const onKeyDownShipperCode = (event, param1, param2) => {
        if (event.keyCode === 13) { // enter key
            getShipperData(event.target.value, param1, param2);
            event.preventDefault();
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if (param1 == 1) {
            if (param2 == 1) {
                code = value;
                if (ShipperACode != "") {
                    acode = ShipperACode;
                } else {
                    acode = "000";
                }
            } else {
                code = ShipperCode;
                if (value != "") {
                    acode = value;
                } else {
                    acode = "000";
                }
            }
        } else {
            if (param2 == 1) {
                code = value;
                if (ShipperACode != "") {
                    acode = ShipperACode;
                } else {
                    acode = "000";
                }
            } else {
                code = ShipperCode;
                if (value != "") {
                    acode = value;
                } else {
                    acode = "000";
                }
            }
        }

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if (acode != "") {
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                }).then((response) => {
                    if (response.status == 200) {
                        // useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setShipperData(result, param1);
                            document.getElementById('warehouse_input').focus();
                        } else {
                            setShipperData([], param1);
                            if (param2 == 2) {
                                document.getElementById('warehouse_input').focus();
                            } else {
                                document.getElementById('shipper_a_input').focus();
                            }
                        }
                    }
                })
                .catch((error) => {
                    if (param2 == 2) {
                        document.getElementById('warehouse_input').focus();
                    } else {
                        document.getElementById('shipper_a_input').focus();
                    }
                });
        } else {
            setShipperData([], param1);
            if (param2 == 2) {
                document.getElementById('warehouse_input').focus();
            } else {
                document.getElementById('shipper_a_input').focus();
            }
        }
    }
    const setShipperData = (data, param) => {
        if (data['shipper_code'] != null) {
            setShipperCode(data['shipper_code']);
            setShipperId(data['shipper_id']);
            setShipperACode(data['shipper_a_code']);
            setShipperName(data['shipper_name']);
        } else {
            setShipperCode("");
            setShipperId("");
            setShipperACode("");
            setShipperName("");
        }
    }

    // item ----------------------------------------------------
    const [ItemCode, setItemCode] = useState("");
    const handleChangeItemCode = (event) => {
        setItemCode(useJwt.validateString(event.target.value, 15));
    };
    const [ItemACode, setItemACode] = useState("");
    const handleChangeItemACode = (event) => {
        setItemACode(useJwt.validateNum(event.target.value, 3));
    };
    const [ItemName, setItemName] = useState("");
    const onKeyDownItemCode = (event, param1, param2) => {
        if (event.keyCode === 13) { // enter key
            getItemData(event.target.value, param1, param2);
            event.preventDefault();
        }
    };

    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if (param2 == 1) {
            code = value;
            if (ItemACode != "") {
                acode = ItemACode;
            } else {
                acode = "000";
            }
        } else {
            code = ItemCode;
            if (value != "") {
                acode = value;
            } else {
                acode = "000";
            }
        }

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode;
            data['item_code'] = code;
            if (acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        // useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setItemData(result, param1);
                        } else {
                            setItemData([], param1);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setItemData([], param1);
        }
    }

    // =================================================================================

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);
            if (menuPatternData['menu_daily_flag_1'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            document.getElementById("shipper_input").focus();
        }
    });
    // ----------------------------- Worker modal -------------------------------------
    const [showModalWorker, setShowModalWorker] = useState(false);
    const [WorkerSearchValue, setWorkerSearchValue] = useState("");
    const [WorkerTableData, setWorkerTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWorkerModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let name = WorkerName;
        let workerId = WorkerId;
        setWorkerSearchValue(name);

        if (workerId != "0") {
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_id'] = WarehouseId;

            if (workerId != "0") {
                data['id'] = workerId;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWorkerByIdEndPoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    if (response.data.data.length > 0) {
                        let tempArray = [];
                        response.data.data.forEach(element => {
                            let temp = element;
                            temp['name'] = element.user_family_nm_kanji + " " + element.user_first_nm_kanji

                            tempArray.push(temp);
                        });
                        setWorkerTableData(tempArray);
                    }

                    setShowModalWorker(true);
                })
                .catch((error) => {
                });
        } else {
            setWorkerTableData([]);
            setShowModalWorker(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode;
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setShipperTableData(response.data.data);
                    setShowModalShipper(true);
                })
                .catch((error) => {
                });
        } else {
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemSearchWarehouse, setItemSearchWarehouse] = useState("");
    const [ItemSearchInOutFlag, setItemSearchInOutFlag] = useState("2");
    const [itemUrl, setItemUrl] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event, id, code, acode) => {
        document.getElementById('selectedId').value = id;
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode);
        setItemSearchWarehouse(WarehouseCode);
        setItemSearchInOutFlag(inout == "2" ? "1" : "0");

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemEndpoint;
        if (inout == "2") {
            url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemStockEndpoint;
        }
        setItemUrl(url);
        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            data['shipper_code'] = ShipperCode;
            if (acode != "") {
                data['item_a_code'] = acode;
            }

            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setItemTableData(response.data.data);
                    setShowModalItem(true);
                })
                .catch((error) => {
                });
        } else {
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    const setItemData = (data) => {
        let item_code = "";
        let item_a_code = "";
        let item_name = "";
        let stock_num = "";
        let item_unit = "";
        if (data['item_code']) {
            item_code = data['item_code'];
            item_a_code = data['item_a_code'];
            item_name = data['item_name'];
            stock_num = data['amount'];
            item_unit = data['item_unit'];
        }

        let itemData = [];
        for (var i = 0; i < sMainTableData.length; i++) {
            let row = {};
            row = sMainTableData[i];
            if (i == document.getElementById('selectedId').value) {
                if (row['item_code'] != item_code || row['item_a_code'] != item_a_code) {
                    row['rot_code'] = "";
                    row['rot_name'] = "";
                    row['rot_weight'] = 0;
                }
                row['item_code'] = item_code;
                row['item_a_code'] = item_a_code;
                row['item_name'] = item_name;
                row['stock_num'] = stock_num;
                row['item_unit'] = item_unit;
            }
            itemData[i] = row;
        }
        gridSMainTable(itemData);
    }

    const scheduleSave = (event) => {
        event.preventDefault();


        let data = {};
        data['userID'] = userData['id'];

        data['schedule_date'] = commandDate;
        data['shipper_code'] = ShipperCode;
        data['shipper_a_code'] = ShipperACode;
        data['summary_code'] = SummaryCode;
        data['freightage_type_1'] = freightageCode1;
        data['freightage_type_2'] = freightageCode2;
        data['freightage_type_3'] = freightageCode3;
        data['consignor_code'] = ConsignorCode;
        data['consignor_a_code'] = ConsignorACode;
        data['carrier_code'] = CarrierCode;
        data['carrier_a_code'] = CarrierACode;
        data['carrier_stool_code'] = CarrierStoolCode;
        data['distination_code'] = DistinationCode;
        data['distination_a_code'] = DistinationACode;
        data['area_code'] = AreaCode;
        data['work_type'] = inout == "2" ? "1" : "0";
        data['start_date_time'] = StartDate + ' ' + StartTime;
        data['end_date_time'] = EndDate + ' ' + EndTime;
        data['sys_user_id'] = WorkerId;
        data['warehouse_id'] = WarehouseId;
        data['carrier_name'] = CarrierName
        data['driver_name'] = driverName
        data['driver_phone'] = driverPhone
        data['car_number'] = carNumber
        data['car_type'] = carType
        data['bus_id'] = busId
        data['command_id'] = commandId

        // item data --------------------------------------
        let itemData = [];
        for (var i = 0; i < sMainTableData.length; i++) {
            let row = {};
            // if(mainTableData[i]['sys_item_id'] != 0) {
            //     row['sys_item_id'] = mainTableData[i]['sys_item_id'];
            // }

            row['item_code'] = sMainTableData[i]['item_code'];
            row['item_a_code'] = sMainTableData[i]['item_a_code'];
            row['shipper_item_code'] = sMainTableData[i]['shipper_item_code'];
            row['rot_code'] = sMainTableData[i]['rot_code'];
            row['rot_name'] = sMainTableData[i]['rot_name'];
            row['rot_id'] = sMainTableData[i]['rot_id'];
            row['warehouse_code'] = WarehouseCode;
            row['table_code'] = sMainTableData[i]['table_code'];
            if (sMainTableData[i]['case_num'] != "") {
                row['case_num'] = sMainTableData[i]['case_num'];
            } else {
                row['case_num'] = '';
            }
            if (sMainTableData[i]['bara_num'] != "") {
                row['bara_num'] = sMainTableData[i]['bara_num'];
            } else {
                row['bara_num'] = '';
            }

            row['item_num'] = sMainTableData[i]['item_num'];
            row['item_deadline_date'] = sMainTableData[i]['item_deadline_date'];
            row['remark'] = sMainTableData[i]['remark'];

            itemData[i] = row;
        }

        data['items'] = itemData;

        // if(SysLeavingSlipId == 0) {
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setScheduleRegisterPoint;
        csLoading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if (response.status == 200) {
                    useJwt.setToken(response.data.token);
                    toast.success("登録致しました。", { autoClose: 3000 });
                    setCommandDate(useJwt.formatDate(new Date()));
                    setSummaryCode("");

                    // setMainTableData([]);
                    setSMainTableData([]);
                    setInout("")
                    setEmptyData("")
                    setCarrierName("")
                    setDriverName("")
                    setDriverPhone("")
                    setCarNumber("")
                    setCarType("1")
                    setBusName("")
                    setBusId("")
                    setBusStandard("")
                }
                csLoading(false);
            })
            .catch((error) => {
                csLoading(false);
                if (error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }
            });
        setOpen(false);
    }

    const [itemCount, setItemCount] = useState("");
    const handleChangeItemCount = (e) => {
        setItemCount(e.target.value);
    }

    const [commandDate, setCommandDate] = useState(useJwt.formatDate(new Date()))
    const handleChangeCommandDate = (date) => {
        setCommandDate(useJwt.formatDate(date));
    };

    const allCheckData = (event) => {
        $('#tbodyWrapCommand input[type="checkbox"]').prop('checked', event.target.checked);
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrapCommand input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };

    const allScheduleCheckData = (event) => {
        $('#tbodyWrap input[type="checkbox"]').prop('checked', event.target.checked);

        QRGenerate();
    }
    const handleScheduleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allScheduleCheckBtn').checked = all_check_flg;
        QRGenerate();
    };


    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let data = [];
        for (var i = 0; i < result.length; i++) {
            let row = {};
            row = result[i];
            data[i] = row;
        }
        setMainTableData(data);
    }

    const [sMainTableData, setSMainTableData] = useState([]);

    const gridSMainTable = (result) => {
        let data = [];
        for (var i = 0; i < result.length; i++) {
            let row = {};
            row = result[i];
            data[i] = row;
        }
        setSMainTableData(data);
    }
    // summary ----------------------------------------------------
    const [SummaryCode, setSummaryCode] = useState("");
    const handleChangeSummaryCode = (event) => {
        setSummaryCode(useJwt.validateNum(event.target.value, 4));
    };
    const [SummaryName, setSummaryName] = useState("");
    const onKeyDownSummaryCode = (event) => {
        if (event.keyCode === 13) { // enter key
            getSummaryData(event.target.value);
            event.preventDefault();
            document.getElementById('add_button').focus();
        }
    };
    const getSummaryData = (code) => {
        if (code != "") {
            const data_r = {
                userID: userData['id'],
                summary_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstSummaryEndpoint;
            axios
                .post(url, data_r, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if (t_data.length > 0) {
                        setSummaryData(t_data[0]);
                    } else {
                        setSummaryData([]);
                    }
                })
                .catch((error) => {
                });
        } else {
            setSummaryData([]);
        }
    }
    const setSummaryData = (result) => {
        if (result['sys_summary_id']) {
            setSummaryCode(result['summary_code']);
            setSummaryName(result['summary_name']);
        } else {
            setSummaryName("");
        }
    }

    // ------------------------------ Summary Modal --------------------------------------
    const [showModalSummary, setShowModalSummary] = useState(false);
    const [SummarySearchValue, setSummarySearchValue] = useState("");
    const [SummaryTableData, setSummaryTableData] = useState([]);
    const loadSummaryModalData = (event) => {
        event.preventDefault();
        let code = SummaryCode;
        setSummarySearchValue(code);

        if (code != "") {
            const data = {
                userID: userData['id'],
                summary_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstSummaryEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setSummaryTableData(response.data.data);
                    setShowModalSummary(true);
                })
                .catch((error) => {
                });
        } else {
            setSummaryTableData([]);
            setShowModalSummary(true);
        }
    }

    const [codeType, setCodeType] = useState("1");
    const onChangeCodeType = (e, type) => {
        setCodeType(type);
    }

    const handleChangeItemData = (event, control_type, col, index) => {
        let value = '';
        if (control_type == 2) {
            value = useJwt.formatDate(event);
        } else {
            value = event.target.value;
        }

        // if(col == 'item_num' && In == "1"){
        //     var weight = $('.table_weight').eq(index).val();
        //     var rotWeight = $('.rot_weight').eq(index).val();

        //     if(parseFloat(weight) + parseFloat(rotWeight * value) > 1){
        //         var i = 1;
        //         while(parseFloat(weight) + parseFloat(rotWeight * i) <= 1){
        //             i++;
        //         }
        //         toast.error("数量を超えました。設定した棚に" + (i - 1) +"個以上入庫できません。", {autoClose:3000});
        //         value = "0";
        //         // $('.item_num').eq(index).val("");
        //         // return;
        //     }
        // }

        // if (col == 'item_num' && Out == "2") {
        //     var amount = $('.stock_num').eq(index).val();
        //     console.log(amount)

        //     if (amount < value) {
        //         toast.error("在庫数量を超えています", { autoClose: 3000 });
        //         value = "";
        //     }
        // }
        if (col == 'item_code' || col == 'rot_code') {
            value = useJwt.validateString(value, 15);
        } else if (col == 'item_a_code') {
            value = useJwt.validateNum(value, 3);
        } else if (col == 'shipper_item_code') {
            value = useJwt.validateString(value, 15);
        } else if (col == 'warehouse_code') {
            value = useJwt.validateNum(value, 2);
        } else if (col == 'table_code') {
            value = useJwt.validateString(value, 8);
        } else if (col == 'item_unit') {
            value = useJwt.validateString(value, 4);
        } else if (col == 'item_num' || col == 'case_num' || col == 'bara_num') {
            value = useJwt.validateDecimal(value, 6, 3);
        } else if (col == 'remark') {
            value = useJwt.validateString(value, 10);
        } else if (col == 'rot_name') {
            value = useJwt.validateString(value, 20);
        }
        let data = [];
        for (var i = 0; i < sMainTableData.length; i++) {
            let row = {};
            row = sMainTableData[i];
            if (i == index) {
                row[col] = value;
                if (col == 'item_code' || col == 'item_a_code') {
                    row['shipper_item_code'] = "";
                    row['rot_code'] = "";
                    row['case_num'] = "";
                    row['bara_num'] = "";
                }
                if (col == 'rot_code') {
                    row['rot_id'] = '';
                }
            }
            data[i] = row;
        }
        gridSMainTable(data);
    }

    const tableKeyDown = (e, id) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    const tableEndKeyDown = (e, id, index) => {
        let dataLength = sMainTableData.length;

        if (dataLength <= index + 1) return;

        if (e.keyCode === 13) {
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const loadWarehouseModalData = (event) => {
        event.preventDefault();
        setWarehouseSearchValue(WarehouseCode);

        if (WarehouseCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = WarehouseCode;
            data['warehouse_company_id'] = userData['id'];

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWarehouseTableData(response.data.data);
                    setShowModalWarehouse(true);
                })
                .catch((error) => {
                });
        } else {
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }

    const onKeyDownWarehouseCode = (event) => {
        if (event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value);
            event.preventDefault();
        }
    };
    const getWarehouseData = (code) => {
        if (code != "") {
            const data = {
                userID: userData['id'],
                warehouse_company_id: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        // useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWarehouseData(result);
                            document.getElementById('worker_input').focus();
                        } else {
                            setWarehouseData([]);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setWarehouseData([]);
        }
    }
    const setWarehouseData = (data) => {
        if (data['warehouse_code']) {
            setWarehouseCode(data['warehouse_code']);
            setWarehouseId(data['sys_warehouse_id']);
            setWarehouseName(data['warehouse_name']);
            setWarehouseType(data['warehouse_type']);
        } else {
            setWarehouseCode("");
            setWarehouseId("");
            setWarehouseName("");
            setWarehouseType("");
        }

        setWorkerName("");
        setWorkerId("0");
    }

    // ----------------------------- Table modal -------------------------------------
    const [showModalTable, setShowModalTable] = useState(false);
    const [tableSearchValue, setTableSearchValue] = useState("");
    const [tableSearchValue2, setTableSearchValue2] = useState("");
    const [tableSearchValue3, setTableSearchValue3] = useState("");
    const [tableData, setTableData] = useState([]);
    const loadTableModalData = (event, idx, table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value = idx;
        var warehouse_code = WarehouseCode;

        if (warehouse_code == "") {
            toast.error("倉庫コードを入力してください。", { autoClose: 3000 });
            return;
        }

        var rot_weight = $(".rot_weight").eq(idx).val();
        setTableSearchValue(table_code);
        setTableSearchValue2(warehouse_code);
        setTableSearchValue3(rot_weight);

        if (warehouse_code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if (table_code != "") data['table_code'] = table_code;
            data['weight'] = rot_weight;

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstTableEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setTableData(response.data.data);
                    setShowModalTable(true);
                })
                .catch((error) => {
                });
        } else {
            setTableData([]);
            setShowModalTable(true);
        }
    }
    const setTableCode = (data) => {
        let itemData = [];
        for (var i = 0; i < sMainTableData.length; i++) {
            let row = {};
            row = sMainTableData[i];
            if (i == document.getElementById('selectedId').value) {
                if (data) {
                    row['table_code'] = data['table_code'];
                    row['weight'] = data['weight'];
                } else {
                    row['table_code'] = "";
                    row['weight'] = 0;
                }
            }
            itemData[i] = row;
        }
        gridSMainTable(itemData);
    }

    const commandView = () => {
        // if (ShipperCode == "") {
        //     toast.error("荷主を選択してください。", { autoClose: 3000 });
        //     setOpen(false);
        //     return;
        // }
        getInoutCommand(commandDate, userData['id'], "4", inout);
    }

    const itemRegistry = (e, id) => {
        navigate("/contact/shipper/item", {
            state: {
                data: id,
            },
            replace: false,
        });
    }

    const [emptyData, setEmptyData] = useState("")
    const [driverId, setDriverId] = useState("")
    const [commandId, setCommandId] = useState("")
    const scheduleAddByCommand = (e, commandData) => {
        console.log(commandData)
        setEmptyData(commandData)
        setShipperCode(commandData['shipper_code'])
        setShipperACode(commandData['shipper_a_code'])
        setShipperName(commandData['shipper_name'])
        setInout(commandData['inout_type'])
        setCarrierName(commandData['carrier_name'] ? commandData['carrier_name'] : '')
        setDriverName(commandData['driver_name'] ? commandData['driver_name'] : '')
        setDriverPhone(commandData['driver_phone'] ? commandData['driver_phone'] : '')
        setCarNumber(commandData['car_number'] ? commandData['car_number'] : '')
        setCarType(commandData['car_type'] ? commandData['car_type'] : '1')
        setDriverId(commandData['driver_id'] ? commandData['driver_id'] : '')
        setCarrierId(commandData['transport_id'] ? commandData['transport_id'] : '')
        setCommandId(commandData['id'])
        let data = [];
        let temp = {};
        temp['item_code'] = commandData['item_code'];
        temp['item_a_code'] = commandData['item_a_code'];
        temp['shipper_item_code'] = commandData['shipper_item_code'];
        temp['item_name'] = commandData['item_name'];
        temp['rot_code'] = commandData['rot_code'];
        temp['rot_name'] = commandData['rot_name'];
        temp['rot_weight'] = commandData['rot_weight'];
        temp['item_num'] = commandData['item_num'];
        temp['item_unit'] = commandData['item_unit'];
        temp['sys_user_id'] = WorkerId;
        temp['shipper_code'] = ShipperCode;
        temp['shipper_a_code'] = ShipperACode;
        temp['work_type'] = commandData['inout_type'];
        temp['warehouse_code'] = WarehouseCode;
        temp['table_code'] = 0;
        temp['unit_weight'] = 0;
        temp['unit_volume'] = 0;
        temp['case_num'] = 0;
        temp['bara_num'] = 0;
        temp['start_date_time'] = StartDate + ' ' + StartTime;
        temp['end_date_time'] = EndDate + ' ' + EndTime;
        temp['remark'] = "";

        data.push(temp);
        gridSMainTable(data);
    }

    const scheduleAdd = () => {
        newRecord();
    }

    const scheduleDel = () => {
        if (itemInformation.length == 0) {
            toast.error("削除したい作業スケジュールを選択してください。", { autoClose: 3000 });
            return;
        }

        loadModalDelData2();
    }

    const QRGenerate = () => {
        let data = [];
        var x = 0;

        var checkboxes = $('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            let temp = {};
            if (checkboxes[i].checked) {
                temp['code'] = sMainTableData[i]['shipper_code'] + '&&' + sMainTableData[i]['shipper_a_code'] + '&&' + sMainTableData[i]['item_code']
                    + '&&' + sMainTableData[i]['item_a_code'] + '&&' + sMainTableData[i]['rot_code'] + '&&' + WarehouseCode + '&&' + sMainTableData[i]['table_code']
                    + '&&' + sMainTableData[i]['item_num'] + '&&' + userData['id'] + '&&' + (inout == "2" ? "1" : "0");
                temp['name'] = sMainTableData[i]['item_name'];
                data.push(temp);
            }
        }

        if (data.length == 0) {
            setItemInformation([]);
        } else {
            setItemInformation(data);
        }
    }

    const getInoutCommand = (date, code, sub_code, inout_type) => {
        if (code == "") return false;
        let data = {};
        data['inout_type'] = inout_type;
        data['command_date'] = date;
        data['warehouse_id'] = code;
        // data['shipper_id'] = ShipperId;

        data['userID'] = userData['id'];

        csLoading(true)
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getInoutCommandWarehouseEndPoint;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                csLoading(false)
                let data = []
                response.data.data.forEach(element => {
                    element['item_code'] = element['m_item_code']
                    element['item_a_code'] = element['m_item_a_code']
                    data.push(element)
                })
                console.log(data)
                setMainTableData(data);
            })
            .catch((error) => {
                csLoading(false)
            });
    }

    // freightage ----------------------------------------------------
    const [freightageCode1, setFreightageCode1] = useState("");
    const [freightageName1, setFreightageName1] = useState("");
    const [freightageCode2, setFreightageCode2] = useState("");
    const [freightageName2, setFreightageName2] = useState("");
    const [freightageCode3, setFreightageCode3] = useState("");
    const [freightageName3, setFreightageName3] = useState("");
    const handleChangeFreightageCode = (event, param) => {
        if (param == 1) {
            setFreightageCode1(useJwt.validateNum(event.target.value, 4));
        } else if (param == 2) {
            setFreightageCode2(useJwt.validateNum(event.target.value, 4));
        } else {
            setFreightageCode3(useJwt.validateNum(event.target.value, 4));
        }
    };
    const onKeyDownFreightageCode = (event, param) => {
        if (event.keyCode == 13) { // enter key
            if (param == 1) document.getElementById('freightageCode2').focus();
            if (param == 2) document.getElementById('freightageCode3').focus();
            if (param == 3) document.getElementById('input1').focus();
            getFreightageData(event.target.value, param);
            event.preventDefault();
        }
    };
    const getFreightageData = (code, param) => {
        if (code != "") {
            const data_r = {
                userID: userData['id'],
                freightage_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstFreightageEndpoint;
            axios
                .post(url, data_r, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if (t_data.length > 0) {
                        if (param == 1) {
                            setFreightageName1(t_data[0]['freightage_name']);
                        } else if (param == 2) {
                            setFreightageName2(t_data[0]['freightage_name']);
                        } else {
                            setFreightageName3(t_data[0]['freightage_name']);
                        }
                    } else {
                        if (param == 1) {
                            setFreightageCode1("");
                            setFreightageName1("");
                        } else if (param == 2) {
                            setFreightageCode2("");
                            setFreightageName2("");
                        } else {
                            setFreightageCode3("");
                            setFreightageName3("");
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            if (param == 1) {
                setFreightageName1("");
            } else if (param == 2) {
                setFreightageName2("");
            } else {
                setFreightageName3("");
            }
        }
    }
    // Consignor -----------------------------------------------------
    const [ConsignorCode, setConsignorCode] = useState("");
    const handleChangeConsignorCode = (event) => {
        setConsignorCode(useJwt.validateNum(event.target.value, 6));
    };
    const [ConsignorACode, setConsignorACode] = useState("");
    const handleChangeConsignorACode = (event) => {
        setConsignorACode(useJwt.validateNum(event.target.value, 3));
    };
    const [ConsignorName, setConsignorName] = useState("");
    const onKeyDownConsignorCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getConsignorData();
            if (param == 1) document.getElementById('input2').focus();
            if (param == 2) document.getElementById('input8').focus();
            event.preventDefault();
        }
    };
    const getConsignorData = () => {
        if (ConsignorCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['consignor_code'] = ConsignorCode;
            if (ConsignorACode != "") {
                data['consignor_a_code'] = ConsignorACode;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstConsignorEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if (t_data.length > 0) {
                        setConsignorData(t_data[0]);
                    } else {
                        setConsignorData([]);
                    }
                })
                .catch((error) => {
                });
        } else {
            setConsignorData([]);
        }
    }
    const setConsignorData = (result) => {
        if (result['sys_consignor_id']) {
            setConsignorCode(result['consignor_code']);
            if (result['consignor_a_code'] != null) {
                setConsignorACode(result['consignor_a_code']);
            }
            if (result['consignor_name'] != null) {
                setConsignorName(result['consignor_name']);
            }
        } else {
            setConsignorName("");
        }
    }
    // Carrier ----------------------------------------------------------
    const [CarrierCode, setCarrierCode] = useState("");
    const [carrierId, setCarrierId] = useState("");
    const handleChangeCarrierCode = (event) => {
        setCarrierCode(useJwt.validateNum(event.target.value, 6));
    };
    const [CarrierACode, setCarrierACode] = useState("");
    const handleChangeCarrierACode = (event) => {
        setCarrierACode(useJwt.validateNum(event.target.value, 3));
    };
    const [CarrierName, setCarrierName] = useState("");
    const onKeyDownCarrierCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            if (param == 1) {
                document.getElementById('input4').focus();
                getCarrierData(event.target.value, CarrierACode);
            } else {
                document.getElementById('input9').focus();
                getCarrierData(CarrierCode, event.target.value);
            }
            event.preventDefault();
        }
    };

    const getCarrierData = (code, acode) => {
        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = code;
            if (acode != "") {
                data['carrier_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCarrierEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if (t_data.length > 0) {
                        setCarrierData(t_data[0]);
                    } else {
                        setCarrierData([]);
                    }
                })
                .catch((error) => {
                });
        } else {
            setCarrierData([]);
        }
    }
    const setCarrierData = (result) => {
        if (result['sys_carrier_id']) {
            setCarrierId(result['sys_carrier_id'])
            setCarrierCode(result['carrier_code']);
            setCarrierACode(result['carrier_a_code']);
            setCarrierName(result['carrier_name']);
        } else {
            setCarrierName("");
        }
    }

    const [CarrierStoolCode, setCarrierStoolCode] = useState("");
    const handleChangeCarrierStoolCode = (event) => {
        setCarrierStoolCode(useJwt.validateNum(event.target.value, 4));
    };
    // Distination -----------------------------------------------------------
    const [DistinationCode, setDistinationCode] = useState("");
    const handleChangeDistinationCode = (event) => {
        setDistinationCode(useJwt.validateNum(event.target.value, 6));
    };
    const [DistinationACode, setDistinationACode] = useState("");
    const handleChangeDistinationACode = (event) => {
        setDistinationACode(useJwt.validateNum(event.target.value, 3));
    };
    const onKeyDownDistinationCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            if (param == 1) {
                document.getElementById('input7').focus();
                getDistinationData(event.target.value, DistinationACode);
            } else {
                document.getElementById('input3').focus();
                getDistinationData(DistinationCode, event.target.value);
            }
            event.preventDefault();
        }
    };
    const [DistinationName, setDistinationName] = useState("");
    const [DistinationTel, setDistinationTel] = useState("");
    const [DistinationAddress1, setDistinationAddress1] = useState("");
    const [DistinationAddress2, setDistinationAddress2] = useState("");
    const [DistinationAddress3, setDistinationAddress3] = useState("");

    const getDistinationData = (code, acode) => {
        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = code;
            if (acode != "") {
                data['distination_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstDistinationEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if (t_data.length > 0) {
                        setDistinationData(t_data[0]);
                    } else {
                        setDistinationData([]);
                    }
                })
                .catch((error) => {
                });
        } else {
            setDistinationData([]);
        }
    }

    const setDistinationData = (result) => {
        if (result['sys_distination_id']) {
            setDistinationCode(result['distination_code']);
            setDistinationACode(result['distination_a_code']);
            setDistinationName(result['distination_name']);
            setDistinationAddress1(result['distination_address_1']);
            setDistinationAddress2(result['distination_address_2']);
            setDistinationAddress3(result['distination_address_3']);
            setDistinationTel(result['distination_tel']);
        } else {
            setDistinationName("");
            setDistinationAddress1(""); setDistinationAddress2(""); setDistinationAddress3("");
            setDistinationTel("");
        }
    }
    // Area -------------------------------------------------------------------
    const [AreaCode, setAreaCode] = useState("");
    const [AreaName, setAreaName] = useState("");
    const handleChangeAreaCode = (event) => {
        setAreaCode(useJwt.validateNum(event.target.value, 6));
    };
    const onKeyDownAreaCode = (event) => {
        if (event.keyCode === 13) { // enter key
            getAreaData(event.target.value);
            document.getElementById('input6').focus();
            event.preventDefault();
        }
    };
    const getAreaData = (code) => {
        if (code != "") {
            const data_r = {
                userID: userData['id'],
                area_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstAreaEndpoint;
            axios
                .post(url, data_r, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    let t_data = response.data.data;
                    if (t_data.length > 0) {
                        setAreaData(t_data[0]);
                    } else {
                        setAreaData([]);
                    }

                })
                .catch((error) => {
                });
        } else {
            setAreaData([]);
        }
    }
    const setAreaData = (data) => {
        if (data['area_code'] != null) {
            setAreaCode(data['area_code']);
            setAreaName(data['area_name']);
        } else {
            setAreaCode("");
            setAreaName("");
        }
    }

    // ------------------------------- freightage modal --------------------------------
    const [showModalFreightage, setShowModalFreightage] = useState(false);
    const [FreightageSearchValue, setFreightageSearchValue] = useState("");
    const [FreightageTableData, setFreightageTableData] = useState([]);

    const setFreightageData = (data) => {
        let resultCode = data['freightage_code'];
        let resultName = data['freightage_name'];
        if (document.getElementById('selectedId').value == 'freightageCode1') {
            setFreightageCode1(resultCode);
            document.getElementById('freightageCode1').value = resultCode;
            setFreightageName1(resultName);
            document.getElementById('freightageName1').value = resultName;
        } else if (document.getElementById('selectedId').value == 'freightageCode2') {
            setFreightageCode2(resultCode);
            document.getElementById('freightageCode2').value = resultCode;
            setFreightageName2(resultName);
            document.getElementById('freightageName2').value = resultName;
        } else {
            setFreightageCode3(resultCode);
            document.getElementById('freightageCode3').value = resultCode;
            setFreightageName3(resultName);
            document.getElementById('freightageName3').value = resultName;
        }
    }
    const loadFreightageModalData = (event, param) => {
        event.preventDefault();
        let code = document.getElementById("freightageCode" + param).value;
        setFreightageSearchValue(code);
        document.getElementById('selectedId').value = "freightageCode" + param;

        if (code != "") {
            const data = {
                userID: userData['id'],
                freightage_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstFreightageEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setFreightageTableData(response.data.data);
                    setShowModalFreightage(true);
                })
                .catch((error) => {
                });
        } else {
            setFreightageTableData([]);
            setShowModalFreightage(true);
        }
    }
    // ------------------------------- Consignor Modal ---------------------------------
    const [showModalConsignor, setShowModalConsignor] = useState(false);
    const [ConsignorSearchValue, setConsignorSearchValue] = useState("");
    const [ConsignorSearchValue2, setConsignorSearchValue2] = useState("");
    const [ConsignorTableData, setConsignorTableData] = useState([]);
    const loadConsignorModalData = (event) => {
        event.preventDefault();
        setConsignorSearchValue(ConsignorCode);
        setConsignorSearchValue2(ConsignorACode);

        if (ConsignorCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['consignor_code'] = ConsignorCode;
            if (ConsignorACode != "") {
                data['consignor_a_code'] = ConsignorACode;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstConsignorEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setConsignorTableData(response.data.data);
                    setShowModalConsignor(true);
                })
                .catch((error) => {
                });
        } else {
            setConsignorTableData([]);
            setShowModalConsignor(true);
        }
    }
    // ------------------------------- Carrier Modal ------------------------------------
    const [showModalCarrier, setShowModalCarrier] = useState(false);
    const [CarrierSearchValue, setCarrierSearchValue] = useState("");
    const [CarrierSearchValue2, setCarrierSearchValue2] = useState("");
    const [CarrierTableData, setCarrierTableData] = useState([]);
    const loadCarrierModalData = (event) => {
        event.preventDefault();
        setCarrierSearchValue(CarrierCode);
        setCarrierSearchValue2(CarrierACode);

        if (CarrierCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = CarrierCode;
            if (CarrierACode != "") {
                data['carrier_a_code'] = CarrierACode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCarrierEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setCarrierTableData(response.data.data);
                    setShowModalCarrier(true);
                })
                .catch((error) => {
                });
        } else {
            setCarrierTableData([]);
            setShowModalCarrier(true);
        }
    }
    // ------------------------------- Distination Modal --------------------------------
    const [showModalDistination, setShowModalDistination] = useState(false);
    const [DistinationSearchValue, setDistinationSearchValue] = useState("");
    const [DistinationSearchValue2, setDistinationSearchValue2] = useState("");
    const [DistinationTableData, setDistinationTableData] = useState([]);

    const loadDistinationModalData = (event) => {
        event.preventDefault();
        setDistinationSearchValue(DistinationCode);
        setDistinationSearchValue2(DistinationACode);

        if (DistinationCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = DistinationCode;
            if (DistinationACode != "") {
                data['distination_a_code'] = DistinationACode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstDistinationEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setDistinationTableData(response.data.data);
                    setShowModalDistination(true);
                })
                .catch((error) => {
                });
        } else {
            setDistinationTableData([]);
            setShowModalDistination(true);
        }
    }
    // ----------------------- Area Modal ------------------------------------------
    const [showModalArea, setShowModalArea] = useState(false);
    const [AreaSearchValue, setAreaSearchValue] = useState("");
    const [AreaTableData, setAreaTableData] = useState([]);
    const loadAreaModalData = (event) => {
        event.preventDefault();
        let code = AreaCode;
        setAreaSearchValue(code);

        if (code != "") {
            const data = {
                userID: userData['id'],
                area_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstAreaEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setAreaTableData(response.data.data);
                    setShowModalArea(true);
                })
                .catch((error) => {
                });
        } else {
            setAreaTableData([]);
            setShowModalArea(true);
        }
    }

    // ------------------------------ rot modal ---------------------------------------
    const [showModalRot, setShowModalRot] = useState(false);
    const [RotSearchValue, setRotSearchValue] = useState("");
    const [RotSearchValue2, setRotSearchValue2] = useState("");
    const [RotSearchValue3, setRotSearchValue3] = useState("");
    const [RotSearchValue4, setRotSearchValue4] = useState("");
    const [RotSearchWarehouse, setRotSearchWarehouse] = useState("");
    const [RotSearchInOutFlag, setRotSearchInOutFlag] = useState("");
    const [RotTableData, setRotTableData] = useState([]);
    const loadRotModalData = (event, id, rot_code, shipper_code, item_code, item_a_code) => {
        document.getElementById('selectedId').value = id;
        if (WarehouseCode == "") {
            toast.error("倉庫を選択してください。", { autoClose: 3000 });
            return
        }
        setRotSearchValue(rot_code);
        setRotSearchValue2(shipper_code);
        setRotSearchValue3(item_code);
        setRotSearchValue4(item_a_code);
        setRotSearchWarehouse(WarehouseCode);
        setRotSearchInOutFlag("2");
        if (rot_code != "" && shipper_code != "" && item_code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['rot_code'] = rot_code;
            data['shipper_code'] = shipper_code;
            data['item_code'] = item_code;
            data['item_a_code'] = item_a_code;
            data['warehouse_code'] = WarehouseCode;

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstRotEndpoint;

            if (inout == "2") {
                url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstRotStockEndpoint;
            }

            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setRotTableData(response.data.data);
                    setShowModalRot(true);
                })
                .catch((error) => {
                });
        } else {
            setRotTableData([]);
            if (shipper_code != "" && item_code != "") {
                setShowModalRot(true);
            } else {
                setShowModalRot(false);
            }
        }
    }

    const viewRotModalData = (event, id, rot_code, shipper_code, item_code, item_a_code) => {

        setRotSearchValue2(shipper_code);
        setRotSearchValue3(item_code);
        setRotSearchValue4(item_a_code);
        setRotSearchInOutFlag("1");
        let data = {};
        data['userID'] = userData['id'];
        data['shipper_code'] = shipper_code;
        data['item_code'] = item_code;
        data['item_a_code'] = item_a_code;

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstRotStockEndpoint;

        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setRotTableData(response.data.data);
                setShowModalRot(true);
            })
            .catch((error) => {
            });
    }

    const setRotData = (data) => {
        let itemData = [];
        for (var i = 0; i < sMainTableData.length; i++) {
            let row = {};
            row = sMainTableData[i];
            if (i == document.getElementById('selectedId').value) {
                if (data['shipper_code'] == ShipperCode
                    && data['item_code'] == row['item_code'] && data['item_a_code'] == row['item_a_code']) {
                    row['rot_code'] = data['rot_code'];
                    row['rot_name'] = data['rot_name'];
                    if (data['limit_amount']) row['rot_weight'] = Math.round((1 / data['limit_amount']) * 10000) / 10000;
                    else row['rot_weight'] = 0;
                    row['stock_num'] = data['amount'];
                    row['rot_id'] = data['sys_rot_id'];
                } else {
                    row['rot_code'] = "";
                    row['rot_name'] = "";
                    row['rot_weight'] = 0;
                    row['stock_num'] = 0;
                    row['rot_id'] = "";
                }
            }
            itemData[i] = row;
        }
        gridSMainTable(itemData);
    }
    const [showModalDel2, setShowModalDel2] = useState(false);

    const deleteItem = (event) => {
        setShowModalDel2(false);
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (!checkboxes[i].checked) {
                remainIndexs.push(i);
            }
        }

        let remainData = [];
        for (var i = 0; i < sMainTableData.length; i++) {
            if (sMainTableData[i]['sys_item_id'] > 0) {
                let row = {};
                row = sMainTableData[i];
                remainData.push(row);
            } else {
                for (var j = 0; j < remainIndexs.length; j++) {
                    if (i == remainIndexs[j]) {
                        let row = {};
                        row = sMainTableData[i];
                        remainData.push(row);
                    }
                }
            }
        }
        gridSMainTable(remainData);
        $('#tbodyWrap input[type="checkbox"]').prop('checked', false);
    }

    const loadModalDelData2 = () => {
        setShowModalDel2(true);
    }

    const handlePrint = useReactToPrint({
        content: () => QRRef.current,
    });

    const codePrint = () => {

        QRGenerate();
        if (itemInformation.length == 0) {
            toast.error("作業スケジュールを選択してください。", { autoClose: 3000 });
            return;
        }

        handlePrint();
    }

    const dataKeyDown = (e) => {
        if (e.keyCode == 13) {
            document.getElementById('input10').focus();
        }
    }

    const keyDownDriverName = (e) => {
        if (e.keyCode == 13) {
            document.getElementById('input11').focus();
        }
    }

    const keyDownCarNumber = (e) => {
        if (e.keyCode == 13) {
            document.getElementById('input12').focus();
        }
    }

    const keyDownDriverPhone = (e) => {
        if (e.keyCode == 13) {
            document.getElementById('input13').focus();
        }
    }

    const keyDownCarrierName = (e) => {
        if (e.keyCode == 13) {
            document.getElementById('input10').focus();
        }
    }

    const historyDiv = (data) => {
        let historyData = JSON.parse(data);
        return (
            <table style={{ border: 'none' }}>
                <thead>
                    <tr>
                        <th style={{ width: '30%' }}>日付</th>
                        <th style={{ width: '30%' }}>出庫</th>
                        <th style={{ width: '40%' }}>数量</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        historyData.map((val, index) => (
                            <tr>
                                <td style={{ width: '30%' }}>{val['itemMonth']}</td>
                                <td style={{ width: '30%' }}>{val['count']}</td>
                                <td style={{ width: '40%' }}>{val['amount']}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    }

    const [driverName, setDriverName] = useState("")
    const [driverPhone, setDriverPhone] = useState("")
    const [carNumber, setCarNumber] = useState("")
    const [carType, setCarType] = useState("1")

    const handleChangeCarrierName = (e) => {
        setCarrierName(e.target.value)
    }

    const handleChangeDriverName = (e) => {
        setDriverName(e.target.value)
    }

    const handleChangeDriverPhone = (e) => {
        setDriverPhone(e.target.value)
    }

    const handleChangeCarNumber = (e) => {
        setCarNumber(e.target.value)
    }

    const handleChangeCarType = (e) => {
        setCarType(e.target.value)
    }
    //driver data
    const [driverModalShow, setDriverModalShow] = useState(false);
    const [driverTableData, setDriverTableData] = useState([]);
    const [driverSearchValue, setDriverSearchValue] = useState("");
    const [driverSearchValue2, setDriverSearchValue2] = useState("");
    const loadDriverModalData = (event, carNumber) => {
        event.preventDefault();
        setDriverSearchValue(carrierId);
        setDriverSearchValue2(carNumber);
        if (carrierId != "") {
            const data = {
                userID: userData['id'],
                transport: carrierId,
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperCarrierDriverEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setDriverTableData(response.data.data);
                    setDriverModalShow(true);
                })
                .catch((error) => {
                    setDriverTableData([]);
                    if (error.response && error.response.status && error.response.status === 401) {
                        useJwt.shipperLogout();
                        navigate("/login");
                    }
                });
        } else {
            setDriverTableData([]);
            toast.error("運送会社を選択してください。", { autoClose: 3000 });

        }
    }


    const setDriverData = (data) => {
        if (data['id']) {
            setCarNumber(data['car_number'])
            setCarType(data['car_standard'])
            setDriverName(data['driver_name'])
            setDriverPhone(data['phone_number'])
        }
    }
    const onKeyDownCarNumber = (event, index) => {
        if (event.keyCode === 13) { // enter key
            document.getElementById('input5').focus();
            if (event.target.value != "" && carrierId != "") {
                const data_r = {
                    userID: userData['id'],
                    car_number: event.target.value,
                    transport: carrierId
                };
                let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getDriverEndpoint;
                axios
                    .post(url, data_r, {
                        headers: {
                            Authorization: "Bearer " + useJwt.getToken(),
                        },
                    })
                    .then((response1) => {
                        let t_data = response1.data.data;
                        if (t_data.length > 0) {
                            setDriverData(t_data[0]);
                        } else {
                            setDriverData([]);
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.status && error.response.status === 401) {
                            useJwt.shipperLogout();
                            navigate("/login");
                        }
                    });
            } else {
                setDriverData([]);
                toast.error("運送会社を選択してください。", { autoClose: 3000 });
            }
            event.preventDefault();
        }
    };


    //bus data
    const [busModalShow, setBusModalShow] = useState(false);
    const [busTableData, setBusTableData] = useState([]);
    const [busName, setBusName] = useState("");
    const [busStandard, setBusStandard] = useState("");
    const [busId, setBusId] = useState("");
    const [busSearchValue2, setBusSearchValue2] = useState("");
    const loadBusModalData = (event) => {
        event.preventDefault();
        let code = WarehouseId;
        setBusSearchValue2(busName);
        if (code != "") {
            const data = {
                userID: userData['id'],
                warehouse_id: code,
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getBusSettingEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    let result = response.data.data;
                    let tempList = [];
                    for (var i = 0; i < result.length; i++) {
                        let temp = {};
                        temp['id'] = result[i]['id'];
                        temp['warehouse_id'] = result[i]['warehouse_id'];
                        temp['bus_name'] = result[i]['bus_name'];

                        let standardList = JSON.parse(result[i]['standard']);
                        let tempStandard = "";
                        for (var j = 0; j < standardList.length; j++) {
                            if (j != 0) tempStandard += '、'
                            if (standardList[j] == "1") tempStandard += '4t';
                            if (standardList[j] == "2") tempStandard += '10t';
                            if (standardList[j] == "3") tempStandard += 'トレーラー';
                        }
                        temp['standard'] = tempStandard;
                        tempList.push(temp);
                    }

                    setBusTableData(tempList);
                    setBusModalShow(true);
                })
                .catch((error) => {
                    setBusTableData([]);
                });
        } else {
            setBusTableData([]);
            toast.error("倉庫を選択してください。", { autoClose: 3000 });
        }
    }


    const setBusData = (result) => {
        console.log(result);
        if (result['id']) {
            setBusId(result['id']);
            setBusName(result['bus_name']);
            setBusStandard(result['standard']);
        } else {
            setBusId("");
            setBusName("");
            setBusStandard("");
        }
    }

    const handleChangeBusName = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 2) {
            str = str.substring(0, 2);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setBusName(str);
    };

    const onKeyDownBusName = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('input5').focus();
        }
        if (event.keyCode === 13 && WarehouseId != "") { // enter key
            document.getElementById('input5').focus();
            if (event.target.value != "") {
                const data_r = {
                    userID: userData['id'],
                    bus_name: event.target.value,
                    warehouse_id: WarehouseId
                };
                let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getBusSettingEndpoint;
                axios
                    .post(url, data_r, {
                        headers: {
                            Authorization: "Bearer " + useJwt.getToken(),
                        },
                    })
                    .then((response1) => {
                        let t_data = response1.data.data;
                        if (t_data.length > 0) {
                            let result = t_data[0];
                            let temp = {};
                            temp['id'] = result['id'];
                            temp['warehouse_id'] = result['warehouse_id'];
                            temp['bus_name'] = result['bus_name'];

                            let standardList = JSON.parse(result['standard']);
                            let tempStandard = "";
                            for (var j = 0; j < standardList.length; j++) {
                                if (j != 0) tempStandard += '、'
                                if (standardList[j] == "1") tempStandard += '4t';
                                if (standardList[j] == "2") tempStandard += '10t';
                                if (standardList[j] == "3") tempStandard += 'トレーラー';
                            }
                            temp['standard'] = tempStandard;
                            setBusData(temp);
                        } else {
                            setBusData([]);
                        }
                    })
                    .catch((error) => {
                    });
            } else {
                setBusData([]);
            }
            event.preventDefault();
        }
    };

    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row, style.job_row_align];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_1 = [gstyle.cs_col, gstyle.cs_col_1];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_5 = [gstyle.cs_col, gstyle.cs_col_5];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_60px = [gstyle.cs_input, gstyle.w60px];
    const style_input_bl_n = [gstyle.cs_input, style.job_time];

    const style_th_w1p = [style.cs_th, style.w1p];
    const style_th_w2p = [style.cs_th, style.w2p];
    const style_th_w3p = [style.cs_th, style.w3p];
    const style_th_w4p = [style.cs_th, style.w4p];
    const style_th_w5p = [style.cs_th, style.w5p];

    const style_td_w1p = [style.cs_td, style.w1p];
    const style_td_w2p = [style.cs_td, style.w2p];
    const style_td_w2p_flex = [style.cs_td, style.w2p, gstyle.cs_flex];
    const style_td_w3p = [style.cs_td, style.w3p, gstyle.cs_flex];
    const style_td_w4p = [style.cs_td, style.w4p];
    const style_td_w5p = [style.cs_td, style.w5p];

    const style_col_span = [gstyle.cs_col_1, style.job_span];
    const style_check_ml = [style.check_label, style.job_radio_margin];
    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];
    const style_tbl_input_bl_n = [style.cs_tbl_input, gstyle.cs_bl_n];
    const style_input_80_bl_n = [gstyle.cs_input, gstyle.w80, gstyle.cs_bl_n];

    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_select_40 = [gstyle.cs_select, gstyle.w170px];
    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csLoader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WorkerModal show={showModalWorker} onClose={() => setShowModalWorker(false)} width={'70%'}
                SearchValue={WorkerSearchValue} SearchValue2={WarehouseId} onChange={setWorkerSearchValue}
                tableData={WorkerTableData} setTableData={setWorkerTableData}
                target={setWorkerData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={() => setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue}
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ItemModal show={showModalItem} onClose={() => setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue}
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} warehouseCode={ItemSearchWarehouse} inOutFlag={ItemSearchInOutFlag}
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
            <SummaryModal show={showModalSummary} onClose={() => setShowModalSummary(false)} width={'70%'}
                SearchValue={SummarySearchValue} onChange={setSummarySearchValue}
                tableData={SummaryTableData} setTableData={setSummaryTableData}
                target={setSummaryData} />
            <WarehouseModal show={showModalWarehouse} onClose={() => setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue}
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} />
            <FreightageModal show={showModalFreightage} onClose={() => setShowModalFreightage(false)} width={'70%'}
                SearchValue={FreightageSearchValue} onChange={setFreightageSearchValue}
                tableData={FreightageTableData} setTableData={setFreightageTableData}
                target={setFreightageData} />
            <ConsignorModal show={showModalConsignor} onClose={() => setShowModalConsignor(false)} width={'70%'}
                SearchValue={ConsignorSearchValue} onChange={setConsignorSearchValue}
                SearchValue2={ConsignorSearchValue2} onChange2={setConsignorSearchValue2}
                tableData={ConsignorTableData} setTableData={setConsignorTableData}
                target={setConsignorData} />
            <CarrierModal show={showModalCarrier} onClose={() => setShowModalCarrier(false)} width={'70%'}
                SearchValue={CarrierSearchValue} onChange={setCarrierSearchValue}
                SearchValue2={CarrierSearchValue2} onChange2={setCarrierSearchValue2}
                tableData={CarrierTableData} setTableData={setCarrierTableData}
                target={setCarrierData} />
            <DriverModal show={driverModalShow} onClose={() => setDriverModalShow(false)} width={'60%'}
                SearchValue={driverSearchValue} SearchValue2={driverSearchValue2} onChange={setDriverSearchValue2}
                driverTableData={driverTableData} setDriverTableData={setDriverTableData}
                target={setDriverData} />
            <DistinationModal show={showModalDistination} onClose={() => setShowModalDistination(false)} width={'70%'}
                SearchValue={DistinationSearchValue} onChange={setDistinationSearchValue}
                SearchValue2={DistinationSearchValue2} onChange2={setDistinationSearchValue2}
                tableData={DistinationTableData} setTableData={setDistinationTableData}
                target={setDistinationData} />
            <AreaModal show={showModalArea} onClose={() => setShowModalArea(false)} width={'70%'}
                SearchValue={AreaSearchValue} onChange={setAreaSearchValue}
                tableData={AreaTableData} setTableData={setAreaTableData}
                target={setAreaData} />
            <TableModal show={showModalTable} onClose={() => setShowModalTable(false)} width={'70%'}
                SearchValue={tableSearchValue} SearchValue2={tableSearchValue2} onChange={setTableSearchValue}
                tableData={tableData} setTableData={setTableData}
                target={setTableCode} />
            <RotModal show={showModalRot} onClose={() => setShowModalRot(false)} width={'70%'}
                SearchValue={RotSearchValue} onChange={setRotSearchValue}
                SearchValue2={RotSearchValue2} SearchValue3={RotSearchValue3} SearchValue4={RotSearchValue4}
                tableData={RotTableData} setTableData={setRotTableData} warehouseCode={RotSearchWarehouse} inOutFlag={RotSearchInOutFlag}
                target={setRotData} />
            <Modal2 title="入出庫入力削除" show={showModalDel2} onClose={() => setShowModalDel2(false)} width={'40%'}
                onConfirm={deleteItem}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <BusModal show={busModalShow} onClose={() => setBusModalShow(false)} width={'40%'}
                SearchValue={WarehouseId} SearchValue2={busSearchValue2} onChange={setBusSearchValue2}
                busTableData={busTableData} setBusTableData={setBusTableData}
                target={setBusData} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>荷主からの入出庫指示</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1} style={{ paddingTop: 10 }}>
                    {/* 入出 */}
                    <div className={style_row.join(" ")} style={{ justifyContent: 'center' }}>
                        <div className={style_input_wrap.join(" ")} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ fontSize: 15, padding: 10 }}>年月日</span>
                            <div className={style_date_wrap.join(" ")}>
                                <InputDatepicker value={commandDate} onChange={handleChangeCommandDate} id={"schedule_date"} focusId="shipper_input" />
                            </div>
                        </div>
                        <Button type={"primary"} style={{ width: 100, marginLeft: 30 }} onClick={commandView}>検索</Button>
                    </div>
                    <div className={style.tbl_wrap} style={{ display: 'flex' }}>
                        <div className={style_item_tbl.join(" ")} style={{ margin: 0, marginBottom: 10 }}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    {/* <div className={style_th_col_ck.join(" ")} style={{ width: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} style={{ marginLeft: -1 }} />
                                    </div> */}
                                    <div className={style_th_w2p.join(" ")} style={{ width: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>区分</div>
                                    <div className={style_th_w2p.join(" ")} style={{ width: '11%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>荷主名</div>
                                    <div className={style_th_w2p.join(" ")} style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>品番</div>
                                    <div className={style_th_w1p.join(" ")} style={{ width: '8%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>荷主品番</div>
                                    <div className={style_th_w2p.join(" ")} style={{ width: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>品名</div>
                                    <div className={style_th_w3p.join(" ")} style={{ width: '6%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>ロットNo</div>
                                    <div className={style_th_w5p.join(" ")} style={{ width: '6%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>ロット名</div>
                                    <div className={style_th_w5p.join(" ")} style={{ width: '8%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>入出庫時間</div>
                                    <div className={style_th_w5p.join(" ")} style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>運送会社名</div>
                                    <div className={style_th_w5p.join(" ")} style={{ width: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>車種</div>
                                    <div className={style_th_w5p.join(" ")} style={{ width: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>出庫履歴</div>
                                    <div className={style_th_w5p.join(" ")} style={{ width: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>在庫</div>
                                    <div className={style_th_w4p.join(" ")} style={{ width: '3%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>単位</div>
                                    <div className={style_th_w4p.join(" ")} style={{ width: '3%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>数量</div>
                                    <div className={style_th_w4p.join(" ")} style={{ width: '4%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrapCommand" >
                                {mainTableData.map((data, index) => (
                                    <div className={style.cs_tr} key={index} >
                                        {/* <div className={style_td_col_ck.join(" ")} style={{ width: '5%', display: 'flex', alignItems: 'center' }}>
                                            {
                                                data['item_code'] ?
                                                    <input type={"checkbox"} className={style.cs_tbl_check}
                                                        id={'chk_' + index} onChange={handleChangeChk} />
                                                    :
                                                    <input type={"checkbox"} className={style.cs_tbl_check}
                                                        id={'chk_' + index} onChange={handleChangeChk} disabled />
                                            }
                                        </div> */}
                                        <div className={style_td_w1p.join(" ")} style={{ width: '2%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                            {data['inout_type'] == "1" ? '入庫' : '出庫'}
                                            <input type={'hidden'} className={style.cs_tbl_input}
                                                value={data['inout_type'] ? data['inout_type'] : ''} />
                                        </div>
                                        <div className={style_td_w1p.join(" ")} style={{ width: '11%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                            {data['shipper_name']}
                                            <input type={'hidden'} className={style.cs_tbl_input}
                                                value={data['inout_type'] ? data['inout_type'] : ''} />
                                        </div>
                                        <div className={style_td_w2p_flex.join(" ")} style={{ width: '10%', justifyContent: 'center' }}>
                                            {
                                                data['item_code'] ?
                                                    data['item_code'] + ' ' + data['item_a_code']
                                                    :
                                                    <Button type={"default"} style={{ width: 100 }} onClick={(e) => itemRegistry(e, data['item_id'])}>商品登録</Button>
                                            }
                                            <input type={'hidden'} className={style.cs_tbl_input}
                                                value={data['item_code'] ? data['item_code'] : ''} />
                                            <input type={'hidden'} value={data['item_a_code'] ? data['item_a_code'] : ''} />
                                        </div>
                                        <div className={style_td_w1p.join(" ")} style={{ width: '8%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                            {data['shipper_item_code']}
                                            <input type={'hidden'} className={style.cs_tbl_input}
                                                value={data['shipper_item_code'] ? data['shipper_item_code'] : ''} />
                                        </div>
                                        <div className={style_td_w2p.join(" ")} style={{ width: '15%', display: 'flex', alignItems: 'center', textAlign: 'left', lineBreak: 'anywhere' }}>
                                            {data['item_name'] ? data['item_name'] : ''}
                                        </div>
                                        <div className={style_td_w3p.join(" ")} style={{ width: '6%', justifyContent: 'center' }}>
                                            {data['rot_code']}
                                            <input type={'hidden'} className="rot_code" value={data['rot_code'] ? data['rot_code'] : ''} />
                                        </div>
                                        <div className={style_td_w5p.join(" ")} style={{ display: 'flex', width: '6%', justifyContent: 'left', alignItems: 'center', lineBreak: 'anywhere' }}>
                                            {data['rot_name'] ? data['rot_name'] : ''}
                                        </div>
                                        <div className={style_td_w5p.join(" ")} style={{ display: 'flex', width: '8%', justifyContent: 'left', alignItems: 'center', lineBreak: 'anywhere' }}>
                                            {data['start_date'] ? data['start_date'] : ''}
                                        </div>
                                        <div className={style_td_w5p.join(" ")} style={{ display: 'flex', width: '10%', justifyContent: 'left', alignItems: 'center', lineBreak: 'anywhere' }}>
                                            {data['carrier_name'] ? data['carrier_name'] : ''}
                                        </div>
                                        <div className={style_td_w5p.join(" ")} style={{ display: 'flex', width: '5%', justifyContent: 'left', alignItems: 'center', lineBreak: 'anywhere' }}>
                                            {data['car_type'] == "1" ? '4t' : data['car_type'] == "2" ? '10t' : 'トレーラー'}
                                        </div>
                                        <div className={style_td_w5p.join(" ")} style={{ display: 'flex', width: '2%', justifyContent: 'center', alignItems: 'center', lineBreak: 'anywhere' }}>
                                            {
                                                data['history'] &&
                                                <div className="item-history">
                                                    <Popover placement="bottom" content={historyDiv(data['history'])} trigger="click">
                                                        <Button type="default" shape="circle" size="small" icon={<SearchOutlined />} />
                                                    </Popover>
                                                </div>
                                            }
                                        </div>
                                        <div className={style_td_w5p.join(" ")} style={{ display: 'flex', width: '2%', justifyContent: 'center', alignItems: 'center', lineBreak: 'anywhere' }}>
                                            <Button type="default" shape="circle" size="small" icon={<SearchOutlined />}
                                                onClick={(e) => viewRotModalData(e, index, data['rot_code'] ? data['rot_code'] : ''
                                                    , data['shipper_code'] ? data['shipper_code'] : ShipperCode
                                                    , data['item_code'] ? data['item_code'] : ''
                                                    , data['item_a_code'] ? data['item_a_code'] : '')} />
                                        </div>
                                        <div className={style_td_w5p.join(" ")} style={{ display: 'flex', width: '3%', justifyContent: 'center', alignItems: 'center', lineBreak: 'anywhere' }}>
                                            {data['item_unit'] ? data['item_unit'] : ''}
                                        </div>
                                        <div className={style_td_w4p.join(" ")} style={{ width: '3%', justifyContent: 'end', display: 'flex', alignItems: 'center' }}>
                                            {data['item_num']}
                                            <input type={'hidden'} className="item_num" value={data['item_num'] ? data['item_num'] : ''} />
                                        </div>
                                        <div className={style_td_w1p.join(" ")} style={{ width: '4%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                            {
                                                data['item_code'] &&
                                                <Button type="primary" shape="circle" size="small" icon={<ScheduleFilled />} onClick={(e) => scheduleAddByCommand(e, data)} />
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={gstyle.border}>
                    <p className={gstyle.text}>作業スケジュール</p>
                </div>
                {/* 作業者 */}
                <div className={gstyle.cs_card_1} style={{ paddingTop: 10, marginTop: 20 }}>
                    <div className={style_row.join(" ")} style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                        <div className={style_col_span.join(" ")} style={{ maxWidth: 30, padding: 20 }}>
                            <span className={style.cs_span}>荷主</span>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <div className={gstyle.cs_flex}>
                                    <input type={'number'} className={style_input_100px.join(" ")} style={{ margin: 0 }}
                                        onKeyDown={(e) => onKeyDownShipperCode(e, 1, 1)} id="shipper_input"
                                        value={ShipperCode} onChange={handleChangeShipperCode} />
                                    <input type={'number'} className={style_input_100px_bl_n.join(" ")} style={{ margin: 0 }}
                                        onKeyDown={(e) => onKeyDownShipperCode(e, 1, 2)} id="shipper_a_input"
                                        value={ShipperACode} onChange={handleChangeShipperACode} />
                                    <input type={'text'} className={style_input_bl_n.join(" ")} disabled style={{ margin: 0 }}
                                        value={ShipperName} />
                                    <div className={gstyle.icon_search_wrap} onClick={(e) => loadShipperModalData(e, 1)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style_col_1.join(" ")}>
                            <Radio.Group value={inout} onChange={handleClickInout} style={{ display: "flex", justifyContent: "start" }} buttonStyle="solid">
                                <Radio.Button value="1">入庫</Radio.Button>
                                <Radio.Button value="2">出庫</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_60px.join(" ")}
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e)} id="warehouse_input"
                                            value={WarehouseCode} onChange={handleChangeWarehouseCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadWarehouseModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className={style_col_2.join(" ")} style={{ marginTop: -2 }}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>作業者
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_bl_n.join(" ")} style={{ margin: 0 }}
                                            onKeyDown={(e) => onKeyDownWorkerName(e, 1, 1)} id="worker_input"
                                            value={WorkerName} onChange={handleChangeWorkerName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadWorkerModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")} style={{ marginLeft: 30 }}>
                            <label className={style_label_wrap.join(" ")}>開始予定時間</label>
                            <div style={{ display: 'flex' }}>
                                <InputDatepicker value={StartDate} onChange={handleChangeStartDate} width={105} />
                                <input type="time" id="start_time_input" className={style_input_bl_n.join(" ")} value={StartTime}
                                    onChange={(e) => handleChangeStartTime(e)} style={{ minWidth: 110, margin: 0, textAlign: 'center' }}
                                    onKeyDown={(e) => onKeyDownStartTime(e)}
                                />
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")} style={{ marginLeft: 60 }}>
                            <label className={style_label_wrap.join(" ")}>終了予定時間</label>
                            <div style={{ display: 'flex' }}>
                                <InputDatepicker value={EndDate} onChange={handleChangeEndDate} width={105} />
                                <input type="time" id="end_time_input" className={style_input_bl_n.join(" ")} value={EndTime}
                                    onChange={(e) => handleChangeEndTime(e)} style={{ minWidth: 110, margin: 0, textAlign: 'center' }}
                                    onKeyDown={(e) => onKeyDownEndTime(e)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* 摘要 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>摘要コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownSummaryCode(e)} id="summary_input"
                                            value={SummaryCode} onChange={handleChangeSummaryCode} />
                                        <input type={'text'} className={style_input_80_bl_n.join(" ")}
                                            value={SummaryName} disabled />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadSummaryModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")} style={{ display: 'flex', justifyContent: 'end' }}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <div className={style_date_wrap.join(" ")} style={{ margin: 0, marginTop: 20 }}>
                                        <label className={style.check_label}>
                                            <input type={'radio'} name='code' checked={codeType == 1 ? true : false} value={In} onChange={(e) => onChangeCodeType(e, 1)} />
                                            <div>QRコード</div>
                                        </label>

                                        <label className={style_check_ml.join(" ")}>
                                            <input type={'radio'} name='code' id='out' onChange={(e) => onChangeCodeType(e, 2)} checked={codeType == 2 ? true : false} />
                                            <div>バーコード</div>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_5.join(" ")} style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
                            <Button type={"primary"} style={{ width: 100 }} onClick={codePrint}>コード発行</Button>
                            <Button type={"primary"} style={{ marginLeft: 50, width: 100 }} onClick={scheduleAdd} id="add_button">追加</Button>
                            <Button type={"primary"} style={{ marginLeft: 10, width: 100 }} onClick={scheduleDel} danger>削除</Button>
                        </div>
                    </div>
                    <div className={style.tbl_wrap} id="scheduleTable" style={{ marginTop: 20 }}>
                        <div className={style_item_tbl.join(" ")} style={{ margin: 0 }}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allScheduleCheckBtn"
                                            onChange={allScheduleCheckData} />
                                    </div>
                                    <div className={style_th_w2p.join(" ")} style={{ width: '18%' }}>品番</div>
                                    <div className={style_th_w1p.join(" ")} style={{ width: '8%' }}>荷主品番</div>
                                    <div className={style_th_w2p.join(" ")} style={{ width: '17%' }}>品名</div>
                                    <div className={style_th_w5p.join(" ")} style={{ width: '8%' }}>ロットNo</div>
                                    <div className={style_th_w5p.join(" ")} style={{ width: '7%' }}>ロット名</div>
                                    <div className={style_th_w4p.join(" ")} style={{ width: '7%' }}>棚コード</div>
                                    <div className={style_th_w4p.join(" ")} style={{ width: '4%' }}>単位</div>
                                    <div className={style_th_w4p.join(" ")} style={{ width: '5%' }}>ケース数</div>
                                    <div className={style_th_w4p.join(" ")} style={{ width: '5%' }}>バラ数</div>
                                    <div className={style_th_w4p.join(" ")} style={{ width: '5%' }}>数量</div>
                                    <div className={style_th_w4p.join(" ")} style={{ width: '8%' }}>入庫日</div>
                                    <div className={style_th_w4p.join(" ")} style={{ width: '8%' }}>備考</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {sMainTableData?.map((data, index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                                id={'chk_' + index} onChange={handleScheduleChangeChk} />
                                        </div>
                                        <div className={style_td_w2p_flex.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '18%' }}>
                                            {
                                                emptyData == "" ?
                                                    <>
                                                        <input type={'text'} className={style.cs_tbl_input}
                                                            value={data['item_code'] ? data['item_code'] : ''} id={"table_shipper_input" + index}
                                                            onChange={(e) => handleChangeItemData(e, 1, 'item_code', index)}
                                                            onKeyDown={(e) => tableKeyDown(e, "table_shipper_a_input" + index)} />
                                                        <input type={'number'} className={style_tbl_input_bl_n.join(" ")} style={{ width: 50 }}
                                                            value={data['item_a_code'] ? data['item_a_code'] : ''} id={"table_shipper_a_input" + index}
                                                            onChange={(e) => handleChangeItemData(e, 1, 'item_a_code', index)}
                                                            onKeyDown={(e) => tableKeyDown(e, 'shipper_item_input' + index)}
                                                        />
                                                        <div className={gstyle.icon_search_wrap}
                                                            onClick={(e) => loadItemModalData(e, index, data['item_code'] ? data['item_code'] : '', data['item_a_code'] ? data['item_a_code'] : '')}>
                                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                        </div>
                                                    </>
                                                    :
                                                    data['item_code'] + " " + data['item_a_code']
                                            }
                                        </div>
                                        <div className={style_td_w1p.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '8%' }}>
                                            {
                                                emptyData == "" ?
                                                    <input type={'text'} className={style.cs_tbl_input}
                                                        value={data['shipper_item_code'] ? data['shipper_item_code'] : ''} id={"shipper_item_input" + index}
                                                        onChange={(e) => handleChangeItemData(e, 1, 'shipper_item_code', index)} onKeyDown={(e) => tableKeyDown(e, 'rot_code_input' + index)} />
                                                    :
                                                    data['shipper_item_code'] ? data['shipper_item_code'] : ''
                                            }
                                        </div>
                                        <div className={style_td_w2p.join(" ")} style={{ display: 'flex', justifyContent: 'left', lineBreak: 'anywhere', alignItems: 'center', width: '17%' }}>
                                            {data['item_name'] ? data['item_name'] : ''}
                                        </div>
                                        <div className={style_td_w5p.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '8%' }}>
                                            {
                                                inout != "2" ?
                                                    <input type={'text'} className="rot_code"
                                                        style={{
                                                            border: 'solid 1px #D6D6D6', width: 'calc(100% - 15px)', margin: 0, height: 30,
                                                            padding: '0px 5px', color: '#565656', fontSize: 13, outline: 0, textAlign: 'end'
                                                        }}
                                                        value={data['rot_code'] ? data['rot_code'] : ''} id={"rot_code_input" + index}
                                                        onChange={(e) => handleChangeItemData(e, 1, 'rot_code', index)} onKeyDown={(e) => tableKeyDown(e, 'rot_name_input' + index)} />
                                                    :
                                                    <>
                                                        <input type={'text'} className="rot_code"
                                                            style={{
                                                                border: 'solid 1px #D6D6D6', width: 'calc(100% - 15px)', margin: 0, height: 30,
                                                                padding: '0px 5px', color: '#565656', fontSize: 13, outline: 0, textAlign: 'end'
                                                            }}
                                                            value={data['rot_code'] ? data['rot_code'] : ''} id={"rot_code_input" + index}
                                                            onChange={(e) => handleChangeItemData(e, 1, 'rot_code', index)} onKeyDown={(e) => tableKeyDown(e, 'table_code_input' + index)} disabled />
                                                        <div className={gstyle.icon_search_wrap}
                                                            onClick={(e) => loadRotModalData(e, index, data['rot_code'] ? data['rot_code'] : ''
                                                                , data['shipper_code'] ? data['shipper_code'] : ShipperCode
                                                                , data['item_code'] ? data['item_code'] : ''
                                                                , data['item_a_code'] ? data['item_a_code'] : '')}>
                                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                        </div>
                                                    </>
                                            }
                                            <input type={'hidden'} className="rot_weight" value={data['rot_weight'] ? data['rot_weight'] : 0} />
                                            <input type={'hidden'} className="rot_id" value={data['rot_id'] ? data['rot_id'] : ''} />
                                            <input type={'hidden'} className="stock_num" value={data['stock_num'] ? data['stock_num'] : 0} />
                                        </div>
                                        <div className={style_td_w5p.join(" ")} style={{ display: 'flex', justifyContent: 'left', lineBreak: 'anywhere', alignItems: 'center', width: '7%' }}>
                                            {
                                                inout != "2" ?
                                                    <input type={'text'} className="rot_name"
                                                        style={{
                                                            border: 'solid 1px #D6D6D6', width: 'calc(100% - 15px)', margin: 0, height: 30,
                                                            padding: '0px 5px', color: '#565656', fontSize: 13, outline: 0, textAlign: 'end'
                                                        }}
                                                        value={data['rot_name'] ? data['rot_name'] : ''} id={"rot_name_input" + index}
                                                        onChange={(e) => handleChangeItemData(e, 1, 'rot_name', index)} onKeyDown={(e) => tableKeyDown(e, 'table_code_input' + index)} />
                                                    :
                                                    data['rot_name']
                                            }
                                        </div>
                                        <div className={style_td_w4p.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '7%' }}>
                                            <input type={'num'} className={style.cs_tbl_input}
                                                value={data['table_code'] ? data['table_code'] : ''} id={"table_code_input" + index}
                                                onChange={(e) => handleChangeItemData(e, 1, 'table_code', index)} onKeyDown={(e) => tableKeyDown(e, 'case_num_input' + index)} />
                                            <div className={gstyle.icon_search_wrap}
                                                onClick={(e) => loadTableModalData(e, index, data['table_code'] ? data['table_code'] : '')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            <input type={'hidden'} className="table_weight" value={data['weight'] ? data['weight'] : 0} />
                                        </div>
                                        <div className={style_td_w4p.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '4%' }}>
                                            {data['item_unit'] ? data['item_unit'] : ''}
                                        </div>
                                        <div className={style_td_w4p.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5%' }}>
                                            <input type={'text'} className={style.cs_tbl_input} style={{ textAlign: 'end' }}
                                                value={data['case_num'] ? data['case_num'] : ''} id={"case_num_input" + index}
                                                onChange={(e) => handleChangeItemData(e, 1, 'case_num', index)} onKeyDown={(e) => tableKeyDown(e, 'bara_num_input' + index)} />
                                        </div>
                                        <div className={style_td_w4p.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5%' }}>
                                            <input type={'text'} className={style.cs_tbl_input} style={{ textAlign: 'end' }}
                                                value={data['bara_num'] ? data['bara_num'] : ''} id={"bara_num_input" + index}
                                                onChange={(e) => handleChangeItemData(e, 1, 'bara_num', index)} onKeyDown={(e) => tableKeyDown(e, 'item_num_input' + index)} />
                                        </div>
                                        <div className={style_td_w4p.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5%' }}>
                                            {
                                                data['stock_num'] > 0
                                                    ?
                                                    <Popover content={useJwt.numberWithCommas(data['stock_num'])} title="現在残" trigger="focus" style={{
                                                        width:
                                                            50
                                                    }}>
                                                        <input type={'text'} className="item_num" id={"item_num_input" + index}
                                                            style={{
                                                                border: 'solid 1px #D6D6D6', width: 'calc(100% - 15px)', margin: 0, height: 30,
                                                                padding: '0px 5px', color: '#565656', fontSize: 13, outline: 0, textAlign: 'end'
                                                            }}
                                                            value={data['item_num'] ? data['item_num'] : ''}
                                                            onChange={(e) => handleChangeItemData(e, 1, 'item_num', index)}
                                                            onKeyDown={(e) => tableKeyDown(e, 'date_input' + index)} />
                                                    </Popover>
                                                    :
                                                    <input type={'text'} className="item_num" id={"item_num_input" + index}
                                                        style={{
                                                            border: 'solid 1px #D6D6D6', width: 'calc(100% - 15px)', margin: 0, height: 30,
                                                            padding: '0px 5px', color: '#565656', fontSize: 13, outline: 0, textAlign: 'end'
                                                        }}
                                                        value={data['item_num'] ? data['item_num'] : ''}
                                                        onChange={(e) => handleChangeItemData(e, 1, 'item_num', index)}
                                                        onKeyDown={(e) => tableKeyDown(e, 'date_input' + index)} />
                                            }
                                        </div>
                                        <div className={style_td_w4p.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '8%' }}>
                                            <InputDatepickerTable value={data['item_deadline_date'] ? data['item_deadline_date'] : ''}
                                                onChange={(e) => handleChangeItemData(e, 2, 'item_deadline_date', index)} id={"date_input" + index}
                                                onKeyDown={(e) => tableKeyDown(e, 'remark_input' + index)} focusId={"remark_input" + index}
                                            />
                                        </div>
                                        <div className={style_td_w4p.join(" ")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '8%' }}>
                                            <input type={'text'} className={style.cs_tbl_input} id={"remark_input" + index}
                                                value={data['remark'] ? data['remark'] : ''}
                                                onChange={(e) => handleChangeItemData(e, 1, 'remark', index)}
                                                onKeyDown={(e) => tableEndKeyDown(e, 'table_shipper_input' + (index + 1), index)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* 諸掛1 ~ 諸掛3 */}
                    {
                        inout == "2" &&
                        <>
                            <div className={style_row.join(" ")} style={{ marginTop: 30 }}>
                                <div className={style_col_4.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>諸掛①</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'number'} className={style_input_100px.join(" ")} id="freightageCode1"
                                                    value={freightageCode1}
                                                    onKeyDown={(e) => onKeyDownFreightageCode(e, 1)}
                                                    onChange={(e) => handleChangeFreightageCode(e, 1)} />
                                                <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="freightageName1"
                                                    value={freightageName1} />
                                                <div className={gstyle.icon_search_wrap} onClick={(e) => loadFreightageModalData(e, 1)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_4.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>諸掛②</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'number'} className={style_input_100px.join(" ")} id="freightageCode2"
                                                    value={freightageCode2}
                                                    onKeyDown={(e) => onKeyDownFreightageCode(e, 2)}
                                                    onChange={(e) => handleChangeFreightageCode(e, 2)} />
                                                <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="freightageName2"
                                                    value={freightageName2} />
                                                <div className={gstyle.icon_search_wrap} onClick={(e) => loadFreightageModalData(e, 2)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_4.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>諸掛③</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'number'} className={style_input_100px.join(" ")} id="freightageCode3"
                                                    value={freightageCode3}
                                                    onKeyDown={(e) => onKeyDownFreightageCode(e, 3)}
                                                    onChange={(e) => handleChangeFreightageCode(e, 3)} />
                                                <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="freightageName3"
                                                    value={freightageName3} />
                                                <div className={gstyle.icon_search_wrap} onClick={(e) => loadFreightageModalData(e, 3)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={style_row.join(" ")}>
                                <div className={style_col_6.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>荷送人</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'number'} className={style_input_100px.join(" ")}
                                                    value={ConsignorCode}
                                                    id='input1'
                                                    onKeyDown={(e) => onKeyDownConsignorCode(e, 1)}
                                                    onChange={(e) => handleChangeConsignorCode(e)} />
                                                <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                                    value={ConsignorACode} id='input2'
                                                    onKeyDown={(e) => onKeyDownConsignorCode(e, 2)}
                                                    onChange={(e) => handleChangeConsignorACode(e)} />
                                                <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                    value={ConsignorName} />
                                                <div className={gstyle.icon_search_wrap} onClick={(e) => loadConsignorModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_6.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>地区</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'number'} className={style_input_100px.join(" ")}
                                                    value={AreaCode} id='input8'
                                                    onKeyDown={(e) => onKeyDownAreaCode(e)}
                                                    onChange={(e) => handleChangeAreaCode(e)} />
                                                <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                    value={AreaName} />
                                                <div className={gstyle.icon_search_wrap} onClick={(e) => loadAreaModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={style_row.join(" ")}>
                                <div className={style_col_6.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>届け先</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'number'} className={style_input_100px.join(" ")}
                                                    value={DistinationCode} id='input6'
                                                    onKeyDown={(e) => onKeyDownDistinationCode(e, 1)}
                                                    onChange={(e) => handleChangeDistinationCode(e)} />
                                                <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                                    value={DistinationACode} id='input7'
                                                    onKeyDown={(e) => onKeyDownDistinationCode(e, 2)}
                                                    onChange={(e) => handleChangeDistinationACode(e)} />
                                                <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                    value={DistinationName} />
                                                <div className={gstyle.icon_search_wrap} onClick={(e) => loadDistinationModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_3.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>TEL</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'text'} className={style_input_80.join(" ")} disabled
                                                    value={DistinationTel} />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={style_row.join(" ")}>
                                <div className={style_col_4.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>住所（都道府県）</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'text'} className={style_input_80.join(" ")} disabled
                                                    value={DistinationAddress1} />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_4.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>市区町村</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'text'} className={style_input_80.join(" ")} disabled
                                                    value={DistinationAddress2} />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_4.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>番地</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'text'} className={style_input_80.join(" ")} disabled
                                                    value={DistinationAddress3} />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={style_row.join(" ")}>
                                <div className={style_col_4.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>運送業者</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'number'} className={style_input_100px.join(" ")}
                                                    value={CarrierCode} id='input3'
                                                    onKeyDown={(e) => onKeyDownCarrierCode(e, 1)}
                                                    onChange={(e) => handleChangeCarrierCode(e)} />
                                                <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                                    value={CarrierACode} id='input4'
                                                    onKeyDown={(e) => onKeyDownCarrierCode(e, 2)}
                                                    onChange={(e) => handleChangeCarrierACode(e)} />
                                                <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                    value={CarrierName} />
                                                <div className={gstyle.icon_search_wrap} onClick={(e) => loadCarrierModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>

                                <div className={style_col_3.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>ドライバー検索</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'text'} className={style_input_100px.join(" ")} style={{ width: '80%' }}
                                                    value={carNumber} id='input9'
                                                    onKeyDown={(e) => onKeyDownCarNumber(e)}
                                                    onChange={(e) => handleChangeCarNumber(e)} />
                                                <div className={gstyle.icon_search_wrap} onClick={(e) => loadDriverModalData(e, carNumber)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_3.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>運送便</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'number'} className={style_input_80.join(" ")}
                                                    id='input5' onKeyDown={(e) => dataKeyDown(e)}
                                                    value={CarrierStoolCode} onChange={handleChangeCarrierStoolCode} />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        inout != "" &&
                        <>
                            <div className={style_row.join(" ")} style={{ marginTop: 30 }}>
                                {inout == "1" &&
                                    <div className={style_col_2.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <span className={gstyle.cs_span}>運送会社名</span>
                                                <div className={gstyle.cs_flex}>
                                                    <input type={'text'} className={style_input_100px.join(" ")} style={{ width: '100%' }}
                                                        value={CarrierName} onKeyDown={(e) => keyDownCarrierName(e)}
                                                        onChange={(e) => handleChangeCarrierName(e)} />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                }
                                <div className={style_col_2.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>ドライバー名</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'text'} className={style_input_100px.join(" ")} style={{ width: '100%' }}
                                                    value={driverName} id='input10' onKeyDown={(e) => keyDownDriverName(e)}
                                                    onChange={(e) => handleChangeDriverName(e)} />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_2.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>車番</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'text'} className={style_input_100px.join(" ")} style={{ width: '100%' }} id="input11"
                                                    value={carNumber} onKeyDown={(e) => keyDownCarNumber(e)}
                                                    onChange={(e) => handleChangeCarNumber(e)} />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_2.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>携帯</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'text'} className={style_input_100px.join(" ")} style={{ width: '100%' }} id="input12"
                                                    value={driverPhone} onKeyDown={(e) => keyDownDriverPhone(e)}
                                                    onChange={(e) => handleChangeDriverPhone(e)} />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_2.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>車種</span>
                                            <div className={gstyle.cs_flex}>
                                                <select className={style_select_40.join(" ")} id="input13"
                                                    value={carType}
                                                    onChange={handleChangeCarType} style={{ marginLeft: 5, width: '100%' }}>
                                                    <option value="1">4t</option>
                                                    <option value="2">10t</option>
                                                    <option value="3">トレーラー</option>
                                                </select>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={style_row.join(" ")}>
                                <div className={style_col_4.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>バース</span>
                                            <div className={gstyle.cs_flex}>
                                                <input type={'number'} className={style_input_100px.join(" ")}
                                                    onKeyDown={(e) => onKeyDownBusName(e)}
                                                    value={busName} onChange={handleChangeBusName} />
                                                <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                                    value={busStandard} />
                                                <div className={gstyle.icon_search_wrap} onClick={(e) => loadBusModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {/* button 登録 */}
                    <div className={gstyle.btn_update_wrap} style={{ gap: 50 }}>
                        <Button type={"primary"} onClick={handleOpen} style={{ width: 130 }}>登 録</Button>
                        <Button type={"primary"} onClick={(e) => navigate('/')} style={{ width: 130 }} danger>戻 る</Button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>

                        <DialogTitle > <div style={{ fontSize: "1.1rem" }}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={scheduleSave} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
            <div style={{ display: 'none' }}>
                <div ref={QRRef} id="QRDiv">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ marginTop: 30 }}>
                            {itemInformation.map((item, index) => (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {item['name']}
                                    </div>
                                    <div style={{ marginBottom: 20 }} >
                                        {
                                            codeType == "1" ?
                                                <QRCodeCanvas
                                                    id="qrCode"
                                                    value={item['code']}
                                                    size={300}
                                                    bgColor={"#ffffff"}
                                                    level={"H"}
                                                    key={index}
                                                    removeAfterPrint
                                                />
                                                :
                                                <Barcode value={item['code']} format="CODE128" width={720} height={170} key={index} />
                                        }
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default JobRegistory;