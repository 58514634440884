import gstyle from '../../../css/GeneralStyle.module.css';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import * as useJwt from '../../../auth/jwt/useJwt.js';
import style from "./CalendarItem.module.css";
import moment from 'moment';
import React, { useState, useEffect, Component } from 'react';
import axios from "axios";
import { Button, Modal, Row, Col } from 'antd';
import $ from 'jquery';

export default class CalendarItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startPoint: 0,
      width: 0,
      unit: 0,
      startDate: "",
      sys_schedule_id: 0,
      user_id: 0,
      sys_user_id: 0,
      length_flag: 0,
      start_date_time: "",
      end_date_time: "",
      isModalOpen: false,
      active: false,
      x: "",
      w: "",
      cel_data:[],
      item_detail : [],
      sub_item_name : '',
      sub_item_num : 0,
    };
    this.elementRef = React.createRef();

    var isSave = false;
    let bodyElementX = "";

    let originalWidth = 0;

    let oldPageX = 0;

    var startX = 0;

    var startY = 0;

    let move_active = false;

    let originalElementX = 0;

    let originalMouseX = 0;

    let ref = "";

    var toSide = "";


    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.showModal = this.showModal.bind(this);
    this.taskInfo = this.taskInfo.bind(this);
    this.startResize = this.startResize.bind(this);
    this.stopResize = this.stopResize.bind(this);
    this.resizeFrame = this.resizeFrame.bind(this);
    this.dragMoveFrame = this.dragMoveFrame.bind(this);
    this.onDragHandle = this.onDragHandle.bind(this);
    this.onDragEndHandle = this.onDragEndHandle.bind(this);
    this.dragLeaving = this.dragLeaving.bind(this);
  }

  showModal() {
    this.setState({ isModalOpen: true });
  };
  handleOk() {
    this.setState({ isModalOpen: false });
  };
  handleCancel() {
    this.setState({ isModalOpen: false });
  };

  componentDidMount() {
    // let userData = JSON.parse(useJwt.getUserData());

    // if (this.props.itemData.id == this.props.itemData.id) {
    //   this.setState({
    //     width: this.props.itemData.item.width,
    //     startPoint: this.props.itemData.item.start_point,
    //     unit: this.props.itemData.item.unit,
    //     startDate: this.props.itemData.item.start_date,
    //     sys_schedule_id: this.props.itemData.sys_schedule_id,
    //     sys_user_id: this.props.itemData.sys_user_id,
    //     length_flag: this.props.itemData.length_flag,
    //     start_date_time: this.props.itemData.start_date_time,
    //     end_date_time: this.props.itemData.end_date_time,
    //     user_id: userData['id']
    //   })
    // }
    let userData = JSON.parse(useJwt.getUserData());
      let itemList = [];
      let subItemList = {};

      let tempItemNum = 0;
      let tempItemName = "";

      if(this.props.itemData.item_detail){
        let tempList = this.props.itemData.item_detail.split('&&');

        for(let i = 0; i<tempList.length; i++){
          let subElement = tempList[i];
          let subTempList = subElement.split("##");

          let itemTempList = {};
          itemTempList['item_name'] = subTempList[0];
          itemTempList['item_code'] = subTempList[2];
          itemTempList['item_a_code'] = subTempList[3];
          itemTempList['shipper_item_code'] = subTempList[4];
          itemTempList['rot_code'] = subTempList[5];
          itemTempList['warehouse_code'] = subTempList[6];
          itemTempList['table_code'] = subTempList[7];
          itemTempList['case_num'] = subTempList[8];
          itemTempList['bara_num'] = subTempList[9];
          if(tempItemNum < subTempList[10]){
            tempItemNum = subTempList[10];
            tempItemName = subTempList[0];
          }
          itemTempList['item_num'] = subTempList[10];
          itemTempList['item_deadline_date'] = subTempList[11];
          itemTempList['remark'] = subTempList[12];
          itemList.push(itemTempList);
        }
      }

      this.setState({
        width: this.props.itemData.item.width,
        startPoint: this.props.itemData.item.start_point,
        unit: this.props.itemData.item.unit,
        startDate: this.props.itemData.item.start_date,
        sys_schedule_id: this.props.itemData.sys_schedule_id,
        sys_user_id: this.props.itemData.sys_user_id,
        length_flag: this.props.itemData.item.length_flag,
        start_date_time: this.props.itemData.start_date_time,
        end_date_time: this.props.itemData.end_date_time,
        user_id: userData['id'],
        item_detail : itemList,
        sub_item_name : tempItemName,
        sub_item_num : tempItemNum,
        cel_data: {
          sys_schedule_id: this.props.itemData.sys_schedule_id,
          user_id: userData['id'],
          sys_user_id: this.props.itemData.sys_user_id,
          start_date_time: this.props.itemData.start_date_time,
          end_date_time: this.props.itemData.end_date_time,
          work_type: this.props.itemData.work_type,
          item_name: this.props.itemData.item_name,
      }
      })
  }

  componentWillUnmount() {
    this.setState({
      width: 0
    })
  }

  componentWillReceiveProps(nextProps) {
    let userData = JSON.parse(useJwt.getUserData());
      let itemList = [];
      let subItemList = {};

      let tempItemNum = 0;
      let tempItemName = "";

      if(this.props.itemData.item_detail){
        let tempList = this.props.itemData.item_detail.split('&&');

        for(let i = 0; i<tempList.length; i++){
          let subElement = tempList[i];
          let subTempList = subElement.split("##");

          let itemTempList = {};
          itemTempList['item_name'] = subTempList[0];
          itemTempList['item_code'] = subTempList[2];
          itemTempList['item_a_code'] = subTempList[3];
          itemTempList['shipper_item_code'] = subTempList[4];
          itemTempList['rot_code'] = subTempList[5];
          itemTempList['warehouse_code'] = subTempList[6];
          itemTempList['table_code'] = subTempList[7];
          itemTempList['case_num'] = subTempList[8];
          itemTempList['bara_num'] = subTempList[9];
          if(tempItemNum < subTempList[10]){
            tempItemNum = subTempList[10];
            tempItemName = subTempList[0];
          }
          itemTempList['item_num'] = subTempList[10];
          itemTempList['item_deadline_date'] = subTempList[11];
          itemTempList['remark'] = subTempList[12];
          itemList.push(itemTempList);
        }
      }
     this.setState({
        item_detail : itemList,
        sub_item_name : tempItemName,
        sub_item_num : tempItemNum
      })
 
    if (this.props.itemData.item.width !== nextProps.itemData.item.width) {
 
      this.setState({
        width: nextProps.itemData.item.width,
        startPoint: nextProps.itemData.item.start_point,
        unit: nextProps.itemData.item.unit,
        startDate: nextProps.itemData.item.start_date,
        sys_schedule_id: nextProps.itemData.sys_schedule_id,
        sys_user_id: nextProps.itemData.sys_user_id,
        length_flag: nextProps.itemData.item.length_flag,
        start_date_time: nextProps.itemData.start_date_time,
        end_date_time: nextProps.itemData.end_date_time,
        user_id: userData['id'],
        cel_data: {
          sys_schedule_id: nextProps.itemData.sys_schedule_id,
          user_id: userData['id'],
          sys_user_id: nextProps.itemData.sys_user_id,
          start_date_time: nextProps.itemData.start_date_time,
          end_date_time: nextProps.itemData.end_date_time,
          work_type: nextProps.itemData.work_type,
          item_name: nextProps.itemData.item_name,
        }
      })
    }
  }

  onDragHandle(e){
    var data = e.target.getAttribute("data-set");
    var temp = JSON.parse(data);
    temp['page_start_x'] = e.screenX;

    this.oldPageX = e.screenX;

    this.startX = e.screenX;
    this.startY = e.screenY;

    this.move_active = true;

    e.dataTransfer?.setData("text", JSON.stringify(temp));
  }

  onDragEndHandle(e){

    e.preventDefault();

    var currentY = e.screenY;

    if(Math.abs(this.startY - currentY) > 20 )  return;

    let start_ms = Math.round(this.state.startPoint / this.state.unit);
    let length_ms = Math.round(this.state.width / this.state.unit);

    var start_date = "";
    var end_date = "";

    start_date = moment(this.state.startDate, "YYYY-MM-DD HH:mm:ss").add(start_ms, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    end_date = moment(start_date, "YYYY-MM-DD HH:mm:ss").add(length_ms, 'seconds').format('YYYY-MM-DD HH:mm:ss');

    let data = {
      userID: this.state.user_id,
      sys_schedule_id: this.state.sys_schedule_id,
      start_date_time: start_date,
      end_date_time: end_date,
      sys_user_id: this.state.sys_user_id
    };

    if (this.move_active) {

      let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setScheduleRegisterPoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.setState({ start_date_time: start_date });
            this.setState({ end_date_time: end_date });

            let temp_cel_data = this.state.cel_data;
            temp_cel_data['start_date_time'] = start_date;
            temp_cel_data['end_date_time'] = end_date;
            this.setState({cel_data: temp_cel_data});
            this.isSave = false;
          }
        })
        .catch((error) => {
        });
    }

    this.move_active =  false;

    // e.target.style.backgroundColor = "";
  }
  handleMouseDown(e, toSide) {
    e.preventDefault();

    let body = e.target;
    // console.log('native - path', e.target);
    const ref = this.elementRef.current;
    const bodyRef = this.elementRef.current.parentElement;

    let bodyElementX = bodyRef.getBoundingClientRect().left;

    const mminStep = 20;
    let originalWidth = parseFloat(
      getComputedStyle(ref, null).getPropertyValue("width").replace("px", "") //current widget width
    );

    let originalElementX = ref.getBoundingClientRect().left; //widget start x-point

    let originalMouseX = e.pageX; //mouse x-point

  };

  handleMouseOut(e, idx) {
    if (document.getElementById("rItem_" + idx) != null) {
      document.getElementById("rItem_" + idx).style.opacity = 0;
    }

    if (document.getElementById("lItem_" + idx) != null) {
      document.getElementById("lItem_" + idx).style.opacity = 0;
    }
  }

  handleMouseOver(e, idx) {
    if (document.getElementById("rItem_" + idx) != null) {
      document.getElementById("rItem_" + idx).style.opacity = 1;
    }

    if (document.getElementById("lItem_" + idx) != null) {
      document.getElementById("lItem_" + idx).style.opacity = 1;
    }
  }

  startResize(e, side) {
    this.setState({ active: true, x: e.clientX });
    this.toSide = side;
    this.ref = this.elementRef.current;
    const bodyRef = this.elementRef.current.parentElement;

    this.bodyElementX = bodyRef.getBoundingClientRect().left;

    const mminStep = 20;
    this.originalWidth = parseFloat(
      getComputedStyle(this.ref, null).getPropertyValue("width").replace("px", "") //current widget width
    );

    this.originalElementX = this.ref.getBoundingClientRect().left; //widget start x-point

    this.originalMouseX = e.pageX; //mouse x-point

  };

  dragMoveFrame(e){

    if(this.move_active){
      document.body.style.cursor = "move";

      var documentWidth = $('#row_' + this.state.sys_user_id).width();

      var oldX = this.oldPageX;
      var currentX =e.screenX;
  
      var calcX = oldX - currentX;
  
      if(this.state.startPoint - calcX < 0){
        this.setState({ startPoint: 0 })
      }else{
        if(documentWidth < (this.state.startPoint - calcX) + this.state.width){
          this.setState({ startPoint: documentWidth - this.state.width });
          this.oldPageX = e.screenX;
        }else
          this.setState({ startPoint: this.state.startPoint - calcX })
          this.oldPageX = e.screenX;
      }
    }
  }

  dragLeaving(e){
    this.move_active = false;
  }

  resizeFrame(e, toSide) {
    const active = this.state.active;
    const x = this.state.x;
    if (active) {
      if (this.toSide === "right") {
        let newWidth = e.pageX - this.ref.getBoundingClientRect().left; //current mouse point -  rect start 

        if (newWidth >= 20) {
          this.setState({ width: newWidth });
        }
      } else if (this.toSide === "left") {
        let newWidth = this.originalWidth - (e.pageX - this.originalMouseX);

        let start_point = (this.originalElementX - (this.originalMouseX - e.pageX) - this.bodyElementX);

        if (newWidth > 20 && start_point > -1) {
          this.setState({ width: newWidth });
          this.setState({ startPoint: start_point })
        }
      }
    }
  };

  stopResize(e) {
    this.setState({ active: false, x: this.state.x });

    document.body.style.cursor = "auto";

    let start_ms = Math.round(this.state.startPoint / this.state.unit);
    let length_ms = Math.round(this.state.width / this.state.unit);

    var start_date = "";
    var end_date = "";

    if (this.state.length_flag == 3) {
      start_date = this.state.start_date_time;
      end_date = this.state.end_date_time;
    } else if (this.state.length_flag == 2) {
      start_date = this.state.start_date_time;
      end_date = moment(this.state.startDate, "YYYY-MM-DD HH:mm:ss").add(length_ms, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    } else {
      start_date = moment(this.state.startDate, "YYYY-MM-DD HH:mm:ss").add(start_ms, 'seconds').format('YYYY-MM-DD HH:mm:ss');
      end_date = moment(start_date, "YYYY-MM-DD HH:mm:ss").add(length_ms, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    }

    let data = {
      userID: this.state.user_id,
      sys_schedule_id: this.state.sys_schedule_id,
      start_date_time: start_date,
      end_date_time: end_date,
      sys_user_id: this.state.sys_user_id
    };

    if (this.state.active) {

      let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setScheduleRegisterPoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.setState({ start_date_time: start_date });
            this.setState({ end_date_time: end_date });

            let temp_cel_data = this.state.cel_data;
            temp_cel_data['start_date_time'] = start_date;
            temp_cel_data['end_date_time'] = end_date;
            this.setState({cel_data: temp_cel_data});
            this.isSave = false;
          }
        })
        .catch((error) => {
        });
    }


  };

  taskInfo() {
    Modal.info({
      title: '詳細情報',
      centered: true,
      okText: '確認',
      width: 500,
      content: (
        <div>
          <Row gutter={20} style={{justifyContent:'center'}}>
            <Col span={8}>
              荷主名 :
            </Col>
            <Col span={12}>
              {this.props.itemData.shipper_name}
            </Col>
          </Row>
          <Row gutter={20} style={{justifyContent:'center'}}>
            <Col span={8}>
              倉庫名 :
            </Col>
            <Col span={12}>
              {this.props.itemData.warehouse_name}
            </Col>
          </Row>
          <Row gutter={20} style={{justifyContent:'center'}}>
            <Col span={8}>
              開始予定時間 :
            </Col>
            <Col span={12}>
              {this.state.start_date_time}
            </Col>
          </Row>
          <Row gutter={20} style={{justifyContent:'center'}}>
            <Col span={8}>
              終了予定時間 :
            </Col>
            <Col span={12}>
              {this.state.end_date_time}
            </Col>
          </Row>
          <Row gutter={20} style={{justifyContent:'center'}}>
            <Col span={8}>
              電話番号 :
            </Col>
            <Col span={12}>
              {this.props.itemData.user_tel_1}
            </Col>
          </Row>
          <Row gutter={20} style={{justifyContent:'center'}}>
            <Col span={8}>
            実際の作業時間 :
            </Col>
            <Col span={12}>
              {this.props.itemData.start_time}
            </Col>
          </Row>
          <Row gutter={20} style={{justifyContent:'center'}}>
            <Col span={8}>
            実際の終了時間 :
            </Col>
            <Col span={12}>
              {this.props.itemData.end_time}
            </Col>
          </Row>
          <div style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: 15,
            marginLeft: -15,
            paddingBottom: 0
          }}>
          <div 
            style={{
              width: "55%", 
              display: "flex",
              justifyContent: "center", 
              backgroundColor: "gray", 
              padding:3,
              borderTop:'1px solid black',
              borderBottom:'1px solid black',
              borderLeft:'1px solid black',
            }}>
              <div>商品名</div>
          </div>
          <div 
            style={{
              width: "25%", 
              display: "flex",
              justifyContent: "center", 
              backgroundColor: "gray", 
              padding:3,
              borderTop:'1px solid black',
              borderBottom:'1px solid black',
              borderLeft:'1px solid black',
              borderRight:'1px solid black',
            }}>
            <div>ロット番号</div>
          </div>
          <div 
            style={{
              width: "20%", 
              display: "flex",
              justifyContent: "center", 
              backgroundColor: "gray", 
              padding:3,
              borderTop:'1px solid black',
              borderBottom:'1px solid black',
              borderRight:'1px solid black',
            }}>
            <div>数量</div>
          </div>
          </div>
          {
            this.state.item_detail?.map((element, index) => (
              <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginLeft : -15
                  }}
                  key={index}
                >
                  <div 
                  style={{
                    width: "55%", 
                    display: "flex",
                    justifyContent: "center", 
                    padding:3,
                    borderBottom:'1px solid black',
                    borderLeft:'1px solid black',
                  }}>
                    <div>{element['item_name']}</div>
                  </div>
                  <div 
                  style={{
                    width: "25%", 
                    display: "flex",
                    justifyContent: "center", 
                    padding:3,
                    borderLeft:'1px solid black',
                    borderBottom:'1px solid black',
                    borderRight:'1px solid black',
                  }}>
                    <div>{element['rot_code']}</div>
                  </div>
                  <div 
                  style={{
                    width: "20%", 
                    display: "flex",
                    justifyContent: "center", 
                    padding:3,
                    borderBottom:'1px solid black',
                    borderRight:'1px solid black',
                  }}>
                    <div>{element['item_num']}</div>
                  </div>
                </div>
            ))
          }
        </div>
      ),
      onOk() { },
    });
  };
  render() {
    return (
      <div className={style.cs_flex} onMouseMove={this.resizeFrame} onDragLeave={this.dragLeaving} onDragOver={this.dragMoveFrame} onMouseUp={this.stopResize} style={{zIndex:1}}>
        {this.props.timeData?.map((time_data, index) => (
          <div className={style.time_line_item} style={{ width: (time_data - 1) + "px", height: "40px" }} key={"time_" + index}></div>
        ))}
        {
          this.props.itemData['work_type'] == 0 ?
          <>
            <div id={"tItem_" + this.props.itemData['sys_schedule_id'] + this.props.itemData['item']['width']}
              ref={this.elementRef} className={style.element} style={{ width: this.state.width + "px", left: this.state.startPoint + "px" }}
              onMouseOut={(e) => this.handleMouseOut(e, this.props.itemData['sys_schedule_id'])}
              onMouseOver={(e) => this.handleMouseOver(e, this.props.itemData['sys_schedule_id'])}>

              {
                  this.props.itemData.item.fixe_flag === "0" ?
                    <div draggable="true" data-set={JSON.stringify(this.state.cel_data)} 
                      style={{ left: "10px", width: (this.state.width - 20) + "px", position:"absolute", height:"40px", zIndex:"2", cursor: 'move' }}
                      onDragStart={(e) => this.onDragHandle(e)}  onDragEnd={(e)=> this.onDragEndHandle(e)} onClick={this.taskInfo}></div>
                    :
                    <div data-set={JSON.stringify(this.state.cel_data)} 
                      style={{ left: "10px", width: (this.state.width - 20) + "px", position:"absolute", height:"40px", zIndex:"2" }}
                      onClick={this.taskInfo}></div>
              }
              <div className={style.element_out_wrapper}>
                <div className={style.body_text}>
                  {this.props.itemData.shipper_name}<br />{this.state.sub_item_name.substring(0, 10) + '...' + ' ' + this.state.sub_item_num}
                </div>
                
                {
                  this.props.itemData.item.fixe_flag === "0" &&
                  (this.props.itemData.item.length_flag < 2 && this.props.itemData.start_time === null &&
                  <div id={"lItem_" + this.props.itemData['sys_schedule_id']} className={style.editPoint__left_out}
                    onMouseDown={(e) => this.startResize(e, 'left')}></div>)
                }
                {
                  this.props.itemData.item.fixe_flag === "0" &&
                  (this.props.itemData.item.length_flag < 1 && this.props.itemData.start_time === null ?
                    <div id={"rItem_" + this.props.itemData['sys_schedule_id']} className={style.editPoint__right_out}
                      onMouseDown={(e) => this.startResize(e, 'right')}></div>
                    : this.props.itemData.item.length_flag == 2 && this.props.itemData.start_time === null &&
                    <div id={"rItem_" + this.props.itemData['sys_schedule_id']} className={style.editPoint__right_out}
                      onMouseDown={(e) => this.startResize(e, 'right')}></div>)
                }
              </div>
            </div>
            <div id={"rtItem_" + this.props.itemData['sys_schedule_id']}
              className={style.real_element} style={{ width: this.props.itemData['real_item']['width'] + "px", left: this.props.itemData['real_item']['start_point'] + "px" }}
              >
              <div className={style.real_element_out_wrapper}>
              </div>
            </div>
            </>
            : this.props.itemData['work_type'] == 1 &&
            <>
              <div id={"tItem_" + this.props.itemData['sys_schedule_id'] + this.props.itemData['item']['width']}
                ref={this.elementRef} className={style.element} style={{ width: this.state.width + "px", left: this.state.startPoint + "px" }}
                onMouseOut={(e) => this.handleMouseOut(e, this.props.itemData['sys_schedule_id'])}
                onMouseOver={(e) => this.handleMouseOver(e, this.props.itemData['sys_schedule_id'])}>

                {
                  this.props.itemData.item.fixe_flag === "0" ?
                    <div draggable="true" data-set={JSON.stringify(this.state.cel_data)} 
                      style={{ left: "10px", width: (this.state.width - 20) + "px", position:"absolute", height:"40px", zIndex:"2", cursor: 'move' }}
                      onDragStart={(e) => this.onDragHandle(e)}  onDragEnd={(e)=> this.onDragEndHandle(e)} onClick={this.taskInfo}></div>
                    :
                    <div data-set={JSON.stringify(this.state.cel_data)} 
                      style={{ left: "10px", width: (this.state.width - 20) + "px", position:"absolute", height:"40px", zIndex:"2" }}
                      onClick={this.taskInfo}></div>
                }
                <div className={style.element_in_wrapper} >
                  <div className={style.body_text} onClick={this.taskInfo}>
                    {this.props.itemData.shipper_name}<br />{this.state.sub_item_name.substring(0, 10) + '...' + ' ' + this.state.sub_item_num}
                  </div>
                  {
                    this.props.itemData.item.fixe_flag === "0" &&
                    (this.props.itemData.item.length_flag < 2  && this.props.itemData.start_time === null &&
                    <div id={"lItem_" + this.props.itemData['sys_schedule_id']} className={style.editPoint__left_in}
                      onMouseDown={(e) => this.startResize(e, "left")}></div>)
                  }
                  {
                    this.props.itemData.item.fixe_flag === "0" &&
                    (this.props.itemData.item.length_flag < 1 && this.props.itemData.start_time === null ?
                      <div id={"rItem_" + this.props.itemData['sys_schedule_id']} className={style.editPoint__right_in}
                        onMouseDown={(e) => this.startResize(e, "right")}></div>
                      : this.props.itemData.item.length_flag == 2 && this.props.itemData.start_time === null &&
                      <div id={"rItem_" + this.props.itemData['sys_schedule_id']} className={style.editPoint__right_in}
                        onMouseDown={(e) => this.startResize(e, "right")}></div>)
                  }
                </div>
              </div>
              <div id={"rtItem_" + this.props.itemData['sys_schedule_id']}
                className={style.real_element} style={{ width: this.props.itemData['real_item']['width'] + "px", left: this.props.itemData['real_item']['start_point'] + "px" }}
                >
                <div className={style.real_element_in_wrapper}>
                </div>
              </div>
            </>

        }
      </div>
    )
  }
}
