/* eslint-disable eqeqeq */
import style from "./SubscriptionEnterList.module.css";
import gstyle from "../../css/GeneralStyle.module.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as useJwt from "../../auth/jwt/useJwt.js";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import ReactPaginate from "react-paginate";
import "./pagenation.css";
import { Button, Modal, Tooltip, Popconfirm } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Delete,
  Edit,
} from "@material-ui/icons";
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { BorderRight } from "@material-ui/icons";


var currentId = 0;
var currentIndex = 0;
const SubscriptionEnterList = (props) => {
  const authentication = useJwt.isUserLoggedIn();
  const userData = JSON.parse(useJwt.getUserData());
  const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);

  const csLoading = (flag) => {
    if (flag) {
      document.getElementById("csLoader").style.display = "flex";
    } else {
      document.getElementById("csLoader").style.display = "none";
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = (e, idx, index) => {
    currentId = idx;
    currentIndex = index;
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    OpenSearchWindow();
  }, [props]);

  useEffect(() => {
    if (authentication !== true)
      navigate("/login");
    if (!loaded) {
      if (menuPatternData['menu_daily_flag_2'] == 0 && userData['sys_role_id'] != 10000) {
        navigate("/");
      }
    }
  });
  const [tokenDataList, setTokenDataList] = useState([])
  const [shipperEmail, setShipperEmail] = useState("")

  // ================================= New Window ====================================
  const OpenSearchWindow = () => {

    let data = {};
    data["userID"] = userData["id"];
    data["warehouse_id"] = userData["id"];
    gridMainTable([]);
    let url =
      jwtDefaultConfig.backendUrl + jwtDefaultConfig.getSubscriptionList;
    csLoading(true);
    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
        },
      })
      .then((response) => {

        let result = response.data.data.shipper;
        if (result.length > 0) {
          gridMainTable(result);
        } else {
          // toast.error("検索結果はありません。", { autoClose: 3000 });
          gridMainTable([]);
        }
        let tokenResult = response.data.data.shipperToken;
        setTokenDataList(tokenResult)
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
      });
  };
  const [mainTableData, setMainTableData] = useState([]);

  const gridMainTable = (result) => {
    let data = result;
    setMainTableData(data);
    // for pagenation
    setItemOffset(0);
    const endOffset = 0 + itemsPerPage;
    setCurrentItems(data.slice(0, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  };
  // starts pagenation code -----------------------------------------
  const itemsPerPage = 50;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % mainTableData.length;
    setItemOffset(newOffset);
    const endOffset = newOffset + itemsPerPage;
    setCurrentItems(mainTableData.slice(newOffset, endOffset));
  };
  // ends pagenation code ---------------------------------------------
  // ========================== starts code for search ===============================

  const [shipperName, setShipperName] = useState("");
  const [shipperCode, setShipperCode] = useState("");
  const [shipperACode, setShipperACode] = useState("");
  const [subscriptionDate, setSubscriptionDate] = useState("");
  const [subscriptionContent, setSubscriptionContent] = useState("");

  const reportDetail = (e, index) => {
    setModalOpen(true);
    let currentData = currentItems[index];
    setShipperName(currentData['shipper_name'])
    setShipperCode(currentData['shipper_code'])
    setSubscriptionDate(currentData['subscription_date'])
    setSubscriptionContent(currentData['message'])
  };

  const [modalOpen, setModalOpen] = useState(false);

  const subscription = (e, data) => {
    navigate("/contact/shipper", {
      state: {
        data : data,
      },
      replace: false,
    });

    // let data = {};
    // data['userID'] = userData['id'];
    // // data['warehouse_id'] = userData['id'];
    // data['id'] = currentId;
    // data['approval'] = "1";

    // setOpen(false);
    // let url =
    //   jwtDefaultConfig.backendUrl + jwtDefaultConfig.updateSubscriptionList;
    // csLoading(true);
    // axios
    //   .post(url, data, {
    //     headers: {
    //       Authorization: "Bearer " + useJwt.getToken(),
    //     },
    //   })
    //   .then((response) => {
    //     let result = response.data.data;

    //     $('.subscriptionTxt').eq(currentIndex).html("<span>承認完了</span>");
    //     csLoading(false);
    //   })
    //   .catch((error) => {
    //     csLoading(false);
    //   });

  }
  const onEmailChange = (e) => {
    setShipperEmail(e.target.value);
  }
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const sendShipperEmail = () => {
    let data = {};
    data['userID'] = userData['id'];

    if (shipperEmail == "") {
      toast.error("メールアドレスを入力してください。", { autoClose: 3000 })
      return
    }

    if (!validateEmail(shipperEmail)) {
      toast.error("メールアドレス形式が間違っています。", { autoClose: 3000 })
      return
    }
    data['shipper_email'] = shipperEmail;

    let url =
      jwtDefaultConfig.backendUrl + jwtDefaultConfig.setShipperContactEmail;
    csLoading(true);
    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
        },
      })
      .then((response) => {
        let result = response.data;
        if(result == "failed"){
          toast.error("トークンの発行に失敗しました。", { autoClose: 3000 });
          return
        }
        console.log(result)
        setTokenDataList(result)
        toast.success("トークン発行に成功しました。", { autoClose: 3000 });
        setShipperEmail("")
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
        toast.error("トークンの発行に失敗しました。", { autoClose: 3000 });
      });

  }

  const onShipperDelete = (e, id) => {
    let data = {};
    data['userID'] = userData['id'];
    data['id'] = id

    let url =
      jwtDefaultConfig.backendUrl + jwtDefaultConfig.delShipperContactEmail;
    csLoading(true);
    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
        },
      })
      .then((response) => {
        let result = response.data;
        console.log(result)
        setTokenDataList(result)
        toast.success("トークン発行に成功しました。", { autoClose: 3000 });
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
        toast.error("トークンの発行に失敗しました。", { autoClose: 3000 });
      });
  }
  // ========================== style =================================================
  // style array
  const style_row = [gstyle.cs_row];
  const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
  const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
  const style_input_wrap3 = [style.grp_tbl_wrap];

  return (
    <div className={gstyle.display}>
      <div className={gstyle.loader_container} id="csLoader">
        <ClipLoader color={"#fff"} size={60} />
      </div>
      <ToastContainer />
      <input type={"hidden"} id="selectedId" />
      <div className={gstyle.border}>
        <p className={gstyle.text}>荷主申請承認</p>
      </div>
      <div className={gstyle.filed}>
        <div className={gstyle.cs_card_1} style={{ display: 'flex' }}>
          <div className={style.nw_container} style={{ padding: 15 }}>
            <p className={gstyle.text} style={{ marginBottom: 20 }}>荷主申請</p>
            <div className={style_row.join(" ")}>
              <div style={{ width: '20%', fontSize: 17, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <label>Email:</label>
              </div>
              <div style={{ width: '40%' }}>
                <input type="email" onChange={(e) => onEmailChange(e)} style={{ width: '98%', height: 30, padding: "0px 10px" }} required></input>
              </div>
              <div style={{ width: '30%', display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" onClick={sendShipperEmail}>送信</Button>
              </div>
            </div>
            <div className={style_row.join(" ")} style={{ marginTop: 30 }}>
              <table>
                <thead>
                  <tr>
                    <th className={style.custom_table_header} style={{ width: "30%" }}>メールアドレス</th>
                    <th className={style.custom_table_header} style={{ width: "40%" }}>トークン</th>
                    <th className={style.custom_table_header} style={{ width: "20%" }}>日付</th>
                    <th className={style.custom_table_header} style={{ width: "10%" }}></th>
                  </tr>
                </thead>
                <tbody id="tbody_token_wrap">
                  {
                    tokenDataList?.map((data, index) => (
                      <tr key={"token" + index}>
                        <td className={style.custome_table_td}>{data['shipper_email']}</td>
                        <td className={style.custome_table_td}>{data['token']}</td>
                        <td className={style.custome_table_td}>{data['date']}</td>
                        <td className={style.custome_table_td}>
                          <Tooltip placement="right" title={'削除'}>
                            <Popconfirm
                              title="削除"
                              description="データを削除しますか？"
                              okText="確認"
                              cancelText="閉じる"
                              placement="top"
                              onConfirm={(e) => onShipperDelete(e, data['id'])}
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            >
                              <Delete style={{ color: 'red', cursor: 'pointer' }} />
                            </Popconfirm>
                          </Tooltip>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ borderRight: '1px solid gray', marginTop: 50 }}></div>
          <div className={style.nw_container} style={{ padding: 15 }}>
            {/* Table */}
            <p className={gstyle.text} style={{ marginBottom: 20 }}>荷主申請承認</p>
            <div className={style_row.join(" ")}>
              <div className={style_col_12.join(" ")}>
                <div className={style_input_wrap3.join(" ")}>
                  <table>
                    <thead>
                      <tr>
                        <th className={style.custom_table_header} style={{ width: "30%" }}>荷主名</th>
                        <th className={style.custom_table_header} style={{ width: "15%" }}>申請日</th>
                        <th className={style.custom_table_header} style={{ width: "30%" }}>メッセージ</th>
                        <th className={style.custom_table_header} style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody id="tbody_wrap">
                      {currentItems.map((data, index) => (
                        <tr key={index}>
                          <td className={style.custome_table_td} style={{ textAlign: 'left', lineBreak: 'anywhere' }}>{data["shipper_name"]}</td>
                          <td className={style.custome_table_td}>{data["subscription_date"]}</td>
                          <td className={style.custome_table_td} style={{ textAlign: 'left', lineBreak: 'anywhere' }}>
                            {(data["message"] || "").substring(0, 30)}
                          </td>
                          <td className={style.custome_table_td}>
                            <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }} className="subscriptionTxt">
                              <Button
                                type="dashed"
                                onClick={(e) => reportDetail(e, index)} 
                              >
                                詳細
                              </Button>
                              <Button
                                type="primary"
                                onClick={(e) => subscription(e, data)}
                              >
                                認可
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {mainTableData.length > 0 && (
              <div className={style_row.join(" ")}>
                <div className={style_col_12.join(" ")}>
                  <div className={style.nw_title_wrap}>
                    <ReactPaginate
                      previousLabel={"前へ"}
                      nextLabel={"次へ"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        title="荷主情報詳細"
        style={{}}
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        width={900}
        footer={null}
      >
        <div className={gstyle.filed}>
          <div className={style_row.join(" ")} style={{ marginTop: 30 }}>
            <div className={style_col_6.join(" ")} style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 20, }}>
              <div className={style_row.join(" ")} style={{ width: "80%", display: "flex", justifyContent: "end", }}>
                <div style={{ width: "30%", display: "flex", justifyContent: "end", }}>
                  <span style={{ marginRight: 20, fontWeight: 600 }}>
                    荷主名:
                  </span>
                </div>
                <div style={{ width: "70%" }}>{shipperName}</div>
              </div>
              <div className={style_row.join(" ")} style={{ width: "80%", display: "flex", justifyContent: "end", }}>
                <div style={{ width: "30%", display: "flex", justifyContent: "end", }}>
                  <span style={{ marginRight: 20, fontWeight: 600 }}>
                    申請日:
                  </span>
                </div>
                <div style={{ width: "70%" }}>{subscriptionDate}</div>
              </div>
            </div>
            <div className={style_col_6.join(" ")}>
              <p style={{ fontWeight: 600 }}>メッセージ</p>
              <div>{subscriptionContent}</div>
            </div>
          </div>
        </div>
      </Modal>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle > <div style={{ fontSize: "1.1rem" }}>こちらの内容で登録しますか？</div> </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
          <Button onClick={subscription} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
        </DialogActions>
      </Dialog>
    </div>

  );
};

export default SubscriptionEnterList;
