//====================================================================================================
//	在庫移動処理 -> AdobeXD 23ページ
//	作成日:2022/08/25		最終更新日:2022/08/29
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
import InputTableText from '../../../../common/tenjin_tokubuchi/input_table_text/InputTableText';
import style from "./Text.module.css";

const ImportLocationText = (props)=> {
    return (
        <InputTableText text={`${style.text}` } tab_index={props.tab_index} name={"ImportLocationText_" + String(props.name_id)} />
    );
}

export default ImportLocationText;