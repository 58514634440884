/* eslint-disable eqeqeq */
import React, { useState } from "react";
import style from "./RegistryBoth.module.css";
import gstyle from "../../css/GeneralStyle.module.css";
import axios from "axios";
import * as useJwt from "../../auth/jwt/useJwt.js";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import ShipperModal from "../../components/common/modal/ShipperModal";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

const RegistryShipper = () => {
  const [shipperCode, setShipperCode] = useState("");
  const [shipperACode, setShipperACode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameKana, setCompanyNameKana] = useState("");
  const [zipNumber1, setZipNumber1] = useState("");
  const [zipNumber2, setZipNumber2] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [telephone1, setTelephone1] = useState("");
  const [telephone2, setTelephone2] = useState("");
  const [telephone3, setTelephone3] = useState("");
  const [fax1, setFax1] = useState("");
  const [fax2, setFax2] = useState("");
  const [fax3, setFax3] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [isRevealPasswordConfirm, setIsRevealPasswordConfirm] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };

  const togglePasswordConfirm = () => {
    setIsRevealPasswordConfirm((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    // // if (shipperCode == "") {
    // //   toast.error("荷主コード入力してください。", { autoClose: 3000 });
    // //   setOpen(false);
    // //   return;
    // // }
    // if (shipperACode == "") {
    //   toast.error("補助コード入力してください。", { autoClose: 3000 });
    //   setOpen(false);
    //   return;
    // }
    if (companyName == "") {
      toast.error("会社名を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (companyNameKana == "") {
      toast.error("会社名(フリガナ)を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (zipNumber1 == "" || zipNumber2 == "") {
      toast.error("郵便番号を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (address1 == "") {
      toast.error("都道府県を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (address2 == "") {
      toast.error("市区町村を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (address3 == "") {
      toast.error("番地を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (telephone1 == "" || telephone2 == "" || telephone3 == "") {
      toast.error("電話番号力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    // if (fax1 == "" || fax2 == "" || fax3 == "") {
    //   toast.error("FAX番号力してください。", { autoClose: 3000 });
    //   setOpen(false);
    //   return;
    // }
    if (email == "") {
      toast.error("メールアドレスを入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (password == "") {
      toast.error("パスワードを入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (password != passwordConfirm) {
      toast.error("パスワードを再入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const csLoading = (flag) => {
    if (flag) {
      document.getElementById("csLoader").style.display = "flex";
    } else {
      document.getElementById("csLoader").style.display = "none";
    }
  };

  const onCompanyChange = (e) => {
    setCompanyName(e.target.value);
  };

  const onCompanyChangeKana = (e) => {
    setCompanyNameKana(e.target.value);
  };

  const onZipNumber1Change = (e) => {
    let z1 = /^[0-9]*$/;
    let str = e.target.value;
    if (str.length > 4) {
      str = str.substring(0, 4);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setZipNumber1(str);
  };

  const onZipNumber2Change = (e) => {
    let z1 = /^[0-9]*$/;
    let str = e.target.value;
    if (str.length > 4) {
      str = str.substring(0, 4);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setZipNumber2(str);
  };

  const getAddress = (event) => {
    if (zipNumber1 != "" && zipNumber2 != "") {
      let googleApiUrl = "https://maps.googleapis.com/maps/api/geocode/json";
      let postcodeParam =
        "?components=postal_code:" +
        zipNumber1 +
        "-" +
        zipNumber2 +
        "&language=ja";
      let apiKey = "&key=" + useJwt.getGoogleApiKey();
      let url = googleApiUrl + postcodeParam + apiKey;
      csLoading(true);
      axios
        .get(url, "")
        .then((response1) => {
          if (response1.data.status == "OK") {
            let address_components =
              response1.data.results[0]["address_components"];
            let code_len = address_components.length;
            let address1 = "";
            if (address_components[code_len - 2]["long_name"]) {
              address1 = address_components[code_len - 2]["long_name"];
            }
            let address2 = "";
            if (
              address_components[code_len - 3]["long_name"] &&
              address_components[code_len - 3]["types"][0] != "postal_code"
            ) {
              address2 = address_components[code_len - 3]["long_name"];
            }

            let town = "";
            if (code_len > 4) {
              town = address_components[code_len - 4]['long_name'];
            } else {
              town = "工業団地";
            }
            
            setAddress1(address1);
            setAddress2(address2);
          } else {
            // ZERO_RESULTS
            setAddress1("");
            setAddress2("");
          }
          csLoading(false);
        })
        .catch((error) => {
          csLoading(false);
        });
    }
    event.preventDefault();
  };

  const onAddress1Change = (e) => {
    setAddress1(e.target.value);
  };

  const onAddress2Change = (e) => {
    setAddress2(e.target.value);
  };

  const onAddress3Change = (e) => {
    setAddress3(e.target.value);
  };

  const onTelephone1Change = (e) => {
    setTelephone1(e.target.value);
  };

  const onTelephone2Change = (e) => {
    setTelephone2(e.target.value);
  };

  const onTelephone3Change = (e) => {
    setTelephone3(e.target.value);
  };

  const onFax1Change = (e) => {
    setFax1(e.target.value);
  };

  const onFax2Change = (e) => {
    setFax2(e.target.value);
  };

  const onFax3Change = (e) => {
    setFax3(e.target.value);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onPasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };

  const saveData = (event) => {
    event.preventDefault();
    const formData = new FormData();

    if(SysShipperId > 0){
      formData.append("id", SysShipperId);
    }
    formData.append("shipper_code", shipperCode);
    formData.append("shipper_a_code", shipperACode);
    formData.append("shipper_name", companyName);
    formData.append("shipper_name_kana", companyNameKana);
    formData.append("shipper_zip", zipNumber1 + "-" + zipNumber2);
    formData.append("shipper_address_1", address1);
    formData.append("shipper_address_2", address2);
    formData.append("shipper_address_3", address3);
    formData.append(
      "shipper_tel",
      telephone1 + "-" + telephone2 + "-" + telephone3
    );
    formData.append("shipper_fax", fax1 + "-" + fax2 + "-" + fax3);
    formData.append("email", email);
    formData.append("password", password);

    let url =
      jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstShipperUserEndpoint;

    csLoading(true);
    axios
      .post(url, formData, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast.success("登録致しました。", { autoClose: 3000 });
        csLoading(false);

        navigate("/registry/shipper/entry/next", {
          state: {
            data: response.data,
            password : password,
          },
          replace: false,
        });
      })
      .catch((error) => {
        console.log(error.response.data)
        toast.error(error.response.data.message, { autoClose: 3000 });

        csLoading(false);
      });

    setOpen(false);
  };
//--------------shipper modal----------
  const [showModal, setShowModal] = useState(false);

  const [SearchValue, setSearchValue] = useState("");
  const [SearchValue2, setSearchValue2] = useState("");
  const [tableData, setTableData] = useState([]);

  const loadModalData = (event) => {
    event.preventDefault();
    setSearchValue(shipperCode);
    setSearchValue2(shipperACode);

    if (shipperCode != "") {
      let data = {};
      data["shipper_code"] = shipperCode;
      if (shipperACode != "") {
        data["shipper_a_code"] = shipperACode;
      }
      console.log(data);
      let url =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperEndpoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          if (response.data) {
            setTableData(response.data);
            setShowModal(true);
          }
        })
        .catch((error) => {});
    } else {
      setTableData([]);
      setShowModal(true);
    }
  };
  // Shipper ID
  const [SysShipperId, setSysShipperId] = useState(0);
  const handleChangeShipperCode = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 6) {
      str = str.substring(0, 6);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperCode(str);
  };
  const handleChangeShipperACode = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 6) {
      str = str.substring(0, 6);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperACode(str);
  };
  const onKeyDownShipperCode = (event, param) => {
    if (event.keyCode === 13) {
      // enter key
      if (param == 1) {
        getShipperData(event.target.value, shipperACode);
      } else {
        getShipperData(shipperCode, event.target.value);
      }
      event.preventDefault();
    }
  };

  const getShipperData = (code, acode) => {
    if (code != "") {
      let data = {};
      data["shipper_code"] = code;
      if (acode != "") {
        data["shipper_a_code"] = acode;
      }
      let url =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstShipperUserEndpoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response1) => {
          let t_data = response1.data;
          if (t_data.length > 0) {
            setShipperData(t_data[0]);
          } else {
            setShipperData([]);
          }
        })
        .catch((error) => {});
    } else {
      setShipperData([]);
    }
  };

  const setShipperData = (result) => {
    if (result["id"]) {
      setSysShipperId(result["id"]);
      setShipperCode(result["shipper_code"]);
      setShipperACode(result["shipper_a_code"]);
      setCompanyName(result["shipper_name"]);
      // setShipperAName(result["shipper_a_name"]);
      setCompanyNameKana(result["shipper_name_kana"]);
      // setShipperHonor(result["shipper_honor"]);
      if (result["shipper_zip"] != null) {
        const postcode_ary = result["shipper_zip"].split("-");
        if (postcode_ary[0]) {
          setZipNumber1(postcode_ary[0]);
        }
        if (postcode_ary[1]) {
          setZipNumber2(postcode_ary[1]);
        }
      }
      if (result["shipper_address_1"] != null) {
        setAddress1(result["shipper_address_1"]);
      }
      if (result["shipper_address_2"] != null) {
        setAddress2(result["shipper_address_2"]);
      }
      if (result["shipper_address_3"] != null) {
        setAddress3(result["shipper_address_3"]);
      }
      if (result["shipper_tel"] != null) {
        const phone_ary = result["shipper_tel"].split("-");
        if (phone_ary[0]) {
          setTelephone1(phone_ary[0]);
        }
        if (phone_ary[1]) {
          setTelephone2(phone_ary[1]);
        }
        if (phone_ary[2]) {
          setTelephone3(phone_ary[2]);
        }
      }
      if (result["shipper_fax"] != null) {
        const fax_ary = result["shipper_fax"].split("-");
        if (fax_ary[0]) {
          setFax1(fax_ary[0]);
        }
        if (fax_ary[1]) {
          setFax2(fax_ary[1]);
        }
        if (fax_ary[2]) {
          setFax3(fax_ary[2]);
        }
      }
      if (result["shipper_email"] != null) {
        setEmail(result["shipper_email"]);
      }
    } else {
      setSysShipperId(0);
      setCompanyName("");
      // setShipperAName("");
      setCompanyNameKana("");
      // setShipperHonor(0);
      setZipNumber1("");
      setZipNumber2("");
      setAddress1("");
      setAddress2("");
      setAddress3("");
      setTelephone1("");
      setTelephone2("");
      setTelephone3("");
      setFax1("");
      setFax2("");
      setFax3("");
      setEmail("");
    }
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  // ========================== ends code for search ==================================

  return (
    <div className={style.registerBackground}>
      <div className={gstyle.loader_container} id="csLoader">
        <ClipLoader color={"#fff"} size={60} />
      </div>
      <ShipperModal
        show={showModal}
        onClose={() => setShowModal(false)}
        width={"70%"}
        SearchValue={SearchValue}
        onChange={setSearchValue}
        SearchValue2={SearchValue2}
        onChange2={setSearchValue2}
        tableData={tableData}
        setTableData={setTableData}
        target={setShipperData}
      />

      <form className={style.inputForm}>
        <div className={style.title}>
          <span>荷主登録</span>
        </div>
        <div className={style.inputLayout}>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>会社名</p>
            <input
              type={"text"}
              className={style.cs_input}
              value={companyName}
              onChange={onCompanyChange}
            />
          </div>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>フリガナ</p>
            <input
              type={"text"}
              className={style.cs_input}
              value={companyNameKana}
              onChange={onCompanyChangeKana}
            />
          </div>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>郵便番号</p>
            <input
              type={"text"}
              className={style.cs_input}
              value={zipNumber1}
              style={{ width: "30%" }}
              onChange={onZipNumber1Change}
            />
            <span style={{ margin: 5 }}>-</span>
            <input
              type={"text"}
              className={style.cs_input}
              value={zipNumber2}
              style={{ width: "30%" }}
              onChange={onZipNumber2Change}
            />
            <button className={style.zipGetBut} onClick={getAddress}>
              郵便検索
            </button>
          </div>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>都道府県</p>
            <input
              type={"text"}
              className={style.cs_input}
              value={address1}
              onChange={onAddress1Change}
            />
          </div>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>市区町村</p>
            <input
              type={"text"}
              className={style.cs_input}
              value={address2}
              onChange={onAddress2Change}
            />
          </div>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>番地</p>
            <input
              type={"text"}
              className={style.cs_input}
              value={address3}
              onChange={onAddress3Change}
            />
          </div>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>電話番号</p>
            <input
              type={"text"}
              className={style.cs_input}
              style={{ width: "15%" }}
              value={telephone1}
              onChange={onTelephone1Change}
            />
            <span style={{ margin: 5 }}>-</span>
            <input
              type={"text"}
              className={style.cs_input}
              style={{ width: "15%" }}
              value={telephone2}
              onChange={onTelephone2Change}
            />
            <span style={{ margin: 5 }}>-</span>
            <input
              type={"text"}
              className={style.cs_input}
              style={{ width: "15%" }}
              value={telephone3}
              onChange={onTelephone3Change}
            />
          </div>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>FAX番号</p>
            <input
              type={"text"}
              className={style.cs_input}
              style={{ width: "15%" }}
              value={fax1}
              onChange={onFax1Change}
            />
            <span style={{ margin: 5 }}>-</span>
            <input
              type={"text"}
              className={style.cs_input}
              style={{ width: "15%" }}
              value={fax2}
              onChange={onFax2Change}
            />
            <span style={{ margin: 5 }}>-</span>
            <input
              type={"text"}
              className={style.cs_input}
              style={{ width: "15%" }}
              value={fax3}
              onChange={onFax3Change}
            />
          </div>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>メールアドレス</p>
            <input
              type={"text"}
              className={style.cs_input}
              value={email}
              onChange={onEmailChange}
            />
          </div>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>パスワード</p>
            <div className={style.passwordField}>
              <input
                className={style.PasswordText}
                placeholder="Password"
                type={isRevealPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={handleChangePassword}
              />
              {isRevealPassword ? (
                <AiFillEyeInvisible
                  onClick={togglePassword}
                  role="presentation"
                  className={style.PasswordReveal}
                />
              ) : (
                <AiFillEye
                  onClick={togglePassword}
                  role="presentation"
                  className={style.PasswordReveal}
                />
              )}
            </div>
            {/* <input
              type={"password"}
              className={style.cs_input}
              value={password}
              onChange={onPasswordChange}
            /> */}
          </div>
          <div className={style.subLayout}>
            <p className={style.inputLabel}>パスワード確認</p>
            <div className={style.passwordField}>
              <input
                className={style.PasswordText}
                placeholder="Password"
                type={isRevealPasswordConfirm ? "text" : "password"}
                name="password"
                value={passwordConfirm}
                onChange={onPasswordConfirmChange}
              />
              {isRevealPasswordConfirm ? (
                <AiFillEyeInvisible
                  onClick={togglePasswordConfirm}
                  role="presentation"
                  className={style.PasswordReveal}
                />
              ) : (
                <AiFillEye
                  onClick={togglePasswordConfirm}
                  role="presentation"
                  className={style.PasswordReveal}
                />
              )}
            </div>
            {/* <input
              type={"password"}
              className={style.cs_input}
              value={passwordConfirm}
              onChange={onPasswordConfirmChange}
            /> */}
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <Button className={style.registryBut} onClick={handleOpen}>
            次へ
          </Button>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {" "}
            <div style={{ fontSize: "1.1rem" }}>
              こちらの内容で登録しますか？
            </div>{" "}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} className={gstyle.save_confirm_no}>
              いいえ
            </Button>
            <Button
              onClick={saveData}
              className={gstyle.save_confirm_yes}
              autoFocus
            >
              はい
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <ToastContainer />
    </div>
  );
};

export default RegistryShipper;
