/* eslint-disable eqeqeq */
import style from './BusScheduleManagement.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';

import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import CalendarLine from '../../../components/common/calendar/ShipperBusScheduleLine';
import WarehouseModal from '../../../components/common/modal/ShipperWarehouseModal';
import CompanyWarehouseModal from '../../../components/common/modal/WarehouseUserModal';

import IconSearch from '../../../components/common/icons/icon_search.png';
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import Arrow from '../../../components/common/icons/open-arrow.png';
import BackArrow from '../../../components/common/icons/close-arrow.png';
import ArrowUp from '../../../components/common/icons/down-arrow.png';
import ArrowDown from '../../../components/common/icons/up-arrow.png';
import { Col, Row } from 'antd';
import { Button, Radio, Select } from 'antd';

import moment from 'moment';

const BusScheduleView = () => {

    const authentication = useJwt.isShipperUserLoggedIn();
    const userData = JSON.parse(useJwt.getShipperUserData());
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    // =================================================================================

    const [workerTableData, setWorkerTableData] = useState([]);
    const [inScheduleTableData, setInScheduleTableData] = useState([]);
    const [outScheduleTableData, setOutScheduleTableData] = useState([]);
    const [timeLineData, setTimeLineData] = useState([]);
    const [headerData1, setHeaderData1] = useState([]);
    const [headerData2, setHeaderData2] = useState([]);

    //date string

    const [dateString, setDateString] = useState(moment(new Date(), "YYYY-MM-DD").format('YYYY年MM月DD日'));

    let timeData = [];
    const timeRef = React.useRef(null);


    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);

            var item_length = 0;
            if (timeRef.current) {
                item_length = (timeRef.current.offsetWidth - 210) / 24;
                setItemLength(item_length);
            }

            for (let index = 0; index < 24; index++) {
                timeData.push(item_length);
            }
            setTimeLineData(timeData);
        }
    });

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    // warehouse --------------------------------------------------
    const [companyWarehouseCode, setCompanyWarehouseCode] = useState("");
    const [companyWarehouseName, setCompanyWarehouseName] = useState("");
    const [shipperId, setShipperId] = useState("")
    const [shipperCode, setShipperCode] = useState("")
    const setCompanyWarehouseData = (data, param) => { //rsc
        if (data['id']) {
            setCompanyWarehouseCode(data['id']);
            setCompanyWarehouseName(data['company_name']);
            setShipperId(data['shipper_id'])
            setShipperCode(data['shipper_code'])
            setSearchType("0");
            var start_date = moment(StartDate, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
            var end_date = moment(start_date, "YYYY-MM-DD HH:mm:ss").add(timeArea, 'days').add(-1, "seconds").format('YYYY-MM-DD HH:mm:ss');

            let api_data = {
                userID: userData['id'],
                start_time: start_date,
                end_time: end_date,
                company_warehouse_id: data['id']
            };

            csloading(true);
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperDriverScheduleByBus;
            axios
                .post(url, api_data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setLoaded(true);
                    if (response.status == 200) {
                        if (response.data.data[0].length == undefined) {
                            setWorkerTableData([]);
                        } else
                            setWorkerTableData(timeCalcData(response.data.data[0], StartDate, itemLength, timeArea));

                        setInScheduleTableData(response.data.data[1]);
                        setOutScheduleTableData(response.data.data[2]);
                    }
                    csloading(false);
                })
                .catch((error) => {
                    csloading(false);
                });
        } else {
            setCompanyWarehouseCode("");
            setCompanyWarehouseName("");
            setShipperId("")
            setShipperCode("")
        }
    }

    const [companyTargetParam, setCompanyTargetParam] = useState(1);

    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalCompanyWarehouse, setShowModalCompanyWarehouse] = useState(false);
    const [companyWarehouseSearchValue, setCompanyWarehouseSearchValue] = useState("");
    const [companyWarehouseTableData, setCompanyWarehouseTableData] = useState([]);
    const [warehouseUrl, setWarehouseUrl] = useState("");

    const loadCompanyWarehouseModalData = (event, param) => {
        event.preventDefault();
        setCompanyTargetParam(param);
        let wName = companyWarehouseName;
        let code = companyWarehouseCode
        setCompanyWarehouseSearchValue(wName);
        setWarehouseUrl(jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseUserByShipperEndpoint);

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_user_id'] = userData['id'];
            if (wName != "") {
                data['id'] = code;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseUserByShipperEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setCompanyWarehouseTableData(response.data.data);
                    setShowModalCompanyWarehouse(true);
                })
                .catch((error) => {
                });
        } else {
            setCompanyWarehouseTableData([]);
            setShowModalCompanyWarehouse(true);
        }
    }

    // time_length
    const [itemLength, setItemLength] = useState(0);

    const timeCalcData = (param, start_date, item_Length, totalTime) => {
        let totalArray = [];
        let subArray = [];
        let tempArray = [];

        param.forEach(element => {
            subArray = [];
            $.each(element, function (key) {
                tempArray = element[key];
                tempArray['item'] = timeWidthCalc(start_date, tempArray.start_date_time, tempArray.end_date_time, item_Length, totalTime);

                var ms = 0;
                var real_start_time = "";
                var real_end_time = "";

                ms = moment(tempArray.start_date_time, "YYYY-MM-DD HH:mm:ss").diff(moment(tempArray.start_time, "YYYY-MM-DD HH:mm:ss"), "seconds");

                if (ms >= 0) {
                    real_start_time = tempArray.start_date_time;
                } else real_start_time = tempArray.start_time;

                ms = moment(tempArray.end_date_time, "YYYY-MM-DD HH:mm:ss").diff(moment(tempArray.end_time, "YYYY-MM-DD HH:mm:ss"), "seconds");

                if (ms >= 0) {
                    real_end_time = tempArray.end_time;
                } else real_end_time = tempArray.end_date_time;

                tempArray['real_item'] = realTimeWidthCalc(start_date, real_start_time, real_end_time, item_Length, totalTime);

                let standardList = JSON.parse(tempArray.standard);
                let tempStandard = "";
                for (var j = 0; j < standardList.length; j++) {
                    if (j != 0) tempStandard += '、'
                    if (standardList[j] == "1") tempStandard += '4t';
                    if (standardList[j] == "2") tempStandard += '10t';
                    if (standardList[j] == "3") tempStandard += 'トレーラー';
                }
                tempArray['standard_label'] = tempStandard;
                console.log(tempArray)
                subArray.push(tempArray);
            });
            totalArray.push(subArray);
        });

        return totalArray;
    }

    const timeWidthCalc = (start_date, sTime, eTime, item_Length, totalTime) => {

        var cur_date = new Date();
        var start_ms = moment(sTime, "YYYY-MM-DD HH:mm:ss").diff(moment(start_date, "YYYY-MM-DD HH:mm:ss"), 'seconds');
        var fixed_ms = moment(sTime, "YYYY-MM-DD HH:mm:ss")
            .diff(moment(cur_date.getFullYear() + '-' + (cur_date.getMonth() + 1) + '-' + cur_date.getDate(), "YYYY-MM-DD HH:mm:ss"), 'seconds');
        var fixe_flag = "0";

        if (fixed_ms < 0)
            fixe_flag = "1";

        var startPoint = 0;
        var width = 0;
        var item_unit;
        var lengthFlag = 0;

        if (sTime != null) {
            if (totalTime == 1) {
                item_unit = item_Length / (totalTime * 3600);
            } else if (totalTime == 4) {
                item_unit = item_Length / (6 * 3600);
            } else if (totalTime == 7) {
                item_unit = item_Length / (12 * 3600);
            }

            var length_ms = 0;
            if (start_ms < 0 || start_ms == 0) {
                startPoint = 0;
                lengthFlag = 2;
                length_ms = moment(eTime, "YYYY-MM-DD HH:mm:ss").diff(moment(start_date, "YYYY-MM-DD HH:mm:ss"), 'seconds');
            } else {
                startPoint = Math.round(start_ms * item_unit);
                length_ms = moment(eTime, "YYYY-MM-DD HH:mm:ss").diff(moment(sTime, "YYYY-MM-DD HH:mm:ss"), 'seconds');
            }

            if (length_ms < 0 || length_ms == 0) {
                width = 0;
            } else if (length_ms >= (86400 * totalTime) && startPoint == 0) {
                lengthFlag = 3;
                width = (86400 * totalTime) * item_unit;
            } else if (length_ms >= (86400 * totalTime) && startPoint > 0) {
                width = (86400 * totalTime) * item_unit - startPoint;
                lengthFlag = 1;
            } else if (length_ms <= (86400 * totalTime) && startPoint > 0) {
                if (length_ms + start_ms >= 86400 * totalTime) {
                    length_ms = (86400 * totalTime) - start_ms;
                    width = length_ms * item_unit;
                    lengthFlag = 1;
                } else {
                    width = length_ms * item_unit;
                }
            } else {
                width = length_ms * item_unit;
            }
            return { width: width, start_point: startPoint, unit: item_unit, start_date: start_date, length_flag: lengthFlag, fixe_flag: fixe_flag };
        } else
            return { width: 0, start_point: 0, unit: 0, start_date: 0, length_flag: 0 };
    }


    const realTimeWidthCalc = (start_date, sTime, eTime, item_Length, totalTime) => {
        var start_ms = moment(sTime, "YYYY-MM-DD HH:mm:ss").diff(moment(start_date, "YYYY-MM-DD HH:mm:ss"), 'seconds');
        var startPoint = 0;
        var width = 0;
        var item_unit;
        var lengthFlag = 0;

        if (sTime != null) {
            if (totalTime == 1) {
                item_unit = item_Length / (totalTime * 3600);
            } else if (totalTime == 4) {
                item_unit = item_Length / (6 * 3600);
            } else if (totalTime == 7) {
                item_unit = item_Length / (12 * 3600);
            }

            var length_ms = 0;
            if (start_ms < 0 || start_ms == 0) {
                startPoint = 0;
                lengthFlag = 2;
                length_ms = moment(eTime, "YYYY-MM-DD HH:mm:ss").diff(moment(start_date, "YYYY-MM-DD HH:mm:ss"), 'seconds');
            } else {
                startPoint = Math.round(start_ms * item_unit);
                length_ms = moment(eTime, "YYYY-MM-DD HH:mm:ss").diff(moment(sTime, "YYYY-MM-DD HH:mm:ss"), 'seconds');
            }

            if (length_ms < 0 || length_ms == 0) {
                width = 0;
            } else if (length_ms >= (86400 * totalTime) && startPoint == 0) {
                lengthFlag = 3;
                width = (86400 * totalTime) * item_unit;
            } else if (length_ms >= (86400 * totalTime) && startPoint > 0) {
                width = (86400 * totalTime) * item_unit - startPoint;
                lengthFlag = 1;
            } else if (length_ms <= (86400 * totalTime) && startPoint > 0) {
                if (length_ms + start_ms >= 86400 * totalTime) {
                    length_ms = (86400 * totalTime) - start_ms;
                    width = length_ms * item_unit;
                    lengthFlag = 1;
                } else {
                    width = length_ms * item_unit;
                }
            } else {
                width = length_ms * item_unit;
            }
            return { width: width, start_point: startPoint, unit: item_unit, start_date: start_date, length_flag: lengthFlag };
        } else
            return { width: 0, start_point: 0, unit: 0, start_date: 0, length_flag: 0 };
    }

    // start date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));

    const onChange = (date, dateString) => {
        // console.log(date, dateString);
    };

    const handleChangeStartDate = (date) => {

        setStartDate(useJwt.formatDate(date));

        let temp_date = useJwt.formatDate(date);

        csloading(true);
        arrowImgInit();

        var start_date = moment(temp_date, "YYYY-MM-DD 00:00:00").format('YYYY-MM-DD HH:mm:ss');
        var end_date = moment(start_date, "YYYY-MM-DD HH:mm:ss").add(timeArea, 'days').add(-1, "seconds").format('YYYY-MM-DD HH:mm:ss');

        if (timeArea == 1) {
            setDateString(moment(start_date, "YYYY-MM-DD").format('YYYY年MM月DD日'));
        } else {
            setDateString(moment(start_date, "YYYY-MM-DD").format('YYYY年MM月DD日') + " ~ " + moment(end_date, "YYYY-MM-DD").format('YYYY年MM月DD日'));
        }

        var area_number = 24;
        if (timeArea == 4) {
            area_number = 16;
            let temp1 = [];
            let temp2 = [];

            var WeekChars = ["月", "火", "水", "木", "金", "土", "日"];
            var temp_date_calc = moment(temp_date, "YYYY-MM-DD HH:mm:ss");
            for (let idx = 0; idx < timeArea; idx++) {
                temp1.push(temp_date_calc.format('D') + "(" + WeekChars[temp_date_calc.isoWeekday() - 1] + ")");
                for (let sub = 0; sub < 4; sub++) {
                    temp2.push((sub * 6) + " - " + ((sub + 1) * 6));
                }
                temp_date_calc = moment(temp_date_calc, "YYYY-MM-DD HH:mm:ss").add(1, 'days');
            }

            setHeaderData1(temp1);
            setHeaderData2(temp2);
        }

        if (timeArea == 7) {
            area_number = 14;
            let temp1 = [];
            let temp2 = [];


            var WeekChars = ["月", "火", "水", "木", "金", "土", "日"];
            var temp_date_calc = moment(temp_date, "YYYY-MM-DD HH:mm:ss");
            for (let idx = 0; idx < timeArea; idx++) {
                temp1.push(temp_date_calc.format('D') + "(" + WeekChars[temp_date_calc.isoWeekday() - 1] + ")");
                temp2.push("午前");
                temp2.push("午後");
                temp_date_calc = moment(temp_date_calc, "YYYY-MM-DD HH:mm:ss").add(1, 'days');
            }

            setHeaderData1(temp1);
            setHeaderData2(temp2);
        }

        let data = {
            userID: userData['id'],
            start_time: start_date,
            end_time: end_date,
            company_warehouse_id: companyWarehouseCode
        };
        if (searchType == "2") {
            data = {
                userID: userData['id'],
                start_time: start_date,
                end_time: end_date,
                default_worker: 1,
                company_warehouse_id: companyWarehouseCode
            };
        } else if (searchType == "1") {
            data = {
                userID: userData['id'],
                start_time: start_date,
                end_time: end_date,
                'warehouse_id': WarehouseId,
                company_warehouse_id: companyWarehouseCode
            };
        }

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperDriverScheduleByBus;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(true);
                if (response.status == 200) {
                    setWorkerTableData(timeCalcData(response.data.data[0], temp_date, itemLength, timeArea));
                    setInScheduleTableData(response.data.data[1]);
                    setOutScheduleTableData(response.data.data[2]);
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });
    };

    // ----------------------------- tab change -------------------------------------

    const [tab1Toggled, setTab1Toggled] = useState(0);
    const [tab2Toggled, setTab2Toggled] = useState(0);
    const handleTab = (flag) => {
        if (flag == 1) {
            if (tab1Toggled == 0) {
                document.getElementById('tab1').style.right = "0px";
                document.getElementById('tab1').style.transition = "right 0.5s";
                setTab1Toggled(1);
            } else {
                document.getElementById('tab1').style.right = "-300px";
                document.getElementById('tab1').style.transition = "right 0.5s";
                setTab1Toggled(0);
            }
        }
        if (flag == 2) {
            if (tab2Toggled == 0) {
                document.getElementById('tab2').style.right = "0px";
                document.getElementById('tab2').style.transition = "right 0.5s";
                setTab2Toggled(1);
            } else {
                document.getElementById('tab2').style.right = "-300px";
                document.getElementById('tab2').style.transition = "right 0.5s";
                setTab2Toggled(0);
            }
        }
    }
    // ========================== schedule time line drap or drop=================================================

    const createWorkerDataItem = (data) => {
        console.log('ad', data);
        let newDataItem = {
            sys_schedule_id: data['id'],
            bus_id: data['bus_id'],
            user_id: userData['id'],
            start_date_time: data['start_date_time'],
            end_date_time: data['end_date_time'],
            work_type: data['work_type'],
            item_name: data['item_name'],
            car_standard: data['car_standard'],
        };

        return JSON.stringify(newDataItem);
    }

    const imgUpDownHandle = (e, row_id, flag) => {
        e.preventDefault();

        if (flag == 1) {
            document.getElementById("row_" + row_id).style.maxHeight = "10000px";
            document.getElementById("row_" + row_id).style.display = "inline-table";

            document.getElementById("img_down_" + row_id).style.display = "none";
            document.getElementById("img_up_" + row_id).style.display = "";
        } else {
            document.getElementById("row_" + row_id).style.display = "";

            document.getElementById("img_down_" + row_id).style.display = "";
            document.getElementById("img_up_" + row_id).style.display = "none";
        }
    }

    const arrowImgInit = () => {
        $('.img_up').prop('style', 'width: 15px; cursor: pointer; display:none');
        $('.img_down').prop('style', 'width: 15px; cursor: pointer');

    }

    var drop_id = 0;
    const onDropHandle = (e, row_id, carType) => {
        e.preventDefault();
        var data = e.dataTransfer.getData("text");
        var temp = JSON.parse(data);
        temp['car_type'] = temp['car_standard'];
        let busType = JSON.parse(carType);
        console.log(row_id);
        console.log(temp['bus_id']);

        let checked = false;
        for (var i = 0; i < busType.length; i++) {
            if (temp['car_standard'] == busType[i]) {
                checked = true;
            }
        }

        if (!checked) {
            toast.error("車種をご確認ください。", { autoClose: 3000 })
            return;
        }

        if (temp['bus_id'] == row_id) return;

        temp['bus_id'] = row_id;
        temp['userID'] = userData['id'];

        if (drop_id != temp['sys_schedule_id']) {
            drop_id = temp['sys_schedule_id'];
            temp['id'] = temp['sys_schedule_id'];
            temp['company_warehouse_id'] = companyWarehouseCode

            arrowImgInit();
            csloading(true);
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperDriverScheduleByBus;
            axios
                .post(url, temp, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    if (response.status == 200) {
                        var start_date = moment(StartDate, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
                        var end_date = moment(start_date, "YYYY-MM-DD HH:mm:ss").add(timeArea, 'days').add(-1, "seconds").format('YYYY-MM-DD HH:mm:ss');

                        let data = {
                            userID: userData['id'],
                            start_time: start_date,
                            end_time: end_date,
                            company_warehouse_id: companyWarehouseCode
                        };
                        if (searchType == "2") {
                            data = {
                                userID: userData['id'],
                                start_time: start_date,
                                end_time: end_date,
                                default_worker: 1,
                                company_warehouse_id: companyWarehouseCode
                            };
                        } else if (searchType == "1") {
                            data = {
                                userID: userData['id'],
                                start_time: start_date,
                                end_time: end_date,
                                'warehouse_id': WarehouseId,
                                company_warehouse_id: companyWarehouseCode
                            };
                        }

                        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperDriverScheduleByBus;
                        axios
                            .post(url, data, {
                                headers: {
                                    Authorization: "Bearer " + useJwt.getToken(),
                                },
                            })
                            .then((response) => {
                                setLoaded(true);
                                if (response.status == 200) {
                                    setWorkerTableData(timeCalcData(response.data.data[0], StartDate, itemLength, timeArea));
                                    setInScheduleTableData(response.data.data[1]);
                                    setOutScheduleTableData(response.data.data[2]);
                                }
                                csloading(false);
                            })
                            .catch((error) => {
                                csloading(false);
                            });
                    }
                })
                .catch((error) => {
                    csloading(false);
                });
        }
    }

    const allowDrop = (e, id) => {
        e.preventDefault();
    }

    const onDragHandle = (e) => {
        e.dataTransfer.setData("text", e.target.getAttribute("data-set"));
    }

    const [timeArea, setTimeArea] = useState("1");
    const handleTimeArea = (param) => {
        setTimeArea(param);

        arrowImgInit();

        csloading(true);
        var start_date = moment(StartDate, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
        var end_date = moment(start_date, "YYYY-MM-DD HH:mm:ss").add(param, 'days').add(-1, "seconds").format('YYYY-MM-DD HH:mm:ss');

        if (param == 1) {
            setDateString(moment(start_date, "YYYY-MM-DD").format('YYYY年MM月DD日'));
        } else {
            setDateString(moment(start_date, "YYYY-MM-DD").format('YYYY年MM月DD日') + " ~ " + moment(end_date, "YYYY-MM-DD").format('YYYY年MM月DD日'));
        }

        var item_length = 0;
        var area_number = 24;
        if (param == 4) {
            area_number = 16;
            let temp1 = [];
            let temp2 = [];

            var WeekChars = ["月", "火", "水", "木", "金", "土", "日"];
            var temp_date_calc = moment(start_date, "YYYY-MM-DD HH:mm:ss");
            for (let idx = 0; idx < param; idx++) {
                temp1.push(temp_date_calc.format('D') + "(" + WeekChars[temp_date_calc.isoWeekday() - 1] + ")");
                for (let sub = 0; sub < 4; sub++) {
                    temp2.push((sub * 6) + " - " + ((sub + 1) * 6));
                }
                temp_date_calc = moment(temp_date_calc, "YYYY-MM-DD HH:mm:ss").add(1, 'days');
            }

            setHeaderData1(temp1);
            setHeaderData2(temp2);
        }

        if (param == 7) {
            area_number = 14;
            let temp1 = [];
            let temp2 = [];

            var WeekChars = ["月", "火", "水", "木", "金", "土", "日"];
            var temp_date_calc = moment(start_date, "YYYY-MM-DD HH:mm:ss");
            for (let idx = 0; idx < param; idx++) {
                temp1.push(temp_date_calc.format('D') + "(" + WeekChars[temp_date_calc.isoWeekday() - 1] + ")");
                temp2.push("午前");
                temp2.push("午後");
                temp_date_calc = moment(temp_date_calc, "YYYY-MM-DD HH:mm:ss").add(1, 'days');
            }

            setHeaderData1(temp1);
            setHeaderData2(temp2);
        }

        timeData = [];

        if (timeRef.current) {
            item_length = (timeRef.current.offsetWidth - 210) / area_number;
            setItemLength(item_length);
        }

        for (let index = 0; index < area_number; index++) {
            timeData.push(item_length);
        }

        setTimeLineData(timeData);
        timeData = [];

        let data = {
            userID: userData['id'],
            start_time: start_date,
            end_time: end_date,
            company_warehouse_id: companyWarehouseCode
        };
        if (searchType == "2") {
            data = {
                userID: userData['id'],
                start_time: start_date,
                end_time: end_date,
                default_worker: 1,
                company_warehouse_id: companyWarehouseCode
            };
        } else if (searchType == "1") {
            data = {
                userID: userData['id'],
                start_time: start_date,
                end_time: end_date,
                'warehouse_id': WarehouseId,
                company_warehouse_id: companyWarehouseCode
            };
        }

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperDriverScheduleByBus;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(true);
                if (response.status == 200) {
                    setWorkerTableData(timeCalcData(response.data.data[0], start_date, item_length, param));
                    setInScheduleTableData(response.data.data[1]);
                    setOutScheduleTableData(response.data.data[2]);
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });
    }

    // ========================== search type============================================

    const [searchType, setSearchType] = useState("0");
    const handleChangeSearchType = (value) => {

        setSearchType(value);

        arrowImgInit();

        setWarehouseCode("");
        setWarehouseName("");
        setWarehouseId("0");

        var start_date = moment(StartDate, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
        var end_date = moment(start_date, "YYYY-MM-DD HH:mm:ss").add(timeArea, 'days').add(-1, "seconds").format('YYYY-MM-DD HH:mm:ss');

        let data = {
            userID: userData['id'],
            start_time: start_date,
            end_time: end_date,
            company_warehouse_id: companyWarehouseCode
        };

        if (value == "1") {
            return;
        } else if (value == "2") {
            data = {
                userID: userData['id'],
                start_time: start_date,
                end_time: end_date,
                default_worker: 1,
                company_warehouse_id: companyWarehouseCode
            };
        }
        csloading(true);

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperDriverScheduleByBus;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if (response.status == 200) {
                    if (response.data[0].length == undefined) {
                        setWorkerTableData([]);
                    } else
                        setWorkerTableData(timeCalcData(response.data.data[0], StartDate, itemLength, timeArea));

                    setWorkerTableData(timeCalcData(response.data.data[0], StartDate, itemLength, timeArea));
                    setInScheduleTableData(response.data.data[1]);
                    setOutScheduleTableData(response.data.data[2]);
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });
    }

    //=========================== warehouse =============================================
    const [WarehouseId, setWarehouseId] = useState("0");
    const [WarehouseCode, setWarehouseCode] = useState("");
    const handleChangeWarehouseCode = (event) => {
        setWarehouseCode(useJwt.validateNum(event.target.value, 2));
    };
    const [WarehouseName, setWarehouseName] = useState("");

    const onKeyDownWarehouseCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value, param);
            event.preventDefault();
        }
    };

    const getWarehouseData = (code, param) => {
        if (code != "") {
            const data = {
                userID: userData['id'],
                warehouse_company_id: companyWarehouseCode,
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperWarehouseEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWarehouseData(result, param);
                        } else {
                            setWarehouseData([], param);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setWarehouseData([], param);
        }
    }
    const setWarehouseData = (data, param) => {
        if (data['warehouse_code']) {
            arrowImgInit();

            setWarehouseCode(data['warehouse_code']);
            setWarehouseName(data['warehouse_name']);
            setWarehouseId(data['sys_warehouse_id']);


            var start_date = moment(StartDate, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
            var end_date = moment(start_date, "YYYY-MM-DD HH:mm:ss").add(timeArea, 'days').add(-1, "seconds").format('YYYY-MM-DD HH:mm:ss');

            let api_data = {
                userID: userData['id'],
                start_time: start_date,
                end_time: end_date,
                warehouse_id: data['sys_warehouse_id'],
                company_warehouse_id: companyWarehouseCode
            };

            csloading(true);
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperDriverScheduleByBus;
            axios
                .post(url, api_data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setLoaded(true);
                    if (response.status == 200) {
                        if (response.data.data[0].length == undefined) {
                            setWorkerTableData([]);
                        } else
                            setWorkerTableData(timeCalcData(response.data.data[0], StartDate, itemLength, timeArea));

                        setInScheduleTableData(response.data.data[1]);
                        setOutScheduleTableData(response.data.data[2]);
                    }
                    csloading(false);
                })
                .catch((error) => {
                    csloading(false);
                });
        } else {
            setWarehouseCode("");
            setWarehouseName("");
            setWarehouseId("0");
        }
    }

    //============================waerhouse modal=====================================
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseSearchValue2, setWarehouseSearchValue2] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWarehouseModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode;

        setWarehouseSearchValue(code);
        setWarehouseSearchValue2(companyWarehouseCode);

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_company_id'] = companyWarehouseCode
            if (code != "") {
                data['warehouse_code'] = code;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWarehouseTableData(response.data.data);
                    setShowModalWarehouse(true);
                })
                .catch((error) => {
                });
        } else {
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }

    const handleResize = (e) => {
        // console.log('sdfsdfsdf');
    }

    const handleBodyMouseDown = (e) => {
        document.getElementById('tab1').style.right = "-300px";
        document.getElementById('tab1').style.transition = "right 0.5s";
        setTab1Toggled(0);
        document.getElementById('tab2').style.right = "-300px";
        document.getElementById('tab2').style.transition = "right 0.5s";
        setTab2Toggled(0);
    }
    // ========================== style =================================================
    const style_total_row = [gstyle.cs_flex, style.total_row];
    const style_select_40 = [style.cs_select, gstyle.w30px];

    const style_input_100px = [style.cs_input, gstyle.w100px];
    const style_input_bl_n = [style.cs_input, style.job_time];

    const style_border = [gstyle.border, style.body_div_m];
    const style_time_line = [gstyle.cs_flex, style.time_line];

    return (
        <div className={style.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <CompanyWarehouseModal show={showModalCompanyWarehouse} onClose={() => setShowModalCompanyWarehouse(false)} width={'70%'}
                SearchValue={companyWarehouseSearchValue} url={warehouseUrl} onChange={setCompanyWarehouseSearchValue}
                tableData={companyWarehouseTableData} setTableData={setCompanyWarehouseTableData}
                target={setCompanyWarehouseData} />
            <WarehouseModal show={showModalWarehouse} onClose={() => setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} SearchValue2={WarehouseSearchValue2} onChange={setWarehouseSearchValue}
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <input type={'hidden'} id="selectedId" />
            <div className={style_border.join(" ")}>
                <p className={gstyle.text}>スケジュール表</p>
            </div>
            <div className={style.body_div_m} onMouseDown={(e) => handleBodyMouseDown(e)}>
                <Row style={{ marginBottom: "10px" }}>
                    <label className={style.ml_auto} style={{ fontSize: "20px" }}>{dateString}</label>
                    <div className={style.ml_auto}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 120 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ height: 10, width: 70, backgroundColor: '#ec8758', marginRight: 10, borderRadius:5 }}></div>
                                <span>入庫</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ height: 10, width: 70, backgroundColor: '#546e5a', marginRight: 10, borderRadius:5 }}></div>
                                <span>出庫</span>
                            </div>
                        </div>
                    </div>
                </Row>
                <Row gutter={24} style={{ marginBottom: "15px" }}>
                    <Col span={3}>
                        <InputDatepicker value={StartDate} onChange={handleChangeStartDate} />
                    </Col>
                    <Col span={6}>
                        <div className={gstyle.cs_flex}>
                            <span style={{ minWidth: 65, marginLeft: 10 }}>倉庫会社</span>
                            <input type={'text'} className={style_input_100px.join(" ")} disabled
                                value={companyWarehouseName} />
                            <div className={gstyle.icon_search_wrap} onClick={(e) => loadCompanyWarehouseModalData(e, 1)}>
                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                            </div>
                        </div>
                    </Col>
                    <Col span={2}>
                        <Select defaultValue="0" value={searchType} style={{ width: 120 }} onChange={handleChangeSearchType}
                            options={[
                                { value: '0', label: '全て', },
                                { value: '1', label: '所属倉庫', },
                                // { value: '2', label: '所属作業員', },
                            ]}
                        />
                    </Col>
                    <Col span={6}>
                        {
                            searchType == 1 &&
                            <div className={gstyle.cs_flex}>
                                <input type={'number'} className={style_input_100px.join(" ")}
                                    onKeyDown={(e) => onKeyDownWarehouseCode(e, 1)}
                                    value={WarehouseCode} onChange={handleChangeWarehouseCode} />
                                <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                    value={WarehouseName} />
                                <div className={gstyle.icon_search_wrap} onClick={(e) => loadWarehouseModalData(e, 1)}>
                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                </div>
                            </div>
                        }
                    </Col>
                    <Col span={6} style={{ marginLeft: "auto" }}>
                        <Radio.Group value={timeArea} onChange={(e) => handleTimeArea(e.target.value)} style={{ display: "flex", justifyContent: "end" }}>
                            <Radio.Button value="1">1日</Radio.Button>
                            <Radio.Button value="4">4日</Radio.Button>
                            <Radio.Button value="7">1週間</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>

                <div className={style.time_line_header}>
                    <div className={style.time_line_name_header}>
                        <span style={{ marginLeft: -10 }}>バース</span>
                    </div>
                    <div>
                        {
                            timeArea == 1 ?
                                <div className={style_time_line.join(" ")}>
                                    {timeLineData?.map((time_data, index) => (
                                        index == timeLineData.length - 1 ?
                                            <div className={style.time_line_header_body_l} style={{ width: (time_data - 1) + "px", height: "40px" }} key={"shtime_" + index}>
                                                {index == 0 ?
                                                    <div className={style.time_line_header_body_item} style={{ transform: "translateX(-0.5ch)", marginLeft: 5 }}>{moment(StartDate, "YYYY-MM-DD").format("M/D")} <br /> {index}</div>
                                                    : index < 10 ?
                                                        <div className={style.time_line_header_body_item} style={{ transform: "translateX(-0.5ch)" }}>{index}</div>
                                                        :
                                                        <div className={style.time_line_header_body_item} style={{ transform: "translateX(-1.1ch)" }}>{index}</div>
                                                }
                                            </div>
                                            :
                                            <div className={style.time_line_header_body} style={{ width: (time_data - 1) + "px", height: "40px" }} key={"shtime_" + index}>
                                                {index == 0 ?
                                                    <div className={style.time_line_header_body_item} style={{ transform: "translateX(-0.5ch)", marginLeft: 5 }}>{moment(StartDate, "YYYY-MM-DD").format("M/D")} <br /> {index}</div>
                                                    : index < 10 ?
                                                        <div className={style.time_line_header_body_item} style={{ transform: "translateX(-0.5ch)" }}>{index}</div>
                                                        :
                                                        <div className={style.time_line_header_body_item} style={{ transform: "translateX(-1.1ch)" }}>{index}</div>
                                                }
                                            </div>
                                    ))}
                                </div>
                                :
                                timeArea == 4 ?
                                    <Row>
                                        <div style={{ display: "flex" }}>
                                            {headerData1?.map((header1_data, index) => (
                                                <div className={style.time_line_header_other_body} style={{ width: ((timeLineData[0] - 1) * 4 + 3) + "px", height: "18px" }} key={"sh1time_" + index}>
                                                    <div className={style.time_line_header_body_other_item}>{header1_data}</div>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            {timeLineData?.map((time_data, index) => (
                                                <div className={style.time_line_header_other_body} style={{ width: (time_data - 1) + "px", height: "18px" }} key={"shtime_" + index}>
                                                    <div className={style.time_line_header_body_other_item}>{headerData2[index]}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </Row>
                                    :
                                    <Row>
                                        <div style={{ display: "flex" }}>
                                            {headerData1?.map((header1_data, index) => (
                                                <div className={style.time_line_header_other_body} style={{ width: ((timeLineData[0] - 1) * 2 + 1) + "px", height: "18px" }} key={"sh1time_" + index}>
                                                    <div className={style.time_line_header_body_other_item}>{header1_data}</div>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            {timeLineData?.map((time_data, index) => (
                                                <div className={style.time_line_header_other_body} style={{ width: (time_data - 1) + "px", height: "18px" }} key={"shtime_" + index}>
                                                    <div className={style.time_line_header_body_other_item}>{headerData2[index]}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </Row>

                        }
                    </div>

                </div>
                <div className={style.time_line_body} ref={timeRef} onResize={(e) => handleResize(e)} key="qqq">
                    {workerTableData?.map((data, ind) => (
                        <div className={style_total_row.join(" ")} key={ind} id={"t_" + data[0]['id']} style={{ alignItems: "stretch" }}>
                            <div className={style.time_line_name} key={"stl_" + ind}>
                                <div className={style.time_name_body} id={"n_" + data[0]['id']} key={"ln_" + data[0]['id']}>
                                    <span className={style.time_name_sub1}>{data[0]['warehouse_name']}</span><br />
                                    <span className={style.time_name_sub2}>{data[0]['bus_name'] + "番バース"}</span><br />
                                    <span className={style.time_name_sub2}>
                                        {data[0]['standard_label']}
                                    </span>
                                </div>
                                {
                                    data.length > 3 &&
                                    <div className={style.arrow_div}>
                                        <img className="img_down" id={"img_down_" + data[0]['id']} src={ArrowUp} style={{ width: "15px", cursor: "pointer" }} onClick={(e) => imgUpDownHandle(e, data[0]['id'], 1)} />
                                        <img className="img_up" id={"img_up_" + data[0]['id']} src={ArrowDown} style={{ width: "15px", cursor: "pointer", display: "none" }} onClick={(e) => imgUpDownHandle(e, data[0]['id'], 2)} />
                                    </div>
                                }
                            </div>
                            <div className="schedule_row" id={"row_" + data[0]['id']} onDrop={(e) => onDropHandle(e, data[0]['id'], data[0]['standard'])} onDragOver={(e) => allowDrop(e, data[0]['id'])} key={"time_line_" + data[0]['id']}>
                                <CalendarLine timeData={timeLineData} busData={data} startTime={StartDate} shipperId={shipperId}></CalendarLine>
                            </div>
                        </div>
                    ))}

                    <div className={style_total_row.join(" ")} style={{ height: "calc(100vh - 10px)", zIndex: "1" }}>
                        <div className={style.time_line_name} style={{ height: "100%" }}>
                            <div className={style.time_name_body} >
                            </div>
                        </div>
                        <div className={style_time_line.join(" ")}>
                            {timeLineData?.map((time_data, index) => (
                                <div className={style.time_line_item} style={{ width: (time_data - 1) + "px", height: "calc(100vh - 10px)" }} key={"stime_" + index}></div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BusScheduleView;

