/* eslint-disable eqeqeq */
import style from './StocksByWarehouse.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ItemModal from '../../../components/common/modal/ShipperItemModal';
import WarehouseUserModal from '../../../components/common/modal/WarehouseUserModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import { Select } from 'antd'

const StocksByWarehouse = () => {

    const authentication = useJwt.isShipperUserLoggedIn();
    const userData = JSON.parse(useJwt.getShipperUserData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    // slip date ---------------------------------------
    const [SlipDate, setSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeSlipDate = (date) => {
        setSlipDate(useJwt.formatDate(date));
    };

    // warehouse company --------------------------------------------------
    const [WarehouseCompanyCode, setWarehouseCompanyCode] = useState("");
    const handleChangeWarehouseCompanyCode = (event) => {
        setWarehouseCompanyCode(useJwt.validateNum(event.target.value, 2));
        gridMainTable([]);
    };
    const [WarehouseCompanyName, setWarehouseCompanyName] = useState("");
    const onKeyDownWarehouseCompanyCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getWarehouseCompanyData(event.target.value, param);
            event.preventDefault();
        }
    };
    const getWarehouseCompanyData = (code, param) => {
        if (code != "") {
            const data = {
                userID: userData['id'],
                shipper_id: userData['id'],
                id: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseUserByShipperEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWarehouseCompanyData(result, param);
                        } else {
                            setWarehouseCompanyData([], param);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setWarehouseCompanyData([], param);
        }
    }
    const setWarehouseCompanyData = (data, param) => {
        console.log(data)
        if (data['id']) {
            setWarehouseCompanyCode(data['id']);
            setWarehouseCompanyName(data['company_name']);

        } else {
            setWarehouseCompanyCode("");
            setWarehouseCompanyName("");
        }
    }

    // ----------------------------- Warehouse Company modal -------------------------------------
    const [showModalWarehouseCompany, setShowModalWarehouseCompany] = useState(false);
    const [WarehouseCompanySearchValue, setWarehouseCompanySearchValue] = useState("");
    const [WarehouseCompanyTableData, setWarehouseCompanyTableData] = useState([]);
    const [warehouseCompanyUrl, setWarehouseCompanyUrl] = useState("");

    const loadWarehouseCompanyModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCompanyCode;
        setWarehouseCompanySearchValue(code);
        setWarehouseCompanyUrl(jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseUserByShipperEndpoint);

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_id'] = userData['id'];
            if (code != "") {
                data['id'] = code;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseUserByShipperEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWarehouseCompanyTableData(response.data.data);
                    setShowModalWarehouseCompany(true);
                })
                .catch((error) => {
                });
        } else {
            setWarehouseCompanyTableData([]);
            setShowModalWarehouseCompany(true);
        }
    }

    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value, 2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value, 2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            if (WarehouseCompanyCode == "") {
                toast.error("倉庫会社を入力してください。", { autoClose: 3000 });
                return;
            }
            getWarehouseData(event.target.value, param);
            event.preventDefault();

            if (param == 1) {
                document.getElementById('warehouse_code2').focus();
            } else {
                document.getElementById('item_code1').focus();
            }
        }
    };
    const getWarehouseData = (code, param) => {
        if (WarehouseCompanyCode == "") {
            toast.error("倉庫会社を入力してください。", { autoClose: 3000 });
            return;
        }

        if (code != "") {
            const data = {
                userID: WarehouseCompanyCode,
                warehouse_company_id: WarehouseCompanyCode,
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseCompanyEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWarehouseData(result, param);
                        } else {
                            setWarehouseData([], param);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setWarehouseData([], param);
        }
    }
    const [warehouseId, setWarehouseId] = useState("")
    const setWarehouseData = (data, param) => {
        setWarehouseId(data['id'])
        setWarehouseName1(data['company_name']);
        setShipperCode(data['shipper_code'])
    }
    // item ----------------------------------------------------
    const [ItemCode1, setItemCode1] = useState("");
    const handleChangeItemCode1 = (event) => {
        setItemCode1(useJwt.validateString(event.target.value, 15));
    };
    const [ItemACode1, setItemACode1] = useState("");
    const handleChangeItemACode1 = (event) => {
        setItemACode1(useJwt.validateNum(event.target.value, 3));
    };
    const [ItemName1, setItemName1] = useState("");
    const [ItemCode2, setItemCode2] = useState("");
    const handleChangeItemCode2 = (event) => {
        setItemCode2(useJwt.validateString(event.target.value, 15));
    };
    const [ItemACode2, setItemACode2] = useState("");
    const handleChangeItemACode2 = (event) => {
        setItemACode2(useJwt.validateNum(event.target.value, 3));
    };
    const [ItemName2, setItemName2] = useState("");
    const onKeyDownItemCode = (event, param1, param2) => {
        if (event.keyCode === 13) { // enter key
            if (WarehouseCompanyCode == "") {
                toast.error("倉庫会社を入力してください。", { autoClose: 3000 });
                return;
            }
            getItemData(event.target.value, param1, param2);
            event.preventDefault();
            if (param1 == 1) {
                if (param2 == 1) {
                    document.getElementById('item_a_code1').focus();
                } else {
                    document.getElementById('item_code2').focus();
                }
            } else {
                if (param2 == 1) {
                    document.getElementById('item_a_code2').focus();
                } else {
                    document.getElementById('type1').focus();
                }
            }
        }
    };
    const getItemData = (value, param1, param2) => {
        if (WarehouseCompanyCode == "") {
            toast.error("倉庫会社を入力してください。", { autoClose: 3000 });
            return;
        }

        let code;
        let acode;
        if (param1 == 1) {
            if (param2 == 1) {
                code = value;
                acode = ItemACode1;
            } else {
                code = ItemCode1;
                acode = value;
            }
        } else {
            if (param2 == 1) {
                code = value;
                acode = ItemACode2;
            } else {
                code = ItemCode2;
                acode = value;
            }
        }

        if (code != "") {
            let data = {};
            data['userID'] = WarehouseCompanyCode;
            data['shipper_id'] = userData['id'];
            data['item_code'] = code;
            if (acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setItemData(result, param1);
                        } else {
                            setItemData([], param1);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setItemData([], param1);
        }
    }
    const setItemData = (data, param) => {
        if (data['item_code']) {
            // setItemCode1(data['item_code']);
            // setItemACode1(data['item_a_code']);
            setItemName(data['item_name']);
        } else {
            setItemCode1("");
            setItemACode1("");
            setItemName("");
        }
    }

    // ================================== result ===============================================
    const [totalStockNum, setTotalStockNum] = useState("");
    const [totalItemNum, setTotalItemNum] = useState("");
    // =================================================================================

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);
        }
    });
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
        if (itemName == "") {
            toast.error("商品名を入力または選択してください。", { autoClose: 3000 });
            return;
        }

        let data = {};
        data['userID'] = userData['id'];
        data['cdate'] = SlipDate;
        data['shipper_user_id'] = userData['id'];
        data['warehouse_id'] = warehouseId;
        data['item_name'] = itemName;

        if (document.getElementById("rot").checked) {
            data['rot'] = "2";
            setRotValue("2")
        } else {
            data['rot'] = "1";
            setRotValue("1")
        }
        gridMainTable([]);
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperStockTotalEndpoint;
        csloading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let result = response.data.data;
                if (result.length > 0) {
                    // let stockNum = 0;
                    // let item_num = 0;
                    // let item_code = "";
                    // result.forEach(data => {
                    //     stockNum += data.stock_num;
                    //     if (item_code != (data.item_code + data.item_a_code)) {
                    //         item_num++;
                    //         item_code = data.item_code + data.item_a_code;
                    //     }
                    // });
                    // setTotalStockNum(useJwt.numberWithCommas(stockNum));
                    // setTotalItemNum(item_num);
                    gridMainTable(result);
                } else {
                    toast.error("検索結果はありません。", { autoClose: 3000 });
                    gridMainTable([]);
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });

    }
    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let data = result;
        setMainTableData(data);
        // for pagenation
        setItemOffset(0);
        const endOffset = 0 + itemsPerPage;
        setCurrentItems(data.slice(0, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }
    // starts pagenation code -----------------------------------------
    const itemsPerPage = 50;
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % mainTableData.length;
        // console.log( `User requested page number ${event.selected}, which is offset ${newOffset}` );
        setItemOffset(newOffset);
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(mainTableData.slice(newOffset, endOffset));
    };
    // ends pagenation code ---------------------------------------------
    // ========================== starts code for search ===============================
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const [WarehouseUrl, setWarehouseUrl] = useState("");
    const [TargetParam, setTargetParam] = useState(1);
    const loadWarehouseModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        setWarehouseUrl(jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseUserByShipperEndpoint);

        // if (code != "") {
        let data = {};
        data['userID'] = userData['id'];
        data['shipper_user_id'] = userData['id'];

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseUserByShipperEndpoint;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        // } else {
        //     setWarehouseTableData([]);
        //     setShowModalWarehouse(true);
        // }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event, param) => {
        event.preventDefault();
        if (WarehouseCompanyCode == "") {
            toast.error("倉庫会社を入力してください。", { autoClose: 3000 });
            return;
        }
        setTargetParam(param);
        let code = ItemCode1;
        let acode = ItemACode1;
        if (param == 2) {
            code = ItemCode2;
            acode = ItemACode2;
        }
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(userData['id']);
        if (code != "") {
            let data = {};
            data['userID'] = WarehouseCompanyCode;
            data['shipper_id'] = userData['id'];
            data['item_code'] = code;
            if (acode != "") {
                data['item_a_code'] = acode;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setItemTableData(response.data.data);
                    setShowModalItem(true);
                })
                .catch((error) => {
                });
        } else {
            setItemTableData([]);
            setShowModalItem(true);
        }
    }

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);

    const onType1KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('type2').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if (type == 1) {
            setType1Open(true);
        } else {
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if (type == 1) {
            setType2Open(true);
        } else {
            setType2Open(false);
        }
    }

    const onSelectData = (e, index) => {
        document.getElementById('searchBut').focus();

        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== starts code for search ===============================
    const [SearchValue, setSearchValue] = useState("");
    const [SearchValue2, setSearchValue2] = useState("");
    const [SearchValue3, setSearchValue3] = useState("");
    const [tableData, setTableData] = useState([]);
    const [shipperCode, setShipperCode] = useState("");
    const [showModal, setShowModal] = useState(false);
    const loadModalData = (event) => {
        event.preventDefault();

        setSearchValue("");
        setSearchValue2(warehouseId);
        setSearchValue3(shipperCode);

        // if (shipperItemCode != "") {
        //     let data = {};
        //     data['userID'] = userData['id'];
        //     data['shipper_item_code'] = shipperItemCode;
        //     if (WarehouseCode != "") {
        //         data['create_user_id'] = WarehouseCode;
        //     }
        //     if (shipperCode != "") {
        //         data['shipper_code'] = shipperCode;
        //     }
        //     data['searchMethod'] = "1"; // here use

        //     let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperMstItemEndpoint;
        //     axios
        //         .post(url, data, {
        //             headers: {
        //                 Authorization: "Bearer " + useJwt.getToken(),
        //             },
        //         })
        //         .then((response) => {
        //             setTableData(response.data.data);
        //             setShowModal(true);
        //         })
        //         .catch((error) => {
        //         });
        // } else {
        setTableData([]);
        setShowModal(true);
        // }
    }

    const [itemName, setItemName] = useState("")
    const handleChangeItemName = (e) => {
        setItemName(e.target.value)
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap2 = [style.p2030];
    const style_input_wrap3 = [style.grp_tbl_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_60px = [gstyle.cs_input, gstyle.w60px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_60px_bl_n = [gstyle.cs_input, gstyle.w60px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_th_total_title = [style.cs_th, style.total_label];
    const style_td_total_label = [style.cs_td, style.total_label];
    const style_td_total_val = [style.cs_td];
    const style_total_input_wrap = [gstyle.p0015, gstyle.mt30];
    const style_input_345px = [gstyle.cs_input, gstyle.w345px];
    const style_input_200px = [gstyle.cs_input, gstyle.w200px];

    const [rotValue, setRotValue] = useState("1")
    const rotChange = (e) => {
        console.log(e.target.value)
    }
    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WarehouseUserModal show={showModalWarehouse} onClose={() => setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} url={WarehouseUrl} onChange={setWarehouseSearchValue}
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} />
            <ItemModal show={showModal} onClose={() => setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} onChange={setSearchValue}
                SearchValue2={SearchValue2} onChange2={setSearchValue2}
                SearchValue3={SearchValue3}
                tableData={tableData} setTableData={setTableData}
                target={setItemData} />

            {/* <ItemModal show={showModalItem} onClose={() => setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} WarehouseId={WarehouseCompanyCode}
                SearchValue2={ItemSearchValue2} SearchValue3={ItemSearchValue3} onChange2={setItemSearchValue2}
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} /> */}
            <div className={gstyle.border}>
                <p className={gstyle.text}>倉庫会社別在庫状況</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 年月日, 荷主, 倉庫 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_2.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker value={SlipDate} onChange={handleChangeSlipDate} focusId='shipper_code' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫会社</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_345px.join(" ")} disabled
                                            value={WarehouseName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadWarehouseModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>商品名</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_200px.join(" ")}
                                            onKeyDown={(e) => onKeyDownItemCode(e, 1)} id='input2'
                                            value={itemName} onChange={handleChangeItemName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <label className={gstyle.cs_label} style={{ marginTop: 20 }}>
                            <div className={gstyle.cs_flex}>
                                <input type={"checkbox"} className={style.cs_input_check} id="rot" />
                                <span>ロット別在庫</span>
                            </div>
                        </label>
                        <div className={style_btn_new_wrap.join(" ")}>
                            <button className={gstyle.btn_new} style={{ marginTop: 17 }} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                        </div>
                    </div>
                    {/* Total */}
                    <div className={style_row.join(" ")}>
                        { /* 検索 */}
                        {/* <div style={{ flex: '1' }}>
                            <div className={style_btn_new_wrap.join(" ")}>
                                <button className={gstyle.btn_new} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                            </div>
                        </div> */}
                        {/* <div className={style_col_3.join(" ")}>
                            <div className={style_total_input_wrap.join(" ")}>
                                <div className={style.cs_tbl}>
                                    <div className={style.cs_thead}>
                                        <div className={style.cs_tr}>
                                            <div className={style_th_total_title.join(" ")}>合計</div>
                                        </div>
                                    </div>
                                    <div className={style.cs_tbody}>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_label.join(" ")}>在庫数</div>
                                            <div className={style_td_total_val.join(" ")}>{totalStockNum}</div>
                                        </div>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_label.join(" ")}>商品数</div>
                                            <div className={style_td_total_val.join(" ")}>{totalItemNum}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className={style.nw_container}>
                        { /* Table */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th >倉庫会社</th>
                                                <th >品名</th>
                                                {
                                                    rotValue == "2" &&
                                                    <>
                                                        <th >倉庫名</th>
                                                        <th >ロットコード</th>
                                                    </>
                                                }
                                                <th >入数</th>
                                                <th >ケース数</th>
                                                <th >バラ数</th>
                                                <th >数量</th>
                                            </tr>
                                        </thead>
                                        <tbody id="tbody_wrap">
                                            {currentItems.map((data, index) => (
                                                <tr key={index}>
                                                    <td >{data['company_name']}</td>
                                                    <td >{data['item_name']}</td>
                                                    {
                                                        rotValue == "2" &&
                                                        <>
                                                            <td className={style.td_right}>{data['warehouse_name']}</td>
                                                            <td className={style.td_right}>{data['rot_code']}</td>
                                                        </>
                                                    }
                                                    <td className={style.td_right}>{data['item_quantity']}</td>
                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data['caseAmount'])}</td>
                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data['BaraAmount'])}</td>
                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data['amount'])}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {mainTableData.length > 0 && (
                            <div className={style_row.join(" ")}>
                                <div className={style_col_12.join(" ")}>
                                    <div className={style.nw_title_wrap}>
                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default StocksByWarehouse;