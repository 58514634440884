import  { React, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../sidebar/ShipperSidebar";
import style from './Layout.module.css';
import Header from '../header/Header';
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import { useNavigate } from "react-router-dom";

const ShipperLayout = () => {
	const navigate = useNavigate();
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);
	const authentication = useJwt.isShipperUserLoggedIn();
	useEffect(() => {
		if(authentication !== true)
			navigate("/login");
	});
    return (
		authentication === true?
			(<div className={style.wrapper}>
				<div className={isSidebarOpen ? style.sidebar_wrapper_open : style.sidebar_wrapper_close}>
				<Sidebar 
						isSidebarOpen={isSidebarOpen}
						setIsSidebarOpen={setIsSidebarOpen}
					/>
				</div>
				<div className={isSidebarOpen ? style.sidebar_padding_open : style.sidebar_padding_close}></div>
				<div className={isSidebarOpen ? style.main_wrapper_shrink : style.main_wrapper_spread}>
					<div className={style.header_padding}></div>
					<div className={style.header_wrapper}>
						<Header
						isSidebarOpen={isSidebarOpen}
						setIsSidebarOpen={setIsSidebarOpen}					
						/>
					</div>
					<div className={isSidebarOpen ? style.outlet_wrapper : style.outlet_wrapper_moved}>
						<Outlet />
					</div>
				</div>
			</div>)
		:
		(<div className={style.wrapper}></div>)
    )
}

export default ShipperLayout;
