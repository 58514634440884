/* eslint-disable eqeqeq */
import style from './StockBalanceSetting.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import IconSearch from '../../../components/common/icons/icon_search.png';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import StartTableModal from '../../../components/common/modal/TableModal';
import InputDatepicker from '../../../components/common/input/InputDatepicker';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select} from 'antd';

const StockBalanceSetting = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [DeadlineDate, setDeadlineDate] = useState("");
    const handleChangeDeadlineDate = (date) => {
        setDeadlineDate(formatDate(date));
    };
    
    // shipper ---------------------------------------------------
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>6) {
            str = str.substring(0, 6);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setShipperCode(str);
    };
    const [ShipperACode, setShipperACode] = useState("");
    const handleChangeShipperACode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>3) {
            str = str.substring(0, 3);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setShipperACode(str);
    };
    const [ShipperName, setShipperName] = useState("");
    const onKeyDownShipperCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            if(param == 1) {
                getShipperData(event.target.value,ShipperACode);
                document.getElementById('input3').focus();
            }else{
                getShipperData(ShipperCode,event.target.value);
                document.getElementById('input4').focus();
            }
            event.preventDefault();
        }
    };
    const getShipperData = (code, acode) => {
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result);
                        let data = {};
                        data['shipper_code'] = result['shipper_code'];
                        data['warehouse_code'] = WarehouseCode;
                        if(WarehouseCode != "") {
                            data['table_code'] = LocationNo;
                        }
                        if(SelectItemType==1){
                            data['item_code'] = ItemCode
                            data['item_a_code'] = ItemACode;
                        }else{
                            data['item_code'] = '';
                            data['item_a_code'] = '';
                        }
                        getStockBalanceSettingData(data);
                    }else{
                        setShipperData([]);
                        gridMainTable([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([]);
            gridMainTable([]);
        }
    }
    // warehouse --------------------------------------------------
    const [WarehouseCode, setWarehouseCode] = useState("");
    const handleChangeWarehouseCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>2) {
            str = str.substring(0, 2);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setWarehouseCode(str);
    };
    const [WarehouseName, setWarehouseName] = useState("");
    const onKeyDownWarehouseCode = (event) => {
        if(event.keyCode === 13) { // enter key
            document.getElementById('input5').focus();
            getWarehouseData(event.target.value);
            event.preventDefault();
        }
    };
    const getWarehouseData = (code) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result);
                        let data = {};
                        data['shipper_code'] = ShipperCode;
                        data['warehouse_code'] = result['warehouse_code'];
                        if(WarehouseCode != "") {
                            data['table_code'] = LocationNo;
                        }
                        if(SelectItemType==1){
                            data['item_code'] = ItemCode
                            data['item_a_code'] = ItemACode;
                        }else{
                            data['item_code'] = '';
                            data['item_a_code'] = '';
                        }
                        getStockBalanceSettingData(data);
                    }else{
                        setWarehouseData([]);
                        setLocationData([]);
                        setLocationNo("");
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setWarehouseData([]);
            setLocationData([]);
            setLocationNo("");
        }
    }
    const setWarehouseData = (data) => {
        if(data['warehouse_code']) {
            setWarehouseCode(data['warehouse_code']);
            setWarehouseName(data['warehouse_name']);
        }else{
            setWarehouseName("");
        }
    }
    // location ------------------------------------------------
    const [LocationNo, setLocationNo] = useState("");
    const handleChangeLocationNo = (event) => {
        setLocationNo(event.target.value);
    };
    const [LocationData, setLocationData] = useState([]);
    // item ----------------------------------------------------
    const [SelectItemType, setSelectItemType] = useState("");
    const handleChangeSelectItemType = (event) => {
        setSelectItemType(event);
        let data = {};
        data['shipper_code'] = ShipperCode;
        data['warehouse_code'] = WarehouseCode;
        if(WarehouseCode != "") {
            data['table_code'] = LocationNo;
        }
        if(event == 1){
            data['item_code'] = ItemCode
            data['item_a_code'] = ItemACode;
            document.getElementById('item_wrap').style.display='block';
        }else{
            data['item_code'] = '';
            data['item_a_code'] = '';
            setItemData([]);
            document.getElementById('item_wrap').style.display='none';
        }
        // getStockBalanceSettingData(data);
    };

    const [ItemCode, setItemCode] = useState("");
    const handleChangeItemCode = (event) => {
        let str = event.target.value;
        if(str.length>15) {
            str = str.substring(0, 15);
        }
        setItemCode(str);
    };
    const [ItemACode, setItemACode] = useState("");
    const handleChangeItemACode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>3) {
            str = str.substring(0, 3);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setItemACode(str);
    };
    const onKeyDownItemCode = (event, index) => {
        if(event.keyCode === 13) { // enter key
            if(index == 1) {
                document.getElementById('input8').focus();
                getItemData(ShipperCode,event.target.value,ItemACode);
            }else{
                document.getElementById('table_input0').focus();
                getItemData(ShipperCode,ItemCode,event.target.value);
            }
            event.preventDefault();
        }
    };
    const [ItemName, setItemName] = useState("");

    const getItemData = (shipper_code, item_code, item_a_code) => {
        if(item_code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = item_code;
            if(shipper_code != "") {
                data['shipper_code'] = shipper_code;
            }
            if(item_a_code != "") {
                data['item_a_code'] = item_a_code;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result);
                        let data = {};
                        data['shipper_code'] = shipper_code;
                        data['warehouse_code'] = WarehouseCode;
                        if(WarehouseCode != "") {
                            data['table_code'] = LocationNo;
                        }
                        data['item_code'] = result['item_code'];
                        data['item_a_code'] = result['item_a_code'];
                        
                        getStockBalanceSettingData(data);
                    }else{
                        setItemData([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([]);
        }
    }
    const setItemData = (data) => {
        if(data['item_code']) {
            setItemCode(data['item_code']);
            setItemACode(data['item_a_code']);
            setItemName(data['item_name']);
        }else{
            setItemName("");
        }
    }
    // table ----------------------------------------------------
    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        let data = [];
        for(var i=0; i<result.length; i++) {
            let row = {};
            row = result[i];
            if(row['stock_balance'] ==null ) {
                row['stock_balance'] = "";
            }
            data[i] = row;
        }
        setMainTableData(data);
    }
    const formatDate = (date) => {
        let year = date.getFullYear();
        let month = '' + (date.getMonth()*1 + 1);
        let day = '' + date.getDate();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return  year + '-' + month + '-' + day;
    }
    const handlChangeData = (event, control_type, col , index) => {
        let value = '';
        value = event.target.value;
        var regexp = /^(\d{0,11})?$/;
        if(value != "" ) {
            if(!regexp.test(value)) {
                toast.error("整数 11 桁 の数字を入力する必要があります。", {autoClose:3000}); 
                value = value.substring(0, value.length-1);
            }
        }
        
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row[col] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }
 
    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_start_setting_flag_4'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            document.getElementById('item_wrap').style.display='none';
            setLoaded(true);
        }
	});

    const getStockBalanceSettingData = (filter) => {
        let data = {};
        data['userID'] = userData['id'];
        if(filter['shipper_code'] != null) {
            data['shipper_code'] = filter['shipper_code'];
            if(filter['warehouse_code'] != null && filter['warehouse_code'] != "") {
                data['warehouse_code'] = filter['warehouse_code'];
            }
            if(filter['table_code'] != null && filter['table_code'] > 0 ) {
                data['table_code'] = filter['table_code'];
            }
            if(filter['item_code'] != null && filter['item_code'] != "") {
                data['item_code'] = filter['item_code'];
            }
            if(filter['item_a_code'] != null && filter['item_a_code'] != "") {
                data['item_a_code'] = filter['item_a_code'];
            }
            csloading(true);
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockBalanceSettingsEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data;
                        gridMainTable(result);
                    }else{
                        gridMainTable([]);
                    }
                }else{
                    gridMainTable([]);
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }
            });
        }
    }

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];

        let validate_flg = true;
        let vmsg = "";

        let stockSaveData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            if(DeadlineDate != "") {
                row['deadline_date'] = DeadlineDate;
            }else{
                row['deadline_date'] = formatDate(new Date());
            }
            row['shipper_code'] = mainTableData[i]['shipper_code'];
            row['warehouse_code'] = mainTableData[i]['warehouse_code'];
            row['table_code'] = mainTableData[i]['table_code'];
            row['item_code'] = mainTableData[i]['item_code'];
            row['item_a_code'] = mainTableData[i]['item_a_code'];
            row['rot_code'] = mainTableData[i]['rot_code'];
            if(mainTableData[i]['stock_balance'] != null && mainTableData[i]['stock_balance'] != "") {
                row['stock_balance'] = mainTableData[i]['stock_balance'];
            }else{
                vmsg = "在庫数量を入力してください。";       
                validate_flg = false;
                break;
            }

            stockSaveData[i] = row;
        }
        
        if(validate_flg){
            data['data'] = stockSaveData;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setStockBalanceSettingsEndpoint;
            csloading(true);
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data == 1) {
                        toast.success("登録致しました。", {autoClose:3000});
                    }else if(response.data.data == 2){
                        toast.error("すでに登録済みです。", {autoClose:3000});
                    }else{
                        toast.error("登録に失敗しました。", {autoClose:3000});
                    }
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
            });
        }else{
            toast.error(vmsg, {autoClose:3000});  
        }
        setOpen(false);
    }
    // ========================== starts code for search ===============================
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event) => {
        event.preventDefault();

        setShipperSearchValue(ShipperCode);
        setShipperSearchValue2("");

        if(ShipperCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    const setShipperData = (data) => {
        if(data['shipper_code'] != null) {
            setShipperCode(data['shipper_code']);
            setShipperACode(data['shipper_a_code']);
            setShipperName(data['shipper_name']);
        }else{
            setShipperCode("");
            setShipperACode("");
            setShipperName("");
        }
    }
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const loadWarehouseModalData = (event) => {
        event.preventDefault();
        setWarehouseSearchValue(WarehouseCode);

        if(WarehouseCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(WarehouseCode != "") {
                data['warehouse_code'] = WarehouseCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event) => {
        event.preventDefault();
        setItemSearchValue(ItemCode);
        setItemSearchValue2(ItemACode);
        setItemSearchValue3(ShipperCode);
        if(ItemCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = ItemCode;
            if(ItemACode != "") {
                data['item_a_code'] = ItemACode;
            }
            if(ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }

    // -----------------------------start Table modal -------------------------------------
    const [showModalStartTable, setShowModalStartTable] = useState(false);
    const [startTableSearchValue, setStartTableSearchValue] = useState("");
    const [startTableSearchValue2, setStartTableSearchValue2] = useState("");
    const [startTableData, setStartTableData] = useState([]);
    const loadStartTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        var warehouse_code = WarehouseCode;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setStartTableSearchValue(table_code);
        setStartTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setStartTableData(response.data.data);
                setShowModalStartTable(true);
            })
            .catch((error) => {
            });
        }else{
            setStartTableData([]);
            setShowModalStartTable(true);
        }
    }
    const setStartTableCode = (data) => {

        setLocationData(data);
        setLocationNo(data['table_code']);
    }

    const [type1Open, setType1Open] = useState(false);

    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const tableKeyDown = (e, id) =>{
        if(e.keyCode == 13){
            document.getElementById(id).focus();
        }
    }

    
    const onDataKeyDown = (e, param) => {
        if(e.keyCode == 13){
            switch(param){
                case 1 :
                    document.getElementById('input1').focus();
                    break;
                case 2 :
                    document.getElementById('input2').focus();
                    break;
                case 3 :
                    document.getElementById('input3').focus();
                    break;
                case 4 :
                    document.getElementById('input4').focus();
                    break;
                case 5 :
                    document.getElementById('input5').focus();
                    break;
                case 6 :
                    document.getElementById('input6').focus();
                    break;
                case 7 :
                    if(SelectItemType == 1) document.getElementById('input7').focus();
                    else    document.getElementById('table_input0').focus();
                    break;
                    default :
            }
        }
    }
        
    const onSelectData = (e, index)=>{
        
        document.getElementById('registerBut').focus();
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            default:
            break;
        }
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_1 = [style.cs_th, gstyle.cs_col_1];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_td_col_1 = [style.cs_td, gstyle.cs_col_1];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} 
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} />
            <StartTableModal show={showModalStartTable} onClose={()=>setShowModalStartTable(false)} width={'70%'}
                SearchValue={startTableSearchValue} SearchValue2={startTableSearchValue2}  onChange={setStartTableSearchValue} 
                tableData={startTableData} setTableData={setStartTableData}
                target={setStartTableCode} />

            <div className={gstyle.border}>
                <p className={gstyle.text}>商品残数設定処理</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 締切年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>締切年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={DeadlineDate} onChange={handleChangeDeadlineDate} focusId='input2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1)} id='input2'
                                        value={ShipperCode} onChange={handleChangeShipperCode} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2)} id='input3'
                                        value={ShipperACode} onChange={handleChangeShipperACode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="ShipperName"
                                        value={ShipperName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 倉庫コード, ロケーション No. */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownWarehouseCode(e)} id='input4'
                                        value={WarehouseCode} onChange={handleChangeWarehouseCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="WarehouseName"
                                        value={WarehouseName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>棚コード</span>
                                    <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                        <input type={'num'} className={style.cs_tbl_input} 
                                            value={LocationNo} id='input5'
                                            onKeyDown={(e)=>onDataKeyDown(e, 6)}
                                            onChange={(e) => handleChangeLocationNo(e)} />
                                            <div className={gstyle.icon_search_wrap} 
                                                onClick={(e)=>loadStartTableModalData(e)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 商品指定 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>商品指定</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                        value={SelectItemType}
                                        open={type1Open}
                                        onSelect={(e)=>onSelectData(e, 1)}
                                        placeholder="商品指定を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeSelectItemType}
                                        onKeyDown={(e)=>onDataKeyDown(e, 7)}
                                        onFocus={(e) => onFocusType1(e, 1)}
                                        onBlur={(e) => onFocusType1(e, 2)}
                                        filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop:14, width:'100%'}}
                                        id="input6"
                                        options={[
                                            {value:"0", label:'自動表示'},
                                            {value:"1", label:'個別指定'},
                                        ]}
                                    />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 商品 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")} id="item_wrap">
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>商品</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,1)} id='input7'
                                        value={ItemCode} onChange={handleChangeItemCode} />
                                        <input type={'text'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,2)} id='input8'
                                        value={ItemACode} onChange={handleChangeItemACode} />
                                        <input type={'text'} className={gstyle.cs_input} disabled id="ItemName"
                                        value={ItemName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* Table */}
                    <div className={gstyle.hyfiled}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_1.join(" ")}>商品コード</div>
                                    <div className={style_th_col_1.join(" ")}>補助コード</div>
                                    <div className={style.cs_th}>商品名称</div>
                                    <div className={style_th_col_1.join(" ")}>ロット№</div>
                                    <div className={style_th_col_1.join(" ")}>入数</div>
                                    <div className={style_th_col_1.join(" ")}>ケース数</div>
                                    <div className={style_th_col_1.join(" ")}>バラ数</div>
                                    <div className={style_th_col_2.join(" ")}>数量</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['item_code']} 
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['item_a_code']}
                                        </div>
                                        <div className={style.cs_td}>
                                            {data['item_name']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['rot_code']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['item_quantity']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['case_num']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['bara_num']}
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'number'} className={style.cs_tbl_input} 
                                                value={(data['stock_balance'] != null)?data['stock_balance']:''} 
                                                id={'table_input'+index} onKeyDown={(e)=>tableKeyDown(e, 'table_input' + (index+1))}
                                                onChange={(e) => handlChangeData(e,1,'stock_balance',index)} />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id="registerBut" onClick={handleOpen} >登録する</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default StockBalanceSetting;