//====================================================================================================
//	在庫移動処理 -> AdobeXD 23ページ
//	作成日:2022/08/25		最終更新日:2022/08/29
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from "react";
import { useForm } from "react-hook-form";

import style from './MoveInventory.module.css';
// ---------- useForm用コンテキスト ----------
import { RegisterContext } from '../../components/common/tenjin_tokubuchi/register_context/RegisterContext';
// ---------- 見出し ----------
import Headline from '../../components/common/tenjin_tokubuchi/headline/Headline';
// ---------- 一段目 ----------
import MoveInventoryDate from '../../components/tenjin_tokubuchi/move_inventory/01_move_inventory_date/MoveInventoryDate';
import VocherNumber from '../../components/tenjin_tokubuchi/move_inventory/02_voucher_number/VoucherNumber';
import MoveDivision from '../../components/tenjin_tokubuchi/move_inventory/03_move_division/MoveDivision';
import BillingDivision from '../../components/tenjin_tokubuchi/move_inventory/04_billing_division/BillingDivision';
import InquiryNumber from '../../components/tenjin_tokubuchi/move_inventory/05_inquiry_number/InquiryNumber';
// ---------- 二段目 ----------
import ExportWarehouse from '../../components/tenjin_tokubuchi/move_inventory/06_export_warehouse/ExportWarehouse';
import ImportWarehouse from '../../components/tenjin_tokubuchi/move_inventory/07_import_warehouse/ImportWarehouse';
// ---------- 三段目 + 四段目 ----------
import InputTable from '../../components/tenjin_tokubuchi/move_inventory/101_input_table/InputTable';
// ---------- ボタン ----------
import MyButton from '../../components/common/tenjin_tokubuchi/my_button/MyButton';

const MoveInventory = () => {
	const {register, handleSubmit, reset} = useForm();
	const onSubmit = data => console.log(data);
	
	return (
		<div className={ style.base }>
			<Headline text="在庫移動処理" />
			<form className={ style.contents } onSubmit={handleSubmit(onSubmit)}>
				<RegisterContext.Provider value={register}>
					<div className={ style.input_area_1st }>
						<MoveInventoryDate />
						<VocherNumber />
						<MoveDivision />
						<BillingDivision />
						<InquiryNumber />
					</div>
					<div className={ style.input_area_2nd }>
						<ExportWarehouse />
						<ImportWarehouse />
					</div>
					<InputTable />
					<div className={ style.button_area }>
						<MyButton link='#' text='移動する' />
					</div>
				</RegisterContext.Provider>
			</form>
    	</div>
		
	);
};

export default MoveInventory;