/* eslint-disable eqeqeq */
import style from './LeavingItemList.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import CarrierModal from '../../../components/common/modal/CarrierModal';
import DistinationModal from '../../../components/common/modal/DistinationModal';
import StartTableModal from '../../../components/common/modal/TableModal';
import EndTableModal from '../../../components/common/modal/TableModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { Select } from 'antd';

const LeavingItemList = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };

    // slip no -------------------------------------------------
    const [SlipNo1, setSlipNo1] = useState("");
    const handleChangeSlipNo1 = (event) => {
        setSlipNo1(useJwt.validateNum(event.target.value,6));
    };
    const [SlipNo2, setSlipNo2] = useState("");
    const handleChangeSlipNo2 = (event) => {
        setSlipNo2(useJwt.validateNum(event.target.value,6));
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
    };
    const OrderTypeData = [ { value:'0', label:'運送便順' }, { value:'1', label:'届け先順' } ];
    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('warehouse2').focus();
            }else{
                if(WarehouseCode1 != "" && WarehouseCode1 == WarehouseCode2){
                    document.getElementById('location1').focus();
                }else{
                    document.getElementById('carrier_code1').focus();
                }
            }
        }
    };
    const getWarehouseData = (code,param) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result,param);
                    }else{
                        setWarehouseData([],param);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setWarehouseData([],param);
        }
    }
    const setWarehouseData = (data,param) => {
        console.log(data);
        if(data['warehouse_code']) {
            if(param == 1) {
                setWarehouseCode1(data['warehouse_code']);
                setWarehouseName1(data['warehouse_name']);
                if(data['warehouse_code'] == WarehouseCode2) {
                    getLocationData(data);
                }else{
                    setLocationData1([]);
                    setLocationData2([]);
                }
            }else{
                setWarehouseCode2(data['warehouse_code']);
                setWarehouseName2(data['warehouse_name']);
                if(data['warehouse_code'] == WarehouseCode1) {
                    getLocationData(data);
                }else{
                    setLocationData1([]);
                    setLocationData2([]);
                }
            }
            
        }else{
            if(param == 1) {
                setWarehouseCode1("");
                setWarehouseName1("");
                
            }else{
                setWarehouseCode2("");
                setWarehouseName2("");
            }
            setLocationData1([]);
            setLocationData2([]);
        }
    }
    // location no -------------------------------------------------------------
    const [LocationNo1, setLocationNo1] = useState("");
    const handleChangeLocationNo1 = (event) => {
        setLocationNo1(event.target.value);
    };
    const [LocationNo2, setLocationNo2] = useState("");
    const handleChangeLocationNo2 = (event) => {
        setLocationNo2(event.target.value);
    };
    const [LocationData1, setLocationData1] = useState([]);
    const [LocationData2, setLocationData2] = useState([]);
    const getLocationData = (filter) => {
        let location_data = [];
        if(filter['warehouse_code'] != null && filter['warehouse_code'] != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = filter['warehouse_code'];
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstLocationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        location_data = response.data.data;
                        setLocationData1(location_data);
                        setLocationData2(location_data);
                    }
                }
            })
            .catch((error) => {
            });
        }
    }
    // Carrier ----------------------------------------------------------
    const [CarrierCode1, setCarrierCode1] = useState("");
    const handleChangeCarrierCode1 = (event) => {
        setCarrierCode1(useJwt.validateNum(event.target.value,6));
    };
    const [CarrierACode1, setCarrierACode1] = useState("");
    const handleChangeCarrierACode1 = (event) => {
        setCarrierACode1(useJwt.validateNum(event.target.value,3));
    };
    const [CarrierName1, setCarrierName1] = useState("");
    const [CarrierCode2, setCarrierCode2] = useState("");
    const handleChangeCarrierCode2 = (event) => {
        setCarrierCode2(useJwt.validateNum(event.target.value,6));
    };
    const [CarrierACode2, setCarrierACode2] = useState("");
    const handleChangeCarrierACode2 = (event) => {
        setCarrierACode2(useJwt.validateNum(event.target.value,3));
    };
    const [CarrierName2, setCarrierName2] = useState("");
    const onKeyDownCarrierCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getCarrierData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('carrier_a_code1').focus();
                }else{
                    document.getElementById('carrier_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('carrier_a_code2').focus();
                }else{
                    document.getElementById('dis_code1').focus();
                }
            }
        }
    };
    const getCarrierData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = CarrierACode1;
            }else{
                code = CarrierCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = CarrierACode2;
            }else{
                code = CarrierCode2;
                acode = value;
            }
        }
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = code;
            if(acode != "") {
                data['carrier_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setCarrierData(t_data[0],param1);
                }else{
                    setCarrierData([],param1);
                }
            })
            .catch((error) => {
            });
        }else{
            setCarrierData([],param1);
        }
    }
    const setCarrierData = (data,param) => {
        if(data['carrier_code']) {
            if(param == 1) {
                setCarrierCode1(data['carrier_code']);
                setCarrierACode1(data['carrier_a_code']);
                setCarrierName1(data['carrier_name']);
            }else{
                setCarrierCode2(data['carrier_code']);
                setCarrierACode2(data['carrier_a_code']);
                setCarrierName2(data['carrier_name']);
            }
        }else{
            if(param == 1) {
                setCarrierCode1("");
                setCarrierACode1("");
                setCarrierName1("");
            }else{
                setCarrierCode2("");
                setCarrierACode2("");
                setCarrierName2("");
            }
        }
    }
    // Distination -----------------------------------------------------------
    const [DistinationCode1, setDistinationCode1] = useState("");
    const handleChangeDistinationCode1 = (event) => {
        setDistinationCode1(useJwt.validateNum(event.target.value,6));
    };
    const [DistinationACode1, setDistinationACode1] = useState("");
    const handleChangeDistinationACode1 = (event) => {
        setDistinationACode1(useJwt.validateNum(event.target.value,3));
    };
    const [DistinationName1, setDistinationName1] = useState("");
    const [DistinationCode2, setDistinationCode2] = useState("");
    const handleChangeDistinationCode2 = (event) => {
        setDistinationCode2(useJwt.validateNum(event.target.value,6));
    };
    const [DistinationACode2, setDistinationACode2] = useState("");
    const handleChangeDistinationACode2 = (event) => {
        setDistinationACode2(useJwt.validateNum(event.target.value,3));
    };
    const [DistinationName2, setDistinationName2] = useState("");
    
    const onKeyDownDistinationCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getDistinationData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('dis_a_code1').focus();
                }else{
                    document.getElementById('dis_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('dis_a_code2').focus();
                }else{
                    document.getElementById('searchBut').focus();
                }
            }
        }
    };
    const getDistinationData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = DistinationACode1;
            }else{
                code = DistinationCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = DistinationACode2;
            }else{
                code = DistinationCode2;
                acode = value;
            }
        }
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = code;
            if(acode != "") {
                data['distination_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setDistinationData(t_data[0],param1);
                }else{
                    setDistinationData([],param1);
                }
            })
            .catch((error) => {
            });
        }else{
            setDistinationData([],param1);
        }
    }
    const setDistinationData = (data,param) => {
        if(data['distination_code']) {
            if(param == 1) {
                setDistinationCode1(data['distination_code']);
                setDistinationACode1(data['distination_a_code']);
                setDistinationName1(data['distination_name']);
            }else{
                setDistinationCode2(data['distination_code']);
                setDistinationACode2(data['distination_a_code']);
                setDistinationName2(data['distination_name']);
            }
        }else{
            if(param == 1) {
                setDistinationCode1("");
                setDistinationACode1("");
                setDistinationName1("");
            }else{
                setDistinationCode2("");
                setDistinationACode2("");
                setDistinationName2("");
            }
        }
    }
    // warehouse type --------------------------------------------
    const [WarehouseType, setWarehouseType] = useState(0);
    const handleChangeWarehouseType = (event) => {
        setWarehouseType(event);
    };

    const WarehouseTypeData = [ { value:'0', label:'全倉庫' }, { value:'1', label:'倉庫別' } ];
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_daily_flag_15'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];

        data['start_slip_date'] = StartDate;
        data['end_slip_date'] = EndDate;

        if(SlipNo1 != "") {
            data['start_slip_no'] = SlipNo1;
        }
        if(SlipNo2 != "") {
            data['end_slip_no'] = SlipNo2;
        }
        if(WarehouseType == 1) {
            if(WarehouseCode1 != "") {
                data['start_warehouse_code'] = WarehouseCode1;
                if(WarehouseCode1 == WarehouseCode2) {
                    if(LocationNo1 != "") {
                        data['start_table_code'] = LocationNo1;
                    }
                    if(LocationNo2 != "") {
                        data['end_table_code'] = LocationNo2;
                    }
                }
            }
            if(WarehouseCode2 != "") {
                data['end_warehouse_code'] = WarehouseCode2;
            }
        }

        if(CarrierCode1 != "") {
            data['start_carrier_code'] = CarrierCode1;
        }
        if(CarrierCode2 != "") {
            data['end_carrier_code'] = CarrierCode2;
        }
        if(CarrierACode1 != "") {
            data['start_carrier_a_code'] = CarrierACode1;
        }
        if(CarrierACode2 != "") {
            data['end_carrier_a_code'] = CarrierACode2;
        }

        if(DistinationCode1 != "") {
            data['start_distination_code'] = DistinationCode1;
        }
        if(DistinationCode2 != "") {
            data['end_distination_code'] = DistinationCode2;
        }
        if(DistinationACode1 != "") {
            data['start_distination_a_code'] = DistinationACode1;
        }
        if(DistinationACode2 != "") {
            data['end_distination_a_code'] = DistinationACode2;
        }

        data['output_order'] = OrderType;
        
        csloading(true);
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getLeavingItemsEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            setInitData(result);
            if(result.length>0){
                let data = [];
                let itemCodes = [];
                for(let i=0; i<result.length; i++) {
                    let filter_code = '';
                    if(OrderType == 0) {
                        filter_code = ''+result[i]['carrier_code']+'-'+result[i]['carrier_a_code']+'-'+result[i]['carrier_stool_code'];
                    } else if(OrderType == 1) {
                        filter_code = ''+result[i]['distination_code']+'-'+result[i]['distination_a_code'];
                    }
                    if(!itemCodes.includes(filter_code)){
                        itemCodes.push(filter_code);
                    }
                }
                
                let total_num = 0;
                let total_weight = 0;
                let total_volume = 0;
                let sub_total_label = "";
                let total_label = "【総 合 計】";
                if(OrderType == 0) {
                    sub_total_label = "【 運送便計 】";
                } else if(OrderType == 1) {
                    sub_total_label = "【 届け先 】";
                }
                for(let i=0; i<itemCodes.length; i++) {
                    let subtotal_num = 0;
                    let subtotal_weight = 0;
                    let subtotal_volume = 0;
                    let row_cnt = 0;
                    for(let j=0;j<result.length;j++) {
                        let filter_code = '';
                        if(OrderType == 0) {
                            filter_code = ''+result[j]['carrier_code']+'-'+result[j]['carrier_a_code']+'-'+result[j]['carrier_stool_code'];
                        } else if(OrderType == 1) {
                            filter_code = ''+result[j]['distination_code']+'-'+result[j]['distination_a_code'];
                        }
                        if(itemCodes[i] == filter_code) {
                            let tmp_row = result[j];
                            tmp_row['total_flg'] = 0;
                            tmp_row['total_label'] = "";
                            if(row_cnt>0) {
                                if(OrderType == 0) {
                                    tmp_row['carrier_code'] = "";
                                    tmp_row['carrier_a_code'] = "";
                                    tmp_row['carrier_stool_code'] = "";
                                    tmp_row['carrier_name'] = "";
                                } else if(OrderType == 1) {
                                    tmp_row['distination_code'] = "";
                                    tmp_row['distination_a_code'] = "";
                                    tmp_row['distination_name'] = "";
                                }
                            }
                            tmp_row['item_weight'] = (result[j]['unit_weight']*result[j]['item_num']).toFixed(3);
                            tmp_row['item_volume'] = (result[j]['unit_volume']*result[j]['item_num']).toFixed(3);
                            data.push(tmp_row);
                            subtotal_num += (result[j]['item_num']*1);
                            subtotal_weight += (result[j]['item_weight']*1);
                            subtotal_volume += (result[j]['item_volume']*1);
                            row_cnt ++;
                        }
                    }
                    let sub_total_row = {};
                    sub_total_row['total_flg'] = 1;
                    sub_total_row['total_label'] = sub_total_label;
                    sub_total_row['item_num'] = subtotal_num.toFixed(3);
                    sub_total_row['item_weight'] = subtotal_weight.toFixed(3);
                    sub_total_row['item_volume'] = subtotal_volume.toFixed(3);

                    data.push(sub_total_row);
                    total_num += (subtotal_num*1);
                    total_weight += (subtotal_weight*1);
                    total_volume += (subtotal_volume*1);
                }
                let total_row = {};
                total_row['total_flg'] = 1;
                total_row['total_label'] = total_label;
                total_row['item_num'] = total_num.toFixed(3);
                total_row['item_weight'] = total_weight.toFixed(3);
                total_row['item_volume'] = total_volume.toFixed(3);
                data.push(total_row);

                gridMainTable(data);
                setOpend(true);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                gridMainTable([]);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
        
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }
    const [InitData, setInitData] = useState([]);
    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let data = result;
        setMainTableData(data);
        // for pagenation
        setItemOffset(0);
        const endOffset = 0 + itemsPerPage;
        setCurrentItems(data.slice(0, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }
    // starts pagenation code -----------------------------------------
    const itemsPerPage = 50;
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % mainTableData.length;
        // console.log( `User requested page number ${event.selected}, which is offset ${newOffset}` );
        setItemOffset(newOffset);
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(mainTableData.slice(newOffset, endOffset));
    };
    // ends pagenation code ---------------------------------------------
    // ========================== starts code for search ===============================
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWarehouseModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode1;
        if(param==2){
            code = WarehouseCode2;
        }
        setWarehouseSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['warehouse_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ------------------------------- Carrier Modal ------------------------------------
    const [showModalCarrier, setShowModalCarrier] = useState(false);
    const [CarrierSearchValue, setCarrierSearchValue] = useState("");
    const [CarrierSearchValue2, setCarrierSearchValue2] = useState("");
    const [CarrierTableData, setCarrierTableData] = useState([]);
    const loadCarrierModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = CarrierCode1;
        let acode = CarrierACode1;
        if(param == 2) {
            code = CarrierCode2;
            acode = CarrierACode2;
        }
        setCarrierSearchValue(code);
        setCarrierSearchValue2(acode);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = code;
            if(acode != "") {
                data['carrier_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setCarrierTableData(response.data.data);
                setShowModalCarrier(true);
            })
            .catch((error) => {
            });
        }else{
            setCarrierTableData([]);
            setShowModalCarrier(true);
        }
    }
    // ------------------------------- Distination Modal --------------------------------
    const [showModalDistination, setShowModalDistination] = useState(false);
    const [DistinationSearchValue, setDistinationSearchValue] = useState("");
    const [DistinationSearchValue2, setDistinationSearchValue2] = useState("");
    const [DistinationTableData, setDistinationTableData] = useState([]);
    const loadDistinationModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = DistinationCode1;
        let acode = DistinationACode1;
        if(param == 2) {
            code = DistinationCode2;
            acode = DistinationACode2;
        }
        setDistinationSearchValue(code);
        setDistinationSearchValue2(acode);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = code;
            if(acode != "") {
                data['distination_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setDistinationTableData(response.data.data);
                setShowModalDistination(true);
            })
            .catch((error) => {
            });
        }else{
            setDistinationTableData([]);
            setShowModalDistination(true);
        }
    }
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        setCurrentItems(mainTableData);
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText, setCurrentItems, mainTableData]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

                        
    // -----------------------------start Table modal -------------------------------------
    const [showModalStartTable, setShowModalStartTable] = useState(false);
    const [startTableSearchValue, setStartTableSearchValue] = useState("");
    const [startTableSearchValue2, setStartTableSearchValue2] = useState("");
    const [startTableData, setStartTableData] = useState([]);
    const loadStartTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = WarehouseCode1;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setStartTableSearchValue(table_code);
        setStartTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setStartTableData(response.data.data);
                setShowModalStartTable(true);
            })
            .catch((error) => {
            });
        }else{
            setStartTableData([]);
            setShowModalStartTable(true);
        }
    }
    const setStartTableCode = (data) => {

        setLocationData1(data);
        setLocationNo1(data['table_code']);
    }
    
    // -----------------------------end Table modal -------------------------------------
    const [showModalEndTable, setShowModalEndTable] = useState(false);
    const [endTableSearchValue, setEndTableSearchValue] = useState("");
    const [endTableSearchValue2, setEndTableSearchValue2] = useState("");
    const [endTableData, setEndTableData] = useState([]);
    const loadEndTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = WarehouseCode1;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setEndTableSearchValue(table_code);
        setEndTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setEndTableData(response.data.data);
                setShowModalEndTable(true);
            })
            .catch((error) => {
            });
        }else{
            setEndTableData([]);
            setShowModalEndTable(true);
        }
    }
    const setEndTableCode = (data) => {
        setLocationData2(data);
        setLocationNo2(data['table_code']);
    }

    
    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type2').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('warehouse1')){
                    document.getElementById('warehouse1').focus();
                }else{
                    document.getElementById('carrier_code1').focus();
                }
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }
    
    const onSlipNoKeyDown = (e, param) =>{
        if(e.keyCode == 13){
            if(param == 1){
                document.getElementById('slipNo2').focus();
            }else{
                document.getElementById('type1').focus();
            }
        }
    }

    const onLocationKeyDown = (e, param) =>{
        if(e.keyCode == 13){
            if(param == 1){
                document.getElementById('location2').focus();
            }else{
                document.getElementById('carrier_code1').focus();
            }
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap2 = [style.p2030];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <CarrierModal show={showModalCarrier} onClose={()=>setShowModalCarrier(false)} width={'70%'}
                SearchValue={CarrierSearchValue} onChange={setCarrierSearchValue} 
                SearchValue2={CarrierSearchValue2} onChange2={setCarrierSearchValue2}
                tableData={CarrierTableData} setTableData={setCarrierTableData}
                target={setCarrierData} targetParam={TargetParam} />
            <DistinationModal show={showModalDistination} onClose={()=>setShowModalDistination(false)} width={'70%'}
                SearchValue={DistinationSearchValue} onChange={setDistinationSearchValue} 
                SearchValue2={DistinationSearchValue2} onChange2={setDistinationSearchValue2}
                tableData={DistinationTableData} setTableData={setDistinationTableData}
                target={setDistinationData} targetParam={TargetParam} />
            <StartTableModal show={showModalStartTable} onClose={()=>setShowModalStartTable(false)} width={'70%'}
                SearchValue={startTableSearchValue} SearchValue2={startTableSearchValue2}  onChange={setStartTableSearchValue} 
                tableData={startTableData} setTableData={setStartTableData}
                target={setStartTableCode} />
            <EndTableModal show={showModalEndTable} onClose={()=>setShowModalEndTable(false)} width={'70%'}
                SearchValue={endTableSearchValue} SearchValue2={endTableSearchValue2}  onChange={setEndTableSearchValue} 
                tableData={endTableData} setTableData={setEndTableData}
                target={setEndTableCode} />

            <div className={gstyle.border}>
                <p className={gstyle.text}>出荷明細表</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={StartDate} onChange={handleChangeStartDate} id='date2' focusId='date3' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={EndDate} onChange={handleChangeEndDate} id='date3' focusId='slipNo1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 伝票 No */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始伝票 No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        value={SlipNo1} onChange={handleChangeSlipNo1} id='slipNo1' onKeyDown={(e)=>onSlipNoKeyDown(e, 1)}/>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了伝票 No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        value={SlipNo2} onChange={handleChangeSlipNo2} id='slipNo2' onKeyDown={(e)=>onSlipNoKeyDown(e, 2)}/>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 出力順位, 倉庫区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>setType1Open(!type1Open)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={OrderTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>setType2Open(!type2Open)}
                                            placeholder="倉庫区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeWarehouseType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={WarehouseTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫, 棚 */}
                    {WarehouseType == 1 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始倉庫</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownWarehouseCode(e,1)} id='warehouse1'
                                                value={WarehouseCode1} onChange={handleChangeWarehouseCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了倉庫</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownWarehouseCode(e,2)} id='warehouse2'
                                                value={WarehouseCode2} onChange={handleChangeWarehouseCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            {WarehouseCode1 != "" && WarehouseCode1 == WarehouseCode2 && <>
                                <div className={style_col_3.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>開始棚コード</span>
                                            <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                                <input type={'num'} className={style.cs_tbl_input} 
                                                    value={LocationNo1} id='location1' 
                                                    onKeyDown={(e)=>onLocationKeyDown(e, 1)}
                                                    onChange={(e) => handleChangeLocationNo1(e)} />
                                                    <div className={gstyle.icon_search_wrap} 
                                                        onClick={(e)=>loadStartTableModalData(e)}>
                                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                    </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_3.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>終了棚コード</span>
                                            <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                                <input type={'num'} className={style.cs_tbl_input} 
                                                    value={LocationNo2} id='location2'
                                                    onKeyDown={(e)=>onLocationKeyDown(e, 2)}
                                                    onChange={(e) => handleChangeLocationNo2(e)} />
                                                    <div className={gstyle.icon_search_wrap} 
                                                        onClick={(e)=>loadEndTableModalData(e)}>
                                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                    </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </>}
                        </div>
                    }
                    {/* 運送業者 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始運送業者</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={CarrierCode1} id='carrier_code1'
                                            onKeyDown={(e) => onKeyDownCarrierCode(e,1,1)}
                                            onChange={(e) => handleChangeCarrierCode1(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={CarrierACode1} id='carrier_a_code1'
                                        onKeyDown={(e) => onKeyDownCarrierCode(e,1,2)}
                                        onChange={(e) => handleChangeCarrierACode1(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={CarrierName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCarrierModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了運送業者</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={CarrierCode2} id='carrier_code2'
                                            onKeyDown={(e) => onKeyDownCarrierCode(e,2,1)}
                                            onChange={(e) => handleChangeCarrierCode2(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={CarrierACode2} id='carrier_a_code2'
                                        onKeyDown={(e) => onKeyDownCarrierCode(e,2,2)}
                                        onChange={(e) => handleChangeCarrierACode2(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={CarrierName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCarrierModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 届け先 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始届け先</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={DistinationCode1} id='dis_code1'
                                            onKeyDown={(e) => onKeyDownDistinationCode(e,1,1)}
                                            onChange={(e) => handleChangeDistinationCode1(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={DistinationACode1} id='dis_a_code1'
                                        onKeyDown={(e) => onKeyDownDistinationCode(e,1,2)}
                                        onChange={(e) => handleChangeDistinationACode1(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={DistinationName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadDistinationModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了届け先</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={DistinationCode2} id='dis_code2'
                                            onKeyDown={(e) => onKeyDownDistinationCode(e,2,1)}
                                            onChange={(e) => handleChangeDistinationCode2(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={DistinationACode2} id='dis_a_code2'
                                        onKeyDown={(e) => onKeyDownDistinationCode(e,2,2)}
                                        onChange={(e) => handleChangeDistinationACode2(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={DistinationName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadDistinationModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        {/* Title, 発行年月日 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_2.join(" ")}></div>
                            <div className={style_col_8.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>出荷明細表({OrderTypeData[OrderType]['value']})</h2>
                                </div>
                            </div>
                            <div className={style_col_2.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {/* 日付範囲 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_2}>[日付範囲]:</span>
                                            <span className={style.cs_span_2}>
                                                ({StartDate} ~ {EndDate})
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* 出力順位, 倉庫区分 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_2}>[出力順位]:</span>
                                            <span className={style.cs_span_2}>{OrderTypeData[OrderType]['value']}</span>
                                            <span className={style.cs_span_2}>[倉庫区分]:</span>
                                            <span className={style.cs_span_2}>{WarehouseTypeData[WarehouseType]['value']}</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        { /* Table */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <table>
                                        <thead>
                                            <tr>
                                                {OrderType == 0 ? <>
                                                    <th>運送業者ｺｰﾄﾞ</th>
                                                    <th>運送便</th>
                                                </>:<>
                                                    <th>届け先ｺｰﾄﾞ</th>
                                                </>}
                                                <th rowSpan={2}>出庫年月日</th>
                                                <th rowSpan={2}>伝票No</th>
                                                <th>荷送人ｺｰﾄﾞ</th>
                                                <th>荷送人名</th>
                                                <th>荷主ｺｰﾄﾞ</th>
                                                <th>補助</th>
                                                <th>品番</th>
                                                <th rowSpan={2}>数量</th>
                                                <th rowSpan={2}>重量</th>
                                                <th rowSpan={2}>容積</th>
                                            </tr>
                                            <tr>
                                                {OrderType == 0 ? <>
                                                    <th colSpan="2">運送業者名</th>
                                                    <th>届け先ｺｰﾄﾞ</th>
                                                    <th>届け先名</th>
                                                </>:<>
                                                    <th>届け先名</th>
                                                    <th>運送業者ｺｰﾄﾞ</th>
                                                    <th>運送業者名</th>
                                                </>}
                                                <th colSpan="2">荷主名</th>
                                                <th>ﾛｯﾄNo</th>
                                            </tr>
                                        </thead>
                                        <tbody id="tbody_wrap" className={loading?'tbl_hide':'tbl_show'}>
                                            {  currentItems.map((data,index) => (
                                                <React.Fragment key={index}>
                                                    {data['total_flg'] == 0 ?
                                                        <>
                                                            <tr>
                                                                {OrderType == 0 ? <>
                                                                    <td>{(data['carrier_code'] != "" && data['carrier_code'] != "")?
                                                                        data['carrier_code']+'-'+data['carrier_a_code'] : ""
                                                                    }</td>
                                                                    <td>{data['carrier_stool_code']}</td>
                                                                </>:<>
                                                                    <td>{(data['distination_code'] != "" && data['distination_a_code'] != "")?
                                                                        data['distination_code']+'-'+data['distination_a_code'] : ""
                                                                    }</td>
                                                                </>}
                                                                <td rowSpan={2}>{useJwt.formatDate(new Date(data['slip_date']))}</td>
                                                                <td rowSpan={2}>{data['slip_no']}</td>
                                                                <td>{data['consignor_code']} - {data['consignor_a_code']}</td>
                                                                <td className={style.td_left}>{data['consignor_name']}</td>
                                                                <td>{data['shipper_code']}</td>
                                                                <td>{data['shipper_a_code']}</td>
                                                                <td>{data['item_code']} - {data['item_a_code']}</td>
                                                                <td rowSpan={2} className={style.td_right}>{useJwt.numberWithCommas(data['item_num'])}</td>
                                                                <td rowSpan={2} className={style.td_right}>{useJwt.numberWithCommas(data['item_weight'])}</td>
                                                                <td rowSpan={2}>{useJwt.numberWithCommas(data['item_volume'])}</td>
                                                            </tr>
                                                            <tr>
                                                                {OrderType == 0 ? <>
                                                                    <td colSpan="2" className={style.td_left}>{data['carrier_name']}</td>
                                                                    <td>{data['distination_code']} - {data['distination_a_code']}</td>
                                                                    <td className={style.td_left}>{data['distination_name']}</td>
                                                                </>:<>
                                                                    <td className={style.td_left}>{data['distination_name']}</td>
                                                                    <td>{data['carrier_code']} - {data['carrier_a_code']}</td>
                                                                    <td className={style.td_left}>{data['carrier_name']}</td>
                                                                </>}
                                                                <td colSpan="2" className={style.td_left}>{data['shipper_name']}</td>
                                                                <td>{data['rot_code']}</td>
                                                            </tr>
                                                        </>
                                                        :
                                                        <tr className={style.bg_tbl_total}>
                                                            <td colSpan={OrderType == 0 ?9:8} height="52">{data['total_label']}</td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(data['item_num'])}</td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(data['item_weight'])}</td>
                                                            <td>{useJwt.numberWithCommas(data['item_volume'])}</td>
                                                        </tr>
                                                    }
                                                </React.Fragment>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style.nw_title_wrap}>
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'出荷明細表'+OrderTypeData[OrderType]['value']+'_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                                <CSVLink data={mainTableData} filename={'出荷明細表'+OrderTypeData[OrderType]['value']+'_'+useJwt.formatDateTime(new Date())+'.csv'} 
                                className={style.btn_csv}>ﾌｧｲﾙ</CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default LeavingItemList;