/* eslint-disable eqeqeq */
import style from './Bus.module.css';
import gstyle from '../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import { Button, Tooltip, Popconfirm } from 'antd';
import InputDatepicker from '../../components/common/input/InputDatepicker';
import {
    Delete,
    Edit,
  } from "@material-ui/icons";
  import { QuestionCircleOutlined } from '@ant-design/icons';
const BusListIndex = () => {

    const navigate = useNavigate();

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());

    const [loaded, setLoaded] = useState(false);

    const csLoading = (flag) => {
        if(flag) {
            document.getElementById('csLoader').style.display="flex";
        }else{
            document.getElementById('csLoader').style.display="none";
        }
    } 

    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        setMainTableData([]);
        setMainTableData(result);
    }

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['bus_flag_5'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            scheduleSearch();
            setLoaded(true);
        }
	});

    const scheduleEdit = (e, data) => {

        let temp = JSON.parse(data);
        temp['flag'] = 3;
        navigate("/bus/schedule/registry", {
            state: {
              data : temp,
            },
            replace: false,
          });
    }

    const scheduleDelete = (e, sData) => {
        console.log('data')
        let temp = JSON.parse(sData);
        let data = {};
        data['userID'] = userData['id'];
        data['id'] = temp['id'];

        csLoading(true);
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.deleteDriverSchedule;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                console.log('wwww = ',data['id'])
                let totalArray = [];
                for(var i = 0; i < mainTableData.length ; i++){
                    var id = mainTableData[i]['id'];
                    if(id == data['id'])    continue;

                    totalArray.push(mainTableData[i]);
                }
                gridMainTable(totalArray);
            }
            csLoading(false);
        })
        .catch((error) => {
            csLoading(false);
        });
    }

    const handleSearch = ( e ) => {
        scheduleSearch();
    }

    const scheduleSearch = () =>{
        let data = {};
        data['userID'] = userData['id'];
        data['schedule_date'] = scheduleDate;

        csLoading(true);
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getDriverScheduleList;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                gridMainTable(response.data.data);
            }
            csLoading(false);
        })
        .catch((error) => {
            csLoading(false);
        });
    }

    const [scheduleDate, setScheduleDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeScheduleDate = ( date ) => {
        setScheduleDate(useJwt.formatDate(date));
    }
    
    const busRegister = ( e ) => {
        navigate("/bus/schedule/registry");
    }
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_th_col_05 = [style.cs_th, style.wp5, style.td_item_align];
    const style_th_col_1 = [style.cs_th, style.wp10, style.td_item_align];
    const style_th_col_2 = [style.cs_th, style.wp20, style.td_item_align];
    const style_td_col_05 = [style.cs_td, style.wp5, style.td_item_align];
    const style_td_col_1 = [style.cs_td, style.wp10, style.td_item_align];
    const style_td_col_2 = [style.cs_td, style.wp20, style.td_item_align];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csLoader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <div className={gstyle.border}>
                <p className={gstyle.text}>バース一覧</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_2.join(" ")} style={{marginLeft:50}}>
                            <InputDatepicker  value={scheduleDate} onChange={handleChangeScheduleDate} />
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <Button type="default" style={{width:100, marginLeft:30}} onClick={(e) => handleSearch(e)} >検索</Button>
                        </div>
                        <div style={{marginLeft:'auto'}}>
                            <Button type="primary" style={{width:150}} onClick={(e) => busRegister(e)} >バース予約</Button>
                        </div>
                    </div>
                    <div className={gstyle.hyfiled} style={{marginTop:30}}>
                        <div style={{width:'100%'}}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_05.join(" ")}></div>
                                    <div className={style_th_col_1.join(" ")}>予約区分</div>
                                    <div className={style_th_col_1.join(" ")}>予約日</div>
                                    <div className={style_th_col_1.join(" ")}>倉庫名</div>
                                    <div className={style_th_col_1.join(" ")}>バース名</div>
                                    <div className={style_th_col_2.join(" ")}>運送会社名</div>
                                    <div className={style_th_col_1.join(" ")}>車番号</div>
                                    <div className={style_th_col_1.join(" ")}>ドライバー名</div>
                                    <div className={style_th_col_1.join(" ")}>車種</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_05.join(" ")} style={{gap:5}}>
                                            <Tooltip placement="left" title={'編集'}>
                                                <a onClick={(e)=>scheduleEdit(e, JSON.stringify(data))}><Edit/></a>
                                            </Tooltip>
                                            <Tooltip placement="right" title={'削除'}>
                                                <Popconfirm
                                                    title="削除"
                                                    description="データを削除しますか？"
                                                    okText="確認"
                                                    cancelText="閉じる"
                                                    placement="top"
                                                    style={{width:300}}
                                                    onConfirm={(e)=>scheduleDelete(e, JSON.stringify(data))}
                                                    icon={<QuestionCircleOutlined style={{color:'red'}}/>}
                                                >
                                                    <Delete style={{color:'red', cursor:'pointer'}}/>
                                                </Popconfirm>
                                            </Tooltip>
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['work_type'] == 0 ? '入庫' : '出庫'}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['schedule_date']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['warehouse_name']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['bus_name']}
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            {data['carrier_name']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['car_number']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['driver_name']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['car_standard'] == 1 ? '4t' : data['car_standard'] == 2 ? '10t' : 'トレーラー'}
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusListIndex;