//====================================================================================================
//	在庫移動処理 -> AdobeXD 23ページ
//	作成日:2022/08/25		最終更新日:2022/08/26
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
import InputListLabel from '../../../../common/tenjin_tokubuchi/input_table_label/InputTableLabel';
import style from "./Label.module.css";

const LotNameLabel = ()=> {
    return (
        <InputListLabel style={`${style.label}`} label={"ロット名"} />
    );
}

export default LotNameLabel;