/* eslint-disable eqeqeq */
import style from './Item.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import ItemModal from '../../../components/common/modal/ItemModal';
import ItemTypeModal from '../../../components/common/modal/ItemTypeModal';
import CategoryModal from '../../../components/common/modal/CategoryModal';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select} from 'antd';

const Item = () => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    // Item ID
    const [SysItemId, setSysItemId] = useState(0);
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 6) {
            str = str.substring(0, 6);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setShipperCode(str);
    };
    const [ShipperName, setShipperName] = useState("");

    const onKeyDownShipperCode = (event) => {
        if (event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(7, event.target.value)
            setShipperCode(deg)
            getShipperData(event, deg);
            document.getElementById('input2').focus();
        }
    };

    const getShipperData = (event, deg) => {
        if (deg != "") {
            const data = {
                userID: userData['id'],
                shipper_code: deg
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                }).then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setShipperName(result['shipper_name']);
                        } else {
                            setShipperName("");
                        }
                    }
                    event.preventDefault();
                })
                .catch((error) => {
                });
        } else {
            setShipperName("");
            event.preventDefault();
        }
    }

    const [ItemCode, setItemCode] = useState("");
    const handleChangeItemCode = (event) => {
        let str = event.target.value;
        if (str.length > 15) {
            str = str.substring(0, 15);
        }
        setItemCode(str);
    };
    const [ItemACode, setItemACode] = useState("");
    const handleChangeItemACode = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 3) {
            str = str.substring(0, 3);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setItemACode(str);
    };
    const onKeyDownItemCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(16, event.target.value)
            setItemCode(deg)
            getItemData(event, deg);
            if(param == 1){
                document.getElementById('input3').focus();
            }else                document.getElementById('input4').focus();

        }
    };
    const getItemData = (event, deg) => {
        if (deg != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = deg;
            if (ItemACode != "") {
                data['item_a_code'] = ItemACode;
            }
            if (ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if (t_data.length > 0) {
                        setItemData(t_data[0]);
                    } else {
                        setItemData([]);
                    }
                    event.preventDefault();
                })
                .catch((error) => {
                });
        } else {
            setItemData([]);
            event.preventDefault();
        }
    }
    const [ItemName, setItemName] = useState("");
    const handleChangeItemName = (event) => {
        let str = event.target.value;
        if (str.length > 30) {
            str = str.substring(0, 30);
        }
        setItemName(str);
    };
    const [ItemNameKana, setItemNameKana] = useState("");
    const handleChangeItemNameKana = (event) => {
        let str = event.target.value;
        if (str.length > 30) {
            str = str.substring(0, 30);
        }
        setItemNameKana(str);
    };
    const [ItemAName, setItemAName] = useState("");
    const handleChangeItemAName = (event) => {
        let str = event.target.value;
        if (str.length > 30) {
            str = str.substring(0, 30);
        }
        setItemAName(str);
    };

    const [ItemType, setItemType] = useState();
    const handleChangeItemType = (event) => {
        setItemType(event);
    };
    const [ItemSetFlag, setItemSetFlag] = useState();
    const handleChangeItemSetFlag = (event) => {
        setItemSetFlag(event);
    };

    const [ItemQuantity, setItemQuantity] = useState("");
    const handleChangeItemQuantity = (event) => {
        let str = event.target.value;
        var regexp = /^\d{1,6}(\.\d{1,3})?$/;
        if (str != "" && !regexp.test(str)) {
            toast.error("整数 6 桁 小数 3 桁の数字を入力する必要があります。", { autoClose: 3000 });
            str = str.substring(0, str.length - 1);
        }
        setItemQuantity(str);
    };
    const [ItemFractionType, setItemFractionType] = useState();
    const handleChangeItemFractionType = (event) => {
        setItemFractionType(event);
    }
    const [UnitWeight, setUnitWeight] = useState("0.000");
    const handleChangeUnitWeight = (event) => {
        let str = event.target.value;
        var regexp = /^\d{1,6}(\.\d{1,3})?$/;
        if (str != "" && !regexp.test(str)) {
            toast.error("整数 6 桁 小数 3 桁の数字を入力する必要があります。", { autoClose: 3000 });
            str = str.substring(0, str.length - 1);
        }
        setUnitWeight(str);
    }
    const [UnitWeightBara, setUnitWeightBara] = useState("0.000");
    const handleChangeUnitWeightBara = (event) => {
        let str = event.target.value;
        var regexp = /^\d{1,6}(\.\d{1,3})?$/;
        if (str != "" && !regexp.test(str)) {
            toast.error("整数 6 桁 小数 3 桁の数字を入力する必要があります。", { autoClose: 3000 });
            str = str.substring(0, str.length - 1);
        }
        setUnitWeightBara(str);
    }
    const [UnitVolume, setUnitVolume] = useState("0.000");
    const handleChangeUnitVolume = (event) => {
        let str = event.target.value;
        var regexp = /^\d{1,6}(\.\d{1,3})?$/;
        if (str != "" && !regexp.test(str)) {
            toast.error("整数 6 桁 小数 3 桁の数字を入力する必要があります。", { autoClose: 3000 });
            str = str.substring(0, str.length - 1);
        }
        setUnitVolume(str);
    }
    const [UnitVolumeBara, setUnitVolumeBara] = useState("0.000");
    const handleChangeUnitVolumeBara = (event) => {
        let str = event.target.value;
        var regexp = /^\d{1,6}(\.\d{1,3})?$/;
        if (str != "" && !regexp.test(str)) {
            toast.error("整数 6 桁 小数 3 桁の数字を入力する必要があります。", { autoClose: 3000 });
            str = str.substring(0, str.length - 1);
        }
        setUnitVolumeBara(str);
    }
    const [ItemUnit, setItemUnit] = useState("");
    const handleChangeItemUnit = (event) => {
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        setItemUnit(str);
    }
    const [StockCalType, setStockCalType] = useState("");
    const handleChangeStockCalType = (event) => {
        setStockCalType(event);
    }
    const [InvoiceCalType, setInvoiceCalType] = useState("");
    const handleChangeInvoiceCalType = (event) => {
        setInvoiceCalType(event);
    }
    const [TaxNoData, setTaxNoData] = useState([{ value: 0, label: '非課税' }]);
    const [TaxNo, setTaxNo] = useState(0);
    const handleChangeTaxNo = (event) => {
        setTaxNo(event);
    };
    const [FirstArrivalDate, setFirstArrivalDate] = useState("");
    const handleChangeFirstArrivalDate = (date) => {
        setFirstArrivalDate(formatDate(date));
    };
    const formatDate = (date) => {
        let year = date.getFullYear();
        let month = '' + (date.getMonth() * 1 + 1);
        let day = '' + date.getDate();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return year + '-' + month + '-' + day;
    }
    const [ItemDeadlineDate, setItemDeadlineDate] = useState("");
    const handleChangeItemDeadlineDate = (date) => {
        setItemDeadlineDate(formatDate(date));
    };

    const [ItemTypeCode, setItemTypeCode] = useState("");
    const handleChangeItemTypeCode = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 3) {
            str = str.substring(0, 3);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setItemTypeCode(str);
    };
    const [ItemTypeName, setItemTypeName] = useState("");

    const [CategoryCode1, setCategoryCode1] = useState("");
    const [CategoryName1, setCategoryName1] = useState("");
    const [CategoryCode2, setCategoryCode2] = useState("");
    const [CategoryName2, setCategoryName2] = useState("");
    const [CategoryCode3, setCategoryCode3] = useState("");
    const [CategoryName3, setCategoryName3] = useState("");
    const handleChangeCategoryCode = (event, param) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 3) {
            str = str.substring(0, 3);
        }
        if (!z1.test(str)) {
            str = "";
        }
        if (param == 1) {
            setCategoryCode1(str);
        } else if (param == 2) {
            setCategoryCode2(str);
        } else {
            setCategoryCode3(str);
        }
    };

    const [PriceUpdateDate, setPriceUpdateDate] = useState("");
    const handleChangePriceUpdateDate = (date) => {
        setPriceUpdateDate(formatDate(date));
    };

    const [StorageFeeRate, setStorageFeeRate] = useState("0.000");
    const handleChangeStorageFeeRate = (event) => {
        let str = event.target.value;
        var regexp = /^\d{1,7}(\.\d{1,3})?$/;
        if (str != "" && !regexp.test(str)) {
            toast.error("整数 6 桁 小数 3 桁の数字を入力する必要があります。", { autoClose: 3000 });
            str = str.substring(0, str.length - 1);
        }
        setStorageFeeRate(str);
    }
    const [ArrivalCargoHandlingFeeRate, setArrivalCargoHandlingFeeRate] = useState("0.000");
    const handleChangeArrivalCargoHandlingFeeRate = (event) => {
        let str = event.target.value;
        var regexp = /^\d{1,7}(\.\d{1,3})?$/;
        if (str != "" && !regexp.test(str)) {
            toast.error("整数 6 桁 小数 3 桁の数字を入力する必要があります。", { autoClose: 3000 });
            str = str.substring(0, str.length - 1);
        }
        setArrivalCargoHandlingFeeRate(str);
    }
    const [LeavingCargoHandlingFeeRate, setLeavingCargoHandlingFeeRate] = useState("0.000");
    const handleChangeLeavingCargoHandlingFeeRate = (event) => {
        let str = event.target.value;
        var regexp = /^\d{1,7}(\.\d{1,3})?$/;
        if (str != "" && !regexp.test(str)) {
            toast.error("整数 6 桁 小数 3 桁の数字を入力する必要があります。", { autoClose: 3000 });
            str = str.substring(0, str.length - 1);
        }
        setLeavingCargoHandlingFeeRate(str);
    }
    const [StockCalPrice, setStockCalPrice] = useState("0.000");
    const handleChangeStockCalPrice = (event) => {
        let str = event.target.value;
        var regexp = /^\d{1,7}(\.\d{1,3})?$/;
        if (str != "" && !regexp.test(str)) {
            toast.error("整数 6 桁 小数 3 桁の数字を入力する必要があります。", { autoClose: 3000 });
            str = str.substring(0, str.length - 1);
        }
        setStockCalPrice(str);
    }

    const setItemData = (result) => {
        if (result['sys_item_id']) {
            setSysItemId(result['sys_item_id']);
            setShipperCode(result['shipper_code']);

            const data = {
                userID: userData['id'],
                shipper_code: result['shipper_code']
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                }).then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setShipperName(result['shipper_name']);
                        }
                    }
                })
                .catch((error) => {
                });

            setItemCode(result['item_code']);
            if (result['item_a_code'] != null) {
                setItemACode(result['item_a_code']);
            }
            if (result['item_name'] != null) {
                setItemName(result['item_name']);
            }
            if (result['item_a_name'] != null) {
                setItemAName(result['item_a_name']);
            }
            if (result['item_name_kana'] != null) {
                setItemNameKana(result['item_name_kana']);
            }
            if (result['item_type'] != null) {
                setItemType(result['item_type']);
            }
            if (result['item_set_flag'] != null) {
                setItemSetFlag(result['item_set_flag']);
            }
            if (result['item_quantity'] != null) {
                setItemQuantity(result['item_quantity']);
            }
            if (result['item_fraction_type'] != null) {
                setItemFractionType(result['item_fraction_type']);
            }
            if (result['unit_weight'] != null) {
                setUnitWeight(result['unit_weight']);
            }
            if (result['unit_weight_bara'] != null) {
                setUnitWeightBara(result['unit_weight_bara']);
            }
            if (result['unit_volume'] != null) {
                setUnitVolume(result['unit_volume']);
            }
            if (result['unit_volume_bara'] != null) {
                setUnitVolumeBara(result['unit_volume_bara']);
            }
            if (result['item_unit'] != null) {
                setItemUnit(result['item_unit']);
            }
            if (result['stock_cal_type'] != null) {
                setStockCalType(result['stock_cal_type'] ? result['stock_cal_type'] : "");
            }
            if (result['invoice_cal_type'] != null) {
                setInvoiceCalType(result['invoice_cal_type'] ? result['invoice_cal_type'] : "");
            }
            if (result['tax_no'] != null) {
                setTaxNo(result['tax_no']);
            }
            if (result['first_arrival_date'] != null) {
                setFirstArrivalDate(result['first_arrival_date']);
            }
            if (result['item_deadline_date'] != null) {
                setItemDeadlineDate(result['item_deadline_date']);
            }

            setItemTypeCode(result['item_type_code']);
            const data2 = {
                userID: userData['id'],
                item_type_code: result['item_type_code']
            };
            let url2 = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
                .post(url2, data2, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                }).then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result1 = response.data.data[0];
                            setItemTypeName(result1['item_type_name']);
                        }
                    }
                })
                .catch((error) => {
                });

            if (result['category_code_1'] != null) {
                setCategoryCode1(result['category_code_1']);
                const data3 = {
                    userID: userData['id'],
                    category_code: result['category_code_1']
                };
                let url3 = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
                axios
                    .post(url3, data3, {
                        headers: {
                            Authorization: "Bearer " + useJwt.getToken(),
                        },
                    }).then((response) => {
                        if (response.status == 200) {
                            if (response.data.data.length > 0) {
                                let result1 = response.data.data[0];
                                setCategoryName1(result1['category_name']);
                            }
                        }
                    })
                    .catch((error) => {
                    });
            }
            if (result['category_code_2'] != null) {
                setCategoryCode2(result['category_code_2']);
                const data3 = {
                    userID: userData['id'],
                    category_code: result['category_code_2']
                };
                let url3 = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
                axios
                    .post(url3, data3, {
                        headers: {
                            Authorization: "Bearer " + useJwt.getToken(),
                        },
                    }).then((response) => {
                        if (response.status == 200) {
                            if (response.data.data.length > 0) {
                                let result1 = response.data.data[0];
                                setCategoryName2(result1['category_name']);
                            }
                        }
                    })
                    .catch((error) => {
                    });
            }
            if (result['category_code_3'] != null) {
                setCategoryCode3(result['category_code_3']);
                const data3 = {
                    userID: userData['id'],
                    category_code: result['category_code_3']
                };
                let url3 = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
                axios
                    .post(url3, data3, {
                        headers: {
                            Authorization: "Bearer " + useJwt.getToken(),
                        },
                    }).then((response) => {
                        if (response.status == 200) {
                            if (response.data.data.length > 0) {
                                let result1 = response.data.data[0];
                                setCategoryName3(result1['category_name']);
                            }
                        }
                    })
                    .catch((error) => {
                    });
            }
            if (result['price_update_date'] != null && result['price_update_date'] != '0000-00-00 00:00:00') {
                setPriceUpdateDate(result['price_update_date']);
            }

            if (result['storage_fee_rate'] != null) {
                setStorageFeeRate(result['storage_fee_rate']);
            }
            if (result['arrival_cargo_handling_fee_rate'] != null) {
                setArrivalCargoHandlingFeeRate(result['arrival_cargo_handling_fee_rate']);
            }
            if (result['leaving_cargo_handling_fee_rate'] != null) {
                setLeavingCargoHandlingFeeRate(result['leaving_cargo_handling_fee_rate']);
            }
            if (result['stock_cal_price'] != null) {
                setStockCalPrice(result['stock_cal_price']);
            }
        } else {
            setSysItemId(0);
            setItemName(""); setItemAName(""); setItemNameKana("");
            setItemType(null); setItemSetFlag(null); setItemQuantity("0.000"); setItemFractionType(null);
            setUnitWeight("0.000"); setUnitWeightBara("0.000");
            setUnitVolume("0.000"); setUnitVolumeBara("0.000"); setItemUnit("");
            setStockCalType(""); setInvoiceCalType(""); setTaxNo("");
            setFirstArrivalDate(""); setItemDeadlineDate("");
            setItemTypeCode(""); setCategoryCode1(""); setCategoryCode2(""); setCategoryCode3("");
            setPriceUpdateDate(""); setStorageFeeRate("0.000"); setArrivalCargoHandlingFeeRate("0.000");
            setLeavingCargoHandlingFeeRate("0.000"); setStockCalPrice("0.000");
        }
    }

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            if (menuPatternData['menu_master_flag_7'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            const data = {
                userID: userData['id']
            };

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstTaxEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setLoaded(true);
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            let TaxNoDataAry = [];
                            TaxNoDataAry = [
                                { key: 0, value: '非課税' },
                                { key: 1, value: result['tax_1'] },
                                { key: 2, value: result['tax_2'] },
                                { key: 3, value: result['tax_3'] },
                                { key: 4, value: result['tax_4'] },
                                { key: 5, value: result['tax_5'] },
                                { key: 6, value: result['tax_6'] },
                                { key: 7, value: result['tax_7'] },
                                { key: 8, value: result['tax_8'] },
                                { key: 9, value: result['tax_9'] }
                            ];
                            setTaxNoData(TaxNoDataAry);
                        }
                        /*
                        let url2 = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
                        axios
                        .post(url2, data, {
                            headers: {
                            Authorization: "Bearer " + useJwt.getToken(),
                            },
                        }).then((response2) => {
                            if(response2.status == 200) {
                                if(response2.data.data.length>0) {
                                    let result2 = response2.data.data[0];
                                    setItemData(result2);
                                }
                            }
                        })
                        .catch((error) => {
                        });
                        */
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status && error.response.status === 401) {
                        useJwt.logout();
                        navigate("/login");
                    }
                });
        }
    });

    const saveData = (event) => {
        setOpen(true);

        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if (SysItemId > 0) {
            data['sys_item_id'] = SysItemId;
        }

        if (ShipperCode != "") {
            data['shipper_code'] = ShipperCode;
        } else {
            toast.error("荷主コードを入力してください。", { autoClose: 3000 });       // please enter code
            setOpen(false);
            return;
        }
        if (ItemCode != "") {
            data['item_code'] = ItemCode;
        } else {
            toast.error("品番を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        data['item_a_code'] = ItemACode;
        data['shipper_item_code'] = "";
        if (ItemName != "") {
            data['item_name'] = ItemName;
        } else {
            toast.error("名称を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (ItemAName != "") {
            data['item_a_name'] = ItemAName;
        }
        if (ItemNameKana != "") {
            data['item_name_kana'] = ItemNameKana;
        } else {
            toast.error("フリガナを入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        data['item_type'] = ItemType;
        data['item_set_flag'] = ItemSetFlag;
        if (ItemQuantity != "") {
            data['item_quantity'] = ItemQuantity;
        } else {
            toast.error("入数を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (ItemSetFlag == 1 && ItemQuantity > 0) {
            data['item_fraction_type'] = ItemFractionType;
        } else {
            data['item_fraction_type'] = 0;
        }

        if (UnitWeight != "") {
            data['unit_weight'] = UnitWeight;
        } else {
            toast.error("単位重量を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        data['unit_weight_bara'] = UnitWeightBara;
        if (UnitVolume != "") {
            data['unit_volume'] = UnitVolume;
        } else {
            toast.error("単位容積を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        data['unit_volume_bara'] = UnitVolumeBara;
        if (ItemUnit != "") {
            data['item_unit'] = ItemUnit;
        } else {
            toast.error("単位を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        data['stock_cal_type'] = StockCalType;
        data['invoice_cal_type'] = InvoiceCalType;
        data['tax_no'] = TaxNo;
        data['first_arrival_date'] = FirstArrivalDate;
        data['item_deadline_date'] = ItemDeadlineDate;
        if (ItemTypeCode != "") {
            data['item_type_code'] = ItemTypeCode;
        } else {
            toast.error("品種コードを入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        data['category_code_1'] = CategoryCode1;
        data['category_code_2'] = CategoryCode2;
        data['category_code_3'] = CategoryCode3;
        if (PriceUpdateDate != "") {
            data['price_update_date'] = PriceUpdateDate;
        } else {
            toast.error("価格改定日を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        data['price_update_end_date'] = "";
        // if (ItemSetFlag == 1 && ItemQuantity > 0 && ItemFractionType == 4) {
            data['storage_fee_rate'] = StorageFeeRate;
            data['arrival_cargo_handling_fee_rate'] = ArrivalCargoHandlingFeeRate;
            data['leaving_cargo_handling_fee_rate'] = LeavingCargoHandlingFeeRate;
            data['stock_cal_price'] = StockCalPrice;
        // } else {
        //     data['storage_fee_rate'] = "0.000";
        //     data['arrival_cargo_handling_fee_rate'] = "0.000";
        //     data['leaving_cargo_handling_fee_rate'] = "0.000";
        //     data['stock_cal_price'] = "0.000";
        // }

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstItemEndpoint;
        csloading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if (response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if (response.data.data == 1) {
                        toast.success("登録致しました。", { autoClose: 3000 });
                    } else if (response.data.data == 2) {
                        toast.error("すでに登録済みです。", { autoClose: 3000 });
                    } else {
                        toast.error("登録に失敗しました。", { autoClose: 3000 });
                    }

                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if (error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                } else {
                    toast.error("登録に失敗しました。", { autoClose: 3000 });
                }
            });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if (SysItemId > 0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        if (SysItemId > 0) {
            let data = {};
            data['userID'] = userData['id'];
            data['sys_item_id'] = SysItemId;
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.delMstItemEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    if (response.status == 200) {
                        setItemCode("");
                        setItemData([]);
                    }
                })
                .catch((error) => {
                });

            setItemCode("");
            setItemData([]);
        }
    }
    // ========================== starts code for search ===============================
    const [SearchValue, setSearchValue] = useState("");
    const [SearchValue2, setSearchValue2] = useState("");
    const [SearchValue3, setSearchValue3] = useState("");
    const [tableData, setTableData] = useState([]);
    const loadModalData = (event) => {
        event.preventDefault();

        setSearchValue(ItemCode);
        setSearchValue2(ItemACode);
        setSearchValue3(ShipperCode);

        if (ItemCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = ItemCode;
            if (ItemACode != "") {
                data['item_a_code'] = ItemACode;
            }
            if (ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setTableData(response.data.data);
                    setShowModal(true);
                })
                .catch((error) => {
                });
        } else {
            setTableData([]);
            setShowModal(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event) => {
        event.preventDefault();

        setShipperSearchValue(ShipperCode);
        setShipperSearchValue2("");

        if (ShipperCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            if (ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }

            

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setShipperTableData(response.data.data);
                    setShowModalShipper(true);
                })
                .catch((error) => {
                });
        } else {
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    const setShipperData = (data) => {
        setShipperCode(data['shipper_code']);
        setShipperName(data['shipper_name']);
    }
    // ----------------------------- item type modal -----------------------------------
    const [showModalItemType, setShowModalItemType] = useState(false);
    const [ItemTypeSearchValue, setItemTypeSearchValue] = useState("");
    const [ItemTypeTableData, setItemTypeTableData] = useState([]);
    const loadItemTypeModalData = (event) => {
        event.preventDefault();
        setItemTypeSearchValue(ItemTypeCode);

        if (ItemTypeCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            if (ItemTypeCode != "") {
                data['item_type_code'] = ItemTypeCode;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setItemTypeTableData(response.data.data);
                    setShowModalItemType(true);
                })
                .catch((error) => {
                });
        } else {
            setItemTypeTableData([]);
            setShowModalItemType(true);
        }
    }
    const setItemTypeData = (data) => {
        setItemTypeCode(data['item_type_code']);
        setItemTypeName(data['item_type_name']);
    }
    // ----------------------------- category modal -------------------------------------
    const [showModalCategory, setShowModalCategory] = useState(false);
    const [CategorySearchValue, setCategorySearchValue] = useState("");
    const [CategoryTableData, setCategoryTableData] = useState([]);

    const loadCategoryModalData = (event, param) => {
        event.preventDefault();
        let code = document.getElementById("CategoryCode" + param).value;
        setCategorySearchValue(code);
        document.getElementById('selectedId').value = "CategoryCode" + param;

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            if (code != "") {
                data['category_code'] = code;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setCategoryTableData(response.data.data);
                    setShowModalCategory(true);
                })
                .catch((error) => {
                });
        } else {
            setCategoryTableData([]);
            setShowModalCategory(true);
        }
    }
    const setCategoryData = (data) => {
        let resultCode = data['category_code'];
        let resultName = data['category_name'];
        if (document.getElementById('selectedId').value == 'CategoryCode1') {
            setCategoryCode1(resultCode);
            setCategoryName1(resultName);
        } else if (document.getElementById('selectedId').value == 'CategoryCode2') {
            setCategoryCode2(resultCode);
            setCategoryName2(resultName);
        } else {
            setCategoryCode3(resultCode);
            setCategoryName3(resultName);
        }
    }

    const onDataKeyDown = (e, param) => {
        if(e.keyCode == 13){
            switch(param){
                case 1 :
                    document.getElementById('input1').focus();
                    break;
                case 2 :
                    document.getElementById('input2').focus();
                    break;
                case 3 :
                    document.getElementById('input3').focus();
                    break;
                case 4 :
                    document.getElementById('input4').focus();
                    break;
                case 5 :
                    document.getElementById('input5').focus();
                    break;
                case 6 :
                    document.getElementById('input6').focus();
                    break;
                case 7 :
                    document.getElementById('input7').focus();
                    break;
                case 8 :
                    document.getElementById('input8').focus();
                    break;
                case 9 :
                    document.getElementById('input9').focus();
                    break;
                case 10 :
                    if(document.getElementById('input10')){
                        document.getElementById('input10').focus();
                    }else{
                        document.getElementById('input11').focus();
                    }
                    break;
                case 11 :
                    document.getElementById('input11').focus();
                    break;
                case 12 :
                    document.getElementById('input12').focus();
                    break;
                case 13 :
                    document.getElementById('input13').focus();
                    break;
                case 14 :
                    document.getElementById('input14').focus();
                    break;
                case 15 :
                    document.getElementById('input15').focus();
                    break;
                case 16 :
                    document.getElementById('input16').focus();
                    break;
                case 17 :
                    document.getElementById('input17').focus();
                    break;
                case 18 :
                    document.getElementById('input18').focus();
                    break;
                case 19 :
                    document.getElementById('input19').focus();
                    break;
                case 20 :
                    document.getElementById('input20').focus();
                    break;
                case 21 :
                    document.getElementById('input21').focus();
                    break;
                case 22 :
                    document.getElementById('input22').focus();
                    break;
                case 23 :
                    document.getElementById('CategoryCode2').focus();
                    break;
                case 24 :
                    document.getElementById('CategoryCode3').focus();
                    break;
                case 25 :
                    if(document.getElementById('input25')){
                        document.getElementById('input25').focus();
                    }else{
                        document.getElementById('input29').focus();
                    }
                    break;
                case 26 :
                    document.getElementById('input26').focus();
                    break;
                case 27 :
                    document.getElementById('input27').focus();
                    break;
                case 28 :
                    document.getElementById('input28').focus();
                    break;
                case 29 :
                    document.getElementById('input29').focus();
                    break;
                    default :
            }
        }
    }

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);
    const [type5Open, setType5Open] = useState(false);
    const [type6Open, setType6Open] = useState(false);
    const [type7Open, setType7Open] = useState(false);
    const [type8Open, setType8Open] = useState(false);
    const [type9Open, setType9Open] = useState(false);
    const [type10Open, setType10Open] = useState(false);
    const [type11Open, setType11Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type2').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type3').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type4').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onType4KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type5').focus();
            event.preventDefault();
        }
    }
    const onFocusType4 = (e, type) => {
        if(type == 1){
            setType4Open(true);
        }else{
            setType4Open(false);
        }
    }

    const onType5KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type6').focus();
            event.preventDefault();
        }
    }
    const onFocusType5 = (e, type) => {
        if(type == 1){
            setType5Open(true);
        }else{
            setType5Open(false);
        }
    }

    const onType6KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type6').focus();
            event.preventDefault();
        }
    }
    const onFocusType6 = (e, type) => {
        if(type == 1){
            setType6Open(true);
        }else{
            setType6Open(false);
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('focus').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            case 4:
                setType4Open(false);
                break;
            case 5:
                setType5Open(false);
                break;
            case 6:
                setType6Open(false);
                break;
            case 7:
                setType7Open(false);
                break;
            case 8:
                setType8Open(false);
                break;
            case 9:
                setType9Open(false);
                break;
            default:
                break;
        }
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_200px = [gstyle.cs_input, gstyle.w200px];
    const style_input_80 = [gstyle.cs_input, gstyle.w100];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ItemModal show={showModal} onClose={() => setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} onChange={setSearchValue}
                SearchValue2={SearchValue2} onChange2={setSearchValue2}
                SearchValue3={SearchValue3}
                tableData={tableData} setTableData={setTableData}
                target={setItemData} />
            <ShipperModal show={showModalShipper} onClose={() => setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue}
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} />
            <ItemTypeModal show={showModalItemType} onClose={() => setShowModalItemType(false)} width={'70%'}
                SearchValue={ItemTypeSearchValue} onChange={setItemTypeSearchValue}
                tableData={ItemTypeTableData} setTableData={setItemTypeTableData}
                target={setItemTypeData} />
            <CategoryModal show={showModalCategory} onClose={() => setShowModalCategory(false)} width={'70%'}
                SearchValue={CategorySearchValue} onChange={setCategorySearchValue}
                tableData={CategoryTableData} setTableData={setCategoryTableData}
                target={setCategoryData} />

            <Modal2 title="商品削除" show={showModalDel} onClose={() => setShowModalDel(false)} width={'40%'}
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>商品マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    { /* 荷主コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownShipperCode(e)} id='input1'
                                            value={ShipperCode} onChange={handleChangeShipperCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="ShipperName"
                                            value={ShipperName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadShipperModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 品番 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_200px.join(" ")}
                                            onKeyDown={(e) => onKeyDownItemCode(e, 1)} id='input2'
                                            value={ItemCode} onChange={handleChangeItemCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")}
                                            onKeyDown={(e) => onKeyDownItemCode(e, 2)} id='input3'
                                            value={ItemACode} onChange={handleChangeItemACode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 名称, フリガナ */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>名称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input4' onKeyDown={(e)=>onDataKeyDown(e, 5)}
                                            value={ItemName} onChange={handleChangeItemName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>フリガナ</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input5' onKeyDown={(e)=>onDataKeyDown(e, 6)}
                                            value={ItemNameKana} onChange={handleChangeItemNameKana} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 略称 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>略称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input6' onKeyDown={(e)=>onDataKeyDown(e, 7)}
                                            value={ItemAName} onChange={handleChangeItemAName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 商品区分, セット区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>商品区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="商品区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeItemType}
                                            onKeyDown={(e)=>onDataKeyDown(e, 8)}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="input7"
                                            value={ItemType}
                                            options={[
                                                {value:'0', label:'ロット未使用'},
                                                {value:'1', label:'ロット使用'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>セット区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="セット区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeItemSetFlag}
                                            onKeyDown={(e)=>onDataKeyDown(e, 9)}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="input8"
                                            value={ItemSetFlag}
                                            options={[
                                                {value:'0', label:'通常'},
                                                {value:'1', label:'セット'},
                                            ]}
                                            />
                                    </div>
                                </label>
                            </div>
                            <input type='text' style={{width:0, outline:'white', border:'none'}} id="focus"/>
                        </div>
                    </div>
                    { /* 入数, 請求端数計算 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>入数</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")}
                                        id='input9' onKeyDown={(e)=>onDataKeyDown(e, 10)}
                                            value={ItemQuantity} onChange={handleChangeItemQuantity} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        {ItemSetFlag == 1 && ItemQuantity > 0 &&
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>請求端数計算</span>
                                        <div className={gstyle.cs_flex}>
                                            <Select
                                                showSearch
                                                open={type3Open}
                                                onSelect={(e)=>onSelectData(e, 3)}
                                                placeholder="請求端数計算を選択します。"
                                                optionFilterProp="children"
                                                onChange={handleChangeItemFractionType}
                                                onKeyDown={(e)=>onDataKeyDown(e, 11)}
                                                onFocus={(e) => onFocusType3(e, 1)}
                                                onBlur={(e) => onFocusType3(e, 2)}
                                                filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                style={{ marginTop:14, width:'100%'}}
                                                id="input10"
                                                value={ItemFractionType}
                                                options={[
                                                    {value:'0', label:'なし'},
                                                    {value:'1', label:'四捨五入'},
                                                    {value:'2', label:'切り上げ'},
                                                    {value:'3', label:'切り捨て'},
                                                    {value:'4', label:'バラ計算'},
                                                ]}
                                                />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                    { /* 単位重量 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>単位重量</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")}
                                        id='input11' onKeyDown={(e)=>onDataKeyDown(e, 12)}
                                            value={UnitWeight} onChange={handleChangeUnitWeight} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>(ﾊﾞﾗ)</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")}
                                        id='input12' onKeyDown={(e)=>onDataKeyDown(e, 13)}
                                            value={UnitWeightBara} onChange={handleChangeUnitWeightBara} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 単位容積 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>単位容積</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")}
                                        id='input13' onKeyDown={(e)=>onDataKeyDown(e, 14)}
                                            value={UnitVolume} onChange={handleChangeUnitVolume} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>(ﾊﾞﾗ)</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")}
                                        id='input14' onKeyDown={(e)=>onDataKeyDown(e, 15)}
                                            value={UnitVolumeBara} onChange={handleChangeUnitVolumeBara} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 単位 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>単位</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input15' onKeyDown={(e)=>onDataKeyDown(e, 16)}
                                            value={ItemUnit} onChange={handleChangeItemUnit} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <input type='text' style={{width:0, outline:'white', border:'none'}} id="focus"/>
                    </div>
                    { /* 在庫計算区分, 請求計算区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>在庫計算区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type4Open}
                                            onSelect={(e)=>onSelectData(e, 4)}
                                            placeholder="在庫計算区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeStockCalType}
                                            onKeyDown={(e)=>onDataKeyDown(e, 17)}
                                            onFocus={(e) => onFocusType4(e, 1)}
                                            onBlur={(e) => onFocusType4(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="input16"
                                            value={StockCalType}
                                            options={[
                                                {value:'0', label:'数量'},
                                                {value:'1', label:'重量'},
                                                {value:'2', label:'容積'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>請求計算区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type5Open}
                                            onSelect={(e)=>onSelectData(e, 5)}
                                            placeholder="請求計算区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeInvoiceCalType}
                                            onKeyDown={(e)=>onDataKeyDown(e, 18)}
                                            onFocus={(e) => onFocusType5(e, 1)}
                                            onBlur={(e) => onFocusType5(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="input17"
                                            value={InvoiceCalType}
                                            options={[
                                                {value:'0', label:'数量'},
                                                {value:'1', label:'重量'},
                                                {value:'2', label:'容積'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 税No */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>税No</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type6Open}
                                            onSelect={(e)=>onSelectData(e, 6)}
                                            placeholder="税Noを選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeTaxNo}
                                            onKeyDown={(e)=>onDataKeyDown(e, 19)}
                                            onFocus={(e) => onFocusType6(e, 1)}
                                            onBlur={(e) => onFocusType6(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="input18"
                                            value={TaxNo}
                                            options={TaxNoData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 初回入庫日, 商品期限 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>初回入庫日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker
                                            value={FirstArrivalDate}
                                            onChange={handleChangeFirstArrivalDate} id='input19' focusId='input20' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>商品期限</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker
                                            value={ItemDeadlineDate}
                                            onChange={handleChangeItemDeadlineDate} id='input20' focusId='input21' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 品種コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品種コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                        id='input21' onKeyDown={(e)=>onDataKeyDown(e, 22)}
                                            value={ItemTypeCode} onChange={handleChangeItemTypeCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="ItemTypeName"
                                            value={ItemTypeName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadItemTypeModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>価格改定日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker
                                            value={PriceUpdateDate}
                                            onChange={handleChangePriceUpdateDate} id='input22' focusId='CategoryCode1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 分類コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>分類コード①</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} id="CategoryCode1"
                                        onKeyDown={(e)=>onDataKeyDown(e, 23)}
                                            value={CategoryCode1} onChange={(e) => handleChangeCategoryCode(e, 1)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="CategoryName1"
                                            value={CategoryName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadCategoryModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>分類コード②</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} id="CategoryCode2"
                                        onKeyDown={(e)=>onDataKeyDown(e, 24)}
                                            value={CategoryCode2} onChange={(e) => handleChangeCategoryCode(e, 2)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="CategoryName2"
                                            value={CategoryName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadCategoryModalData(e, 2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>分類コード③</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} id="CategoryCode3"
                                        onKeyDown={(e)=>onDataKeyDown(e, 25)}
                                            value={CategoryCode3} onChange={(e) => handleChangeCategoryCode(e, 3)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="CategoryName1"
                                            value={CategoryName3} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadCategoryModalData(e, 3)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 保管料率, 入庫荷役料率, 出庫荷役料率 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>保管料率</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")}
                                        id='input25' onKeyDown={(e)=>onDataKeyDown(e, 26)}
                                            value={StorageFeeRate} onChange={handleChangeStorageFeeRate} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>入庫荷役料率</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")}
                                        id='input26' onKeyDown={(e)=>onDataKeyDown(e, 27)}
                                            value={ArrivalCargoHandlingFeeRate} onChange={handleChangeArrivalCargoHandlingFeeRate} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出庫荷役料率</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")}
                                        id='input27' onKeyDown={(e)=>onDataKeyDown(e, 28)}
                                            value={LeavingCargoHandlingFeeRate} onChange={handleChangeLeavingCargoHandlingFeeRate} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 在庫評価単価 */}
                    {ItemSetFlag == 1 && ItemQuantity > 0 && ItemFractionType > 0 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_4.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>在庫評価単価</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_80.join(" ")}
                                            id='input28' onKeyDown={(e)=>onDataKeyDown(e, 29)}
                                                value={StockCalPrice} onChange={handleChangeStockCalPrice} />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }

                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='input29' onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} disabled={SysItemId > 0 ? false : true}>削除</button>
                    </div>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle> こちらの内容で登録しますか？</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                    <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

export default Item;