/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './StockListByType.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import CategoryModal from '../../../components/common/modal/CategoryModal';
import ItemTypeModal from '../../../components/common/modal/ItemTypeModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import {Select} from 'antd';

const StockListByType = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    // slip date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    
    // start and end date ---------------------------------------
    const [SlipDate, setSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeSlipDate = (date) => {
        setSlipDate(useJwt.formatDate(date));
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
    };
    const OrderTypeData = [ { value:'0', label:'品種順' }, { value:'1', label:'分類順' } ];
    // category --------------------------------------------------------
    const [CategoryCode1, setCategoryCode1] = useState("");
    const [CategoryName1, setCategoryName1] = useState("");
    const handleChangeCategoryCode1 = (event) => {
        setCategoryCode1(useJwt.validateNum(event.target.value,3));
    };
    const [CategoryCode2, setCategoryCode2] = useState("");
    const [CategoryName2, setCategoryName2] = useState("");
    const handleChangeCategoryCode2 = (event) => {
        setCategoryCode2(useJwt.validateNum(event.target.value,3));
    };
    const onKeyDownCategoryCode =(event,param) => {
        if(event.keyCode === 13) { // enter key
            getCategoryData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('category_code2').focus();
            }else{
                document.getElementById('type2').focus();
            }
        }
    };
    const getCategoryData = (code,param) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let t_data = response.data;
                if(t_data.length > 0) {
                    setCategoryData(t_data[0],param);
                }else{
                    setCategoryData([],param);
                }
            })
            .catch((error) => {
            });
        }else{
            setCategoryData([],param);
        }
    }
    const setCategoryData = (data,param) => {
        if(data['category_code']) {
            if(param == 1) {
                setCategoryCode1(data['category_code']);
                setCategoryName1(data['category_name']);
            }else{
                setCategoryCode2(data['category_code']);
                setCategoryName2(data['category_name']);
            }
        }else{
            if(param == 1) {
                setCategoryCode1("");
                setCategoryName1("");
            }else{
                setCategoryCode2("");
                setCategoryName2("");
            }
        }
    }
    // item type ---------------------------------------------------
    const [ItemTypeCode1, setItemTypeCode1] = useState("");
    const handleChangeItemTypeCode1 = (event) => {
        setItemTypeCode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemTypeName1, setItemTypeName1] = useState("");
    const [ItemTypeCode2, setItemTypeCode2] = useState("");
    const handleChangeItemTypeCode2 = (event) => {
        setItemTypeCode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemTypeName2, setItemTypeName2] = useState("");
    const onKeyDownItemTypeCode =(event,param) => {
        if(event.keyCode === 13) { // enter key
            getItemTypeData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('item_type_code2').focus();
            }else{
                document.getElementById('type2').focus();
            }
        }
    };
    const getItemTypeData = (code,param) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                item_type_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let t_data = response.data.data;
                if(t_data.length > 0) {
                    setItemTypeData(t_data[0],param);
                }else{
                    setItemTypeData([],param);
                }
            })
            .catch((error) => {
            });
        }else{
            setItemTypeData([],param);
        }
    }
    const setItemTypeData = (data,param) => {
        if(data['item_type_code']) {
            if(param == 1) {
                setItemTypeCode1(data['item_type_code']);
                setItemTypeName1(data['item_type_name']);
            }else{
                setItemTypeCode2(data['item_type_code']);
                setItemTypeName2(data['item_type_name']);
            }
        }else{
            if(param == 1) {
                setItemTypeCode1("");
                setItemTypeName1("");
            }else{
                setItemTypeCode2("");
                setItemTypeName2("");
            }
        }
    }
    // shipper type -----------------------------------------------
    const [ShipperType, setShipperType] = useState(0);
    const handleChangeShipperType = (event) => {
        setShipperType(event);
    };
    const ShipperTypeData = [ { value:'0', label:'全荷主' }, { value:'1', label:'荷主別' } ];
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();
            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('type3').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ShipperACode1;
            }else{
                code = ShipperCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ShipperACode2;
            }else{
                code = ShipperCode2;
                acode = value;
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // weight unit type -----------------------------------------------
    const [WeightUnitType, setWeightUnitType] = useState(0);
    const handleChangeWeightUnitType = (event) => {
        setWeightUnitType(event);
    };
    const WeightUnitTypeData = [ { value:'0', label:'1kg 単位' }, { value:'1', label:'10kg 単位' } 
    , { value:'2', label:'100kg 単位' }, { value:'3', label:'1000kg 単位' }];
    // weight round type -----------------------------------------------
    const [WeightRoundType, setWeightRoundType] = useState(0);
    const handleChangeWeightRoundType = (event) => {
        setWeightRoundType(event);
    };
    // volume unit type -----------------------------------------------
    const [VolumeUnitType, setVolumeUnitType] = useState(0);
    const handleChangeVolumeUnitType = (event) => {
        setVolumeUnitType(event);
    };
    const VolumeUnitTypeData = [ { value:'0', label:'1 単位' }, { value:'1', label:'10 単位' } 
    , { value:'2', label:'100 単位' }, { value:'3', label:'1000 単位' }];
    // volume round type -----------------------------------------------
    const [VolumeRoundType, setVolumeRoundType] = useState(0);
    const handleChangeVolumeRoundType = (event) => {
        setVolumeRoundType(event);
    };
    // Price unit type -----------------------------------------------
    const [PriceUnitType, setPriceUnitType] = useState(0);
    const handleChangePriceUnitType = (event) => {
        setPriceUnitType(event);
    };
    const PriceUnitTypeData = [ { value:'0', label:'1円 単位' }, { value:'1', label:'10円 単位' } 
    , { value:'2', label:'100円 単位' }, { value:'3', label:'1000円 単位' }];
    // Price round type -----------------------------------------------
    const [PriceRoundType, setPriceRoundType] = useState(0);
    const handleChangePriceRoundType = (event) => {
        setPriceRoundType(event);
    };
    // filter type -----------------------------------------------
    const [FilterType, setFilterType] = useState(0);
    const handleChangeFilterType = (event) => {
        setFilterType(event);
    };

    const RoundVal = (type,val) => {
        if(type == 1){
            if(WeightUnitType == 1 ) {
                val = val/10;
            }else if(WeightUnitType == 2 ) {
                val = val/100;
            }else if(WeightUnitType == 3 ){
                val = val/1000;
            }
            if(WeightUnitType > 0){
                if(WeightRoundType == 0 ) {
                    val = Math.round(val);
                }else if(WeightRoundType == 1 ) {
                    val = Math.ceil(val);
                }else{
                    val = Math.floor(val);
                }
            }
        }else if(type == 2) {
            if(VolumeUnitType == 1 ) {
                val = val/10;
            }else if(VolumeUnitType == 2 ) {
                val = val/100;
            }else if(VolumeUnitType == 3 ){
                val = val/1000;
            }
            if(VolumeUnitType > 0){
                if(VolumeRoundType == 0 ) {
                    val = Math.round(val);
                }else if(VolumeRoundType == 1 ) {
                    val = Math.ceil(val);
                }else{
                    val = Math.floor(val);
                }
            }
        }else{
            if(PriceUnitType == 1 ) {
                val = val/10;
            }else if(PriceUnitType == 2 ) {
                val = val/100;
            }else if(PriceUnitType == 3 ){
                val = val/1000;
            }
            if(PriceUnitType > 0){
                if(PriceRoundType == 0 ) {
                    val = Math.round(val);
                }else if(PriceRoundType == 1 ) {
                    val = Math.ceil(val);
                }else{
                    val = Math.floor(val);
                }
            }
        }
        
        
        return val;
    }
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_stock_flag_6'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        
        // data['start_slip_date'] = SlipDate;
        data['end_slip_date'] = SlipDate;
        data['sort_type'] = OrderType;
        if(ShipperType==1) {
            data['start_shipper_code'] = ShipperCode1;
            data['end_shipper_code'] = ShipperCode2;
            data['start_shipper_a_code'] = ShipperACode1;
            data['end_shipper_a_code'] = ShipperACode2;
        }
        
        data['start_category_code'] = CategoryCode1;
        data['end_category_code'] = CategoryCode2;
        data['start_item_type_code'] = ItemTypeCode1;
        data['end_item_type_code'] = ItemTypeCode2;

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockListByTypeEndpont;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            // console.log(result)
            if(result.length>0){
                gridMainTable(result);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                gridMainTable([]);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
        
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let data = [];
        let itemCodes_1 = [];
        let header_info_1 = [];
        for(var i=0; i<result.length; i++) {
            let filter_code_1 = ''+result[i]['shipper_code'];
            if(!itemCodes_1.includes(filter_code_1)){
                itemCodes_1.push(filter_code_1);
                let row = {};
                row['shipper_code'] = result[i]['shipper_code'];
                row['shipper_a_code'] = result[i]['shipper_a_code'];
                row['shipper_name'] = result[i]['shipper_name'];
                header_info_1.push(row);
            }
        }
        let total_stockNum = 0;
        let total_stockWeight = 0;
        let total_stockVolume = 0;
        let total_stockPrice = 0;

        for(var i=0; i<itemCodes_1.length; i++) {
            let row = {};
            row['header_info'] = header_info_1[i];
            row['filter_code'] = itemCodes_1[i];
            
            let item_info_1 = [];
            let item_info = [];
            let sub_total_stockNum = 0;
            let sub_total_stockWeight = 0;
            let sub_total_stockVolume = 0;
            let sub_total_stockPrice = 0;
            
            for(var j=0;j<result.length;j++) {
                let filter_code_1 = ''+result[j]['shipper_code'];
                if(itemCodes_1[i] == filter_code_1) {
                    if(result[j]['stock_ary']['stockNum'] != ''){
                        sub_total_stockNum += (result[j]['stock_ary']['stockNum']*1);
                    }
                    if(result[j]['stock_ary']['stockWeight'] != ''){
                        sub_total_stockWeight += (result[j]['stock_ary']['stockWeight']*1);
                    }
                    if(result[j]['stock_ary']['stockVolume'] != ''){
                        sub_total_stockVolume += (result[j]['stock_ary']['stockVolume']*1);
                    }
                    if(result[j]['stock_ary']['stockPrice'] != ''){
                        sub_total_stockPrice += (result[j]['stock_ary']['stockPrice']*1);
                    }

                    let row_2 = result[j];
                    row_2['total_flg'] = 0;
                    row_2['total_label'] = "";
                    item_info.push(row_2);
                }
            }

            let total_row = {};
            total_row['total_flg'] = 1;
            total_row['total_label'] = "【荷 主 計】";
            total_row['stockNum'] = sub_total_stockNum;
            total_row['stockWeight'] = sub_total_stockWeight;
            total_row['stockVolume'] = sub_total_stockVolume;
            total_row['stockPrice'] = sub_total_stockPrice;
            item_info.push(total_row);

            row['item_info'] = item_info;
            row['total_flg'] = 0;
            row['total_label'] = "";
            data.push(row);

            total_stockNum += sub_total_stockNum*1;
            total_stockWeight += sub_total_stockWeight*1;
            total_stockVolume += sub_total_stockVolume*1;
            total_stockPrice += sub_total_stockPrice*1;
        }
        let total_row = {};
        total_row['total_flg'] = 1;
        total_row['total_label'] = "【合 計】";
        total_row['stockNum'] = total_stockNum;
        total_row['stockWeight'] = total_stockWeight;
        total_row['stockVolume'] = total_stockVolume;
        total_row['stockPrice'] = total_stockPrice;
        data.push(total_row);

        // console.log("data:",data);
        // let data = result;
        setMainTableData(data);
        if(data.length>0){
            setOpend(true);
        }
    }
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------- Category Modal ------------------------------------------
    const [showModalCategory, setShowModalCategory] = useState(false);
    const [CategorySearchValue, setCategorySearchValue] = useState("");
    const [CategoryTableData, setCategoryTableData] = useState([]);
    const loadCategoryCodeModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = CategoryCode1;
        if(param==2){
            code = CategoryCode2;
        }
        setCategorySearchValue(code);
        if(code != ""){
            const data = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setCategoryTableData(response.data);
                setShowModalCategory(true);
            })
            .catch((error) => {
            });
        }else{
            setCategoryTableData([]);
            setShowModalCategory(true);
        }
    }
    // ----------------------------- item type modal -----------------------------------
    const [showModalItemType, setShowModalItemType] = useState(false);
    const [ItemTypeSearchValue, setItemTypeSearchValue] = useState("");
    const [ItemTypeTableData, setItemTypeTableData] = useState([]);
    const loadItemTypeModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemTypeCode1;
        if(param==2){
            code = ItemTypeCode2;
        }
        setItemTypeSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['item_type_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTypeTableData(response.data.data);
                setShowModalItemType(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTypeTableData([]);
            setShowModalItemType(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);
    const [type5Open, setType5Open] = useState(false);
    const [type6Open, setType6Open] = useState(false);
    const [type7Open, setType7Open] = useState(false);
    const [type8Open, setType8Open] = useState(false);
    const [type9Open, setType9Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('category_code1')){
                    document.getElementById('category_code1').focus();
                }else{
                    document.getElementById('item_type_code1').focus();
                }
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('shipper_code1')){
                    document.getElementById('shipper_code1').focus();
                }else{
                    document.getElementById('type3').focus();
                }
            })
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type4').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onType4KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type5').focus();
            event.preventDefault();
        }
    }
    const onFocusType4 = (e, type) => {
        if(type == 1){
            setType4Open(true);
        }else{
            setType4Open(false);
        }
    }

    const onType5KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type6').focus();
            event.preventDefault();
        }
    }
    const onFocusType5 = (e, type) => {
        if(type == 1){
            setType5Open(true);
        }else{
            setType5Open(false);
        }
    }

    const onType6KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type7').focus();
            event.preventDefault();
        }
    }
    const onFocusType6 = (e, type) => {
        if(type == 1){
            setType6Open(true);
        }else{
            setType6Open(false);
        }
    }

    const onType7KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type8').focus();
            event.preventDefault();
        }
    }
    const onFocusType7 = (e, type) => {
        if(type == 1){
            setType7Open(true);
        }else{
            setType7Open(false);
        }
    }

    const onType8KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type9').focus();
            event.preventDefault();
        }
    }
    const onFocusType8 = (e, type) => {
        if(type == 1){
            setType8Open(true);
        }else{
            setType8Open(false);
        }
    }

    const onType9KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType9 = (e, type) => {
        if(type == 1){
            setType9Open(true);
        }else{
            setType9Open(false);
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('focus').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            case 4:
                setType4Open(false);
                break;
            case 5:
                setType5Open(false);
                break;
            case 6:
                setType6Open(false);
                break;
            case 7:
                setType7Open(false);
                break;
            case 8:
                setType8Open(false);
                break;
            case 9:
                setType9Open(false);
                break;
            default:
                break;
        }
    }

    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_9 = [gstyle.cs_col, gstyle.cs_col_9];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_label_wrap3 = [gstyle.cs_label, gstyle.cs_flex_2, style.float_right];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap2 = [style.p2030];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_wrap4 = [gstyle.p00300015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_245px = [gstyle.cs_input, gstyle.w245px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];
    const style_select_245px = [gstyle.cs_select, gstyle.w245px];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <CategoryModal show={showModalCategory} onClose={()=>setShowModalCategory(false)} width={'70%'}
                SearchValue={CategorySearchValue} onChange={setCategorySearchValue} 
                tableData={CategoryTableData} setTableData={setCategoryTableData}
                target={setCategoryData} targetParam={TargetParam} />
            <ItemTypeModal show={showModalItemType} onClose={()=>setShowModalItemType(false)} width={'70%'}
                SearchValue={ItemTypeSearchValue} onChange={setItemTypeSearchValue} 
                tableData={ItemTypeTableData} setTableData={setItemTypeTableData}
                target={setItemTypeData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>在庫評価一覧表</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={SlipDate} onChange={handleChangeSlipDate} id='date2' focusId='type1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 出力順位 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={OrderTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 品種 */}
                    {OrderType ==0 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_4.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始品種</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemTypeCode(e,1)} id='item_type_code1'
                                            value={ItemTypeCode1} onChange={handleChangeItemTypeCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemTypeName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemTypeModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_4.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了品種</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemTypeCode(e,2)} id='item_type_code2'
                                            value={ItemTypeCode2} onChange={handleChangeItemTypeCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemTypeName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemTypeModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 分類 */}
                    {OrderType ==1 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始分類</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownCategoryCode(e,1)} id='category_code1'
                                                value={CategoryCode1} onChange={handleChangeCategoryCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={CategoryName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCategoryCodeModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了分類</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownCategoryCode(e,2)} id='category_code2'
                                                value={CategoryCode2} onChange={handleChangeCategoryCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={CategoryName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCategoryCodeModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 荷主区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="荷主区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeShipperType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={ShipperTypeData}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <input type='text' style={{width:0, outline:'white', border:'none'}} id="focus"/>
                    { /* 荷主 */}
                    {ShipperType == 1 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始荷主</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                            value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                            <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                            value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ShipperName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了荷主</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                            value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                            <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                            value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ShipperName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/*  重量単位, 端数区分, */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>重量単位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="重量単位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeWeightUnitType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={WeightUnitTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>端数区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type4Open}
                                            onSelect={(e)=>onSelectData(e, 4)}
                                            placeholder="端数区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeWeightRoundType}
                                            onKeyDown={onType4KeyDown}
                                            onFocus={(e) => onFocusType4(e, 1)}
                                            onBlur={(e) => onFocusType4(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type4"
                                            options={[
                                                {value:'0', label:'四捨五入'},
                                                {value:'1', label:'切り上げ'},
                                                {value:'2', label:'切り捨て'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  容積単位, 端数区分, */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>容積単位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type5Open}
                                            onSelect={(e)=>onSelectData(e, 5)}
                                            placeholder="容積単位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeVolumeUnitType}
                                            onKeyDown={onType5KeyDown}
                                            onFocus={(e) => onFocusType5(e, 1)}
                                            onBlur={(e) => onFocusType5(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type5"
                                            options={VolumeUnitTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>端数区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type6Open}
                                            onSelect={(e)=>onSelectData(e, 6)}
                                            placeholder="端数区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeVolumeRoundType}
                                            onKeyDown={onType6KeyDown}
                                            onFocus={(e) => onFocusType6(e, 1)}
                                            onBlur={(e) => onFocusType6(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type6"
                                            options={[
                                                {value:'0', label:'四捨五入'},
                                                {value:'1', label:'切り上げ'},
                                                {value:'2', label:'切り捨て'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  金額単位, 端数区分, */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>金額単位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type7Open}
                                            onSelect={(e)=>onSelectData(e, 7)}
                                            placeholder="金額単位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangePriceUnitType}
                                            onKeyDown={onType7KeyDown}
                                            onFocus={(e) => onFocusType7(e, 1)}
                                            onBlur={(e) => onFocusType7(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type7"
                                            options={PriceUnitTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>端数区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type8Open}
                                            onSelect={(e)=>onSelectData(e, 8)}
                                            placeholder="端数区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangePriceRoundType}
                                            onKeyDown={onType8KeyDown}
                                            onFocus={(e) => onFocusType8(e, 1)}
                                            onBlur={(e) => onFocusType8(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type8"
                                            options={[
                                                {value:'0', label:'四捨五入'},
                                                {value:'1', label:'切り上げ'},
                                                {value:'2', label:'切り捨て'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 打出区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>打出区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type9Open}
                                            onSelect={(e)=>onSelectData(e, 9)}
                                            placeholder="打出区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeFilterType}
                                            onKeyDown={onType9KeyDown}
                                            onFocus={(e) => onFocusType9(e, 1)}
                                            onBlur={(e) => onFocusType9(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type9"
                                            options={[
                                                {value:'0', label:'発生又は在庫有り'},
                                                {value:'1', label:'発生有りのみ'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        {/* Title, 発行年月日 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_2.join(" ")}></div>
                            <div className={style_col_8.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>在庫評価一覧表</h2>
                                </div>
                            </div>
                            <div className={style_col_2.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {/* 出力順位 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_8.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>{useJwt.formatDate2(new Date(SlipDate))}  現在</span>
                                        </div>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>
                                                [出力順位]:{OrderTypeData[OrderType]['value']}   
                                            </span>
                                            <span className={style.cs_span_3}>
                                                [荷主区分]:{ShipperTypeData[ShipperType]['value']}    
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_4.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap3.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>重量 : </span>
                                            <span className={style.cs_span_3}>{WeightUnitTypeData[WeightUnitType]['value']}</span>
                                        </div>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>容積 : </span>
                                            <span className={style.cs_span_3}>{VolumeUnitTypeData[VolumeUnitType]['value']}</span>
                                        </div>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>金額 : </span>
                                            <span className={style.cs_span_3}>￥{useJwt.numberWithCommas(PriceUnitTypeData[PriceUnitType]['value'])}</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* table */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>荷主ｺｰﾄﾞ</th>
                                                <th>荷主名</th>
                                                {OrderType == 0 &&
                                                    <th>品種ｺｰﾄﾞ</th>
                                                }
                                                {OrderType == 0 &&
                                                    <th>品種名</th>
                                                }
                                                {OrderType == 1 &&
                                                    <th>分類ｺｰﾄﾞ</th>
                                                }
                                                {OrderType == 1 &&
                                                    <th>分類名</th>
                                                }
                                                <th>在庫重量</th>
                                                <th>在庫容積</th>
                                                <th>在庫金額</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {  mainTableData.map((data,index) => (
                                                <React.Fragment key={index}>
                                                    {   data['total_flg'] == 0?
                                                            data['item_info'].map((data1,index1) => (
                                                                <React.Fragment key={index1}>
                                                                    {data1['total_flg'] == 0?
                                                                        <tr>
                                                                            {ShipperType>0 && index1>0?
                                                                                <td></td>
                                                                                :
                                                                                <td>{data['header_info']['shipper_code']}</td>
                                                                            }
                                                                            
                                                                            <td className={style.td_left}>{data['header_info']['shipper_name']}</td>
                                                                            {OrderType == 0 &&
                                                                                <td>{data1['item_type_code']}</td>
                                                                            }
                                                                            {OrderType == 0 &&
                                                                                <td  className={style.td_left}>{data1['item_type_name']}</td>
                                                                            }
                                                                            {OrderType == 1 &&
                                                                                <td>{data1['category_code']}</td>
                                                                            }
                                                                            {OrderType == 1 &&
                                                                                <td className={style.td_left}>{data1['category_name']}</td>
                                                                            }
                                                                            <td className={style.td_right}>{useJwt.numberWithCommas(RoundVal(1,data1['stock_ary']['stockWeight']))}</td>
                                                                            <td className={style.td_right}>{useJwt.numberWithCommas(RoundVal(2,data1['stock_ary']['stockVolume']))}</td>
                                                                            <td className={style.td_right}>￥{useJwt.numberWithCommas(RoundVal(3,data1['stock_ary']['stockPrice']))}</td>
                                                                        </tr>
                                                                    :<>{ShipperType>0 &&
                                                                        <tr className={style.bg_tbl_total}>
                                                                            <td colSpan={4}>{data1['total_label']}</td>
                                                                            <td className={style.td_right}>{useJwt.numberWithCommas(RoundVal(1,data1['stockWeight']))}</td>
                                                                            <td className={style.td_right}>{useJwt.numberWithCommas(RoundVal(2,data1['stockVolume']))}</td>
                                                                            <td className={style.td_right}>￥{useJwt.numberWithCommas(RoundVal(3,data1['stockPrice']))}</td>
                                                                        </tr>
                                                                        }
                                                                    </>}
                                                                </React.Fragment>
                                                            ))
                                                        :
                                                        <tr className={style.bg_tbl_total}>
                                                            <td colSpan={4}>{data['total_label']}</td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(RoundVal(1,data['stockWeight']))}</td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(RoundVal(2,data['stockVolume']))}</td>
                                                            <td className={style.td_right}>￥{useJwt.numberWithCommas(RoundVal(3,data['stockPrice']))}</td>
                                                        </tr>
                                                    }
                                                </React.Fragment>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'在庫評価一覧表_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default StockListByType;