/* eslint-disable eqeqeq */
import style from './Category.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '../../../components/common/modal/Modal';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import CategoryModal from '../../../components/common/modal/CategoryModal';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select} from 'antd';

const Category = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Category ID
    const [CategoryId, setCategoryId] = useState(0);
    const [MstType, setMstType] = useState(0);
    const MstTypeData = [{value:0,label:'荷主'},{value:1,label:'品種'},{value:2,label:'商品'},{value:3,label:'倉庫'}];
    const handleChangeMstType = (event) => {
        setMstType(event);
        let categoryTypeDataAry = [];
        if(event == 0 || event == 2) {
            categoryTypeDataAry = [
                {value:0, label:'分類①'},
                {value:1, label:'分類②'},
                {value:2, label:'分類③'}
            ];
        }else{
            categoryTypeDataAry = [
                {value:0, label:'分類①'}
            ];
        }
        setCategoryTypeData(categoryTypeDataAry);
        setCategoryType(0);
    }
    const [CategoryType, setCategoryType] = useState(0);
    const handleChangeCategoryType = (event) => {
        setCategoryType(event);
    }
    const [CategoryTypeData, setCategoryTypeData] = useState([{value:0,label:'分類①'},{value:1,label:'分類②'},{value:2,label:'分類③'}]);
    const CategoryTypeData2 = [{value:0,label:'分類①'},{value:1,label:'分類②'},{value:2,label:'分類③'}];
    const [CategoryCode, setCategoryCode] = useState("");
    const handleChangeCategoryCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>3) {
            str = str.substring(0, 3);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCategoryCode(str);
    };
    const onKeyDownCategoryCode = (event) => {
        if(event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(4, event.target.value)
            setCategoryCode(deg)
            document.getElementById('input2').focus();
            if(deg != "") {
                const data_r = {
                    userID: userData['id'],
                    category_code: deg
                };
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
                axios
                .post(url, data_r, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    let t_data = response.data.data;
                    if(t_data.length > 0) {
                        setCategoryData(t_data[0]);
                    }else{
                        setCategoryData([]);
                    }
                })
                .catch((error) => {
                });
            }else{
                setCategoryData([]);
            }
            event.preventDefault();
        }
    };
    
    const [CategoryName, setCategoryName] = useState("");
    const handleChangeCategoryName = (event) => {
        setCategoryName(event.target.value);
    };
    
    const setCategoryData = (result) => {
        if(result['sys_category_id']) {
            setCategoryId(result['sys_category_id']);
            setMstType(result['mst_type']);
            let categoryTypeDataAry = [];
            if(result['mst_type'] == 0 || result['mst_type'] == 2) {
                categoryTypeDataAry = [
                    {key:0, value:'分類①'},
                    {key:1, value:'分類②'},
                    {key:2, value:'分類③'}
                ];
            }else{
                categoryTypeDataAry = [
                    {key:0, value:'分類①'}
                ];
            }
            setCategoryTypeData(categoryTypeDataAry);
            setCategoryType(result['category_type']);
            setCategoryCode(result['category_code']);
            setCategoryName(result['category_name']);
        }else{
            setCategoryId(0);
            setCategoryName("");
        }
    }

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_master_flag_14'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(CategoryId>0) {
            data['sys_category_id'] = CategoryId;
        }

        data['mst_type'] = MstType;
        data['category_type'] = CategoryType;
        if(CategoryCode != "") {
            data['category_code'] = CategoryCode;
        }else{
            toast.error("コードを入力してください。", {autoClose:3000});       // please enter code
            setOpen(false);
            return;
        }
        if(CategoryName != "") {
            data['category_name'] = CategoryName;
        }else{
            toast.error("名称を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setMstCategoryEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data == 1) {
                    toast.success("登録致しました。", {autoClose:3000});
                }else if(response.data.data == 2){
                    toast.error("すでに登録済みです。", {autoClose:3000});
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
                    
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else{
                toast.error("登録に失敗しました。", {autoClose:3000});
            }
        });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if(CategoryId>0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        if(CategoryId>0) {
            let data = {};
            data['userID'] = userData['id'];
            data['sys_category_id'] = CategoryId;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.delMstCategoryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    setCategoryCode("");
                    setCategoryData([]);
                }
            })
            .catch((error) => {
            });

            setCategoryCode("");
            setCategoryData([]);
        }
    }
    // ========================== starts code for search ===============================
    const [SearchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState([]);
    const loadModalData = (event) => {
        event.preventDefault();
        let code = CategoryCode;
        setSearchValue(code);
        if(code != ""){
            const data = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData(response.data.data);
                setShowModal(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData([]);
            setShowModal(true);
        }
    }

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type2').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('input1').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onDataKeyDown = ( e ) =>{
        if(e.keyCode == 13){
            document.getElementById('registerBut').focus();
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('registerBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_select_245px = [gstyle.cs_select, gstyle.w245px];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <CategoryModal show={showModal} onClose={()=>setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} onChange={setSearchValue} 
                tableData={tableData} setTableData={setTableData}
                target={setCategoryData} />
            <Modal2 title="分類削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>分類マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>マスタ区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            value={MstType}
                                            placeholder="マスタ区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeMstType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={MstTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>分類種別</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            value={CategoryType | ''}
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="分類種別を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeCategoryType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={CategoryTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownCategoryCode(e)} id='input1'
                                        value={CategoryCode} onChange={handleChangeCategoryCode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalData(e)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>名称</span>
                                    <div className={gstyle.cs_flex}> 
                                        <input type={'text'} className={style_input_80.join(" ")}  
                                        id='input2' onKeyDown={(e)=>onDataKeyDown(e)}
                                        value={CategoryName} onChange={handleChangeCategoryName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='registerBut' onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} disabled={CategoryId>0?false:true}>削除</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Category;