/*====================================================================================================
	単位変更処理 -> AdobeXD 24ページ
	作成日:2022/08/26		最終更新日:2022/09/01
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

import React from 'react';
// ---------- テーブルの見出し 下段 ----------
import style from './TableLabelBottom.module.css';
import WarehouseLabel from '../09_warehouse/label/Label';
import LocationLabel from '../10_location/label/Label';
import LotLabel from '../11_lot/label/Label';
import CapacityLabel from '../12_capacity/label/Label';
import CaseNumLabel from '../13_case_num/label/Label';
import SingleNumLabel from '../14_single_num/label/Label';
import VolumeLabel from '../15_volume/label/Label';
import MemoLabel from '../16_memo/label/Label';

const TableLabelBottom = ()=> {
    return (
        <thead>
            <tr className={ style.flex }>
                <WarehouseLabel />
                <LocationLabel />
                <LotLabel />
                <CapacityLabel />
                <CaseNumLabel />
                <SingleNumLabel />
                <VolumeLabel />
                <MemoLabel />
            </tr>
        </thead>
    )
}

export default TableLabelBottom;