/* eslint-disable eqeqeq */
import style from './UserIndex.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import { createTheme, ThemeProvider, useTheme, Button } from '@mui/material';
import { jaJP } from '@mui/material/locale';

const UserIndex = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const theme = useTheme();

    const columns = [
        {
            id: 'name',
            accessorKey: 'name',
            enableColumnActions: false,
            header: '氏名',
        },
        {
            id: 'name_kana',
            accessorKey: 'name_kana',
            enableColumnActions: false,
            header: 'かな',
        },
        {
            id: 'email',
            accessorKey: 'email',
            enableColumnActions: false,
            header: 'メールアドレス',
        },
        {
            id: 'birthday',
            accessorKey: 'birthday',
            enableColumnActions: false,
            header: '生年月日',
        },
        {
            id: 'telephone',
            accessorKey: 'telephone',
            enableColumnActions: false,
            header: '電話番号',
        },
        {
            id: 'userEdit',
            header: '',
            columnDefType: 'display',
            enableColumnOrdering: true,
            Cell: ({ row }) => (
                <Button onClick={() => navigateUserUpdate(row.original)}>編集</Button>
            ),
            size: 50,
        }
    ];

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }
    // =================================================================================
    const [tableData, setTableData] = useState([]);
    const gridData = (data) => {
        const dataSource = [];

        data.forEach(element => {
            let temp = {};
            temp['name'] = element['user_family_nm_kanji'] + " " + element["user_first_nm_kanji"]
            temp['name_kana'] = element['user_family_nm_kana'] + " " + element["user_first_nm_kana"]
            temp['email'] = element['email'];
            temp['birthday'] = element['user_birthday']
            temp['telephone'] = element['user_tel_1'];
            temp['id'] = element['id'];

            dataSource.push(temp);
        });
        setTableData(dataSource);
    }

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);
            csloading(true);
            if (menuPatternData['menu_worker_manage_flag_2'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }

            const data = {
                userID: userData['id'],
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstUserListEndPoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setLoaded(true);
                    if (response.status == 200) {
                        gridData(response.data.data);
                    }
                    csloading(false);
                })
                .catch((error) => {
                    csloading(false);
                });

        }
    });

    //navigate user registory update
    const navigateUserUpdate = (param) => {
        navigate("/workerManagement/userRegistory", {
            state: {
                user_id: param.id
            }, replace: false
        });
    }
    // style array
    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_btn_new = [gstyle.btn_new, style.btn_mt];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <div className={gstyle.border}>
                <p className={gstyle.text}>社員リスト</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* button 設定 */}
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        {/* table */}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '0px 50px' }}>
                            <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                                <button className={style_btn_new.join(" ")} onClick={(e) => navigate('/workerManagement/userRegistory')} >新規登録</button>
                            </div>
                            <div className={style_item_tbl.join(" ")}>
                                <ThemeProvider theme={createTheme(theme, jaJP)}>
                                    <MaterialReactTable
                                        columns={columns}
                                        data={tableData}
                                        enableTopToolbar={true}
                                        enableSelectAll={false}
                                        enableGlobalFilter={true}
                                        enableColumnFilters={false}
                                        initialState={{
                                            showColumnFilters: true,
                                            showGlobalFilter: true,
                                        }}
                                        localization={MRT_Localization_JA}
                                        muiTableBodyProps={{
                                            sx: {
                                                '& tr:nth-of-type(odd)': {
                                                    backgroundColor: '#f5f5f5',
                                                    border: '1px solid #e0e0e0',
                                                },
                                            },
                                        }}
                                        muiTableBodyCellProps={{
                                            sx: {
                                                border: '1px solid #e0e0e0', 
                                                textAlign: 'center'
                                            },
                                        }}
                                        muiTableHeadCellProps={{
                                            sx: {
                                                border: '1px solid #e0e0e0', 
                                                '.Mui-TableHeadCell-Content': {
                                                    display: 'flex',
                                                    justifyContent: 'center',

                                                },
                                                backgroundColor: '#cfcfcf'
                                            }
                                        }}
                                        // muiTableBodyRowProps={({ row }) => ({
                                        //     onClick: (event) => {
                                        //         console.info(event, row.id);
                                        //     },
                                        //     sx: {
                                        //         cursor: 'pointer', 
                                        //     },
                                        // })}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserIndex;