//====================================================================================================
//	作成日:2022/08/25		最終更新日:2022/08/29
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import { useContext } from 'react';
import { RegisterContext } from '../register_context/RegisterContext';
import font from '../font/Font.module.css';
import style from './InputTableText.module.css';

const ItemsNumberText = ( props )=> {
    // registerのcontext
    const register = useContext( RegisterContext );

    const textStyle = style.text + " " + font.font;

    return (
        <td className={ props.text + " " + style.td }>
            <input className={ textStyle } type="text" tabIndex={props.tab_index} {...register(props.name)}/>
        </td>
    );
}

export default ItemsNumberText;