//====================================================================================================
//	在庫移動処理 -> AdobeXD 23ページ
//	作成日:2022/08/25		最終更新日:2022/08/29
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
import MyTextBox from "../../../common/tenjin_tokubuchi/my_text_box/MyTextBox";
import style from "./BillingDivision.module.css";

const BillingDivision = ()=> {
    return (
        <MyTextBox
            style_flex={`${style.flex}`}
            style_title={`${style.title}`}
            style_input_text={`${style.input_text}`}
            title_text={"請求区分"}
            name={"BillingDivision"}
            tab_index={4}
        />
    );
}

export default BillingDivision;