/* eslint-disable eqeqeq */
import style from './Master.module.css'
import HeadingText from '../../components/administrator/headingText/HeadingText';
import LinkButton from '../../components/administrator/linkButton/LinkButton';
import * as useJwt  from '../../auth/jwt/useJwt.js';

const App = () => {
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const userData = JSON.parse(useJwt.getUserData());
    return (
        <>
            <label className='bodyLabel'>作業管理</label>
            <div className='contentBody'>
            <HeadingText color='#F8C461' title='基本マスタ' />
            <div className={style.links}>
                {	(menuPatternData['menu_master_flag_1'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/warehouse' title='倉庫マスタ' />
				}
                {	(menuPatternData['menu_master_flag_2'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/location' title='棚マスタ' />
				}
                {	(menuPatternData['menu_master_flag_3'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/shipper' title='荷主マスタ' />
				}
                {	(menuPatternData['menu_master_flag_4'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/consignor' title='荷送人マスタ' />
				}
            </div>
            <div className={style.links}>
                {	(menuPatternData['menu_master_flag_5'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/distination' title='届け先マスタ' />
				}
                {	(menuPatternData['menu_master_flag_6'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/item-type' title='品種マスタ' />
				}
                {	(menuPatternData['menu_master_flag_7'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/item' title='商品マスタ' />
				}
                {	(menuPatternData['menu_master_flag_8'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/rot' title='ロットマスタ' />
				}
            </div>
            <div className={style.links}>
                {	(menuPatternData['menu_master_flag_9'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/shipperItem' title='荷主品番マスタ' />
				}
                {	(menuPatternData['menu_master_flag_10'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/carrier' title='運送業者マスタ' />
				}
                {	(menuPatternData['menu_master_flag_11'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/freightage' title='諸掛マスタ' />
				}
                {	(menuPatternData['menu_master_flag_12'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/summary' title='概要マスタ' />
				}
            </div>
            <div className={style.links}>
                {	(menuPatternData['menu_master_flag_13'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/area' title='地区マスタ' />
				}
                {	(menuPatternData['menu_master_flag_14'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/category' title='分類マスタ' />
				}
                <LinkButton link='/master/tax' title='消費税率マスタ' />
            </div>
            <HeadingText color='#73A776' title='価格改定' />
            <div className={style.links}>
                {	(menuPatternData['menu_master_flag_15'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/priceRevision' title='価格改定処理' />
				}
                
            </div>
            <HeadingText color='#F8C461' title='商品複写' />
            <div className={style.links}>
                {	(menuPatternData['menu_master_flag_16'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/master/itemCopy' title='商品複写体処理' />
				}
            </div>
            </div>
        </>
    );
};

export default App;