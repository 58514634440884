//====================================================================================================
//	作成日:2022/08/25		最終更新日:2022/08/26
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
import font from '../font/Font.module.css';
import style from './InputTableLabel.module.css';

const ItemsNumberTitle = ( props )=> {
    const thStyle = props.style + " " + style.label + " " + font.font;
    return (
        <th className={ thStyle }>
            {props.label}
        </th>
    );
}

export default ItemsNumberTitle;