/* eslint-disable eqeqeq */
import style from './Billing.module.css'
import HeadingText from '../../components/administrator/headingText/HeadingText';
import LinkButton from '../../components/administrator/linkButton/LinkButton';
import * as useJwt  from '../../auth/jwt/useJwt.js';

const App = () => {
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const userData = JSON.parse(useJwt.getUserData());
    return (
		<>
		<label className='bodyLabel'>請求管理</label>
		<div className='contentBody'>
            <HeadingText color='#F8C461' title='請求管理' />
            <div className={style.links}>
                {	(menuPatternData['menu_invoice_flag_1'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/billing/billingList' title='請求一覧表' />
				}
                {	(menuPatternData['menu_invoice_flag_2'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/billing/billingStatement' title='請求明細書' underTitle='/(50003W)' />
				}
                {	(menuPatternData['menu_invoice_flag_3'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/billing/billingStatement2' title='請求明細書' underTitle='(三期縦型)' />
				}
                {/* {	menuPatternData['menu_invoice_flag_4'] == 1 &&
					<LinkButton link='#' title='請求明細書' underTitle='(5003#)' />
				} */}
                {	(menuPatternData['menu_invoice_flag_5'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/billing/freightageList' title='諸掛明細書' />
				}
                {	(menuPatternData['menu_invoice_flag_6'] == 1 || userData['sys_role_id'] == 10000) &&
                    <LinkButton link='/billing/billingQuantityList' title='請求数量確認表' />
				}
            </div>
        </div>
        </>
    );
};

export default App;