import React from 'react';
import style from "./Driver.module.css";
import gstyle from '../../css/GeneralStyle.module.css';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import * as useJwt  from '../../auth/jwt/useJwt.js';

import {Button} from "antd";
import ClipLoader from 'react-spinners/ClipLoader';

const NewDriverWait = (props) => {

  let urlParams = useLocation();
  const navigate = useNavigate();

  const [timeLoaded, setTimeLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [bus, setBus] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);

  useEffect(()=>{
    if(!timeLoaded){
      setTimeout(() => {
        let data = {id : urlParams.state.data.id}
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getDriverScheduleById;
        axios
          .post(url, data)
          .then((response) => {
            let data = response.data;
            if(data.start_date_time){
              setTimeLoaded(true);
              setBus(data.bus_name);
              setWaiting(true);
              setScheduleData(data);
            }
            csLoading(false);
          })
          .catch((error) => {
            csLoading(false);
          });
          setLoaded(!loaded);
        }, 10000);
      }
  })
  
  const csLoading = (flag) => {
    if(flag) {
        document.getElementById('csLoader').style.display="flex";
    }else{
        document.getElementById('csLoader').style.display="none";
    }
  } 
  
  const handleNext = (e) =>{
      navigate("/driver/working", {
        state: {
          data : scheduleData,
          companyName: urlParams.state.companyName,
          warehouseName : urlParams.state.warehouseName,
        },
        replace: false,
      });
  }

  //style
  const style_header = [style.content_flex_column, style.title, style.content_align_center, style.mt70];
  const style_content = [style.content_flex_column, style.mt30];
  const style_content_but = [style.content_flex_column, style.mt70, style.but_gap, style.content_align_center];

  return (
    <div className={style.content_flex_column}>
      <div className={gstyle.loader_container} style={{display:'flex'}} id="csLoader">
          <ClipLoader color={'#fff'} size={60} />
      </div>
          <div className={style_header.join(" ")}>
            <label>{urlParams.state.companyName}予約システム</label>
            <label className={style.sub_title}>{urlParams.state.warehouseName}</label>
          </div>

          <div className={style_content.join(" ")}>
          {
            waiting ?
            <>
              <label style={{textAlign:'center', fontSize:16}}>お待たせ致しました。</label>
              <label style={{textAlign:'center', fontSize:16}}>{bus}番が空きましたので、そちらで<br/>荷下ろしをお願いします。</label>
            </>
            :
            <>
              <label style={{textAlign:'center', fontSize:16}}>担当者からご連絡あるまで</label>
              <label style={{textAlign:'center', fontSize:16}}>少々おまちください。</label>
            </>
          }
          </div>

          <div className={style_content_but.join(" ")}>
            {
              waiting && 
              <Button className={style.button} size='middle' color='primary' fill='outline' onClick={(e) => handleNext(e)}>次へ</Button>
            }
          </div>

    </div>
  );
}

export default NewDriverWait;
