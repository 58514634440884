/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './FreightageList.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import { Select } from 'antd';


const FreightageList = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    
    
    // slip date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // date type -----------------------------------------------
    const [DateType, setDateType] = useState(0);
    const handleChangeDateType = (event) => {
        setDateType(event);
    };
    const DateTypeData = [ { value:'0', label:'締日指定' }, { value:'1', label:'期締範囲指定' }, ];
    // deadline day ---------------------------------------------
    const [DeadLineDay, setDeadLineDay] = useState(useJwt.formatDate(new Date()));
    const handleChangeDeadLineDay = (date) => {
        setDeadLineDay(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();
            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('type2').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ShipperACode1;
            }else{
                code = ShipperCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ShipperACode2;
            }else{
                code = ShipperCode2;
                acode = value;
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // sub code type -----------------------------------------------
    const [SubCodeType, setSubCodeType] = useState(0);
    const handleChangeSubCodeType = (event) => {
        setSubCodeType(event);
    };
    // header print type -----------------------------------------------
    const [HeaderPrintType, setHeaderPrintType] = useState(0);
    const handleChangeHeaderPrintType = (event) => {
        setHeaderPrintType(event);
    };
    // tax rate ----------------------------------------------------------
    const [TaxRate, setTaxRate] = useState("0.000");
    const getTaxData = () => {
        let data = {};
        data['userID'] = userData['id'];
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTaxEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        }).then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data.length>0) {
                    setTaxRate(response.data.data[0]['tax_1']);
                }
            }
            csloading(false);
        })
        .catch((error) => {
        });
    }
    // self company info ---------------------------------------------
    const [ConsignorName, setConsignorName] = useState("");
    const [ConsignorZip, setConsignorZip] = useState("");
    const [ConsignorAddress1, setConsignorAddress1] = useState("");
    const [ConsignorAddress2, setConsignorAddress2] = useState("");
    const [ConsignorAddress3, setConsignorAddress3] = useState("");
    const [ConsignorTel, setConsignorTel] = useState("");
    const [ConsignorFax, setConsignorFax] = useState("");
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_invoice_flag_5'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            getTaxData();
            const data = {
                userID: userData['id'],
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMainSettingEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        var address = response.data.data['distination_address_1'] + response.data.data['distination_address_2'] + response.data.data['distination_address_3']
                        setConsignorName(response.data.data['company_name']);
                        setConsignorZip(response.data.data['distination_zip']);
                        setConsignorAddress1(address);
                        setConsignorAddress2(response.data.data['distination_address_2']);
                        setConsignorAddress3(response.data.data['distination_address_3']);
                        setConsignorTel(response.data.data['distination_tel']);
                        setConsignorFax(response.data.data['distination_fax']);
                    }
                })
                .catch((error) => {
                });
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        if(DateType == 0) {
            data['deadline_day'] = useJwt.getDay(DeadLineDay);
            data['deadline_year_month'] = useJwt.getYearMonth(DeadLineDay);
        }else{
            // data['start_slip_date'] = StartDate;
            // data['end_slip_date'] = EndDate;
            data['deadline_year_month'] = useJwt.getYearMonth(EndDate);
        }
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        data['sub_code_type'] = SubCodeType;
        data['prev_flg'] = 0;
        
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getFreightageInvoiceEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let freightageData = response.data.data.freightageData;
            // console.log("freightageData", freightageData);
            // console.log("TaxRate",TaxRate);
            if(freightageData.length>0){
                gridMainTable(freightageData);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let data = [];
        let itemCodes = [];
        let header_info = [];
        for(var i=0; i<result.length; i++) {
            let filter_code = ''+result[i]['shipper_code'];
            if(!itemCodes.includes(filter_code)){
                itemCodes.push(filter_code);
                let row = {};
                row['shipper_code'] = result[i]['shipper_code'];
                row['shipper_a_code'] = result[i]['shipper_a_code'];
                row['shipper_name'] = result[i]['shipper_name'];
                row['shipper_zip'] = result[i]['shipper_zip'];
                header_info.push(row);
            }
        }
        for(var i=0; i<itemCodes.length; i++) {
            let row = {};
            row['filter_code'] = itemCodes[i];
            row['header_info'] = header_info[i];
            row['freightage_info'] = [];
            let total_i = 0; // total 諸掛合計
            let total_j = 0; // total 合計
            let total_k = 0; // total 消費税
            let total_l = 0; // total 当月御請求額
            for(var j=0;j<result.length;j++) {
                let filter_code = ''+result[j]['shipper_code'];
                if(itemCodes[i] == filter_code) {
                    let tmp_row = result[j];
                    row['freightage_info'].push(tmp_row);
                    total_i += 1*tmp_row['freightage_price'];
                }
            }
            
            row['header_info']['total_i'] = total_i;
            total_k = (1*total_i*TaxRate/100).toFixed(0);
            row['header_info']['total_k'] = total_k;
            total_l = 1*total_i+1*total_k;
            row['header_info']['total_l'] = total_l;
            data.push(row);
        }
        // console.log("data", data);
        setMainTableData(data);
        if(data.length>0){
            setOpend(true);
        }
    }
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

        
    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('date2')){
                    document.getElementById('date2').focus();
                }else{
                    document.getElementById('date3').focus();
                }
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type3').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            default:
                break;
        }
    }

    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_9 = [gstyle.cs_col, gstyle.cs_col_9];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap2 = [style.p2030];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_wrap4 = [gstyle.p00300015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>諸掛明細表</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 締日区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='type1'/>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>締日区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="締日区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeDateType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={DateTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 締切年月日 */}
                    {DateType==0 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>締切年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={DeadLineDay} onChange={handleChangeDeadLineDay} id='date2' focusId='shipper_code1' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 年月日 */}
                    {DateType==1 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={StartDate} onChange={handleChangeStartDate} id='date3' focusId='date4' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={EndDate} onChange={handleChangeEndDate} id='date4' focusId='shipper_code1' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  補助区分, ヘッダー印字 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>補助区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="補助区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeSubCodeType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={[
                                                {value:'0', label:'一括'},
                                                {value:'1', label:'補助別'},
                                            ]}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>ヘッダー印字</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="ヘッダー印字を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeHeaderPrintType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={[
                                                {value:'0', label:'２頁以降印字無し'},
                                                {value:'1', label:'２頁以降印字有り'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        {/* Title, 発行年月日 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_2.join(" ")}></div>
                            <div className={style_col_8.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>諸掛明細表</h2>
                                </div>
                            </div>
                            <div className={style_col_2.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {  mainTableData.map((data,index) => (
                            <div key={index}>
                                {/* header  */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_3.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        〒 {data['header_info']['shipper_zip']}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {data['header_info']['shipper_name']}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={style_col_2.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                    御中
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={style_col_3.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div>
                                                    <p>
                                                    {
                                                        DateType == 0?useJwt.formatDate2(new Date(DeadLineDay))+" 締"
                                                        :'自 '+useJwt.formatDate2(new Date(StartDate))
                                                    } 
                                                    </p>
                                                    <p>
                                                    {
                                                        DateType == 1 && '至 '+useJwt.formatDate2(new Date(EndDate))
                                                    } 
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={style_col_4.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {'〒 '+ConsignorZip+' '+ConsignorAddress1+" "+ConsignorAddress2+" "+ConsignorAddress3}
                                                    </span>
                                                </div>
                                            </label>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        TEL:
                                                    </span>
                                                    <span className={style.cs_span_3}>
                                                        {ConsignorTel}
                                                    </span>
                                                    <span className={style.cs_span_3}>
                                                        FAX:
                                                    </span>
                                                    <span className={style.cs_span_3}>
                                                        {ConsignorFax}
                                                    </span>
                                                </div>
                                            </label>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {ConsignorName}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* table */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_2.join(" ")}>
                                    </div>
                                    <div className={style_col_8.join(" ")}>
                                        <div className={style_input_wrap3.join(" ")}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>合　計</th>
                                                        <th>消費税</th>
                                                        <th>御請求額</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_i'])}</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_k'])}</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_l'])}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className={style_col_2.join(" ")}>
                                    </div>
                                </div>
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap3.join(" ")}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>伝票日付</th>
                                                        <th>伝票No</th>
                                                        <th>諸掛名</th>
                                                        <th>数量</th>
                                                        <th>単価</th>
                                                        <th>金額</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {  data['freightage_info'].map((data1,index1) => (
                                                        <React.Fragment key={index1}>
                                                            <tr>
                                                                <td>{useJwt.formatDate(new Date(data1['slip_date']))}</td>
                                                                <td>{data1['slip_no']}</td>
                                                                <td className={style.td_left}>{data1['freightage_name']}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['freightage_num'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['freightage_unit_price'])}</td>
                                                                <td className={style.td_right}>￥{useJwt.numberWithCommas(data1['freightage_price'])}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )) }
                                                    <tr>
                                                        <td colSpan={5}>【 合 計 】</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_i'])}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'諸掛明細表_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default FreightageList;