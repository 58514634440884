//====================================================================================================
//	在庫移動処理 -> AdobeXD 23ページ
//	作成日:2022/08/25		最終更新日:2022/08/30
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
// ---------- テーブルのテキスト 下段 ----------
import style from './TableTextBottom.module.css';
import ExportLocationText from '../13_export_location/text/Text';
import ImportLocationText from '../14_import_location/text/Text';
import LotText from '../15_lot/text/Text';
import CaseNumText from '../16_case_num/text/Text';
import SingleNumText from '../17_single_num/text/Text';
import VolumeText from '../18_volume/text/Text';
import MemoText from '../19_memo/text/Text';

const TableTextBottom = (props)=> {
    return (
        <tr className={style.flex}>
            <ExportLocationText tab_index={Number(props.tab_index)} name_id={props.name_id} />
            <ImportLocationText tab_index={Number(props.tab_index) + 1} name_id={props.name_id} />
            <LotText tab_index={Number(props.tab_index) + 2} name_id={props.name_id} />
            <CaseNumText tab_index={Number(props.tab_index) + 3} name_id={props.name_id} />
            <SingleNumText tab_index={Number(props.tab_index) + 4} name_id={props.name_id} />
            <VolumeText tab_index={Number(props.tab_index) + 5} name_id={props.name_id} />
            <MemoText tab_index={Number(props.tab_index) + 6} name_id={props.name_id} />
        </tr>
    )
}

export default TableTextBottom;