/* eslint-disable eqeqeq */
import style from "./CsvImport.module.css";
import HeadingText from "../../components/administrator/headingText/HeadingText";
import LinkButton from "../../components/administrator/linkButton/LinkButton";
import * as useJwt  from '../../auth/jwt/useJwt.js';

const CsvImport = () => {
  const menuPatternData = JSON.parse(useJwt.getMenuPatternData()); 
  const userData = JSON.parse(useJwt.getUserData());

  return (
    <>
      <label className='bodyLabel'>CSVインポート</label>
      <div className='contentBody'>
      <HeadingText color="#1188E8" title="マスターインポート" />
      <div className={style.links}>
        {	(menuPatternData['csv_flag_1'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/shipperImport" title="荷主マスタ" />
				}
        {	(menuPatternData['csv_flag_2'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton
            link="/csvImport/shipperItemImport"
            title="荷主（適用）マスタ"
          />
				}
        {	(menuPatternData['csv_flag_17'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/warehouse" title="倉庫マスタ" />
				}
        {	(menuPatternData['csv_flag_18'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/location" title="棚マスタ" />
				}
        {	(menuPatternData['csv_flag_3'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/carrier" title="運送業者マスタ" />
				}
        {	(menuPatternData['csv_flag_4'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/category" title="分類マスタ" />
				}
        {	(menuPatternData['csv_flag_5'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/itemType" title="品種マスタ" />
				}
        {	(menuPatternData['csv_flag_6'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/item" title="商品マスタ" />
				}
        {	(menuPatternData['csv_flag_7'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/shipperItemCode" title="荷主品番マスタ" />
        }
        {	(menuPatternData['csv_flag_8'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/consignor" title="荷送人マスタ" />
        }
        {	(menuPatternData['csv_flag_9'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/freightage" title="諸掛マスタ" />
        }
        {	(menuPatternData['csv_flag_10'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/area" title="地区マスタ" />
        }
        {	(menuPatternData['csv_flag_11'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/summary" title="摘要マスタ" />
        }
        {	(menuPatternData['csv_flag_12'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/destination" title="届け先マスタ" />
        }
        {	(menuPatternData['csv_flag_13'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/csvImport/rot" title="ﾛｯﾄマスタ" />
        }
      </div>
      <HeadingText color="#73A776" title="価格改定インポート" />
      <div className={style.links}>
      {	(menuPatternData['csv_flag_14'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton
            link="/csvImport/priceDay"
            title="荷主別改定日順"
          />
        }
        {	(menuPatternData['csv_flag_15'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton
            link="/csvImport/priceItem"
            title="荷主別商品順"
          />
        }
      </div>
      <HeadingText color="#f8c461" title="入出庫インポート" />
        <div className={style.links}>
          {	(menuPatternData['csv_flag_16'] == 1 || userData['sys_role_id'] == 10000) &&
            <LinkButton link="/csvImport/items" title="入出庫" />
          }
          {	(menuPatternData['csv_flag_19'] == 1 || userData['sys_role_id'] == 10000) &&
            <LinkButton link="/csvImport/stock" title="在庫" />
          }
        </div>
      </div>
    </>
  );
};

export default CsvImport;
