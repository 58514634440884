/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import axios from "axios";
import style from './Modal.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import Modal from './Modal';
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { jaJP } from '@mui/material/locale';

const ShipperItemModal = props => {

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setSelfCompanyFlag(props.SearchValue3 ? true : false)
    },[props.SearchValue3]);

    const userData = JSON.parse(useJwt.getUserData());
    const theme = useTheme();
    const [selfCompanyFlag, setSelfCompanyFlag] = useState(props.SearchValue3 ? true : false)

    const columns = [
        {
            id: 'company_name',
            accessorKey: 'company_name',
            enableColumnActions: false,
            header: '倉庫会社',
            size: 150
        },
        {
            id: 'item_name',
            accessorKey: 'item_name',
            enableColumnActions: false,
            header: '名称',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.item_name}</div>
            ),
            size: 300
        },
        {
            id: 'item_a_name',
            accessorKey: 'item_a_name',
            enableColumnActions: false,
            header: '名称(ﾌﾘｶﾞﾅ)',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.item_a_name}</div>
            ),
            size: 200
        },
        {
            id: 'shipper_item_code',
            accessorKey: 'shipper_item_code',
            enableColumnActions: false,
            header: '荷主品番コード',
            size: 200
        },
        {
            id: 'item_quantity',
            accessorKey: 'item_quantity',
            enableColumnActions: false,
            header: '入数',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.item_quantity}</div>
            ),
            size: 100
        },
    ];

    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_select_40 = [gstyle.cs_select, gstyle.w170px];
    const style_input_100 = [gstyle.cs_input, gstyle.w300px, gstyle.cs_bl_n];

    const [SearchFieldType, setSearchFieldType] = useState(0);
    const handleChangeSearchFieldType = (event) => {
        setSearchFieldType(event.target.value);
    }
    const handleChangeSearchValue = (event) => {
        props.onChange(event.target.value);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            searchData();
        }
    }
    const searchData = () => {
        let data = {};
        data['userID'] = userData['id'];
        data['shipper_user_id'] = userData['id'];
        data['create_user_id'] = props.SearchValue2;
        data['shipper_code'] = props.SearchValue3;

        if (props.WarehouseId) {
            data['warehouse_id'] = props.WarehouseId;
        }
        if (SearchFieldType == 0) {
            data['item_name'] = props.SearchValue;
        } else if (SearchFieldType == 1) {
            data['item_name_kana'] = props.SearchValue;
        } else {
            data['shipper_item_code'] = props.SearchValue;
        }
        data['filterByLike'] = 1;
        if (document.getElementById("myLifeWarehouse")) {
            if(document.getElementById("myLifeWarehouse").checked)  data['myLifeWarehouse'] = '1'
            else    data['myLifeWarehouse'] = '2'
        } else {
            data['myLifeWarehouse'] = '3';
        }
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperMstItemEndpoint;

        props.setTableData([]);
        setLoaded(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(false);
                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        props.setTableData(response.data.data);
                    }
                    if (response.data.data.length == 0) toast.error("検索結果はありません。", { autoClose: 3000 });
                }
            })
            .catch((error) => {
                setLoaded(false);
                toast.error("検索結果はありません。", { autoClose: 3000 });
            });
    }
    const handleChangeData = (itemData) => {
        let data = itemData;
        data['new'] = selfCompanyFlag
        props.target(itemData, props.targetParam != null ? props.targetParam : '');
        props.onClose(true);
        props.setTableData([]);
    }

    const handleCompanyCheck = (e) => {
        setSelfCompanyFlag(!selfCompanyFlag)
    }

    return (
        <Modal title="商品検索" show={props.show} onClose={props.onClose} width={props.width}>
            <div className={gstyle.hyfiled}>
            </div>
            <div className={gstyle.hyfiled}>
                <div className={style.cs_tbl} style={{ minHeight: 610 }}>
                    <ThemeProvider theme={createTheme(theme, jaJP)}>
                        <MaterialReactTable
                            columns={columns}
                            data={props.tableData}
                            enableTopToolbar={true}
                            enableSelectAll={false}
                            enableGlobalFilter={true}
                            enableColumnFilters={false}
                            initialState={{
                                showColumnFilters: true,
                                showGlobalFilter: true,
                            }}
                            localization={MRT_Localization_JA}
                            muiTableBodyProps={{
                                sx: {
                                    '& tr:nth-of-type(odd)': {
                                        backgroundColor: '#f5f5f5',
                                        border: '1px solid #e0e0e0',
                                    },
                                    'td': {
                                        padding: '0.4rem'
                                    }
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    border: '1px solid #e0e0e0',
                                    textAlign: 'center'
                                },
                            }}
                            muiTableHeadCellProps={{
                                sx: {
                                    border: '1px solid #e0e0e0',
                                    '.Mui-TableHeadCell-Content': {
                                        display: 'flex',
                                        justifyContent: 'center',

                                    },
                                    backgroundColor: '#cfcfcf'
                                }
                            }}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {
                                    handleChangeData(row.original);
                                },
                                sx: {
                                    cursor: 'pointer',
                                },
                            })}
                            renderTopToolbarCustomActions={({ table }) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <select className={style_select_40.join(" ")} id="SearchFieldType"
                                                value={SearchFieldType}
                                                onChange={handleChangeSearchFieldType}>
                                                <option value="0">名称</option>
                                                <option value="1">名称(ﾌﾘｶﾞﾅ)</option>
                                                <option value="2">荷主品番コード</option>
                                            </select>
                                            <input type={'text'} className={style_input_100.join(" ")}
                                                value={props.SearchValue} onKeyDown={handleKeyDown}
                                                onChange={handleChangeSearchValue} />
                                        </div>
                                    </label>
                                    <Button onClick={searchData} loading={loaded} type={'primary'} style={{ marginLeft: 30, width: 120, height: 35, borderRadius: 'unset' }}>
                                        {loaded ? '検索中' : '検索'}
                                    </Button>
                                    {
                                        props.SearchValue3 &&
                                        (selfCompanyFlag ?
                                            <label className={gstyle.cs_label}>
                                                <div className={gstyle.cs_flex}>
                                                    <input type={"checkbox"} className={style.cs_input_check} onClick={handleCompanyCheck} id="myLifeWarehouse" checked />
                                                    <span>選択した倉庫会社</span>
                                                </div>
                                            </label>
                                            :
                                            <label className={gstyle.cs_label}>
                                                <div className={gstyle.cs_flex}>
                                                    <input type={"checkbox"} className={style.cs_input_check} onClick={handleCompanyCheck} id="myLifeWarehouse" />
                                                    <span>選択した倉庫会社</span>
                                                </div>
                                            </label>
                                        )
                                    }
                                </div>
                            )}
                        />
                    </ThemeProvider>
                </div>
            </div>
        </Modal>
    )
}
export default ShipperItemModal;




