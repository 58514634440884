/* eslint-disable eqeqeq */
import React, {useState, useEffect} from 'react';
import axios from "axios";
import style from './Modal.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import Modal from './Modal';
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { toast } from 'react-toastify';
import { Button } from 'antd';

const BusModal = props => {

    const userData = JSON.parse(useJwt.getUserData());
    const [loaded, setLoaded] = useState(false);

    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_50 = [gstyle.cs_input, gstyle.w50];

    const style_th_col_1 = [style.cs_th, gstyle.cs_col_1];
    const style_th_col_4 = [style.cs_th, gstyle.cs_col_4];
    const style_td_col_1 = [style.cs_td, gstyle.cs_col_1];
    const style_td_col_4 = [style.cs_td, gstyle.cs_col_4];

    const handleChangeSearchValue = (event) => {
        props.onChange(event.target.value);
    }

    const searchData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        data['warehouse_id'] = props.SearchValue;
        if(props.SearchValue2)     data['bus_name'] = props.SearchValue2;

        if(document.getElementById("RotFilterByLike").checked){
            data['filterByLike'] = 1;
        }else{
            data['filterByLike'] = 0;
        }
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getBusSettingEndpoint;
        setLoaded(true);
        props.setBusTableData([]);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            setLoaded(false);
            if(response.status == 200) {
                if(response.data.data.length > 0){
                    let result = response.data.data;
                //     props.target(result,props.targetParam!=null?props.targetParam:'');
                //     props.onClose(true);
                // }else{
                    let tempList = [];
                    for(var i = 0; i < result.length; i++){
                        let temp = {};
                        temp['id'] = result[i]['id'];
                        temp['warehouse_id'] = result[i]['warehouse_id'];
                        temp['bus_name'] = result[i]['bus_name'];

                        let standardList = JSON.parse(result[i]['standard']);
                        let tempStandard = "";
                        for(var j = 0; j < standardList.length; j++){
                            if(j != 0)  tempStandard += '、'
                            if(standardList[j] == "1")  tempStandard += '4t';
                            if(standardList[j] == "2")  tempStandard += '10t';
                            if(standardList[j] == "3")  tempStandard += 'トレーラー';
                        }
                        temp['standard'] = tempStandard;
                        tempList.push(temp);
                    }
            
                    props.setBusTableData(tempList);
                }
                if(response.data.data.length == 0)  toast.error("検索結果はありません。", { autoClose: 3000 });
            }
        })
        .catch((error) => {
            setLoaded(false);
            toast.error("検索結果はありません。", { autoClose: 3000 });
        });
    }
    const handleChangeData = (event, busName, standard, id) => {
        event.preventDefault();

        let data = {};
        data['bus_name'] = busName;
        data['standard'] = standard;
        data['id'] = id;
        props.target(data,props.targetParam!=null?props.targetParam:'');
        props.onClose(true);
    }

    return (
        <Modal title="バース検索" show={props.show} onClose={props.onClose} width={props.width}>
            <div className={gstyle.hyfiled}>
                <div style={{display: 'flex', alignItems:'center'}}>
                    <label className={style_label_wrap.join(" ")}>
                        <div className={gstyle.cs_flex}>
                            <span style={{width:90, marginLeft:20}}>バース</span>
                            <input type={'text'} className={style_input_50.join(" ")} 
                                value={props.SearchValue2} onChange={handleChangeSearchValue}/>
                        </div>
                    </label>
                    <Button onClick={searchData} loading={loaded} type={'primary'} style={{marginLeft: 30, width:120, height:35, borderRadius:'unset'}}>
                        { loaded ? '検索中' : '検索'}
                    </Button>
                    <label className={gstyle.cs_label}>
                        <div className={gstyle.cs_flex}>
                            <input type={"checkbox"} className={style.cs_input_check} id="RotFilterByLike" />
                            <span>部分検索</span>
                        </div>
                    </label>
                </div>
            </div>
            <div className={gstyle.hyfiled} style={{justifyContent:'center'}}>
                <div className={style.cs_tbl}>
                    <div className={style.cs_thead} >
                        <div className={style.cs_tr} style={{justifyContent:'center'}} >
                            {/* <div className={style_th_col_1.join(" ")} >ID</div> */}
                            <div className={style_th_col_4.join(" ")}>バース</div>
                            <div className={style_th_col_4.join(" ")}>バース規格</div>
                        </div>
                    </div>
                    <div className={style.cs_tbody}>
                        { props.busTableData.map((data) => (
                            <div className={style.cs_tr} key={data['id']}  style={{justifyContent:'center'}}
                            onClick={(e)=>handleChangeData(e,data['bus_name'], data['standard'], data['id'])}>
                                {/* <div className={style_td_col_1.join(" ")}>{data['id']}</div> */}
                                <div className={style_td_col_4.join(" ")}>{data['bus_name']}</div>
                                <div className={style_td_col_4.join(" ")}>{data['standard']}</div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default BusModal;
