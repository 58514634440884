/* eslint-disable eqeqeq */
import React, {useState} from 'react';
import axios from "axios";
import style from './Modal.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import Modal from './Modal';
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { toast } from 'react-toastify';
import { Button } from 'antd';

const ScheduleModal = props => {

    const [loaded, setLoaded] = useState(false);

    const userData = JSON.parse(useJwt.getUserData());

    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_50 = [gstyle.cs_input];

    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];

    const handleChangeSearchValue = (event) => {
        props.onChange(event.target.value);
    }

    const handleKeyDown = (event) => {
        if(event.keyCode === 13){
            searchData();
        }
    }
    
    const searchData = () => {
        let data = {};
        data['userID'] = userData['id'];
        data['warehouse_id'] = props.SearchValue;
        data['schedule_date'] = props.SearchValue2;

        if(document.getElementById("WorkerFilterByLike").checked){
            data['filterByLike'] = 1;
        }else{
            data['filterByLike'] = 0;
        }
        console.log('wwww');
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getScheduleListByWarehouseEndpoint;
        setLoaded(true);
        props.setScheduleTableData([]);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            setLoaded(false);
            if(response.status == 200) {
                if(response.data.data.length > 0){
                //     let result = response.data.data[0];
                //     props.target(result,props.targetParam!=null?props.targetParam:'');
                //     props.onClose(true);
                // }else{
                    props.setScheduleTableData(response.data.data);
                }

                if(response.data.data.length == 0)  toast.error("検索結果はありません。", { autoClose: 3000 });

            }
        })
        .catch((error) => {
            console.log(error);
            setLoaded(false);
            toast.error("検索結果はありません。", { autoClose: 3000 });
        });
    } 
    const handleChangeData = (event, sysScheduleId) => {
            event.preventDefault();
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_id'] = props.SearchValue;
            data['schedule_date'] = props.SearchValue2;
            data['sys_schedule_id'] = sysScheduleId;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getScheduleListByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    if(response.data.data.length==1){
                        let result = response.data.data[0];
                        props.target(result,props.targetParam!=null?props.targetParam:'');
                        props.onClose(true);
                    }else{
                        props.setScheduleTableData([]);
                        props.onClose(true);
                    }
                }
            })
            .catch((error) => {
                props.onClose(true);
            });
    }

    return (
        <Modal title="スケジュール検索" show={props.show} onClose={props.onClose} width={props.width}>
            <div className={gstyle.hyfiled}>
                <div style={{display: 'flex', alignItems:'center'}}>
                    <label className={style_label_wrap.join(" ")}>作業員名&nbsp;</label>
                    <div className={gstyle.cs_flex}>
                        <input type={'text'} className={style_input_50.join(" ")} 
                            value={props.SearchValue3} onKeyDown={handleKeyDown}
                            onChange={handleChangeSearchValue} />
                    </div>
                    
                    <Button onClick={searchData} loading={loaded} type={'primary'} style={{marginLeft: 30, width:120, height:35, borderRadius:'unset'}}>
                        { loaded ? '検索中' : '検索'}
                    </Button>
                    <label className={gstyle.cs_label}>
                        <div className={gstyle.cs_flex}>
                            <input type={"checkbox"} className={style.cs_input_check} id="WorkerFilterByLike" />
                            <span>部分検索</span>
                        </div>
                    </label>
                </div>
            </div>
            <div className={gstyle.hyfiled}>
                <div className={style.cs_tbl}>
                    <div className={style.cs_thead}>
                        <div className={style.cs_tr}>
                            <div className={style.cs_th}>荷主名称</div>
                            <div className={style_th_col_2.join(" ")}>作業員名</div>
                            <div className={style.cs_th}>開始予定時間</div>
                            <div className={style.cs_th}>終了予定時間</div>
                        </div>
                    </div>
                    <div className={style.cs_tbody}>
                        { props.scheduleTableData.map((data) => (
                            <div className={style.cs_tr} key={data['sys_schedule_id']} onClick={(e)=>handleChangeData(e,data['sys_schedule_id'])}>
                                <div className={style.cs_td}>{data['shipper_name']}</div>
                                <div className={style_td_col_2.join(" ")}>{data['user_family_nm_kanji']+ ' ' +data['user_first_nm_kanji']}</div>
                                <div className={style.cs_td}>{data['start_date_time']}</div>
                                <div className={style.cs_td}>{data['end_date_time']}</div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default ScheduleModal;




