/* eslint-disable eqeqeq */
import style from './Location.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import IconSearch from '../../../components/common/icons/icon_search.png';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import { Button, Select, Radio } from 'antd';
import $ from 'jquery';
import { QRCodeCanvas } from "qrcode.react";
import { useReactToPrint } from "react-to-print";
import { Barcode } from '@progress/kendo-react-barcodes';


const Location = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    // ========================== print =================================================
    const QRRef = useRef();
    const [itemInformation, setItemInformation] = useState([]);


    const handlePrint = useReactToPrint({
        content: () => QRRef.current,
    });

    const codePrint = () => {
        if (itemInformation.length == 0) {
            toast.error("棚を選択してください。", { autoClose: 3000 });
            return;
        }

        handlePrint();
    }

    const QRGenerate = () => {
        let data = [];
        var x = 0;

        var checkboxes = $('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            console.log(checkboxes)
            let temp = {};
            if (checkboxes[i].checked) {
                temp['code'] = WarehouseCode + '&&' + mainTableData[i]['table_code']
                    + '&&' + mainTableData[i]['table_row'] + '&&' + mainTableData[i]['table_column']
                    + '&&' + mainTableData[i]['table_floor'] + '&&' + userData['id'];
                temp['name'] = WarehouseName + ' ' + '棚番号:' + mainTableData[i]['table_row'] + ' 番地:' + mainTableData[i]['table_column']
                    + ' 段:' + mainTableData[i]['table_floor'];
                data.push(temp);
                x++;
            }
        }

        if (data.length == 0) {
            setItemInformation([]);
        }

        setItemInformation(data);
    }

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [WarehouseCode, setWarehouseCode] = useState("");
    const handleChangeWarehouseCode = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 2) {
            str = str.substring(0, 2);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setWarehouseCode(str);
    };
    const [WarehouseName, setWarehouseName] = useState("");

    const onKeyDownWarehouseCode = (event) => {
        if (event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value);
            event.preventDefault();
        }
    };

    const getWarehouseData = (code) => {
        if (code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            csloading(true);
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    csloading(false);
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWarehouseData(result);
                        } else {
                            setWarehouseData([]);
                        }
                    }
                })
                .catch((error) => {
                    csloading(false);
                });
        } else {
            setWarehouseData([]);
        }
    }
    const setWarehouseData = (data) => {
        if (data['warehouse_code']) {
            setWarehouseCode(data['warehouse_code']);
            setWarehouseName(data['warehouse_name']);
            let filterData = {};
            filterData['warehouse_code'] = data['warehouse_code'];
            getLocationData(filterData);
        } else {
            setWarehouseName("");
            gridMainTable([]);
        }
    }

    const getLocationData = (result) => {
        let data = {};
        data['userID'] = userData['id'];
        data['warehouse_code'] = result['warehouse_code'];
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstLocationEndpoint;
        csloading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                csloading(false);

                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        let result = response.data.data;
                        gridMainTable(result);
                        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
                        for (let i = 0; i < checkboxes.length; i++) {
                            checkboxes[i].checked = false;
                        }
                    } else {
                        gridMainTable([]);
                    }
                }
            })
            .catch((error) => {
                csloading(false);
            });
    }

    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        setMainTableData([]);
        setMainTableData(result);
    }

    const handlChangeLocationCodeData = (event, index) => {
        let value = event.target.value;
        if (value.length > 8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row['table_code'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }

    const handlChangeLocationRowData = (event, index) => {
        let value = event.target.value;
        if (value.length > 8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row['table_row'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }

    const handlChangeLocationColumnData = (event, index) => {
        let value = event.target.value;
        if (value.length > 8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row['table_column'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }

    const handlChangeLocationFloorData = (event, index) => {
        let value = event.target.value;
        if (value.length > 8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row['table_floor'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }

    const allCheckData = (event) => {
        $('#tbodyWrap input[type="checkbox"]').prop('checked', event.target.checked);
        QRGenerate();
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
        QRGenerate();
    };

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            if (menuPatternData['menu_daily_flag_27'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
    });

    // ========================== starts code for search ===============================
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const loadWarehouseModalData = (event) => {
        event.preventDefault();
        setWarehouseSearchValue(WarehouseCode);

        if (WarehouseCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            if (WarehouseCode != "") {
                data['warehouse_code'] = WarehouseCode;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWarehouseTableData(response.data.data);
                    setShowModalWarehouse(true);
                })
                .catch((error) => {
                });
        } else {
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    const [codeType, setCodeType] = useState("1");
    const onChangeCodeType = (e, type) => {
        setCodeType(type);
    }
    const pageSizeData = [
        [{ x: 2, y: 4 }, { x: 10, y: 0 }, { x: 90, y: 60 }, { x: 10, y: 5 }, { x: 8 }],
        [{ x: 2, y: 5 }, { x: 20, y: 0 }, { x: 80, y: 45 }, { x: 10, y: 5 }, { x: 10 }],
        [{ x: 2, y: 6 }, { x: 10, y: 0 }, { x: 90, y: 35 }, { x: 10, y: 5 }, { x: 12 }],
        [{ x: 3, y: 6 }, { x: 10, y: 0 }, { x: 60, y: 35 }, { x: 5, y: 5 }, { x: 18 }],
    ];

    const [column, setColumn] = useState(pageSizeData[0][0]['x'])
    const [row, setRow] = useState(pageSizeData[0][0]['y'])
    const [pageLR, setPageLR] = useState(pageSizeData[0][1]['x'])
    const [pageTB, setPageTB] = useState(pageSizeData[0][1]['y'])
    const [qrWidth, setQrWidth] = useState(pageSizeData[0][2]['x'])
    const [qrHeight, setQrHeight] = useState(pageSizeData[0][2]['y'])
    const [btweenLR, setBtweenLR] = useState(pageSizeData[0][3]['x'])
    const [btweenTB, setBtweenTB] = useState(pageSizeData[0][3]['y'])
    const [limitCount, setLimitCount] = useState(pageSizeData[0][4]['x'])

    const [sizeKind, setSizeKind] = useState("0")
    const kindData = [{ value: '0', label: 'ページ(2×4)' }, { value: '1', label: 'ページ(2×5)' }, { value: '2', label: 'ページ(2×6)' }, { value: '3', label: 'ページ(3×6)' }];
    const handleSizeKind = (event) => {
        setSizeKind(event);
        var sizeValueArray = pageSizeData[event]
        setColumn(sizeValueArray[0]['x'])
        setRow(sizeValueArray[0]['y'])

        setPageLR(sizeValueArray[1]['x'])
        setPageTB(sizeValueArray[1]['y'])

        setQrWidth(sizeValueArray[2]['x'])
        setQrHeight(sizeValueArray[2]['y'])

        setBtweenLR(sizeValueArray[3]['x'])
        setBtweenTB(sizeValueArray[3]['y'])

        setLimitCount(sizeValueArray[4]['x'])

    };

    const [changeFlag, setChangeFlage] = useState(1)
    const onInputChange = (event, param) => {
        if (param == 1) setColumn(event.target.value)
        if (param == 2) setRow(event.target.value)
        if (param == 3) setPageLR(event.target.value)
        if (param == 4) setPageTB(event.target.value)
        if (param == 5) setQrWidth(event.target.value)
        if (param == 6) setQrHeight(event.target.value)
        if (param == 7) setBtweenLR(event.target.value)
        if (param == 8) setBtweenTB(event.target.value)

    }

    const [layout, setLayout] = useState('left')
    const handleLayoutChange = (e) => {
        setLayout(e)
    }
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];

    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];
    const style_check_ml = [style.check_label, style.job_radio_margin];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <WarehouseModal show={showModalWarehouse} onClose={() => setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue}
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>棚番QRコード印刷</p>
            </div>
            <div className={gstyle.filed} style={{ zIndex: 2 }}>
                <div className={gstyle.cs_card_1}>
                    { /* 倉庫コード */}
                    <div className={style_row.join(" ")} style={{ alignItems: 'end' }}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e)}
                                            value={WarehouseCode} onChange={handleChangeWarehouseCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="WarehouseName"
                                            value={WarehouseName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadWarehouseModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label></label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")} style={{ display: 'flex', justifyContent: 'end' }}>
                            <div className={style_input_wrap.join(" ")}>
                                <div className={style_date_wrap.join(" ")} style={{ margin: 0, marginBottom:10 }}>
                                    <label className={style.check_label} style={{ display: 'flex' }}>
                                        <input type={'radio'} name='code' checked={codeType == 1 ? true : false} onChange={(e) => onChangeCodeType(e, 1)} />
                                        <div style={{ marginLeft: 5 }}>QRコード</div>
                                    </label>

                                    <label style={{ display: 'flex', marginLeft: 20 }}>
                                        <input type={'radio'} name='code' onChange={(e) => onChangeCodeType(e, 2)} checked={codeType == 2 ? true : false} />
                                        <div style={{ marginLeft: 5 }}>バーコード</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={style_col_2.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")} style={{ marginBottom: 10, gap: 30 }}>
                                <Button type={"primary"} style={{ width: 100, backgroundColor: '#ff6116' }} onClick={codePrint}>コード発行</Button>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")} style={{ maxWidth: 485 }}>
                            <table class="input" id="setting">
                                <tbody>
                                    <tr>
                                        <td style={{ width: '30%', backgroundColor: '#d2eaff' }}>テンプレート</td>
                                        <td style={{ backgroundColor: 'aliceblue' }}>
                                            <Select
                                                optionFilterProp="children"
                                                onChange={handleSizeKind}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                style={{ width: '40%' }}
                                                value={sizeKind}
                                                options={kindData}
                                            />
                                            <Radio.Group value={layout} onChange={(e) => handleLayoutChange(e.target.value)} style={{ marginLeft: 10 }}>
                                                <Radio.Button value="left">左</Radio.Button>
                                                <Radio.Button value="center">中</Radio.Button>
                                                <Radio.Button value="flex-end">右</Radio.Button>
                                            </Radio.Group>

                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%', backgroundColor: '#d2eaff' }}>１ページのラベル数</td>
                                        <td style={{ width: 195, backgroundColor: 'aliceblue' }}>
                                            <input style={{ width: 50, height: 20 }} value={column} onChange={(e) => onInputChange(e, 1)} /> 列　×
                                            <input style={{ width: 50, height: 20 }} value={row} onChange={(e) => onInputChange(e, 2)} /> 段
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%', backgroundColor: '#d2eaff' }}>ページ余白</td>
                                        <td style={{ width: 195, backgroundColor: 'aliceblue' }}> 左右：
                                            <input style={{ width: 50, height: 20 }} value={pageLR} onChange={(e) => onInputChange(e, 3)} /> mm　　上下：
                                            <input style={{ width: 50, height: 20 }} value={pageTB} onChange={(e) => onInputChange(e, 4)} /> mm
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%', backgroundColor: '#d2eaff' }}>ラベルサイズ</td>
                                        <td style={{ width: 195, backgroundColor: 'aliceblue' }}> 横幅：
                                            <input style={{ width: 50, height: 20 }} value={qrWidth} onChange={(e) => onInputChange(e, 5)} /> mm　　縦高：
                                            <input style={{ width: 50, height: 20 }} value={qrHeight} onChange={(e) => onInputChange(e, 6)} /> mm
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%', backgroundColor: '#d2eaff' }}>ラベル内余白</td>
                                        <td style={{ width: 195, backgroundColor: 'aliceblue' }}> 左右：
                                            <input style={{ width: 50, height: 20 }} value={btweenLR} onChange={(e) => onInputChange(e, 7)} /> mm　　上下：
                                            <input style={{ width: 50, height: 20 }} value={btweenTB} onChange={(e) => onInputChange(e, 8)} /> mm
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={gstyle.hyfiled}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_col_2.join(" ")}>棚コード</div>
                                    <div className={style_th_col_2.join(" ")}>棚番号</div>
                                    <div className={style_th_col_2.join(" ")}>番地</div>
                                    <div className={style_th_col_2.join(" ")}>段</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {mainTableData.map((data, index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                                id={'chk_' + index} onChange={handleChangeChk} />
                                            <input type="hidden" id={'sys_table_id_' + index} value={data['sys_table_id']} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input}
                                                id={'table_code_' + data['sys_table_id']}
                                                value={data['table_code']}
                                                onChange={(e) => handlChangeLocationCodeData(e, index)} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input}
                                                id={'table_row' + data['sys_table_id']}
                                                value={data['table_row']}
                                                onChange={(e) => handlChangeLocationRowData(e, index)} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input}
                                                id={'table_column' + data['sys_table_id']}
                                                value={data['table_column']}
                                                onChange={(e) => handlChangeLocationColumnData(e, index)} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input}
                                                id={'table_floor' + data['sys_table_id']}
                                                value={data['table_floor']}
                                                onChange={(e) => handlChangeLocationFloorData(e, index)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 500, display: 'none' }}>
                    <div ref={QRRef} id="QRDiv">
                        <div>
                            <div style={{ marginTop: pageTB + "mm", marginLeft: pageLR + "mm", display: 'flex', flexWrap: 'wrap', maxWidth: '210mm' }}>
                                {itemInformation.map((item, index) => (
                                    <>
                                        {
                                            index == limitCount &&
                                            <div style={{ width: '210mm', display: 'flex', marginTop: pageTB + "mm", pageBreakBefore: 'always' }}></div>
                                        }
                                        <div style={{ width: qrWidth + "mm", height: (parseInt(qrHeight) + 5) + 'mm', display: 'flex', marginTop: btweenTB + 'mm', justifyContent: layout }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {item['name']}
                                                </div>
                                                <div >
                                                    {
                                                        codeType == "1" ?
                                                            <QRCodeCanvas
                                                                id="qrCode"
                                                                value={item['code']}
                                                                style={{ width: qrHeight + 'mm', height: qrHeight + 'mm' }}
                                                                bgColor={"#ffffff"}
                                                                level={"H"}
                                                                key={index}
                                                                removeAfterPrint
                                                            />
                                                            :
                                                            <Barcode value={item['code']} format="CODE128" width={qrWidth} height={qrHeight} key={index} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* <div ref={QRRef} id="QRDiv">
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <div style={{marginTop:30}}>
                                {itemInformation.map((item, index) => (
                                    <>
                                    <div style={{display:'flex', justifyContent:'center'}} key={"name" + index}>
                                        {item['name']}
                                    </div>
                                    <div style={{marginBottom:20}} >
                                    {
                                        codeType == "1" ? 
                                            <QRCodeCanvas
                                            id="qrCode"
                                            value={item['code']}
                                            size={300}
                                            bgColor={"#ffffff"}
                                            level={"H"}
                                            key={index}
                                            removeAfterPrint
                                            />
                                        :
                                        <Barcode value={item['code']} format="CODE128" width={520} height={170} key={index}/>
                                    }
                                    </div>
                                </>
                                ))}
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Location;