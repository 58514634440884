/* eslint-disable eqeqeq */
import style from './ItemType.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import ItemTypeModal from '../../../components/common/modal/ItemTypeModal';
import CategoryModal from '../../../components/common/modal/CategoryModal';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from 'antd';

const ItemType = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // ItemType ID
    const [SysItemTypeId, setSysItemTypeId] = useState(0);
    const [ItemTypeCode, setItemTypeCode] = useState("");
    const handleChangeItemTypeCode = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 3) {
            str = str.substring(0, 3);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setItemTypeCode(str);
    };
    const onKeyDownItemTypeCode = (event) => {
        if (event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(4, event.target.value)
            setItemTypeCode(deg)
            document.getElementById('input2').focus();
            getItemTypeData(event, deg);
        }
    };
    const getItemTypeData = (event, deg) => {
        if (deg != "") {
            const data_r = {
                userID: userData['id'],
                item_type_code: deg
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
                .post(url, data_r, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if (t_data.length > 0) {
                        setItemTypeData(t_data[0]);
                    } else {
                        setItemTypeData([]);
                    }
                    event.preventDefault();
                })
                .catch((error) => {
                });
        } else {
            setItemTypeData([]);
            event.preventDefault();
        }
    }

    const [ItemTypeName, setItemTypeName] = useState("");
    const handleChangeItemTypeName = (event) => {
        let str = event.target.value;
        if (str.length > 30) {
            str = str.substring(0, 30);
        }
        setItemTypeName(str);
    };
    const [ItemTypeAName, setItemTypeAName] = useState("");
    const handleChangeItemTypeAName = (event) => {
        let str = event.target.value;
        if (str.length > 6) {
            str = str.substring(0, 6);
        }
        setItemTypeAName(str);
    };

    const [CategoryCode, setCategoryCode] = useState("");
    const [CategoryName, setCategoryName] = useState("");
    const handleChangeCategoryCode = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 3) {
            str = str.substring(0, 3);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setCategoryCode(str);
    };

    const onKeyDownCategoryCode = (event) => {
        if (event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(4, event.target.value)
            setCategoryCode(deg)
            getCategoryData(event.target.value);
            document.getElementById('input5').focus();
            event.preventDefault();
        }
    };

    const getCategoryData = (code) => {
        if (code != "") {
            const data_r = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
            axios
                .post(url, data_r, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    let t_data = response.data;
                    if (t_data.length > 0) {
                        setCategoryData(t_data[0]);
                    } else {
                        setCategoryData([]);
                    }
                })
                .catch((error) => {
                });
        } else {
            setCategoryData([]);
        }
    }

    const setItemTypeData = (result) => {
        if (result['sys_item_type_id']) {
            setSysItemTypeId(result['sys_item_type_id']);
            setItemTypeCode(result['item_type_code']);
            setItemTypeName(result['item_type_name']);
            setItemTypeAName(result['item_type_a_name']);
            getCategoryData(result['category_code']);
        } else {
            setSysItemTypeId(0);
            setItemTypeName("");
            setItemTypeAName("");
            getCategoryData([]);
        }
    }

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            if (menuPatternData['menu_master_flag_6'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
    });

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if (SysItemTypeId > 0) {
            data['sys_item_type_id'] = SysItemTypeId;
        }

        if (ItemTypeCode != "") {
            data['item_type_code'] = ItemTypeCode;
        } else {
            toast.error("コードを入力してください。", { autoClose: 3000 });       // please enter code
            setOpen(false);
            return;
        }
        if (ItemTypeName != "") {
            data['item_type_name'] = ItemTypeName;
        } else {
            toast.error("名称を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (ItemTypeAName != "") {
            data['item_type_a_name'] = ItemTypeAName;
        }
        if (CategoryCode != "") {
            data['category_code'] = CategoryCode;
        } else {
            toast.error("分類コードを入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstItemTypeEndpoint;
        csloading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if (response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if (response.data.data == 1) {
                        toast.success("登録致しました。", { autoClose: 3000 });
                    } else if (response.data.data == 2) {
                        toast.error("すでに登録済みです。", { autoClose: 3000 });
                    } else {
                        toast.error("登録に失敗しました。", { autoClose: 3000 });
                    }

                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if (error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                } else {
                    toast.error("登録に失敗しました。", { autoClose: 3000 });
                }
            });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if (SysItemTypeId > 0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        if (SysItemTypeId > 0) {
            let data = {};
            data['userID'] = userData['id'];
            data['sys_item_type_id'] = SysItemTypeId;
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.delMstItemTypeEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    if (response.status == 200) {
                        setItemTypeCode("");
                        setItemTypeData([]);
                    }
                })
                .catch((error) => {
                });

            setItemTypeCode("");
            setItemTypeData([]);
        }
    }
    // ========================== starts code for search ===============================
    const [SearchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState([]);

    const loadModalData = (event) => {
        event.preventDefault();
        let code = ItemTypeCode;
        setSearchValue(code);

        if (code != "") {
            const data = {
                userID: userData['id'],
                item_type_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setTableData(response.data.data);
                    setShowModal(true);
                })
                .catch((error) => {
                });
        } else {
            setTableData([]);
            setShowModal(true);
        }
    }
    // ----------------------- Category Modal ------------------------------------------
    const [showModalCategory, setShowModalCategory] = useState(false);
    const [SearchValue2, setSearchValue2] = useState("");
    const [tableData2, setTableData2] = useState([]);
    const loadModalCategoryData = (event) => {
        event.preventDefault();
        let code = CategoryCode;
        setSearchValue2(code);

        if (code != "") {
            const data = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setTableData2(response.data.data);
                    setShowModalCategory(true);
                })
                .catch((error) => {
                });
        } else {
            setTableData2([]);
            setShowModalCategory(true);
        }
    }
    const setCategoryData = (data) => {
        if (data['category_code'] != null) {
            setCategoryCode(data['category_code']);
            setCategoryName(data['category_name']);
        } else {
            setCategoryCode("");
            setCategoryName("");
        }

    }

    const onDataKeyDown = (e, param) => {
        if (e.keyCode == 13) {
            switch (param) {
                case 1:
                    document.getElementById('input1').focus();
                    break;
                case 2:
                    document.getElementById('input2').focus();
                    break;
                case 3:
                    document.getElementById('input3').focus();
                    break;
                case 4:
                    document.getElementById('input4').focus();
                    break;
                case 5:
                    document.getElementById('input5').focus();
                    break;
                default:
            }
        }
    }
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];

    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <ItemTypeModal show={showModal} onClose={() => setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} onChange={setSearchValue}
                tableData={tableData} setTableData={setTableData}
                target={setItemTypeData} />
            <CategoryModal show={showModalCategory} onClose={() => setShowModalCategory(false)} width={'70%'}
                SearchValue={SearchValue2} onChange={setSearchValue2}
                tableData={tableData2} setTableData={setTableData2}
                target={setCategoryData} />
            <Modal2 title="諸掛削除" show={showModalDel} onClose={() => setShowModalDel(false)} width={'40%'}
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>品種マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownItemTypeCode(e)} id='input1'
                                            value={ItemTypeCode} onChange={handleChangeItemTypeCode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>名称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                            id='input2' onKeyDown={(e) => onDataKeyDown(e, 3)}
                                            value={ItemTypeName} onChange={handleChangeItemTypeName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>略称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                            id='input3' onKeyDown={(e) => onDataKeyDown(e, 4)}
                                            value={ItemTypeAName} onChange={handleChangeItemTypeAName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>分類コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownCategoryCode(e)} id='input4'
                                            value={CategoryCode} onChange={handleChangeCategoryCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={CategoryName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadModalCategoryData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='input5' onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} disabled={SysItemTypeId > 0 ? false : true}>削除</button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{ fontSize: "1.1rem" }}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default ItemType;