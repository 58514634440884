/* eslint-disable eqeqeq */
import style from './ShipperItem.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import IconSearch from '../../../components/common/icons/icon_search.png';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

const ShipperItem = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // shipper -------------------------------
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>6) {
            str = str.substring(0, 6);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setShipperCode(str);
    };
    const [ShipperName, setShipperName] = useState("");
    const onKeyDownShipperCode = (event) => {
        if(event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(7, event.target.value)
            setShipperCode(deg)
            getShipperData(deg);
            document.getElementById('item_code1').focus();
            event.preventDefault();
        }
    };
    const getShipperData = (code) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                shipper_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result);
                    }else{
                        setShipperData([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([]);
            
        }
    }
    const setShipperData = (data) => {
        if(data['shipper_code']) {
            setShipperCode(data['shipper_code']);
            setShipperName(data['shipper_name']);
        }else{
            setShipperCode("");
            setShipperName("");
        }
    }
    // item ----------------------------------------------------
    const [ItemCode1, setItemCode1] = useState("");
    const handleChangeItemCode1 = (event) => {
        setItemCode1(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode1, setItemACode1] = useState("");
    const handleChangeItemACode1 = (event) => {
        setItemACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName1, setItemName1] = useState("");
    const [ItemCode2, setItemCode2] = useState("");
    const handleChangeItemCode2 = (event) => {
        setItemCode2(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode2, setItemACode2] = useState("");
    const handleChangeItemACode2 = (event) => {
        setItemACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName2, setItemName2] = useState("");
    const onKeyDownItemCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(16, event.target.value)

            getItemData(deg,param1,param2);

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('item_a_code1').focus();
                }else{
                    document.getElementById('item_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('item_a_code2').focus();
                }else{
                    document.getElementById('shipper_item_code_0').focus();
                }
            }
            event.preventDefault();
        }
    };
    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ItemACode1;
            }else{
                code = ItemCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ItemACode2;
            }else{
                code = ItemCode2;
                acode = value;
            }
        }

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result,param1);
                    }else{
                        setItemData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([],param1);
        }
    }
    const setItemData = (data,param) => {
        if(data['item_code']) {
            if(param == 1) {
                setItemCode1(data['item_code']);
                setItemACode1(data['item_a_code']);
                setItemName1(data['item_name']);
            }else{
                setItemCode2(data['item_code']);
                setItemACode2(data['item_a_code']);
                setItemName2(data['item_name']);
            }
            getShipperItemData(data,param);
        }else{
            if(param == 1) {
                setItemCode1("");
                setItemACode1("");
                setItemName1("");
            }else{
                setItemCode2("");
                setItemACode2("");
                setItemName2("");
            }
        }
    }
    const getShipperItemData = (result,param) => {
        if(ShipperCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode;
            if(param == 1) {
                data['start_item_code'] = result['item_code'];
                if(ItemCode2 != ""){
                    data['end_item_code'] = ItemCode2;
                }
                data['start_item_a_code'] = result['item_a_code'];
                if(ItemACode2 != ""){
                    data['end_item_a_code'] = ItemACode2;
                }
            }else{
                if(ItemCode1 != ""){
                    data['start_item_code'] = ItemCode1;
                }
                data['end_item_code'] = result['item_code'];
                if(ItemACode1 != ""){
                    data['start_item_a_code'] = ItemACode1;
                }
                data['end_item_a_code'] = result['item_a_code'];
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    if(response.data.data.length>0) {
                        let result = response.data.data;
                        gridMainTable(result);
                        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
                        for (let i = 0; i < checkboxes.length; i++) {
                            checkboxes[i].checked = false;
                        }
                    }else{
                        gridMainTable([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            gridMainTable([]);
        }
    }

    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        setMainTableData(result);
    }

    const handlChangeShipperItemData = (event, index) => {
        let value = event.target.value;
        if(value.length>8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row['shipper_item_code'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }

    const allCheckData = (event) => {
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', event.target.checked);
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_master_flag_9'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];

        let validate_flg = true;
        let vmsg = "";

        let ShipperItemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            let checked = document.getElementById('chk_'+i).checked
            if(checked && mainTableData[i]['sys_item_id'] != 0) {
                row['sys_item_id'] = mainTableData[i]['sys_item_id'];
                row['shipper_item_code'] = mainTableData[i]['shipper_item_code'];
                row['update_user_id'] = userData['id'];
                ShipperItemData.push(row);
            }
        }
        if(validate_flg && ShipperItemData.length>0){
            data['data'] = ShipperItemData;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setShipperItemCodeEndpoint;
            csloading(true);
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data == 1) {
                        toast.success("登録致しました。", {autoClose:3000});
                    }else if(response.data.data == 2){
                        toast.error("すでに登録済みです。", {autoClose:3000});
                    }else{
                        toast.error("登録に失敗しました。", {autoClose:3000});
                    }
                        
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
            });
        }else{
            // toast.error(vmsg, {autoClose:3000});  
        }
        setOpen(false);
    }
    
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event) => {
        event.preventDefault();

        setShipperSearchValue(ShipperCode);
        setShipperSearchValue2("");

        if(ShipperCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemCode1;
        let acode = ItemACode1;
        if(param == 2) {
            code = ItemCode2;
            acode = ItemACode2;
        }
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode);
        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    
    
    const tableEndKeyDown = ( e, id, index ) =>{
        let dataLength = mainTableData.length;

        if(dataLength <= index + 1)  return;
        
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w140px];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} 
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>荷主品番マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    { /* 荷主コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e)} id='shipper_code'
                                        value={ShipperCode} onChange={handleChangeShipperCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")}
                                        value={ShipperName} disabled />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 商品 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始商品</span>
                                    <div className={gstyle.cs_flex}> 
                                        <input type={'text'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,1,1)} id='item_code1'
                                        value={ItemCode1} onChange={handleChangeItemCode1} />
                                        <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,1,2)} id='item_a_code1'
                                        value={ItemACode1} onChange={handleChangeItemACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ItemName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了商品</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,2,1)} id='item_code2'
                                        value={ItemCode2} onChange={handleChangeItemCode2} />
                                        <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,2,2)} id='item_a_code2'
                                        value={ItemACode2} onChange={handleChangeItemACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ItemName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* table */ }
                    <div className={gstyle.hyfiled}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_col_2.join(" ")}>品番</div>
                                    <div className={style_th_col_2.join(" ")}>補助</div>
                                    <div className={style_th_col_2.join(" ")}>品名</div>
                                    <div className={style_th_col_2.join(" ")}>荷主品番</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                            id={'chk_'+index} onChange={handleChangeChk} />
                                            <input type="hidden" id={'sys_item_id_'+index} value={data['sys_item_id']} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['item_code']} disabled/>
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['item_a_code']} disabled/>
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['item_name']} disabled/>
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                id={'shipper_item_code_'+index}
                                                value={data['shipper_item_code']} 
                                                onKeyDown={(e)=>tableEndKeyDown(e, 'shipper_item_code_'+(index+1), index)}
                                                onChange={(e) => handlChangeShipperItemData(e, index)} />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >登録する</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default ShipperItem;