/* eslint-disable eqeqeq */
import style from './Deposits.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

const Deposits = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // sys_deposit_id
    const [SysDepositId, setSysDepositId] = useState(0);
    // slip date
    const [DepositDate, setDepositDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeDepositDate = (date) => {
        setDepositDate(useJwt.formatDate(date));
    };
    // shipper ---------------------------------------------------
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        setShipperCode(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode, setShipperACode] = useState("");
    const handleChangeShipperACode = (event) => {
        setShipperACode(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName, setShipperName] = useState("");
    const onKeyDownShipperCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            if(param == 1) {
                getShipperData(event.target.value,ShipperACode);
                document.getElementById('shipper_a_code').focus();
            }else{
                getShipperData(ShipperCode,event.target.value);
                document.getElementById('deposit_price0').focus();
            }
            event.preventDefault();
        }
    };
    const getShipperData = (code, acode) => {
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result);
                    }else{
                        setShipperData([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([]);
        }
    }
    const setShipperData = (result) => {
        if(result['shipper_code'] != null) {
            setShipperCode(result['shipper_code']);
            setShipperACode(result['shipper_a_code']);
            setShipperName(result['shipper_name']);
            getDepositData(result['shipper_code']);
        }else{
            setShipperCode("");
            setShipperACode("");
            setShipperName("");
            getDepositData("");
        }
    }
    const getDepositData = (shipper_code) => {
        let DataAry = mainTableData;
        if(shipper_code != "") {
            const data = {
                userID: userData['id']
            };
            data['start_deposit_date'] = DepositDate;
            data['end_deposit_date'] = DepositDate;
            data['start_shipper_code'] = shipper_code;
            data['end_shipper_code'] = shipper_code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getDepositsEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let result2 = response.data.data;
                for(let i=0; i<DataAry.length; i++) {
                    if(result2.length>0){
                        for(let j=0; j<result2.length; j++) {
                            if(DataAry[i]['deposit_no'] == result2[j]['deposit_item']) {
                                DataAry[i]['sys_deposit_id'] = result2[j]['sys_deposit_id'];
                                DataAry[i]['deposit_price'] = result2[j]['deposit_price'];
                                DataAry[i]['remark'] = result2[j]['remark'];
                                DataAry[i]['deposit_date'] = result2[j]['deposit_date'];
                                DataAry[i]['shipper_code'] = result2[j]['shipper_code'];
                                break;
                            }
                        }
                    }else{
                        DataAry[i]['sys_deposit_id'] = 0;
                        DataAry[i]['deposit_price'] = "";
                        DataAry[i]['remark'] = "";
                        DataAry[i]['deposit_date'] = "";
                        DataAry[i]['shipper_code'] = "";
                    }
                }
                gridMainTable(DataAry);
            })
            .catch((error) => {
            });
        }else{
            gridMainTable(DataAry);
        }
    }
    // ====================================== table ====================================
    const [mainTableData, setMainTableData] = useState([]);
    const [TotalPrice, setTotalPrice] = useState("0.000");

    const gridMainTable = (result) => {
        let data = [];
        let total_price = 0;
        for(var i=0; i<result.length; i++) {
            let row = {};
            row = result[i];
            if(row['deposit_price'] != '' && row['deposit_price']>0) {
                total_price += 1*row['deposit_price'];
            }
            data[i] = row;
        }
        setTotalPrice(total_price.toFixed(3));
        setMainTableData(data);
    }
    const handleChangeItemData = (event, control_type, col , index) => {
        let value = '';
        if(control_type==2){
            value = useJwt.formatDate(event);
        }else{
            value = event.target.value;
        }

        if(col == 'deposit_price') {
            value = useJwt.validateDecimal(value,'x',3);
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row[col] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_daily_flag_6'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            const data = {
                userID: userData['id']
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMainSettingEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(true);
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    
                    let result = response.data.data;
                    let DataAry = [];
                    DataAry = [
                        {deposit_no:'01', deposit_item:result['deposit_item_name_1'],sys_deposit_id:0,deposit_price:'',remark:'',deposit_date:'',shipper_code:''},
                        {deposit_no:'02', deposit_item:result['deposit_item_name_2'],sys_deposit_id:0,deposit_price:'',remark:'',deposit_date:'',shipper_code:''},
                        {deposit_no:'03', deposit_item:result['deposit_item_name_3'],sys_deposit_id:0,deposit_price:'',remark:'',deposit_date:'',shipper_code:''},
                        {deposit_no:'04', deposit_item:result['deposit_item_name_4'],sys_deposit_id:0,deposit_price:'',remark:'',deposit_date:'',shipper_code:''},
                        {deposit_no:'05', deposit_item:result['deposit_item_name_5'],sys_deposit_id:0,deposit_price:'',remark:'',deposit_date:'',shipper_code:''},
                        {deposit_no:'06', deposit_item:result['deposit_item_name_6'],sys_deposit_id:0,deposit_price:'',remark:'',deposit_date:'',shipper_code:''},
                        {deposit_no:'07', deposit_item:result['deposit_item_name_7'],sys_deposit_id:0,deposit_price:'',remark:'',deposit_date:'',shipper_code:''},
                        {deposit_no:'08', deposit_item:result['deposit_item_name_8'],sys_deposit_id:0,deposit_price:'',remark:'',deposit_date:'',shipper_code:''},
                        {deposit_no:'09', deposit_item:result['deposit_item_name_9'],sys_deposit_id:0,deposit_price:'',remark:'',deposit_date:'',shipper_code:''}
                    ];
                    gridMainTable(DataAry);
                }
            })
            .catch((error) => {
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }
            });
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysDepositId>0) {
            data['sys_deposit_id'] = SysDepositId;
        }

        if(DepositDate == "") {
            toast.error("年月日を入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }
        if(ShipperCode == "") {
            toast.error("荷主コードを入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }

        // item data --------------------------------------
        let itemData = [];
        let delIds = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            if(mainTableData[i]['sys_deposit_id'] > 0) {
                row['sys_deposit_id'] = mainTableData[i]['sys_deposit_id'];
                row['deposit_date'] = mainTableData[i]['deposit_date'];
                row['shipper_code'] = mainTableData[i]['shipper_code'];
            }else{
                row['deposit_date'] = DepositDate;
                row['shipper_code'] = ShipperCode;
            }
            
            row['deposit_item'] = mainTableData[i]['deposit_no'];
            row['deposit_price'] = mainTableData[i]['deposit_price'];
            row['remark'] = mainTableData[i]['remark'];
            
            if(mainTableData[i]['deposit_price']>0) {
                itemData.push(row);
            } else if(mainTableData[i]['sys_deposit_id']>0) {
                delIds.push(mainTableData[i]['sys_deposit_id']);
            }
        }
        if(itemData.length>0){
            data['items'] = itemData;
            if(SysDepositId == 0) {
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setDepositsEndpoint;
                csloading(true);
                axios
                .post(url, data, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    if(response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if(response.data.data == 1) {
                            if(delIds.length>0) {
                                let data = {};
                                data['userID'] = userData['id'];
                                data['delIds'] = delIds;
                                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.delDepositsEndpoint;
                                axios
                                .post(url, data, {
                                    headers: {
                                    Authorization: "Bearer " + useJwt.getToken(),
                                    },
                                })
                                .then((response) => {
                                    toast.success("登録致しました。", {autoClose:3000});
                                    getDepositData(ShipperCode);
                                })
                                .catch((error) => {
                                });
                            }else{
                                toast.success("登録致しました。", {autoClose:3000});
                                getDepositData(ShipperCode);
                            }
                        }else if(response.data.data == 2){
                            toast.error("すでに登録済みです。", {autoClose:3000});
                        }else{
                            toast.error("登録に失敗しました。", {autoClose:3000});
                        }
                    }
                    csloading(false);
                })
                .catch((error) => {
                    csloading(false);
                    if(error.response && error.response.status && error.response.status === 401) {
                        useJwt.logout();
                        navigate("/login");
                    }else{
                        toast.error("登録に失敗しました。", {autoClose:3000});
                    }
                });
            }else{
                toast.error("この伝票はすでに登録されています。", {autoClose:3000});
            }
        }else{
            toast.error("入金額を入力してください。", {autoClose:3000});  
        }
        setOpen(false);
    }
    
    // ========================== starts code for search ===============================
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event) => {
        event.preventDefault();
        setShipperSearchValue(ShipperCode);
        setShipperSearchValue2(ShipperACode);
        if(ShipperCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }

    const tableKeyDown = ( e, id, index ) =>{
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    const tableEndKeyDown = ( e, id, index ) =>{
        let dataLength = mainTableData.length;

        if(dataLength <= index + 1)  return;
        
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_total_input_wrap = [gstyle.p0015, gstyle.mt30];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_th_w1p = [style.cs_th, style.w1p];
    const style_th_w2p = [style.cs_th, style.w2p];
    const style_th_w3p = [style.cs_th, style.w3p];
    const style_th_w5p = [style.cs_th, style.w5p];


    const style_td_w1p = [style.cs_td, style.w1p];
    const style_td_w2p_flex = [style.cs_td, style.w2p, gstyle.cs_flex];
    const style_td_w3p = [style.cs_td, style.w3p, gstyle.cs_flex];
    const style_td_w5p = [style.cs_td, style.w5p];

    const style_th_total_title = [style.cs_th, style.total_label];
    const style_td_total_val = [style.cs_td, style.td_text_right];

    const style_td_input_right = [style.cs_tbl_input, style.td_input_right];
    const style_td_text_left = [style.cs_td, style.td_line_break, style.td_text_left, style.w3p];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>入金入力</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 年月日, 伝票No */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={DepositDate} onChange={handleChangeDepositDate} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1)} id={"shipper_code"}
                                        value={ShipperCode} onChange={handleChangeShipperCode} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2)} id="shipper_a_code"
                                        value={ShipperACode} onChange={handleChangeShipperACode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="ShipperName"
                                        value={ShipperName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* table */}
                    <div className={style.tbl_wrap}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_w2p.join(" ")}>No</div>
                                    <div className={style_th_w3p.join(" ")}>入金項目</div>
                                    <div className={style_th_w5p.join(" ")}>入金額</div>
                                    <div className={style_th_w1p.join(" ")}>備考</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_w2p_flex.join(" ")}>
                                            {data['deposit_no']?data['deposit_no']:''}
                                        </div>
                                        <div className={style_td_text_left.join(" ")}>
                                            {data['deposit_item']?data['deposit_item']:''}
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            <input type={'text'} className={style_td_input_right.join(" ")} 
                                            disabled={data['deposit_item']!=''?false:true} id={'deposit_price'+index}
                                            value={data['deposit_price']?data['deposit_price']:''} 
                                            onChange={(e) => handleChangeItemData(e,1,'deposit_price',index)} 
                                            onKeyDown={(e)=> tableKeyDown(e, "remark"+index, index)}
                                            />
                                        </div>
                                        <div className={style_td_w1p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            disabled={data['deposit_item']!=''?false:true}
                                            value={data['remark']?data['remark']:''} id={"remark"+index}
                                            onChange={(e) => handleChangeItemData(e,1,'remark',index)} 
                                            onKeyDown={(e)=> tableEndKeyDown(e, "deposit_price"+(index+1), index)}
                                            />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* Total */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_total_input_wrap.join(" ")}>
                                <div className={style.cs_tbl}>
                                    <div className={style.cs_thead}>
                                        <div className={style.cs_tr}>
                                            <div className={style_th_total_title.join(" ")}>合計金額</div>
                                        </div>
                                    </div>
                                    <div className={style.cs_tbody}>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_val.join(" ")}>￥{useJwt.numberWithCommas(TotalPrice)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >登録する</button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Deposits;