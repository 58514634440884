//====================================================================================================
//	単位変更処理 -> AdobeXD 24ページ
//	作成日:2022/08/26		最終更新日:2022/08/30
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from "react";
import { useForm } from "react-hook-form";

import style from './RecomposeLot.module.css';
// ---------- useForm用コンテキスト ----------
import { RegisterContext } from '../../components/common/tenjin_tokubuchi/register_context/RegisterContext';
// ---------- 見出し ----------
import Headline from '../../components/common/tenjin_tokubuchi/headline/Headline';
// ---------- 上部の入力項目 ----------
import RecomposeLotDate from '../../components/tenjin_tokubuchi/recompose_lot/01_recompose_lot_date/RecomposeLotDate';
import VocherNumber from '../../components/tenjin_tokubuchi/recompose_lot/02_voucher_number/VoucherNumber';
import Shipper from '../../components/tenjin_tokubuchi/recompose_lot/03_shipper/Shipper';
// ---------- 入力テーブル ----------
import InputTable from '../../components/tenjin_tokubuchi/recompose_lot/101_input_table/InputTable';
// ---------- ボタン(丸角) ----------
import MyButton from '../../components/common/tenjin_tokubuchi/my_button/MyButton';

const RecomposeLot = () => {
	const {register, handleSubmit, reset} = useForm();
	const onSubmit = data => console.log(data);

	return (
		<div className={ style.base }>
			<Headline text="単位変更処理" />
			<form className={ style.contents } onSubmit={handleSubmit(onSubmit)}>
				<RegisterContext.Provider value={register}>
					<div className={ style.input_area_1st }>
						<RecomposeLotDate />
						<VocherNumber />
						<Shipper />
					</div>
					<InputTable />
					<div className={ style.button_area }>
						<MyButton link='#' text='移動する' />
					</div>
				</RegisterContext.Provider>
			</form>
    	</div>
	);
};

export default RecomposeLot;