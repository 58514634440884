import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import style from "./Driver.module.css";
import gstyle from '../../css/GeneralStyle.module.css';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from "axios";
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import { Button, Space } from 'antd-mobile'

function Driver(props) {

  const navigate = useNavigate();
  const params = useParams();

  const [loaded, setLoaded] = useState("");
  const [bodyLoaded, setBodyLoaded] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [warehouseName, setWarehouseName] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [warehouseId, setWarehouseId] = useState("")
  const [currentDate, setCurrentDate] = useState("")

  useEffect(()=>{

    if(!bodyLoaded){
      setBodyLoaded(true);
      setWarehouseId(params.warehouse);
      setCurrentDate(params.date);
  
      let data = {
        warehouse_id : params.warehouse,
        current_date : params.date,
      }
      csLoading(true);
  
      let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getDriverWarehouseByIdEndpoint;
      axios
        .post(url, data)
        .then((response) => {
          let data = response.data;
          if(data == "0"){
            setLoaded("1");
            csLoading(false);
          }else if(data == "1"){
            setLoaded("2");
            csLoading(false);
          }else{
            setCompanyId(data['id']);
            setCompanyName(data['company_name']);
            setWarehouseName(data['warehouse_name']);
            setLoaded("3");
            csLoading(false);
          }
        })
        .catch((error) => {
          csLoading(false);
          setLoaded("2");
        });
    }
  })
  
  const csLoading = (flag) => {
    if(flag) {
        document.getElementById('csLoader').style.display="flex";
    }else{
        document.getElementById('csLoader').style.display="none";
    }
} 

const handleReConnect = ( e ) => {
  setBodyLoaded(false);
  setLoaded("");
  navigate("/driver/" + warehouseId + "/" + currentDate);
}

const handleNext = ( e ) => {
  navigate("/driver/confirm", {
    state: {
      companyId : companyId,
      companyName: companyName,
      warehouseName : warehouseName,
      warehouseId : warehouseId,
    },
    replace: false,
  });
}
//style
  const style_header = [style.content_flex_column, style.title, style.content_align_center, style.mt70];
  const style_content = [style.content_flex_column, style.mt30];
  const style_content_but = [style.content_flex_column, style.mt70, style.but_gap, style.content_align_center];

  return (
    <div className={style.content_flex_column}>
      <div className={gstyle.loader_container} id="csLoader">
          <ClipLoader color={'#fff'} size={60} />
      </div>
      {
        loaded == "3" && 
        <>
          <div className={style_header.join(" ")}>
            <label>{companyName}予約システム</label>
            <label className={style.sub_title}>{warehouseName}</label>
          </div>

          <div className={style_content.join(" ")}>
            <label style={{textAlign:'center', fontSize:16}}>予約を取りますか？</label>
            <label style={{textAlign:'center', fontSize:16}}>既に予約済みの方も、<br/>こちらから申請お願いします。</label>
          </div>

          <div className={style_content_but.join(" ")}>
            <Button className={style.button} size='small' color='primary' fill='solid' onClick={(e) => handleNext(e)}>はい</Button>
            <Button className={style.button} size='small' color='primary' fill='outline'>いいえ</Button>
          </div>
        </>
      }
      {
        (loaded == "1" || loaded == "2") && 
        <>
          <div className={style_header.join(" ")} style={{height:'60vh'}}>
            <label style={{color:'red'}}>予約システムにアクセスできません。</label>
          </div>

          <div className={style_content_but.join(" ")}>
            <Button style={{width:120}} onClick={(e) => handleReConnect(e)}>再接続</Button>
          </div>
        </>
      }
    </div>
  );
}

export default Driver;
