/* eslint-disable eqeqeq */
import style from './Consignor.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '../../../components/common/modal/Modal';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import ConsignorModal from '../../../components/common/modal/ConsignorModal';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

const Consignor = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Consignor ID
    const [SysConsignorId, setSysConsignorId] = useState(0);
    const [ConsignorCode, setConsignorCode] = useState("");
    const handleChangeConsignorCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>6) {
            str = str.substring(0, 6);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setConsignorCode(str);
    };
    const onKeyDownConsignorCode = (event, param) => {
        if(event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(7, event.target.value)
            setConsignorCode(deg)
      
            if(param == 1){
                document.getElementById('input2').focus();
            }else{
                document.getElementById('input3').focus();
            }
            if(deg != "") {
                let data = {};
                data['userID'] = userData['id'];
                data['consignor_code'] = deg;
                if(ConsignorACode != "") {
                    data['consignor_a_code'] = ConsignorACode;
                }
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstConsignorEndpoint;
                axios
                .post(url, data, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if(t_data.length > 0) {
                        setConsignorData(t_data[0]);
                    }else{
                        setConsignorData([]);
                    }
                })
                .catch((error) => {
                });
            }else{
                setConsignorData([]);
            }
            event.preventDefault();
        }
    };
    const [ConsignorACode, setConsignorACode] = useState("");
    const handleChangeConsignorACode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>3) {
            str = str.substring(0, 3);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setConsignorACode(str);
    };
    const [ConsignorName, setConsignorName] = useState("");
    const handleChangeConsignorName = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setConsignorName(str);
    };
    const [ConsignorNameKana, setConsignorNameKana] = useState("");
    const handleChangeConsignorNameKana = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setConsignorNameKana(str);
    };
    const [ConsignorAName, setConsignorAName] = useState("");
    const handleChangeConsignorAName = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setConsignorAName(str);
    };
    const [ConsignorZip1, setConsignorZip1] = useState("");
    const handleChangeConsignorZip1 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setConsignorZip1(str);
    };
    const [ConsignorZip2, setConsignorZip2] = useState("");
    const handleChangeConsignorZip2 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setConsignorZip2(str);
    };
    const getAddress = (event) => {
        if(ConsignorZip1 != "" && ConsignorZip2 != "") {
            let googleApiUrl = "https://maps.googleapis.com/maps/api/geocode/json";
            let postcodeParam = "?components=postal_code:"+ConsignorZip1+"-"+ConsignorZip2+"&language=ja";
            let apiKey = "&key="+useJwt.getGoogleApiKey();
            let url = googleApiUrl + postcodeParam + apiKey;
            csloading(true);
            axios
            .get(url, "")
            .then((response1) => {
                if(response1.data.status == "OK") {
                    let address_components = response1.data.results[0]['address_components'];
                    let code_len = address_components.length;
                    let address1 = "";
                    if(address_components[code_len-2]['long_name']) {
                        address1 = address_components[code_len-2]['long_name'];
                    }
                    let address2 = "";
                    if(address_components[code_len-3]['long_name'] && address_components[code_len-3]['types'][0] != "postal_code") {
                        address2 = address_components[code_len-3]['long_name'];
                    }
                    let town = "";
                    if(code_len > 4){
                      town = address_components[code_len - 4]['long_name'];
                    }else{
                        town = "工業団地";
                    }
        
                    setConsignorAddress1(address1);
                    setConsignorAddress2(address2);
                    setConsignorTown(town);
                }else{ // ZERO_RESULTS
                    setConsignorAddress1("");
                    setConsignorAddress2("");
                    setConsignorTown("");
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });
        }
        event.preventDefault();
    }
    const [ConsignorAddress1, setConsignorAddress1] = useState("");
    const handleChangeConsignorAddress1 = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setConsignorAddress1(str);
    };
    const [ConsignorAddress2, setConsignorAddress2] = useState("");
    const handleChangeConsignorAddress2 = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setConsignorAddress2(str);
    };
    const [ConsignorAddress3, setConsignorAddress3] = useState("");
    const handleChangeConsignorAddress3 = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setConsignorAddress3(str);
    };

    const [ConsignorTown, setConsignorTown] = useState("");
    const handleChangeConsignorTown = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setConsignorTown(str);
    };
    const [ConsignorTel1, setConsignorTel1] = useState("");
    const handleChangeConsignorTel1 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setConsignorTel1(str);
    };
    const [ConsignorTel2, setConsignorTel2] = useState("");
    const handleChangeConsignorTel2 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setConsignorTel2(str);
    };
    const [ConsignorTel3, setConsignorTel3] = useState("");
    const handleChangeConsignorTel3 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setConsignorTel3(str);
    };
    const [ConsignorFax1, setConsignorFax1] = useState("");
    const handleChangeConsignorFax1 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setConsignorFax1(str);
    };
    const [ConsignorFax2, setConsignorFax2] = useState("");
    const handleChangeConsignorFax2 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setConsignorFax2(str);
    };
    const [ConsignorFax3, setConsignorFax3] = useState("");
    const handleChangeConsignorFax3 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setConsignorFax3(str);
    };
    const [ConsignorEmail, setConsignorEmail] = useState("");
    const handleChangeConsignorEmail = (event) => {
        let str = event.target.value;
        setConsignorEmail(str);
    };
    const setConsignorData = (result) => {
        if(result['sys_consignor_id']) {
            setSysConsignorId(result['sys_consignor_id']);
            setConsignorCode(result['consignor_code']);
            if(result['consignor_a_code'] != null) {
                setConsignorACode(result['consignor_a_code']);
            }
            if(result['consignor_name'] != null) {
                setConsignorName(result['consignor_name']);
            }
            if(result['consignor_a_name'] != null) {
                setConsignorAName(result['consignor_a_name']);
            }
            if(result['consignor_name_kana'] != null) {
                setConsignorNameKana(result['consignor_name_kana']);
            }
            if(result['consignor_zip'] != null){
                const postcode_ary = result['consignor_zip'].split("-");
                if(postcode_ary[0]) {
                    setConsignorZip1(postcode_ary[0]);
                }
                if(postcode_ary[1]) {
                    setConsignorZip2(postcode_ary[1]);
                }
            }
            if(result['consignor_address_1'] != null) {
                setConsignorAddress1(result['consignor_address_1']);
            }
            if(result['consignor_address_2'] != null) {
                setConsignorAddress2(result['consignor_address_2']);
            }
            if(result['consignor_address_3'] != null) {
                setConsignorAddress3(result['consignor_address_3']);
            }
            if (result["consignor_town"] != null) {
                setConsignorTown(result["consignor_town"]);
              }else{
                setConsignorTown("");
              }
                    if(result['consignor_tel'] != null){
                const phone_ary = result['consignor_tel'].split("-");
                if(phone_ary[0]) {
                    setConsignorTel1(phone_ary[0]);
                }
                if(phone_ary[1]) {
                    setConsignorTel2(phone_ary[1]);
                }
                if(phone_ary[2]) {
                    setConsignorTel3(phone_ary[2]);
                }
            }
            if(result['consignor_fax'] != null){
                const fax_ary = result['consignor_fax'].split("-");
                if(fax_ary[0]) {
                    setConsignorFax1(fax_ary[0]);
                }
                if(fax_ary[1]) {
                    setConsignorFax2(fax_ary[1]);
                }
                if(fax_ary[2]) {
                    setConsignorFax3(fax_ary[2]);
                }
            }
            if(result['consignor_email'] != null) {
                setConsignorEmail(result['consignor_email']);
            }
        }else{
            setSysConsignorId(0);
            setConsignorName(""); setConsignorAName(""); setConsignorNameKana("");
            setConsignorZip1(""); setConsignorZip2("");
            setConsignorAddress1(""); setConsignorAddress2(""); setConsignorAddress3(""); setConsignorTown("");
            setConsignorTel1(""); setConsignorTel2(""); setConsignorTel3("");
            setConsignorFax1(""); setConsignorFax2(""); setConsignorFax3("");
            setConsignorEmail("");
        }
    }

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_master_flag_4'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysConsignorId>0) {
            data['sys_consignor_id'] = SysConsignorId;
        }

        if(ConsignorCode != "") {
            data['consignor_code'] = ConsignorCode;
        }else{
            toast.error("コードを入力してください。", {autoClose:3000});       // please enter code
            setOpen(false);
            return;
        }
        data['consignor_a_code'] = ConsignorACode;
        if(ConsignorName != "") {
            data['consignor_name'] = ConsignorName;
        }else{
            toast.error("名称を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        data['consignor_a_name'] = ConsignorAName;
        if(ConsignorNameKana != "") {
            data['consignor_name_kana'] = ConsignorNameKana;
        }else{
            toast.error("フリガナを入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(ConsignorZip1 != "" && ConsignorZip2 != "") {
            data['consignor_zip'] = ConsignorZip1 + "-" + ConsignorZip2;  //郵便番号
        }else{
            toast.error("郵便番号を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(ConsignorAddress1 != "") {
            data['consignor_address_1'] = ConsignorAddress1;
        }else{
            toast.error("住所（都道府県）を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(ConsignorAddress2 != "") {
            data['consignor_address_2'] = ConsignorAddress2;
        }else{
            toast.error("市区町村を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        data['consignor_address_3'] = ConsignorAddress3;
        data['consignor_town'] = ConsignorTown;
        if(ConsignorTel1!= "" && ConsignorTel2 != "" && ConsignorTel3 != "") {
            data['consignor_tel'] = ConsignorTel1 + "-" + ConsignorTel2 + "-" + ConsignorTel3;  //電話番号
        }else{
            toast.error("電話番号を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(ConsignorFax1!= "" && ConsignorFax2 != "" && ConsignorFax3 != "") {
            data['consignor_fax'] = ConsignorFax1 + "-" + ConsignorFax2 + "-" + ConsignorTel3;  //Fax番号
        }else{
            toast.error("FAX番号を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(ConsignorEmail != "") {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(ConsignorEmail.match(mailformat)){
                data['consignor_email'] = ConsignorEmail;
            }else{
                toast.error("正しいメールアドレスを入力してください。", {autoClose:3000});
                setOpen(false);
                return;
            }
        }else{
            toast.error("メールアドレスを入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setMstConsignorEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data == 1) {
                    toast.success("登録致しました。", {autoClose:3000});
                }else if(response.data.data == 2){
                    toast.error("すでに登録済みです。", {autoClose:3000});
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
                    document.getElementById('input1').focus();
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else{
                toast.error("登録に失敗しました。", {autoClose:3000});
            }
        });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if(SysConsignorId>0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        if(SysConsignorId>0) {
            let data = {};
            data['userID'] = userData['id'];
            data['sys_consignor_id'] = SysConsignorId;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.delMstConsignorEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    setConsignorCode("");
                    setConsignorData([]);
                }
            })
            .catch((error) => {
            });

            setConsignorCode("");
            setConsignorData([]);
        }
    }
    // ========================== starts code for search ===============================
    const [SearchValue, setSearchValue] = useState("");
    const [SearchValue2, setSearchValue2] = useState("");
    const [tableData, setTableData] = useState([]);
    const loadModalData = (event) => {
        event.preventDefault();
        setSearchValue(ConsignorCode);
        setSearchValue2(ConsignorACode);

        if(ConsignorCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['consignor_code'] = ConsignorCode;
            if(ConsignorACode != "") {
                data['consignor_a_code'] = ConsignorACode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstConsignorEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData(response.data.data);
                setShowModal(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData([]);
            setShowModal(true);
        }
    }

    const onDataKeyDown = (e, param) => {
        if(e.keyCode == 13){
            switch(param){
                case 1 :
                    document.getElementById('input1').focus();
                    break;
                case 2 :
                    document.getElementById('input2').focus();
                    break;
                case 3 :
                    document.getElementById('input3').focus();
                    break;
                case 4 :
                    document.getElementById('input4').focus();
                    break;
                case 5 :
                    document.getElementById('input5').focus();
                    break;
                case 6 :
                    document.getElementById('input6').focus();
                    break;
                case 7 :
                    document.getElementById('input7').focus();
                    break;
                case 8 :
                    document.getElementById('input8').focus();
                    break;
                case 9 :
                    document.getElementById('input9').focus();
                    break;
                case 10 :
                    document.getElementById('input10').focus();
                    break;
                case 101 :
                    document.getElementById('input101').focus();
                    break;
                case 11 :
                    document.getElementById('input11').focus();
                    break;
                case 12 :
                    document.getElementById('input12').focus();
                    break;
                case 13 :
                    document.getElementById('input13').focus();
                    break;
                case 14 :
                    document.getElementById('input14').focus();
                    break;
                case 15 :
                    document.getElementById('input15').focus();
                    break;
                case 16 :
                    document.getElementById('input16').focus();
                    break;
                case 17 :
                    document.getElementById('input17').focus();
                    break;
                case 18 :
                    document.getElementById('input18').focus();
                    break;
                default :
            }
        }
    }
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_60px_bl_n = [gstyle.cs_input, gstyle.w60px, gstyle.cs_bl_n];
    const style_input_220px = [gstyle.cs_input, gstyle.w220px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_input_80px = [gstyle.cs_input, gstyle.w80px];
    const style_split_span = [style.split_span];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <ConsignorModal show={showModal} onClose={()=>setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} onChange={setSearchValue} 
                SearchValue2={SearchValue2} onChange2={setSearchValue2}
                tableData={tableData} setTableData={setTableData}
                target={setConsignorData} />
            <Modal2 title="荷送人削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>荷送人マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownConsignorCode(e, 1)} id='input1'
                                        value={ConsignorCode} onChange={handleChangeConsignorCode} />

                                        <input type={'number'} className={style_input_60px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownConsignorCode(e, 2)} id='input2'
                                        value={ConsignorACode} onChange={handleChangeConsignorACode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalData(e)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>名称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}   
                                        id='input3' onKeyDown={(e)=>onDataKeyDown(e, 4)}
                                        value={ConsignorName} onChange={handleChangeConsignorName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>フリガナ</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input4' onKeyDown={(e)=>onDataKeyDown(e, 5)}
                                        value={ConsignorNameKana} onChange={handleChangeConsignorNameKana}  />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>略称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input5' onKeyDown={(e)=>onDataKeyDown(e, 6)}
                                        value={ConsignorAName} onChange={handleChangeConsignorAName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>郵便番号</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input6' onKeyDown={(e)=>onDataKeyDown(e, 7)}
                                        value={ConsignorZip1} onChange={handleChangeConsignorZip1} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input7' onKeyDown={(e)=>onDataKeyDown(e, 8)}
                                        value={ConsignorZip2} onChange={handleChangeConsignorZip2} />
                                        <div className={style.postcode_btn_wrap}>
                                            <button className={style.postcode_btn} onClick={getAddress} >郵便検索</button>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>住所（都道府県）</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input8' onKeyDown={(e)=>onDataKeyDown(e, 9)}
                                        value={ConsignorAddress1} onChange={handleChangeConsignorAddress1} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>市区町村</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input9' onKeyDown={(e)=>onDataKeyDown(e, 101)}
                                        value={ConsignorAddress2} onChange={handleChangeConsignorAddress2} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>町名</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input101' onKeyDown={(e)=>onDataKeyDown(e, 11)}
                                        value={ConsignorTown} onChange={handleChangeConsignorTown} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>丁目・番地</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input10' onKeyDown={(e)=>onDataKeyDown(e, 11)}
                                        value={ConsignorAddress3} onChange={handleChangeConsignorAddress3} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>電話番号</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input11' onKeyDown={(e)=>onDataKeyDown(e, 12)}
                                        value={ConsignorTel1} onChange={handleChangeConsignorTel1} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input12' onKeyDown={(e)=>onDataKeyDown(e, 13)}
                                        value={ConsignorTel2} onChange={handleChangeConsignorTel2} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input13' onKeyDown={(e)=>onDataKeyDown(e, 14)}
                                        value={ConsignorTel3} onChange={handleChangeConsignorTel3} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>FAX番号</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input14' onKeyDown={(e)=>onDataKeyDown(e, 15)}
                                        value={ConsignorFax1} onChange={handleChangeConsignorFax1} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input15' onKeyDown={(e)=>onDataKeyDown(e, 16)}
                                        value={ConsignorFax2} onChange={handleChangeConsignorFax2} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input16' onKeyDown={(e)=>onDataKeyDown(e, 17)}
                                        value={ConsignorFax3} onChange={handleChangeConsignorFax3} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>メールアドレス</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input17' onKeyDown={(e)=>onDataKeyDown(e, 18)}
                                        value={ConsignorEmail} onChange={handleChangeConsignorEmail} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='input18' onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} disabled={SysConsignorId>0?false:true}>削除</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Consignor;