/* eslint-disable eqeqeq */
import style from "./CsvImport.module.css";
import gstyle from "../../css/GeneralStyle.module.css";
import React, { useState, useEffect } from "react";
import * as useJwt from "../../auth/jwt/useJwt.js";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button, Radio, Popconfirm } from "antd";
import axios from "axios";
import ShipperModal from '../../components/common/modal/ShipperByWarehouseModal';
import IconSearch from '../../components/common/icons/icon_search.png';

import CsvImportOrderDataComponent from "../../components/common/CsvImportOrderDataInput.tsx";

const StockImport = () => {

  const [importErrorData, setImportErrorData] = useState([]);
  const [loadFlag, setLoadFlag] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  let csvHeader = [
    "倉庫名",
    "荷主名",
    "地区略称",
    "品番",
    "品名",
    "在庫数量",
    "単位",
    "備考",
  ];

  const [csvImport, setCsvImport] = useState(0)

  useEffect(() => {
    console.log('importError', importErrorData[0]);
    if(importErrorData[0] == "success" && loadFlag == false){
      toast.success("インポート致しました。", {autoClose:3000});
      setImportErrorData([])
    }
    csLoading(loadFlag);
    setCsvImport(1)
  }, [loadFlag, importErrorData]);

  const csLoading = (flag) => {
    if (flag) {
      document.getElementById("csLoader").style.display = "flex";
    } else {
      document.getElementById("csLoader").style.display = "none";
    }
  };
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const [TargetParam, setTargetParam] = useState(1);
  const userData = JSON.parse(useJwt.getUserData());
    // shipper ---------------------------------------------------
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        setShipperCode(useJwt.validateNum(event.target.value, 6));
    };
    const [ShipperACode, setShipperACode] = useState("");
    const handleChangeShipperACode = (event) => {
        setShipperACode(useJwt.validateNum(event.target.value, 3));
    };
    const [ShipperName, setShipperName] = useState("");
    const [ShipperId, setShipperId] = useState("");

    const onKeyDownShipperCode = (event, param1, param2) => {
        if (event.keyCode === 13) { // enter key
            getShipperData(event.target.value, param1, param2);
            event.preventDefault();
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if (param1 == 1) {
            if (param2 == 1) {
                code = value;
                if (ShipperACode != "") {
                    acode = ShipperACode;
                } else {
                    acode = "000";
                }
            } else {
                code = ShipperCode;
                if (value != "") {
                    acode = value;
                } else {
                    acode = "000";
                }
            }
        } else {
            if (param2 == 1) {
                code = value;
                if (ShipperACode != "") {
                    acode = ShipperACode;
                } else {
                    acode = "000";
                }
            } else {
                code = ShipperCode;
                if (value != "") {
                    acode = value;
                } else {
                    acode = "000";
                }
            }
        }

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if (acode != "") {
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                }).then((response) => {
                    if (response.status == 200) {
                        // useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setShipperData(result, param1);
                            document.getElementById('warehouse_input').focus();
                        } else {
                            setShipperData([], param1);
                            if(param2 == 2){
                                document.getElementById('warehouse_input').focus();
                            }else{
                                document.getElementById('shipper_a_input').focus();
                            }
                        }
                    }
                })
                .catch((error) => {
                    if(param2 == 2){
                        document.getElementById('warehouse_input').focus();
                    }else{
                        document.getElementById('shipper_a_input').focus();
                    }
                });
        } else {
            setShipperData([], param1);
            if(param2 == 2){
                document.getElementById('warehouse_input').focus();
            }else{
                document.getElementById('shipper_a_input').focus();
            }
        }
    }
    const setShipperData = (data, param) => {
        if (data['shipper_code'] != null) {
            setShipperCode(data['shipper_code']);
            setShipperId(data['shipper_id']);
            setShipperACode(data['shipper_a_code']);
            setShipperName(data['shipper_name']);
        } else {
            setShipperCode("");
            setShipperId("");
            setShipperACode("");
            setShipperName("");
        }
    }

      // ----------------------------- shipper modal -------------------------------------
      const [showModalShipper, setShowModalShipper] = useState(false);
      const [ShipperSearchValue, setShipperSearchValue] = useState("");
      const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
      const [ShipperTableData, setShipperTableData] = useState([]);
      const loadShipperModalData = (event, param) => {
          event.preventDefault();
          setTargetParam(param);
          let code = ShipperCode;
          setShipperSearchValue(code);
          setShipperSearchValue2("");
  
          if (code != "") {
              let data = {};
              data['userID'] = userData['id'];
              data['shipper_code'] = code;
  
              let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
              axios
                  .post(url, data, {
                      headers: {
                          Authorization: "Bearer " + useJwt.getToken(),
                      },
                  })
                  .then((response) => {
                      setShipperTableData(response.data.data);
                      setShowModalShipper(true);
                  })
                  .catch((error) => {
                  });
          } else {
              setShipperTableData([]);
              setShowModalShipper(true);
          }
      }

      const stockDelete = (e, val) =>{
        let data = {};
        data['userID'] = userData['id'];
        if(val === 2) {
          data['shipper_code'] = ShipperCode;
          data['shipper_a_code'] = ShipperACode;
        }

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.deleteStockEndpoint;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
              setModalOpen(false)
              toast.success("削除しました。", { autoClose: 3000 });
            })
            .catch((error) => {
              setModalOpen(false)
              toast.error("削除できませんでした。", {autoClose:3000});
            });
      }
      const style_input_100px = [gstyle.cs_input, gstyle.w100px];
      const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
      const style_input_bl_n = [gstyle.cs_input, style.job_time];

  return (
    <div className={gstyle.display}>
      <ShipperModal show={showModalShipper} onClose={() => setShowModalShipper(false)} width={'70%'}
          SearchValue={ShipperSearchValue} onChange={setShipperSearchValue}
          SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
          tableData={ShipperTableData} setTableData={setShipperTableData}
          target={setShipperData} targetParam={TargetParam} />
      <div className={gstyle.loader_container} id="csLoader">
        <ClipLoader color={"#fff"} size={60} />
      </div>
      <ToastContainer />
      <div className={gstyle.border}>
        <p className={gstyle.text}>在庫CSVインポート</p>
      </div>
      <div className={gstyle.filed}>
        <div className={style.cs_card}>
          <div style={{display:'flex'}}>
            <CsvImportOrderDataComponent
              url={jwtDefaultConfig.importStockItemCsv}
              setErrorData={setImportErrorData}
              setLoadFlag={setLoadFlag}
              csvHeader={csvHeader}
              index={1}
            />
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <button className="stock_delete" onClick={(e)=>setModalOpen(true)}>在庫削除</button>
            </div>
          </div>

          <div className={style.error_tag}>
            {importErrorData?.map((value, index) => (
              value != 'success' &&
              <p className={style.error_content} key={index}>{value}</p>
            ))}
          </div>
        </div>
      </div>
      <Modal
        centered
        closable={false}
        open={modalOpen}
        width={600}
        footer={[
          <div style={{display:'flex', justifyContent:'center', gap:30}}>
            <Popconfirm
                title="削除"
                description="在庫を削除しますか？"
                okText="削除"
                cancelText="閉じる"
                placement="top"
                style={{width:300}}
                onConfirm={(e)=>stockDelete(e, value)}
            >
              <Button type="primary" danger>削除</Button>
            </Popconfirm>
            <Button type="primary" onClick={(e)=>setModalOpen(false)}>閉じる</Button>
          </div>
        ]}
        >
        <div style={{display:'flex', flexDirection:'column', gap:20, marginTop:20}}>
          <Radio.Group onChange={onChange} value={value}>
            <div style={{display:'flex', flexDirection:'column', gap:30}}>
              <Radio value={1} style={{height:35, lineHeight:2.5}}>全在庫削除</Radio>
              <Radio value={2} style={{height:35, lineHeight:2.5}}>
                <div style={{display:'flex'}}>
                  荷主別削除&nbsp;&nbsp;&nbsp;
                  {value === 2 ? (
                    <div className={gstyle.cs_flex}>
                        <input type={'number'} className={style_input_100px.join(" ")} style={{margin:0}}
                            onKeyDown={(e) => onKeyDownShipperCode(e, 1, 1)} id="shipper_input"
                            value={ShipperCode} onChange={handleChangeShipperCode} />
                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} style={{margin:0}}
                            onKeyDown={(e) => onKeyDownShipperCode(e, 1, 2)}  id="shipper_a_input"
                            value={ShipperACode} onChange={handleChangeShipperACode} />
                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled style={{margin:0}}
                            value={ShipperName} />
                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadShipperModalData(e, 1)}>
                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                        </div>
                    </div>
                  ) : null}
                </div>
              </Radio>
            </div>
          </Radio.Group>
        </div>
      </Modal>
    </div>
  );
};

export default StockImport;
