/* eslint-disable eqeqeq */
import React, {useState, useEffect} from 'react';
import axios from "axios";
import style from './Modal.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import Modal from './Modal';
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { toast } from 'react-toastify';
import { Button } from 'antd';

const WarehouseUserModal = props => {

    const [loaded, setLoaded] = useState(false);
    const userData = JSON.parse(useJwt.getUserData());

    useEffect(() => {
    }, [props]);


    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_select_40 = [gstyle.cs_select, gstyle.w40];
    const style_input_50 = [gstyle.cs_input, gstyle.w50, gstyle.cs_bl_n];

    const style_th_col_1 = [style.cs_th, gstyle.cs_col_1];
    const style_td_col_1 = [style.cs_td, gstyle.cs_col_1];

    const [SearchFieldType, setSearchFieldType] = useState(0);
    const handleChangeSearchFieldType = (event) => {
        setSearchFieldType(event.target.value);
    }
    const handleChangeSearchValue = (event) => {
        props.onChange(event.target.value);
    }

    const handleKeyDown = (event) => {
        if(event.keyCode === 13){
            searchData();
        }
    }
    
    const searchData = () => {
        let data = {};
        data['userID'] = userData['id'];
        data['shipper_user_id'] = userData['id'];
        if(SearchFieldType == 0) {
            data['company_name'] = props.SearchValue;
        } else if(SearchFieldType == 1) {
            data['manage_name'] = props.SearchValue;
        }
        if(document.getElementById("WarehouseFilterByLike").checked){
            data['filterByLike'] = 1;
        }else{
            data['filterByLike'] = 0;
        }
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseUserEndpoint;
        setLoaded(true);
        props.setTableData([]);

        if(props.url)   url = props.url;

        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            setLoaded(false);
            if(response.status == 200) {
                if(response.data.data.length > 0){
                //     let result = response.data.data[0];
                //     props.target(result,props.targetParam!=null?props.targetParam:'');
                //     props.onClose(true);
                // }else{
                    props.setTableData(response.data.data);
                }

                if(response.data.data.length == 0)  toast.error("検索結果はありません。", { autoClose: 3000 });
            }
        })
        .catch((error) => {
            setLoaded(false);
            toast.error("検索結果はありません。", { autoClose: 3000 });
        });
    }
    const handleChangeData = (event, warehouse_data) => {
        event.preventDefault();
        let result = JSON.parse(warehouse_data);
        props.target(result,props.targetParam!=null?props.targetParam:'');
        props.onClose(true);
    }

    return (
        <Modal title="倉庫会社検索" show={props.show} onClose={props.onClose} width={props.width}>
            <div className={gstyle.hyfiled}>
                <div style={{display: 'flex', alignItems:'center'}}>
                    <label className={style_label_wrap.join(" ")}>
                        <div className={gstyle.cs_flex}>
                            <select className={style_select_40.join(" ")} id="SearchFieldType"
                                value={SearchFieldType}
                                onChange={handleChangeSearchFieldType}>
                                    <option value="0">名称</option>
                                    <option value="1">担当者名</option>
                                </select>
                            <input type={'text'} className={style_input_50.join(" ")} 
                                value={props.SearchValue} onKeyDown={handleKeyDown}
                                onChange={handleChangeSearchValue} />
                        </div>
                    </label>
                    <Button onClick={searchData} loading={loaded} type={'primary'} style={{marginLeft: 30, width:120, height:35, borderRadius:'unset'}}>
                        { loaded ? '検索中' : '検索'}
                    </Button>
                    <label className={gstyle.cs_label}>
                        <div className={gstyle.cs_flex}>
                            <input type={"checkbox"} className={style.cs_input_check} id="WarehouseFilterByLike" />
                            <span>部分検索</span>
                        </div>
                    </label>
                </div>
            </div>
            <div className={gstyle.hyfiled}>
                <div className={style.cs_tbl}>
                    <div className={style.cs_thead}>
                        <div className={style.cs_tr}>
                            <div className={style.cs_th}>名称</div>
                            <div className={style.cs_th}>担当者名</div>
                        </div>
                    </div>
                    <div className={style.cs_tbody}>
                        { props.tableData.map((data) => (
                            <div className={style.cs_tr} key={data['id']} 
                            onClick={(e)=>handleChangeData(e,JSON.stringify(data))}>
                                <div className={style.cs_td_left}>{data['company_name']}</div>
                                <div className={style.cs_td_left}>{data['manage_name']}</div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default WarehouseUserModal;




