/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import style from "./Login.module.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import * as useJwt from "../../auth/jwt/useJwt.js";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button } from "antd";

const Login = (props) => {
  //useJwt.logout();
  // パスワード表示制御ようのstate
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    $('#modal').css('opacity', 1);
    $('#modal').css('z-index', 100);
  };

  const handleForget = (event) => {
    event.preventDefault();
    $('#modal_forget').css('opacity', 1);
    $('#modal_forget').css('z-index', 100);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const authentication = useJwt.isUserLoggedIn();
  const shipperAuthentication = useJwt.isShipperUserLoggedIn();

  useEffect(() => {
    if (authentication === true) navigate("/");
    if (shipperAuthentication === true) navigate("/shipper");
  }, [props, authentication, shipperAuthentication, navigate]);

  const shipperLogin = (event, flag) =>{
    $('#modal').css('opacity', 0);
    $('#modal').css('z-index', 0);

    $('#modal_forget').css('opacity', 0);
    $('#modal_forget').css('z-index', 0);

    if(flag == 1){
      const data = {
        email: email,
        password: password,
      };
  
      axios
        .post(useJwt.getShipperLoginEndpoint(), data)
        .then((response) => {
          const userData = response.data.data;
          useJwt.setToken(response.data.token);
  
          useJwt.setShipperUserData(JSON.stringify(userData));
  
          const data = {
            userID: userData["id"],
            sys_menu_pattern_id: userData["sys_menu_pattern_id"],
          };
          let url =
            jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperMenuPatternEndpoint;
          axios
            .post(url, data, {
              headers: {
                Authorization: "Bearer " + useJwt.getToken(),
              },
            })
            .then((response2) => {
              if (response2.status == 200) {
                if (response2.data.data.length > 0) {
                  let result = response2.data.data[0];
                  useJwt.setMenuPatternData(JSON.stringify(result));
                }
                navigate("/shipper");
              }
            })
            .catch((error) => {});
        })
        .catch((error) => {
          toast.error(error.response.data.message, { autoClose: 3000 });
        });
      }else{
        navigate("/forget/shipper");
      }
  }

  const cancelClose = (e) =>{
    e.stopPropagation();
    e.preventDefault();
    return false;  
  }

  const loginClose = () =>{
    $('#modal').css('opacity', 0);
    $('#modal').css('z-index', 0);

    $('#modal_forget').css('opacity', 0);
    $('#modal_forget').css('z-index', 0);
  }
  const warehouseLogin = (event, flag) =>{
    $('#modal').css('opacity', 0);
    $('#modal').css('z-index', 0);

    $('#modal_forget').css('opacity', 0);
    $('#modal_forget').css('z-index', 0);

    if(flag == 1){
      const data = {
        email: email,
        password: password,
      };
  
      axios
        .post(useJwt.getWarehouseLoginEndpoint(), data)
        .then((response) => {
          const userData = response.data.data;
          useJwt.setToken(response.data.token);
  
          useJwt.setUserData(JSON.stringify(userData));
  
          const data = {
            userID: userData["id"],
            sys_menu_pattern_id: userData["sys_menu_pattern_id"],
          };
          let url =
            jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMenuPatternEndpoint;
          axios
            .post(url, data, {
              headers: {
                Authorization: "Bearer " + useJwt.getToken(),
              },
            })
            .then((response2) => {
              if (response2.status == 200) {
                if (response2.data.data.length > 0) {
                  let result = response2.data.data[0];
                  useJwt.setMenuPatternData(JSON.stringify(result));
                }
                navigate("/");
              }
            })
            .catch((error) => {});
        })
        .catch((error) => {
          toast.error(error.response.data.message, { autoClose: 3000 });
        });
      }else{
        navigate("/forget/warehouse");
      }
  }

  return (
    <>
    <div className={style.loginModal} id="modal" onClick={loginClose}>
      <div className={style.modalContent} onClick={cancelClose}>
        <Button type="primary" style={{width:120}} onClick={(e) => shipperLogin(e, 1)}>荷主</Button>
        <Button type="default" style={{width:120}} onClick={(e) => warehouseLogin(e, 1)}>倉庫</Button>
      </div>
    </div>

    <div className={style.loginModal} id="modal_forget" onClick={loginClose}>
      <div className={style.modalContent} onClick={cancelClose}>
        <Button type="primary" style={{width:120}} onClick={(e) => shipperLogin(e, 2)}>荷主</Button>
        <Button type="default" style={{width:120}} onClick={(e) => warehouseLogin(e, 2)}>倉庫</Button>
      </div>
    </div>

    <div className={style.background}>
      <div className={style.userRegistryLayout}>
        <Link to={"/registry"} className={style.userRegistryBut}>
          会員登録はこちら
        </Link>
      </div>
      <div className={style.form}>
        <div className={style.title}>
          <span>ログイン</span>
        </div>
        <div>
          <div>
            <div className={style.subTitle}>メールアドレス</div>
            <div className={style.emailField}>
              <input
                className={style.emailText}
                type="text"
                placeholder="Email"
                value={email}
                onChange={handleChangeEmail}
              />
            </div>
          </div>
          <div>
            <div className={style.subTitle}>パスワード</div>
            <div className={style.passwordField}>
              <input
                className={style.PasswordText}
                placeholder="Password"
                type={isRevealPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={handleChangePassword}
              />
              {isRevealPassword ? (
                <AiFillEyeInvisible
                  onClick={togglePassword}
                  role="presentation"
                  className={style.PasswordReveal}
                />
              ) : (
                <AiFillEye
                  onClick={togglePassword}
                  role="presentation"
                  className={style.PasswordReveal}
                />
              )}
            </div>
          </div>

          <div className={style.checkboxField}>
            <input type="checkbox" id="check"/>
            <label className={style.label} for="check" style={{marginLeft:3}}>ログイン状態を維持する</label>
          </div>

          <div className={style.linkField}>
            <button className={style.forgetButton} onClick = {handleForget} type="button">
              パスワードを忘れた方
            </button>
          </div>

          <div className={style.submitButtonField}>
            <button className={style.submitButton}  onClick = {handleSubmit} type="button">
              ログイン
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
    </>
  );
};

export default Login;
