/*====================================================================================================
	単位変更処理 -> AdobeXD 24ページ
	作成日:2022/08/26		最終更新日:2022/09/01
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

import React from 'react';
import MyTextBox from "../../../common/tenjin_tokubuchi/my_text_box/MyTextBox";
import style from "./RecomposeLotDate.module.css";

const RecomposeLotDate = ()=> {
    return (
        <MyTextBox
            style_flex={`${style.flex}`}
            style_title={`${style.title}`}
            style_input_text={`${style.input_text}`}
            title_text={"在庫移動日"}
            name={"RecomposeLotDate"}
            tab_index={1}
        />
    );
}

export default RecomposeLotDate;