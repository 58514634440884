/* eslint-disable eqeqeq */

import jwtDefaultConfig from './jwtDefaultConfig';

const getWarehouseLoginEndpoint = () => {
    return jwtDefaultConfig.backendUrl+jwtDefaultConfig.warehouseLoginEndpoint;
}

const getShipperLoginEndpoint = () => {
    return jwtDefaultConfig.backendUrl+jwtDefaultConfig.shipperLoginEndpoint;
}

const getPasswordChangeEndpoint = () => {
    return jwtDefaultConfig.backendUrl+jwtDefaultConfig.passwordChangeEndpoint;
}

const getShipperPasswordForgetEndpoint = () => {
    return jwtDefaultConfig.backendUrl+jwtDefaultConfig.shipperPasswordForgetEndpoint;
}

const getWarehousePasswordForgetEndpoint = () => {
    return jwtDefaultConfig.backendUrl+jwtDefaultConfig.warehousePasswordForgetEndpoint;
}

const setToken = (value) => {
    localStorage.setItem(jwtDefaultConfig.storageTokenKeyName, value);
}

const getToken = () => {
    return localStorage.getItem(jwtDefaultConfig.storageTokenKeyName);
}

const setRefreshToken = (value) => {
    localStorage.setItem(jwtDefaultConfig.storageRefreshTokenKeyName, value);
}

const getRefreshToken = () => {
    return localStorage.getItem(jwtDefaultConfig.storageRefreshTokenKeyName);
}

const setUserData = (value) => {
    localStorage.setItem(jwtDefaultConfig.userDataKeyName, value); 
}

const getUserData = () => {
    let userData = localStorage.getItem(jwtDefaultConfig.userDataKeyName);
    if(userData)
        return localStorage.getItem(jwtDefaultConfig.userDataKeyName);
    else
        return localStorage.getItem(jwtDefaultConfig.shipperUserDataKeyName);
}

const setShipperUserData = (value) => {
    localStorage.setItem(jwtDefaultConfig.shipperUserDataKeyName, value); 
}

const getShipperUserData = () => {
    return localStorage.getItem(jwtDefaultConfig.shipperUserDataKeyName);
}

const setMenuPatternData = (value) => {
    localStorage.setItem(jwtDefaultConfig.menuPatternDataKeyName, value); 
}

const setQRcodeData = (value) => {
    localStorage.setItem("QRcode", value); 
}

const getQRcodeData = ( ) => {
    return localStorage.getItem("QRcode");
}

const setDriverWorking = (value) => {
    localStorage.setItem("driverWorking", value); 
}

const getDriverWorking = ( ) => {
    return localStorage.getItem("driverWorking");
}

const removeDriverWorking = ( ) => {
    return localStorage.removeItem("driverWorking");
}

const setDriverData = (value) => {
    localStorage.setItem("driverData", value); 
}

const getDriverData = ( ) => {
    return localStorage.getItem("driverData");
}

const removeDriverData = ( ) => {
    return localStorage.removeItem("driverData");
}

const setPopupCount = (value) =>{
    localStorage.setItem("popupCount", value); 
}

const getPopupCount = ( ) => {
    return localStorage.getItem("popupCount");
}

const getMenuPatternData = () => {
    return localStorage.getItem(jwtDefaultConfig.menuPatternDataKeyName);
}

const isUserLoggedIn = () => {
    if(localStorage.getItem(jwtDefaultConfig.userDataKeyName) && localStorage.getItem(jwtDefaultConfig.storageTokenKeyName))
        return  true;
    else 
        return false;
}

const isShipperUserLoggedIn = () => {
    if(localStorage.getItem(jwtDefaultConfig.shipperUserDataKeyName) && localStorage.getItem(jwtDefaultConfig.storageTokenKeyName))
        return  true;
    else 
        return false;
}
const logout = () => {
    localStorage.removeItem(jwtDefaultConfig.userDataKeyName);
}

const shipperLogout = () => {
    localStorage.removeItem(jwtDefaultConfig.shipperUserDataKeyName);
}

const getGoogleApiKey = () => {
    return jwtDefaultConfig.googleApiKey;
}

const validateNum = (value, len) => {
    let z1= /^[0-9]*$/;
    let str = value;
    if(str.length>len) {
        str = str.substring(0, len);
    }
    if(!z1.test(str)) { 
        str = "";
    }
    return str;
}
const validateString = (value, len) => {
    let str = value;
    if(str.length>len) {
        str = str.substring(0, len);
    }
    return str;
}

const validateDecimal = (value,i,j) => {
    let str = value;
    var regexp = '';
    
    if(i==6 && j==3){
        regexp = /^\d+(\.\d{0,3})?$/;
        if(str != "" && !regexp.test(str)) {
            str = str.substring(0, str.length-1);
        }else if(str*1>1000000){
            str = str.substring(0, str.length-1);
        }
    }else if(i==7 && j==2){
        regexp = /^\d+(\.\d{0,2})?$/;
        if(str != "" && !regexp.test(str)) {
            str = str.substring(0, str.length-1);
        }else if(str*1>10000000){
            str = str.substring(0, str.length-1);
        }
    }else if(i==7 && j==3){
        regexp = /^\d+(\.\d{0,3})?$/;
        if(str != "" && !regexp.test(str)) {
            str = str.substring(0, str.length-1);
        }else if(str*1>10000000){
            str = str.substring(0, str.length-1);
        }
    }else if(i==9 && j==3){
        regexp = /^\d+(\.\d{0,3})?$/;
        if(str != "" && !regexp.test(str)) {
            str = str.substring(0, str.length-1);
        }else if(str*1>1000000000){
            str = str.substring(0, str.length-1);
        }
    }else if(i=='x' && j==3){
        regexp = /^\d+(\.\d{0,3})?$/;
        if(str != "" && !regexp.test(str)) {
            str = str.substring(0, str.length-1);
        }
    }
    return str;
};

const validateYearMonth = (value) => {
    let str = value;
    if(str.length>7) {
        str = str.substring(0, 7);
    }
    var regexp = /^(\d{1,4})[./-](\d{1,2})?$/;
    if(str.length<5){
        regexp = /^(\d{1,4})?$/;
    }
    if(!regexp.test(str)) {
        str = str.substring(0, str.length-1);
    }
    return str;
}

const formatDate = (date) => {
    let year = date.getFullYear();
    let month = '' + (date.getMonth()*1 + 1);
    let day = '' + date.getDate();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return  year + '-' + month + '-' + day;
}
const formatDate2 = (date) => {
    var start = new Date(date);
    let year = start.getFullYear();
    let month = '' + (start.getMonth()*1 + 1);
    let day = '' + start.getDate();
    return  year + '年 ' + month + '月 ' + day + '日';
}

const formatDateMonth = ( date ) =>{
    let temp = date.split('-');
    if(temp.length == 2){
        let tempDate = new Date(temp[0]*1, temp[1]*1, 0);
        let year = tempDate.getFullYear();
        let month = '' + (tempDate.getMonth()*1 + 1);
        let day = '' + tempDate.getDate();
        return  year + '年 ' + month + '月 ' + day + '日';
    }
}
const formatYearMonth = (date) => {
    let year = date.getFullYear();
    let month = '' + (date.getMonth()*1 + 1);
    return  year + '年 ' + month + '月';
}

const formatDateTime = (date) => {

    let year = date.getFullYear();
    let month = '' + (date.getMonth()*1 + 1);
    let day = '' + date.getDate();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    let hour = date.getHours();
    if (hour.length < 2) 
        hour = '0' + hour;
    let min = date.getMinutes();
    if (min.length < 2) 
        min = '0' + min;
    let sec = date.getSeconds();
    if (sec.length < 2) 
        sec = '0' + sec;

    return ''+ year + month + day +"_"+ hour + min + sec ;
}
const getPreviousDay = (date) => {
    let tdate = new Date(date);
    const previous = new Date(tdate.getTime());
    previous.setDate(tdate.getDate() - 1);
    return formatDate(previous);
}
const getPreviousMonth = (date) => {
    let tdate = new Date(date);
    const previous = new Date(tdate.getTime());
    if(tdate.getDate()>28){
        previous.setDate(28);
    }
    previous.setMonth(tdate.getMonth() - 1);
    return getYearMonth(formatDate(previous));
}
const getDay = (date) => {
    const date_ary = date.split("-");
    return date_ary[2]*1;
}
const getYearMonth = (date) => {
    const date_ary = date.split("-");
    return date_ary[0]+"/"+date_ary[1];
}

const numberWithCommas = (x) => {
    let num = x * 1.0;
    let temp = num.toFixed(3).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1');
    return temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const moneyUnitView = (val, unit, roundUnit) =>{
    let calc_value = 0;
    switch (unit) {
        case '0':
            calc_value = val;            
            break;
        case '1':
            calc_value = val / 10;            
            break;
        case '2':
            calc_value = val / 100;  
            break;
        case '3':
            calc_value = val / 1000;            
            break;
            
        default:
            break;
    }

    switch (roundUnit) {
        case '0':
            calc_value = Math.round(calc_value);            
            break;
        case '1':
            calc_value = Math.ceil(calc_value);            
            break;
        case '2':
            calc_value = Math.floor(calc_value);            
            break;
            
        default:
            break;
    }

    return calc_value;
}

const amountUnitView = (val, unit, roundUnit) =>{
    let calc_value = 0;
    switch (unit) {
        case '0':
            calc_value = val;            
            break;
        case '1':
            calc_value = val / 10;            
            break;
        case '2':
            calc_value = val / 100;  
            break;
        case '3':
            calc_value = val / 1000;            
            break;
            
        default:
            break;
    }

    switch (roundUnit) {
        case '0':
            calc_value = Math.round(calc_value);            
            break;
        case '1':
            calc_value = Math.ceil(calc_value);            
            break;
        case '2':
            calc_value = Math.floor(calc_value);            
            break;
            
        default:
            break;
    }

    return calc_value;
}

const createToken = (str) => {
    str = str.replace('-', '');
    return 'xxxxxxxxxxxx4xxxxxx'.replace(/[x]/g, function (c, p) {
      return str[p % str.length];
    });
  }

  const pad = (n) => {
    return new Array(n).join('0').slice((n || 2) * -1);
  }

  const beforeDeg = (n, val) => {
    if(val != "")   return pad(n - val.length) + val
    else    return ''
  }
export { 
    getWarehouseLoginEndpoint
    ,getShipperLoginEndpoint
    ,setToken
    ,getToken
    ,setRefreshToken
    ,getRefreshToken
    ,setUserData
    ,getUserData
    ,setShipperUserData
    ,getShipperUserData
    ,setMenuPatternData
    ,getMenuPatternData
    ,isUserLoggedIn
    ,isShipperUserLoggedIn
    ,logout
    ,shipperLogout
    ,getGoogleApiKey
    ,validateNum
    ,validateString
    ,validateDecimal
    ,validateYearMonth
    ,formatDate
    ,formatDate2
    ,formatYearMonth
    ,formatDateTime
    ,getDay
    ,getYearMonth
    ,getPreviousDay
    ,getPreviousMonth
    ,setQRcodeData
    ,getQRcodeData
    ,getShipperPasswordForgetEndpoint
    ,getWarehousePasswordForgetEndpoint
    ,getPasswordChangeEndpoint
    ,numberWithCommas
    ,setDriverWorking
    ,getDriverWorking
    ,removeDriverWorking
    ,setDriverData
    ,getDriverData
    ,removeDriverData
    ,setPopupCount
    ,getPopupCount
    ,formatDateMonth
    ,moneyUnitView
    ,amountUnitView
    ,createToken
    ,beforeDeg
}