/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import axios from "axios";
import style from './Modal.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import Modal from './Modal';
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { toast } from 'react-toastify';
import { Button, Radio } from 'antd';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { jaJP } from '@mui/material/locale';
import CarrierModal from '../../../components/common/modal/ShipperCarrierModal';
import DriverModal from '../../../components/common/modal/ShipperDriverModal';
import HistoryModal from '../../../components/common/modal/InoutHistoryModal.js';
import IconSearch from '../../../components/common/icons/icon_search.png';
import { useNavigate } from "react-router-dom";
import ClipLoader from 'react-spinners/ClipLoader';
import moment from 'moment/moment.js';
import InputDatepicker from '../../../components/common/input/InputDatepicker';

const InoutModal = props => {

    const [loaded, setLoaded] = useState(false);

    const userData = JSON.parse(useJwt.getUserData());
    const theme = useTheme();
    const navigate = useNavigate();

    const columns = [
        {
            id: 'item_name',
            accessorKey: 'item_name',
            enableColumnActions: false,
            header: '名称',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.item_name}</div>
            ),
            size: 200
        },
        {
            id: 'item_a_name',
            accessorKey: 'item_a_name',
            enableColumnActions: false,
            header: '名称(ﾌﾘｶﾞﾅ)',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.item_a_name}</div>
            ),
            size: 150
        },
        {
            id: 'shipper_item_code',
            accessorKey: 'shipper_item_code',
            enableColumnActions: false,
            header: '荷主品番コード',
            size: 150
        },
        {
            id: 'item_quantity',
            accessorKey: 'item_quantity',
            enableColumnActions: false,
            header: '入数',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.item_quantity}</div>
            ),
            size: 100
        },
    ];
    const [inout, setInout] = useState("1");

    const handleClickInout = (e) => {
        console.log(e.target.value)
        setInout(e.target.value)
        clearItem();
    }

    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_select_40 = [gstyle.cs_select, gstyle.w170px];
    const style_input_100 = [gstyle.cs_input, gstyle.w220px, gstyle.cs_bl_n];

    const [SearchFieldType, setSearchFieldType] = useState(0);
    const handleChangeSearchFieldType = (event) => {
        setSearchFieldType(event.target.value);
    }
    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            searchData();
        }
    }
    const [searchValue, setSearchValue] = useState("")
    const searchData = () => {
        let data = {};
        data['userID'] = userData['id'];
        data['shipper_user_id'] = userData['id'];
        data['create_user_id'] = props.warehouseId;
        data['shipper_code'] = props.shipperCode;

        if (SearchFieldType == 0) {
            data['item_name'] = searchValue;
        } else if (SearchFieldType == 1) {
            data['item_name_kana'] = searchValue;
        } else {
            data['shipper_item_code'] = searchValue;
        }
        data['filterByLike'] = 1;
        data['myLifeWarehouse'] = '1'
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperMstItemEndpoint;

        setItemTableData([]);
        setLoaded(true);

        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(false);

                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        setItemTableData(response.data.data);
                    }
                    if (response.data.data.length == 0) toast.error("検索結果はありません。", { autoClose: 3000 });
                }
            })
            .catch((error) => {
                setLoaded(false);
                toast.error("検索結果はありません。", { autoClose: 3000 });
                if (error.response && error.response.status && error.response.status === 401) {
                    useJwt.shipperLogout();
                    navigate("/login");
                }
            });
    }

    const handleChangeRotCode = (e) => {
        setRotCode(e.target.value)
    }

    const handleChangeRotName = (e) => {
        setRotName(e.target.value)
    }

    const handleChangeOther = (e) => {
        setOther(e.target.value)
    }

    const [stockData, setStockData] = useState([])
    const handleChangeData = (itemData) => {
        console.log(itemData)
        setItemId(itemData.sys_item_id)
        setItemName(itemData.item_name)
        setItemCode(itemData.item_code)
        setItemACode(itemData.item_a_code)
        setItemUnit(itemData.item_unit)
        setShipperItemCode(itemData.shipper_item_code)
        setQuantity(itemData.item_quantity)

        csLoading(true)
        let data = {};
        data['userID'] = userData['id'];
        data['item_code'] = itemData.item_code;
        data['warehouse_id'] = props.warehouseId
        data['shipper_code'] = props.shipperCode

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstItemStockByShipper;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setStockData(response.data.data)
                csLoading(false)
            })
            .catch((error) => {
                csLoading(false)
                if (error.response && error.response.status && error.response.status === 401) {
                    useJwt.shipperLogout();
                    navigate("/login");
                }
            });

        // props.target(itemData, props.targetParam != null ? props.targetParam : '');
        // props.onClose(true);
        // props.setTableData([]);
    }

    const clearItem = () => {
        setItemName("")
        setItemCode("")
        setItemACode("")
        setItemUnit("")
        setShipperItemCode("")
        setQuantity("")
        setCarrierId("")
        setCarrierName("")
        setDriverId("")
        setDriverName("")
        setDriverPhone("")
        setCarNumber("")
        setCarStandard("1")
        setAmount("")
        setCarNumber("")
        setStartTime("")
        setOther("")
    }

    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const inoutHistory = () => {
        if (itemName == "") {
            toast.error("商品をお選びください。", { autoClose: 3000 });
            return
        }

        setStartDate(moment(props.commandDate, "YYYY-MM-DD").add('-1', 'months').format("YYYY-MM-DD"))
        setEndDate(props.commandDate)

        setHistoryModalShow(true)

    }
    const [addLoaded, setAddLoaded] = useState(false)
    const [carrierId, setCarrierId] = useState("")
    const [carrierName, setCarrierName] = useState("")
    const [driverId, setDriverId] = useState("")
    const [carNumber, setCarNumber] = useState("")
    const [carStandard, setCarStandard] = useState("1")

    const setCarrierData = (data) => {
        if (data['id']) {
            setCarrierId(data['id'])
            setCarrierName(data['carrier_name'])
        }
    }

    // ------------------------------- Carrier Modal ------------------------------------
    const [showModalCarrier, setShowModalCarrier] = useState(false);
    const [CarrierSearchValue, setCarrierSearchValue] = useState("");
    const [CarrierSearchValue2, setCarrierSearchValue2] = useState("");
    const [CarrierTableData, setCarrierTableData] = useState([]);
    const loadCarrierModalData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        data['shipper_id'] = userData['id'];

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCarrierShipperEndpoint;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setCarrierTableData(response.data.data);
                setShowModalCarrier(true);
            })
            .catch((error) => {
                if (error.response && error.response.status && error.response.status === 401) {
                    useJwt.shipperLogout();
                    navigate("/login");
                }
            });
    }


    //driver data
    const [driverModalShow, setDriverModalShow] = useState(false);
    const [driverTableData, setDriverTableData] = useState([]);
    const [driverSearchValue, setDriverSearchValue] = useState("");
    const [driverSearchValue2, setDriverSearchValue2] = useState("");
    const loadDriverModalData = (event, carNumber) => {
        event.preventDefault();
        setDriverSearchValue(carrierId);
        setDriverSearchValue2(carNumber);
        if (carrierId != "") {
            const data = {
                userID: userData['id'],
                transport: carrierId,
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperCarrierDriverEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setDriverTableData(response.data.data);
                    setDriverModalShow(true);
                })
                .catch((error) => {
                    setDriverTableData([]);
                    if (error.response && error.response.status && error.response.status === 401) {
                        useJwt.shipperLogout();
                        navigate("/login");
                    }
                });
        } else {
            setDriverTableData([]);
            toast.error("運送会社を選択してください。", { autoClose: 3000 });

        }
    }


    const setDriverData = (data) => {
        if (data['id']) {
            setDriverId(data['id'])
            setCarNumber(data['car_number'])
            setCarStandard(data['car_standard'])
            setDriverName(data['driver_name'])
            setDriverPhone(data['phone_number'])
        }
    }
    const [driverName, setDriverName] = useState("")
    const [driverPhone, setDriverPhone] = useState("")
    const handleDriverNameChange = (e) => {
        setDriverName(e.target.value)
    }
    const handleDriverPhoneChange = (e) => {
        setDriverPhone(e.target.value)
    }
    const handleCarNumberChange = (e) => {
        setCarNumber(e.target.value)
    }
    const onKeyDownCarNumber = (event, index) => {
        if (event.keyCode === 13) { // enter key
            if (event.target.value != "" && carrierId != "") {
                const data_r = {
                    userID: userData['id'],
                    car_number: event.target.value,
                    transport: carrierId
                };
                let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperCarrierDriverEndpoint;
                axios
                    .post(url, data_r, {
                        headers: {
                            Authorization: "Bearer " + useJwt.getToken(),
                        },
                    })
                    .then((response1) => {
                        let t_data = response1.data.data;
                        if (t_data.length > 0) {
                            setDriverData(t_data[0]);
                        } else {
                            setDriverData([]);
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.status && error.response.status === 401) {
                            useJwt.shipperLogout();
                            navigate("/login");
                        }
                    });
            } else {
                setDriverData([]);
                toast.error("運送会社を選択してください。", { autoClose: 3000 });
            }
            event.preventDefault();
        }
    };
    const handleAmountChange = (e) => {
        setAmount(e.target.value)
    }
    const handleCarrierChange = (e) => {
        var name = e.target.value;
        setCarrierName(e.target.value)
        if (name == "") {
            setCarrierId("")
            setDriverId("")
            setCarNumber("")
            setCarStandard("")
        }
    }
    const [itemName, setItemName] = useState("")
    const [itemId, setItemId] = useState("")
    const [itemCode, setItemCode] = useState("")
    const [itemACode, setItemACode] = useState("")
    const [itemUnit, setItemUnit] = useState("")
    const [shipperItemCode, setShipperItemCode] = useState("")
    const [quantity, setQuantity] = useState("")
    const [amount, setAmount] = useState("")
    const [rotCode, setRotCode] = useState("")
    const [rotName, setRotName] = useState("")
    const [other, setOther] = useState("")

    const saveData = (e) => {
        e.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        data['shipper_user_id'] = userData['id'];
        data['warehouse_id'] = props.warehouseId

        var rotDate = props.commandDate
        rotDate = rotDate.replaceAll("-", "")
        var customRot = "CRT" + rotDate;
        let itemData = [];

        if (itemCode == "") {
            toast.error("商品をお選びください。", { autoClose: 3000 });
            return;
        }

        if (amount == "") {
            toast.error("数量をご入力ください。", { autoClose: 3000 });
            return;
        }

        if (StartTime == "") {
            toast.error("時間をご入力ください。", { autoClose: 3000 });
            return;
        }

        let row = {}
        row['item_id'] = itemId
        row['item_code'] = itemCode;
        row['item_a_code'] = itemACode;
        row['item_name'] = itemName;
        row['item_unit'] = itemUnit;
        row['shipper_item_code'] = shipperItemCode;
        row['rot_code'] = rotCode ? rotCode : (inout == "1" ? customRot : '');
        row['rot_name'] = rotName;
        row['item_num'] = amount;
        row['inout_type'] = inout;
        row['warehouse_id'] = props.warehouseId;
        row['shipper_id'] = props.shipperId;
        row['shipper_code'] = props.shipperCode;
        row['transport_id'] = carrierId;
        row['carrier_name'] = carrierName;
        row['driver_id'] = driverId;
        row['driver_name'] = driverName;
        row['driver_phone'] = driverPhone;
        row['car_number'] = carNumber
        row['car_type'] = carStandard
        row['command_date'] = props.commandDate;
        row['start_date'] = inoutDate + ' ' + StartTime + ":00"
        row['other'] = other

        itemData[0] = row
        data['items'] = itemData;

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setInoutCommandEndpoint;
        // setAddLoaded(true);
        csLoading(true);

        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                // setAddLoaded(false);
                csLoading(false);

                if (response.status == 200) {
                    row['id'] = response.data.data.id
                    useJwt.setToken(response.data.token);
                    toast.success("登録致しました。", { autoClose: 3000 });
                    props.target(row, props.targetParam != null ? props.targetParam : '');
                    clearItem()
                }
                // csLoading(false);
            })
            .catch((error) => {
                csLoading(false);
                if (error.response && error.response.status && error.response.status === 401) {
                    useJwt.shipperLogout();
                    navigate("/login");
                }
            });
        // setOpen(false);
    }
    const [ItemTableData, setItemTableData] = useState([]);

    const handleChangeInoutDate = (date) => {
        setInoutDate(useJwt.formatDate(date));
    }
    const [StartTime, setStartTime] = useState("");
    const handleChangeStartTime = (event) => {
        setStartTime(event.target.value);
    };

    const csLoading = (flag) => {
        if (flag) {
            document.getElementById('csLoader').style.display = "flex";
        } else {
            document.getElementById('csLoader').style.display = "none";
        }
    }

    const handleChangeCarStandard = (e) => {
        setCarStandard(e.target.value)
    }

    const [historyModalShow, setHistoryModalShow] = useState(false)
    const [inoutDate, setInoutDate] = useState(useJwt.formatDate(new Date()))
    const style_td_w20p = [style.line_input];
    const style_input_bl_n = [gstyle.cs_input, style.job_time];

    return (
        <Modal title="入出庫指示追加" show={props.show} onClose={props.onClose} width={props.width}>
            <div className={gstyle.loader_container} id="csLoader">
                <ClipLoader color={'#fff'} size={60} />
            </div>

            <CarrierModal show={showModalCarrier} onClose={() => setShowModalCarrier(false)} width={'60%'}
                SearchValue={CarrierSearchValue} onChange={setCarrierSearchValue}
                SearchValue2={CarrierSearchValue2} onChange2={setCarrierSearchValue2}
                tableData={CarrierTableData} setTableData={setCarrierTableData}
                target={setCarrierData} />
            <DriverModal show={driverModalShow} onClose={() => setDriverModalShow(false)} width={'60%'}
                SearchValue={driverSearchValue} SearchValue2={driverSearchValue2} onChange={setDriverSearchValue2}
                driverTableData={driverTableData} setDriverTableData={setDriverTableData}
                target={setDriverData} />
            <HistoryModal show={historyModalShow} onClose={() => setHistoryModalShow(false)} width={'80%'}
                startDate={startDate} endDate={endDate} shipperCode={props.shipperCode} warehouseId={props.warehouseId} itemCode={itemCode} />

            <div className={gstyle.hyfiled}>
            </div>
            <div className={gstyle.hyfiled}>
                <div className={style.cs_tbl} style={{ minHeight: 610, marginBottom: 0, display: 'flex' }}>
                    <div style={{ width: 800, border: '1px solid #eeeeee', padding: 10, borderRadius: 5 }}>
                        <ThemeProvider theme={createTheme(theme, jaJP)}>
                            <MaterialReactTable
                                columns={columns}
                                height={590}
                                data={ItemTableData}
                                enableTopToolbar={true}
                                enableSelectAll={false}
                                enableGlobalFilter={true}
                                enableColumnFilters={false}
                                initialState={{
                                    showColumnFilters: true,
                                    showGlobalFilter: true,
                                }}
                                localization={MRT_Localization_JA}
                                muiTableBodyProps={{
                                    sx: {
                                        '& tr:nth-of-type(odd)': {
                                            backgroundColor: '#f5f5f5',
                                            border: '1px solid #e0e0e0',
                                        },
                                        'td': {
                                            padding: '0.4rem',
                                            border: '1px solid #e0e0e0'
                                        },
                                        height: '395px'
                                    },
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                        border: '1px solid #e0e0e0',
                                        textAlign: 'center'
                                    },
                                }}
                                muiTableHeadCellProps={{
                                    sx: {
                                        border: '1px solid #e0e0e0',
                                        '.Mui-TableHeadCell-Content': {
                                            display: 'flex',
                                            justifyContent: 'center',

                                        },
                                        backgroundColor: '#cfcfcf'
                                    }
                                }}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                        handleChangeData(row.original);
                                    },
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}
                                renderTopToolbarCustomActions={({ table }) => (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <div className={gstyle.cs_flex}>
                                                <select className={style_select_40.join(" ")} id="SearchFieldType"
                                                    value={SearchFieldType}
                                                    onChange={handleChangeSearchFieldType}>
                                                    <option value="0">名称</option>
                                                    <option value="1">名称(ﾌﾘｶﾞﾅ)</option>
                                                    <option value="2">荷主品番コード</option>
                                                </select>
                                                <input type={'text'} className={style_input_100.join(" ")}
                                                    value={searchValue} onKeyDown={handleKeyDown}
                                                    onChange={handleChangeSearchValue} />
                                            </div>
                                        </label>
                                        <Button onClick={searchData} loading={loaded} type={'primary'} style={{ marginLeft: 30, width: 120, height: 35 }}>
                                            {loaded ? '検索中' : '検索'}
                                        </Button>
                                    </div>
                                )}
                            />
                        </ThemeProvider>
                    </div>
                    <div style={{ border: '1px solid #eeeeee', borderRadius: 5, flex: 1, marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
                        <div style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
                            <Radio.Group value={inout} onChange={handleClickInout} style={{ display: "flex", justifyContent: "end" }} buttonStyle="solid">
                                <Radio.Button value="1">入庫</Radio.Button>
                                <Radio.Button value="2">出庫</Radio.Button>
                            </Radio.Group>
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <div style={{ flex: 1, display: 'flex' }}>
                                <div style={{ display: "flex", flexDirection: 'column', padding: "0px 10px", gap: 5, alignItems: 'end', minWidth: 100 }}>
                                    <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>名 称 : </p>
                                    <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>荷主品番コード : </p>
                                    <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>入 数 : </p>
                                    {
                                        inout == "1" ?
                                            <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>商品入庫時間 : </p>
                                            :
                                            <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>商品出庫時間 : </p>
                                    }
                                    {
                                        inout == "1" &&
                                        <>
                                            <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>運送会社名 : </p>
                                            <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>ドライバー :</p>
                                            <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}></p>
                                        </>
                                    }
                                    <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>数量 : </p>
                                    <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>ロットNo : </p>
                                    <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>ロット名 : </p>
                                    <p style={{ fontWeight: 600, display: 'flex', alignItems: 'center', minHeight: 32, fontSize: 15 }}>備考 : </p>
                                </div>
                                <div style={{ display: "flex", flex: 1, flexDirection: 'column', padding: "0px 10px", gap: 5 }}>
                                    <div style={{ minHeight: 32, maxHeight: 32, display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 15 }}>{itemName}</p>
                                    </div>
                                    <div style={{ minHeight: 32, maxHeight: 32, display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 15 }}>{shipperItemCode}</p>
                                    </div>
                                    <div style={{ minHeight: 32, maxHeight: 32, display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 15 }}>{quantity}</p>
                                    </div>
                                    <div style={{ minHeight: 32, maxHeight: 32, display: 'flex', alignItems: 'center' }}>
                                        <div style={{ display: 'flex' }}>
                                            <InputDatepicker value={inoutDate} onChange={handleChangeInoutDate} width={105} />
                                            <input type="time" id="start_time_input" className={style_input_bl_n.join(" ")} value={StartTime}
                                                onChange={(e) => handleChangeStartTime(e)} style={{ minWidth: 110, margin: 0, textAlign: 'center' }}
                                            // onKeyDown={(e) => onKeyDownStartTime(e)}
                                            />
                                        </div>
                                    </div>
                                    {
                                        inout == "1" &&
                                        <>
                                            <div style={{ minHeight: 32, display: 'flex', alignItems: 'center' }}>
                                                <div className={style_td_w20p.join(" ")}>
                                                    <input type={'text'} className={style.cs_tbl_input} onChange={(e) => handleCarrierChange(e)}
                                                        value={carrierName} placeholder='運送会社名' />
                                                    <div className={gstyle.icon_search_wrap}
                                                        onClick={(e) => loadCarrierModalData(e)}>
                                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ minHeight: 32, display: 'flex', alignItems: 'center' }}>
                                                <div className={style_td_w20p.join(" ")}>
                                                    <input type={'text'} className={style.cs_tbl_input} onChange={(e) => handleCarNumberChange(e)}
                                                        value={carNumber} placeholder='車番' onKeyDown={(e) => onKeyDownCarNumber(e)} />
                                                    <div className={gstyle.icon_search_wrap}
                                                        onClick={(e) => loadDriverModalData(e, carNumber)}>
                                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                    </div>
                                                </div>
                                                <div className={style_td_w20p.join(" ")}>
                                                    <input type={'text'} className={style.cs_tbl_input} onChange={handleDriverNameChange}
                                                        placeholder='ドライバー名' value={driverName} style={{marginLeft:5}}/>
                                                </div>
                                            </div>
                                            <div style={{ minHeight: 32, maxHeight: 32, display: 'flex', alignItems: 'center' }}>
                                                <div className={style_td_w20p.join(" ")}>
                                                    <input type={'text'} className={style.cs_tbl_input} onChange={handleDriverPhoneChange}
                                                        placeholder='携帯' value={driverPhone} />
                                                </div>
                                                <div className={style_td_w20p.join(" ")}>
                                                    <select className={style_select_40.join(" ")} id="SearchFieldType"
                                                        value={carStandard}
                                                        onChange={handleChangeCarStandard} style={{ marginLeft: 5, width: '100%' }}>
                                                        <option value="1">4t</option>
                                                        <option value="2">10t</option>
                                                        <option value="3">トレーラー</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div style={{ minHeight: 32, display: 'flex', alignItems: 'center' }}>
                                        <input type={'number'} className={style.cs_tbl_input} style={{ width: '45%' }} onChange={(e) => handleAmountChange(e)}
                                            value={amount} />
                                    </div>
                                    <div style={{ minHeight: 32, display: 'flex', alignItems: 'center' }}>
                                        <input type={'text'} className={style.cs_tbl_input} style={{ width: '45%' }} onChange={(e) => handleChangeRotCode(e)}
                                            value={rotCode} />
                                    </div>
                                    <div style={{ minHeight: 32, display: 'flex', alignItems: 'center' }}>
                                        <input type={'text'} className={style.cs_tbl_input} style={{ width: '45%' }} onChange={(e) => handleChangeRotName(e)}
                                            value={rotName} />
                                    </div>
                                    <div style={{ minHeight: 32, display: 'flex', alignItems: 'center' }}>
                                        <input type={'text'} className={style.cs_tbl_input} style={{ width: '100%' }} onChange={(e) => handleChangeOther(e)}
                                            value={other} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                                <Button onClick={saveData} loading={addLoaded} type={'primary'} style={{ marginLeft: 30, width: 120, height: 35 }}>
                                    指示登録
                                </Button>
                                <Button onClick={clearItem} type={'default'} style={{ marginLeft: 30, width: 120, height: 35 }} danger>
                                    キャンセル
                                </Button>
                                <Button onClick={inoutHistory} type={'default'} style={{ marginLeft: 30, width: 120, height: 35 }} >
                                    入出庫履歴
                                </Button>
                            </div>
                        </div>
                        <div style={{ flex: 1, borderTop: '1px solid #eeeeee' }}>
                            <h3 style={{ padding: 10 }}>在庫</h3>
                            <div style={{ overflowY: 'auto', maxHeight: 150 }}>
                                {
                                    stockData?.map((val, index) => (
                                        <div style={{ display: 'flex', fontSize: 15 }}>
                                            <span>{val.warehouse_name}:</span><span>{val.amount}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default InoutModal;




