/* eslint-disable eqeqeq */
import style from './Rot.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import InputDatepicker from '../../../components/common/input/InputDatepicker';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select} from 'antd';

const Rot = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [standardData, setStandardData] = useState([]);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>6) {
            str = str.substring(0, 6);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setShipperCode(str);
    };
    const [ShipperName, setShipperName] = useState("");

    const onKeyDownShipperCode = (event) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value);
            document.getElementById('item_code').focus();
            event.preventDefault();
        }
    };

    const getShipperData = (code) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                shipper_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperName(result['shipper_name']);
                    }else{
                        setShipperName("");
                        setItemCode("");
                        setItemACode("");
                        setItemName("");
                        gridMainTable([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperName("");
        }
    }
    const setShipperData = (data) => {
        if(data['shipper_code']) {
            setShipperCode(data['shipper_code']);
            setShipperName(data['shipper_name']);
        }else{
            setShipperName("");
        }
    }

    const [ItemCode, setItemCode] = useState("");
    const handleChangeItemCode = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setItemCode(str);
    };
    const [ItemACode, setItemACode] = useState("");
    const handleChangeItemACode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>3) {
            str = str.substring(0, 3);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setItemACode(str);
    };
    const onKeyDownItemCode = (event, index) => {
        if(event.keyCode === 13) { // enter key
            if(index == 1) {
                getItemData(ShipperCode,event.target.value,ItemACode);
                document.getElementById('item_a_code').focus();
            }else{
                getItemData(ShipperCode,ItemCode,event.target.value);
                document.getElementById('newRecord').focus();
            }
            event.preventDefault();
        }
    };
    const [ItemName, setItemName] = useState("");

    const getItemData = (shipper_code, item_code, item_a_code) => {
        if(item_code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = item_code;
            if(shipper_code != "") {
                data['shipper_code'] = shipper_code;
            }
            if(item_a_code != "") {
                data['item_a_code'] = item_a_code;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result);
                    }else{
                        setItemData([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([]);
        }
    }
    const setItemData = (data) => {
        if(data['item_code']) {
            setItemCode(data['item_code']);
            setItemACode(data['item_a_code']);
            setItemName(data['item_name']);
            let filterData = {};
            filterData['shipper_code'] = ShipperCode;
            filterData['item_code'] = data['item_code'];
            filterData['item_a_code'] = data['item_a_code'];
            getRotMainData(filterData);
        }else{
            setItemName("");
            gridMainTable([]);
        }
    }

    const setRotBasicData = (result) => {
        if(result['shipper_code']) {
            setShipperCode(result['shipper_code']);
            getShipperData(result['shipper_code']);
            setItemCode(result['item_code']);
            setItemACode(result['item_a_code']);
            getItemData(result['shipper_code'], result['item_code'], result['item_a_code']);
        }else{
            setShipperName("");
            setItemName("");
        }
    }

    const getRotMainData = (result) => {
        let data = {};
        data['userID'] = userData['id'];
        data['shipper_code'] = result['shipper_code'];
        data['item_code'] = result['item_code'];
        data['item_a_code'] = result['item_a_code'];
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstRotEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        }).then((response) => {
            if(response.status == 200) {
                if(response.data.data.length>0) {
                    let result = response.data.data;
                    gridMainTable(result);
                   
                    var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
                    for (let i = 0; i < checkboxes.length; i++) {
                        checkboxes[i].checked = false;
                    }
                }else{
                    gridMainTable([]);
                }
            }
        })
        .catch((error) => {
        });
    }

    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        setMainTableData(result);
    }

    const formatDate = (date) => {
        let year = date.getFullYear();
        let month = '' + (date.getMonth()*1 + 1);
        let day = '' + date.getDate();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return  year + '-' + month + '-' + day;
    }

    const handlChangeRotData = (event, control_type, col , index) => {
        let value = '';
        if(control_type==2){
            value = formatDate(event);
        }else{
            value = event.target.value;
        }

        if(col == 'rot_code'){
            if(value.length>15) {
                value = value.substring(0, 15);
            }
        }else if(col == 'rot_name'){
            if(value.length>30) {
                value = value.substring(0, 30);
            }
        }else if(col == 'rot_a_name'){
            if(value.length>30) {
                value = value.substring(0, 30);
            }
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                if(control_type < 3){
                    row[col] = value;
                }else{
                    if(control_type == 3)   row[col] = true;
                    else    row[col] = false;
                }
            }
            data[i] = row;
        }
        console.log(data);
        gridMainTable(data);
    }

    const allCheckData = (event) => {
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = event.target.checked;
        }
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_master_flag_8'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }

            setLoaded(true);
            let data = {};
            data['userID'] = userData['id'];

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstRotStandardEndpoint;
            csloading(true);
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                csloading(false);

                if(response.status == 200) {
                    console.log(response.data.data);
                    let resData = response.data.data;
                    let tempArray = [];
                    for (let index = 0; index < resData.length; index++) {
                        let temp = {value:resData[index]['id'], label:resData[index]['rot_unit']};
                        console.log(temp);
                        tempArray.push(temp);                        
                    }
                    setStandardData(tempArray);
                }
            })
            .catch((error) => {
                csloading(false);
            });
        }
	});

    const newRecord = (event) => {
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            data[i] = row;
        }
        let row = {};
        row['sys_rot_id'] = 0;
        row['shipper_code'] = ShipperCode;
        row['item_code'] = ItemCode;
        row['item_a_code'] = ItemACode;
        row['rot_code'] = "";
        row['rot_show'] = false;
        row['rot_name'] = "";
        row['rot_a_name'] = "";
        row['first_arrival_date'] = formatDate(new Date());
        row['item_deadline_date'] = formatDate(new Date());
        data[i] = row; 
        gridMainTable(data);
        
        setTimeout(()=>{
            let endRow = mainTableData.length;
            document.getElementById('rot_code_'+endRow).focus();
        },10)
    }

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];

        let validate_flg = true;
        let vmsg = "";

        let rotData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            if(mainTableData[i]['sys_rot_id'] != 0) {
                row['sys_rot_id'] = mainTableData[i]['sys_rot_id'];
            }
            if(mainTableData[i]['shipper_code'] != "") {
                row['shipper_code'] = mainTableData[i]['shipper_code'];
            }else{
                vmsg = "荷主コードを入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['item_code'] != "") {
                row['item_code'] = mainTableData[i]['item_code'];
            }else{
                vmsg = "品番コードを入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['item_a_code'] != "") {
                row['item_a_code'] = mainTableData[i]['item_a_code'];
            }else{
                vmsg = "品番･補助コードを入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['rot_code'] != "") {
                row['rot_code'] = mainTableData[i]['rot_code'];
            }else{
                vmsg = "コードを入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['rot_name'] != "") {
                row['rot_name'] = mainTableData[i]['rot_name'];
            }else{
                vmsg = "名称を入力してください。";       
                validate_flg = false;
                break;
            }
            row['rot_a_name'] = mainTableData[i]['rot_a_name'];
            if(mainTableData[i]['first_arrival_date'] != "") {
                row['first_arrival_date'] = mainTableData[i]['first_arrival_date'];
            }else{
                vmsg = "初回入庫日を入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['item_deadline_date'] != "") {
                row['item_deadline_date'] = mainTableData[i]['item_deadline_date'];
            }else{
                vmsg = "商品期限を入力してください。";       
                validate_flg = false;
                break;
            }
            row['standard'] = mainTableData[i]['standard'];

            console.log(row);
            rotData[i] = row;
        }
        
        if(validate_flg){
            data['data'] = rotData;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setMstRotEndpoint;
            csloading(true);
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data == 1) {
                        toast.success("登録致しました。", {autoClose:3000});
                        let filterData = {};
                        filterData['shipper_code'] = ShipperCode;
                        filterData['item_code'] = ItemCode;
                        filterData['item_a_code'] = ItemACode;
                        getRotMainData(filterData);
                    }else if(response.data.data == 2){
                        toast.error("すでに登録済みです。", {autoClose:3000});
                    }else{
                        toast.error("登録に失敗しました。", {autoClose:3000});
                    }
                        
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
            });
        }else{
            toast.error(vmsg, {autoClose:3000});  
        }
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        setShowModalDel(true);
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        let delIds = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(checkboxes[i].checked){
                let sys_rot_id = document.getElementById('sys_rot_id_'+i).value;
                if(sys_rot_id>0){
                    delIds.push(sys_rot_id);
                }
            }
        }
        
        if(delIds.length>0) {
            let data = {};
            data['userID'] = userData['id'];
            data['delIds'] = delIds;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.delMstRotEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    let filterData = {};
                    filterData['shipper_code'] = ShipperCode;
                    filterData['item_code'] = ItemCode;
                    filterData['item_a_code'] = ItemACode;
                    getRotMainData(filterData);
                    
                }
            })
            .catch((error) => {
            });
        }
    }
    // ========================== starts code for search ===============================
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event) => {
        event.preventDefault();

        setShipperSearchValue(ShipperCode);
        setShipperSearchValue2("");

        if(ShipperCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event) => {
        event.preventDefault();
        setItemSearchValue(ItemCode);
        setItemSearchValue2(ItemACode);
        setItemSearchValue3(ShipperCode);
        if(ItemCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = ItemCode;
            if(ItemACode != "") {
                data['item_a_code'] = ItemACode;
            }
            if(ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    
    const handleChangeRotStandard = (event, index) => {
        let value = event;

        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row['standard'] = value;
            }
            data[i] = row;
        }

        setMainTableData(data);
    }

    const tableKeyDown = ( e, id ) =>{
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    const tableEndKeyDown = ( e, id, index ) =>{
        let dataLength = mainTableData.length;

        if(dataLength <= index + 1)  return;
        
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_1 = [style.cs_th, gstyle.cs_col_1];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_1 = [style.cs_td, gstyle.cs_col_1];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} 
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} />
            <Modal2 title="ロット削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>ロットマスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    { /* 荷主コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e)}
                                        value={ShipperCode} onChange={handleChangeShipperCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="ShipperName"
                                        value={ShipperName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 品番 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")} id='item_code'
                                        onKeyDown={(e) => onKeyDownItemCode(e,1)}
                                        value={ItemCode} onChange={handleChangeItemCode} />
                                        <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,2)} id='item_a_code'
                                        value={ItemACode} onChange={handleChangeItemACode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="ItemName"
                                        value={ItemName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")}>
                                <button className={gstyle.btn_new} id='newRecord' onClick={newRecord} >追加</button>
                            </div>
                        </div>
                    </div>
                    <div className={gstyle.hyfiled}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_col_2.join(" ")}>コード</div>
                                    <div className={style_th_col_1.join(" ")}>規格</div>
                                    <div className={style_th_col_2.join(" ")}>名称</div>
                                    <div className={style_th_col_2.join(" ")}>略称</div>
                                    <div className={style.cs_th}>初回入庫日</div>
                                    <div className={style.cs_th}>商品期限</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                            id={'chk_'+index} onChange={handleChangeChk} />
                                            <input type="hidden" id={'sys_rot_id_'+index} value={data['sys_rot_id']} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                id={'rot_code_'+index}
                                                value={data['rot_code']} 
                                                onKeyDown={(e)=>tableKeyDown(e, "rot_unit_"+index)}
                                                onChange={(e) => handlChangeRotData(e,1,'rot_code',index)} />
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                        <Select
                                            open={data['rot_show']}
                                            value={data['standard'] ? data['standard'] : ''}
                                            optionFilterProp="children"
                                            onChange={(e) =>handleChangeRotStandard(e, index)}
                                            onKeyDown={(e)=>tableKeyDown(e, "rot_name_"+index)}
                                            onFocus={(e) => handlChangeRotData(e,3,'rot_show',index)}
                                            onBlur={(e) =>handlChangeRotData(e,4,'rot_show',index)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{width:'100%'}}
                                            id={"rot_unit_"+index}
                                            options={standardData}
                                        />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                id={'rot_name_'+index}
                                                onKeyDown={(e)=>tableKeyDown(e, "rot_a_name_"+index)}
                                                value={data['rot_name']} 
                                                onChange={(e) => handlChangeRotData(e,1,'rot_name',index)} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                id={'rot_a_name_'+index}
                                                value={data['rot_a_name']?data['rot_a_name']:''} 
                                                onKeyDown={(e)=>tableKeyDown(e, "arrival_date"+index)}
                                                onChange={(e) => handlChangeRotData(e,1,'rot_a_name',index)} />
                                        </div>
                                        <div className={style.cs_td}>
                                            <InputDatepicker 
                                                value={data['first_arrival_date']}
                                                onChange={(e) => handlChangeRotData(e,2,'first_arrival_date',index)}
                                                id={'arrival_date' + index} focusId={'deadline_date' + index} />
                                        </div>
                                        <div className={style.cs_td}>
                                            <InputDatepicker 
                                                value={data['item_deadline_date']}
                                                onChange={(e) => handlChangeRotData(e,2,'item_deadline_date',index)} 
                                                id={'deadline_date' + index} focusId={'rot_code_' + (index + 1)}
                                                />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} >削除</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Rot;