import style from './Heading.module.css'

const Heading = ({color, title}) => {
	return (
		<>
			<div className={style.heading} style={{borderBottom:`1px solid ${color}`}}>
				<div className={style.heading_color} style={{backgroundColor: color}}></div>
				<div className={style.heading_title}>{title}</div>
			</div>
    	</>
	);
};

export default Heading;