/* eslint-disable eqeqeq */
import style from "./CsvImport.module.css";
import gStyle from "../../css/GeneralStyle.module.css";
import React, { useState, useEffect } from "react";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";

import CsvImportDataCheckComponent from "../../components/common/CsvImportDataCheckInput.tsx";

const CategoryImport = () => {

  const [importErrorData, setImportErrorData] = useState([]);
  const [loadFlag, setLoadFlag] = useState(false);

  let categoryCsvHeader = [
    "ﾏｽﾀ区分名",
    "種別名",
    "ｺｰﾄﾞ",
    "分類名称"
  ];

  useEffect(() => {
    if (importErrorData[0] == "success" && loadFlag == false) {
      toast.success("インポート致しました。", { autoClose: 3000 });
      setImportErrorData([])
    }
    csLoading(loadFlag);
  }, [loadFlag, importErrorData]);

  const csLoading = (flag) => {
    if (flag) {
      document.getElementById("csLoader").style.display = "flex";
    } else {
      document.getElementById("csLoader").style.display = "none";
    }
  };

  return (
    <div className={gStyle.display}>
      <div className={gStyle.loader_container} id="csLoader">
        <ClipLoader color={"#fff"} size={60} />
      </div>
      <ToastContainer />
      <div className={gStyle.border}>
        <p className={gStyle.text}>分類CSVインポート</p>
      </div>
      <div className={gStyle.filed}>
        <div className={style.cs_card}>
          <CsvImportDataCheckComponent
            url={jwtDefaultConfig.importCategoryCsv}
            setErrorData={setImportErrorData}
            setLoadFlag={setLoadFlag}
            csvHeader={categoryCsvHeader}
            index={1}
          />
          <div className={style.error_tag}>
            {importErrorData?.map((value, index) => (
              value != 'success' &&
              <p className={style.error_content} key={index}>{value}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryImport;
