/* eslint-disable eqeqeq */
import style from './StockMoveSlip.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import RotModal from '../../../components/common/modal/RotModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import SummaryModal from '../../../components/common/modal/SummaryModal';
import Modal2 from '../../../components/common/modal/Modal2';
import ArrivalTableModal from '../../../components/common/modal/TableModal';
import LeavingTableModal from '../../../components/common/modal/TableModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import InputDatepickerTable from '../../../components/common/input/InputDatepickerTable';
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Popover, Select} from 'antd';

const StockMoveSlip = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [showModalDel2, setShowModalDel2] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        console.log(ArrivalWarehouseType);
        if(SlipDate == "") {
            toast.error("年月日を入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }
        if(SlipNo == "") {
            toast.error("伝票Noを入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }
        if(ShipperCode1 == "") {
            toast.error("荷主1を入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }
        if(MoveType == 3) {
            if(ShipperCode2 == "") {
                toast.error("荷主2を入力してください。", {autoClose:3000}); 
                setOpen(false);
                return;
            }
            if(LeavingWarehouseCode == "") {
                toast.error("出庫倉庫を入力してください。", {autoClose:3000}); 
                setOpen(false);
                return;
            }
        }else{
            if(MoveType == 0) {
                if(LeavingWarehouseCode == "") {
                    toast.error("出庫倉庫を入力してください。", {autoClose:3000}); 
                    setOpen(false);
                    return;
                }
                if(ArrivalWarehouseCode == "") {
                    toast.error("入庫倉庫を入力してください。", {autoClose:3000}); 
                    setOpen(false);
                    return;
                }
            }else if(MoveType == 1) {
                if(LeavingWarehouseCode == "") {
                    toast.error("出庫倉庫を入力してください。", {autoClose:3000}); 
                    setOpen(false);
                    return;
                }
            }else{
                if(LeavingWarehouseCode == "" && LeavingWarehouseCode==""){
                    toast.error("倉庫を入力してください。", {autoClose:3000}); 
                    setOpen(false);
                    return;
                }
            }
        }

        // item data --------------------------------------
        let validate_flg = true;
        let vmsg = "";
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            if(mainTableData[i]['leaving_table_code'] == "" && LeavingWarehouseType != 0) {
                vmsg = "出庫棚コードを入力してください。";
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['arrival_table_code'] == "" && ArrivalWarehouseType != 0) {
                vmsg = "入庫棚コードを入力してください。";
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['item_code'] == "") {
                vmsg = "品番コードを入力してください。";
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['item_num'] == "") {
                vmsg = "数量を入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['item_deadline_date'] == "") {
                vmsg = "商品期限を入力してください。";       
                validate_flg = false;
                break;
            }
        }

        if(mainTableData.length == 0){
            vmsg = "商品を入力してください。"; 
            validate_flg = false;
        }

        if(!validate_flg){
            toast.error(vmsg, {autoClose:3000});
            return;
        }

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // sys_stock_move_id
    const [SysStockMoveId, setSysStockMoveId] = useState(0);
    // slip date
    const [SlipDate, setSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeSlipDate = (date) => {
        setSlipDate(useJwt.formatDate(date));
    };
    // slip no -------------------------------------------------
    const [SlipNo, setSlipNo] = useState("");
    const [oldSlipNo, setOldSlipNo] = useState("");
    const handleChangeSlipNo = (event) => {
        setSlipNo(useJwt.validateNum(event.target.value,6));
    };
    const onKeyDownSlipNo = (event) => {
        if(event.keyCode === 13){
            let data = {};
            data['slip_date'] = SlipDate;
            data['slip_no'] = event.target.value;
            getSlipData(data);
            document.getElementById('question_number').focus();
            event.preventDefault();
        }
    }

    const onKeyDownQuestion = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type1').focus();
            event.preventDefault();
        }
    }

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type2').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('shipper_code').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onBlurSlipNo = (event) => {
        if(event.target.value != "" && SlipNo != oldSlipNo){
            let data = {};
            data['slip_date'] = SlipDate;
            data['slip_no'] = event.target.value;
            getSlipData(data);
            setOldSlipNo(event.target.value);
        }
        event.preventDefault();
    }
    // question no ---------------------------------------------
    const [QuestionNo, setQuestionNo] = useState("");
    const handleChangeQuestionNo = (event) => {
        setQuestionNo(useJwt.validateNum(event.target.value,8));
    };
    // move type -----------------------------------------------
    const [MoveType, setMoveType] = useState(0);
    const handleChangeMoveType = (event) => {
        console.log(event);
        setMoveType(event);
        if(event<3) {
            setShipperCode2("");
            setShipperACode2("");
            setShipperName2("");
            if(event==1){
                setArrivalWarehouseCode("");
                setArrivalWarehouseName("");
            }
        }else{
            setArrivalWarehouseCode("");
            setArrivalWarehouseName("");
            setLeavingWarehouseCode("");
            setLeavingWarehouseName("");
        }

        if(event==2){
            setLeavingWarehouseCode("");
            setLeavingWarehouseName("");
            //getLocationData([], 'leaving');
            setArrivalWarehouseCode("");
            setArrivalWarehouseName("");
            //getLocationData([], 'arrival');
        }
        // document.getElementById('type2').focus();
    };
    // invoice type -----------------------------------------------
    const [InvoiceType, setInvoiceType] = useState(0);
    const handleChangeInvoiceType = (event) => {
        setInvoiceType(event);
    };
    // warehouse --------------------------------------------------
    const [ArrivalWarehouseCode, setArrivalWarehouseCode] = useState("");
    const handleChangeArrivalWarehouseCode = (event) => {
        setArrivalWarehouseCode(useJwt.validateNum(event.target.value,2));
    }
    const [ArrivalWarehouseType, setArrivalWarehouseType] = useState(0);
    const [ArrivalWarehouseName, setArrivalWarehouseName] = useState("");

    const [LeavingWarehouseCode, setLeavingWarehouseCode] = useState("");
    const handleChangeLeavingWarehouseCode = (event) => {
        setLeavingWarehouseCode(useJwt.validateNum(event.target.value,2));
    }
    const [LeavingWarehouseType, setLeavingWarehouseType] = useState(0);
    const [LeavingWarehouseName, setLeavingWarehouseName] = useState("");

    const onKeyDownWarehouseCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value,param);
            if(param == 2 && MoveType != 1)  document.getElementById('warehouse_other_code').focus();
            else    document.getElementById('addRow').focus();
            event.preventDefault();
        }
    };
    const getWarehouseData = (code,param) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        console.log(result);
                        if(param == 1) {
                            setArrivalWarehouseCode(result['warehouse_code']);
                            setArrivalWarehouseName(result['warehouse_name']);
                            setArrivalWarehouseType(result['warehouse_type']);
                            // getLocationData(result, 'arrival');
                            if(MoveType==2){
                                setLeavingWarehouseCode("");
                                setLeavingWarehouseName("");
                                setLeavingWarehouseType(0);
                                // getLocationData([], 'leaving');
                            }
                        }else{
                            setLeavingWarehouseCode(result['warehouse_code']);
                            setLeavingWarehouseName(result['warehouse_name']);
                            setLeavingWarehouseType(result['warehouse_type']);
                            // getLocationData(result, 'leaving');
                            if(MoveType==2){
                                setArrivalWarehouseCode("");
                                setArrivalWarehouseName("");
                                setArrivalWarehouseType(0);
                                // getLocationData([], 'arrival');
                            }
                        }
                    }else{
                        if(param == 1) {
                            setArrivalWarehouseCode("");
                            setArrivalWarehouseName("");
                            setArrivalWarehouseType(0);
                            // getLocationData([], 'arrival');
                        }else{
                            setLeavingWarehouseCode("");
                            setLeavingWarehouseName("");
                            setLeavingWarehouseType(0);
                            // getLocationData([], 'leaving');
                        }
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            if(param == 1) {
                setArrivalWarehouseCode("");
                setArrivalWarehouseName("");
                setArrivalWarehouseType(0);
                // getLocationData([], 'arrival');
            }else{
                setLeavingWarehouseCode("");
                setLeavingWarehouseName("");
                setLeavingWarehouseType(0);
                // getLocationData([], 'leaving');
            }
        }
        if(MoveType==2){
            if(param == 1) {
                setLeavingWarehouseCode("");
                setLeavingWarehouseName("");
                setLeavingWarehouseType(0);
                // getLocationData([], 'leaving');
            }else{
                setArrivalWarehouseCode("");
                setArrivalWarehouseName("");
                setArrivalWarehouseType(0);
                // getLocationData([], 'arrival');
            }
        }
    }
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");

    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");

    const onKeyDownShipperCode = (event,param,shipper_type) => {
        if(event.keyCode === 13) { // enter key
            if(shipper_type == 'leaving'){
                if(MoveType < 3){
                    document.getElementById('warehouse_code').focus();
                }else   document.getElementById('shipper_other_code').focus();
            }else{
                document.getElementById('warehouse_code').focus();
            }
                if(param == 1) {
                getShipperData(event.target.value,ShipperACode1,shipper_type);
            }else{
                getShipperData(ShipperCode1,event.target.value,shipper_type);
            }
            event.preventDefault();
        }
    };
    const setShipperData = (result,shipper_type) => {
        if(shipper_type == 'leaving') {
            if(result['shipper_code'] != null) {
                setShipperCode1(result['shipper_code']);
                setShipperACode1(result['shipper_a_code']);
                setShipperName1(result['shipper_name']);
            }else{
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }
            gridMainTable([]);
        }else{
            if(result['shipper_code'] != null && MoveType>2) {
                setShipperCode2(result['shipper_code']);
                setShipperACode2(result['shipper_a_code']);
                setShipperName2(result['shipper_name']);
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    const getShipperData = (code, acode,shipper_type) => {
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }else{
                data['shipper_a_code'] = "000";
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,shipper_type);
                    }else{
                        setShipperData([],shipper_type);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],shipper_type);
        }
    }
    // summary ----------------------------------------------------
    const [SummaryCode, setSummaryCode] = useState("");
    const handleChangeSummaryCode = (event) => {
        setSummaryCode(useJwt.validateNum(event.target.value,4));
    };
    const [SummaryName, setSummaryName] = useState("");
    const handleChangeSummaryName = (event) => {
        setSummaryName(useJwt.validateString(event.target.value,20));
    };
    const onKeyDownSummaryCode = (event) => {
        if(event.keyCode === 13) { // enter key
            getSummaryData(event.target.value);
            event.preventDefault();
        }
    };
    const getSummaryData = (code) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                summary_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstSummaryEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setSummaryData(t_data[0]);
                }else{
                    setSummaryData([]);
                }
            })
            .catch((error) => {
            });
        }else{
            setSummaryData([]);
        }
    }
    const setSummaryData = (result) => {
        if(result['sys_summary_id']) {
            setSummaryCode(result['summary_code']);
            setSummaryName(result['summary_name']);
        }else{
            setSummaryName("");
        }
    }
    // ====================================== table ====================================
    const [mainTableData, setMainTableData] = useState([]);
    const [TotalNum, setTotalNum] = useState("0.000");
    const [TotalWeight, setTotalWeight] = useState("0.000");
    const [TotalVolume, setTotalVolume] = useState("0.000");
    //const [LocationData1, setLocationData1] = useState([]);
    //const [LocationData2, setLocationData2] = useState([]);

    const getSlipData = (filter) => {
        let data = {};
        let validateflg = true;
        data['userID'] = userData['id'];
        if(filter['slip_date'] != null && filter['slip_date'] != "") {
            data['slip_date'] = filter['slip_date'];
        }else{
            validateflg = false;
        }
        if(filter['slip_no'] != null && filter['slip_no'] != "") {
            data['slip_no'] = filter['slip_no'];
        }else{
            validateflg = false;
        }
        if(validateflg) {
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.searchStockMoveSlipEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                // console.log(t_data);
                if(t_data.length > 0) {
                    setSysStockMoveId(t_data[0]['sys_stock_move_id']);
                    setMoveType(t_data[0]['move_type'])
                    setInvoiceType(t_data[0]['invoice_type']);
                    if(t_data[0]['arrival_warehouse_code']!=null){
                        setArrivalWarehouseCode(t_data[0]['arrival_warehouse_code']);
                    }else{
                        setArrivalWarehouseCode("");
                    }
                    if(t_data[0]['arrival_warehouse_name']!=null){
                        setArrivalWarehouseName(t_data[0]['arrival_warehouse_name']);
                    }else{
                        setArrivalWarehouseName("");
                    }
                    
                    setLeavingWarehouseCode(t_data[0]['leaving_warehouse_code']);
                    setLeavingWarehouseName(t_data[0]['leaving_warehouse_name']);
                    setShipperCode1(t_data[0]['leaving_shipper_code']);
                    setShipperACode1(t_data[0]['leaving_shipper_a_code']);
                    setShipperName1(t_data[0]['leaving_shipper_name']);
                    if(t_data[0]['arrival_shipper_code']!=null){
                        setShipperCode2(t_data[0]['arrival_shipper_code']);
                    }else{
                        setShipperCode2("");
                    }
                    if(t_data[0]['arrival_shipper_a_code']!=null){
                        setShipperACode2(t_data[0]['arrival_shipper_a_code']);
                    }else{
                        setShipperACode2("");
                    }
                    if(t_data[0]['arrival_shipper_name']!=null){
                        setShipperName2(t_data[0]['arrival_shipper_name']);
                    }else{
                        setShipperName2("");
                    }

                    gridMainTable(t_data);
                }else{
                    setSysStockMoveId(0);
                    setMoveType(0)
                    setInvoiceType(0);
                    setArrivalWarehouseCode("");
                    setArrivalWarehouseName("");
                    setLeavingWarehouseCode("");
                    setLeavingWarehouseName("");
                    setShipperCode1("");
                    setShipperACode1("");
                    setShipperName1("");
                    setShipperCode2("");
                    setShipperACode2("");
                    setShipperName2("");

                    gridMainTable([]);
                }
            })
            .catch((error) => {
            });
        }else{
            gridMainTable([]);
        }
    }

    const gridMainTable = (result) => {
        let data = [];
        let total_num = 0;
        let total_weight = 0;
        let total_volume = 0;
        for(var i=0; i<result.length; i++) {
            let row = {};
            row = result[i];
            total_num += 1*row['item_num'];
            total_weight += (1*row['item_num']*row['unit_weight']);
            total_volume += (1*row['item_num']*row['unit_volume']);
            data[i] = row;
        }
        setTotalNum(total_num.toFixed(3));
        setTotalWeight(total_weight.toFixed(3));
        setTotalVolume(total_volume.toFixed(3));
        setMainTableData(data);
    }
    const handlChangeItemData = (event, control_type, col , index) => {
        let value = '';
        if(control_type==2){
            value = useJwt.formatDate(event);
        }else{
            value = event.target.value;
        }

        // if(col == 'item_num'){
        //     var weight = $('.arrival_table_weight').eq(index).val();
        //     var rotWeight = $('.rot_weight').eq(index).val();

        //     if(parseFloat(weight) + parseFloat(rotWeight * value) > 1){
        //         var i = 1;
        //         while(parseFloat(weight) + parseFloat(rotWeight * i) <= 1){
        //             i++;
        //         }
        //         toast.error("数量を超えました。設定した棚に" + (i - 1) +"個以上入庫できません。", {autoClose:3000});
        //         $('.item_num').eq(index).val("");
        //         return;
        //     }
        // }

        if(col == 'item_num'){
            var stock_num = $('.stock_num').eq(index).val();

            if(parseFloat(stock_num) < value){
                var i = 1;
                toast.error("在庫数量を超えています", {autoClose:3000});
                value = "";
            }
        }

        if(col == 'item_code' || col == 'rot_code'){
            value = useJwt.validateString(value,15);
        }else if(col == 'item_a_code'){
            value = useJwt.validateNum(value,3);
        }else if(col == 'shipper_item_code'){
            value = useJwt.validateString(value,15);
        }else if(col == 'warehouse_code'){
            value = useJwt.validateNum(value,2);
        }else if(col == 'leaving_table_code'){
            value = useJwt.validateString(value,8);
        }else if(col == 'arrival_table_code'){
            value = useJwt.validateString(value,8);
        }else if(col == 'item_unit') {
            value = useJwt.validateString(value,4);
        }else if(col == 'item_num' || col == 'case_num' || col == 'bara_num') {
            value = useJwt.validateDecimal(value,6,3);
        }else if(col == 'remark') {
            value = useJwt.validateString(value,10);
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row[col] = value;
                if(col == 'item_code' || col == 'item_a_code') {
                    row['shipper_item_code'] = "";
                    row['rot_code'] = "";
                    row['case_num'] = "";
                    row['bara_num'] = "";
                }
                // if(col == 'item_code' || col == 'item_a_code' || col == 'rot_code'){
                //     getItemRemainNum(i, SlipDate, ShipperCode1, row['item_code']
                //     , row['item_a_code'], row['rot_code'], LeavingWarehouseCode);
                // }
            }
            data[i] = row;
        }
        gridMainTable(data);
    }
    const allCheckData = (event) => {
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', event.target.checked);
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };
    const newRecord = (event) => {

        if(MoveType == 2){
            if(ArrivalWarehouseCode != "" && LeavingWarehouseCode != ""){
                toast.error("移動区分が調整の場合は「出庫倉庫」か「入庫倉庫」どちらか一方を指定する必要があります。", {autoClose:3000});
                return;
            }
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            data[i] = row;
        }
        let row = {};
        row['sys_stock_move_item_id'] = 0;
        row['item_code'] = "";
        row['item_a_code'] = "";
        row['shipper_item_code'] = "";
        row['item_name'] = "";
        row['rot_code'] = "";
        row['rot_name'] = "";
        row['leaving_table_code'] = "";
        row['arrival_table_code'] = "";
        row['weight'] = 0;
        row['item_unit'] = 0;
        row['unit_weight'] = 0;
        row['unit_volume'] = 0;
        row['case_num'] = 0;
        row['bara_num'] = 0;
        row['item_num'] = 0;
        row['stock_num'] = 0;
        row['item_deadline_date'] = useJwt.formatDate(new Date());
        row['remark'] = "";
        // row['remain_qty'] = 0;
        // row['disable_flg'] = true;

        data[i] = row; 
        gridMainTable(data);

        setTimeout(()=>{
            let endRow = mainTableData.length;
            document.getElementById('item_code'+endRow).focus();
        },10)

    }
    const deleteItem = (event) => {
        setShowModalDel2(false);
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked){
                remainIndexs.push(i);
            }
        }
        
        let remainData = [];
        for(var i=0; i<mainTableData.length; i++) {
            if(mainTableData[i]['sys_stock_move_item_id']>0) {
                let row = {};
                row = mainTableData[i];
                remainData.push(row);
            }else{
                for(var j=0; j<remainIndexs.length; j++){
                    if(i==remainIndexs[j]){
                        let row = {};
                        row = mainTableData[i];
                        remainData.push(row);
                    }
                }
            }
        }
        gridMainTable(remainData);
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', false);
    }
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_daily_flag_3'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            document.getElementById('slip_number').focus();
        }
	});

    const getItemRemainNum = (index,date, shipper_code, item_code, item_a_code, rot_code, warehouse_code) => {
        let data = {};
        data['userID'] = userData['id'];
        data['end_slip_date'] = date;
        if(shipper_code != ""){
            data['shipper_code'] = shipper_code;
        }
        if(ShipperACode1 != ""){
            data['shipper_a_code'] = ShipperACode1;
        }
        if(item_code != ""){
            data['item_code'] = item_code;
        }
        if(item_a_code != ""){
            data['item_a_code'] = item_a_code;
        }
        if(rot_code != ""){
            data['rot_code'] = rot_code;
        }
        if(warehouse_code != ""){
            data['start_warehouse_code'] = warehouse_code;
            data['end_warehouse_code'] = warehouse_code;
        }

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockHistoryByItemEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            let item_remain_num = 0;
            if(result.length>0) {
                let total_in_num = 0;
                let total_out_num = 0;
                for(var i=0; i<result.length; i++) {
                    if(result[i]['arrival_num'] != "") {
                        total_in_num += (1*result[i]['arrival_num']);
                    }else if(result[i]['leaving_num'] != "") {
                        total_out_num += (1*result[i]['leaving_num']);
                    }
                }
                item_remain_num = total_in_num-total_out_num;
            }
            let data1 = [];
            for(var j=0; j<mainTableData.length; j++) {
                let row = {};
                row = mainTableData[j];
                if(j == index){
                    row['remain_qty'] = item_remain_num.toFixed(3);
                    if(item_remain_num*1>0){
                        row['disable_flg'] = false;
                    }else{
                        if(MoveType==2 && ArrivalWarehouseCode != ""){
                            row['disable_flg'] = false;
                        }else{
                            row['disable_flg'] = true;
                        }
                    }
                }
                data1[j] = row;
            }
            gridMainTable(data1);
        })
        .catch((error) => {
        });
    }

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysStockMoveId>0) {
            data['sys_stock_move_id'] = SysStockMoveId;
        }

        data['stock_move_date'] = SlipDate;
        data['slip_no'] = SlipNo;
        data['move_type'] = MoveType;
        data['leaving_shipper_code'] = ShipperCode1;
        data['leaving_shipper_a_code'] = ShipperACode1;
        if(MoveType == 3) {
            data['arrival_shipper_code'] = ShipperCode2;
            data['arrival_shipper_a_code'] = ShipperACode1;
            data['leaving_warehouse_code'] = LeavingWarehouseCode;
            data['arrival_warehouse_code'] = ArrivalWarehouseCode;
        }else{
            data['arrival_shipper_code'] = "";
            data['arrival_shipper_a_code'] = "";
            if(MoveType == 0) {
                data['leaving_warehouse_code'] = LeavingWarehouseCode;
                data['arrival_warehouse_code'] = ArrivalWarehouseCode;
            }else if(MoveType == 1) {
                data['leaving_warehouse_code'] = LeavingWarehouseCode;
                data['arrival_warehouse_code'] = "";
            }else{
                if(LeavingWarehouseCode != ""){
                    data['leaving_warehouse_code'] = LeavingWarehouseCode;
                    data['arrival_warehouse_code'] = "";
                }else{
                    data['leaving_warehouse_code'] = "";
                    data['arrival_warehouse_code'] = ArrivalWarehouseCode;
                }
            }
        }
        data['invoice_type'] = InvoiceType;
        
        data['question_no'] = QuestionNo;
        data['summary_code'] = SummaryCode;

        // item data --------------------------------------
        let validate_flg = true;
        let vmsg = "";
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            if(mainTableData[i]['sys_stock_move_item_id'] != 0) {
                row['sys_stock_move_item_id'] = mainTableData[i]['sys_stock_move_item_id'];
            }
            row['item_code'] = mainTableData[i]['item_code'];
            row['item_a_code'] = mainTableData[i]['item_a_code'];
            row['shipper_item_code'] = mainTableData[i]['shipper_item_code'];
            row['rot_code'] = mainTableData[i]['rot_code'];
            row['arrival_warehouse_code'] = ArrivalWarehouseCode;
            row['leaving_warehouse_code'] = LeavingWarehouseCode;
            row['warehouse_code'] = "";

            if(MoveType == 3) {
                row['leaving_table_code'] = "";
                row['arrival_table_code'] = "";
            }else{
                if(MoveType == 0) {
                    row['leaving_table_code'] = mainTableData[i]['leaving_table_code'];
                    row['arrival_table_code'] = mainTableData[i]['arrival_table_code'];
                }else if(MoveType == 1) {
                    row['leaving_table_code'] = mainTableData[i]['leaving_table_code'];
                    row['arrival_table_code'] = "";
                }else{
                    row['leaving_table_code'] = mainTableData[i]['leaving_table_code'];
                    row['arrival_table_code'] = mainTableData[i]['arrival_table_code'];
                }
            }

            if(mainTableData[i]['case_num'] != "") {
                row['case_num'] = mainTableData[i]['case_num'];
            }else{
                row['case_num'] = '';
            }
            if(mainTableData[i]['bara_num'] != "") {
                row['bara_num'] = mainTableData[i]['bara_num'];
            }else{
                row['bara_num'] = '';
            }
            row['item_num'] = mainTableData[i]['item_num'];
            row['item_deadline_date'] = mainTableData[i]['item_deadline_date'];
            row['remark'] = mainTableData[i]['remark'];
            row['arrival_weight'] = mainTableData[i]['arrival_weight'] + (mainTableData[i]['rot_weight'] * mainTableData[i]['item_num']);
            row['leaving_weight'] = (mainTableData[i]['rot_weight'] * mainTableData[i]['item_num']);

            itemData[i] = row;
        }
        data['items'] = itemData;
        
        // if(SysStockMoveId == 0) {
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setStockMoveSlipItemsEndpoint;
            csloading(true);
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data == 1) {
                        toast.success("登録致しました。", {autoClose:3000});
                        setArrivalWarehouseCode("");
                        setLeavingWarehouseCode("");
                        setArrivalWarehouseName("");
                        setLeavingWarehouseName("");
                        setShipperName1("");
                        setShipperACode1("");
                        setShipperCode1("");
                        setShipperName2("");
                        setShipperACode2("");
                        setShipperCode2("");
                        setInvoiceType(0);
                        setMoveType(0);
                        setQuestionNo("");
                        setSlipNo("");
                        setSlipDate(useJwt.formatDate(new Date()));
                        setMainTableData([]);
                        setTotalVolume(0.000);
                        setTotalWeight(0.000);
                        setTotalNum(0.000);
                    }else if(response.data.data == 2){
                        toast.error("すでに登録済みです。", {autoClose:3000});
                    }else{
                        toast.error("登録に失敗しました。", {autoClose:3000});
                    }
                }
                csloading(false);
            })
            .catch((error) => {
                console.log(error)
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
            });
        // }else{
        //     toast.error("この伝票はすでに登録されています。", {autoClose:3000});
        // }
        setOpen(false);
    }
    
    const loadModalDelData = (event) => {
        event.preventDefault();
    }
    const loadModalDelData2 = (event) => {
        event.preventDefault();
        setShowModalDel2(true);
    }
    const deleteData = (event) => {
        event.preventDefault();
    }
    // ========================== starts code for search ===============================
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const [ShipperType, setShipperType] = useState("arrival");
    const loadShipperModalData = (event,shipper_type) => {
        event.preventDefault();
        let shipper_code = ShipperCode1;
        let shipper_a_code = ShipperACode1;
        if(shipper_type == 'arrival') {
            shipper_code = ShipperCode2;
            shipper_a_code = ShipperACode2;
        }
        setShipperSearchValue(shipper_code);
        setShipperSearchValue2(shipper_a_code);
        setShipperType(shipper_type);
        if(shipper_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(shipper_code != "") {
                data['shipper_code'] = shipper_code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemSearchWarehouse, setItemSearchWarehouse] = useState("");
    const [ItemSearchInOutFlag, setItemSearchInOutFlag] = useState("2");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,id, code, acode) => {
        document.getElementById('selectedId').value=id;
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode1);
        setItemSearchWarehouse(LeavingWarehouseCode);
        setItemSearchInOutFlag("1");
        if(code != "" && ShipperCode1 != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            data['shipper_code'] = ShipperCode1;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemStockEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            if(ShipperCode1 != ""){
                setShowModalItem(true);
            }else{
                setShowModalItem(false);
            }
        }
    }
    const setItemData = (data) => {
        let item_code = "";
        let item_a_code = "";
        let item_name = "";
        let item_unit = "";
        let unit_weight = 0;
        let unit_volume = 0;
        let stock_num = 0;
        if(data['item_code'] && data['shipper_code'] == ShipperCode1) {
            item_code = data['item_code'];
            item_a_code = data['item_a_code'];
            item_name = data['item_name'];
            item_unit = data['item_unit'];
            unit_weight = data['unit_weight'];
            unit_volume = data['unit_volume'];
            stock_num = data['amount'];
        }
        
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i==document.getElementById('selectedId').value){
                if(item_code != row['item_code'] || item_a_code != row['item_a_code']){
                    row['rot_code'] = "";
                    row['rot_weight'] = 0;
                }
                row['item_code'] = item_code;
                row['item_a_code'] = item_a_code;
                row['item_name'] = item_name;
                row['item_unit'] = item_unit;
                row['unit_weight'] = unit_weight;
                row['unit_volume'] = unit_volume;
                row['stock_num'] = stock_num;
                // getItemRemainNum(i, SlipDate, ShipperCode1, row['item_code']
                //     , row['item_a_code'], row['rot_code'], LeavingWarehouseCode);
            }
            itemData[i] = row;
        }
        gridMainTable(itemData);
    }

    // -----------------------------arrival Table modal -------------------------------------
    const [arrivalShowModalTable, setArrivalShowModalTable] = useState(false);
    const [arrivalTableSearchValue, setArrivalTableSearchValue] = useState("");
    const [arrivalTableSearchValue2, setArrivalTableSearchValue2] = useState("");
    const [arrivalTableSearchValue3, setArrivalTableSearchValue3] = useState("");
    const [arrivalTableData, setArrivalTableData] = useState([]);
    const loadArrivalTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = ArrivalWarehouseCode;
        var rot_weight = $(".rot_weight").eq(idx).val();
console.log('rot_weight', rot_weight);
        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setArrivalTableSearchValue(table_code);
        setArrivalTableSearchValue2(warehouse_code);
        setArrivalTableSearchValue3(rot_weight);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;
            data['weight'] = rot_weight;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setArrivalTableData(response.data.data);
                setArrivalShowModalTable(true);
            })
            .catch((error) => {
            });
        }else{
            setArrivalTableData([]);
            setArrivalShowModalTable(true);
        }
    }
    const setArrivalTableCode = (data) => {
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i==document.getElementById('selectedId').value){
                if(data) {
                    row['arrival_table_code'] = data['table_code'];
                    row['arrival_weight'] = data['weight'];
                }else{
                    row['arrival_table_code'] = "";
                    row['arrival_weight'] = 0;
                }
            }
            itemData[i] = row;
        }
        gridMainTable(itemData);
    }


    // -----------------------------leaving Table modal -------------------------------------
    const [leavingShowModalTable, setLeavingShowModalTable] = useState(false);
    const [leavingTableSearchValue, setLeavingTableSearchValue] = useState("");
    const [leavingTableSearchValue2, setLeavingTableSearchValue2] = useState("");
    const [leavingTableSearchValue3, setLeavingTableSearchValue3] = useState("");
    const [leavingTableData, setLeavingTableData] = useState([]);
    const loadLeavingTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = LeavingWarehouseCode;
        // var rot_weight = $(".rot_weight").eq(idx).val();

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setLeavingTableSearchValue(table_code);
        setLeavingTableSearchValue2(warehouse_code);
        setLeavingTableSearchValue3(0);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['leaving_table_code'] = table_code;
            // data['leaving_weight'] = rot_weight;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLeavingTableData(response.data.data);
                setLeavingShowModalTable(true);
            })
            .catch((error) => {
            });
        }else{
            setLeavingTableData([]);
            setLeavingShowModalTable(true);
        }
    }
    const setLeavingTableCode = (data) => {
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i==document.getElementById('selectedId').value){
                if(data) {
                    row['leaving_table_code'] = data['table_code'];
                    row['leaving_weight'] = data['weight'];
                }else{
                    row['leaving_table_code'] = "";
                    row['leaving_weight'] = 0;
                }
            }
            itemData[i] = row;
        }
        gridMainTable(itemData);
    }

    // ------------------------------ rot modal ---------------------------------------
    const [showModalRot, setShowModalRot] = useState(false);
    const [RotSearchValue, setRotSearchValue] = useState("");
    const [RotSearchValue2, setRotSearchValue2] = useState("");
    const [RotSearchValue3, setRotSearchValue3] = useState("");
    const [RotSearchValue4, setRotSearchValue4] = useState("");
    const [RotSearchWarehouse, setRotSearchWarehouse] = useState("");
    const [RotSearchInOutFlag, setRotSearchInOutFlag] = useState("");
    const [RotTableData, setRotTableData] = useState([]);
    const loadRotModalData = (event,id, rot_code, shipper_code, item_code,item_a_code) => {
        document.getElementById('selectedId').value=id;
        setRotSearchValue(rot_code);
        setRotSearchValue2(shipper_code);
        setRotSearchValue3(item_code);
        setRotSearchValue4(item_a_code);
        setRotSearchWarehouse(LeavingWarehouseCode);
        setRotSearchInOutFlag("1");
        if(rot_code != "" && shipper_code != "" && item_code != "" && item_a_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['rot_code'] = rot_code;
            data['shipper_code'] = shipper_code;
            data['item_code'] = item_code;
            data['item_a_code'] = item_a_code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstRotStockEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setRotTableData(response.data.data);
                setShowModalRot(true);
            })
            .catch((error) => {
            });
        }else{
            setRotTableData([]);
            if(shipper_code != "" && item_code != "" && item_a_code != "") {
                setShowModalRot(true);
            }else{
                setShowModalRot(false);
            }
        }
    }
    const setRotData = (data) => {
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i==document.getElementById('selectedId').value){
                console.log('limit', data['limit_amount']);
                if(data['shipper_code'] == ShipperCode1 
                && data['item_code'] == row['item_code'] && data['item_a_code']==row['item_a_code']) {
                    row['rot_code'] = data['rot_code'];
                    row['rot_name'] = data['rot_name'];
                    if(data['limit_amount'] > 0)    row['rot_weight'] = Math.round((1 / data['limit_amount']) * 10000) / 10000;
                    else    row['rot_weight'] = 0;
                    row['stock_num'] = data['amount'];
                }else{
                    row['rot_code'] = "";
                    row['rot_name'] = "";
                    row['rot_weight'] = 0;
                    row['stock_num'] = 0;
                }
                // getItemRemainNum(i, SlipDate, ShipperCode1, row['item_code']
                //     , row['item_a_code'], row['rot_code'], LeavingWarehouseCode);
            }
            itemData[i] = row;
        }
        gridMainTable(itemData);
    }

    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const loadWarehouseModalData = (event,id,warehouse_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=id;
        setWarehouseSearchValue(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(warehouse_code != "") {
                data['warehouse_code'] = warehouse_code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    const setWarehouseData = (data) => {
        if(document.getElementById('selectedId').value == 'arrival') {
            setArrivalWarehouseCode(data['warehouse_code']);
            setArrivalWarehouseName(data['warehouse_name']);
            setArrivalWarehouseType(data['warehouse_type']);
        }else if(document.getElementById('selectedId').value == 'leaving') {
            setLeavingWarehouseCode(data['warehouse_code']);
            setLeavingWarehouseName(data['warehouse_name']);
            setLeavingWarehouseType(data['warehouse_type']);
        }

        if(MoveType==2){
            if(document.getElementById('selectedId').value == 'arrival') {
                setLeavingWarehouseCode("");
                setLeavingWarehouseName("");
                setLeavingWarehouseType(0);
            }else if(document.getElementById('selectedId').value == 'leaving') {
                setArrivalWarehouseCode("");
                setArrivalWarehouseName("");
                setArrivalWarehouseType(0);
            }
        }
    }

    // ------------------------------ Summary Modal --------------------------------------
    const [showModalSummary, setShowModalSummary] = useState(false);
    const [SummarySearchValue, setSummarySearchValue] = useState("");
    const [SummaryTableData, setSummaryTableData] = useState([]);
    const loadSummaryModalData = (event) => {
        event.preventDefault();
        let code = SummaryCode;
        setSummarySearchValue(code);

        if(code != ""){
            const data = {
                userID: userData['id'],
                summary_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstSummaryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setSummaryTableData(response.data.data);
                setShowModalSummary(true);
            })
            .catch((error) => {
            });
        }else{
            setSummaryTableData([]);
            setShowModalSummary(true);
        }
    }

    
    const tableKeyDown = ( e, id, index ) =>{
        if(e.keyCode === 13){
            e.preventDefault();
            if("arrival_table_code" + index == id){
                if(MoveType == 1){
                    document.getElementById("case_num" + index).focus();
                }else{
                    document.getElementById(id).focus();
                }
            }else{
                document.getElementById(id).focus();
            }
        }
    }

    const tableEndKeyDown = ( e, id, index ) =>{
        let dataLength = mainTableData.length;

        if(dataLength <= index + 1)  return;
        
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_total_input_wrap = [gstyle.p0015, gstyle.mt30];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_select_245px = [gstyle.cs_select, gstyle.w245px];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];
    const style_input_80_bl_n = [gstyle.cs_input, gstyle.w80, gstyle.cs_bl_n];
    const style_tbl_input_bl_n = [style.cs_tbl_left_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_th_w4p = [style.cs_th, style.w4p];
    const style_th_w5p = [style.cs_th, style.w5p];
    const style_th_w7p = [style.cs_th, style.w7p];
    const style_th_w8p = [style.cs_th, style.w8p];
    const style_th_w9p = [style.cs_th, style.w9p];
    const style_th_w10p = [style.cs_th, style.w10p];
    const style_th_w14p = [style.cs_th, style.w14p];
    const style_th_w15p = [style.cs_th, style.w15p];

    const style_td_w4p = [style.cs_td, style.w4p];
    const style_td_w5p = [style.cs_td, style.w5p];
    const style_td_w7p = [style.cs_td, style.w7p];
    const style_td_w8p = [style.cs_td, style.w8p];
    const style_td_w9p = [style.cs_td, style.w9p];
    const style_td_w10p = [style.cs_td, style.w10p];
    const style_td_w14p = [style.cs_td, style.w14p];
    const style_td_w15p = [style.cs_td, style.w15p];

    const style_th_total_title = [style.cs_th, style.total_label];
    const style_td_total_label = [style.cs_td, style.total_label];
    const style_td_total_val = [style.cs_td];

    const style_td_input_right = [style.cs_tbl_input, style.td_number_right]
    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={ShipperType} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} 
                tableData={ItemTableData} setTableData={setItemTableData} warehouseCode={ItemSearchWarehouse} inOutFlag={ItemSearchInOutFlag}
                target={setItemData} />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} />
            <RotModal show={showModalRot} onClose={()=>setShowModalRot(false)} width={'70%'}
                SearchValue={RotSearchValue} onChange={setRotSearchValue} 
                SearchValue2={RotSearchValue2} SearchValue3={RotSearchValue3} SearchValue4={RotSearchValue4}
                tableData={RotTableData} setTableData={setRotTableData} warehouseCode={RotSearchWarehouse} inOutFlag={RotSearchInOutFlag}
                target={setRotData} />
            <SummaryModal show={showModalSummary} onClose={()=>setShowModalSummary(false)} width={'70%'}
                SearchValue={SummarySearchValue} onChange={setSummarySearchValue} 
                tableData={SummaryTableData} setTableData={setSummaryTableData}
                target={setSummaryData} />
            <Modal2 title="在庫移動処理削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <Modal2 title="在庫移動処理削除" show={showModalDel2} onClose={()=>setShowModalDel2(false)} width={'40%'} 
                onConfirm={deleteItem}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <ArrivalTableModal show={arrivalShowModalTable} onClose={()=>setArrivalShowModalTable(false)} width={'70%'}
                SearchValue={arrivalTableSearchValue} SearchValue2={arrivalTableSearchValue2} SearchValue3={arrivalTableSearchValue3}  onChange={setArrivalTableSearchValue} 
                tableData={arrivalTableData} setTableData={setArrivalTableData}
                target={setArrivalTableCode} />
            <LeavingTableModal show={leavingShowModalTable} onClose={()=>setLeavingShowModalTable(false)} width={'70%'}
                SearchValue={leavingTableSearchValue} SearchValue2={leavingTableSearchValue2} SearchValue3={leavingTableSearchValue3}  onChange={setLeavingTableSearchValue} 
                tableData={leavingTableData} setTableData={setLeavingTableData}
                target={setLeavingTableCode} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>在庫移動処理</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 年月日, 伝票No, 問合せNo */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={SlipDate} onChange={handleChangeSlipDate} focusId="slip_number"/>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>伝票No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")} 
                                        onKeyDown={(e) => onKeyDownSlipNo(e)} id="slip_number"
                                        onBlur={(e) => onBlurSlipNo(e)}
                                        value={SlipNo} onChange={handleChangeSlipNo} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>問合せNo</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")} id="question_number"
                                        value={QuestionNo} onChange={handleChangeQuestionNo} 
                                        onKeyDown={(e)=>onKeyDownQuestion(e)}/>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 移動区分, 請求区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>移動区分</span>
                                    <div className={gstyle.cs_flex} >
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>setType1Open(!type1Open)}
                                            placeholder="移動区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeMoveType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={[
                                            {
                                                value: '0',
                                                label: '倉替',
                                            },
                                            {
                                                value: '1',
                                                label: '滅失',
                                            },
                                            {
                                                value: '2',
                                                label: '調整',
                                            },
                                            {
                                                value: '3',
                                                label: '荷主間移動',
                                            },
                                            ]}
                                        />                                        
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>請求区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                                showSearch
                                                open={type2Open}
                                                onSelect={(e)=>setType2Open(!type2Open)}
                                                placeholder="請求区分を選択します。"
                                                optionFilterProp="children"
                                                onChange={handleChangeInvoiceType}
                                                onKeyDown={onType2KeyDown}
                                                onFocus={(e) => onFocusType2(e, 1)}
                                                onBlur={(e) => onFocusType2(e, 2)}
                                                filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                style={{ marginTop:14, width:250}}
                                                id="type2"
                                                options={[
                                                {
                                                    value: '0',
                                                    label: '請求対象',
                                                },
                                                {
                                                    value: '1',
                                                    label: '請求対象外',
                                                },
                                                ]}
                                            />                                        
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主1,2 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主1</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,'leaving')} id="shipper_code"
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,'leaving')} id="shipper_a_code"
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,'leaving')}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主2</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        disabled={MoveType<3?true:false} id="shipper_other_code"
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,'arrival')}
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        disabled={MoveType<3?true:false} id="shipper_other_a_code"
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,'arrival')}
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        {
                                            MoveType < 3 ?
                                            <div className={gstyle.icon_search_wrap}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon"/>
                                            </div>
                                            :
                                            <div className={gstyle.icon_search_wrap}
                                                onClick={(e)=>loadShipperModalData(e,'arrival')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon"/>
                                            </div>
                                        }
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 出庫倉庫, 入庫倉庫 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出庫倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,2)} id="warehouse_code"
                                            value={LeavingWarehouseCode} onChange={handleChangeLeavingWarehouseCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={LeavingWarehouseName} />
                                        <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadWarehouseModalData(e,'leaving',LeavingWarehouseCode)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>入庫倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            disabled={(MoveType==1)?true:false} id="warehouse_other_code"
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,1)}
                                            value={ArrivalWarehouseCode} onChange={handleChangeArrivalWarehouseCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ArrivalWarehouseName} />
                                        {
                                            MoveType == 1 ?
                                            <div className={gstyle.icon_search_wrap} >
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            :
                                            <div className={gstyle.icon_search_wrap} 
                                                onClick={(e)=>loadWarehouseModalData(e,'arrival',ArrivalWarehouseCode)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        }
                                    </div>
                                </label>
                            </div>
                        </div>
                        { /* 追加, 削除 */}
                        <div className={style_col_4.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")}>
                                <button className={gstyle.btn_new} onClick={newRecord} id="addRow">追加</button>
                                <button className={gstyle.btn_delete2} onClick={loadModalDelData2} >削除</button>
                            </div>
                        </div>
                    </div>
                    {/* table */}
                    <div className={style.tbl_wrap}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_w15p.join(" ")}>品番</div>
                                    <div className={style_th_w5p.join(" ")}>荷主品番</div>
                                    <div className={style_th_w14p.join(" ")}>品名</div>
                                    <div className={style_th_w10p.join(" ")}>ロットNo</div>
                                    <div className={style_th_w8p.join(" ")}>ロット名</div>
                                    <div className={style_th_w7p.join(" ")}>出庫棚</div>
                                    <div className={style_th_w7p.join(" ")}>入庫棚</div>
                                    <div className={style_th_w4p.join(" ")}>単位</div>
                                    <div className={style_th_w4p.join(" ")}>ケース</div>
                                    <div className={style_th_w4p.join(" ")}>バラ</div>
                                    <div className={style_th_w5p.join(" ")}>数量</div>
                                    <div className={style_th_w9p.join(" ")}>商品期限</div>
                                    <div className={style_th_w8p.join(" ")}>備考</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                            id={'chk_'+index} onChange={handleChangeChk} />
                                        </div>
                                        <div className={style_td_w15p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            value={data['item_code']?data['item_code']:''} id={"item_code" + index}
                                            onChange={(e) => handlChangeItemData(e,1,'item_code',index)}  onKeyDown={(e)=>tableKeyDown(e, 'item_a_code' + index, index)}/>
                                            <input type={'number'} className={style_tbl_input_bl_n.join(" ")} 
                                            value={data['item_a_code']?data['item_a_code']:''}  id={"item_a_code" + index}
                                            onChange={(e) => handlChangeItemData(e,1,'item_a_code',index)}  onKeyDown={(e)=>tableKeyDown(e, 'shipper_item_code' + index, index)}/>
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadItemModalData(e,index,data['item_code']?data['item_code']:'',data['item_a_code']?data['item_a_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            value={data['shipper_item_code']?data['shipper_item_code']:''}  id={"shipper_item_code" + index}
                                            onChange={(e) => handlChangeItemData(e,1,'shipper_item_code',index)} onKeyDown={(e)=>tableKeyDown(e, 'rot_code' + index, index)} />
                                        </div>
                                        <div className={style_td_w14p.join(" ")} style={{justifyContent:"left"}}>
                                            {data['item_name']?data['item_name']:''}
                                        </div>
                                        <div className={style_td_w10p.join(" ")}>
                                            <input type={'text'} className="rot_code"  id={"rot_code" + index}
                                            style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                            padding: '0px 2px',color: '#565656',fontSize: 13,outline: 0}} 
                                            value={data['rot_code']?data['rot_code']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'rot_code',index)} onKeyDown={(e)=>tableKeyDown(e, 'leaving_table_code' + index, index)} />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadRotModalData(e,index,data['rot_code']?data['rot_code']:''
                                                ,data['shipper_code']?data['shipper_code']:ShipperCode1
                                                ,data['item_code']?data['item_code']:''
                                                ,data['item_a_code']?data['item_a_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            <input type={'hidden'} className="rot_weight" value={data['rot_weight']?data['rot_weight']:0}/> 
                                            <input type={'hidden'} className="stock_num" value={data['stock_num']?data['stock_num']:0}/>
                                        </div>
                                        <div className={style_td_w8p.join(" ")}>
                                            {data['rot_name']?data['rot_name']:''}
                                        </div>
                                        <div className={style_td_w7p.join(" ")} style={{display:'flex'}}>
                                        {
                                            LeavingWarehouseType != 0 &&
                                            <>
                                            <input type={'number'} className={style.cs_tbl_input} id={"leaving_table_code" + index}
                                            value={data['leaving_table_code']?data['leaving_table_code']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'leaving_table_code',index)}
                                             onKeyDown={(e)=>tableKeyDown(e, 'arrival_table_code' + index, index)}/>
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadLeavingTableModalData(e,index,data['leaving_table_code']?data['leaving_table_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            <input type={'hidden'} className="leaving_table_weight" value={data['leaving_weight']?data['leaving_weight']:0}/>
                                            </>
                                        }
                                        </div>
                                        <div className={style_td_w7p.join(" ")} style={{display:'flex'}}>
                                        {
                                            ArrivalWarehouseType != 0 &&
                                            <>
                                            <input type={'number'} className={style.cs_tbl_input} id={"arrival_table_code" + index}
                                            value={data['arrival_table_code']?data['arrival_table_code']:''} 
                                            onKeyDown={(e)=>tableKeyDown(e, 'case_num' + index, index)}
                                            onChange={(e) => handlChangeItemData(e,1,'arrival_table_code',index)} />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadArrivalTableModalData(e,index,data['arrival_table_code']?data['arrival_table_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            <input type={'hidden'} className="arrival_table_weight" value={data['arrival_weight']?data['arrival_weight']:0}/>
                                            </>
                                        }
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            {data['item_unit']?data['item_unit']:''}
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'number'} className={style_td_input_right.join(" ")} 
                                            value={data['case_num']?data['case_num']:''} id={"case_num" + index}
                                            onChange={(e) => handlChangeItemData(e,1,'case_num',index)} onKeyDown={(e)=>tableKeyDown(e, 'bara_num' + index, index)}/>
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'number'} className={style_td_input_right.join(" ")} 
                                            value={data['bara_num']?data['bara_num']:''} id={"bara_num" + index}
                                            onChange={(e) => handlChangeItemData(e,1,'bara_num',index)} onKeyDown={(e)=>tableKeyDown(e, 'item_num' + index, index)}/>
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                        {
                                            data['stock_num'] > 0 
                                            ?
                                            <Popover content={useJwt.numberWithCommas(data['stock_num'])} title="現在残" trigger="focus" style={{width:
                                            50}}>
                                            <input type={'number'} className={style_td_input_right.join(" ")}
                                            value={data['item_num']?data['item_num']:''} id={"item_num" + index}
                                            onChange={(e) => handlChangeItemData(e,1,'item_num',index)} 
                                            onKeyDown={(e)=>tableKeyDown(e, 'deadline_input' + index, index)}/>
                                            </Popover>
                                            :
                                            <input type={'number'} className={style_td_input_right.join(" ")}
                                            value={data['item_num']?data['item_num']:''} id={"item_num" + index}
                                            onChange={(e) => handlChangeItemData(e,1,'item_num',index)} 
                                            onKeyDown={(e)=>tableKeyDown(e, 'deadline_input' + index, index)}/>
                                        }
                                        </div>
                                        <div className={style_td_w9p.join(" ")}>
                                            <InputDatepickerTable  value={data['item_deadline_date']?data['item_deadline_date']:''} 
                                            onChange={(e) => handlChangeItemData(e,2,'item_deadline_date',index)}
                                            id={'deadline_input'+index} focusId={"remark"+index}
                                            />
                                        </div>
                                        <div className={style_td_w8p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            value={data['remark']?data['remark']:''} id={"remark" + index}
                                            onChange={(e) => handlChangeItemData(e,1,'remark',index)} 
                                            onKeyDown={(e)=>tableEndKeyDown(e, "item_code"+(index+1), index)}/>
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* Total */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_total_input_wrap.join(" ")}>
                                <div className={style.cs_tbl}>
                                    <div className={style.cs_thead}>
                                        <div className={style.cs_tr}>
                                            <div className={style_th_total_title.join(" ")}>合計</div>
                                        </div>
                                    </div>
                                    <div className={style.cs_tbody}>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_label.join(" ")}>数量</div>
                                            <div className={style_td_total_val.join(" ")}>{TotalNum}</div>
                                        </div>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_label.join(" ")}>重量</div>
                                            <div className={style_td_total_val.join(" ")}>{TotalWeight}</div>
                                        </div>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_label.join(" ")}>容積</div>
                                            <div className={style_td_total_val.join(" ")}>{TotalVolume}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 摘要 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>摘要コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownSummaryCode(e)}
                                        value={SummaryCode} onChange={handleChangeSummaryCode} />
                                        <input type={'text'} className={style_input_80_bl_n.join(" ")}  
                                        value={SummaryName} disabled />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadSummaryModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >登録する</button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default StockMoveSlip;