/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './BillingList.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import CategoryModal from '../../../components/common/modal/CategoryModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import { Select } from 'antd';

const BillingList = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    
    
    // slip date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // date type -----------------------------------------------
    const [DateType, setDateType] = useState(0);
    const handleChangeDateType = (event) => {
        setDateType(event);
    };
    const DateTypeData = [ { value:'0', label:'締日指定' }, { value:'1', label:'期締範囲指定' }, ];
    // deadline day ---------------------------------------------
    const [DeadLineDay, setDeadLineDay] = useState(useJwt.formatDate(new Date()));
    const handleChangeDeadLineDay = (date) => {
        setDeadLineDay(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
    };
    const OrderTypeData = [ { value:'0', label:'荷主順' }, { value:'1', label:'分類①' },
    { value:'2', label:'分類②' }, { value:'3', label:'分類③' } ];
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('type3').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ShipperACode1;
            }else{
                code = ShipperCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ShipperACode2;
            }else{
                code = ShipperCode2;
                acode = value;
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // category --------------------------------------------------------
    const [CategoryCode1, setCategoryCode1] = useState("");
    const [CategoryName1, setCategoryName1] = useState("");
    const handleChangeCategoryCode1 = (event) => {
        setCategoryCode1(useJwt.validateNum(event.target.value,3));
    };
    const [CategoryCode2, setCategoryCode2] = useState("");
    const [CategoryName2, setCategoryName2] = useState("");
    const handleChangeCategoryCode2 = (event) => {
        setCategoryCode2(useJwt.validateNum(event.target.value,3));
    };
    const onKeyDownCategoryCode =(event,param) => {
        if(event.keyCode === 13) { // enter key
            getCategoryData(event.target.value,param);
            if(param == 1)  document.getElementById('category_code2').focus();
            else    document.getElementById('type3').focus();
            event.preventDefault();
        }
    };
    const getCategoryData = (code,param) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let t_data = response.data;
                if(t_data.length > 0) {
                    setCategoryData(t_data[0],param);
                }else{
                    setCategoryData([],param);
                }
            })
            .catch((error) => {
            });
        }else{
            setCategoryData([],param);
        }
    }
    const setCategoryData = (data,param) => {
        if(data['category_code']) {
            if(param == 1) {
                setCategoryCode1(data['category_code']);
                setCategoryName1(data['category_name']);
            }else{
                setCategoryCode2(data['category_code']);
                setCategoryName2(data['category_name']);
            }
        }else{
            if(param == 1) {
                setCategoryCode1("");
                setCategoryName1("");
            }else{
                setCategoryCode2("");
                setCategoryName2("");
            }
        }
    }
    // sub code type -----------------------------------------------
    const [SubCodeType, setSubCodeType] = useState(0);
    const handleChangeSubCodeType = (event) => {
        setSubCodeType(event);
    };
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_invoice_flag_1'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];

        if(DateType == 0) {
            data['deadline_year_month'] = useJwt.getYearMonth(DeadLineDay);
        }else{
            data['deadline_year_month'] = useJwt.getYearMonth(EndDate);
        }
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        
        csloading(true);
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstInvoiceEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        }).then((response) => {
            if(response.status == 200) {
                let invoice_data = [];
                if(response.data.data.length>0) {
                    invoice_data = response.data.data;
                    if(DateType == 0) {
                        data['deadline_year_month'] = useJwt.getPreviousMonth(DeadLineDay);
                    }else{
                        data['deadline_year_month'] = useJwt.getPreviousMonth(EndDate);
                    }
                    axios
                    .post(url, data, {
                        headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                        },
                    }).then((response2) => {
                        if(response2.status == 200) {
                            let prev_invoice_data = [];
                            if(response2.data.data.length>0) {
                                prev_invoice_data = response2.data.data;
                            }
                            // console.log("invoice_data:",invoice_data);
                            // console.log("prev_invoice_data:",prev_invoice_data);
                            for(var i=0;i<invoice_data.length;i++) {
                                invoice_data[i]['prev_invoice'] = 0;
                                for(var j=0;j<prev_invoice_data.length;j++) {
                                    if(invoice_data[i]['shipper_code'] == prev_invoice_data[j]['shipper_code']) {
                                        invoice_data[i]['prev_invoice'] = prev_invoice_data[j]['invoice_price'];
                                        break;
                                    }
                                }
                            }
                            // console.log("invoice_data:",invoice_data);
                            gridMainTable(invoice_data);
                        }
                        csloading(false);
                    })
                    .catch((error) => {
                        csloading(false);
                    });
                }else{
                    toast.error("検索結果はありません。", {autoClose:3000}); 
                    csloading(false);
                }
            }
        })
        .catch((error) => {
            csloading(false);
        });
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        setMainTableData(result);
        if(result.length>0){
            setOpend(true);
        }
    }
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ----------------------- Category Modal ------------------------------------------
    const [showModalCategory, setShowModalCategory] = useState(false);
    const [CategorySearchValue, setCategorySearchValue] = useState("");
    const [CategoryTableData, setCategoryTableData] = useState([]);
    const [mstType, setMstType] = useState(0);
    const [categoryType, setCategoryType] = useState(0);
    const loadCategoryCodeModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = CategoryCode1;
        if(param==2){
            code = CategoryCode2;
        }

        let category = 0;
        if(OrderType == 1){
            category = 0;
        }else if(OrderType == 2){
            category = 1;
        }else{
            category = 2;
        }
        setCategoryType(category);
        setCategorySearchValue(code);
        if(code != ""){
            const data = {
                userID: userData['id'],
                category_code: code,
                mst_type: 0,
                category_type: category,
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setCategoryTableData(response.data);
                setShowModalCategory(true);
            })
            .catch((error) => {
            });
        }else{
            setCategoryTableData([]);
            setShowModalCategory(true);
        }
    }
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('date2')){
                    document.getElementById('date2').focus();
                }else{
                    document.getElementById('date3').focus();
                }
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('category_code1')){
                    document.getElementById('category_code1').focus();
                }else{
                    document.getElementById('shipper_code1').focus();
                }
            },10)
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }


    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
                
            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_9 = [gstyle.cs_col, gstyle.cs_col_9];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap2 = [style.p2030];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_wrap4 = [gstyle.p00300015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <CategoryModal show={showModalCategory} onClose={()=>setShowModalCategory(false)} width={'70%'}
                SearchValue={CategorySearchValue} onChange={setCategorySearchValue} 
                tableData={CategoryTableData} setTableData={setCategoryTableData} mstType={mstType} categoryType={categoryType}
                target={setCategoryData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>請求一覧表</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 締日区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='type1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>締日区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="締日区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeDateType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={DateTypeData}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 締切年月日 */}
                    {DateType==0 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>締切年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={DeadLineDay} onChange={handleChangeDeadLineDay} id='date2' focusId='type2' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 年月日 */}
                    {DateType==1 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={StartDate} onChange={handleChangeStartDate} id='date3' focusId='date4' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={EndDate} onChange={handleChangeEndDate} id='date4' focusId='type2' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 出力順位 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={OrderTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    {OrderType == 0 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始荷主</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                            value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                            <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                            value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ShipperName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了荷主</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                            value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                            <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                            value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ShipperName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 分類 */}
                    {OrderType > 0 &&
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始分類</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownCategoryCode(e,1)} id='category_code1'
                                            value={CategoryCode1} onChange={handleChangeCategoryCode1} />
                                        <input type={'text'} className={gstyle.cs_input} disabled
                                        value={CategoryName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCategoryCodeModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了分類</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownCategoryCode(e,2)} id='category_code2'
                                            value={CategoryCode2} onChange={handleChangeCategoryCode2} />
                                        <input type={'text'} className={gstyle.cs_input} disabled
                                        value={CategoryName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCategoryCodeModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    }
                    {/*  補助区分, */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>補助区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="補助区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeSubCodeType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={[
                                                {value:'0', label:'一括'},
                                                {value:'1', label:'補助別'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        {/* Title, 発行年月日 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_2.join(" ")}></div>
                            <div className={style_col_8.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>請求一覧表</h2>
                                </div>
                            </div>
                            <div className={style_col_2.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {/* table */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>荷主ｺｰﾄﾞ</th>
                                                <th rowSpan={2}>前回請求額</th>
                                                <th rowSpan={2}>今回入金額</th>
                                                <th rowSpan={2}>前回分残高</th>
                                                <th rowSpan={2}>今回保管料</th>
                                                <th rowSpan={2}>今回荷役料</th>
                                                <th rowSpan={2}>今回諸掛金額</th>
                                                <th rowSpan={2}>請求額</th>
                                                <th rowSpan={2}>消費税</th>
                                                <th rowSpan={2}>今回御請求額</th>
                                            </tr>
                                            <tr>
                                                <th>荷主</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {  mainTableData.map((data,index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>{data['shipper_code']}</td>
                                                        <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(data['prev_invoice'])}</td>
                                                        <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(data['deposit_price'])}</td>
                                                        <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(1*data['prev_invoice']-data['deposit_price'])}</td>
                                                        <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(data['storage_price'])}</td>
                                                        <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(data['cargo_price'])}</td>
                                                        <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(data['freightage_price'])}</td>
                                                        <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(1*data['storage_price']+1*data['cargo_price']+1*data['freightage_price'])}</td>
                                                        <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(data['tax_price'])}</td>
                                                        <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(data['invoice_price'])}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{data['shipper_name']}</td>
                                                    </tr>
                                                </React.Fragment>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'請求一覧表_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default BillingList;