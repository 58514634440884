/* eslint-disable eqeqeq */
import  { React, useState, useRef } from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja'
import 'react-datepicker/dist/react-datepicker.css';
import "./InputCalender.css";
registerLocale('ja', ja);

const InputDatepicker = props => {
    const [date, setDate] = useState(null);
    const handleChange = (param, id) => {
        props.onChange(param);
        setDate(param);
    }

    const [open, setOpen] = useState(false);
    const [focus, setFocus] = useState(false);

    const onFocusInData = (e) =>{
        console.log('in')
        setOpen(true);
    }

    const onFocusOutData = (e) =>{
        setOpen(false);
    }

    const onSelectData = (e)=>{
        setOpen(false);
        setFocus(true);
    }
    const onKeyDownDate = (e, id) =>{
        if(e.keyCode == 13 && id != ''){
            setOpen(false);
            document.getElementById(id).focus();
        }
    }
    const onClick = (e) =>{
        if(focus == false){
            setOpen(true);
        }
        setFocus(false);
    }
    return (
        <>
            <DatePicker className="formDatePicker" id={props.id}
                open={open}
                onSelect={(e)=>onSelectData(e)}
                onFocus={(e)=>onFocusInData(e)}
                onBlur={(e)=>onFocusOutData(e)}
                onInputClick={(e)=>onClick(e)}
                onClickOutside={(e)=>setOpen(false)}
                onChange = {selectedDate => {handleChange(selectedDate || '')}}
                locale="ja"
                selected={props.value==''?'':new Date(props.value)}
                dateFormat="yyyy-MM-dd" style={{zIndex:"2"}}
                onKeyDown={(e)=>onKeyDownDate(e, props.focusId || '')}
            />
        </>
    )
}

export default InputDatepicker;
