import style from './HeadingText.module.css'

const HeadingText = ({ color, title }) => {
	return (
		<>
			<div className={style.heading} style={{borderBottom:`1px solid ${color}`}}>
				<div className={style.headingText_color} style={{ backgroundColor: color }}></div>
				<div className={style.headingText_title}>{title}</div>
			</div>
		</>
	);
};

export default HeadingText;