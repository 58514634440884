import React from 'react';
import style from "./Driver.module.css";
import gstyle from '../../css/GeneralStyle.module.css';
import {Button} from "antd-mobile";
import { useState, useEffect } from 'react';
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import * as useJwt  from '../../auth/jwt/useJwt.js';
import ClipLoader from 'react-spinners/ClipLoader';

function Working() {
  let urlParams = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{

    let current_date = useJwt.getDriverWorking();
    if(current_date){
      setTimeout(() => {
        let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  
        var diff_ms = moment(current_time).diff(current_date, "seconds");
  
        setDisplayTime(secondToHours(diff_ms));
      }, 1000);
    }

  })


  const secondToHours = (ms) => {
    var hours = Math.floor(ms / 3600);
    var minutes = Math.floor((ms - hours * 3600) / 60);
    var seconds = ms - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return hours + " : " + minutes + " : " + seconds;
  };

  const [loaded, setLoaded] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [displayTime, setDisplayTime] = useState("");
  const [busName, setBusName] = useState(urlParams.state.data.bus_name + "番バース");
  const [busTime, setBusTime] = useState(urlParams.state.data.start_date_time);
  const [busEndTime, setBusEndTime] = useState(urlParams.state.data.end_date_time);

  const csLoading = (flag) => {
    if(flag) {
        document.getElementById('csLoader').style.display="flex";
    }else{
        document.getElementById('csLoader').style.display="none";
    }
  }

  const workStart = () => {
    csLoading(true);
    let current_date = moment().format("YYYY-MM-DD HH:mm:ss");

    let data = {
      id : urlParams.state.data.id,
      start_time : current_date,
    }

    let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setDriverScheduleTime;
    axios
      .post(url, data)
      .then((response) => {
        useJwt.setDriverWorking(current_date);
        setCurrentTime(current_date);
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
      });
  }

  const workEnd = (e) => {
    csLoading(true);
    let current_date = moment().format("YYYY-MM-DD HH:mm:ss");

    let data = {
      id : urlParams.state.data.id,
      end_time : current_date,
    }

    let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setDriverScheduleTime;
    axios
      .post(url, data)
      .then((response) => {
        csLoading(false);
        useJwt.removeDriverWorking();
        setCurrentTime("");
        navigate("/driver/workEnd", {
          state: {
            companyName: urlParams.state.companyName,
            warehouseName : urlParams.state.warehouseName,
          },
          replace: false,
        });
      })
      .catch((error) => {
        csLoading(false);
      });
  }

  //style

  const style_header = [style.content_flex_column, style.title, style.content_align_center, style.mt70];
  const style_content = [style.content_flex_column, style.mt30, style.content_align_center];
  const style_content_but = [style.content_flex_row, style.mt70, style.content_align_center];

  return (
    <div className={style.content_flex_column}>
      <div className={gstyle.loader_container} id="csLoader">
          <ClipLoader color={'#fff'} size={60} />
      </div>
      <div className={style_header.join(" ")}>
        <label>{urlParams.state.companyName}予約システム</label>
        <label className={style.sub_title}>{urlParams.state.warehouseName}</label>
      </div>

      <div className={style_content.join(" ")}>
          <div >
            <label style={{fontSize:16}}>開始予定時間 : {busTime}</label>
          </div>
          <div >
            <label style={{fontSize:16}}>終了予定時間 : {busEndTime}</label>
          </div>
          {
            currentTime !== "" && 
            <div >
              <label style={{fontSize:16}}>実際作業時間 : {currentTime}</label>
            </div>
          }
      </div>
      <div className={style_content.join(" ")}>
          <p style={{fontSize: 50}}>{displayTime}</p>
      </div>

      <div className={style_content_but.join(" ")}>
      {
        currentTime !== "" ?
          <Button className={style.button} style={{marginRight:15}} size='small' color='primary' fill='solid' disabled>作業開始</Button>
        :
          <Button className={style.button} style={{marginRight:15}}  size='small' color='primary' fill='solid' onClick={(e) =>workStart(e)}>作業開始</Button>
      }
        <Button className={style.button} style={{marginLeft:15}}  size='small' color='primary' fill='outline' onClick={(e) =>workEnd(e)}>作業終了</Button>
      </div>

    </div>
  );
}

export default Working;
