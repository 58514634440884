/* eslint-disable eqeqeq */
import style from "./CsvImport.module.css";
import gStyle from "../../css/GeneralStyle.module.css";
import React, { useState, useEffect } from "react";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";

import CsvImportDataCheckComponent from "../../components/common/CsvImportDataCheckInput.tsx";

const ItemsImport = () => {
  const [importErrorData, setImportErrorData] = useState([]);
  const [loadFlag, setLoadFlag] = useState(false);

  let itemCsvHeader = [
    "日付範囲",
    "範囲指定",
    "荷主ｺｰﾄﾞ",
    "補助",
    "区分",
    "年月日",
    "伝票No",
    "品番",
    "ﾛｯﾄNo",
    "倉庫",
    "ﾛｹｰｼｮﾝNo",
    "入庫数量",
    "出庫数量",
    "在庫数量",
    "荷主名",
    "品名"
  ];

  useEffect(() => {
    if(importErrorData[0] == "success" && loadFlag == false){
      toast.success("インポート致しました。", {autoClose:3000});
      setImportErrorData([])
    }
    csLoading(loadFlag);
  }, [loadFlag, importErrorData]);

  const csLoading = (flag) => {
    if (flag) {
      document.getElementById("csLoader").style.display = "flex";
    } else {
      document.getElementById("csLoader").style.display = "none";
    }
  };

  return (
    <div className={gStyle.display}>
      <div className={gStyle.loader_container} id="csLoader">
        <ClipLoader color={"#fff"} size={60} />
      </div>
      <ToastContainer />
      <div className={gStyle.border}>
        <p className={gStyle.text}>入出庫CSVインポート</p>
      </div>
      <div className={gStyle.filed}>
        <div className={style.cs_card}>
          <CsvImportDataCheckComponent
            url={jwtDefaultConfig.importItemsCsv}
            setErrorData={setImportErrorData}
            setLoadFlag={setLoadFlag}
            csvHeader={itemCsvHeader}
          />
          <div className={style.error_tag}>
            {importErrorData?.map((value, index) => (
              value != 'success' && 
              <p className={style.error_content} key={index}>
                {value}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemsImport;
