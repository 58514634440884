/* eslint-disable eqeqeq */
import style from './UserRegistory.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import { useNavigate, useLocation } from "react-router-dom";
import ImageUploading from "react-images-uploading";

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

import MenuPatternModal from '../../../components/common/modal/MenuPatternModal';

const UserRegistory = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    let userInformation = useLocation();
    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        if (Email == "") {
            toast.error("メールアドレスを入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (Id == "") {
            if (Password == "") {
                toast.error("パスワードを入力してください。", { autoClose: 3000 });
                setOpen(false);
                return;
            }
        }
        if (PatternId == "") {
            toast.error("メニューのパターンを入力します。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (FamilyNameKanji == "") {
            toast.error("氏名を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (FirstNameKanji == "") {
            toast.error("氏名を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (FamilyNameKana == "") {
            toast.error("氏名(かな)を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (FirstNameKana == "") {
            toast.error("氏名(かな)を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);
            if (menuPatternData['menu_worker_manage_flag_2'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }

            csloading(true);
            const data = {
                userID: userData['id'],
                sys_menu_pattern_id : PatternId
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMenuPatternEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    csloading(false);
                    if (response.status == 200) {
                        setCompany(response.data.data[0]['sys_company_id']);
                        setCompanyData(response.data.data);
                    }
                })
                .catch((error) => {
                    csloading(false);
                });

            let userInfo = userInformation.state;

            if (userInfo) {
                data['id'] = userInfo.user_id;
                setId(userInfo.user_id);
                url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstUserEndPoint;
                axios
                    .post(url, data, {
                        headers: {
                            Authorization: "Bearer " + useJwt.getToken(),
                        },
                    })
                    .then((response) => {
                        setLoaded(true);
                        if (response.status == 200) {
                            setEmail(response.data.data['email']);
                            setUserNo(response.data.data['user_no']);
                            setEmploymentType(response.data.data['employment_type']);

                            if (response.data.data['delegate_flag'] == "1") setDelegateFlag(true);
                            else setDelegateFlag(false);

                            setCompany(response.data.data['company_no']);
                            setOffice(response.data.data['office_no']);
                            setDepartment(response.data.data['department_no']);

                            if (response.data.data['role_worker_flag'] == "1") setWorkerFlag(true);
                            else setWorkerFlag(false);

                            if (response.data.data['role_trainman_flag'] == "1") setTrainmanFlag(true);
                            else setTrainmanFlag(false);

                            if (response.data.data['role_clerk_flag'] == "1") setClerkFlag(true);
                            else setClerkFlag(false);

                            if (response.data.data['role_dispatcher_flag'] == "1") setDispatcherFlag(true);
                            else setDispatcherFlag(false);

                            if (response.data.data['role_admin_flag'] == "1") setAdminFlag(true);
                            else setAdminFlag(false);

                            setWarehouseId(response.data.data['warehouse_id']);
                            setWarehouseCode(response.data.data['warehouse_code']);
                            setWarehouseName(response.data.data['warehouse_name']);
                            setFamilyNameKanji(response.data.data['user_family_nm_kanji']);
                            setFirstNameKanji(response.data.data['user_first_nm_kanji']);
                            setFamilyNameKana(response.data.data['user_family_nm_kana']);
                            setFirstNameKana(response.data.data['user_first_nm_kana']);
                            setUserTel1(response.data.data['user_tel_1']);
                            setUserTel2(response.data.data['user_tel_2'] == null ? '' : response.data.data['user_tel_2']);

                            setGender(response.data.data['user_gender']);

                            setBlood(response.data.data['user_blood_type'] == null ? "" : response.data.data['user_blood_type']);
                            setWarehouseId(response.data.data['warehouse_id']);
                            let zip = response.data.data['user_zip'];
                            if (zip != "") {
                                let zArray = zip.split('-');
                                setDistinationZip1(zArray[0]);
                                setDistinationZip2(zArray[1]);
                            }
                            setDistinationAddress1(response.data.data['user_address_1']);
                            setDistinationAddress2(response.data.data['user_address_2']);
                            setDistinationAddress3(response.data.data['user_address_3']);
                            setDistinationTown(response.data.data['user_town']);
                            setBuilddingName(response.data.data['user_building_name']);

                            setBirthDay(response.data.data['user_birthday']);
                            setInDate(response.data.data['company_joined_date']);
                            setOutDate(response.data.data['company_exited_date']);
                            setPatternId(response.data.data['sys_menu_pattern_id']);
                            setPassword("");
                            const data = {
                                userID: userData['id'],
                                sys_menu_pattern_id : response.data.data['sys_menu_pattern_id']
                            };
                            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMenuPatternEndpoint;
                            axios
                                .post(url, data, {
                                    headers: {
                                        Authorization: "Bearer " + useJwt.getToken(),
                                    },
                                })
                                .then((response) => {
                                    setLoaded(true);
                                    if (response.status == 200) {
                                        setPatternData(response.data.data[0]);
                                    }
                                })
                                .catch((error) => {
                                });
                
                                console.log(response.data.data['name_card_url'].length);
                            if(response.data.data['user_profile_url'].length > 1){
                                let tempImage = [];
                                
                                let tempUrl = response.data.data['user_profile_url'];
                                let index = tempUrl.indexOf('data:image');
                                tempImage['data_url'] = tempUrl.substring(index, tempUrl.length);
                                tempImage.push(tempImage);
                                setImages(tempImage);
                            }

                            if(response.data.data['name_card_url'].length > 1){
                                let tempImage = [];
                                
                                let tempUrl = response.data.data['name_card_url'];
                                let index = tempUrl.indexOf('data:image');
                                tempImage['data_url'] = tempUrl.substring(index, tempUrl.length);
                                tempImage.push(tempImage);
                                setNameImages(tempImage);
                            }

                            if(response.data.data['personal_history_url'].length > 1){
                                let tempImage = [];
                                
                                let tempUrl = response.data.data['personal_history_url'];
                                let index = tempUrl.indexOf('data:image');
                                tempImage['data_url'] = tempUrl.substring(index, tempUrl.length);
                                tempImage.push(tempImage);
                                setPersonalImages(tempImage);
                            }


                            if(response.data.data['personal_history_url'].length > 1){
                                let tempImage = [];
                                
                                let tempUrl = response.data.data['personal_history_url'];
                                let index = tempUrl.indexOf('data:image');
                                tempImage['data_url'] = tempUrl.substring(index, tempUrl.length);
                                tempImage.push(tempImage);
                                setCarImages(tempImage);
                            }
                        }
                        csloading(false);
                    })
                    .catch((error) => {
                        csloading(false);
                    });
            }
        }
    });

    // email set
    const [Id, setId] = useState("");

    // email set
    const [Email, setEmail] = useState("");
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    // password set
    const [Password, setPassword] = useState("");
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    }

    // user_no set
    const [UserNo, setUserNo] = useState("");
    const handleChangeUserNo = (event) => {
        setUserNo(event.target.value);
    }

    // employment_type set
    const [EmploymentType, setEmploymentType] = useState("0");
    const handleChangeEmploymentType = (event) => {
        setEmploymentType(event.target.value);
    }

    // delegate_flag set
    const [DelegateFlag, setDelegateFlag] = useState(0);
    const handleChangeDelegateFlag = (event) => {
        let val;
        if (event.target.checked) {
            val = 1;
        } else val = 0;

        setDelegateFlag(val);
    }

    // =================================================================================
    const [companyData, setCompanyData] = useState([]);
    const [officeData, setOfficeData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);

    // company set
    const [Company, setCompany] = useState("");
    const handleChangeCompany = (event) => {
        setCompany(event.target.value);
    }

    // office set
    const [Office, setOffice] = useState("");
    const handleChangeOffice = (event) => {
        setOffice(event.target.value);
    }

    // department set
    const [Department, setDepartment] = useState("");
    const handleChangeDepartment = (event) => {
        setDepartment(event.target.value);
    }

    // worker flag set
    const [WorkerFlag, setWorkerFlag] = useState(0);
    const handleChangeWorkerFlag = (event) => {
        let val;
        if (event.target.checked) {
            val = 1;
        } else val = 0;

        setWorkerFlag(val);
    }

    // trainman flag set
    const [TrainmanFlag, setTrainmanFlag] = useState(0);
    const handleChangeTrainmanFlag = (event) => {
        let val;
        if (event.target.checked) {
            val = 1;
        } else val = 0;

        setTrainmanFlag(val);
    }

    // clerk flag set
    const [ClerkFlag, setClerkFlag] = useState(0);
    const handleChangeClerkFlag = (event) => {
        let val;
        if (event.target.checked) {
            val = 1;
        } else val = 0;

        setClerkFlag(val);
    }

    // dispatcher flag set
    const [DispatcherFlag, setDispatcherFlag] = useState(0);
    const handleChangeDispatcherFlag = (event) => {
        let val;
        if (event.target.checked) {
            val = 1;
        } else val = 0;

        setDispatcherFlag(val);
    }

    // admin flag set
    const [AdminFlag, setAdminFlag] = useState(0);
    const handleChangeAdminFlag = (event) => {
        let val;
        if (event.target.checked) {
            val = 1;
        } else val = 0;

        setAdminFlag(val);
    }

    // FamilyNameKanji set
    const [FamilyNameKanji, setFamilyNameKanji] = useState("");
    const handleChangeFamilyNameKanji = (event) => {
        setFamilyNameKanji(event.target.value);
    }

    // FamilyNameKanji set
    const [FirstNameKanji, setFirstNameKanji] = useState("");
    const handleChangeFirstNameKanji = (event) => {
        setFirstNameKanji(event.target.value);
    }

    // FamilyNameKana set
    const [FamilyNameKana, setFamilyNameKana] = useState("");
    const handleChangeFamilyNameKana = (event) => {
        setFamilyNameKana(event.target.value);
    }

    // FamilyNameKana set
    const [FirstNameKana, setFirstNameKana] = useState("");
    const handleChangeFirstNameKana = (event) => {
        setFirstNameKana(event.target.value);
    }

    // user_tel_1 set
    const [UserTel1, setUserTel1] = useState("");
    const handleChangeUserTel1 = (event) => {
        setUserTel1(event.target.value);
    }

    // user_tel_2 set
    const [UserTel2, setUserTel2] = useState("");
    const handleChangeUserTel2 = (event) => {
        setUserTel2(event.target.value);
    }

    // birthday ---------------------------------------
    const [BirthDay, setBirthDay] = useState("");

    const handleChangeBirthDay = (date) => {
        setBirthDay(useJwt.formatDate(date));
    };

    // gender ---------------------------------------
    const [Gender, setGender] = useState("");

    const handleChangeGender = (event, param) => {
        if (param == 1) setGender("1");
        if (param == 2) setGender("2");
        if (param == 3) setGender("3");
    };

    // blood type ---------------------------------------
    const [Blood, setBlood] = useState("");

    const handleChangeBlood = (event, param) => {
        setBlood(param);
    };

    // 郵便番号
    const [distinationZip1, setDistinationZip1] = useState("");
    const handleChangeDistinationZip1 = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setDistinationZip1(str);
    };
    const [distinationZip2, setDistinationZip2] = useState("");
    const handleChangeDistinationZip2 = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setDistinationZip2(str);
    };
    const getAddress = (event) => {
        if (distinationZip1 != "" && distinationZip2 != "") {
            let googleApiUrl = "https://maps.googleapis.com/maps/api/geocode/json";
            let postcodeParam = "?components=postal_code:" + distinationZip1 + "-" + distinationZip2 + "&language=ja";
            let apiKey = "&key=" + useJwt.getGoogleApiKey();
            let url = googleApiUrl + postcodeParam + apiKey;
            csloading(true);
            axios
                .get(url, "")
                .then((response1) => {
                    if (response1.data.status == "OK") {
                        let address_components = response1.data.results[0]['address_components'];
                        let code_len = address_components.length;
                        let address1 = "";
                        if (address_components[code_len - 2]['long_name']) {
                            address1 = address_components[code_len - 2]['long_name'];
                        }
                        let address2 = "";
                        if (address_components[code_len - 3]['long_name'] && address_components[code_len - 3]['types'][0] != "postal_code") {
                            address2 = address_components[code_len - 3]['long_name'];
                        }

                        let town = "";
                        if(code_len > 4){
                          town = address_components[code_len - 4]['long_name'];
                        }else{
                            town = "工業団地";
                        }
    
                        setDistinationAddress1(address1);
                        setDistinationAddress2(address2);
                        setDistinationTown(town);
                    } else { // ZERO_RESULTS
                        setDistinationAddress1("");
                        setDistinationAddress2("");
                        setDistinationTown("");
                    }
                    csloading(false);
                })
                .catch((error) => {
                    csloading(false);
                });
        }
        event.preventDefault();
    }

    // 住所（都道府県）
    const [distinationAddress1, setDistinationAddress1] = useState("");
    const handleChangeDistinationAddress1 = (event) => {
        setDistinationAddress1(event.target.value);
    };

    const [distinationAddress2, setDistinationAddress2] = useState("");
    const handleChangeDistinationAddress2 = (event) => {
        setDistinationAddress2(event.target.value);
    };

    const [distinationAddress3, setDistinationAddress3] = useState("");
    const handleChangeDistinationAddress3 = (event) => {
        setDistinationAddress3(event.target.value);
    };

    const [distinationTown, setDistinationTown] = useState("");
    const handleChangeDistinationTown = (event) => {
        setDistinationTown(event.target.value);
    };

    const [builddingName, setBuilddingName] = useState("");
    const handleChangeBuilddingName = (event) => {
        setBuilddingName(event.target.value);
    };

    // in date ---------------------------------------
    const [InDate, setInDate] = useState("");

    const handleChangeInDate = (date) => {
        setInDate(useJwt.formatDate(date));
    };

    // out date ---------------------------------------
    const [OutDate, setOutDate] = useState("");

    const handleChangeOutDate = (date) => {
        setOutDate(useJwt.formatDate(date));
    };

    // warehouse --------------------------------------------------
    const [WarehouseId, setWarehouseId] = useState("");
    const [WarehouseCode, setWarehouseCode] = useState("");
    const handleChangeWarehouseCode = (event) => {
        setWarehouseCode(useJwt.validateNum(event.target.value, 2));
    };
    const [WarehouseName, setWarehouseName] = useState("");

    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWarehouseModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode;

        setWarehouseSearchValue(code);

        if (code != "") {
            let data = {};
            data['userID'] = userData['id'];
            if (code != "") {
                data['warehouse_code'] = code;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWarehouseTableData(response.data.data);
                    setShowModalWarehouse(true);
                })
                .catch((error) => {
                });
        } else {
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }

    const onKeyDownWarehouseCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value, param);
            document.getElementById('input6').focus();
            event.preventDefault();
        }
    };
    const getWarehouseData = (code, param) => {
        if (code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWarehouseData(result, param);
                        } else {
                            setWarehouseData([], param);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setWarehouseData([], param);
        }
    }
    const setWarehouseData = (data, param) => {
        if (data['warehouse_code']) {
            setWarehouseId(data['sys_warehouse_id']);
            setWarehouseCode(data['warehouse_code']);
            setWarehouseName(data['warehouse_name']);
        } else {
            setWarehouseId("");
            setWarehouseCode("");
            setWarehouseName("");
        }
    }

    const userRegistry = (event) => {
        event.preventDefault();
        const formData = new FormData();

        formData.append('email', Email);
        formData.append('password', Password);
        formData.append('user_no', UserNo);
        formData.append('employment_type', EmploymentType);
        formData.append('delegate_flag', DelegateFlag ? 1 : 0);
        // formData.append('company_no', Company);
        // formData.append('office_no', Office);
        // formData.append('department_no', Department);
        // formData.append('role_worker_flag', WorkerFlag ? 1 : 0);
        // formData.append('role_trainman_flag', TrainmanFlag ? 1 : 0);
        // formData.append('role_clerk_flag', ClerkFlag ? 1 : 0);
        // formData.append('role_dispatcher_flag', DispatcherFlag ? 1 : 0);
        // formData.append('role_admin_flag', AdminFlag ? 1 : 0);
        formData.append('warehouse_id', WarehouseId);
        formData.append('user_family_nm_kanji', FamilyNameKanji);
        formData.append('user_first_nm_kanji', FirstNameKanji);
        formData.append('user_family_nm_kana', FamilyNameKana);
        formData.append('user_first_nm_kana', FirstNameKana);
        formData.append('user_tel_1', UserTel1);
        formData.append('user_tel_2', UserTel2);
        formData.append('user_birthday', BirthDay);
        formData.append('user_gender', Gender);
        formData.append('user_blood_type', Blood);
        formData.append('user_zip', distinationZip1 + "-" + distinationZip2);
        formData.append('user_address_1', distinationAddress1);
        formData.append('user_address_2', distinationAddress2);
        formData.append('user_address_3', distinationAddress3);
        formData.append('user_town', distinationTown);
        formData.append('user_building_name', builddingName);
        formData.append('company_joined_date', InDate);
        formData.append('company_exited_date', OutDate);
        formData.append('sys_menu_pattern_id', PatternId);

        if (images.length > 0) formData.append('user_profile_url', images[0].data_url);
        if (NameImages.length > 0) formData.append('name_card_url', NameImages[0].data_url);
        if (PersonalImages.length > 0) formData.append('personal_history_url', PersonalImages[0].data_url);
        if (CarImages.length > 0) formData.append('license_url', CarImages[0].data_url);

        formData.append('userID', userData['id']);
        if (Id != "") formData.append('id', Id);


        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstUserEndPoint;

        csloading(true);
        axios
            .post(url, formData, {
                headers: {
                    'Authorization': "Bearer " + useJwt.getToken(),
                    'content-type': 'multipart/form-data'
                },
            })
            .then((response) => {
                let result = response.data.data;

                if (result > 0) {
                    if (Id == "") toast.success("登録致しました。", { autoClose: 3000 });
                    else toast.success("保存致しました。", { autoClose: 3000 });

                    navigate('/workerManagement/userIndex');
                } else {
                    if (Id == "") toast.error("登録に失敗しました。", { autoClose: 3000 });
                    else toast.error("保存に失敗しました。", { autoClose: 3000 });
                }
                csloading(false);
            })
            .catch((error) => {
                toast.error(error.response.data.message, { autoClose: 3000 });
                csloading(false);
            });

        setOpen(false);
    }

    //======================profile image upload=================================================
    const [images, setImages] = React.useState([]);
    const maxNumber = 69;
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);
    };

    //======================name image upload=================================================
    const [NameImages, setNameImages] = React.useState([]);
    const onNameImgChange = (imageList, addUpdateIndex) => {
        // data for submit
        setNameImages(imageList);
    };

    //======================personal image upload=================================================
    const [PersonalImages, setPersonalImages] = React.useState([]);
    const onPersonalChange = (imageList, addUpdateIndex) => {
        // data for submit
        setPersonalImages(imageList);
    };

    //======================car image upload=================================================
    const [CarImages, setCarImages] = React.useState([]);
    const onCarChange = (imageList, addUpdateIndex) => {
        // data for submit
        setCarImages(imageList);
    };
    // ========================== starts code for search ===============================
    // menu pattern ----------------------------------------------------
    const [PatternId, setPatternId] = useState("");
    const [PatternCode, setPatternCode] = useState("");
    const handleChangePatternCode = (event) => {
        setPatternCode(useJwt.validateNum(event.target.value,4));
    };
    const [PatternName, setPatternName] = useState("");
    const handleChangePatternName = (event) => {
        setPatternName(event.target.value);
    };

    const onKeyDownPatternCode = (event) => {
        if(event.keyCode === 13) { // enter key
            document.getElementById('input5').focus();
            if(event.target.value != "") {
                getPatternData(event.target.value);
                event.preventDefault();
            }else{
                setPatternData([]);
                event.preventDefault();
            }
        }
    };

    const getPatternData = (value) => {
        const data_r = {
            userID: userData['id'],
            pattern_code: value
        };
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMenuPatternEndpoint;
        axios
        .post(url, data_r, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response1) => {
            let t_data = response1.data.data;
            if(t_data.length > 0) {
                setPatternData(t_data[0]);
            }else{
                setPatternData([]);
            }
        })
        .catch((error) => {
        });
    }

    const setPatternData = (data) => {
        if(data['pattern_code'] != null) {
            setPatternId(data['sys_menu_pattern_id']);
            setPatternCode(data['pattern_code']);
            setPatternName(data['pattern_name']);
        }else{
            setPatternId("");
            setPatternCode("");
            setPatternName("");
        }
    }

    const [showModalMenuPattern, setShowModalMenuPattern] = useState(false);
    const [MenuPatternSearchValue, setMenuPatternSearchValue] = useState("");
    const [MenuPatternTableData, setMenuPatternTableData] = useState([]);
    const loadMenuPatternModalData = (event,param) => {
        event.preventDefault();
        let code = PatternCode;
        setMenuPatternSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['item_type_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMenuPatternEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setMenuPatternTableData(response.data.data);
                setShowModalMenuPattern(true);
            })
            .catch((error) => {
            });
        }else{
            setMenuPatternTableData([]);
            setShowModalMenuPattern(true);
        }
    }

    const onDataKeyDown = (e, param) => {
        if(e.keyCode == 13){
            switch(param){
                case 1 :
                    document.getElementById('input1').focus();
                    break;
                case 2 :
                    document.getElementById('input2').focus();
                    break;
                case 3 :
                    document.getElementById('input3').focus();
                    break;
                case 4 :
                    document.getElementById('PatternCode').focus();
                    break;
                case 5 :
                    document.getElementById('input5').focus();
                    break;
                case 6 :
                    document.getElementById('input6').focus();
                    break;
                case 7 :
                    document.getElementById('input7').focus();
                    break;
                case 8 :
                    document.getElementById('input8').focus();
                    break;
                case 9 :
                    document.getElementById('input9').focus();
                    break;
                case 10 :
                    document.getElementById('input10').focus();
                    break;
                    case 11 :
                        document.getElementById('input11').focus();
                        break;
                    case 12 :
                        document.getElementById('input12').focus();
                        break;
                    case 13 :
                        document.getElementById('input13').focus();
                        break;
                    case 14 :
                        document.getElementById('input14').focus();
                        break;
                    case 15 :
                        document.getElementById('input15').focus();
                        break;
                    case 16 :
                        document.getElementById('input16').focus();
                        break;
                    case 17 :
                        document.getElementById('input17').focus();
                        break;
                    case 18 :
                        document.getElementById('input18').focus();
                        break;
                    case 19 :
                        document.getElementById('input19').focus();
                        break;
                    case 20 :
                        document.getElementById('input20').focus();
                        break;
                    default :
            }
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row, style.job_row_align];
    const style_row_radio_group = [gstyle.cs_row, style.job_row_align, style.radio_group_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_img_6 = [gstyle.cs_col, gstyle.cs_col_6, style.image_row];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];

    const style_input_bl = [gstyle.cs_input];
    const style_select_40 = [gstyle.cs_select, gstyle.w30px];
    const style_col_checkbox = [gstyle.cs_col, gstyle.cs_col_2, style.job_row_align];
    const style_col_checkbox_group = [gstyle.cs_col, gstyle.cs_col_8, style.job_row_align];

    const style_useful = [style.useful_display, style.cs_span];

    const style_col_span = [gstyle.cs_col_3, style.job_span];

    const zip_but = [gstyle.btn_new, style.distination_zip_btn_wrap];
    const style_place = [gstyle.cs_input, style.address_place_width];
    const style_sub_place = [gstyle.cs_input, style.address_sub_place_width];
    const style_address_label = [gstyle.cs_label, style.address_label_width];
    const style_address_col_4 = [gstyle.cs_col, gstyle.cs_col_4, style.col_padding_left];
    const style_address_col_6 = [gstyle.cs_col, gstyle.cs_col_6, style.col_padding_left];

    const style_drag_drop = [style.op_plus];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_check_ml = [style.checkbox_margin, style.check_label];
    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WarehouseModal show={showModalWarehouse} onClose={() => setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue}
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <MenuPatternModal show={showModalMenuPattern} onClose={()=>setShowModalMenuPattern(false)} width={'70%'}
                SearchValue={MenuPatternSearchValue} onChange={setMenuPatternSearchValue} 
                tableData={MenuPatternTableData} setTableData={setMenuPatternTableData}
                target={setPatternData} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>
                    {Id > 0 && "社員情報編集"}
                    {Id == "" && "社員新規登録"}
                </p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* email */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>メールアドレス</span>
                            <span className={style_useful.join(" ")}>必須</span>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <input type={'text'} 
                                id='input1' onKeyDown={(e)=>onDataKeyDown(e, 2)}
                                value={Email} className={style_input_bl.join(" ")} onChange={(e) => handleChangeEmail(e)}></input>
                            </div>
                        </div>
                    </div>
                    {/* password */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>パスワード</span>
                            {
                                Id != "" ? '' 
                                :
                                <span className={style_useful.join(" ")}>必須</span>
                            }
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <input type={'password'} 
                                id='input2' onKeyDown={(e)=>onDataKeyDown(e, 3)}
                                value={Password} className={style_input_bl.join(" ")} onChange={(e) => handleChangePassword(e)}></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={gstyle.cs_card_1}>
                    {/* user_no */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>社員番号</span>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <input type={'text'} value={UserNo} 
                                id='input3' onKeyDown={(e)=>onDataKeyDown(e, 4)}
                                className={style_input_bl.join(" ")} onChange={(e) => handleChangeUserNo(e)}></input>
                            </div>
                        </div>
                    </div>
                    {/* employment_type */}
                    {/* <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>雇用形態</span>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <select className={style_select_40.join(" ")} id="EmploymentType"
                                    value={EmploymentType}
                                    onChange={handleChangeEmploymentType}>
                                    <option value="0">役員</option>
                                    <option value="1">正社員</option>
                                    <option value="2">契約社員</option>
                                    <option value="3">パート</option>
                                    <option value="4">アルバイト</option>
                                </select>
                            </div>
                        </div>
                        <div className={style_col_checkbox.join(" ")}>
                            <label className={style.check_label}>
                                <input type={'checkbox'} onChange={(e) => handleChangeDelegateFlag(e)} checked={DelegateFlag ? true : false} />
                                <div>代表者</div>
                            </label>
                        </div>
                    </div> */}
                    {/* メニューパターン */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>メニューパターン</span>
                            <span className={style_useful.join(" ")}>必須</span>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} 
                                        className={style_input_100px.join(" ")} id="PatternCode"
                                            value={PatternCode}
                                            onKeyDown={(e) => onKeyDownPatternCode(e)}
                                            onChange={handleChangePatternCode}/>
                                        <input type={'text'} className={style_input_bl_n.join(" ")} id="PatternName"
                                            value={PatternName} 
                                            onChange={handleChangePatternName} disabled/>
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadMenuPatternModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* worker, shipper ... */}
                    {/* <div className={style_row_radio_group.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>役割</span>
                        </div>
                        <div className={style_col_checkbox_group.join(" ")}>
                            <label className={style.check_label}>
                                <input type={'checkbox'} onChange={(e) => handleChangeWorkerFlag(e)} checked={WorkerFlag == 1 ? true : false}></input>
                                <div>作業</div>
                            </label>
                            <label className={style_check_ml.join(" ")}>
                                <input type={'checkbox'} onChange={(e) => handleChangeTrainmanFlag(e)} checked={TrainmanFlag == 1 ? true : false}></input>
                                <div>乗務員</div>
                            </label>
                            <label className={style_check_ml.join(" ")}>
                                <input type={'checkbox'} onChange={(e) => handleChangeClerkFlag(e)} checked={ClerkFlag == 1 ? true : false}></input>
                                <div>事務員</div>
                            </label>
                            <label className={style_check_ml.join(" ")}>
                                <input type={'checkbox'} onChange={(e) => handleChangeDispatcherFlag(e)} checked={DispatcherFlag == 1 ? true : false}></input>
                                <div>配車</div>
                            </label>
                            <label className={style_check_ml.join(" ")}>
                                <input type={'checkbox'} onChange={(e) => handleChangeAdminFlag(e)} checked={AdminFlag == 1 ? true : false}></input>
                                <div>管理者</div>
                            </label>
                        </div>
                    </div> */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>倉庫</span>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e, 1)} 
                                            id='input5'
                                            value={WarehouseCode} onChange={handleChangeWarehouseCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadWarehouseModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* kanji name */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>氏名</span>
                            <span className={style_useful.join(" ")}>必須</span>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <input type={'text'} 
                                        id='input6' onKeyDown={(e)=>onDataKeyDown(e, 7)}
                                value={FamilyNameKanji} className={style_input_bl.join(" ")} onChange={(e) => handleChangeFamilyNameKanji(e)}></input>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <input type={'text'}
                                        id='input7' onKeyDown={(e)=>onDataKeyDown(e, 8)}
                                 value={FirstNameKanji} className={style_input_bl.join(" ")} onChange={(e) => handleChangeFirstNameKanji(e)}></input>
                            </div>
                        </div>
                    </div>
                    {/* kana name */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>かな</span>
                            <span className={style_useful.join(" ")}>必須</span>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <input type={'text'}
                                        id='input8' onKeyDown={(e)=>onDataKeyDown(e, 9)}
                                 value={FamilyNameKana} className={style_input_bl.join(" ")} onChange={(e) => handleChangeFamilyNameKana(e)}></input>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <input type={'text'}
                                        id='input9' onKeyDown={(e)=>onDataKeyDown(e, 10)}
                                 value={FirstNameKana} className={style_input_bl.join(" ")} onChange={(e) => handleChangeFirstNameKana(e)}></input>
                            </div>
                        </div>
                    </div>
                    {/* プロフィール画像(profile img) */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>プロフィール画像</span>
                        </div>
                        <div className={style_col_img_6.join(" ")}>
                            <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url"
                                acceptType={["jpg", "jpeg", "png"]}>
                                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                                    <div className="upload__image-wrapper">
                                        {imageList?.length == 0 &&
                                            <div className={style_drag_drop} onClick={onImageUpload} {...dragProps}>
                                                <p>ここからファイルを選択して登録</p>
                                                <p>ここにファイルをドロップして登録</p>
                                            </div>
                                        }

                                        {imageList?.map((image, index) => (
                                            <div key={index} className={style.image_item}>
                                                <img className={style.image_size} src={image.data_url} alt="" width="100" onClick={() => onImageUpdate(index)} />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </ImageUploading>
                        </div>
                    </div>

                    {/* 名刺(name img) */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>名刺</span>
                        </div>
                        <div className={style_col_img_6.join(" ")}>
                            <ImageUploading multiple value={NameImages} onChange={onNameImgChange} maxNumber={maxNumber} dataURLKey="data_url"
                                acceptType={["jpg", "jpeg", "png"]}>
                                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                                    <div className="upload__image-wrapper">
                                        {imageList?.length == 0 &&
                                            <div className={style_drag_drop} onClick={onImageUpload} {...dragProps}>
                                                <p>ここからファイルを選択して登録</p>
                                                <p>ここにファイルをドロップして登録</p>
                                            </div>
                                        }

                                        {imageList?.map((image, index) => (
                                            <div key={index} className={style.image_item}>
                                                <img className={style.image_size} src={image.data_url} alt="" width="100" onClick={() => onImageUpdate(index)} />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </ImageUploading>
                        </div>
                    </div>
                    {/* user_tel_1 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>電話番号1</span>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <input type={'text'}
                                        id='input10' onKeyDown={(e)=>onDataKeyDown(e, 11)}
                                 value={UserTel1} className={style_input_bl.join(" ")} onChange={(e) => handleChangeUserTel1(e)}></input>
                            </div>
                        </div>
                    </div>
                    {/* user_tel_2 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>電話番号2</span>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <input type={'text'} 
                                        id='input11' onKeyDown={(e)=>onDataKeyDown(e, 12)}
                                value={UserTel2} className={style_input_bl.join(" ")} onChange={(e) => handleChangeUserTel2(e)}></input>
                            </div>
                        </div>
                    </div>
                    {/* 生年月日 */}
                    <div className={style_row_radio_group.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>生年月日</span>
                        </div>
                        <div className={style_col_2.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <InputDatepicker value={BirthDay} onChange={handleChangeBirthDay} id='input12' focusId='input13' />
                            </div>
                        </div>
                    </div>
                    {/* 名刺(personal img) */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>履歴書</span>
                        </div>
                        <div className={style_col_img_6.join(" ")}>
                            <ImageUploading multiple value={PersonalImages} onChange={onPersonalChange} maxNumber={maxNumber} dataURLKey="data_url"
                                acceptType={["jpg", "jpeg", "png"]}>
                                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                                    <div className="upload__image-wrapper">
                                        {imageList?.length == 0 &&
                                            <div className={style_drag_drop} onClick={onImageUpload} {...dragProps}>
                                                <p>ここからファイルを選択して登録</p>
                                                <p>ここにファイルをドロップして登録</p>
                                            </div>
                                        }

                                        {imageList?.map((image, index) => (
                                            <div key={index} className={style.image_item}>
                                                <img className={style.image_size} src={image.data_url} alt="" width="100" onClick={() => onImageUpdate(index)} />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </ImageUploading>
                        </div>
                    </div>
                    {/* 名刺(car img) */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>免許証</span>
                        </div>
                        <div className={style_col_img_6.join(" ")}>
                            <ImageUploading multiple value={CarImages} onChange={onCarChange} maxNumber={maxNumber} dataURLKey="data_url"
                                acceptType={["jpg", "jpeg", "png"]}>
                                {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
                                    <div className="upload__image-wrapper">
                                        {imageList?.length == 0 &&
                                            <div className={style_drag_drop} onClick={onImageUpload} {...dragProps}>
                                                <p>ここからファイルを選択して登録</p>
                                                <p>ここにファイルをドロップして登録</p>
                                            </div>
                                        }

                                        {imageList?.map((image, index) => (
                                            <div key={index} className={style.image_item}>
                                                <img className={style.image_size} src={image.data_url} alt="" width="100" onClick={() => onImageUpdate(index)} />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </ImageUploading>
                        </div>
                    </div>
                    {/* gender */}
                    <div className={style_row_radio_group.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>性別</span>
                        </div>
                        <div className={style_col_checkbox_group.join(" ")}>
                            <label className={style.check_label}>
                                <input type={'radio'} onChange={(e) => handleChangeGender(e, 1)} checked={Gender == 1 ? true : false}></input>
                                <div>男性</div>
                            </label>
                            <label className={style_check_ml.join(" ")}>
                                <input type={'radio'} onChange={(e) => handleChangeGender(e, 2)} checked={Gender == 2 ? true : false}></input>
                                <div>女性</div>
                            </label>
                            <label className={style_check_ml.join(" ")}>
                                <input type={'radio'} onChange={(e) => handleChangeGender(e, 3)} checked={Gender == 3 ? true : false}></input>
                                <div>不明</div>
                            </label>
                        </div>
                    </div>
                    {/* blood type */}
                    <div className={style_row_radio_group.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>血液型</span>
                        </div>
                        <div className={style_col_checkbox_group.join(" ")}>
                            <label className={style.check_label}>
                                <input type={'radio'} onChange={(e) => handleChangeBlood(e, 1)} checked={Blood == 1 ? true : false}></input>
                                <div>A型</div>
                            </label>
                            <label className={style_check_ml.join(" ")}>
                                <input type={'radio'} onChange={(e) => handleChangeBlood(e, 2)} checked={Blood == 2 ? true : false}></input>
                                <div>B型</div>
                            </label>
                            <label className={style_check_ml.join(" ")}>
                                <input type={'radio'} onChange={(e) => handleChangeBlood(e, 3)} checked={Blood == 3 ? true : false}></input>
                                <div>O型</div>
                            </label>
                            <label className={style_check_ml.join(" ")}>
                                <input type={'radio'} onChange={(e) => handleChangeBlood(e, 4)} checked={Blood == 4 ? true : false}></input>
                                <div>AB型</div>
                            </label>
                        </div>
                    </div>
                    {/*郵便番号 zip*/}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>郵便番号</span>
                        </div>
                        <div className={style_address_col_4.join(" ")}>
                            <div className={gstyle.cs_flex}>
                                <input type={'number'} className={gstyle.cs_input} value={distinationZip1}
                                        id='input13' onKeyDown={(e)=>onDataKeyDown(e, 14)}
                                    onChange={handleChangeDistinationZip1} />
                                <span className={style.cs_min_span}>-</span>
                                <input type={'number'} className={gstyle.cs_input} value={distinationZip2}
                                        id='input14' onKeyDown={(e)=>onDataKeyDown(e, 15)}
                                    onChange={handleChangeDistinationZip2} />
                            </div>
                        </div>
                        <div className={style_col_2.join(" ")}>
                            <button className={zip_but.join(" ")} onClick={getAddress} >郵便検索</button>
                        </div>
                    </div>
                    {/*郵便番号 zip*/}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                        </div>
                        <div className={style_address_col_6.join(" ")}>
                            <div className={gstyle.cs_flex}>
                                <label className={gstyle.cs_label}>都道府県
                                    <input type={'text'} className={style_place.join(" ")} value={distinationAddress1}
                                        id='input15' onKeyDown={(e)=>onDataKeyDown(e, 16)}
                                        onChange={handleChangeDistinationAddress1} />
                                </label>

                                <label className={gstyle.cs_label}>市区町村
                                    <input type={'text'} className={style_place.join(" ")} value={distinationAddress2}
                                        id='input16' onKeyDown={(e)=>onDataKeyDown(e, 17)}
                                        onChange={handleChangeDistinationAddress2} />
                                </label>

                                <label className={gstyle.cs_label}>町名
                                    <input type={'text'} className={style_place.join(" ")} value={distinationTown}
                                        id='input16' onKeyDown={(e)=>onDataKeyDown(e, 171)}
                                        onChange={handleChangeDistinationTown} />
                                </label>
                            </div>
                        </div>
                    </div>

                    {/*郵便番号 zip*/}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>住所</span>
                        </div>
                        <div className={style_address_col_6.join(" ")}>
                            <div className={gstyle.cs_flex}>
                                <label className={style_address_label.join(" ")}>丁目・番地
                                    <input type={'text'} className={style_sub_place.join(" ")} value={distinationAddress3}
                                        id='input17' onKeyDown={(e)=>onDataKeyDown(e, 18)}
                                        onChange={handleChangeDistinationAddress3} />
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*郵便番号 zip*/}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                        </div>
                        <div className={style_address_col_6.join(" ")}>
                            <div className={gstyle.cs_flex}>
                                <label className={style_address_label.join(" ")}>建物名
                                    <input type={'text'} className={style_sub_place.join(" ")} value={builddingName}
                                        id='input18' onKeyDown={(e)=>onDataKeyDown(e, 19)}
                                        onChange={handleChangeBuilddingName} />
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 入社日 */}
                    <div className={style_row_radio_group.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>入社日</span>
                        </div>
                        <div className={style_col_2.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <InputDatepicker value={InDate} onChange={handleChangeInDate} id='input19' focusId='input20' />
                            </div>
                        </div>
                    </div>

                    {/* 退職日 */}
                    <div className={style_row_radio_group.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>退職日</span>
                        </div>
                        <div className={style_col_2.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <InputDatepicker value={OutDate} onChange={handleChangeOutDate} id='input20' focusId='registryBut' />
                            </div>
                        </div>
                    </div>
                    {/* button 登録 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='registryBut' onClick={handleOpen}>{Id == "" && "登 録"}{Id != "" && "保 存"}</button>
                        <button className={gstyle.btn_delete} onClick={(e) => navigate('/workerManagement/userIndex')}>戻 る</button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={userRegistry} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

        </div>
    )
}

export default UserRegistory;