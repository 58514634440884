import React from 'react';
import style from "./Driver.module.css";
import {Card} from "antd";
import {Button} from "antd-mobile";
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import * as useJwt  from '../../auth/jwt/useJwt.js';
import moment from "moment";

function ScheduleView() {

  let urlParams = useLocation();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);

  useEffect(()=>{
    setTimeout(() => {
      let current_date = moment().format("YYYY-MM-DD");
      let current_time = moment().format("YYYY-MM-DD HH:mm:ss");

      var diff_ms = moment(current_time).diff(current_date, "seconds");

      setDisplayTime(secondToHours(diff_ms));
    }, 1000);

    if(!loaded){
      setLoaded(true);
      if(urlParams){
        let data = urlParams.state.data;
        setBusName(data.bus_name);
        let temp = data.start_date_time.split(' ');
        if(temp.length > 0){
          setScheduleTime(temp[1]);
        }

      }
    }

  })

  const secondToHours = (ms) => {
    var hours = Math.floor(ms / 3600);
    var minutes = Math.floor((ms - hours * 3600) / 60);
    var seconds = ms - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return hours + ":" + minutes + ":" + seconds;
  };

  const [displayTime, setDisplayTime] = useState(false);
  const [busName, setBusName] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");

  const handleNext = ( e ) => {
    let data = urlParams.state.data;
    navigate("/driver/driverWait", {
      state: {
        data : data,
        companyName: urlParams.state.companyName,
        warehouseName : urlParams.state.warehouseName,
      },
      replace: false,
    });

  }
  //style

  const style_header = [style.content_flex_column, style.title, style.content_align_center, style.mt70];
  const style_content = [style.content_flex_column, style.mt30, style.content_align_center];
  const style_content_but = [style.content_flex_column, style.mt70, style.but_gap, style.content_align_center];

  return (
    <div className={style.content_flex_column}>
      <div className={style_header.join(" ")}>
        <label>{urlParams.state.companyName}予約システム</label>
        <label className={style.sub_title}>{urlParams.state.warehouseName}</label>
      </div>

      <div style={{margin:'0 40px'}}>
        <div className={style.card_head}>
          バース予約状況
        </div>
        <div className={style.card_body}>
          <p>{busName}番バース</p>
          <p className={style.mt30}>予定入庫時間</p>
          <p className={style.time_label}>{scheduleTime}</p>
          <p className={style.mt30}>現在時刻</p>
          <p className={style.time_label}>{displayTime}</p>
        </div>
      </div>

      <div className={style_content_but.join(" ")}>
        <Button className={style.button} size='small' color='primary' fill='outline' onClick={(e) => handleNext(e)}>次へ</Button>
      </div>

    </div>
  );
}

export default ScheduleView;
