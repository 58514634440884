import style from './LinkButton.module.css'
import { Link } from 'react-router-dom'

const LinkButton = ({link, text, underText}) => {
	return (
		<>
			<Link to={link} className={style.link}>
				<p>{text}</p>
				<p className={style.under_text}>{underText}</p>
			</Link>
    	</>
	);
};

export default LinkButton;