/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import style from "./RegistryBoth.module.css";
import gstyle from "../../css/GeneralStyle.module.css";
import axios from "axios";
import * as useJwt from "../../auth/jwt/useJwt.js";

import { Button } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import IconSearch from "../../components/common/icons/icon_search.png";
import CategoryModal from "../../components/common/modal/CategoryModal";
import $ from 'jquery';

import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

const RegistryShipperNext = (props) => {
  const navigate = useNavigate();
  let userInformation = useLocation();

  const [open, setOpen] = React.useState(false);
  const [CategoryCode1, setCategoryCode1] = useState("");
  const [CategoryName1, setCategoryName1] = useState("");
  const [CategoryCode2, setCategoryCode2] = useState("");
  const [CategoryName2, setCategoryName2] = useState("");
  const [CategoryCode3, setCategoryCode3] = useState("");
  const [CategoryName3, setCategoryName3] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    setShipperId(userInformation.state.data.id);
    setCategoryCode2(userInformation.state.data.category_code_2);
    setCategoryCode3(userInformation.state.data.category_code_3);
    setMultiArrivalType(userInformation.state.data.multi_arrival_type);
    setStockEmptyType(userInformation.state.data.stock_empty_type);
    setZeroContainFlag(userInformation.state.data.zero_contain_flag);
    setZeroContainItemFlag(userInformation.state.data.zero_contain_item_flag);
    setShipperSetType(userInformation.state.data.shipper_set_type);
    setShipperPrintFlag(userInformation.state.data.shipper_print_flag);
    setShipperHonor(userInformation.state.data.shipper_honor);
    
    if (userInformation.state.data.category_code_1 != null) {
      setCategoryCode1(userInformation.state.data.category_code_1);
      const data3 = {
        // userID: userData["id"],
        category_code: userInformation.state.data.category_code_1,
      };
      let url3 =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
      axios
        .post(url3, data3, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              let result1 = response.data[0];
              setCategoryName1(result1["category_name"]);
            }
          }
        })
        .catch((error) => {});
    }
    if (userInformation.state.data.category_code_2 != null) {
      setCategoryCode2(userInformation.state.data.category_code_2);
      const data3 = {
        // userID: userData["id"],
        category_code: userInformation.state.data.category_code_2,
      };
      let url3 =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
      axios
        .post(url3, data3, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              let result1 = response.data[0];
              setCategoryName2(result1["category_name"]);
            }
          }
        })
        .catch((error) => {});
    }
    if (userInformation.state.data.category_code_3 != null) {
      setCategoryCode3(userInformation.state.data.category_code_3);
      const data3 = {
        // userID: userData["id"],
        category_code: userInformation.state.data.category_code_3,
      };
      let url3 =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
      axios
        .post(url3, data3, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              let result1 = response.data[0];
              setCategoryName3(result1["category_name"]);
            }
          }
        })
        .catch((error) => {});
    }

  }, [props, userInformation])
  const csLoading = (flag) => {
    if (flag) {
      document.getElementById("csLoader").style.display = "flex";
    } else {
      document.getElementById("csLoader").style.display = "none";
    }
  };

  const [ShipperId, setShipperId] = React.useState("");
  const saveData = (event) => {
    event.preventDefault();

    let data = {};
    data["id"] = ShipperId;
    data["shipper_honor"] = ShipperHonor;

    // data["category_code_1"] = CategoryCode1;
    // data["category_code_2"] = CategoryCode2;
    // data["category_code_3"] = CategoryCode3;

    data["multi_arrival_type"] = MultiArrivalType;
    data["stock_empty_type"] = StockEmptyType;
    data["zero_contain_item_flag"] = ZeroContainItemFlag;
    data["zero_contain_flag"] = ZeroContainFlag;
    data["shipper_set_type"] = ShipperSetType;
    data["shipper_print_flag"] = ShipperPrintFlag;

    console.log( useJwt.getToken());
    let url =
      jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstShipperUserEndpoint;
    csLoading(true);
    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status == 200) {
          const data = {
            email: userInformation.state.data.email,
            password: userInformation.state.password,
          };
      
          axios
            .post(useJwt.getShipperLoginEndpoint(), data)
            .then((response) => {
              const userData = response.data.data;
              useJwt.setToken(response.data.token);
      
              useJwt.setShipperUserData(JSON.stringify(userData));
              navigate("/registry/shipper/end")
      
              // const data = {
              //   userID: userData["id"],
              //   sys_menu_pattern_id: userData["sys_menu_pattern_id"],
              // };
              // let url =
              //   jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperMenuPatternEndpoint;
              // axios
              //   .post(url, data, {
              //     headers: {
              //       Authorization: "Bearer " + useJwt.getToken(),
              //     },
              //   })
              //   .then((response2) => {
              //     if (response2.status == 200) {
              //       if (response2.data.data.length > 0) {
              //         let result = response2.data.data[0];
              //         useJwt.setMenuPatternData(JSON.stringify(result));
              //       }
              //     }
              //   })
              //   .catch((error) => {});
            })
            .catch((error) => {
              // console.log(error.message);
              toast.error(error.message, { autoClose: 3000 });
            });
        }
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
        toast.error("登録に失敗しました。", { autoClose: 3000 });
      });
    setOpen(false);

  };

  const handleChangeCategoryCode = (event, param) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 3) {
      str = str.substring(0, 3);
    }
    if (!z1.test(str)) {
      str = "";
    }
    if (param == 1) {
      setCategoryCode1(str);
    } else if (param == 2) {
      setCategoryCode2(str);
    } else {
      setCategoryCode3(str);
    }
  };

  // ----------------------------- category modal -------------------------------------
  const [showModalCategory, setShowModalCategory] = useState(false);
  const [CategorySearchValue, setCategorySearchValue] = useState("");
  const [CategoryTableData, setCategoryTableData] = useState([]);

  const loadCategoryModalData = (event, param) => {
    event.preventDefault();
    let code = document.getElementById("CategoryCode" + param).value;
    setCategorySearchValue(code);
    $('#selectedId').val("CategoryCode" + param);
    // document.getElementById("selectedId").value = "CategoryCode" + param;
    if (code != "") {
      let data = {};
      if (code != "") {
        data["category_code"] = code;
      }

      let url =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          setCategoryTableData(response.data);
          setShowModalCategory(true);
        })
        .catch((error) => {});
    } else {
      setCategoryTableData([]);
      setShowModalCategory(true);
    }
  };
  const setCategoryData = (data) => {
    let resultCode = data["category_code"];
    let resultName = data["category_name"];
    if (document.getElementById("selectedId").value == "CategoryCode1") {
      setCategoryCode1(resultCode);
      setCategoryName1(resultName);
    } else if (document.getElementById("selectedId").value == "CategoryCode2") {
      setCategoryCode2(resultCode);
      setCategoryName2(resultName);
    } else {
      setCategoryCode3(resultCode);
      setCategoryName3(resultName);
    }
  };

  const [ShipperHonor, setShipperHonor] = useState(0);
  const handleChangeShipperHonor = (event) => {
    setShipperHonor(event.target.value);
  };

  // style array
  const style_row = [gstyle.cs_row];
  const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
  const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
  const style_input_wrap = [gstyle.p0015];
  const style_input_100px = [gstyle.cs_input, gstyle.w100px];
  const style_select_245px = [gstyle.cs_select, gstyle.w245px];

  const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

  const [MultiArrivalType, setMultiArrivalType] = useState(0);
  const handleChangeMultiArrivalType = (event) => {
    setMultiArrivalType(event.target.value);
  };
  const [StockEmptyType, setStockEmptyType] = useState(0);
  const handleChangeStockEmptyType = (event) => {
    setStockEmptyType(event.target.value);
  };
  const [ZeroContainItemFlag, setZeroContainItemFlag] = useState(0);
  const handleChangeZeroContainItemFlag = (event) => {
    setZeroContainItemFlag(event.target.value);
  };
  const [ZeroContainFlag, setZeroContainFlag] = useState(0);
  const handleChangeZeroContainFlag = (event) => {
    setZeroContainFlag(event.target.value);
  };
  const [ShipperSetType, setShipperSetType] = useState(0);
  const handleChangeShipperSetType = (event) => {
    setShipperSetType(event.target.value);
  };
  const [ShipperPrintFlag, setShipperPrintFlag] = useState(0);
  const handleChangeShipperPrintFlag = (event) => {
    setShipperPrintFlag(event.target.value);
  };

  return (
    <div className={style.registerBackground} style={{ height: "100%" }}>
      <div className={gstyle.loader_container} id="csLoader">
        <ClipLoader color={"#fff"} size={60} />
      </div>
      <input type="hidden" id="selectedId"/>
      <CategoryModal
        show={showModalCategory}
        onClose={() => setShowModalCategory(false)}
        width={"70%"}
        SearchValue={CategorySearchValue}
        onChange={setCategorySearchValue}
        tableData={CategoryTableData}
        setTableData={setCategoryTableData}
        target={setCategoryData}
      />
      <form className={style.inputSubForm}>
        <div className={style.title}>
          <span>荷主登録</span>
        </div>
        <div className={style.inputSubForm}>
          {/* <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>分類コード①</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"number"}
                      className={style_input_100px.join(" ")}
                      id="CategoryCode1"
                      value={CategoryCode1}
                      onChange={(e) => handleChangeCategoryCode(e, 1)}
                    />
                    <input
                      type={"text"}
                      className={style_input_bl_n.join(" ")}
                      disabled
                      id="CategoryName1"
                      value={CategoryName1}
                    />
                    <div
                      className={gstyle.icon_search_wrap}
                      onClick={(e) => loadCategoryModalData(e, 1)}
                    >
                      <img
                        src={IconSearch}
                        className={gstyle.icon_search}
                        alt="icon"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>分類コード②</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"number"}
                      className={style_input_100px.join(" ")}
                      id="CategoryCode2"
                      value={CategoryCode2}
                      onChange={(e) => handleChangeCategoryCode(e, 2)}
                    />
                    <input
                      type={"text"}
                      className={style_input_bl_n.join(" ")}
                      disabled
                      id="CategoryName2"
                      value={CategoryName2}
                    />
                    <div
                      className={gstyle.icon_search_wrap}
                      onClick={(e) => loadCategoryModalData(e, 2)}
                    >
                      <img
                        src={IconSearch}
                        className={gstyle.icon_search}
                        alt="icon"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>分類コード③</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"number"}
                      className={style_input_100px.join(" ")}
                      id="CategoryCode3"
                      value={CategoryCode3}
                      onChange={(e) => handleChangeCategoryCode(e, 3)}
                    />
                    <input
                      type={"text"}
                      className={style_input_bl_n.join(" ")}
                      disabled
                      id="CategoryName1"
                      value={CategoryName3}
                    />
                    <div
                      className={gstyle.icon_search_wrap}
                      onClick={(e) => loadCategoryModalData(e, 3)}
                    >
                      <img
                        src={IconSearch}
                        className={gstyle.icon_search}
                        alt="icon"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div> */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>重複入庫</span>
                  <div className={gstyle.cs_flex}>
                    <select
                      className={style_select_245px.join(" ")}
                      value={MultiArrivalType}
                      onChange={handleChangeMultiArrivalType}
                    >
                      <option value="0">許可</option>
                      <option value="1">警告</option>
                      <option value="2">禁止</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>在庫無出庫</span>
                  <div className={gstyle.cs_flex}>
                    <select
                      className={style_select_245px.join(" ")}
                      value={StockEmptyType}
                      onChange={handleChangeStockEmptyType}
                    >
                      <option value="0">許可</option>
                      <option value="1">警告</option>
                      <option value="2">禁止</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
                <div className={style_input_wrap.join(" ")}>
                  <label className={style_label_wrap.join(" ")}>
                    <span className={gstyle.cs_span}>品番</span>
                    <div className={gstyle.cs_flex}>
                      <select
                        className={style_select_245px.join(" ")}
                        value={ZeroContainItemFlag}
                        onChange={handleChangeZeroContainItemFlag}
                      >
                        <option value="0">しない</option>
                        <option value="1">する</option>
                      </select>
                    </div>
                  </label>
                </div>
              </div>
          </div>
          {/* 品番 ロット No */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>ロット No</span>
                  <div className={gstyle.cs_flex}>
                    <select
                      className={style_select_245px.join(" ")}
                      value={ZeroContainFlag}
                      onChange={handleChangeZeroContainFlag}
                    >
                      <option value="0">しない</option>
                      <option value="1">する</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>荷送人設定</span>
                  <div className={gstyle.cs_flex}>
                    <select
                      className={style_select_245px.join(" ")}
                      value={ShipperSetType}
                      onChange={handleChangeShipperSetType}
                    >
                      <option value="0">荷主</option>
                      <option value="1">荷送人</option>
                      <option value="2">自社</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>荷主印字</span>
                  <div className={gstyle.cs_flex}>
                    <select
                      className={style_select_245px.join(" ")}
                      value={ShipperPrintFlag}
                      onChange={handleChangeShipperPrintFlag}
                    >
                      <option value="0">印字有り</option>
                      <option value="1">印字無し</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className={style_row.join(" ")}>
          <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>敬称</span>
                  <div className={gstyle.cs_flex}>
                    <select
                      className={style_select_245px.join(" ")}
                      value={ShipperHonor}
                      onChange={handleChangeShipperHonor}
                    >
                      <option value="0">御中</option>
                      <option value="1">様</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <Button className={style.registryBut} onClick={handleOpen}>
            次へ
          </Button>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {" "}
            <div style={{ fontSize: "1.1rem" }}>
              こちらの内容で登録しますか？
            </div>{" "}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} className={gstyle.save_confirm_no}>
              いいえ
            </Button>
            <Button
              onClick={saveData}
              className={gstyle.save_confirm_yes}
              autoFocus
            >
              はい
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <ToastContainer />
    </div>
  );
};

export default RegistryShipperNext;
