/* eslint-disable eqeqeq */
import style from './Warehouse.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '../../../components/common/modal/Modal';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import CategoryModal from '../../../components/common/modal/CategoryModal';
import AreaModal from '../../../components/common/modal/AreaModal';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select } from 'antd';

const Warehouse = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Warehouse ID
    const [SysWarehouseId, setWarehouseId] = useState(0);
    const [WarehouseCode, setWarehouseCode] = useState("");
    const handleChangeWarehouseCode = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 2) {
            str = str.substring(0, 2);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setWarehouseCode(str);
    };

    const onKeyDownWarehouseCode = (event) => {
        if (event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(3, event.target.value)
            setWarehouseCode(deg)
            document.getElementById('input2').focus();
            if (deg != "") {
                const data_r = {
                    userID: userData['id'],
                    warehouse_code: deg,
                    warehouse_company_id: userData['id']
                };
                let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
                axios
                    .post(url, data_r, {
                        headers: {
                            Authorization: "Bearer " + useJwt.getToken(),
                        },
                    })
                    .then((response1) => {
                        let t_data = response1.data.data;
                        if (t_data.length > 0) {
                            setWarehouseData(t_data[0]);
                        } else {
                            setWarehouseData([]);
                        }
                    })
                    .catch((error) => {
                    });
            } else {
                setWarehouseData([]);
            }
            event.preventDefault();
        }
    };

    const [WarehouseName, setWarehouseName] = useState("");
    const handleChangeWarehouseName = (event) => {
        let str = event.target.value;
        if (str.length > 30) {
            str = str.substring(0, 30);
        }
        setWarehouseName(str);
    };
    const [WarehouseAName, setWarehouseAName] = useState("");
    const handleChangeWarehouseAName = (event) => {
        let str = event.target.value;
        if (str.length > 30) {
            str = str.substring(0, 30);
        }
        setWarehouseAName(str);
    };
    const [WarehouseType, setWarehouseType] = useState(0);
    const handleChangeWarehouseType = (event) => {
        setWarehouseType(event);
    };
    const [CategoryCode, setCategoryCode] = useState("");
    const [CategoryName, setCategoryName] = useState("");
    const handleChangeCategoryCode = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 3) {
            str = str.substring(0, 3);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setCategoryCode(str);
    };
    const onKeyDownCategoryCode = (event) => {
        if (event.keyCode === 13) { // enter key
            getCategoryData(event.target.value);
            document.getElementById('input6').focus();
            event.preventDefault();
        }
    };
    const getCategoryData = (code) => {
        if (code != "") {
            const data_r = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
            axios
                .post(url, data_r, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    let t_data = response.data;
                    if (t_data.length > 0) {
                        setCategoryData(t_data[0]);
                    } else {
                        setCategoryData([]);
                    }
                })
                .catch((error) => {
                });
        } else {
            setCategoryData([]);
        }
    }
    const [AreaCode, setAreaCode] = useState("");
    const [AreaName, setAreaName] = useState("");
    const handleChangeAreaCode = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 6) {
            str = str.substring(0, 6);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setAreaCode(str);
    };
    const onKeyDownAreaCode = (event) => {
        if (event.keyCode === 13) { // enter key
            getAreaData(event.target.value);
            document.getElementById('input7').focus();
            event.preventDefault();
        }
    };
    const getAreaData = (code) => {
        if (code != "") {
            const data_r = {
                userID: userData['id'],
                area_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstAreaEndpoint;
            axios
                .post(url, data_r, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    let t_data = response.data.data;
                    if (t_data.length > 0) {
                        setAreaData(t_data[0]);
                    } else {
                        setAreaData([]);
                    }

                })
                .catch((error) => {
                });
        } else {
            setAreaData([]);
        }
    }

    const setWarehouseData = (result) => {
        if (result['sys_warehouse_id']) {
            setWarehouseId(result['sys_warehouse_id']);
            setWarehouseCode(result['warehouse_code']);
            setWarehouseName(result['warehouse_name']);
            setWarehouseAName(result['warehouse_a_name']);
            setWarehouseType(result['warehouse_type']);
            getCategoryData(result['category_code']);
            getAreaData(result['area_code']);
        } else {
            setWarehouseId(0);
            setWarehouseName("");
            setWarehouseAName("");
            setWarehouseType(0);
            setCategoryData([]);
            setAreaData([]);
        }
    }

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            if (menuPatternData['menu_master_flag_1'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
    });

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if (SysWarehouseId > 0) {
            data['sys_warehouse_id'] = SysWarehouseId;
        }

        data['warehouse_company_id'] = userData['id'];
        if (WarehouseCode != "") {
            data['warehouse_code'] = WarehouseCode;
        } else {
            toast.error("コードを入力してください。", { autoClose: 3000 });       // please enter code
            setOpen(false);
            return;
        }
        if (WarehouseName != "") {
            data['warehouse_name'] = WarehouseName;
        } else {
            toast.error("名称を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (WarehouseAName != "") {
            data['warehouse_a_name'] = WarehouseAName;
        }
        data['warehouse_type'] = WarehouseType;
        data['category_code'] = CategoryCode;
        if (AreaCode != "") {
            data['area_code'] = AreaCode;
        } else {
            toast.error("地区コードを入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstWarehouseEndpoint;
        csloading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if (response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if (response.data.data == 1) {
                        toast.success("登録致しました。", { autoClose: 3000 });
                    } else if (response.data.data == 2) {
                        toast.error("すでに登録済みです。", { autoClose: 3000 });
                    } else {
                        toast.error("登録に失敗しました。", { autoClose: 3000 });
                    }
                    document.getElementById('input1').focus();
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if (error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                } else {
                    toast.error("登録に失敗しました。", { autoClose: 3000 });
                }
            });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if (SysWarehouseId > 0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        if (SysWarehouseId > 0) {
            let data = {};
            data['userID'] = userData['id'];
            data['sys_warehouse_id'] = SysWarehouseId;
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.delMstWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    if (response.status == 200) {
                        setWarehouseCode("");
                        setWarehouseData([]);
                    }
                })
                .catch((error) => {
                });

            setWarehouseCode("");
            setWarehouseData([]);
        }
    }
    // ========================== starts code for search ===============================
    // ----------------------- Warehouse Modal -----------------------------------------
    const [SearchValue, setSearchValue] = useState("");
    const [SearchValueWarehouse, setSearchValueWarehouse] = useState("");
    const [tableData, setTableData] = useState([]);
    const loadModalData = (event) => {
        event.preventDefault();
        let code = WarehouseCode;
        setSearchValue(code);

        if (code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setTableData(response.data.data);
                    setShowModal(true);
                })
                .catch((error) => {
                });
        } else {
            setTableData([]);
            setShowModal(true);
        }
    }
    // ----------------------- Category Modal ------------------------------------------
    const [showModalCategory, setShowModalCategory] = useState(false);
    const [SearchValue2, setSearchValue2] = useState("");
    const [tableData2, setTableData2] = useState([]);
    const [mstType, setMstType] = useState(3);
    const loadModalCategoryData = (event) => {
        event.preventDefault();
        let code = CategoryCode;
        setSearchValue2(code);

        if (code != "") {
            const data = {
                userID: userData['id'],
                category_code: code,
                mst_type: 3
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setTableData2(response.data);
                    setShowModalCategory(true);
                })
                .catch((error) => {
                });
        } else {
            setTableData2([]);
            setShowModalCategory(true);
        }
    }
    const setCategoryData = (data) => {
        if (data['category_code'] != null) {
            setCategoryCode(data['category_code']);
            setCategoryName(data['category_name']);
        } else {
            setCategoryCode("");
            setCategoryName("");
        }

    }
    // ----------------------- Area Modal ------------------------------------------
    const [showModalArea, setShowModalArea] = useState(false);
    const [SearchValue3, setSearchValue3] = useState("");
    const [tableData3, setTableData3] = useState([]);
    const loadModalAreaData = (event) => {
        event.preventDefault();
        let code = AreaCode;
        setSearchValue3(code);

        if (code != "") {
            const data = {
                userID: userData['id'],
                area_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstAreaEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setTableData3(response.data.data);
                    setShowModalArea(true);
                })
                .catch((error) => {
                });
        } else {
            setTableData3([]);
            setShowModalArea(true);
        }
    }
    const setAreaData = (data) => {
        if (data['area_code'] != null) {
            setAreaCode(data['area_code']);
            setAreaName(data['area_name']);
        } else {
            setAreaCode("");
            setAreaName("");
        }
    }

    const [type1Open, setType1Open] = useState(false);

    const onType1KeyDown = (event) => {
        if (event.keyCode == 13) {
            document.getElementById('input5').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if (type == 1) {
            setType1Open(true);
        } else {
            setType1Open(false);
        }
    }

    const onDataKeyDown = (e, param) => {
        if (e.keyCode == 13) {
            switch (param) {
                case 2:
                    document.getElementById('input2').focus();
                    break;
                case 3:
                    document.getElementById('input3').focus();
                    break;
                case 4:
                    document.getElementById('input4').focus();
                    break;
                case 5:
                    document.getElementById('input5').focus();
                    break;
                default:
            }
        }
    }


    const onSelectData = (e, index) => {
        document.getElementById('input7').focus();

        switch (index) {
            case 1:
                setType1Open(false);
                break;
            default:
                break;
        }
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_select_245px = [gstyle.cs_select, gstyle.w245px];

    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <WarehouseModal show={showModal} onClose={() => setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} SearchValueWarehouse={SearchValueWarehouse} onChange={setSearchValue}
                tableData={tableData} setTableData={setTableData}
                target={setWarehouseData} />
            <CategoryModal show={showModalCategory} onClose={() => setShowModalCategory(false)} width={'70%'}
                SearchValue={SearchValue2} onChange={setSearchValue2}
                tableData={tableData2} setTableData={setTableData2}
                target={setCategoryData} mstType={mstType} />
            <AreaModal show={showModalArea} onClose={() => setShowModalArea(false)} width={'70%'}
                SearchValue={SearchValue3} onChange={setSearchValue3}
                tableData={tableData3} setTableData={setTableData3}
                target={setAreaData} />
            <Modal2 title="倉庫削除" show={showModalDel} onClose={() => setShowModalDel(false)} width={'40%'}
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>倉庫マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e)} id='input1'
                                            value={WarehouseCode} onChange={handleChangeWarehouseCode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 名称 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>名称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")} id='input2'
                                            onKeyDown={(e) => onDataKeyDown(e, 3)}
                                            value={WarehouseName} onChange={handleChangeWarehouseName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 略称 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>略称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")} id='input3'
                                            onKeyDown={(e) => onDataKeyDown(e, 4)}
                                            value={WarehouseAName} onChange={handleChangeWarehouseAName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫区分, 分類コード, 地区コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e) => onSelectData(e, 1)}
                                            placeholder="倉庫区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeWarehouseType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop: 14, width: '100%' }}
                                            id="input4"
                                            options={[
                                                { value: '0', label: '棚無し' },
                                                { value: '1', label: '棚有り' },
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>分類コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownCategoryCode(e)} id='input5'
                                            value={CategoryCode} onChange={handleChangeCategoryCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={CategoryName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadModalCategoryData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>地区コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownAreaCode(e)} id='input6'
                                            value={AreaCode} onChange={handleChangeAreaCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={AreaName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadModalAreaData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='input7' onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} disabled={SysWarehouseId > 0 ? false : true}>削除</button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{ fontSize: "1.1rem" }}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Warehouse;