/* eslint-disable eqeqeq */
import style from './FreightageSlip.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import FreightageModal from '../../../components/common/modal/FreightageModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

const FreightageSlip = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [showModalDel2, setShowModalDel2] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // sys_freightage_slip_id
    const [SysFreightageSlipId, setSysFreightageSlipId] = useState(0);
    // slip date
    const [SlipDate, setSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeSlipDate = (date) => {
        setSlipDate(useJwt.formatDate(date));
    };
    // slip no -------------------------------------------------
    const [SlipNo, setSlipNo] = useState("");
    const handleChangeSlipNo = (event) => {
        setSlipNo(useJwt.validateNum(event.target.value,6));
    };
    // shipper ---------------------------------------------------
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        setShipperCode(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode, setShipperACode] = useState("");
    const handleChangeShipperACode = (event) => {
        setShipperACode(useJwt.validateNum(event.target.value,3));
    };

    const onKeyDownSlipNo = (e) => {
        if(e.keyCode == 13){
            document.getElementById('shipper_code').focus();
            e.preventDefault();
        }
    }
    const [ShipperName, setShipperName] = useState("");
    const onKeyDownShipperCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            if(param == 1) {
                getShipperData(event.target.value,ShipperACode);
                document.getElementById('shipper_a_code').focus();
            }else{
                getShipperData(ShipperCode,event.target.value);
                document.getElementById('addRow').focus();
            }
            event.preventDefault();
        }
    };
    const setShipperData = (result) => {
        if(result['shipper_code'] != null) {
            setShipperCode(result['shipper_code']);
            setShipperACode(result['shipper_a_code']);
            setShipperName(result['shipper_name']);

        }else{
            setShipperCode("");
            setShipperACode("");
            setShipperName("");
        }
    }
    const getShipperData = (code, acode) => {
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }else{
                data['shipper_a_code'] = "000";
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result);
                    }else{
                        setShipperData([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([]);
        }
    }
    // ====================================== table ====================================
    const [mainTableData, setMainTableData] = useState([]);
    const [TotalPrice, setTotalPrice] = useState("0.000");

    const gridMainTable = (result) => {
        let data = [];
        let total_price = 0;
        for(var i=0; i<result.length; i++) {
            let row = {};
            row = result[i];
            total_price += 1*row['freightage_price'];
            data[i] = row;
        }
        setTotalPrice(total_price.toFixed(3));
        setMainTableData(data);
    }
    const handleChangeItemData = (event, control_type, col , index) => {
        let value = '';
        if(control_type==2){
            value = useJwt.formatDate(event);
        }else{
            value = event.target.value;
        }

        if(col == 'freightage_code'){
            value = useJwt.validateNum(value,4);
        }else if(col == 'freightage_unit') {
            value = useJwt.validateString(value,4);
        }else if(col == 'freightage_num') {
            value = useJwt.validateDecimal(value,6,3);
        }else if(col == 'freightage_price') {
            value = useJwt.validateDecimal(value,9,3);
        }else if(col == 'freightage_unit_price') {
            value = useJwt.validateDecimal(value,7,2);
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row[col] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }
    const allCheckData = (event) => {
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', event.target.checked);
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };
    const newRecord = (event) => {
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            data[i] = row;
        }
        let row = {};
        row['freightage_code'] = "";
        row['freightage_name'] = "";
        row['freightage_unit'] = "";
        row['freightage_num'] = 0;
        row['freightage_unit_price'] = "0.000";
        row['freightage_price'] = 0;

        data[i] = row; 
        gridMainTable(data);

        setTimeout(()=>{
            let endRow = mainTableData.length;
            document.getElementById('table_code'+endRow).focus();
        },10)
    }
    const deleteItem = (event) => {
        setShowModalDel2(false);
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked){
                remainIndexs.push(i);
            }
        }
        
        let remainData = [];
        for(var i=0; i<mainTableData.length; i++) {
            for(var j=0; j<remainIndexs.length; j++){
                if(i==remainIndexs[j]){
                    let row = {};
                    row = mainTableData[i];
                    remainData.push(row);
                }
            }
        }
        gridMainTable(remainData);
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', false);
    }
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_daily_flag_5'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysFreightageSlipId>0) {
            data['sys_freightage_slip_id'] = SysFreightageSlipId;
        }

        if(SlipDate != "") {
            data['slip_date'] = SlipDate;
        }else{
            toast.error("年月日を入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }
        if(SlipNo != "") {
            data['slip_no'] = SlipNo;
        }else{
            toast.error("伝票Noを入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }
        if(ShipperCode != "") {
            data['shipper_code'] = ShipperCode;
        }else{
            toast.error("荷主コードを入力してください。", {autoClose:3000}); 
            setOpen(false);
            return;
        }
        data['shipper_a_code'] = ShipperACode;

        // item data --------------------------------------
        let validate_flg = true;
        let vmsg = "";
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            
            if(mainTableData[i]['freightage_code'] != "") {
                row['freightage_code'] = mainTableData[i]['freightage_code'];
            }else{
                vmsg = "諸掛コードを入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['freightage_name'] != "") {
                row['freightage_name'] = mainTableData[i]['freightage_name'];
            }else{
                vmsg = "諸掛名を入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['freightage_num'] != "") {
                row['freightage_num'] = mainTableData[i]['freightage_num'];
            }else{
                vmsg = "数量を入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['freightage_unit'] != "") {
                row['freightage_unit'] = mainTableData[i]['freightage_unit'];
            }else{
                vmsg = "単位を入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['freightage_unit_price'] != "") {
                row['freightage_unit_price'] = mainTableData[i]['freightage_unit_price'];
            }else{
                vmsg = "単価を入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['freightage_price'] != "") {
                row['freightage_price'] = mainTableData[i]['freightage_price'];
            }else{
                vmsg = "金額を入力してください。";       
                validate_flg = false;
                break;
            }
            

            itemData[i] = row;
        }
        if(validate_flg){
            data['items'] = itemData;
            if(SysFreightageSlipId == 0) {
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setFreightageSlipItemsEndpoint;
                csloading(true);
                axios
                .post(url, data, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    if(response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if(response.data.data == 1) {
                            toast.success("登録致しました。", {autoClose:3000});
                        }else if(response.data.data == 2){
                            toast.error("すでに登録済みです。", {autoClose:3000});
                        }else{
                            toast.error("登録に失敗しました。", {autoClose:3000});
                        }
                    }
                    csloading(false);
                })
                .catch((error) => {
                    console.log(error)
                    csloading(false);
                    if(error.response && error.response.status && error.response.status === 401) {
                        useJwt.logout();
                        navigate("/login");
                    }else{
                        toast.error("登録に失敗しました。", {autoClose:3000});
                    }
                });
            }else{
                toast.error("この伝票はすでに登録されています。", {autoClose:3000});
            }
        }else{
            toast.error(vmsg, {autoClose:3000});  
        }
        setOpen(false);
    }
    
    const loadModalDelData = (event) => {
        event.preventDefault();
    }
    const loadModalDelData2 = (event) => {
        event.preventDefault();
        setShowModalDel2(true);
    }
    const deleteData = (event) => {
        event.preventDefault();
    }
    // ========================== starts code for search ===============================
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event) => {
        event.preventDefault();
        setShipperSearchValue(ShipperCode);
        setShipperSearchValue2(ShipperACode);
        if(ShipperCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------- freightage modal --------------------------------
    const [showModalFreightage, setShowModalFreightage] = useState(false);
    const [FreightageSearchValue, setFreightageSearchValue] = useState("");
    const [FreightageTableData, setFreightageTableData] = useState([]);

    const setFreightageData = (data) => {

        let freightage_code = "";
        let freightage_name = "";
        let freightage_unit = "";
        let freightage_unit_price = "0.000";
        if(data['freightage_code']) {
            freightage_code = data['freightage_code'];
            freightage_name = data['freightage_name'];
            freightage_unit = data['freightage_unit'];
            freightage_unit_price = data['freightage_unit_price'];
        }
        
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i==document.getElementById('selectedId').value){
                row['freightage_code'] = freightage_code;
                row['freightage_name'] = freightage_name;
                row['freightage_unit'] = freightage_unit;
                row['freightage_unit_price'] = freightage_unit_price;
            }
            itemData[i] = row;
        }
        gridMainTable(itemData);
    }
    const loadFreightageModalData = (event, id, code) => {
        document.getElementById('selectedId').value=id;
        setFreightageSearchValue(code);
        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['freightage_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstFreightageEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setFreightageTableData(response.data.data);
                setShowModalFreightage(true);
            })
            .catch((error) => {
            });
        }else{
            setFreightageTableData([]);
            setShowModalFreightage(true);
        }
    }
        
    const tableKeyDown = ( e, id, index ) =>{
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    const tableEndKeyDown = ( e, id, index ) =>{
        let dataLength = mainTableData.length;

        if(dataLength <= index + 1)  return;
        
        if(e.keyCode === 13){
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_total_input_wrap = [gstyle.p0015, gstyle.mt30];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_th_w1p = [style.cs_th, style.w1p];
    const style_th_w2p = [style.cs_th, style.w2p];
    const style_th_w3p = [style.cs_th, style.w3p];
    const style_th_w4p = [style.cs_th, style.w4p];
    const style_th_w5p = [style.cs_th, style.w5p];


    const style_td_w1p = [style.cs_td, style.w1p];
    const style_td_w2p_flex = [style.cs_td, style.w2p, gstyle.cs_flex];
    const style_td_w3p = [style.cs_td, style.w3p, gstyle.cs_flex];
    const style_td_w4p = [style.cs_td, style.w4p];
    const style_td_w5p = [style.cs_td, style.w5p];

    const style_th_total_title = [style.cs_th, style.total_label];
    const style_td_total_val = [style.cs_td, style.td_text_right, style.td_line_break];

    const style_td_input_right = [style.cs_tbl_input, style.td_input_right];
    const style_td_right = [style.cs_td, style.td_text_left, style.w3p];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} />
            <FreightageModal show={showModalFreightage} onClose={()=>setShowModalFreightage(false)} width={'70%'}
                SearchValue={FreightageSearchValue} onChange={setFreightageSearchValue} 
                tableData={FreightageTableData} setTableData={setFreightageTableData}
                target={setFreightageData} />
            <Modal2 title="入庫入力削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <Modal2 title="入庫入力削除" show={showModalDel2} onClose={()=>setShowModalDel2(false)} width={'40%'} 
                onConfirm={deleteItem}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>諸掛入力</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 年月日, 伝票No */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={SlipDate} onChange={handleChangeSlipDate} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>伝票No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")} id="slip_no"
                                        value={SlipNo} onChange={handleChangeSlipNo} 
                                        onKeyDown={(e)=>onKeyDownSlipNo(e)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1)} id="shipper_code"
                                        value={ShipperCode} onChange={handleChangeShipperCode} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2)} id="shipper_a_code"
                                        value={ShipperACode} onChange={handleChangeShipperACode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="ShipperName"
                                        value={ShipperName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        { /* 追加, 削除 */}
                        <div className={style_col_4.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")}>
                                <button className={gstyle.btn_new} onClick={newRecord} id="addRow" >追加</button>
                                <button className={gstyle.btn_delete2} onClick={loadModalDelData2} >削除</button>
                            </div>
                        </div>
                    </div>
                    {/* table */}
                    <div className={style.tbl_wrap}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_w2p.join(" ")}>諸掛</div>
                                    <div className={style_th_w3p.join(" ")}>諸掛名</div>
                                    <div className={style_th_w4p.join(" ")}>数量</div>
                                    <div className={style_th_w1p.join(" ")}>単位</div>
                                    <div className={style_th_w4p.join(" ")}>単価</div>
                                    <div className={style_th_w5p.join(" ")}>金額</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                            id={'chk_'+index} onChange={handleChangeChk} />
                                        </div>
                                        <div className={style_td_w2p_flex.join(" ")}>
                                            <input type={'number'} className={style.cs_tbl_input} 
                                            value={data['freightage_code']?data['freightage_code']:''} id={"table_code"+index}
                                            onChange={(e) => handleChangeItemData(e,1,'freightage_code',index)} 
                                            onKeyDown={(e)=>tableKeyDown(e, 'table_num'+index, index)}
                                            />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadFreightageModalData(e,index,data['freightage_code']?data['freightage_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                        <div className={style_td_right.join(" ")}>
                                            {data['freightage_name']?data['freightage_name']:''}
                                        </div>
                                        
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'text'} className={style_td_input_right.join(" ")} 
                                            value={data['freightage_num']?data['freightage_num']:''} id={"table_num"+index}
                                            onChange={(e) => handleChangeItemData(e,1,'freightage_num',index)} 
                                            onKeyDown={(e)=>tableKeyDown(e, 'table_unit'+index, index)}
                                            />
                                        </div>
                                        <div className={style_td_w1p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            value={data['freightage_unit']?data['freightage_unit']:''} id={"table_unit"+index}
                                            onChange={(e) => handleChangeItemData(e,1,'freightage_unit',index)} 
                                            onKeyDown={(e)=>tableKeyDown(e, 'table_unit_price'+index, index)}
                                            />
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'text'} className={style_td_input_right.join(" ")} 
                                            value={data['freightage_unit_price']?data['freightage_unit_price']:''} 
                                            onChange={(e) => handleChangeItemData(e,1,'freightage_unit_price',index)} 
                                            id={"table_unit_price"+index}
                                            onKeyDown={(e)=>tableKeyDown(e, 'table_freightage_price'+index, index)}
                                            />
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            <input type={'text'} className={style_td_input_right.join(" ")} 
                                            value={data['freightage_price']?data['freightage_price']:''} id={"table_freightage_price"+index}
                                            onChange={(e) => handleChangeItemData(e,1,'freightage_price',index)} 
                                            onKeyDown={(e)=>tableEndKeyDown(e, 'table_code'+(index+1), index)}
                                            />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* Total */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_total_input_wrap.join(" ")}>
                                <div className={style.cs_tbl}>
                                    <div className={style.cs_thead}>
                                        <div className={style.cs_tr}>
                                            <div className={style_th_total_title.join(" ")}>合計金額</div>
                                        </div>
                                    </div>
                                    <div className={style.cs_tbody}>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_val.join(" ")}>￥{useJwt.numberWithCommas(TotalPrice)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >登録する</button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default FreightageSlip;