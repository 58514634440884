import React, { CSSProperties, useState } from 'react';
import axios from "axios";
import * as useJwt from '../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import './CsvImportInput.css'
import { useCSVReader } from 'react-papaparse';
import $ from 'jquery';

const styles = {
  progressBarBackgroundColor: {
    backgroundColor: 'blue',
    width: '80%',
  } as CSSProperties,
};

export default function CsvImportDataCheckInput(props) {
  const { CSVReader } = useCSVReader();

  const [csvData, setCsvData] = useState([]);
  const [importStatus, setImportStatus] = useState(true)
  const userData = JSON.parse(useJwt.getUserData() as any);

  const sendData = () => {

    props.setLoadFlag(true)
    let url = jwtDefaultConfig.backendUrl + props.url
    let data = {};
    data["userID"] = userData["id"];
    data["data"] = csvData;

    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
        },
      })
      .then((response) => {
        let responseData = response.data.data;
        if (responseData.length == 0) {
          responseData.push("success")
        }
        console.log("response = ", responseData)
        props.setErrorData(responseData)
        props.setLoadFlag(false)
        setImportStatus(true)
        // $('#fileName').text("");
      })
      .catch((error) => {
        props.setLoadFlag(false)
        // $('#fileName').text("");
        let netError = ['インポートに失敗しました。'];
        props.setErrorData(netError)
        setImportStatus(true)
      });
  }
  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        props.setErrorData([])
        console.log(results);
        if (results.data[0].toString() !== props.csvHeader.toString()) {
          props.setErrorData(["ファイル形式が間違っています。"])
          setImportStatus(true);
          return false;
        }

        setCsvData(results);
        setImportStatus(false)
      }}
      config={{
        encoding: 'windows-31j',
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
      }: any) => (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ width: '80%' }}>
            <div className="csvReader">
              <div id="fileName" className='acceptedFile'>
                {acceptedFile && acceptedFile.name}
              </div>
              <button type='button' {...getRootProps()} className="browseFile">
                ファイルを参照
              </button>
              {
                importStatus ?
                  <button onClick={sendData} className="import" disabled>インポート</button>
                  :
                  <button onClick={sendData} className="import">インポート</button>
              }

            </div>
            <ProgressBar style={styles.progressBarBackgroundColor} />
          </div>
        </div>
      )}
    </CSVReader>
  );
}