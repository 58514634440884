import React,{useState} from "react";
import style from "./StockScreenReference.module.css"
import InputField from "../../components/StockScreenReference/InputField";
import { useForm } from "react-hook-form";
import SelectFiled from "../../components/StockScreenReference/SelectField";
import TableInput from "../../components/StockScreenReference/TableInput";

const StockScreenReference = () => {
    const {register, handleSubmit, setValue} = useForm();
    const [showTable, setShowTable] = useState(false);
    const INPUTS1 = [
        {
            name: 'data',
            element: 'input',
            type: 'text',
            label: '年月日',
            styles: {

            },
        },
        {
            name: 'consignor',
            element: 'input',
            type: 'text',
            label: '荷主',
            styles: {

            },
        },
        {
            name: 'warehouse1',
            element: 'select',
            option: ['倉庫A','倉庫B','倉庫C'], // ダミー
            label: '倉庫',
            styles: {

            },
        },
        {
            name: 'warehouse2',
            element: 'select',
            option: ['倉庫A','倉庫B','倉庫C'], // ダミー
            label: '',
            styles: {

            },
        },
    ]
    const INPUTS2 = [
        {
            name: 'item1',
            element: 'input',
            type: 'text',
            label: '商品No',
            styles: {

            },
        },
        {
            name: 'item2',
            element: 'input',
            type: 'text',
            label: '',
            styles: {

            },
        },
    ]
    const INPUTS3 = [
        {
            name: 'partNoClassification',
            element: 'select',
            option: ['A','B','C'], // ダミー
            label: '品番区分',
            styles: {

            },
        },
        {
            name: 'warehouseClassification',
            element: 'select',
            option: ['X','Y','Z'], // ダミー
            label: '倉庫区分',
            styles: {

            },
        },
    ]

    const TABELS = [
        {
            name:'itemNo',
            label:'品番',
            styles:{
                width:'',
            },
        },
        {
            name:'auxiliary',
            label:'補助',
            styles:{
                width:'',
            },
        },
        {
            name:'itemNameAbbreviation',
            label:'品名略称',
            styles:{
                width:'',
            },
        },
        {
            name:'showWarehouse',
            label:'倉庫',
            styles:{
                width:'',
            },
        },
        {
            name:'warehouseAbbreviation',
            label:'倉庫略称',
            styles:{
                width:'',
            },
        },
        {
            name:'location',
            label:'ロケーション',
            styles:{
                width:'',
            },
        },
        {
            name:'stockCases',
            label:'在庫ケース数',
            styles:{
                width:'',
            },
        },
        {
            name:'stockBara',
            label:'在庫バラ数',
            styles:{
                width:'',
            },
        },
        {
            name:'stockQuantity',
            label:'在庫',
            styles:{
                width:'',
            },
        },
        {
            name:'WeightAndVolume',
            label:'重量・容積',
            styles:{
                width:'',
            },
        },
        {
            name:'unit',
            label:'単位',
            styles:{
                width:'',
            },
        },
    ]
    const TABLE_COUNT = [1,2,3,4,5,6];

        
    const onSubmit = data => {
        setShowTable(true);
        // ダミー
        setValue('tables', {
            itemNo1: 'aaa',
            auxiliary1: 'bbb',
            itemNameAbbreviation1:'ccc',
            showWarehouse1: 'ddd',
            warehouseAbbreviation1: 'eee',
            location1: 'fff',
            stockCases1: 'ggg',
            stockBara1: 'hhh',
            stockQuantity1: 'iii',
            WeightAndVolume1: 'jjj',
            unit1: 'kkk',
        })
        console.log(data);
    }

    return (
        <div className={style.display}>
            <div className={style.border}>
                <p className={style.text}>在庫画面参照</p>
            </div>
            
                <div className={`${style.filed} ${showTable && style.showtable}`} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={style.container}>
                        <div className={style.flex}>
                            {INPUTS1.map((data) => {
                                return (
                                    data.element == 'select' ? <SelectFiled key={data.name} {...data} register={register} /> : <InputField key={data.name} {...data} register={register} />
                                )
                            })}
                        </div>
                        <div className={style.flex}>
                            {INPUTS2.map((data) => (
                                <InputField key={data.name} {...data} register={register} styles={{width: '260px', marginRight: '48px'}}/>
                            ))}
                        </div>
                        <div className={style.flex}>
                            {INPUTS3.map((data) => (
                                <SelectFiled key={data.name} {...data} register={register} />
                            ))}
                            <button className={style.button}>検索</button>
                        </div>
                    </div>
                    </form>
                    {showTable && 
                        <table className={style.table}>
                            <thead>
                            <tr>
                                {TABELS.map((data) => (
                                    <th className={style.tableHeader} key={data.name}>{data.label}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {TABLE_COUNT.map((count) => (
                                <tr key={count}>
                                {TABELS.map((Data) => (
                                        <TableInput key={Data.name} {...Data} register={register}  name={Data.name + count}/>
                                    ))}
                                </tr>
                                ))}
                            </tbody>
                        </table>}
                </div>
            

        </div>
    )
}

export default StockScreenReference;