/* eslint-disable eqeqeq */
import style from './Setting.module.css'
import HeadingText from '../../components/administrator/headingText/HeadingText';
import LinkButton from '../../components/administrator/linkButton/LinkButton';
import * as useJwt  from '../../auth/jwt/useJwt.js';

const App = () => {
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const userData = JSON.parse(useJwt.getUserData());
    return (
        <>
            <label className='bodyLabel'>導入時設定</label>
            <div className='contentBody'>
            <HeadingText color='#1188E8' title='基本設定' />
            <div className={style.links}>
                {	(menuPatternData['menu_start_setting_flag_1'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/main-setting' title='基本設定メンテナンス' />
				}
                {	(menuPatternData['menu_start_setting_flag_2'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/invoice-setting' title='送り状出力設定メンテナンス' />
				}
            </div>
            <HeadingText color='#73A776' title='残高設定' />
            <div className={style.links}>
                {	(menuPatternData['menu_start_setting_flag_3'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/shipper-balance-setting' title='荷主残高設定処理' />
				}
                {	(menuPatternData['menu_start_setting_flag_4'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/stock-balance-setting' title='商品残数設定処理' />
				}
            </div>
            <HeadingText color='#F8C461' title='管理' />
            <div className={style.links}>
            {	(menuPatternData['menu_start_setting_flag_5'] == 1 || userData['sys_role_id'] == 10000) &&
                    <LinkButton link='/menu-pattern-setting' title='メニューパターン設定' />
				}
                {	(menuPatternData['menu_register_flag'] == 1 || userData['sys_role_id'] == 10000) &&
                    <LinkButton link='/userRegister' title='ユーザー設定登録' />
				}
                
                
            </div>
            </div>
        </>
    );
};

export default App;