/* eslint-disable eqeqeq */
import style from './UserRegister.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import './UserRegisterTbl.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import MenuPatternModal from '../../../components/common/modal/MenuPatternModal';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import $ from 'jquery';


const UserRegister = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [showModalDel2, setShowModalDel2] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    // user id
    const [SysUserId, setSysUserId] = useState(0);
    // menu pattern ----------------------------------------------------
    const [PatternCode, setPatternCode] = useState("");
    const handleChangePatternCode = (event) => {
        setPatternCode(useJwt.validateNum(event.target.value,4));
    };
    const [PatternName, setPatternName] = useState("");
    const handleChangePatternName = (event) => {
        setPatternName(event.target.value);
    };

    const onKeyDownPatternCode = (event) => {
        if(event.keyCode === 13) { // enter key
            if(event.target.value != "") {
                getPatternData(event.target.value);
                event.preventDefault();
            }else{
                setPatternData([]);
                event.preventDefault();
            }
        }
    };

    const getPatternData = (value) => {
        const data_r = {
            userID: userData['id'],
            pattern_code: value
        };
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMenuPatternEndpoint;
        axios
        .post(url, data_r, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response1) => {
            let t_data = response1.data.data;
            if(t_data.length > 0) {
                setPatternData(t_data[0]);
            }else{
                setPatternData([]);
            }
        })
        .catch((error) => {
        });
    }

    const setPatternData = (data) => {
        if(data['pattern_code'] != null) {
            setPatternCode(data['pattern_code']);
            setPatternName(data['pattern_name']);
        }else{
            setPatternCode("");
            setPatternName("");
        }
    }

    // user no ---------------------------------------------
    const [UserNo, setUserNo] = useState("");
    const handleChangeUserNo = (event) => {
        setUserNo(useJwt.validateNum(event.target.value,8));
    };
    // ====================================== table ====================================
    const [mainTableData, setMainTableData] = useState([]);
    const [TotalNum, setTotalNum] = useState("0.000");
    const [TotalWeight, setTotalWeight] = useState("0.000");
    const [TotalVolume, setTotalVolume] = useState("0.000");
    const [ItemRemainNum, setItemRemainNum] = useState("0.000");

    const getSlipData = (filter) => {
        let data = {};
        let validateflg = true;
        data['userID'] = userData['id'];
        if(filter['slip_date'] != null && filter['slip_date'] != "") {
            data['start_slip_date'] = filter['slip_date'];
            data['end_slip_date'] = filter['slip_date'];
        }else{
            validateflg = false;
        }
        if(filter['shipper_code'] != null && filter['shipper_code'] != "") {
            data['start_shipper_code'] = filter['shipper_code'];
            data['end_shipper_code'] = filter['shipper_code'];
        }else{
            validateflg = false;
        }
        if(filter['shipper_a_code'] != null && filter['shipper_a_code'] != "") {
            data['start_shipper_a_code'] = filter['shipper_a_code'];
            data['end_shipper_a_code'] = filter['shipper_a_code'];
        }
        if(filter['slip_no'] != null && filter['slip_no'] != "") {
            data['slip_no'] = filter['slip_no'];
        }else{
            validateflg = false;
        }
        if(filter['question_no'] != null && filter['question_no'] != "") {
            data['question_no'] = filter['question_no'];
        }
        if(validateflg) {
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.searchArrivalSlipEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setSysUserId(t_data[0]['id']);
                    gridMainTable(t_data);
                }else{
                    gridMainTable([]);
                }
            })
            .catch((error) => {
            });
        }else{
            gridMainTable([]);
        }
    }
    const gridMainTable = (result) => {
        let data = [];
        let total_num = 0;
        let total_weight = 0;
        let total_volume = 0;
        for(var i=0; i<result.length; i++) {
            let row = {};
            row = result[i];
            total_num += 1*row['item_num'];
            total_weight += (1*row['item_num']*row['unit_weight']);
            total_volume += (1*row['item_num']*row['unit_volume']);
            data[i] = row;
        }
        setTotalNum(total_num.toFixed(3));
        setTotalWeight(total_weight.toFixed(3));
        setTotalVolume(total_volume.toFixed(3));
        setMainTableData(data);
    }
    const handlChangeItemData = (event, control_type, col , index) => {
        let value = '';
        if(control_type==2){
            value = useJwt.formatDate(event);
        }else{
            value = event.target.value;
        }

        if(col == 'item_code' || col == 'rot_code'){
            value = useJwt.validateString(value,15);
        }else if(col == 'item_a_code'){
            value = useJwt.validateNum(value,3);
        }else if(col == 'shipper_item_code'){
            value = useJwt.validateString(value,15);
        }else if(col == 'warehouse_code'){
            value = useJwt.validateNum(value,2);
        }else if(col == 'table_code'){
            value = useJwt.validateString(value,8);
        }else if(col == 'item_unit') {
            value = useJwt.validateString(value,4);
        }else if(col == 'item_num') {
            value = useJwt.validateDecimal(value,6,3);
        }else if(col == 'remark') {
            value = useJwt.validateString(value,10);
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row[col] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }
    const allCheckData = (event) => {
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', event.target.checked);
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };
    const newRecord = (event) => {
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            data[i] = row;
        }
        let row = {};
        row['item_code'] = "";
        row['item_a_code'] = "";
        row['shipper_item_code'] = "";
        row['item_name'] = "";
        row['rot_code'] = "";
        row['rot_name'] = "";
        row['warehouse_code'] = "";
        row['warehouse_name'] = "";
        row['table_code'] = 0;
        row['location_data'] = [];
        row['item_unit'] = 0;
        row['unit_weight'] = 0;
        row['unit_volume'] = 0;
        row['case_num'] = 0;
        row['bara_num'] = 0;
        row['item_num'] = 0;
        row['item_deadline_date'] = useJwt.formatDate(new Date());
        row['remark'] = "";

        data[i] = row; 
        gridMainTable(data);
    }
    const deleteItem = (event) => {
        setShowModalDel2(false);
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked){
                remainIndexs.push(i);
            }
        }
        
        let remainData = [];
        for(var i=0; i<mainTableData.length; i++) {
            for(var j=0; j<remainIndexs.length; j++){
                if(i==remainIndexs[j]){
                    let row = {};
                    row = mainTableData[i];
                    remainData.push(row);
                }
            }
        }
        gridMainTable(remainData);
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', false);
    }
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysUserId>0) {
            data['id'] = SysUserId;
        }
        
    }
    
    const loadModalDelData = (event) => {
        event.preventDefault();
    }
    const loadModalDelData2 = (event) => {
        event.preventDefault();
        setShowModalDel2(true);
    }
    const deleteData = (event) => {
        event.preventDefault();
    }
    // ========================== starts code for search ===============================

    const [showModalMenuPattern, setShowModalMenuPattern] = useState(false);
    const [MenuPatternSearchValue, setMenuPatternSearchValue] = useState("");
    const [MenuPatternTableData, setMenuPatternTableData] = useState([]);
    const loadMenuPatternModalData = (event,param) => {
        event.preventDefault();
        let code = PatternCode;
        setMenuPatternSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['item_type_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMenuPatternEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setMenuPatternTableData(response.data.data);
                setShowModalMenuPattern(true);
            })
            .catch((error) => {
            });
        }else{
            setMenuPatternTableData([]);
            setShowModalMenuPattern(true);
        }
    }
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_total_input_wrap = [gstyle.p0015, gstyle.mt30];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_220px = [gstyle.cs_input, gstyle.w220px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_input_80px = [gstyle.cs_input, gstyle.w80px];
    const style_split_span = [style.split_span];
    const style_select_245px = [gstyle.cs_select, gstyle.w245px];

    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_1 = [style.cs_th, gstyle.cs_col_1];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_1 = [style.cs_td, gstyle.cs_col_1];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_th_w1p = [style.cs_th, style.w1p];
    const style_th_w2p = [style.cs_th, style.w2p];
    const style_th_w3p = [style.cs_th, style.w3p];
    const style_th_w4p = [style.cs_th, style.w4p];
    const style_th_w5p = [style.cs_th, style.w5p];
    const style_th_w6p = [style.cs_th, style.w6p];
    const style_th_w7p = [style.cs_th, style.w7p];


    const style_td_w1p = [style.cs_td, style.w1p];
    const style_td_w2p = [style.cs_td, style.w2p];
    const style_td_w2p_flex = [style.cs_td, style.w2p, gstyle.cs_flex];
    const style_td_w3p = [style.cs_td, style.w3p, gstyle.cs_flex];
    const style_td_w4p = [style.cs_td, style.w4p];
    const style_td_w5p = [style.cs_td, style.w5p];
    const style_td_w6p = [style.cs_td, style.w6p, gstyle.cs_flex];
    const style_td_w7p = [style.cs_td, style.w7p];

    const style_th_total_title = [style.cs_th, style.total_label];
    const style_td_total_label = [style.cs_td, style.total_label];
    const style_td_total_val = [style.cs_td];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <MenuPatternModal show={showModalMenuPattern} onClose={()=>setShowModalMenuPattern(false)} width={'70%'}
                SearchValue={MenuPatternSearchValue} onChange={setMenuPatternSearchValue} 
                tableData={MenuPatternTableData} setTableData={setMenuPatternTableData}
                target={setPatternData} />
            <Modal2 title="ユーザー削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <Modal2 title="ユーザー削除" show={showModalDel2} onClose={()=>setShowModalDel2(false)} width={'40%'} 
                onConfirm={deleteItem}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>ユーザー設定登録</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* メニューパターン */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>メニューパターン</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} id="PatternCode"
                                            value={PatternCode}
                                            onKeyDown={(e) => onKeyDownPatternCode(e)}
                                            onChange={handleChangePatternCode}/>
                                        <input type={'text'} className={style_input_bl_n.join(" ")} id="PatternName"
                                            value={PatternName} 
                                            onChange={handleChangePatternName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadMenuPatternModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>社員番号</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")}  
                                            value={UserNo} onChange={handleChangeUserNo} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={saveData} >登録する</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserRegister;