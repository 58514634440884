/* eslint-disable eqeqeq */
import style from './Top.module.css'
import Heading from '../../components/top/heading/Heading';
import LinkButton from '../../components/top/Link/LinkButton';
import * as useJwt  from '../../auth/jwt/useJwt.js';

const Home = () => {
	const menuPatternData = JSON.parse(useJwt.getMenuPatternData()); 
    const userData = JSON.parse(useJwt.getUserData());
	console.log(userData);
	return (
		<>
		<label className='bodyLabel'>日次業務</label>
		<div className='contentBody'>
			<Heading color='#1188E8' title='日時入力' />
			<div className={style.links}>

				{	(menuPatternData['menu_daily_flag_1'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/jobRegistry' text='入出庫指示登録' />
				}

				{	(menuPatternData['menu_daily_flag_3'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/stockMoveSlip' text='在庫移動処理' />
				}
				{	(menuPatternData['menu_daily_flag_4'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/changeAmountSlip' text='単位変更処理' />
				}
				{	(menuPatternData['menu_daily_flag_5'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/freightageSlip' text='諸掛入力' />
				}
				{	(menuPatternData['menu_daily_flag_6'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/deposits' text='入金入力' />
				}

				{	(menuPatternData['menu_daily_flag_26'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/JobQRcode/qrCode' text='商品QRコード印刷' />
				}
				{	(menuPatternData['menu_daily_flag_28'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/warehouse/qrCode' text='倉庫QRコード印刷' />
				}
				{	(menuPatternData['menu_daily_flag_27'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/location/qrCode' text='棚番QRコード印刷' />
				}
				{	(menuPatternData['menu_daily_flag_2'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/warehouse/subscription' text='荷主申請承認' />
				}
				
			</div>
			<Heading color='#73A776' title='日時帳票' />
			<div className={style.links}>
				{	(menuPatternData['menu_daily_flag_7'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/arrivalSlipList' text='入庫リスト' />
				}
				{	(menuPatternData['menu_daily_flag_8'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/leavingSlipList' text='出庫リスト' />
				}
				{	(menuPatternData['menu_daily_flag_9'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/stockMoveSlipList' text='在庫移動リスト' />
				}
				{	(menuPatternData['menu_daily_flag_10'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/changeAmountList' text='単位変更リスト' />
				}
				{	(menuPatternData['menu_daily_flag_11'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/freightageSlipList' text='諸掛リスト' />
				}
				{	(menuPatternData['menu_daily_flag_12'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/depositList' text='入金リスト' />
				}
				{	(menuPatternData['menu_daily_flag_13'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/arrivalSlipItemList' text='入庫確認兼指示書' />
				}
				{	(menuPatternData['menu_daily_flag_14'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/pickingList' text='ピッキングリスト' />
				}
				{	(menuPatternData['menu_daily_flag_15'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/leavingItemList' text='出荷明細表' />
				}
			</div>
			<Heading color='#F8C461' title='送り状' />
			<div className={style.links}>
				{	(menuPatternData['menu_daily_flag_16'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/invoiceItemList' text='送り状発行' underText='【標準】(BP2013)' />
				}
				{	(menuPatternData['menu_daily_flag_17'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/invoiceItemWeightList' text='送り状発行' underText='【重量】(BP2013)'/>
				}
				{	(menuPatternData['menu_daily_flag_18'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/invoiceItemVolumeList' text='送り状発行' underText='【容積】(BP2013)' />
				}
				{	(menuPatternData['menu_daily_flag_19'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='#' text='送り状発行' underText='【標準】(50013CP)'/>
				}
				{	(menuPatternData['menu_daily_flag_20'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='#' text='送り状発行' underText='【重要】(50013CP)'/>
				}
				{	(menuPatternData['menu_daily_flag_21'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='#' text='送り状発行' underText='【容積】(50013CP)'/>
				}
			</div>
			<Heading color='#343233' title='荷札' />
			<div className={style.links} style={{paddingBottom: '24px'}}>
				{	(menuPatternData['menu_daily_flag_22'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/DailyWork/tagItemList' text='荷札発行' underText='【出庫伝票】(M11BP)' />
				}
				{	(menuPatternData['menu_daily_flag_23'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='#' text='荷札発行' underText='【出庫伝票】(M11BPT)'/>
				}
				{	(menuPatternData['menu_daily_flag_24'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='#' text='荷札発行' underText='【単独】(M11BP)' />
				}
				{	(menuPatternData['menu_daily_flag_25'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='#' text='荷札発行' underText='【単独】(M11BPT)'/>
				}
			</div>
    	</div>
		</>
	);
};

export default Home;