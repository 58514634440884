/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import style from "./Login.module.css";
import axios from "axios";
import $ from "jquery";
import * as useJwt from "../../auth/jwt/useJwt.js";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button } from "antd";

const ShipperForgetPassword = (props) => {

  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
  }, [props]);

  const sendShipperEmail = (event) =>{
    $('#modal').css('opacity', 0);
    $('#modal').css('z-index', 0);

    const data = {
      email: email,
    };

    axios
      .post(useJwt.getShipperPasswordForgetEndpoint(), data)
      .then((response) => {
        toast.success("送信されました。", { autoClose: 3000 });
      })
      .catch((error) => {
        toast.error(error.response.data.message, { autoClose: 3000 });
      });
  }
  return (
    <>
    <div className={style.background}>
      <div className={style.forgetForm}>
      <div className={style.title}>
          <span>パスワード再設定</span>
        </div>
        <div style={{marginTop: 60}}>
          <p style={{marginLeft:20, fontSize: 12}}>ご登録いただいたメールアドレスを入力してください。</p>
          <p style={{marginLeft:20, fontSize: 12, marginTop:10}}>メールアドレス宛にパスワード変更ページのURLが記載されたメールを送信します。</p>
          <div style={{marginTop: 20}}>
            <div className={style.subTitle}>メールアドレス</div>
            <div className={style.emailField}>
              <input
                className={style.emailText}
                type="text"
                placeholder="Email"
                value={email}
                onChange={handleChangeEmail}
              />
            </div>
          </div>

          <div className={style.submitButtonField} style={{marginTop:20}}>
            <button className={style.forgetSubmitButton} onClick={(e) => sendShipperEmail(e)}>
            パスワード再設定メールを送信する
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
    </>
  );
};

export default ShipperForgetPassword;
