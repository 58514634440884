/* eslint-disable eqeqeq */
import style from './Tax.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import InputDatepicker from '../../../components/common/input/InputDatepicker';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

const Tax = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        setMainTableData(result);
    }
    const emptyMainTable = () => {
        let row = []
        let data = {}
        data['apply_date'] = ''
        data['tax_1'] = "";
        data['tax_2'] = "";
        data['tax_3'] = "";
        data['tax_4'] = "";
        data['tax_5'] = "";
        data['tax_6'] = "";
        data['tax_7'] = "";
        data['tax_8'] = "";
        data['tax_9'] = "";
        data['sys_tax_id'] = 0
        row.push(data)
        setMainTableData(row)
}
    const formatDate = (date) => {
        let year = date.getFullYear();
        let month = '' + (date.getMonth()*1 + 1);
        let day = '' + date.getDate();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return  year + '-' + month + '-' + day;
    }
    const handlChangeData = (event, control_type, col , index) => {
        let value = '';
        if(control_type==2){
            value = formatDate(event);
        }else{
            value = event.target.value;
            var regexp = /^\d+(\.\d{0,2})?$/;
            if(value != "" ) {
                if(!regexp.test(value)) {
                    toast.error("整数 3 桁 小数 2 桁の数字を入力する必要があります。", {autoClose:3000}); 
                    value = value.substring(0, value.length-1);
                }else{
                    if(value*1>1000){
                        toast.error("整数 3 桁 小数 2 桁の数字を入力する必要があります。", {autoClose:3000}); 
                        value = value.substring(0, value.length-1);
                    }
                }
            }
        }
        
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row[col] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }
 
    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            getTaxData();
        }
	});

    const getTaxData = () => {
        csloading(true);
        let data = {};
        data['userID'] = userData['id'];
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTaxEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        }).then((response) => {

            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data.length>0) {
                    let result = response.data.data[0];
                    gridMainTable([result]);
                }else{
                    emptyMainTable()
                }
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }
        });
    }

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];

        let validate_flg = true;
        let vmsg = "";

        let taxData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            if(mainTableData[i]['sys_tax_id'] != 0) {
                row['sys_tax_id'] = mainTableData[i]['sys_tax_id'];
            }
            if(mainTableData[i]['apply_date'] != "") {
                row['apply_date'] = mainTableData[i]['apply_date'];
            }else{
                vmsg = "適用年月日を入力してください。";       
                validate_flg = false;
                break;
            }
            row['tax_1'] = mainTableData[i]['tax_1'];
            row['tax_2'] = mainTableData[i]['tax_2'];
            row['tax_3'] = mainTableData[i]['tax_3'];
            row['tax_4'] = mainTableData[i]['tax_4'];
            row['tax_5'] = mainTableData[i]['tax_5'];
            row['tax_6'] = mainTableData[i]['tax_6'];
            row['tax_7'] = mainTableData[i]['tax_7'];
            row['tax_8'] = mainTableData[i]['tax_8'];
            row['tax_9'] = mainTableData[i]['tax_9'];

            taxData[i] = row;
        }
        if(validate_flg){
            data['data'] = taxData;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setMstTaxEndpoint;
            csloading(true);
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data == 1) {
                        toast.success("登録致しました。", {autoClose:3000});
                    }else if(response.data.data == 2){
                        toast.error("すでに登録済みです。", {autoClose:3000});
                    }else{
                        toast.error("登録に失敗しました。", {autoClose:3000});
                    }
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
            });
        }else{
            toast.error(vmsg, {autoClose:3000});  
        }
        setOpen(false);
    }
        
    const onDataKeyDown = (e, param) => {
        if(e.keyCode == 13){
            switch(param){
                case 11 :
                    document.getElementById('registerBut').focus();
                    break;
                case 2 :
                    document.getElementById('input2').focus();
                    break;
                case 3 :
                    document.getElementById('input3').focus();
                    break;
                case 4 :
                    document.getElementById('input4').focus();
                    break;
                case 5 :
                    document.getElementById('input5').focus();
                    break;
                case 6 :
                    document.getElementById('input6').focus();
                    break;
                case 7 :
                    document.getElementById('input7').focus();
                    break;
                case 8 :
                    document.getElementById('input8').focus();
                    break;
                case 9 :
                    document.getElementById('input9').focus();
                    break;
                case 10 :
                    document.getElementById('input10').focus();
                    break;
                default :
            }
        }
    }

    // ========================== ends code for search ==================================
    // style array
    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <div className={gstyle.border}>
                <p className={gstyle.text}>消費税率マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    <div className={gstyle.hyfiled}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_2.join(" ")}>適用年月日</div>
                                    <div className={style.cs_th}>税率１</div>
                                    <div className={style.cs_th}>税率2</div>
                                    <div className={style.cs_th}>税率3</div>
                                    <div className={style.cs_th}>税率4</div>
                                    <div className={style.cs_th}>税率5</div>
                                    <div className={style.cs_th}>税率6</div>
                                    <div className={style.cs_th}>税率7</div>
                                    <div className={style.cs_th}>税率8</div>
                                    <div className={style.cs_th}>税率9</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_2.join(" ")}>
                                            <InputDatepicker 
                                                value={data['apply_date']} 
                                                onChange={(e) => handlChangeData(e,2,'apply_date',index)} focusId='input2' />
                                        </div>
                                        <div className={style.cs_td}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['tax_1']} 
                                                id='input2' onKeyDown={(e)=>onDataKeyDown(e, 3)}
                                                onChange={(e) => handlChangeData(e,1,'tax_1',index)} />
                                        </div>
                                        <div className={style.cs_td}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['tax_2']} 
                                                id='input3' onKeyDown={(e)=>onDataKeyDown(e, 4)}
                                                onChange={(e) => handlChangeData(e,1,'tax_2',index)} />
                                        </div>
                                        <div className={style.cs_td}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['tax_3']} 
                                                id='input4' onKeyDown={(e)=>onDataKeyDown(e, 5)}
                                                onChange={(e) => handlChangeData(e,1,'tax_3',index)} />
                                        </div>
                                        <div className={style.cs_td}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['tax_4']} 
                                                id='input5' onKeyDown={(e)=>onDataKeyDown(e, 6)}
                                                onChange={(e) => handlChangeData(e,1,'tax_4',index)} />
                                        </div>
                                        <div className={style.cs_td}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['tax_5']} 
                                                id='input6' onKeyDown={(e)=>onDataKeyDown(e, 7)}
                                                onChange={(e) => handlChangeData(e,1,'tax_5',index)} />
                                        </div>
                                        <div className={style.cs_td}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['tax_6']} 
                                                id='input7' onKeyDown={(e)=>onDataKeyDown(e, 8)}
                                                onChange={(e) => handlChangeData(e,1,'tax_6',index)} />
                                        </div>
                                        <div className={style.cs_td}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['tax_7']} 
                                                id='input8' onKeyDown={(e)=>onDataKeyDown(e, 9)}
                                                onChange={(e) => handlChangeData(e,1,'tax_7',index)} />
                                        </div>
                                        <div className={style.cs_td}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['tax_8']} 
                                                id='input9' onKeyDown={(e)=>onDataKeyDown(e, 10)}
                                                onChange={(e) => handlChangeData(e,1,'tax_8',index)} />
                                        </div>
                                        <div className={style.cs_td}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['tax_9']} 
                                                id='input10' onKeyDown={(e)=>onDataKeyDown(e, 11)}
                                                onChange={(e) => handlChangeData(e,1,'tax_9',index)} />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='registerBut' onClick={handleOpen} >登録する</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Tax;