/* eslint-disable eqeqeq */
import style from './Summary.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '../../../components/common/modal/Modal';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import SummaryModal from '../../../components/common/modal/SummaryModal';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

const Summary = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Summary ID
    const [SysSummaryId, setSysSummaryId] = useState(0);
    const [SummaryCode, setSummaryCode] = useState("");
    const handleChangeSummaryCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setSummaryCode(str);
    };
    const onKeyDownSummaryCode = (event) => {
        if(event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(5, event.target.value)
            setSummaryCode(deg)
            document.getElementById('input2').focus();
            if(deg != "") {
                const data_r = {
                    userID: userData['id'],
                    summary_code: deg
                };
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstSummaryEndpoint;
                axios
                .post(url, data_r, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if(t_data.length > 0) {
                        setSummaryData(t_data[0]);
                    }else{
                        setSummaryData([]);
                    }
                })
                .catch((error) => {
                });
            }else{
                setSummaryData([]);
            }
            event.preventDefault();
        }
    };
    
    const [SummaryName, setSummaryName] = useState("");
    const handleChangeSummaryName = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setSummaryName(str);
    };
    const [SummaryNameKana, setSummaryNameKana] = useState("");
    const handleChangeSummaryNameKana = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setSummaryNameKana(str);
    };
    
    const setSummaryData = (result) => {
        if(result['sys_summary_id']) {
            setSysSummaryId(result['sys_summary_id']);
            setSummaryCode(result['summary_code']);
            setSummaryName(result['summary_name']);
            setSummaryNameKana(result['summary_name_kana']);
        }else{
            setSysSummaryId(0);
            setSummaryName("");
            setSummaryNameKana("");
        }
    }

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_master_flag_12'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysSummaryId>0) {
            data['sys_summary_id'] = SysSummaryId;
        }

        if(SummaryCode != "") {
            data['summary_code'] = SummaryCode;
        }else{
            toast.error("コードを入力してください。", {autoClose:3000});       // please enter pattern code
            setOpen(false);
            return;
        }
        if(SummaryName != "") {
            data['summary_name'] = SummaryName;
        }else{
            toast.error("名称を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(SummaryNameKana != "") {
            data['summary_name_kana'] = SummaryNameKana;
        }else{
            toast.error("フリガナを入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setMstSummaryEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data == 1) {
                    toast.success("登録致しました。", {autoClose:3000});
                }else if(response.data.data == 2){
                    toast.error("すでに登録済みです。", {autoClose:3000});
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
                    
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else{
                toast.error("登録に失敗しました。", {autoClose:3000});
            }
        });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if(SysSummaryId>0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        if(SysSummaryId>0) {
            let data = {};
            data['userID'] = userData['id'];
            data['sys_summary_id'] = SysSummaryId;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.delMstSummaryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    setSummaryCode("");
                    setSummaryData([]);
                }
            })
            .catch((error) => {
            });

            setSummaryCode("");
            setSummaryData([]);
        }
    }
    // ========================== starts code for search ===============================
    const [SearchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState([]);
    const loadModalData = (event) => {
        event.preventDefault();
        let code = SummaryCode;
        setSearchValue(code);

        if(code != ""){
            const data = {
                userID: userData['id'],
                summary_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstSummaryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData(response.data.data);
                setShowModal(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData([]);
            setShowModal(true);
        }
    }
    
    const onDataKeyDown = (e, param) => {
        if(e.keyCode == 13){
            switch(param){
                case 4 :
                    document.getElementById('registerBut').focus();
                    break;
                case 2 :
                    document.getElementById('input2').focus();
                    break;
                case 3 :
                    document.getElementById('input3').focus();
                    break;
                default :
            }
        }
    }
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <SummaryModal show={showModal} onClose={()=>setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} onChange={setSearchValue} 
                tableData={tableData} setTableData={setTableData}
                target={setSummaryData} />
            <Modal2 title="摘要削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>摘要マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownSummaryCode(e)} id='input1'
                                        value={SummaryCode} onChange={handleChangeSummaryCode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalData(e)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>名称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}  
                                        id='input2' onKeyDown={(e)=>onDataKeyDown(e, 3)}
                                        value={SummaryName} onChange={handleChangeSummaryName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>フリガナ</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input3' onKeyDown={(e)=>onDataKeyDown(e, 4)}
                                        value={SummaryNameKana} onChange={handleChangeSummaryNameKana}  />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='registerBut' onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} disabled={SysSummaryId>0?false:true}>削除</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Summary;