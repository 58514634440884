/* eslint-disable eqeqeq */
import style from './DefaultWorker.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import WorkerModal from '../../../components/common/modal/WorkerModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import { useNavigate } from "react-router-dom";

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

const DefaultWorker = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    // =================================================================================

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);
            if (menuPatternData['menu_worker_manage_flag_1'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            csloading(true);
            const data = {
                userID: userData['id']
            };

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getDefalutWorkerEndPoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        console.log(response);
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data;
                            console.log(result);
                            setDefalutTableData(result);
                        } else {
                            setDefalutTableData([]);
                        }
                        csloading(false);
                    }
                })
                .catch((error) => {
                    csloading(false);
                });
            }
    });

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        if(WorkerId == 0){
            toast.error("作業員を選択してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [delOpen, setDelOpen] = React.useState(false);
    const [delId, setDelId] = React.useState(0);

    const handleDelOpen = (e, id) => {
        setDelId(id);
        setDelOpen(true);
    };
    const handleDelClose = () => {
        setDelOpen(false);
    };

    const delDefaultWorker = (event) => {
        event.preventDefault();

        const data = {
            userID: userData['id'],
            sys_id: delId
        };

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.delDefalutWorkerEndPoint;
        axios
        .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
        .then((response) => {
            if (response.status == 200) {
                useJwt.setToken(response.data.token);
                if (response.data.data.length > 0) {
                    let result = response.data.data;
                    setDefalutTableData(result);
                    setDelId(0);
                } else {
                    setDefalutTableData([]);
                }
            }
        })
        .catch((error) => {
        });
        setDelOpen(false);
    };
    const [defalutTableData, setDefalutTableData] = useState([]);


    // waerhouse in worker --------------------------------------------------
    const [WarehouseName, setWarehouseName] = useState("");

    // worker --------------------------------------------------
    const [WorkerName, setWorkerName] = useState("");
    const [WorkerId, setWorkerId] = useState(0);
    const handleChangeWorkerName = (event) => {
        setWorkerName(event.target.value);
    };

    const onKeyDownWorkerName = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getWorkerData(event.target.value);
            event.preventDefault();
        }
    };
    const getWorkerData = (name) => {
        if (name != "") {
            const data = {
                userID: userData['id'],
                name: name
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getDefalutWorkerEndPoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setDefalutTableData(result);
                        } else {
                            setDefalutTableData([]);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setDefalutTableData([]);
        }
    }
    const setWorkerData = (data) => {
        console.log('worker',data);
        if (data['user_family_nm_kanji']) {
            setWorkerId(data['id'])
            setWorkerName(data['user_family_nm_kanji'] + data['user_first_nm_kanji']);
            setWarehouseName(data['warehouse_name']);
        } else {
            setWorkerName("");
            setWarehouseName("");
        }
    }
    // ----------------------------- Worker modal -------------------------------------
    const [showModalWorker, setShowModalWorker] = useState(false);
    const [WorkerSearchValue, setWorkerSearchValue] = useState("");
    const [WorkerTableData, setWorkerTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWorkerModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let name = WorkerName;
        setWorkerSearchValue(name);

        if (name != "") {
            let data = {};
            data['userID'] = userData['id'];
            if (name != "") {
                data['name'] = name;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWorkerListEndPoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWorkerTableData(response.data.data);
                    setShowModalWorker(true);
                })
                .catch((error) => {
                });
        } else {
            setWorkerTableData([]);
            setShowModalWorker(true);
        }
    }

    const workerRegiste = (event) => {
        event.preventDefault();

        const data = {
            userID: userData['id'],
            user_id: userData['id'],
            worker_id: WorkerId
        };

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setDefalutWorkerEndPoint;
        axios
        .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
        .then((response) => {
            if (response.status == 200) {
                useJwt.setToken(response.data.token);
                if (response.data.data.length > 0) {
                    let result = response.data.data;
                    setDefalutTableData(result);
                    setWorkerId(0);
                    setWorkerName("");
                    setWarehouseName("");
                } else {
                    setDefalutTableData([]);
                }
            }
        })
        .catch((error) => {
        });
        setOpen(false);
    }

    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row, style.job_row_align];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_bl_n = [gstyle.cs_input, style.job_time];

    const style_col_span = [gstyle.cs_col_3, style.job_span];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];

    const style_th_w1p = [style.cs_th];
    const style_th_w3p = [style.cs_th];

    const style_td_w1p = [style.cs_td, style.td_align];
    const style_td_w3p = [style.cs_td, gstyle.cs_flex, style.td_align];

    const style_btn_new = [gstyle.btn_new, style.btn_mt];
    const style_btn_delete = [gstyle.btn_delete2, style.btn_up];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WorkerModal show={showModalWorker} onClose={() => setShowModalWorker(false)} width={'70%'}
                SearchValue={WorkerSearchValue} onChange={setWorkerSearchValue}
                tableData={WorkerTableData} setTableData={setWorkerTableData}
                target={setWorkerData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>所属作業員登録</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 作業者 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_span.join(" ")}>
                            <span className={style.cs_span}>作業者</span>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownWorkerName(e, 1, 1)}
                                            value={WorkerName} onChange={handleChangeWorkerName} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadWorkerModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        {/* button 登録 */}
                        <div >
                        <button className={style.btn_new} onClick={handleOpen}>作業員追加</button>
                        <button className={style.btn_delete} onClick={(e) => navigate('/workermanagement/schedulemanagement')}>戻 る</button>
                        </div>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={(e) => workerRegiste(e)} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={delOpen} onClose={handleDelClose}>
                        <DialogTitle> たデータを削除しますか？ </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleDelClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={(e) => delDefaultWorker(e)} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

            <div className={gstyle.cs_card_1}>
                <div className={style_item_tbl.join(" ")}>
                    <div className={style.cs_thead}>
                        <div className={style.cs_tr}>
                            <div className={style_th_w1p.join(" ")}>氏名</div>
                            <div className={style_th_w1p.join(" ")}>かな</div>
                            <div className={style_th_w1p.join(" ")}>営業所</div>
                            <div className={style_th_w1p.join(" ")}>電話番号</div>
                            <div className={style_th_w1p.join(" ")}></div>
                        </div>
                    </div>
                    <div className={style.cs_tbody} id="tbodyWrap">
                        {defalutTableData?.map((data) => (
                            <div className={style.cs_tr} key={data['sys_id']}  >
                                <div className={style_td_w1p.join(" ")}>
                                    {data['user_family_nm_kanji'] + data['user_first_nm_kanji']}
                                </div>
                                <div className={style_td_w1p.join(" ")}>
                                    {data['user_family_nm_kana'] + data['user_first_nm_kana']}
                                </div>
                                <div className={style_td_w1p.join(" ")}>
                                    {data['warehouse_name']}
                                </div>
                                <div className={style_td_w1p.join(" ")}>
                                    {data['user_tel_1']}
                                </div>
                                <div className={style_td_w1p.join(" ")}>
                                    <button className={style_btn_delete.join(" ")}onClick={(e) => handleDelOpen(e, data['sys_id'])}>作業員削除</button>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

        </div>
    )
}

export default DefaultWorker;
