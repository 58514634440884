//====================================================================================================
//	在庫移動処理 -> AdobeXD 23ページ
//	作成日:2022/08/25		最終更新日:2022/08/26
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
// ---------- テーブルの見出し 上段 ----------
import style from './TableLabelTop.module.css';
import ItemsNumberLabel from '../08_items_number/label/Label';
import ShipperItemsNumberLabel from '../09_shipper_items_number/label/Label';
import ItemsNameLabel from '../10_items_name/label/Label';
import LotNumberLabel from '../11_lot_number/label/Label';
import LotNameLabel from '../12_lot_name/label/Label';

const TableLabelTop = ()=> {
    return (
        <thead>
            <tr className={ style.flex }>
                <ItemsNumberLabel />
                <ShipperItemsNumberLabel />
                <ItemsNameLabel />
                <LotNumberLabel />
                <LotNameLabel />
            </tr>
        </thead>
    )
}

export default TableLabelTop;