/* eslint-disable eqeqeq */
import style from './Bus.module.css';
import gstyle from '../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal2 from '../../components/common/modal/Modal2';
import { Button } from 'antd';
import InputDatepicker from '../../components/common/input/InputDatepicker';

const TransportContact = () => {

    const navigate = useNavigate();

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());

    const [loaded, setLoaded] = useState(false);
    const [showModalSchedule, setShowModalSchedule] = useState(false);

    const csLoading = (flag) => {
        if (flag) {
            document.getElementById('csLoader').style.display = "flex";
        } else {
            document.getElementById('csLoader').style.display = "none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        setMainTableData([]);
        setMainTableData(result);
    }

    const [driverData, setDriverData] = useState([]);

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            if (menuPatternData['contact_flag_1'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            scheduleSearch();
            setLoaded(true);
        }
    });

    const [inoutData, setInoutData] = useState([]);
    const loadModalSchedule = (event, id, carrierName, carrierCode, carrierACode, carNumber, carStandard, phoneNumber,
        itemName, transportId, driverId) => {
        event.preventDefault();
        setShowModalSchedule(true);

        let tempData = {};
        tempData['carrier_name'] = carrierName;
        tempData['carrier_code'] = carrierCode;
        tempData['carrier_a_code'] = carrierACode;
        tempData['car_number'] = carNumber;
        tempData['car_standard'] = carStandard;
        tempData['phone_number'] = phoneNumber;
        tempData['item_name'] = itemName;
        tempData['transport_id'] = transportId;
        tempData['driver_id'] = driverId;
        tempData['flag'] = 2;
        tempData['command_id'] = id;

        setInoutData(tempData);
    }

    const driverSchedule = (event) => {
        event.preventDefault();
        setShowModalSchedule(false);
        navigate("/bus/schedule/registry", {
            state: {
                data: inoutData,
            },
            replace: false,
        });
    }

    const handleSearch = (e) => {
        scheduleSearch();
    }

    const scheduleSearch = () => {
        let data = {};
        data['userID'] = userData['id'];
        data['command_date'] = scheduleDate;

        csLoading(true);
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getInOutCommandByDriver;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if (response.status == 200) {
                    gridMainTable(response.data.data);
                }
                csLoading(false);
            })
            .catch((error) => {
                csLoading(false);
            });
    }

    const [scheduleDate, setScheduleDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeScheduleDate = (date) => {
        setScheduleDate(useJwt.formatDate(date));
    }

    // style array
    const style_row = [gstyle.cs_row];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_th_col_1 = [style.cs_th, style.wp10, style.td_item_align];
    const style_th_col_2 = [style.cs_th, style.wp20, style.td_item_align];
    const style_th_col_3 = [style.cs_th, style.wp30, style.td_item_align];
    const style_td_col_1 = [style.cs_td, style.wp10, style.td_item_align];
    const style_td_col_2 = [style.cs_td, style.wp20, style.td_item_align];
    const style_td_col_3 = [style.cs_td, style.wp30, style.td_item_align];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csLoader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <Modal2 title="バース予約" show={showModalSchedule} onClose={() => setShowModalSchedule(false)} width={'40%'}
                onConfirm={driverSchedule}>
                <p>ドライバーの予約をしますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>運送会社通知報告</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* コード */}
                    <div className={style_row.join(" ")} style={{ marginLeft: 55 }}>
                        <div className={style_col_2.join(" ")}>
                            <InputDatepicker value={scheduleDate} onChange={handleChangeScheduleDate} />
                        </div>
                        <div className={style_col_3.join(" ")} style={{ marginLeft: 30 }}>
                            <Button type="primary" style={{ width: 100 }} onClick={(e) => handleSearch(e)} >検索</Button>
                        </div>
                    </div>
                    <div className={gstyle.hyfiled} style={{ marginTop: 30 }}>
                        <div style={{ width: '100%' }}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_1.join(" ")}>入出庫区分</div>
                                    <div className={style_th_col_1.join(" ")}>入出庫時間</div>
                                    <div className={style_th_col_2.join(" ")}>荷主名</div>
                                    <div className={style_th_col_2.join(" ")}>運送会社名</div>
                                    <div className={style_th_col_1.join(" ")}>車番号</div>
                                    <div className={style_th_col_1.join(" ")}>車種</div>
                                    <div className={style_th_col_1.join(" ")}>携帯電話</div>
                                    <div className={style_th_col_2.join(" ")}>商品名</div>
                                    <div className={style_th_col_1.join(" ")}>数量</div>
                                    <div className={style_th_col_1.join(" ")}></div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {mainTableData.map((data, index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['inout_type'] == "1" ? '入庫指示' : '出庫指示'}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['start_date']}
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            {data['shipper_name']}
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            {data['carrier_name']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['car_number']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['car_type'] == "1" ? '4t' : data['car_type'] == "2" ? '10t' : data['car_type'] == "3" ? 'トレーラー' : ''}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['driver_phone']}
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            {data['item_name']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            {data['item_num']}
                                        </div>
                                        <div className={style_td_col_1.join(" ")}>
                                            <Button type="primary" style={{ width: 80 }} onClick={(e) => loadModalSchedule(e, data['id'], data['carrier_name'],
                                                data['carrier_code'], data['carrier_a_code'], data['car_number'],
                                                data['car_type'], data['driver_phone'], data['item_name'], data['transport_id'], data['driver_id'])} >予約</Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransportContact;