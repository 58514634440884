//====================================================================================================
//	在庫移動処理 -> AdobeXD 23ページ
//	作成日:2022/08/25		最終更新日:2022/08/26
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
// ----------入力用テーブル ----------
import style from './InputTable.module.css';
import TableLabelTop from '../102_table_label_top/TableLabelTop';
import TableLabelBottom from '../103_table_label_bottom/TableLabelBottom';
import TableTextTop from '../104_table_text_top/TableTextTop';
import TableTextBottom from '../105_table_text_bottom/TableTextBottom';

const InputTable = ()=> {
    // tab_indexの初期値
    const INIT_INDEX = 8;
    // テーブルの横列数
    const COLUMN_NUM = 6;
    // テーブル上段の要素数
    const TOP_ITEMS_COUNT = 5;
    // テーブル下段の要素数
    const BOTTOM_ITEMS_COUNT = 7;
    
    // 引数の丸め込み
    const checkNum = ( num )=> {
        if( num > COLUMN_NUM ){
            return COLUMN_NUM;
        }else if( num < 0 ){
            return 1;
        }

        return num;
    }
    
    // topCount     :この要素より前にある[ 上段 ]の横列数
    // bottomCount  :この要素より前にある[ 下段 ]の横列数
    const calcTabIndex = ( topCount, bottomCount )=> {
        // 上下に存在する要素数 = 引数の丸め込み * 上下の要素の定数
        const top = checkNum( topCount ) * TOP_ITEMS_COUNT;
        const bottom = checkNum( bottomCount ) * BOTTOM_ITEMS_COUNT;

        // tabIndexの開始位置を返す
        return INIT_INDEX + top + bottom;
    }

    const COUNT = [ 0, 1, 2, 3, 4, 5 ];

    // 上下テーブルを一括で適用
    return (
        <>
            <div className={style.input_area}>
                <table className={style.table}>
                    <TableLabelTop />
                    <tbody>
                        {COUNT.map((data)=> (
                            <TableTextTop tab_index={calcTabIndex( data, data )} name_id={data + 1} key={calcTabIndex( data, data )}/>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className={style.input_area}>
                <table className={style.table}>
                    <TableLabelBottom />
                    <tbody>
                        {COUNT.map((data)=> (
                            <TableTextBottom tab_index={calcTabIndex( data + 1, data )} name_id={data + 1} key={calcTabIndex( data, data )}/>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default InputTable;