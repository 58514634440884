import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/common/layout/Layout';
import Top from './pages/top/Top';
// import ReceivingEntry from './pages/receivingEntry/ReceivingEntry';
// import ShippingEntry from './pages/shippingEntry/ShippingEntry';
import RecomposeLot from './pages/recompose_lot/RecomposeLot';
import MoveInventory from './pages/MoveInventory/MoveInventory';
import Administrator from './pages/administrator/Administrator';
import Billing from './pages/billing/Billing';
import CarryForward from './pages/carryForward/CarryForward';
import Inventory from './pages/inventory/Inventory';
import Master from './pages/master/Master';
import Setting from './pages/setting/Setting';

import WorkerManagement from './pages/workerManagement/WorkerManagement';
import JobRegistory from './pages/workerManagement/jobRegistory/JobRegistory';
import UserIndex from './pages/workerManagement/userRegistory/UserIndex';
import UserRegistory from './pages/workerManagement/userRegistory/UserRegistory';
import ScheduleManagement from './pages/workerManagement/scheduleManagement/ScheduleManagement';
import DefaultWorker from './pages/workerManagement/scheduleManagement/DefaultWorker';

import Login from './pages/login/Login';
import StockScreenReference from './pages/StockScreenReference/StockScreenReference';
import ReceivingAndPaymentReference from './pages/ReceivingAndPaymentReference/ReceivingAndPaymentReference';

import ArrivalSlip from './pages/DailyWork/ArrivalSlip/ArrivalSlip';
import LeavingSlip from './pages/DailyWork/LeavingSlip/LeavingSlip';
import FreightageSlip from './pages/DailyWork/FreightageSlip/FreightageSlip';
import Deposits from './pages/DailyWork/Deposits/Deposits';
import StockMoveSlip from './pages/DailyWork/StockMoveSlip/StockMoveSlip';
import ChangeAmountSlip from './pages/DailyWork/ChangeAmount/ChangeAmountSlip';
import ArrivalSlipList from './pages/DailyWork/ArrivalSlipList/ArrivalSlipList';
import LeavingSlipList from './pages/DailyWork/LeavingSlipList/LeavingSlipList';
import FreightageSlipList from './pages/DailyWork/FreightageSlipList/FreightageSlipList';
import DepositList from './pages/DailyWork/DepositList/DepositList';
import StockMoveSlipList from './pages/DailyWork/StockMoveSlipList/StockMoveSlipList';
import ChangeAmountList from './pages/DailyWork/ChangeAmountList/ChangeAmountList';
import ArrivalSlipItemList from './pages/DailyWork/ArrivalSlipItemList/ArrivalSlipItemList';
import PickingList from './pages/DailyWork/PickingList/PickingList';
import LeavingItemList from './pages/DailyWork/LeavingItemList/LeavingItemList';
import InvoiceItemList from './pages/DailyWork/InvoiceItemList/InvoiceItemList';
import InvoiceItemWeightList from './pages/DailyWork/InvoiceItemWeightList/InvoiceItemWeightList';
import InvoiceItemVolumeList from './pages/DailyWork/InvoiceItemVolumeList/InvoiceItemVolumeList';
import TagItemList from './pages/DailyWork/TagItemList/TagItemList';

import MainSetting from './pages/setting/MainSetting/MainSetting';
import InvoiceSetting from './pages/setting/InvoiceSetting/InvoiceSetting';
import ShipperBalanceSetting from './pages/setting/ShipperBalanceSetting/ShipperBalanceSetting';
import StockBalanceSetting from './pages/setting/StockBalanceSetting/StockBalanceSetting';
import MenuPatternSetting from './pages/setting/MenuPatternSetting/MenuPatternSetting';
import UserRegister from './pages/setting/UserRegister/UserRegister';

import Freightage from './pages/master/Freightage/Freightage';
import Summary from './pages/master/Summary/Summary';
import Category from './pages/master/Category/Category';
import Warehouse from './pages/master/Warehouse/Warehouse';
import Consignor from './pages/master/Consignor/Consignor';
import Item from './pages/master/Item/Item';
import ItemType from './pages/master/ItemType/ItemType';
import Rot from './pages/master/Rot/Rot';
import Area from './pages/master/Area/Area';
import Distination from './pages/master/Distination/Distination';
import Carrier from './pages/master/Carrier/Carrier';
import Location from './pages/master/Location/Location';
import Shipper from './pages/master/Shipper/Shipper';
import ContactShipper from './pages/master/ContactShipper/Shipper';
import Tax from './pages/master/Tax/Tax';
import ShipperItem from './pages/master/ShipperItem/ShipperItem';
import PriceRevision from './pages/master/PriceRevision/PriceRevision';
import ItemCopy from './pages/master/ItemCopy/ItemCopy';

import StockListByItem from './pages/Stock/StockListByItem/StockListByItem';
import StockHistoryByItem from './pages/Stock/StockHistoryByItem/StockHistoryByItem';
import StockHistoryByDate from './pages/Stock/StockHistoryByDate/StockHistoryByDate';
import StockDailyReport from './pages/Stock/StockDailyReport/StockDailyReport';
import StockListByShipper from './pages/Stock/StockListByShipper/StockListByShipper';
import StockListByType from './pages/Stock/StockListByType/StockListByType';
import StockListByWarehouse from './pages/Stock/StockListByWarehouse/StockListByWarehouse';
import StockListByWarehouse2 from './pages/Stock/StockListByWarehouse2/StockListByWarehouse2';

import BillingList from './pages/billing/BillingList/BillingList';
import BillingStatement from './pages/billing/BillingStatement/BillingStatement';
import BillingStatement2 from './pages/billing/BillingStatement2/BillingStatement2';
import FreightageList from './pages/billing/FreightageList/FreightageList';
import BillingQuantityList from './pages/billing/BillingQuantityList/BillingQuantityList';

import ItemStorageMonthReport from './pages/administrator/ItemStorageMonthReport/ItemStorageMonthReport';
import WarehouseStorageMonthReport from './pages/administrator/WarehouseStorageMonthReport/WarehouseStorageMonthReport';
import ShipperInOutList from './pages/administrator/ShipperInOutList/ShipperInOutList';
import ShipperInOutDailyList from './pages/administrator/ShipperInOutDailyList/ShipperInOutDailyList';
import WarehouseInOutList from './pages/administrator/WarehouseInOutList/WarehouseInOutList';
import WarehouseInOutDailyList from './pages/administrator/WarehouseInOutDailyList/WarehouseInOutDailyList';
import DestinationOutList from './pages/administrator/DestinationOutList/DestinationOutList';

import MonthCarryForward from './pages/carryForward/MonthCarryForward/MonthCarryForward';
import YearHistoryMake from './pages/carryForward/YearHistoryMake/YearHistoryMake';
import YearHistoryReference from './pages/carryForward/YearHistoryReference/YearHistoryReference';

import CsvImport from './pages/csvImport/CsvImport'
import ShipperImport from './pages/csvImport/ShipperImport'
import ShipperItemImport from './pages/csvImport/ShipperItemImport'
import CarrierImport from './pages/csvImport/CarrierImport'
import CategoryImport from './pages/csvImport/CategoryImport'
import ItemTypeImport from './pages/csvImport/ItemTypeImport'
import ItemImport from './pages/csvImport/ItemImport'
import ShipperItemCodeImport from './pages/csvImport/ShipperItemCodeImport'
import ConsignorImport from './pages/csvImport/ConsignorImport'
import FreightageImport from './pages/csvImport/FreightageImport'
import AreaImport from './pages/csvImport/AreaImport'
import SummaryImport from './pages/csvImport/SummaryImport'
import DestinationImport from './pages/csvImport/DestinationImport'
import PriceDayImport from './pages/csvImport/PriceDayImport'
import PriceItemImport from './pages/csvImport/PriceItemImport'
import ItemsImport from './pages/csvImport/ItemsImport'
import RotImport from './pages/csvImport/RotImport'
import WarehouseImport from './pages/csvImport/WarehouseImport';
import LocationImport from './pages/csvImport/LocationImport';

import RegistryBoth from './pages/registry/RegistryBoth'
import RegistryWarehouse from './pages/registry/RegistryWarehouse'
import RegistryShipper from './pages/registry/RegistryShipper'
import RegistryShipperNext from './pages/registry/RegistryShipperNext'
import RegistryShipperEnd from './pages/registry/RegistryShipperEnd'
import RegistryWarehouseEnd from './pages/registry/RegistryWarehouseEnd'

import BrokenList from './pages/brokenReport/BrokenList';

import ShipperLayout from './components/common/layout/ShipperLayout';
import Subscription from './pages/shipperJob/Subscription';
import Command from './pages/shipperJob/Command';
import ShipperBusScheduleView from './pages/shipperJob/Bus/BusScheduleView';

import QRCode from './pages/qrcode/QRCode';
import WarehouseQRcode from './pages/DailyWork/LocationQRcode/Warehouse';
import LocationQRcode from './pages/DailyWork/LocationQRcode/Location';
import JobQRcode from './pages/DailyWork/JobQRcode/JobQRcode';
import SubscriptionEnter from './pages/subscription/SubscriptionEnterList';
import ShipperForgetPassword from './pages/login/ShipperForgetPassword';
import WarehouseForgetPassword from './pages/login/WarehouseForgetPassword';
import ForgetPasswordChange from './pages/login/ForgetPassword';

import Bus from './pages/Bus/Bus';
import BusSetting from './pages/Bus/BusSetting';
import DriverRegistry from './pages/Bus/DriverRegistry';
import BusScheduling from './pages/Bus/BusScheduling';
import BusNotification from './pages/Bus/BusNotification';
import BusScheduleView from './pages/Bus/BusScheduleView';
import BusListIndex from './pages/Bus/BusListIndex';

import TransportContact from './pages/contact/TransportContact';
import Contact from './pages/contact/Contact';
import Driver from './pages/driver/Driver';
import DriverConfirm from './pages/driver/DriverConfirm';
import DriverScheduleView from './pages/driver/ScheduleView';
import DriverWait from './pages/driver/DriverWait';
import Working from './pages/driver/Working';
import WorkEnd from './pages/driver/WorkEnd';
import NewDriverWait from './pages/driver/NewDriverWait';

import CarrierShipper from './pages/shipperJob/Carrier/Carrier';
import StockImport from './pages/csvImport/StockImport';
import StocksByWarehouse from './pages/shipperJob/StocksByWarehouse/StocksByWarehouse';
import ShipperItemMaster from './pages/shipperJob/Item/Item';
import ContactItemMaster from './pages/master/ItemShipper/Item';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/password'}>
                    <Route path={'/password/:token/:user'} element={<ForgetPasswordChange />} />
                </Route>
                <Route path={'/forget'}>
                    <Route path={'/forget/shipper'} element={<ShipperForgetPassword />} />
                    <Route path={'/forget/warehouse'} element={<WarehouseForgetPassword />} />
                </Route>
                <Route path={'/login'}>
                    <Route index element={<Login />} />
                </Route>
                <Route path={'/registry'}>
                    <Route index element={<RegistryBoth />} />
                    <Route path={'/registry/warehouse/entry'} element={<RegistryWarehouse />} />
                    <Route path={'/registry/warehouse/end'} element={<RegistryWarehouseEnd />} />
                    <Route path={'/registry/shipper/entry'} element={<RegistryShipper />} />
                    <Route path={'/registry/shipper/entry/next'} element={<RegistryShipperNext />} />
                    <Route path={'/registry/shipper/end'} element={<RegistryShipperEnd />} />
                </Route>
                <Route path={'/driver'}>
                    <Route path={'/driver/:warehouse/:date'}  element={<Driver />} />
                    <Route path={'/driver/confirm'} element={<DriverConfirm />} />
                    <Route path={'/driver/scheduleView'} element={<DriverScheduleView />} />
                    <Route path={'/driver/driverWait'} element={<DriverWait />} />
                    <Route path={'/driver/working'} element={<Working />} />
                    <Route path={'/driver/workEnd'} element={<WorkEnd />} />
                    <Route path={'/driver/newDriverWait'} element={<NewDriverWait />} />
                </Route>
                <Route path={'/shipper'} element={<ShipperLayout />}>
                    <Route index element={<Subscription />} />
                    <Route path={'/shipper/warehouse/subscription'} element={<Subscription />} />
                    <Route path={'/shipper/inout/command'} element={<Command />} />
                    <Route path={'/shipper/stocks-by-warehouse'} element={<StocksByWarehouse />} />
                    <Route path={'/shipper/carrier'} element={<CarrierShipper />} />
                    <Route path={'/shipper/item'} element={<ShipperItemMaster />} />
                    <Route path={'/shipper/schedule/view'} element={<ShipperBusScheduleView/>}/>
                </Route>
                <Route path={'/'} element={<Layout />}>
                    <Route index element={<Top />} />
                    <Route path={'/DailyWork/arrivalSlip'} element={<ArrivalSlip />} />
                    <Route path={'/DailyWork/leavingSlip'} element={<LeavingSlip />} />
                    <Route path={'/DailyWork/freightageSlip'} element={<FreightageSlip />} />
                    <Route path={'/DailyWork/deposits'} element={<Deposits />} />
                    <Route path={'/DailyWork/stockMoveSlip'} element={<StockMoveSlip />} />
                    <Route path={'/DailyWork/changeAmountSlip'} element={<ChangeAmountSlip />} />
                    <Route path={'/DailyWork/arrivalSlipList'} element={<ArrivalSlipList />} />
                    <Route path={'/DailyWork/leavingSlipList'} element={<LeavingSlipList />} />
                    <Route path={'/DailyWork/freightageSlipList'} element={<FreightageSlipList />} />
                    <Route path={'/DailyWork/depositList'} element={<DepositList />} />
                    <Route path={'/DailyWork/stockMoveSlipList'} element={<StockMoveSlipList />} />
                    <Route path={'/DailyWork/changeAmountList'} element={<ChangeAmountList />} />
                    <Route path={'/DailyWork/arrivalSlipItemList'} element={<ArrivalSlipItemList />} />
                    <Route path={'/DailyWork/pickingList'} element={<PickingList />} />
                    <Route path={'/DailyWork/leavingItemList'} element={<LeavingItemList />} />
                    <Route path={'/DailyWork/invoiceItemList'} element={<InvoiceItemList />} />
                    <Route path={'/DailyWork/invoiceItemWeightList'} element={<InvoiceItemWeightList />} />
                    <Route path={'/DailyWork/invoiceItemVolumeList'} element={<InvoiceItemVolumeList />} />
                    <Route path={'/DailyWork/tagItemList'} element={<TagItemList />} />
                    <Route path={'/DailyWork/location/qrCode'} element={<LocationQRcode />} />
                    <Route path={'/DailyWork/warehouse/qrCode'} element={<WarehouseQRcode />} />
                    <Route path={'/DailyWork/JobQRcode/qrCode'} element={<JobQRcode />} />
                    <Route path={'/DailyWork/jobRegistry'} element={<JobRegistory />} />

                    <Route path={'/recomposeLot'} element={<RecomposeLot />} />
                    <Route path={'/moveInventory'} element={<MoveInventory />} />
                    <Route path={'/administrator'} element={<Administrator />} />
                    <Route path={'/billing'} element={<Billing />} />
                    <Route path={'/carryForward'} element={<CarryForward />} />
                    <Route path={'/inventory'} element={<Inventory />} />
                    <Route path={'/master'} element={<Master />} />
                    <Route path={'/setting'} element={<Setting />} />

                    <Route path={'/workerManagement'} element={<WorkerManagement />} />
                    <Route path={'/workerManagement/userIndex'} element={<UserIndex />} />
                    <Route path={'/workerManagement/userRegistory'} element={<UserRegistory />} />
                    <Route path={'/workermanagement/schedulemanagement'} element={<ScheduleManagement />} />
                    <Route path={'/workermanagement/defaultworker'} element={<DefaultWorker />} />


                    <Route path={'/stockScreenReference'} element={<StockScreenReference />} />
                    <Route path={'/receivingAndPaymentReference'} element={<ReceivingAndPaymentReference />} />
                    <Route path={'/main-setting'} element={<MainSetting />} />
                    <Route path={'/invoice-setting'} element={<InvoiceSetting />} />
                    <Route path={'/shipper-balance-setting'} element={<ShipperBalanceSetting />} />
                    <Route path={'/stock-balance-setting'} element={<StockBalanceSetting />} />
                    
                    <Route path={'/menu-pattern-setting'} element={<MenuPatternSetting />} />
                    <Route path={'/userRegister'} element={<UserRegister />} />


                    <Route path={'/master/freightage'} element={<Freightage />} />
                    <Route path={'/master/summary'} element={<Summary />} />
                    <Route path={'/master/category'} element={<Category />} />
                    <Route path={'/master/warehouse'} element={<Warehouse />} />
                    <Route path={'/master/consignor'} element={<Consignor />} />
                    <Route path={'/master/item'} element={<Item />} />
                    <Route path={'/master/item-type'} element={<ItemType />} />
                    <Route path={'/contact/shipper/item'} element={<ContactItemMaster/>}/>
                    <Route path={'/master/rot'} element={<Rot />} />
                    <Route path={'/master/area'} element={<Area />} />
                    <Route path={'/master/distination'} element={<Distination />} />
                    <Route path={'/master/carrier'} element={<Carrier />} />
                    <Route path={'/master/location'} element={<Location />} />
                    <Route path={'/master/shipper'} element={<Shipper />} />
                    <Route path={'/contact/shipper'} element={<ContactShipper />} />
                    <Route path={'/master/tax'} element={<Tax />} />
                    <Route path={'/master/shipperItem'} element={<ShipperItem />} />
                    <Route path={'/master/priceRevision'} element={<PriceRevision />} />
                    <Route path={'/master/itemCopy'} element={<ItemCopy />} />

                    <Route path={'/Stock/stockListByItem'} element={<StockListByItem />} />
                    <Route path={'/Stock/stockHistoryByItem'} element={<StockHistoryByItem />} />
                    <Route path={'/Stock/stockHistoryByDate'} element={<StockHistoryByDate />} />
                    <Route path={'/Stock/stockDailyReport'} element={<StockDailyReport />} />
                    <Route path={'/Stock/stockListByShipper'} element={<StockListByShipper />} />
                    <Route path={'/Stock/stockListByType'} element={<StockListByType />} />
                    <Route path={'/Stock/stockListByWarehouse'} element={<StockListByWarehouse />} />
                    <Route path={'/Stock/stockListByWarehouse2'} element={<StockListByWarehouse2 />} />

                    <Route path={'/billing/billingList'} element={<BillingList />} />
                    <Route path={'/billing/billingStatement'} element={<BillingStatement />} />
                    <Route path={'/billing/billingStatement2'} element={<BillingStatement2 />} />
                    <Route path={'/billing/freightageList'} element={<FreightageList />} />
                    <Route path={'/billing/billingQuantityList'} element={<BillingQuantityList />} />

                    <Route path={'/administrator/itemStorageMonthReport'} element={<ItemStorageMonthReport />} />
                    <Route path={'/administrator/warehouseStorageMonthReport'} element={<WarehouseStorageMonthReport />} />
                    <Route path={'/administrator/shipperInOutList'} element={<ShipperInOutList />} />
                    <Route path={'/administrator/shipperInOutDailyList'} element={<ShipperInOutDailyList />} />
                    <Route path={'/administrator/warehouseInOutList'} element={<WarehouseInOutList />} />
                    <Route path={'/administrator/warehouseInOutDailyList'} element={<WarehouseInOutDailyList />} />
                    <Route path={'/administrator/destinationOutList'} element={<DestinationOutList />} />

                    <Route path={'/carryForward/monthCarryForward'} element={<MonthCarryForward />} />
                    <Route path={'/carryForward/yearHistoryMake'} element={<YearHistoryMake />} />
                    <Route path={'/carryForward/yearHistoryReference'} element={<YearHistoryReference />} />
                    
                    <Route path={'/csvImport'} element={<CsvImport />} />
                    <Route path={'/csvImport/shipperImport'} element={<ShipperImport />} />
                    <Route path={'/csvImport/shipperItemImport'} element={<ShipperItemImport />} />
                    <Route path={'/csvImport/carrier'} element={<CarrierImport />} />
                    <Route path={'/csvImport/category'} element={<CategoryImport />} />
                    <Route path={'/csvImport/itemType'} element={<ItemTypeImport />} />
                    <Route path={'/csvImport/item'} element={<ItemImport />} />
                    <Route path={'/csvImport/shipperItemCode'} element={<ShipperItemCodeImport />} />
                    <Route path={'/csvImport/consignor'} element={<ConsignorImport />} />
                    <Route path={'/csvImport/freightage'} element={<FreightageImport />} />
                    <Route path={'/csvImport/area'} element={<AreaImport />} />
                    <Route path={'/csvImport/summary'} element={<SummaryImport />} />
                    <Route path={'/csvImport/destination'} element={<DestinationImport />} />
                    <Route path={'/csvImport/priceDay'} element={<PriceDayImport />} />
                    <Route path={'/csvImport/priceItem'} element={<PriceItemImport />} />
                    <Route path={'/csvImport/items'} element={<ItemsImport />} />
                    <Route path={'/csvImport/rot'} element={<RotImport />} />
                    <Route path={'/csvImport/warehouse'} element={<WarehouseImport />} />
                    <Route path={'/csvImport/location'} element={<LocationImport />} />
                    <Route path={'/csvImport/stock'} element={<StockImport />} />
                    
                    <Route path={'/broken/report'} element={<BrokenList />} />
                    <Route path={'/qrcode'} element={<QRCode/>} />
                    <Route path={'/warehouse/subscription'} element={<SubscriptionEnter/>} />

                    <Route path={'/bus'} element={<Bus/>}/> 
                    <Route path={'/bus/setting'} element={<BusSetting/>}/> 
                    <Route path={'/bus/driver'} element={<DriverRegistry/>}/> 
                    <Route path={'/bus/schedule/registry'} element={<BusScheduling/>}/> 
                    <Route path={'/bus/schedule/list'} element={<BusScheduleView/>}/> 
                    <Route path={'/bus/notification'} element={<BusNotification/>}/> 
                    <Route path={'/bus/schedule/index'} element={<BusListIndex/>}/>

                    <Route path={'/contact'} element={<Contact/>}/> 
                    <Route path={'/contact/transport'} element={<TransportContact/>}/> 
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
