import style from './WorkerManagement.module.css'
import HeadingText from '../../components/administrator/headingText/HeadingText';
import LinkButton from '../../components/administrator/linkButton/LinkButton';
import * as useJwt  from '../../auth/jwt/useJwt.js';

const App = () => {
	const menuPatternData = JSON.parse(useJwt.getMenuPatternData()); 
    const userData = JSON.parse(useJwt.getUserData());
    return (
        <>
            <label className='bodyLabel'>作業管理</label>
            <div className='contentBody'>
                <HeadingText color='#F8C461' title='作業管理' />
                <div className={style.links}>
                    {	(menuPatternData['menu_worker_manage_flag_1'] == 1 || userData['sys_role_id'] == 10000) &&
                        <LinkButton link='/workermanagement/schedulemanagement' title='スケジュール表' />
                    }
                    {	(menuPatternData['menu_worker_manage_flag_2'] == 1 || userData['sys_role_id'] == 10000) &&
                        <LinkButton link='/workerManagement/userIndex' title='社員リスト' />
                    }
                </div>
            </div>
        </>
    );
};

export default App;