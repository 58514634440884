/*====================================================================================================
	単位変更処理 -> AdobeXD 24ページ
	作成日:2022/08/26		最終更新日:2022/09/01
	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
====================================================================================================*/

import React from 'react';
// ---------- テーブルのテキスト 下段 ----------
import style from './TableTextBottom.module.css';
import WarehouseText from '../09_warehouse/text/Text';
import LocationText from '../10_location/text/Text';
import LotText from '../11_lot/text/Text';
import CapacityText from '../12_capacity/text/Text';
import CaseNumText from '../13_case_num/text/Text';
import SingleNumText from '../14_single_num/text/Text';
import VolumeText from '../15_volume/text/Text';
import MemoText from '../16_memo/text/Text';

const TableTextTop = (props)=> {
    return (
        <tr className={style.flex}>
            <WarehouseText tab_index={Number(props.tab_index)} name_id={props.name_id}/>
            <LocationText tab_index={Number(props.tab_index) + 1} name_id={props.name_id}/>
            <LotText tab_index={Number(props.tab_index) + 2} name_id={props.name_id}/>
            <CapacityText tab_index={Number(props.tab_index) + 3} name_id={props.name_id}/>
            <CaseNumText tab_index={Number(props.tab_index) + 4} name_id={props.name_id}/>
            <SingleNumText tab_index={Number(props.tab_index) + 5} name_id={props.name_id}/>
            <VolumeText tab_index={Number(props.tab_index) + 6} name_id={props.name_id}/>
            <MemoText tab_index={Number(props.tab_index) + 7} name_id={props.name_id}/>
        </tr>
    )
}

export default TableTextTop;