/* eslint-disable eqeqeq */
import style from './Location.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import IconSearch from '../../../components/common/icons/icon_search.png';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import { Button} from 'antd';
import $ from 'jquery';
import { QRCodeCanvas } from "qrcode.react";
import {useReactToPrint} from "react-to-print";
import { Barcode } from '@progress/kendo-react-barcodes';
import InputDatepicker from '../../../components/common/input/InputDatepicker';


const Warehouse = () => {
  
    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [currentDate, setCurrentDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeCurrentDate = (date) => {
        setCurrentDate(useJwt.formatDate(date));
        QRGenerate(useJwt.formatDate(date), WarehouseName, WarehouseId);
    };

    // ========================== print =================================================
    const QRRef = useRef();
    const [itemInformation, setItemInformation] = useState([]);


    const handlePrint = useReactToPrint({
        content: () => QRRef.current,
        pageStyle: `@media print {  
            @page {
              size: 210mm 297mm; /* landscape */
            }
          }`
    });

    const codePrint = () => {
        if(WarehouseCode == ""){
            toast.error("倉庫を選択してください。", {autoClose:3000});
            return;
        } 

        handlePrint();
    }

    const QRGenerate  = (date, name, id) =>{

        let data = [];
        let temp = {};
        temp['code'] = jwtDefaultConfig.frontendUrl + "/driver/" + id+'/'+date;
        temp['name'] = name;
        data.push(temp);
        console.log(data);

        if(data.length == 0){
            setItemInformation([]);
        }
        setItemInformation(data);
    }

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [WarehouseCode, setWarehouseCode] = useState("");
    const [WarehouseId, setWarehouseId] = useState("");
    const handleChangeWarehouseCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>2) {
            str = str.substring(0, 2);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setWarehouseCode(str);
    };
    const [WarehouseName, setWarehouseName] = useState("");

    const onKeyDownWarehouseCode = (event) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value);
            event.preventDefault();
        }
    };

    const getWarehouseData = (code) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            csloading(true);
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                csloading(false);
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result);
                    }else{
                        setWarehouseData([]);
                    }
                }
            })
            .catch((error) => {
                csloading(false);
            });
        }else{
            setWarehouseData([]);
        }
    }
    const setWarehouseData = (data) => {
        console.log(data);
        if(data['sys_warehouse_id']) {
            setWarehouseId(data['sys_warehouse_id']);
            setWarehouseCode(data['warehouse_code']);
            setWarehouseName(data['warehouse_name']);
            QRGenerate(currentDate, data['warehouse_name'], data['sys_warehouse_id']);
        }else{
            setWarehouseName("");
        }
    }

    useEffect(() => {

        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_daily_flag_27'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    // ========================== starts code for search ===============================
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const loadWarehouseModalData = (event) => {
        event.preventDefault();
        setWarehouseSearchValue(WarehouseCode);

        if(WarehouseCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(WarehouseCode != "") {
                data['warehouse_code'] = WarehouseCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    const [codeType, setCodeType] = useState("1");
    const onChangeCodeType = (e, type) => {
        setCodeType(type);
    }

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];

    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];
    const style_check_ml = [style.check_label, style.job_radio_margin];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>棚番QRコード印刷</p>
            </div>
            <div className={gstyle.filed} style={{zIndex:2}}>
                <div className={gstyle.cs_card_1}>
                    { /* 倉庫コード */}
                    <div className={style_row.join(" ")}>
                    <div className={style_col_2.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={currentDate} onChange={handleChangeCurrentDate} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownWarehouseCode(e)}
                                        value={WarehouseCode} onChange={handleChangeWarehouseCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="WarehouseName"
                                        value={WarehouseName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label></label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")} style={{display:'flex', justifyContent:'end'}}>
                            <div className={style_input_wrap.join(" ")}>
                                <div className={style_date_wrap.join(" ")} style={{margin:0, marginTop:35}}>
                                    <label className={style.check_label} style={{display:'flex'}}>
                                        <input type={'radio'} name='code' checked={codeType == 1 ? true : false} onChange={(e) => onChangeCodeType(e, 1)} />
                                        <div style={{marginLeft:5}}>QRコード</div>
                                    </label>

                                    <label style={{display: 'flex', marginLeft:20}}>
                                        <input type={'radio'} name='code' onChange={(e) => onChangeCodeType(e, 2)} checked={codeType == 2 ? true : false} />
                                        <div style={{marginLeft:5}}>バーコード</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")} style={{marginTop:32,gap:30}}>
                                <Button type={"primary"} style={{width:100, backgroundColor: '#ff6116'}} onClick={codePrint}>コード発行</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display:'none'}}>
                    <div ref={QRRef} id="QRDiv" style={{display:'flex', justifyContent:'center', alignItems:'center',width:'210mm', height:'290mm'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <div>
                                {itemInformation.map((item, index) => (
                                    <>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', fontSize:20, flexDirection:'column'}} key={"name" + index}>
                                        <p style={{fontSize:26}}>{item['name']}</p>
                                        <p style={{marginTop:20, marginBottom:10}}>いつもお疲れ様です。バース予約の際はこちらを読み込みください。</p>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'center',marginBottom:20}} key={"body" + index}>
                                    {
                                        codeType == "1" ? 
                                            <QRCodeCanvas
                                            id="qrCode"
                                            value={item['code']}
                                            size={300}
                                            bgColor={"#ffffff"}
                                            level={"H"}
                                            key={index}
                                            removeAfterPrint
                                            />
                                        :
                                        <Barcode value={item['code']} format="CODE128" width={520} height={170} key={index}/>
                                    }
                                    </div>
                                </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Warehouse;