/* eslint-disable eqeqeq */
import style from './Inventory.module.css'
import HeadingText from '../../components/administrator/headingText/HeadingText';
import LinkButton from '../../components/administrator/linkButton/LinkButton';
import * as useJwt  from '../../auth/jwt/useJwt.js';

const App = () => {
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const userData = JSON.parse(useJwt.getUserData());
    return (
        <>
		<label className='bodyLabel'>在庫管理</label>
		<div className='contentBody'>
            <HeadingText color='#F8C461' title='在庫確認' />
            <div className={style.links}>
                {	(menuPatternData['menu_stock_flag_1'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/Stock/stockListByItem' title='在庫画面' />
				}
                {	(menuPatternData['menu_stock_flag_2'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/Stock/stockHistoryByItem' title='商品受払画面' />
				}
                {	(menuPatternData['menu_stock_flag_3'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/Stock/stockHistoryByDate' title='商品受払帳' />
				}
                {	(menuPatternData['menu_stock_flag_4'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/Stock/stockDailyReport' title='在庫日報' />
				}
            </div>
            <HeadingText color='#73A776' title='報告書' />
            <div className={style.links}>
                {	(menuPatternData['menu_stock_flag_5'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/Stock/stockListByShipper' title='在庫報告書' />
				}
            </div>
            <HeadingText color='#F8C461' title='倉庫一覧管理' />
            <div className={style.links}>
                {	(menuPatternData['menu_stock_flag_6'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/Stock/stockListByType' title='在庫評価一覧表' />
				}
                {	(menuPatternData['menu_stock_flag_7'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/Stock/stockListByWarehouse' title='倉庫別在庫評価一覧表' />
				}
                {	(menuPatternData['menu_stock_flag_8'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/Stock/stockListByWarehouse2' title='倉庫別在庫一覧表' />
				}
            </div>
            </div>
        </>
    );
};

export default App;