/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import axios from "axios";
import style from './Modal.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import Modal from './Modal';
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { jaJP } from '@mui/material/locale';

const WarehouseModal = props => {

    const [loaded, setLoaded] = useState(false);

    const userData = JSON.parse(useJwt.getUserData());

    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_select_40 = [gstyle.cs_select, gstyle.w40];
    const style_input_50 = [gstyle.cs_input, gstyle.w50, gstyle.cs_bl_n];

    const theme = useTheme();

    const columns = [
        // {
        //     id: 'sys_warehouse_id',
        //     accessorKey: 'sys_warehouse_id',
        //     enableColumnActions: false,
        //     header: 'ID',
        //     size: 20
        // },
        {
            id: 'warehouse_code',
            accessorKey: 'warehouse_code',
            enableColumnActions: false,
            header: 'コード',
            size: 40
        },
        {
            id: 'warehouse_name',
            accessorKey: 'warehouse_name',
            enableColumnActions: false,
            header: '名称',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.warehouse_name}</div>
            ),
        },
        {
            id: 'warehouse_a_name',
            accessorKey: 'warehouse_a_name',
            enableColumnActions: false,
            header: '略称',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.warehouse_a_name}</div>
            ),
        },
    ];

    const [SearchFieldType, setSearchFieldType] = useState(0);
    const handleChangeSearchFieldType = (event) => {
        setSearchFieldType(event.target.value);
    }
    const handleChangeSearchValue = (event) => {
        props.onChange(event.target.value);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            searchData();
        }
    }

    const searchData = () => {
        let data = {};
        data['userID'] = userData['id'];
        data['warehouse_company_id'] = userData['id'];

        if (props.SearchValue2) data['warehouse_company_id'] = props.SearchValue2;

        if (SearchFieldType == 0) {
            data['warehouse_code'] = props.SearchValue;
        } else if (SearchFieldType == 1) {
            data['warehouse_name'] = props.SearchValue;
        } else {
            data['warehouse_a_name'] = props.SearchValue;
        }
        if (document.getElementById("WarehouseFilterByLike").checked) {
            data['filterByLike'] = 1;
        } else {
            data['filterByLike'] = 0;
        }

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;

        if (props.url) url = props.url;
        setLoaded(true);
        props.setTableData([]);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(false);
                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        //     let result = response.data.data[0];
                        //     props.target(result,props.targetParam!=null?props.targetParam:'');
                        //     props.onClose(true);
                        // }else{
                        props.setTableData(response.data.data);
                        console.log(response.data.data)
                    }

                    if (response.data.data.length == 0) toast.error("検索結果はありません。", { autoClose: 3000 });
                }
            })
            .catch((error) => {
                setLoaded(false);
                toast.error("検索結果はありません。", { autoClose: 3000 });
            });
    }
    const handleChangeData = (row) => {
        props.target(row, props.targetParam != null ? props.targetParam : '');
        props.onClose(true);
    }

    return (
        <Modal title="倉庫検索" show={props.show} onClose={props.onClose} width={props.width}>
            <div className={gstyle.hyfiled}>
                <div className={style.cs_tbl} style={{ minHeight: 610 }}>
                    <ThemeProvider theme={createTheme(theme, jaJP)}>
                        <MaterialReactTable
                            columns={columns}
                            data={props.tableData}
                            enableTopToolbar={true}
                            enableSelectAll={false}
                            enableGlobalFilter={true}
                            enableColumnFilters={false}
                            initialState={{
                                showColumnFilters: true,
                                showGlobalFilter: true,
                            }}
                            localization={MRT_Localization_JA}
                            muiTableBodyProps={{
                                sx: {
                                    '& tr:nth-of-type(odd)': {
                                        backgroundColor: '#f5f5f5',
                                        border: '1px solid #e0e0e0',
                                    },
                                    'td': {
                                        padding: '0.4rem'
                                    }
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    border: '1px solid #e0e0e0',
                                    textAlign: 'center'
                                },
                            }}
                            muiTableHeadCellProps={{
                                sx: {
                                    border: '1px solid #e0e0e0',
                                    '.Mui-TableHeadCell-Content': {
                                        display: 'flex',
                                        justifyContent: 'center',

                                    },
                                    backgroundColor: '#cfcfcf'
                                }
                            }}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {
                                    handleChangeData(row.original);
                                },
                                sx: {
                                    cursor: 'pointer',
                                },
                            })}
                            renderTopToolbarCustomActions={({ table }) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <select className={style_select_40.join(" ")} id="SearchFieldType"
                                                value={SearchFieldType}
                                                onChange={handleChangeSearchFieldType}>
                                                <option value="0">コード</option>
                                                <option value="1">名称</option>
                                                <option value="2">略称</option>
                                            </select>
                                            <input type={'text'} className={style_input_50.join(" ")}
                                                value={props.SearchValue} onKeyDown={handleKeyDown}
                                                onChange={handleChangeSearchValue} />
                                        </div>
                                    </label>
                                    <Button onClick={searchData} loading={loaded} type={'primary'} style={{ marginLeft: 30, width: 120, height: 35, borderRadius: 'unset' }}>
                                        {loaded ? '検索中' : '検索'}
                                    </Button>
                                    <label className={gstyle.cs_label}>
                                        <div className={gstyle.cs_flex}>
                                            <input type={"checkbox"} className={style.cs_input_check} id="WarehouseFilterByLike" />
                                            <span>部分検索</span>
                                        </div>
                                    </label>
                                </div>
                            )}
                        />
                    </ThemeProvider>
                </div>
            </div>
        </Modal>
    )
}
export default WarehouseModal;




