/* eslint-disable eqeqeq */
import style from './PickingList.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import ConsignorModal from '../../../components/common/modal/ConsignorModal';
import DistinationModal from '../../../components/common/modal/DistinationModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { Select } from 'antd';

const PickingList = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [showModalDel2, setShowModalDel2] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    //  自社 -----------------------------------------------
    const [SelfCompName, setSelfCompName] = useState("");
    // slip date ---------------------------------------
    const [SlipDate, setSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeSlipDate = (date) => {
        setSlipDate(useJwt.formatDate(date));
    };

    // slip no -------------------------------------------------
    const [SlipNo1, setSlipNo1] = useState("");
    const handleChangeSlipNo1 = (event) => {
        setSlipNo1(useJwt.validateNum(event.target.value,6));
    };
    const [SlipNo2, setSlipNo2] = useState("");
    const handleChangeSlipNo2 = (event) => {
        setSlipNo2(useJwt.validateNum(event.target.value,6));
    };
    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('warehouse2').focus();
            }else{
                document.getElementById('type1').focus();
            }
        }
    };
    const getWarehouseData = (code,param) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result,param);
                    }else{
                        setWarehouseData([],param);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setWarehouseData([],param);
        }
    }
    const setWarehouseData = (data,param) => {
        if(data['warehouse_code']) {
            if(param == 1) {
                setWarehouseCode1(data['warehouse_code']);
                setWarehouseName1(data['warehouse_name']);
            }else{
                setWarehouseCode2(data['warehouse_code']);
                setWarehouseName2(data['warehouse_name']);
            }
        }else{
            if(param == 1) {
                setWarehouseCode1("");
                setWarehouseName1("");
            }else{
                setWarehouseCode2("");
                setWarehouseName2("");
            }
        }
    }
    // print type ------------------------------------------------
    const [PrintType1, setPrintType1] = useState(0);
    const handleChangePrintType1 = (event) => {
        setPrintType1(event);
    };
    const [PrintType2, setPrintType2] = useState(0);
    const handleChangePrintType2 = (event) => {
        setPrintType2(event);
    };
    const [PrintType3, setPrintType3] = useState(0);
    const handleChangePrintType3 = (event) => {
        setPrintType3(event);
    };
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('type3').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ShipperACode1;
            }else{
                code = ShipperCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ShipperACode2;
            }else{
                code = ShipperCode2;
                acode = value;
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // Consignor -----------------------------------------------------
    const [ConsignorCode1, setConsignorCode1] = useState("");
    const handleChangeConsignorCode1 = (event) => {
        setConsignorCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ConsignorACode1, setConsignorACode1] = useState("");
    const handleChangeConsignorACode1 = (event) => {
        setConsignorACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ConsignorName1, setConsignorName1] = useState("");
    const [ConsignorCode2, setConsignorCode2] = useState("");
    const handleChangeConsignorCode2 = (event) => {
        setConsignorCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ConsignorACode2, setConsignorACode2] = useState("");
    const handleChangeConsignorACode2 = (event) => {
        setConsignorACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ConsignorName2, setConsignorName2] = useState("");
    
    const onKeyDownConsignorCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getConsignorData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('consignor_a_code1').focus();
                }else{
                    document.getElementById('consignor_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('consignor_a_code2').focus();
                }else{
                    document.getElementById('dis_code1').focus();
                }
            }
        }
    };
    const getConsignorData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ConsignorACode1;
            }else{
                code = ConsignorCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ConsignorACode2;
            }else{
                code = ConsignorCode2;
                acode = value;
            }
        }
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['consignor_code'] = code;
            if(acode != "") {
                data['consignor_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstConsignorEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setConsignorData(t_data[0],param1);
                }else{
                    setConsignorData([],param1);
                }
            })
            .catch((error) => {
            });
        }else{
            setConsignorData([],param1);
        }
    }
    const setConsignorData = (data,param) => {
        if(data['consignor_code']) {
            if(param == 1) {
                setConsignorCode1(data['consignor_code']);
                setConsignorACode1(data['consignor_a_code']);
                setConsignorName1(data['consignor_name']);
            }else{
                setConsignorCode2(data['consignor_code']);
                setConsignorACode2(data['consignor_a_code']);
                setConsignorName2(data['consignor_name']);
            }
        }else{
            if(param == 1) {
                setConsignorCode1("");
                setConsignorACode1("");
                setConsignorName1("");
            }else{
                setConsignorCode2("");
                setConsignorACode2("");
                setConsignorName2("");
            }
        }
    }
    // Distination -----------------------------------------------------------
    const [DistinationCode1, setDistinationCode1] = useState("");
    const handleChangeDistinationCode1 = (event) => {
        setDistinationCode1(useJwt.validateNum(event.target.value,6));
    };
    const [DistinationACode1, setDistinationACode1] = useState("");
    const handleChangeDistinationACode1 = (event) => {
        setDistinationACode1(useJwt.validateNum(event.target.value,3));
    };
    const [DistinationName1, setDistinationName1] = useState("");
    const [DistinationCode2, setDistinationCode2] = useState("");
    const handleChangeDistinationCode2 = (event) => {
        setDistinationCode2(useJwt.validateNum(event.target.value,6));
    };
    const [DistinationACode2, setDistinationACode2] = useState("");
    const handleChangeDistinationACode2 = (event) => {
        setDistinationACode2(useJwt.validateNum(event.target.value,3));
    };
    const [DistinationName2, setDistinationName2] = useState("");
    
    const onKeyDownDistinationCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getDistinationData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('dis_a_code1').focus();
                }else{
                    document.getElementById('dis_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('dis_a_code2').focus();
                }else{
                    document.getElementById('searchBut').focus();
                }
            }
        }
    };
    const getDistinationData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = DistinationACode1;
            }else{
                code = DistinationCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = DistinationACode2;
            }else{
                code = DistinationCode2;
                acode = value;
            }
        }
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = code;
            if(acode != "") {
                data['distination_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setDistinationData(t_data[0],param1);
                }else{
                    setDistinationData([],param1);
                }
            })
            .catch((error) => {
            });
        }else{
            setDistinationData([],param1);
        }
    }

    const setDistinationData = (data,param) => {
        if(data['distination_code']) {
            if(param == 1) {
                setDistinationCode1(data['distination_code']);
                setDistinationACode1(data['distination_a_code']);
                setDistinationName1(data['distination_name']);
            }else{
                setDistinationCode2(data['distination_code']);
                setDistinationACode2(data['distination_a_code']);
                setDistinationName2(data['distination_name']);
            }
        }else{
            if(param == 1) {
                setDistinationCode1("");
                setDistinationACode1("");
                setDistinationName1("");
            }else{
                setDistinationCode2("");
                setDistinationACode2("");
                setDistinationName2("");
            }
        }
    }
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_daily_flag_14'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            const data = {
                userID: userData['id'],
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstConsignorFirstRowEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200 && response.data.data.length>0) {
                    setSelfCompName(response.data.data[0]['consignor_name']);
                }
            })
            .catch((error) => {
            });
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        data['slip_date'] = SlipDate;

        data['start_slip_no'] = SlipNo1;
        data['end_slip_no'] = SlipNo2;

        data['start_warehouse_code'] = WarehouseCode1;
        data['end_warehouse_code'] = WarehouseCode2;
        
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;

        data['start_consignor_code'] = ConsignorCode1;
        data['end_consignor_code'] = ConsignorCode2;
        data['start_consignor_a_code'] = ConsignorACode1;
        data['end_consignor_a_code'] = ConsignorACode2;

        data['start_distination_code'] = DistinationCode1;
        data['end_distination_code'] = DistinationCode2;
        data['start_distination_a_code'] = DistinationACode1;
        data['end_distination_a_code'] = DistinationACode2;

        if(PrintType1>0) {
            data['print_type_1'] = PrintType1;
        }
        if(PrintType2>0) {
            data['print_type_2'] = PrintType2;
        }
        if(PrintType3>0) {
            data['print_type_3'] = PrintType3;
        }
        
        
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getPickingListEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            console.log(result);
            if(result.length>0){
                let data = [];
                let itemCodes = [];
                let header_info = [];
                for(let i=0; i<result.length; i++) {
                    let filter_code = '';
                    filter_code = ''+useJwt.formatDate(new Date(result[i]['slip_date']));
                    filter_code += '-'+result[i]['slip_no'];
                    filter_code += '-'+result[i]['shipper_code']+'-'+result[i]['shipper_a_code'];

                    if(!itemCodes.includes(filter_code)){
                        itemCodes.push(filter_code);
                        let row = {};
                        row['slip_date'] = result[i]['slip_date'];
                        row['slip_no'] = result[i]['slip_no'];
                        row['shipper_code'] = result[i]['shipper_code'];
                        row['shipper_a_code'] = result[i]['shipper_a_code'];
                        row['shipper_name'] = result[i]['shipper_name'];
                        row['shipper_set_type'] = result[i]['shipper_set_type'];

                        // 0=荷主 1=荷送人 2=自社
                        if(result[i]['shipper_set_type'] == 2) {
                            row['consignor_code'] = "000000";
                            row['consignor_a_code'] = "000";
                            row['consignor_name'] = SelfCompName; // 自社
                        } else if(result[i]['shipper_set_type'] == 0) {
                            row['consignor_code'] = "000000";
                            row['consignor_a_code'] = "000";
                            row['consignor_name'] = result[i]['shipper_name']; // 0=荷主
                        } else{
                            row['consignor_code'] = result[i]['consignor_code'];
                            row['consignor_a_code'] = result[i]['consignor_a_code'];
                            row['consignor_name'] = result[i]['consignor_name'];
                        }
                        
                        row['distination_code'] = result[i]['distination_code'];
                        row['distination_a_code'] = result[i]['distination_a_code'];
                        row['distination_name'] = result[i]['distination_name'];
                        row['carrier_code'] = result[i]['carrier_code'];
                        row['carrier_a_code'] = result[i]['carrier_a_code'];
                        row['carrier_name'] = result[i]['carrier_name'];
                        row['carrier_stool_code'] = result[i]['carrier_stool_code'];
                        header_info.push(row);
                    }
                }
                
                for(let i=0; i<itemCodes.length; i++) {
                    let row = {};
                    row['filter_code'] = itemCodes[i];
                    row['header_info'] = header_info[i];
                    row['item_info'] = [];
                    for(let j=0;j<result.length;j++) {
                        let filter_code = '';
                        filter_code = ''+useJwt.formatDate(new Date(result[j]['slip_date']));
                        filter_code += '-'+result[j]['slip_no'];
                        filter_code += '-'+result[j]['shipper_code']+'-'+result[j]['shipper_a_code'];

                        if(itemCodes[i] == filter_code) {
                            let tmp_row = result[j];
                            row['item_info'].push(tmp_row);
                        }
                    }
                    data.push(row);
                }
                // console.log(result)
                gridMainTable(data);
                setOpend(true);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                gridMainTable([]);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
        
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }
    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let data = result;
        setMainTableData(data);
        // for pagenation
        setItemOffset(0);
        const endOffset = 0 + itemsPerPage;
        setCurrentItems(data.slice(0, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }
    // starts pagenation code -----------------------------------------
    const itemsPerPage = 50;
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % mainTableData.length;
        // console.log( `User requested page number ${event.selected}, which is offset ${newOffset}` );
        setItemOffset(newOffset);
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(mainTableData.slice(newOffset, endOffset));
    };
    // ends pagenation code ---------------------------------------------
    // ========================== starts code for search ===============================
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWarehouseModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode1;
        if(param==2){
            code = WarehouseCode2;
        }
        setWarehouseSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['warehouse_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------- Consignor Modal ---------------------------------
    const [showModalConsignor, setShowModalConsignor] = useState(false);
    const [ConsignorSearchValue, setConsignorSearchValue] = useState("");
    const [ConsignorSearchValue2, setConsignorSearchValue2] = useState("");
    const [ConsignorTableData, setConsignorTableData] = useState([]);
    const loadConsignorModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ConsignorCode1;
        let acode = ConsignorACode1;
        if(param == 2) {
            code = ConsignorCode2;
            acode = ConsignorACode2;
        }
        setConsignorSearchValue(code);
        setConsignorSearchValue2(acode);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['consignor_code'] = code;
            if(acode != "") {
                data['consignor_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstConsignorEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setConsignorTableData(response.data.data);
                setShowModalConsignor(true);
            })
            .catch((error) => {
            });
        }else{
            setConsignorTableData([]);
            setShowModalConsignor(true);
        }
    }
    // ------------------------------- Distination Modal --------------------------------
    const [showModalDistination, setShowModalDistination] = useState(false);
    const [DistinationSearchValue, setDistinationSearchValue] = useState("");
    const [DistinationSearchValue2, setDistinationSearchValue2] = useState("");
    const [DistinationTableData, setDistinationTableData] = useState([]);
    const loadDistinationModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = DistinationCode1;
        let acode = DistinationACode1;
        if(param == 2) {
            code = DistinationCode2;
            acode = DistinationACode2;
        }
        setDistinationSearchValue(code);
        setDistinationSearchValue2(acode);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = code;
            if(acode != "") {
                data['distination_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setDistinationTableData(response.data.data);
                setShowModalDistination(true);
            })
            .catch((error) => {
            });
        }else{
            setDistinationTableData([]);
            setShowModalDistination(true);
        }
    }
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        setCurrentItems(mainTableData);
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText, setCurrentItems, mainTableData]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);
    
    const onSlipNoKeyDown = (e, param) =>{
        if(param == 1){
            document.getElementById('slipNo2').focus();
        }else{
            document.getElementById('warehouse1').focus();
        }
    }
    
    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type2').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('shipper_code1').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('consignor_code1').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_9 = [gstyle.cs_col, gstyle.cs_col_9];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap2 = [style.p2030];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ConsignorModal show={showModalConsignor} onClose={()=>setShowModalConsignor(false)} width={'70%'}
                SearchValue={ConsignorSearchValue} onChange={setConsignorSearchValue} 
                SearchValue2={ConsignorSearchValue2} onChange2={setConsignorSearchValue2}
                tableData={ConsignorTableData} setTableData={setConsignorTableData}
                target={setConsignorData} targetParam={TargetParam} />
            <DistinationModal show={showModalDistination} onClose={()=>setShowModalDistination(false)} width={'70%'}
                SearchValue={DistinationSearchValue} onChange={setDistinationSearchValue} 
                SearchValue2={DistinationSearchValue2} onChange2={setDistinationSearchValue2}
                tableData={DistinationTableData} setTableData={setDistinationTableData}
                target={setDistinationData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>ピッキングリスト</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 出庫年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出庫年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={SlipDate} onChange={handleChangeSlipDate} id='date2' focusId='slipNo1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 伝票 No */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始伝票 No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        value={SlipNo1} onChange={handleChangeSlipNo1} onKeyDown={(e)=>onSlipNoKeyDown(e, 1)} id='slipNo1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了伝票 No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        value={SlipNo2} onChange={handleChangeSlipNo2} onKeyDown={(e)=>onSlipNoKeyDown(e, 2)} id='slipNo2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,1)} id='warehouse1'
                                            value={WarehouseCode1} onChange={handleChangeWarehouseCode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={WarehouseName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,2)} id='warehouse2'
                                            value={WarehouseCode2} onChange={handleChangeWarehouseCode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={WarehouseName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 自社指定印字区分, 荷主指定印字区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>自社指定印字区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="自社指定印字区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangePrintType1}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={[
                                                {value:0,label:'印字有り'},
                                                {value:1,label:'印字無し'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主指定印字区分</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="荷主指定印字区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangePrintType2}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={[
                                                {value:0,label:'印字有り'},
                                                {value:1,label:'印字無し'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 荷送人指定印字区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷送人指定印字区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="荷送人指定印字区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangePrintType3}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={[
                                                {value:0,label:'印字有り'},
                                                {value:1,label:'印字無し'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 荷送人 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷送人</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                        value={ConsignorCode1} id='consignor_code1'
                                        onKeyDown={(e) => onKeyDownConsignorCode(e,1,1)}
                                        onChange={(e) => handleChangeConsignorCode1(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={ConsignorACode1} id='consignor_a_code1'
                                        onKeyDown={(e) => onKeyDownConsignorCode(e,1,2)}
                                        onChange={(e) => handleChangeConsignorACode1(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ConsignorName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadConsignorModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷送人</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                        value={ConsignorCode2} id='consignor_code2'
                                        onKeyDown={(e) => onKeyDownConsignorCode(e,2,1)}
                                        onChange={(e) => handleChangeConsignorCode2(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={ConsignorACode2} id='consignor_a_code2'
                                        onKeyDown={(e) => onKeyDownConsignorCode(e,2,2)}
                                        onChange={(e) => handleChangeConsignorACode2(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ConsignorName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadConsignorModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 届け先 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始届け先</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={DistinationCode1} id='dis_code1'
                                            onKeyDown={(e) => onKeyDownDistinationCode(e,1,1)}
                                            onChange={(e) => handleChangeDistinationCode1(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={DistinationACode1} id='dis_a_code1'
                                        onKeyDown={(e) => onKeyDownDistinationCode(e,1,2)}
                                        onChange={(e) => handleChangeDistinationACode1(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={DistinationName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadDistinationModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了届け先</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={DistinationCode2} id='dis_code2'
                                            onKeyDown={(e) => onKeyDownDistinationCode(e,2,1)}
                                            onChange={(e) => handleChangeDistinationCode2(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={DistinationACode2} id='dis_a_code2'
                                        onKeyDown={(e) => onKeyDownDistinationCode(e,2,2)}
                                        onChange={(e) => handleChangeDistinationACode2(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={DistinationName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadDistinationModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        {/* Title, 発行年月日 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_2.join(" ")}></div>
                            <div className={style_col_8.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>ピッキングリスト</h2>
                                </div>
                            </div>
                            <div className={style_col_2.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {  mainTableData.map((data,index) => (
                            <div key={index}>
                                {/* header  */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_2}>出庫日:</span>
                                                    <span className={style.cs_span_2}>
                                                        {useJwt.formatDate(new Date(data['header_info']['slip_date']))}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>荷主ｺｰﾄﾞ:</span>
                                                    <span className={style.cs_span_3}>
                                                        {data['header_info']['shipper_code']+"-"+data['header_info']['shipper_a_code']}
                                                    </span>
                                                    <span className={style.cs_span_3}>荷主名:</span>
                                                    <span className={style.cs_span_3}>{data['header_info']['shipper_name']}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>荷送人ｺｰﾄﾞ:</span>
                                                    <span className={style.cs_span_3}>
                                                        {data['header_info']['consignor_code']+"-"+data['header_info']['consignor_a_code']}
                                                    </span>
                                                    <span className={style.cs_span_3}>荷送人名:</span>
                                                    <span className={style.cs_span_3}>{data['header_info']['consignor_name']}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>届け先ｺｰﾄﾞ:</span>
                                                    <span className={style.cs_span_3}>
                                                        {data['header_info']['distination_code']+"-"+data['header_info']['distination_a_code']}
                                                    </span>
                                                    <span className={style.cs_span_3}>届け先名:</span>
                                                    <span className={style.cs_span_3}>{data['header_info']['distination_name']}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>運送業者ｺｰﾄﾞ:</span>
                                                    <span className={style.cs_span_3}>
                                                        {data['header_info']['carrier_code']+"-"+data['header_info']['carrier_a_code']}
                                                    </span>
                                                    <span className={style.cs_span_3}>運送業者名:</span>
                                                    <span className={style.cs_span_3}>{data['header_info']['carrier_name']}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>運送便:</span>
                                                    <span className={style.cs_span_3}>{data['header_info']['carrier_stool_code']}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* table */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap3.join(" ")}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th >倉庫</th>
                                                    <th >棚番号</th>
                                                    <th >番地</th>
                                                    <th >段</th>
                                                    <th >品番</th>
                                                    <th >補助</th>
                                                    <th >品名</th>
                                                    <th >ﾛｯﾄNo</th>
                                                    <th >荷主品番</th>
                                                    <th >数量</th>
                                                    <th >単位</th>
                                                    <th >重量</th>
                                                    <th >伝票No</th>
                                                    <th >備考</th>
                                                </tr>
                                            </thead>
                                            <tbody id="tbody_wrap" className={loading?'tbl_hide':'tbl_show'}>
                                                {  data['item_info'].map((data1,index1) => (
                                                    <tr key={index1}>
                                                        <td >{data1['warehouse_code']}</td>
                                                        <td>{data1['table_row']}</td>
                                                        <td>{data1['table_column']}</td>
                                                        <td>{data1['table_floor']}</td>
                                                        <td >{data1['item_code']}</td>
                                                        <td >{data1['item_a_code']}</td>
                                                        <td className={style.td_left}>{data1['item_name']}</td>
                                                        <td >{data1['rot_code']}</td>
                                                        <td >{data1['shipper_item_code']}</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data1['item_num'])}</td>
                                                        <td >{data1['item_unit']}</td>
                                                        <td  className={style.td_right}>
                                                            {(data1['unit_weight']!=null&&data1['unit_weight']>0&&data1['item_num']!=null&&data1['item_num']>0)?
                                                            useJwt.numberWithCommas(data1['unit_weight']*data1['item_num']):""
                                                            }
                                                        </td>
                                                        <td >{data1['slip_no']}</td>
                                                        <td className={style.td_left}>{data1['remark']}</td>
                                                    </tr>
                                                )) }
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style.nw_title_wrap}>
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'ピッキングリスト_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                                <CSVLink data={mainTableData} filename={'ピッキングリスト_'+useJwt.formatDateTime(new Date())+'.csv'} 
                                className={style.btn_csv}>ﾌｧｲﾙ</CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default PickingList;