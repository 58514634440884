import React, { useState, useEffect } from 'react';
import style from "./Driver.module.css";
import gstyle from '../../css/GeneralStyle.module.css';
import { useLocation, useNavigate } from "react-router-dom";
import ClipLoader from 'react-spinners/ClipLoader';
import axios from "axios";
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import * as useJwt  from '../../auth/jwt/useJwt.js';
import { Modal, Input, Form, Button, Picker } from 'antd-mobile';
import { ExclamationCircleFill } from 'antd-mobile-icons';

function DriverConfirm(props) {

  let urlParams = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let data = {
      warehouse_user_id : urlParams.state.companyId,
    }
    csLoading(true);

    let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierByDriverEndpoint;
    axios
      .post(url, data)
      .then((response) => {
        let data = response.data;
        let tempList = [];
        for(let i = 0; i<data.length; i++){
          let tempItem = {};
          tempItem['value'] = data[i]['sys_carrier_id'];
          tempItem['label'] = data[i]['carrier_name'];
          tempList.push(tempItem);
        }
        console.log(tempList);
        console.log(data);
        setTransportData(tempList);

        let driverData = useJwt.getDriverData();
        if(driverData){
          let driverList = JSON.parse(driverData);
          console.log(driverList);
          setTransportId(driverList['transport_id']);
          setCarNumber(driverList['car_number']);
          setDriverName(driverList['driver_name']);
          setTelephone(driverList['telephone']);
          setCarType(driverList['car_type']);
        }
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
      });
}, [props]);
  
  const csLoading = (flag) => {
    if(flag) {
        document.getElementById('csLoader').style.display="flex";
    }else{
        document.getElementById('csLoader').style.display="none";
    }
  } 
  const [carType, setCarType] = useState("1");
  const [carTypeName, setCarTypeName] = useState("");

  const onChangeCarType = (event) => {
    setCarType(event.target.value);
  };

  const [transportData, setTransportData] = useState([]);
  const [transportId, setTransportId] = useState("");
  
  const handleTransport = (event) => {
    setTransportId(event.target.value);
  }
  const [carNumber, setCarNumber] = useState("");
  const handleCarNumberChange = (value) => {
    setCarNumber(value.target.value);
    console.log(value.target.value);
  }

  const [driverName, setDriverName] = useState("");
  const handleDriverNameChange = (value) => {
    setDriverName(value.target.value);
  }

  const [telephone, setTelephone] = useState("");
  const handleTelephoneChange = (value) => {
    setTelephone(value.target.value);
  }

  const [itemName, setItemName] = useState("");
  const handleItemNameChange = (value) => {
    setItemName(value.target.value);
  }

  const handleNext = ( e ) => {
    let driverData = {};
    driverData['transport_id'] = transportId;
    driverData['car_number'] = carNumber;
    driverData['driver_name'] = driverName;
    driverData['telephone'] = telephone;
    driverData['car_type'] = carType;
    driverData['warehouse_id'] = urlParams.state.warehouseId;
    driverData['warehouse_company_id'] = urlParams.state.companyId;
    driverData['item_name'] = itemName;
    
    csLoading(true);

    let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getDriverScheduleConfirm;
    axios
      .post(url, driverData)
      .then((response) => {
        let data = response.data;
        if(data == 1){
          Modal.alert({
            header: (
              <ExclamationCircleFill
                style={{
                  fontSize: 64,
                  color: 'var(--adm-color-warning)',
                }}
              />
            ),
            title: '失策',
            content: (
              <div>
                <p style={{textAlign:'center'}}>未登録のドライバーです。</p>
                <p style={{textAlign:'center'}}>車番号を確認してください。</p>
              </div>
            ),
            confirmText:'確認'
          })
        }else{
          if(data.start_date_time){
            navigate("/driver/scheduleView", {
              state: {
                data : data,
                companyName: urlParams.state.companyName,
                warehouseName : urlParams.state.warehouseName,
                      },
              replace: false,
            });
          }else{
            navigate("/driver/newDriverWait", {
              state: {
                data : data,
                companyName: urlParams.state.companyName,
                warehouseName : urlParams.state.warehouseName,
                      },
              replace: false,
            });
          }
        }
        console.log(data);
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
      });
    useJwt.removeDriverData();
    console.log(JSON.stringify(driverData))
    useJwt.setDriverData(JSON.stringify(driverData));
  }
  //style
  const style_header = [style.content_flex_column, style.title, style.content_align_center];
  const style_content = [style.content_flex_column, style.mt20, style.row_gap];
  const style_content_but = [style.content_flex_column, style.row_gap, style.content_align_center];
  const style_useful = [style.useful_display];

  return (
    <div className={style.content_flex_column} style={{padding : '10px 25px'}}>
      <div className={gstyle.loader_container} id="csLoader">
          <ClipLoader color={'#fff'} size={60} />
      </div>
      <div className={style_header.join(" ")}>
        <label>{urlParams.state.companyName}予約システム</label>
        <label className={style.sub_title}>{urlParams.state.warehouseName}</label>
      </div>

      <div className={style_content.join(" ")}>
          <div className={style.content_flex_column}>
            <label className={style.form_label}>運送会社名</label>
              <select className={style.select} value={transportId} onChange={handleTransport}>
            {
              transportData?.map((data, index) => (
                <option value={data['value']}>{data['label']}</option>
              ))
            }
            </select>
          </div>
          <div className={style.content_flex_column}>
            <label className={style.form_label}>車番</label>
            <input placeholder='車番を入力してください。' className={style.input}  value={carNumber} onChange={handleCarNumberChange} />
          </div>
          <div className={style.content_flex_column}>
            <label className={style.form_label}>名前</label>
            <input placeholder='名前を入力してください。' className={style.input}  value={driverName} onChange={handleDriverNameChange} />
          </div>
          <div className={style.content_flex_column}>
            <label className={style.form_label}>携帯</label>
            <input placeholder='携帯を入力してください。'  className={style.input} value={telephone} onChange={handleTelephoneChange} />
          </div>
          <div className={style.content_flex_column}>
            <label className={style.form_label}>車種</label>
            <select className={style.select} onChange={onChangeCarType}  value={carType}>
              <option value="1">4t</option>
              <option value="2">10t</option>
              <option value="3">トレーラー</option>
            </select>
          </div>
          <div className={style.content_flex_column}>
            <label className={style.form_label}>車番</label>
            <input placeholder='商品名を入力してください。' className={style.input}  value={itemName} onChange={handleItemNameChange} />
          </div>
      </div>

      <div className={style_content_but.join(" ")}>
        <Button className={style.button} size='small' color='primary' fill='outline' onClick={(e) => handleNext(e)}>次へ</Button>
      </div>

    </div>
  );
}

export default DriverConfirm;
