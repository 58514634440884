/* eslint-disable eqeqeq */
import style from "./Contact.module.css";
import HeadingText from "../../components/administrator/headingText/HeadingText";
import LinkButton from "../../components/administrator/linkButton/LinkButton";
import * as useJwt  from '../../auth/jwt/useJwt.js';

const Contact = () => {
  const menuPatternData = JSON.parse(useJwt.getMenuPatternData()); 
  const userData = JSON.parse(useJwt.getUserData());

  return (
    <>
      <label className='bodyLabel'>荷主連絡</label>
      <div className='contentBody'>
      <HeadingText color="#1188E8" title="荷主連絡" />
      <div className={style.links}>
        {	(menuPatternData['contact_flag_1'] == 1 || userData['sys_role_id'] == 10000) &&
          <LinkButton link="/contact/transport" title="運送会社通知報告" />
				}
      </div>
      </div>
    </>
  );
};

export default Contact;
