/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './StockHistoryByDate.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import {Select} from 'antd'

const StockHistoryByDate = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    // slip date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // date type -----------------------------------------------
    const [DateType, setDateType] = useState(0);
    const handleChangeDateType = (event) => {
        setDateType(event);
    };
    const DateTypeData = [ { value:'0', label:'締日指定' }, { value:'1', label:'日付範囲指定' }, ];
    // deadline day ---------------------------------------------
    const [DeadLineDay, setDeadLineDay] = useState(useJwt.formatDate(new Date()));
    const handleChangeDeadLineDay = (date) => {
        setDeadLineDay(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartSlipDate, setStartSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartSlipDate = (date) => {
        setStartSlipDate(useJwt.formatDate(date));
    };
    const [EndSlipDate, setEndSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndSlipDate = (date) => {
        setEndSlipDate(useJwt.formatDate(date));
    };
    // output order type -----------------------------------------------
    const [WarehouseType, setWarehouseType] = useState(0);
    const handleChangeWarehouseType = (event) => {
        setWarehouseType(event);
    };
    const WarehouseTypeData = [ { value:'0', label:'全倉庫' }, { value:'1', label:'倉庫別' } ];
    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('warehouse_code2').focus();
            }else{
                document.getElementById('type3').focus();
            }
        }
    };
    const getWarehouseData = (code,param) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result,param);
                    }else{
                        setWarehouseData([],param);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setWarehouseData([],param);
        }
    }
    const setWarehouseData = (data,param) => {
        if(data['warehouse_code']) {
            if(param == 1) {
                setWarehouseCode1(data['warehouse_code']);
                setWarehouseName1(data['warehouse_name']);
            }else{
                setWarehouseCode2(data['warehouse_code']);
                setWarehouseName2(data['warehouse_name']);
            }
        }else{
            if(param == 1) {
                setWarehouseCode1("");
                setWarehouseName1("");
            }else{
                setWarehouseCode2("");
                setWarehouseName2("");
            }
        }
    }
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
        setItemData([],1);
        setItemData([],2);
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();
            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('type2').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                if(ShipperACode1 != ""){
                    acode = ShipperACode1;
                }else{
                    acode = "000";
                }
            }else{
                code = ShipperCode1;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }else{
            if(param2==1){
                code = value;
                if(ShipperACode2 != ""){
                    acode = ShipperACode2;
                }else{
                    acode = "000";
                }
            }else{
                code = ShipperCode2;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // item ----------------------------------------------------
    const [ItemCode1, setItemCode1] = useState("");
    const handleChangeItemCode1 = (event) => {
        setItemCode1(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode1, setItemACode1] = useState("");
    const handleChangeItemACode1 = (event) => {
        setItemACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName1, setItemName1] = useState("");
    const [ItemCode2, setItemCode2] = useState("");
    const handleChangeItemCode2 = (event) => {
        setItemCode2(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode2, setItemACode2] = useState("");
    const handleChangeItemACode2 = (event) => {
        setItemACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName2, setItemName2] = useState("");
    const onKeyDownItemCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getItemData(event.target.value,param1,param2);
            event.preventDefault();
        }
    };
    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                if(ItemACode1 != ""){
                    acode = ItemACode1;
                }else{
                    acode = "000";
                }
            }else{
                code = ItemCode1;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }else{
            if(param2==1){
                code = value;
                if(ItemACode2 != ""){
                    acode = ItemACode2;
                }else{
                    acode = "000";
                }
            }else{
                code = ItemCode2;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode1;
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result,param1);
                    }else{
                        setItemData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([],param1);
        }
    }
    const setItemData = (data,param) => {
        if(data['item_code']) {
            if(param == 1) {
                setItemCode1(data['item_code']);
                setItemACode1(data['item_a_code']);
                setItemName1(data['item_name']);
            }else{
                setItemCode2(data['item_code']);
                setItemACode2(data['item_a_code']);
                setItemName2(data['item_name']);
            }
        }else{
            if(param == 1) {
                setItemCode1("");
                setItemACode1("");
                setItemName1("");
            }else{
                setItemCode2("");
                setItemACode2("");
                setItemName2("");
            }
        }
    }
    // sub code type -----------------------------------------------
    const [SubCodeType, setSubCodeType] = useState(0);
    const handleChangeSubCodeType = (event) => {
        setSubCodeType(event);
    };
    // rot type -----------------------------------------------
    const [RotType, setRotType] = useState(0);
    const handleChangeRotType = (event) => {
        setRotType(event);
    };
    // filter type -----------------------------------------------
    const [FilterType, setFilterType] = useState(0);
    const handleChangeFilterType = (event) => {
        setFilterType(event);
    };

    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_stock_flag_3'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        if(DateType == 0) {
            data['prev_flg'] = 0;
            data['deadline_day'] = useJwt.getDay(DeadLineDay);
            data['deadline_year_month'] = useJwt.getYearMonth(DeadLineDay);
        }else{
            data['start_slip_date'] = StartSlipDate;
            data['end_slip_date'] = EndSlipDate;
        }
        data['filter_warehouse'] = WarehouseType;
        if(WarehouseType == 1) {
            data['start_warehouse_code'] = WarehouseCode1;
            data['end_warehouse_code'] = WarehouseCode2;
        }
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        data['start_item_code'] = ItemCode1;
        data['end_item_code'] = ItemCode2;
        data['start_item_a_code'] = ItemACode1;
        data['end_item_a_code'] = ItemACode2;
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockHistoryByDateEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            // console.log(result); csloading(false);
            if(result.length>0){
                getItemRemainNum(result);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                getItemRemainNum([]);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
        
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const getItemRemainNum = (ret) => {
        // if(DateType==1) {
            let data = {};
            data['userID'] = userData['id'];
            if(DateType==1){
                data['end_slip_date'] = useJwt.getPreviousDay(StartSlipDate);
            }else{
                data['prev_flg'] = 1;
                data['deadline_day'] = useJwt.getDay(DeadLineDay);
                data['deadline_year_month'] = useJwt.getYearMonth(DeadLineDay);
            }
            if(WarehouseType == 1) {
                data['start_warehouse_code'] = WarehouseCode1;
                data['end_warehouse_code'] = WarehouseCode2;
            }
            data['start_shipper_code'] = ShipperCode1;
            data['end_shipper_code'] = ShipperCode2;
            data['start_shipper_a_code'] = ShipperACode1;
            data['end_shipper_a_code'] = ShipperACode2;
            data['start_item_code'] = ItemCode1;
            data['end_item_code'] = ItemCode2;
            data['start_item_a_code'] = ItemACode1;
            data['end_item_a_code'] = ItemACode2;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockHistoryByDateEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let result = response.data.data;
                if(result.length>0) {
                    let data = [];
                    let itemCodes = [];
                    for(var i=0; i<result.length; i++) {
                        let filter_code = ''+result[i]['shipper_code']+'-'+result[i]['shipper_a_code'];
                        filter_code += '-'+result[i]['item_code'];
                        if(SubCodeType>0) {
                            filter_code += '-'+result[i]['item_a_code'];
                        }
                        if(WarehouseType>0) {
                            filter_code += '-'+result[i]['warehouse_code'];
                        }
                        if(RotType>0) {
                            filter_code += '-'+result[i]['rot_code'];
                        }
                        if(!itemCodes.includes(filter_code)){
                            itemCodes.push(filter_code);
                        }
                    }
                    for(var i=0; i<itemCodes.length; i++) {
                        let row = {};
                        row['filter_code'] = itemCodes[i];
                        let remainAry = [];
                        let cnt = 0;
                        for(var j=0;j<result.length;j++) {
                            let filter_code = ''+result[j]['shipper_code']+'-'+result[j]['shipper_a_code'];
                            filter_code += '-'+result[j]['item_code'];
                            if(SubCodeType>0) {
                                filter_code += '-'+result[j]['item_a_code'];
                            }
                            if(WarehouseType>0) {
                                filter_code += '-'+result[j]['warehouse_code'];
                            }
                            if(RotType>0) {
                                filter_code += '-'+result[j]['rot_code'];
                            }
                            if(itemCodes[i] == filter_code) {

                                let tmp_row = result[j];
                                let remain_num = 0;
                                if(cnt>0){
                                    if(tmp_row['arrival_num'] != "" && tmp_row['leaving_num'] != ""){
                                        remain_num += (remainAry[cnt-1]*1+1*tmp_row['arrival_num']-1*tmp_row['leaving_num']);
                                    }else{
                                        if(tmp_row['arrival_num'] != ""){
                                            remain_num += (remainAry[cnt-1]*1+1*tmp_row['arrival_num']);
                                        }else if(tmp_row['leaving_num'] != "") {
                                            remain_num += (remainAry[cnt-1]*1-1*tmp_row['leaving_num']);
                                        }else{
                                            remain_num = remainAry[cnt-1];
                                        }
                                    }
                                    tmp_row['remain_num'] = remain_num;
                                }else{
                                    if(tmp_row['arrival_num'] != "" && tmp_row['leaving_num'] != ""){
                                        remain_num = 1*tmp_row['arrival_num']-1*tmp_row['leaving_num'];
                                    }else{
                                        if(tmp_row['arrival_num'] != ""){
                                            remain_num = 1*tmp_row['arrival_num'];
                                        }else if(tmp_row['leaving_num'] != "") {
                                            remain_num = (0*1-tmp_row['leaving_num']);
                                        }
                                    }
                                }
                                remainAry.push(remain_num);
                                cnt++;
                            }
                        }
                        
                        row['remain_num'] = remainAry[remainAry.length-1];
                        data.push(row);
                    }
                    gridMainTable(ret,data);
                }else{
                    gridMainTable(ret,[]);
                }
            })
            .catch((error) => {
            });
        // }else{
        //     gridMainTable(ret,[]);
        // }
    }
    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result,prev_result) => {
        // console.log("prev",prev_result)
        // console.log("current",result)
        let data = [];
        let itemCodes = [];
        let header_info = [];
        for(var i=0; i<result.length; i++) {
            let valid_flg = true;
            if(ShipperCode1 != "" && result[i]['shipper_code'] < ShipperCode1) {
                valid_flg = false;
            }
            if(ShipperCode2 != "" && result[i]['shipper_code'] > ShipperCode2) {
                valid_flg = false;
            }
            if(valid_flg) {
                let filter_code = ''+result[i]['shipper_code']+'-'+result[i]['shipper_a_code'];
                filter_code += '-'+result[i]['item_code'];
                if(SubCodeType>0) {
                    filter_code += '-'+result[i]['item_a_code'];
                }
                if(WarehouseType>0) {
                    filter_code += '-'+result[i]['warehouse_code'];
                }
                if(RotType>0) {
                    filter_code += '-'+result[i]['rot_code'];
                }
                if(!itemCodes.includes(filter_code)){
                    itemCodes.push(filter_code);
                    let row = {};
                    row['shipper_code'] = result[i]['shipper_code'];
                    row['shipper_a_code'] = result[i]['shipper_a_code'];
                    row['shipper_name'] = result[i]['shipper_name'];
                    row['warehouse_code'] = result[i]['warehouse_code'];
                    row['warehouse_name'] = result[i]['warehouse_name'];
                    row['item_code'] = result[i]['item_code'];
                    row['item_a_code'] = result[i]['item_a_code'];
                    row['item_name'] = result[i]['item_name'];
                    header_info.push(row);
                }
            }
        }
        for(var i=0; i<itemCodes.length; i++) {
            let row = {};
            row['filter_code'] = itemCodes[i];
            row['header_info'] = header_info[i];
            row['item_info'] = [];
            let subtotal_arrival_num = 0;
            let subtotal_leaving_num = 0;
            let sub_total_label = "【　合　計　】";
            let cnt = 0;
            for(var j=0;j<result.length;j++) {
                let filter_code = ''+result[j]['shipper_code']+'-'+result[j]['shipper_a_code'];
                filter_code += '-'+result[j]['item_code'];
                if(SubCodeType>0) {
                    filter_code += '-'+result[j]['item_a_code'];
                }
                if(WarehouseType>0) {
                    filter_code += '-'+result[j]['warehouse_code'];
                }
                if(RotType>0) {
                    filter_code += '-'+result[j]['rot_code'];
                }
                if(itemCodes[i] == filter_code) {
                    let tmp_row = result[j];
                    let remain_num = 0;
                    if(cnt>0){
                        if(tmp_row['arrival_num'] != "" && tmp_row['leaving_num'] != ""){
                            remain_num += (row['item_info'][cnt-1]['remain_num']*1+1*tmp_row['arrival_num']-1*tmp_row['leaving_num']);
                        }else{
                            if(tmp_row['arrival_num'] != ""){
                                remain_num += (row['item_info'][cnt-1]['remain_num']*1+1*tmp_row['arrival_num']);
                            }else if(tmp_row['leaving_num'] != "") {
                                remain_num += (row['item_info'][cnt-1]['remain_num']*1-1*tmp_row['leaving_num']);
                            }else{
                                remain_num = row['item_info'][cnt-1]['remain_num']*1;
                            }
                        }
                        tmp_row['remain_num'] = remain_num.toFixed(3);
                    }else{
                        let prev_remain_num = 0;
                        // if(DateType==1) {
                            for(var m=0; m<prev_result.length;m++){
                                // console.log("prev_filter_code",prev_result[m]['filter_code']);
                                // console.log("prev_remain_num",prev_result[m]['remain_num']);
                                // console.log("itemCode",itemCodes[i]);
                                if(itemCodes[i] == prev_result[m]['filter_code']) {
                                    prev_remain_num = prev_result[m]['remain_num'];
                                    break;
                                }
                            }
                            let sub_first_row = {};
                            sub_first_row['total_flg'] = 3;
                            sub_first_row['total_label'] = "【 繰越残数 】";
                            sub_first_row['arrival_num'] = "";
                            sub_first_row['leaving_num'] = "";
                            sub_first_row['remain_num'] = prev_remain_num.toFixed(3);
                            row['item_info'].push(sub_first_row);
                            cnt++;
                        // }
                        
                        if(tmp_row['arrival_num'] != "" && tmp_row['leaving_num'] != ""){
                            remain_num = 1*tmp_row['arrival_num']-1*tmp_row['leaving_num'];
                        }else{
                            if(tmp_row['arrival_num'] != ""){
                                remain_num = 1*tmp_row['arrival_num'];
                            }else if(tmp_row['leaving_num'] != "") {
                                remain_num = (0*1-tmp_row['leaving_num']);
                            }
                        }
                        tmp_row['remain_num'] = (prev_remain_num*1+remain_num).toFixed(3);
                    }
                    tmp_row['total_flg'] = 0;
                    tmp_row['total_label'] = "";
                    row['item_info'].push(tmp_row);
                    if(tmp_row['arrival_num'] != "") {
                        subtotal_arrival_num += (tmp_row['arrival_num']*1);
                    }
                    if(tmp_row['leaving_num'] != "") {
                        subtotal_leaving_num += (tmp_row['leaving_num']*1);
                    }
                    cnt++;
                }
            }
            let sub_total_row = {};
            sub_total_row['total_flg'] = 1;
            sub_total_row['total_label'] = sub_total_label;
            sub_total_row['arrival_num'] = subtotal_arrival_num.toFixed(3);
            sub_total_row['leaving_num'] = subtotal_leaving_num.toFixed(3);
            row['item_info'].push(sub_total_row);

            data.push(row);
        }
        setMainTableData(data);
        if(data.length>0){
            setOpend(true);
        }
    }
    // ========================== starts code for search ===============================
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWarehouseModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode1;
        if(param==2){
            code = WarehouseCode2;
        }
        setWarehouseSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['warehouse_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemCode1;
        let acode = ItemACode1;
        if(param == 2) {
            code = ItemCode2;
            acode = ItemACode2;
        }
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode1);
        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode1;
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);
    const [type5Open, setType5Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('date2')){
                    document.getElementById('date2').focus();
                }else{
                    document.getElementById('date3').focus();
                }
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('warehouse_code1'))
                    document.getElementById('warehouse_code1').focus();
                else
                    document.getElementById('type3').focus();
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type4').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onType4KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type5').focus();
            event.preventDefault();
        }
    }
    const onFocusType4 = (e, type) => {
        if(type == 1){
            setType4Open(true);
        }else{
            setType4Open(false);
        }
    }

    const onType5KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType5 = (e, type) => {
        if(type == 1){
            setType5Open(true);
        }else{
            setType5Open(false);
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            case 4:
                setType4Open(false);
                break;
            case 5:
                setType5Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} 
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>商品受払帳</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 締日区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='type1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>締日区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="締日区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeDateType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={DateTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 締切年月日 */}
                    {DateType==0 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>締切年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={DeadLineDay} onChange={handleChangeDeadLineDay} id='date2' focusId='shipper_code1' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 年月日 */}
                    {DateType==1 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={StartSlipDate} onChange={handleChangeStartSlipDate} id='date3' focusId='date4' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={EndSlipDate} onChange={handleChangeEndSlipDate} id='date4' focusId='shipper_code1' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 商品 */}
                    {ShipperCode1 != "" && ShipperCode1 == ShipperCode2 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,1,1)} id='item_code1'
                                            value={ItemCode1} onChange={handleChangeItemCode1} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,1,2)} id='item_a_code1'
                                            value={ItemACode1} onChange={handleChangeItemACode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,2,1)} id='item_code2'
                                            value={ItemCode2} onChange={handleChangeItemCode2} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,2,2)} id='item_a_code2'
                                            value={ItemACode2} onChange={handleChangeItemACode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 倉庫区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="倉庫区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeWarehouseType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={WarehouseTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫 */}
                    {WarehouseType == 1 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始倉庫</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownWarehouseCode(e,1)} id='warehouse_code1'
                                                value={WarehouseCode1} onChange={handleChangeWarehouseCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了倉庫</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownWarehouseCode(e,2)} id='warehouse_code2'
                                                value={WarehouseCode2} onChange={handleChangeWarehouseCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/*  補助区分, 品番区分, 打出区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>補助区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="補助区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeSubCodeType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={[
                                                {value:'0', label:'一括'},
                                                {value:'1', label:'補助別'},
                                            ]}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type4Open}
                                            onSelect={(e)=>onSelectData(e, 4)}
                                            placeholder="品番区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeRotType}
                                            onKeyDown={onType4KeyDown}
                                            onFocus={(e) => onFocusType4(e, 1)}
                                            onBlur={(e) => onFocusType4(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type4"
                                            options={[
                                                {value:'0', label:'品番集計'},
                                                {value:'1', label:'ロット別'},
                                            ]}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>打出区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type5Open}
                                            onSelect={(e)=>onSelectData(e, 5)}
                                            placeholder="打出区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeFilterType}
                                            onKeyDown={onType5KeyDown}
                                            onFocus={(e) => onFocusType5(e, 1)}
                                            onBlur={(e) => onFocusType5(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type5"
                                            options={[
                                                {value:'0', label:'発生又は在庫有り'},
                                                {value:'1', label:'発生有りのみ'},
                                            ]}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        {/* Title, 発行年月日 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_2.join(" ")}></div>
                            <div className={style_col_8.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>商品受払帳</h2>
                                </div>
                            </div>
                            <div className={style_col_2.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {/* 締日区分 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_2}>[締日区分]:</span>
                                            <span className={style.cs_span_2}>{DateTypeData[DateType]['value']}</span>
                                            {DateType==0 &&
                                                <span className={style.cs_span_2}>
                                                    ({DeadLineDay})
                                                </span>
                                            }
                                            {DateType==1 &&
                                                <span className={style.cs_span_2}>
                                                    ({StartSlipDate} ~ {EndSlipDate})
                                                </span>
                                            }
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {  mainTableData.map((data,index) => (
                            <div key={index}>
                                {/* header  */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_3.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_2}>
                                                        {data['header_info']['shipper_code']} - {data['header_info']['shipper_a_code']}
                                                    </span>
                                                    <span className={style.cs_span_2}>{data['header_info']['shipper_name']}</span>
                                                </div>
                                            </label>
                                        </div>
                                        {WarehouseType==1 &&
                                            <div className={style_input_wrap.join(" ")}>
                                                <label className={style_label_wrap.join(" ")}>
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_2}>
                                                            {data['header_info']['warehouse_code']}
                                                        </span>
                                                        <span className={style.cs_span_2}>
                                                            {data['header_info']['warehouse_name']}
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        }
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_2}>
                                                        {(SubCodeType == 0)?
                                                            data['header_info']['item_code']
                                                        :
                                                            data['header_info']['item_code']+"-"+data['header_info']['item_a_code']
                                                        }
                                                    </span>
                                                    <span className={style.cs_span_2}>{data['header_info']['item_name']}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* table */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap3.join(" ")}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>年月日</th>
                                                        <th>入力区分</th>
                                                        <th>伝票No</th>
                                                        <th>処理区分</th>
                                                        <th>倉庫</th>
                                                        <th >棚番号</th>
                                                        <th >番地</th>
                                                        <th >段</th>
                                                        <th>ﾛｯﾄNo</th>
                                                        <th>入庫数量</th>
                                                        <th>出庫数量</th>
                                                        <th>在庫数量</th>
                                                        <th>請求区分</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {  data['item_info'].map((data1,index1) => (
                                                        <React.Fragment key={index1}>
                                                            {data1['total_flg'] == 0 ?
                                                                <tr>
                                                                    <td>{useJwt.formatDate(new Date(data1['slip_date']))}</td>
                                                                    <td>{data1['input_type']}</td>
                                                                    <td>{data1['slip_no']}</td>
                                                                    <td>{data1['move_type']}</td>
                                                                    <td>{data1['warehouse_code']}</td>
                                                                    <td>{data1['table_row']}</td>
                                                                    <td>{data1['table_column']}</td>
                                                                    <td>{data1['table_floor']}</td>
                                                                    <td>{data1['rot_code']}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['arrival_num'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['leaving_num'])}</td>
                                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data1['remain_num'])}</td>
                                                                    <td>{data1['invoice_type']}</td>
                                                                </tr>
                                                                :
                                                                <tr className={data1['total_flg'] == 1 ?style.bg_tbl_total:""}>
                                                                    <td colSpan={7}>{data1['total_label']}</td>
                                                                    <td className={style.td_right}>{data1['arrival_num']>0?useJwt.numberWithCommas(data1['arrival_num']):""}</td>
                                                                    <td className={style.td_right}>{data1['leaving_num']>0?useJwt.numberWithCommas(data1['leaving_num']):""}</td>
                                                                    <td className={style.td_right}>{data1['total_flg'] == 3 ?useJwt.numberWithCommas(data1['remain_num']):""}</td>
                                                                    <td></td>
                                                                </tr>
                                                            }
                                                        </React.Fragment>
                                                    )) }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'商品受払帳_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default StockHistoryByDate;