/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import style from "./RegistryBoth.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegistryBoth = () => {
  //useJwt.logout();
  // パスワード表示制御ようのstate
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  return (
    <div className={style.background}>
      <form className={style.form}>
        <div className={style.title}>
          <span>新規会員</span>
        </div>
        <div className={style.commandLayout}>
          <span>登録先を選択してください。</span>
        </div>
        <div className={style.buttonLayout}>
          <Link
            to={"/registry/shipper/entry"}
            className={style.shipperRegistryBut}
          >
            荷主
          </Link>
          <Link
            to={"/registry/warehouse/entry"}
            className={style.warehouseRegistryBut}
          >
            倉庫
          </Link>
        </div>
        <div className={style.linkLayout}>
          <Link
            to={"#"}
            className={style.registryLink}
          >
            荷主説明はこちら
          </Link>
          <Link
            to={"#"}
            className={style.registryLink}
          >
            倉庫説明はこちら
          </Link>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default RegistryBoth;
