/* eslint-disable eqeqeq */
import style from './Location.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import { Button } from 'antd';
import $ from 'jquery';
import { QRCodeCanvas } from "qrcode.react";
import ReactToPrint from "react-to-print";

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const Location = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    // ========================== print =================================================
    const QRRef = useRef();
    const [itemInformation, setItemInformation] = useState([]);

    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
    }, []);

    const handleBeforePrint = React.useCallback(() => {
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        $('#QRDiv').css('display', 'block');
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    const reactToPrintTrigger = React.useCallback(() => {
        return (
            <Button type={"primary"} >コード発行</Button>
        );
    }, []);

    const QRGenerate = () => {
        let data = [];
        var x = 0;

        var checkboxes = $('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            console.log(checkboxes)
            let temp = {};
            if (checkboxes[i].checked) {
                temp['item_data'] = WarehouseCode + '&&' + mainTableData[i]['table_code']
                    + '&&' + mainTableData[i]['table_row'] + '&&' + mainTableData[i]['table_column']
                    + '&&' + mainTableData[i]['table_floor'] + '&&' + userData['id'];
                data[x] = temp;
                x++;
            }
        }

        if (data.length == 0) {
            return;
        }

        setItemInformation(data);
    }

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [WarehouseCode, setWarehouseCode] = useState("");
    const handleChangeWarehouseCode = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 2) {
            str = str.substring(0, 2);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setWarehouseCode(str);
    };
    const [WarehouseName, setWarehouseName] = useState("");
    const pad = (n) => {
        return new Array(n).join('0').slice((n || 2) * -1);
    }

    const onKeyDownWarehouseCode = (event) => {
        if (event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(3, event.target.value)
            setWarehouseCode(deg)
            getWarehouseData(deg);
            document.getElementById('input2').focus();
            event.preventDefault();
        }
    };

    const getWarehouseData = (code) => {
        if (code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            csloading(true);
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    csloading(false);
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWarehouseData(result);
                        } else {
                            setWarehouseData([]);
                        }
                    }
                })
                .catch((error) => {
                    csloading(false);
                });
        } else {
            setWarehouseData([]);
        }
    }
    const setWarehouseData = (data) => {
        if (data['warehouse_code']) {
            setWarehouseCode(data['warehouse_code']);
            setWarehouseName(data['warehouse_name']);
            let filterData = {};
            filterData['warehouse_code'] = data['warehouse_code'];
            getLocationData(filterData);
        } else {
            setWarehouseName("");
            gridMainTable([]);
        }
    }

    const getLocationData = (result) => {
        let data = {};
        data['userID'] = userData['id'];
        data['warehouse_code'] = result['warehouse_code'];
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstLocationEndpoint;
        csloading(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                csloading(false);

                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        let result = response.data.data;
                        gridMainTable(result);
                        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
                        for (let i = 0; i < checkboxes.length; i++) {
                            checkboxes[i].checked = false;
                        }
                    } else {
                        gridMainTable([]);
                    }
                }
            })
            .catch((error) => {
                csloading(false);
            });
    }

    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        setMainTableData([]);
        setMainTableData(result);
    }

    const handlChangeLocationCodeData = (event, index) => {
        let value = event.target.value;
        if (value.length > 8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row['table_code'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }

    const handlChangeLocationRowData = (event, index) => {
        let value = event.target.value;
        if (value.length > 8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row['table_row'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }

    const handlChangeLocationColumnData = (event, index) => {
        let value = event.target.value;
        if (value.length > 8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row['table_column'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }

    const handlChangeLocationFloorData = (event, index) => {
        let value = event.target.value;
        if (value.length > 8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row['table_floor'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
    }

    const allCheckData = (event) => {
        $('#tbodyWrap input[type="checkbox"]').prop('checked', event.target.checked);
        QRGenerate();
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
        QRGenerate();
    };

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            if (menuPatternData['menu_master_flag_2'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
    });

    const newRecord = (event) => {
        if (WarehouseCode == "") {
            toast.error("倉庫コードを入力してください。", { autoClose: 3000 });
            return;
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            data[i] = row;
        }
        let row = {};
        row['sys_table_id'] = 0;
        row['warehouse_code'] = WarehouseCode;
        row['table_code'] = "";
        row['table_row'] = "";
        row['table_column'] = "";
        row['table_floor'] = "";
        data[i] = row;
        gridMainTable(data);
        setTimeout(() => {
            let endRow = mainTableData.length;
            document.getElementById('table_code_' + endRow).focus();
        }, 10)

    }

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];

        let validate_flg = true;
        let vmsg = "";

        let locationData = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            if (mainTableData[i]['sys_table_id'] != 0) {
                row['sys_table_id'] = mainTableData[i]['sys_table_id'];
            }
            if (mainTableData[i]['warehouse_code'] != "") {
                row['warehouse_code'] = mainTableData[i]['warehouse_code'];
            } else {
                vmsg = "倉庫コードを入力してください。";
                validate_flg = false;
                break;
            }
            if (mainTableData[i]['table_code'] != "") {
                row['table_code'] = mainTableData[i]['table_code'];
            } else {
                vmsg = "棚コードを入力してください。";
                validate_flg = false;
                break;
            }
            if (mainTableData[i]['table_row'] != "") {
                row['table_row'] = mainTableData[i]['table_row'];
            } else {
                vmsg = "棚番号を入力してください。";
                validate_flg = false;
                break;
            }
            if (mainTableData[i]['table_column'] != "") {
                row['table_column'] = mainTableData[i]['table_column'];
            } else {
                vmsg = "番地を入力してください。";
                validate_flg = false;
                break;
            }
            if (mainTableData[i]['table_floor'] != "") {
                row['table_floor'] = mainTableData[i]['table_floor'];
            } else {
                vmsg = "段を入力してください。";
                validate_flg = false;
                break;
            }

            locationData[i] = row;
        }

        if (validate_flg) {
            data['data'] = locationData;
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstLocationEndpoint;
            csloading(true);
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data == 1) {
                            toast.success("登録致しました。", { autoClose: 3000 });
                            let filterData = {};
                            filterData['warehouse_code'] = WarehouseCode;
                            getLocationData(filterData);
                        } else if (response.data.data == 2) {
                            toast.error("すでに登録済みです。", { autoClose: 3000 });
                        } else {
                            toast.error("登録に失敗しました。", { autoClose: 3000 });
                        }

                    }
                    csloading(false);
                })
                .catch((error) => {
                    csloading(false);
                    if (error.response && error.response.status && error.response.status === 401) {
                        useJwt.logout();
                        navigate("/login");
                    } else {
                        toast.error("登録に失敗しました。", { autoClose: 3000 });
                    }
                });
        } else {
            toast.error(vmsg, { autoClose: 3000 });
        }
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        setShowModalDel(true);
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        let delIds = [];
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                let sys_table_id = document.getElementById('sys_table_id_' + i).value;
                if (sys_table_id > 0) {
                    delIds.push(sys_table_id);
                }
            } else {
                remainIndexs.push(i);
            }
        }

        if (delIds.length > 0) {
            let data = {};
            data['userID'] = userData['id'];
            data['delIds'] = delIds;
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.delMstLocationEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    if (response.status == 200) {
                        let filterData = {};
                        filterData['warehouse_code'] = WarehouseCode;
                        getLocationData(filterData);

                    }
                })
                .catch((error) => {
                });
        } else {
            let remainData = [];
            for (var i = 0; i < mainTableData.length; i++) {
                for (var j = 0; j < remainIndexs.length; j++) {
                    if (i == remainIndexs[j]) {
                        let row = {};
                        row = mainTableData[i];
                        remainData.push(row);
                    }
                }
            }
            gridMainTable(remainData);
            $('#tbodyWrap input[type="checkbox"]').prop('checked', false);
        }
    }
    // ========================== starts code for search ===============================
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const loadWarehouseModalData = (event) => {
        event.preventDefault();
        setWarehouseSearchValue(WarehouseCode);

        if (WarehouseCode != "") {
            let data = {};
            data['userID'] = userData['id'];
            if (WarehouseCode != "") {
                data['warehouse_code'] = WarehouseCode;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWarehouseTableData(response.data.data);
                    setShowModalWarehouse(true);
                })
                .catch((error) => {
                });
        } else {
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }


    const tableKeyDown = (e, id) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    const tableEndKeyDown = (e, id, index) => {
        let dataLength = mainTableData.length;

        if (dataLength <= index + 1) return;

        if (e.keyCode === 13) {
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];

    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <WarehouseModal show={showModalWarehouse} onClose={() => setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue}
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} />
            <Modal2 title="棚削除" show={showModalDel} onClose={() => setShowModalDel(false)} width={'40%'}
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>棚マスタ</p>
            </div>
            <div className={gstyle.filed} style={{ zIndex: 2 }}>
                <div className={gstyle.cs_card_1}>
                    { /* 倉庫コード */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e)}
                                            value={WarehouseCode} onChange={handleChangeWarehouseCode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="WarehouseName"
                                            value={WarehouseName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadWarehouseModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <label></label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")} style={{ marginTop: 32, gap: 30 }}>
                                <Button type="primary" style={{ width: 100 }} id='input2' onClick={newRecord} >追 加</Button>
                                <ReactToPrint
                                    content={() => QRRef.current}
                                    documentTitle={
                                        useJwt.formatDateTime(new Date())
                                    }
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={gstyle.hyfiled}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_col_2.join(" ")}>棚コード</div>
                                    <div className={style_th_col_2.join(" ")}>棚番号</div>
                                    <div className={style_th_col_2.join(" ")}>番地</div>
                                    <div className={style_th_col_2.join(" ")}>段</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {mainTableData.map((data, index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                                id={'chk_' + index} onChange={handleChangeChk} />
                                            <input type="hidden" id={'sys_table_id_' + index} value={data['sys_table_id']} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input}
                                                id={'table_code_' + index}
                                                value={data['table_code']}
                                                onKeyDown={(e) => tableKeyDown(e, 'table_row' + index)}
                                                onChange={(e) => handlChangeLocationCodeData(e, index)} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input}
                                                id={'table_row' + index}
                                                onKeyDown={(e) => tableKeyDown(e, 'table_column' + index)}
                                                value={data['table_row']}
                                                onChange={(e) => handlChangeLocationRowData(e, index)} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input}
                                                id={'table_column' + index}
                                                value={data['table_column']}
                                                onKeyDown={(e) => tableKeyDown(e, 'table_floor' + index)}
                                                onChange={(e) => handlChangeLocationColumnData(e, index)} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input}
                                                id={'table_floor' + index}
                                                value={data['table_floor']}
                                                onKeyDown={(e) => tableEndKeyDown(e, 'table_code_' + (index + 1), index)}
                                                onChange={(e) => handlChangeLocationFloorData(e, index)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} >削除</button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{ fontSize: "1.1rem" }}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div style={{ marginTop: 500, display: 'none' }}>
                    <div ref={QRRef} id="QRDiv">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ marginTop: 30 }}>
                                {itemInformation.map((item, index) => (
                                    <div style={{ marginBottom: 20 }}>
                                        <QRCodeCanvas
                                            id="qrCode"
                                            value={item}
                                            size={150}
                                            bgColor={"#ffffff"}
                                            level={"H"}
                                            key={index}
                                            removeAfterPrint
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Location;