//====================================================================================================
//	作成日:2022/08/25		最終更新日:2022/08/26
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
import font from '../font/Font.module.css';
import style from './Headline.module.css';

const MyHeadline = ( props ) => {
	const textStyle = style.headline_text + " " + font.font;
	return (
		<div className={ style.headline_div }>
			<div className={ textStyle }>{ props.text }</div>
			<div className={ style.headline_border_bottom }></div>
		</div>
	);
};

export default MyHeadline;