/* eslint-disable eqeqeq */
import style from './ArrivalSlip.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import './ArrivalSlipTbl.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import RotModal from '../../../components/common/modal/RotModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import TableModal from '../../../components/common/modal/TableModal';
import SummaryModal from '../../../components/common/modal/SummaryModal';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

const ArrivalSlip = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [showModalDel2, setShowModalDel2] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        if(SlipDate == "") {
            toast.error("年月日を入力してください。", {autoClose:3000}); 
            return;
        }
        if(SlipNo == "") {
            toast.error("伝票Noを入力してください。", {autoClose:3000}); 
            return;
        }
        if(ShipperCode == "") {
            toast.error("荷主コードを入力してください。", {autoClose:3000}); 
            return;
        }
        if(ShipperACode == "") {
            toast.error("荷主補助コードを入力してください。", {autoClose:3000}); 
            return;
        }

        // item data --------------------------------------
        let validate_flg = true;
        let vmsg = "";
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            if(mainTableData[i]['sys_item_id'] != 0) {
                row['sys_item_id'] = mainTableData[i]['sys_item_id'];
            }
            
            if(mainTableData[i]['item_code'] != "") {
                row['item_code'] = mainTableData[i]['item_code'];
            }else{
                vmsg = "品番コードを入力してください。";       
                validate_flg = false;
                break;
            }
            row['item_a_code'] = mainTableData[i]['item_a_code'];
            row['shipper_item_code'] = mainTableData[i]['shipper_item_code'];
            row['rot_code'] = mainTableData[i]['rot_code'];
            if(mainTableData[i]['warehouse_code'] != "") {
                row['warehouse_code'] = mainTableData[i]['warehouse_code'];
            }else{
                vmsg = "倉庫コードを入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['table_code'] != "") {
                row['table_code'] = mainTableData[i]['table_code'];
            }else if(mainTableData[i]['warehouse_type'] != 0){
                vmsg = "棚を入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['case_num'] != "") {
                row['case_num'] = mainTableData[i]['case_num'];
            }else{
                row['case_num'] = '';
            }
            if(mainTableData[i]['bara_num'] != "") {
                row['bara_num'] = mainTableData[i]['bara_num'];
            }else{
                row['bara_num'] = '';
            }
            if(mainTableData[i]['item_num'] != "") {
                row['item_num'] = mainTableData[i]['item_num'];
            }else{
                vmsg = "数量を入力してください。";       
                validate_flg = false;
                break;
            }
            if(mainTableData[i]['item_deadline_date'] != "") {
                row['item_deadline_date'] = mainTableData[i]['item_deadline_date'];
            }else{
                vmsg = "商品期限を入力してください。";       
                validate_flg = false;
                break;
            }
            row['remark'] = mainTableData[i]['remark'];
            
            itemData[i] = row;
        }
        if(itemData.length <= 0) {
            validate_flg = false;
        }

        if(!validate_flg){
            toast.error(vmsg, {autoClose:3000}); 
            return;
        }
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // sys_arrival_slip_id
    const [SysArrivalSlipId, setSysArrivalSlipId] = useState(0);
    // slip date
    const [SlipDate, setSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeSlipDate = (date) => {
        setSlipDate(useJwt.formatDate(date));
    };
    // slip no -------------------------------------------------
    const [SlipNo, setSlipNo] = useState("");
    const handleChangeSlipNo = (event) => {
        setSlipNo(useJwt.validateNum(event.target.value,6));
    };
    const onKeyDownSlipNo = (event) => {
        if(event.keyCode === 13){
            let data = {};
            data['slip_date'] = SlipDate;
            data['slip_no'] = event.target.value;
            data['shipper_code'] = ShipperCode;
            data['shipper_a_code'] = ShipperACode;
            getSlipData(data);
            event.preventDefault();
        }
    }
    // question no ---------------------------------------------
    const [QuestionNo, setQuestionNo] = useState("");
    const handleChangeQuestionNo = (event) => {
        setQuestionNo(useJwt.validateNum(event.target.value,8));
    };
    // shipper ---------------------------------------------------
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        setShipperCode(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode, setShipperACode] = useState("");
    const handleChangeShipperACode = (event) => {
        setShipperACode(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName, setShipperName] = useState("");
    const onKeyDownShipperCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            if(param == 1) {
                getShipperData(event.target.value,ShipperACode);
            }else{
                getShipperData(ShipperCode,event.target.value);
            }
            event.preventDefault();
        }
    };
    const setShipperData = (result) => {
        if(result['shipper_code'] != null) {
            setShipperCode(result['shipper_code']);
            setShipperACode(result['shipper_a_code']);
            setShipperName(result['shipper_name']);
            getItemRemainNum(SlipDate,result['shipper_code']);

            let data = {};
            data['slip_date'] = SlipDate;
            data['slip_no'] = SlipNo;
            data['shipper_code'] = result['shipper_code'];
            data['shipper_a_code'] = result['shipper_a_code'];
            getSlipData(data);
        }else{
            setShipperCode("");
            setShipperACode("");
            setShipperName("");
            setItemRemainNum("0.000");

            getSlipData([]);
        }
    }
    const getShipperData = (code, acode) => {
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }else{
                data['shipper_a_code'] = "000";
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result);
                    }else{
                        setShipperData([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([]);
        }
    }
    // summary ----------------------------------------------------
    const [SummaryCode, setSummaryCode] = useState("");
    const handleChangeSummaryCode = (event) => {
        setSummaryCode(useJwt.validateNum(event.target.value,4));
    };
    const [SummaryName, setSummaryName] = useState("");
    const onKeyDownSummaryCode = (event) => {
        if(event.keyCode === 13) { // enter key
            getSummaryData(event.target.value);
            event.preventDefault();
        }
    };
    const getSummaryData = (code) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                summary_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstSummaryEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setSummaryData(t_data[0]);
                }else{
                    setSummaryData([]);
                }
            })
            .catch((error) => {
            });
        }else{
            setSummaryData([]);
        }
    }
    const setSummaryData = (result) => {
        if(result['sys_summary_id']) {
            setSummaryCode(result['summary_code']);
            setSummaryName(result['summary_name']);
        }else{
            setSummaryName("");
        }
    }
    // ====================================== table ====================================
    const [mainTableData, setMainTableData] = useState([]);
    const [TotalNum, setTotalNum] = useState("0.000");
    const [TotalWeight, setTotalWeight] = useState("0.000");
    const [TotalVolume, setTotalVolume] = useState("0.000");
    const [ItemRemainNum, setItemRemainNum] = useState("0.000");

    const getSlipData = (filter) => {
        let data = {};
        let validateflg = true;
        data['userID'] = userData['id'];
        if(filter['slip_date'] != null && filter['slip_date'] != "") {
            data['start_slip_date'] = filter['slip_date'];
            data['end_slip_date'] = filter['slip_date'];
        }else{
            validateflg = false;
        }
        if(filter['shipper_code'] != null && filter['shipper_code'] != "") {
            data['start_shipper_code'] = filter['shipper_code'];
            data['end_shipper_code'] = filter['shipper_code'];
        }else{
            validateflg = false;
        }
        if(filter['shipper_a_code'] != null && filter['shipper_a_code'] != "") {
            data['start_shipper_a_code'] = filter['shipper_a_code'];
            data['end_shipper_a_code'] = filter['shipper_a_code'];
        }else{
            validateflg = false;
        }
        if(filter['slip_no'] != null && filter['slip_no'] != "") {
            data['slip_no'] = filter['slip_no'];
        }else{
            validateflg = false;
        }
        if(validateflg) {
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.searchArrivalSlipEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setSysArrivalSlipId(t_data[0]['sys_arrival_slip_id']);
                    setQuestionNo(t_data[0]['question_no']);
                    setSummaryCode(t_data[0]['summary_code']);
                    setSummaryName(t_data[0]['summary_name']);
                    gridMainTable(t_data);
                }else{
                    setSysArrivalSlipId(0);
                    gridMainTable([]);
                }
            })
            .catch((error) => {
            });
        }else{
            gridMainTable([]);
        }
    }
    const gridMainTable = (result) => {
        let data = [];
        let total_num = 0;
        let total_weight = 0;
        let total_volume = 0;
        for(var i=0; i<result.length; i++) {
            let row = {};
            row = result[i];
            total_num += 1*row['item_num'];
            total_weight += (1*row['item_num']*row['unit_weight']);
            total_volume += (1*row['item_num']*row['unit_volume']);
            data[i] = row;
        }
        setTotalNum(total_num.toFixed(3));
        setTotalWeight(total_weight.toFixed(3));
        setTotalVolume(total_volume.toFixed(3));
        setMainTableData(data);
    }
    const handlChangeItemData = (event, control_type, col , index) => {
        let value = '';
        if(control_type==2){
            value = useJwt.formatDate(event);
        }else{
            value = event.target.value;
        }

        if(col == 'item_num'){
            var weight = $('.table_weight').eq(index).val();
            var rotWeight = $('.rot_weight').eq(index).val();

            if(parseFloat(weight) + parseFloat(rotWeight * value) > 1){
                var i = 1;
                while(parseFloat(weight) + parseFloat(rotWeight * i) <= 1){
                    i++;
                }
                toast.error("数量を超えました。設定した棚に" + (i - 1) +"個以上入庫できません。", {autoClose:3000});
                $('.item_num').eq(index).val("");
                return;
            }
        }
        if(col == 'item_code' || col == 'rot_code'){
            value = useJwt.validateString(value,15);
        }else if(col == 'item_a_code'){
            value = useJwt.validateNum(value,3);
        }else if(col == 'shipper_item_code'){
            value = useJwt.validateString(value,15);
        }else if(col == 'warehouse_code'){
            value = useJwt.validateNum(value,2);
        }else if(col == 'table_code'){
            value = useJwt.validateString(value,8);
        }else if(col == 'item_unit') {
            value = useJwt.validateString(value,4);
        }else if(col == 'item_num' || col == 'case_num' || col == 'bara_num') {
            value = useJwt.validateDecimal(value,6,3);
        }else if(col == 'remark') {
            value = useJwt.validateString(value,10);
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row[col] = value;
                if(col == 'item_code' || col == 'item_a_code') {
                    row['shipper_item_code'] = "";
                    row['rot_code'] = "";
                    row['case_num'] = "";
                    row['bara_num'] = "";
                }
            }
            data[i] = row;
        }
        gridMainTable(data);
    }
    const allCheckData = (event) => {
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', event.target.checked);
    }
    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };
    const newRecord = (event) => {
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            data[i] = row;
        }
        let row = {};
        row['sys_item_id'] = 0;
        row['item_code'] = "";
        row['item_a_code'] = "";
        row['shipper_item_code'] = "";
        row['item_name'] = "";
        row['rot_code'] = "";
        row['rot_name'] = "";
        row['rot_weight'] = 0;
        row['warehouse_code'] = "";
        row['warehouse_type'] = "";
        row['warehouse_name'] = "";
        row['table_code'] = 0;
        row['item_unit'] = 0;
        row['unit_weight'] = 0;
        row['unit_volume'] = 0;
        row['case_num'] = 0;
        row['bara_num'] = 0;
        row['item_num'] = 0;
        row['item_deadline_date'] = useJwt.formatDate(new Date());
        row['remark'] = "";

        data[i] = row; 
        gridMainTable(data);
    }
    const deleteItem = (event) => {
        setShowModalDel2(false);
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked){
                remainIndexs.push(i);
            }
        }
        
        let remainData = [];
        for(var i=0; i<mainTableData.length; i++) {
            if(mainTableData[i]['sys_item_id']>0) {
                let row = {};
                row = mainTableData[i];
                remainData.push(row);
            }else{
                for(var j=0; j<remainIndexs.length; j++){
                    if(i==remainIndexs[j]){
                        let row = {};
                        row = mainTableData[i];
                        remainData.push(row);
                    }
                }
            }
        }
        gridMainTable(remainData);
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', false);
    }
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_daily_flag_1'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const getItemRemainNum = (date, shipper_code) => {
        let data = {};
        data['userID'] = userData['id'];
        data['end_slip_date'] = date;
        data['shipper_code'] = shipper_code;
        if(ShipperACode != "") {
            data['shipper_a_code'] = ShipperACode;
        }

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockHistoryByItemEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            if(result.length>0) {
                let total_in_num = 0;
                let total_out_num = 0;
                for(var i=0; i<result.length; i++) {
                    if(result[i]['arrival_num'] != "") {
                        total_in_num += (1*result[i]['arrival_num']);
                    }else if(result[i]['leaving_num'] != "") {
                        total_out_num += (1*result[i]['leaving_num']);
                    }
                }
                let item_remain_num = total_in_num-total_out_num;
                setItemRemainNum(item_remain_num.toFixed(3));
            }else{
                setItemRemainNum("0.000");
            }
        })
        .catch((error) => {
        });
    }

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysArrivalSlipId>0) {
            data['sys_arrival_slip_id'] = SysArrivalSlipId;
        }

        data['slip_date'] = SlipDate;
        data['slip_no'] = SlipNo;
        data['shipper_code'] = ShipperCode;
        data['shipper_a_code'] = ShipperACode;
        data['question_no'] = QuestionNo;
        data['summary_code'] = SummaryCode;

        // item data --------------------------------------
        let validate_flg = true;
        let vmsg = "";
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            if(mainTableData[i]['sys_item_id'] != 0) {
                row['sys_item_id'] = mainTableData[i]['sys_item_id'];
            }
            
            row['item_code'] = mainTableData[i]['item_code'];
            row['item_a_code'] = mainTableData[i]['item_a_code'];
            row['shipper_item_code'] = mainTableData[i]['shipper_item_code'];
            row['rot_code'] = mainTableData[i]['rot_code'];
            row['warehouse_code'] = mainTableData[i]['warehouse_code'];
            row['table_code'] = mainTableData[i]['table_code'];
            row['weight'] = mainTableData[i]['weight'] + (mainTableData[i]['rot_weight'] * mainTableData[i]['item_num']);
            row['case_num'] = mainTableData[i]['case_num'];
            row['bara_num'] = mainTableData[i]['bara_num'];
            row['item_num'] = mainTableData[i]['item_num'];
            row['item_deadline_date'] = mainTableData[i]['item_deadline_date'];
            row['remark'] = mainTableData[i]['remark'];

            itemData[i] = row;
        }
        data['items'] = itemData;
        // if(SysArrivalSlipId == 0) {
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setArrivalSlipItemsEndpoint;
            csloading(true);
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data == 1) {
                        toast.success("登録致しました。", {autoClose:3000});
                        setSlipNo("");
                        setShipperCode("");
                        setShipperACode("");
                        setShipperName("");
                        setQuestionNo("");
                        setMainTableData([]);
                    }else if(response.data.data == 2){
                        toast.error("すでに登録済みです。", {autoClose:3000});
                    }else{
                        toast.error("登録に失敗しました。", {autoClose:3000});
                    }
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }
            });
        setOpen(false);
    }
    
    const loadModalDelData = (event) => {
        event.preventDefault();
    }
    const loadModalDelData2 = (event) => {
        event.preventDefault();
        setShowModalDel2(true);
    }
    const deleteData = (event) => {
        event.preventDefault();
    }
    // ========================== starts code for search ===============================
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event) => {
        event.preventDefault();
        setShipperSearchValue(ShipperCode);
        setShipperSearchValue2(ShipperACode);
        if(ShipperCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(ShipperCode != "") {
                data['shipper_code'] = ShipperCode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,id, code, acode) => {
        document.getElementById('selectedId').value=id;
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode);
        if(code != "" && ShipperCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            data['shipper_code'] = ShipperCode;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            if(ShipperCode != ""){
                setShowModalItem(true);
            }else{
                setShowModalItem(false);
            }
        }
    }
    const setItemData = (data) => {
        let item_code = "";
        let item_a_code = "";
        let item_name = "";
        let item_unit = "";
        let unit_weight = 0;
        let unit_volume = 0;
        if(data['item_code'] && data['shipper_code'] == ShipperCode) {
            item_code = data['item_code'];
            item_a_code = data['item_a_code'];
            item_name = data['item_name'];
            item_unit = data['item_unit'];
            unit_weight = data['unit_weight'];
            unit_volume = data['unit_volume'];
        }
        
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i==document.getElementById('selectedId').value){
                row['item_code'] = item_code;
                row['item_a_code'] = item_a_code;
                row['item_name'] = item_name;
                row['item_unit'] = item_unit;
                row['unit_weight'] = unit_weight;
                row['unit_volume'] = unit_volume;
            }
            itemData[i] = row;
        }
        gridMainTable(itemData);
    }
    // ------------------------------ rot modal ---------------------------------------
    const [showModalRot, setShowModalRot] = useState(false);
    const [RotSearchValue, setRotSearchValue] = useState("");
    const [RotSearchValue2, setRotSearchValue2] = useState("");
    const [RotSearchValue3, setRotSearchValue3] = useState("");
    const [RotSearchValue4, setRotSearchValue4] = useState("");
    const [RotTableData, setRotTableData] = useState([]);
    const loadRotModalData = (event,id, rot_code, shipper_code, item_code,item_a_code) => {
        document.getElementById('selectedId').value=id;
        setRotSearchValue(rot_code);
        setRotSearchValue2(shipper_code);
        setRotSearchValue3(item_code);
        setRotSearchValue4(item_a_code);
        if(rot_code != "" && shipper_code != "" && item_code != "" && item_a_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['rot_code'] = rot_code;
            data['shipper_code'] = shipper_code;
            data['item_code'] = item_code;
            data['item_a_code'] = item_a_code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstRotEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setRotTableData(response.data.data);
                setShowModalRot(true);
            })
            .catch((error) => {
            });
        }else{
            setRotTableData([]);
            if(shipper_code != "" && item_code != "" && item_a_code != "") {
                setShowModalRot(true);
            }else{
                setShowModalRot(false);
            }
        }
    }
    const setRotData = (data) => {
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i==document.getElementById('selectedId').value){
                if(data['shipper_code'] == ShipperCode 
                && data['item_code'] == row['item_code'] && data['item_a_code']==row['item_a_code']) {
                    row['rot_code'] = data['rot_code'];
                    row['rot_name'] = data['rot_name'];
                    if(data['limit_amount'])    row['rot_weight'] = Math.round((1 / data['limit_amount']) * 10000) / 10000;
                    else    row['rot_weight'] = 0;
                }else{
                    row['rot_code'] = "";
                    row['rot_name'] = "";
                    row['rot_weight'] = 0;
                }
            }
            itemData[i] = row;
        }
        gridMainTable(itemData);
    }

    // ----------------------------- Table modal -------------------------------------
    const [showModalTable, setShowModalTable] = useState(false);
    const [tableSearchValue, setTableSearchValue] = useState("");
    const [tableSearchValue2, setTableSearchValue2] = useState("");
    const [tableSearchValue3, setTableSearchValue3] = useState("");
    const [tableData, setTableData] = useState([]);
    const loadTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = $(".warehouse_code").eq(idx).val();
        var rot_weight = $(".rot_weight").eq(idx).val();

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setTableSearchValue(table_code);
        setTableSearchValue2(warehouse_code);
        setTableSearchValue3(rot_weight);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;
            data['weight'] = rot_weight;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData(response.data.data);
                setShowModalTable(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData([]);
            setShowModalTable(true);
        }
    }
    const setTableCode = (data) => {
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i==document.getElementById('selectedId').value){
                if(data) {
                    row['table_code'] = data['table_code'];
                    row['weight'] = data['weight'];
                }else{
                    row['table_code'] = "";
                    row['weight'] = 0;
                }
            }
            itemData[i] = row;
        }
        gridMainTable(itemData);
    }

    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const loadWarehouseModalData = (event,id,warehouse_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=id;
        setWarehouseSearchValue(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(warehouse_code != "") {
                data['warehouse_code'] = warehouse_code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    const setWarehouseData = (data) => {
        let itemData = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i==document.getElementById('selectedId').value){
                if(data['warehouse_code']) {
                    row['warehouse_code'] = data['warehouse_code'];
                    row['warehouse_name'] = data['warehouse_name'];
                    row['warehouse_type'] = data['warehouse_type'];
                }else{
                    row['warehouse_code'] = "";
                    row['warehouse_name'] = "";
                    row['warehouse_type'] = 0;
                }
                // getLocationData(data,i);
            }
            itemData[i] = row;
        }
        gridMainTable(itemData);
    }

    // ------------------------------ Summary Modal --------------------------------------
    const [showModalSummary, setShowModalSummary] = useState(false);
    const [SummarySearchValue, setSummarySearchValue] = useState("");
    const [SummaryTableData, setSummaryTableData] = useState([]);
    const loadSummaryModalData = (event) => {
        event.preventDefault();
        let code = SummaryCode;
        setSummarySearchValue(code);

        if(code != ""){
            const data = {
                userID: userData['id'],
                summary_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstSummaryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setSummaryTableData(response.data.data);
                setShowModalSummary(true);
            })
            .catch((error) => {
            });
        }else{
            setSummaryTableData([]);
            setShowModalSummary(true);
        }
    }
    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_total_input_wrap = [gstyle.p0015, gstyle.mt30];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_220px = [gstyle.cs_input, gstyle.w220px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_input_80px = [gstyle.cs_input, gstyle.w80px];
    const style_split_span = [style.split_span];
    const style_select_245px = [gstyle.cs_select, gstyle.w245px];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];
    const style_input_80_bl_n = [gstyle.cs_input, gstyle.w80, gstyle.cs_bl_n];
    const style_tbl_input_bl_n = [style.cs_tbl_input, gstyle.cs_bl_n];

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_1 = [style.cs_th, gstyle.cs_col_1];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_1 = [style.cs_td, gstyle.cs_col_1];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_th_w1p = [style.cs_th, style.w1p];
    const style_th_w2p = [style.cs_th, style.w2p];
    const style_th_w3p = [style.cs_th, style.w3p];
    const style_th_w4p = [style.cs_th, style.w4p];
    const style_th_w5p = [style.cs_th, style.w5p];
    const style_th_w6p = [style.cs_th, style.w6p];
    const style_th_w7p = [style.cs_th, style.w7p];


    const style_td_w1p = [style.cs_td, style.w1p];
    const style_td_w2p = [style.cs_td, style.w2p];
    const style_td_w2p_flex = [style.cs_td, style.w2p, gstyle.cs_flex];
    const style_td_w3p = [style.cs_td, style.w3p, gstyle.cs_flex];
    const style_td_w4p = [style.cs_td, style.w4p];
    const style_td_w5p = [style.cs_td, style.w5p];
    const style_td_w6p = [style.cs_td, style.w6p, gstyle.cs_flex];
    const style_td_w7p = [style.cs_td, style.w7p];

    const style_th_total_title = [style.cs_th, style.total_label];
    const style_td_total_label = [style.cs_td, style.total_label];
    const style_td_total_val = [style.cs_td];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} 
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} />
            <TableModal show={showModalTable} onClose={()=>setShowModalTable(false)} width={'70%'}
                SearchValue={tableSearchValue} SearchValue2={tableSearchValue2} SearchValue3={tableSearchValue3}  onChange={setTableSearchValue} 
                tableData={tableData} setTableData={setTableData}
                target={setTableCode} />
            <RotModal show={showModalRot} onClose={()=>setShowModalRot(false)} width={'70%'}
                SearchValue={RotSearchValue} onChange={setRotSearchValue} 
                SearchValue2={RotSearchValue2} SearchValue3={RotSearchValue3} SearchValue4={RotSearchValue4}
                tableData={RotTableData} setTableData={setRotTableData}
                target={setRotData} />
            <SummaryModal show={showModalSummary} onClose={()=>setShowModalSummary(false)} width={'70%'}
                SearchValue={SummarySearchValue} onChange={setSummarySearchValue} 
                tableData={SummaryTableData} setTableData={setSummaryTableData}
                target={setSummaryData} />
            <Modal2 title="入庫入力削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <Modal2 title="入庫入力削除" show={showModalDel2} onClose={()=>setShowModalDel2(false)} width={'40%'} 
                onConfirm={deleteItem}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>入庫入力</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 年月日, 伝票No, 問合せNo */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={SlipDate} onChange={handleChangeSlipDate} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>伝票No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")} 
                                        onKeyDown={(e) => onKeyDownSlipNo(e)}
                                        value={SlipNo} onChange={handleChangeSlipNo} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>問合せNo</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80.join(" ")}  
                                        value={QuestionNo} onChange={handleChangeQuestionNo} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1)}
                                        value={ShipperCode} onChange={handleChangeShipperCode} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2)}
                                        value={ShipperACode} onChange={handleChangeShipperACode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled id="ShipperName"
                                        value={ShipperName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        { /* 追加, 削除 */}
                        <div className={style_col_4.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")}>
                                <button className={gstyle.btn_new} onClick={newRecord} >追加</button>
                                <button className={gstyle.btn_delete2} onClick={loadModalDelData2} >削除</button>
                            </div>
                        </div>
                    </div>
                    {/* table */}
                    <div className={style.tbl_wrap}>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_w2p.join(" ")}>品番</div>
                                    <div className={style_th_w1p.join(" ")}>荷主品番</div>
                                    <div className={style_th_w2p.join(" ")}>品名</div>
                                    <div className={style_th_w3p.join(" ")}>ロットNo</div>
                                    <div className={style_th_w5p.join(" ")}>ロット名</div>
                                    <div className={style_th_w6p.join(" ")}>倉庫</div>
                                    <div className={style_th_w7p.join(" ")}>倉庫名</div>
                                    <div className={style_th_w4p.join(" ")}>棚コード</div>
                                    <div className={style_th_w4p.join(" ")}>単位</div>
                                    <div className={style_th_w4p.join(" ")}>ケース数</div>
                                    <div className={style_th_w4p.join(" ")}>バラ数</div>
                                    <div className={style_th_w4p.join(" ")}>数量</div>
                                    <div className={style_th_w4p.join(" ")}>商品期限</div>
                                    <div className={style_th_w4p.join(" ")}>備考</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                            id={'chk_'+index} onChange={handleChangeChk} />
                                        </div>
                                        <div className={style_td_w2p_flex.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            value={data['item_code']?data['item_code']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'item_code',index)} />
                                            <input type={'number'} className={style_tbl_input_bl_n.join(" ")} 
                                            value={data['item_a_code']?data['item_a_code']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'item_a_code',index)} />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadItemModalData(e,index,data['item_code']?data['item_code']:'',data['item_a_code']?data['item_a_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                        <div className={style_td_w1p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            value={data['shipper_item_code']?data['shipper_item_code']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'shipper_item_code',index)} />
                                        </div>
                                        <div className={style_td_w2p.join(" ")}>
                                            {data['item_name']?data['item_name']:''}
                                        </div>
                                        <div className={style_td_w3p.join(" ")}>
                                            <input type={'text'} class="rot_code" 
                                            style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                            padding: '0px 5px',color: '#565656',fontSize: 16,outline: 0}} 
                                            value={data['rot_code']?data['rot_code']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'rot_code',index)} />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadRotModalData(e,index,data['rot_code']?data['rot_code']:''
                                                ,data['shipper_code']?data['shipper_code']:ShipperCode
                                                ,data['item_code']?data['item_code']:''
                                                ,data['item_a_code']?data['item_a_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            <input type={'hidden'} class="rot_weight" value={data['rot_weight']?data['rot_weight']:0}/>
                                        </div>
                                        <div className={style_td_w5p.join(" ")}>
                                            {data['rot_name']?data['rot_name']:''}
                                        </div>
                                        <div className={style_td_w6p.join(" ")}>
                                            <input type={'num'} class="warehouse_code" 
                                            style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                            padding: '0px 5px',color: '#565656',fontSize: 16,outline: 0}}
                                            value={data['warehouse_code']?data['warehouse_code']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'warehouse_code',index)} />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadWarehouseModalData(e,index,data['warehouse_code']?data['warehouse_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                        <div className={style_td_w7p.join(" ")}>
                                            {data['warehouse_name']?data['warehouse_name']:''}
                                        </div>
                                        <div className={style_td_w4p.join(" ")} style={{display:'flex'}}>
                                        {
                                            ((data['warehouse_type']?data['warehouse_type']:0) != 0) &&
                                            <>
                                            <input type={'num'} className={style.cs_tbl_input} 
                                            value={data['table_code']?data['table_code']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'table_code',index)} />
                                            <div className={gstyle.icon_search_wrap} 
                                            onClick={(e)=>loadTableModalData(e,index,data['table_code']?data['table_code']:'')}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                            <input type={'hidden'} class="table_weight" value={data['weight']?data['weight']:0}/>
                                            </>
                                        }
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            {data['item_unit']?data['item_unit']:''}
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            disabled={data['item_code']!=''&&data['item_a_code']!=''&&data['shipper_item_code']!=''?false:true}
                                            value={data['case_num']?data['case_num']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'case_num',index)} />
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            disabled={data['item_code']!=''&&data['item_a_code']!=''&&data['shipper_item_code']!=''?false:true}
                                            value={data['bara_num']?data['bara_num']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'bara_num',index)} />
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'text'} class="item_num" 
                                            style={{border: 'solid 1px #D6D6D6',width: 'calc(100% - 15px)',margin: 0,height: 30,
                                            padding: '0px 5px',color: '#565656',fontSize: 16,outline: 0}} 
                                            value={data['item_num']?data['item_num']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'item_num',index)} />
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <InputDatepicker  value={data['item_deadline_date']?data['item_deadline_date']:''} 
                                            onChange={(e) => handlChangeItemData(e,2,'item_deadline_date',index)} />
                                        </div>
                                        <div className={style_td_w4p.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                            value={data['remark']?data['remark']:''} 
                                            onChange={(e) => handlChangeItemData(e,1,'remark',index)} />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* Total */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_total_input_wrap.join(" ")}>
                                <div className={style.cs_tbl}>
                                    <div className={style.cs_thead}>
                                        <div className={style.cs_tr}>
                                            <div className={style_th_total_title.join(" ")}>合計</div>
                                        </div>
                                    </div>
                                    <div className={style.cs_tbody}>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_label.join(" ")}>数量</div>
                                            <div className={style_td_total_val.join(" ")}>{TotalNum}</div>
                                        </div>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_label.join(" ")}>重量</div>
                                            <div className={style_td_total_val.join(" ")}>{TotalWeight}</div>
                                        </div>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_label.join(" ")}>容積</div>
                                            <div className={style_td_total_val.join(" ")}>{TotalVolume}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_total_input_wrap.join(" ")}>
                                <div className={style.cs_tbl}>
                                    <div className={style.cs_thead}>
                                        <div className={style.cs_tr}>
                                            <div className={style_th_total_title.join(" ")}>現在残</div>
                                        </div>
                                    </div>
                                    <div className={style.cs_tbody}>
                                        <div className={style.cs_tr}>
                                            <div className={style_td_total_val.join(" ")}>{ItemRemainNum}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 摘要 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>摘要コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownSummaryCode(e)}
                                        value={SummaryCode} onChange={handleChangeSummaryCode} />
                                        <input type={'text'} className={style_input_80_bl_n.join(" ")}  
                                        value={SummaryName} disabled />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadSummaryModalData(e)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >登録する</button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default ArrivalSlip;