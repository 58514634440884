import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import style from "./QRCode.module.css";
import * as useJwt from "../../auth/jwt/useJwt.js";
import ClipLoader from "react-spinners/ClipLoader";
import { QRCodeCanvas } from "qrcode.react";
import ReactToPrint from "react-to-print";

const QRCode = (props) => {
  const [itemInformation, setItemInformation] = useState([]);

  const QRRef = useRef();

  useEffect(() => {
	const item = JSON.parse(localStorage.getItem('QRcode'));
    console.log("info.state", item);
    if (item) {
      setItemInformation(item);
    }
  }, [props]);

  // ========================== print =================================================
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    // console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {}, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    // console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 1000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <div className={style.cs_flex + " " + style.mt20}>
        <button className={style.link}>印刷</button>
      </div>
    );
  }, []);

  return (
    <>
      <div className={style.container}>
        <div className={style.loader_container} id="csloader">
          <ClipLoader color={"#fff"} size={60} />
        </div>

        <div className={style.title}>QRコード印刷</div>
        <ReactToPrint
          content={() => QRRef.current}
          documentTitle={
            useJwt.formatDateTime(new Date())
          }
          onAfterPrint={handleAfterPrint}
          onBeforeGetContent={handleOnBeforeGetContent}
          onBeforePrint={handleBeforePrint}
          removeAfterPrint
          trigger={reactToPrintTrigger}
        />
        <div ref={QRRef}>
          <div className={style.cs_flex + " " + style.mt20}>
            <div className={style.qr_container}>
              <div className={style.cs_flex + " " + style.mt20} style={{flexDirection:'column'}}>
                {itemInformation.map((item, index) => (
                  <div className={style.QRcode}>
                    <QRCodeCanvas
                      id="qrCode"
                      value={item}
                      size={150}
                      bgColor={"#ffffff"}
                      level={"H"}
					  key={index}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCode;
