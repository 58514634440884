import React from "react";
import style from './SelectField.module.css';

const SelectFiled = (props) => {
    const {label, register, name, option,} = props;

    return(
        <div className={style.formSelect} style={props.styles}>
            <label className={style.label}>
                {label}
                <div className={`${!label && style.unlabel }`} style={{position:'relative'}}>
                    <select className={`${style.input}`} {...register('inputs.'+name)}>
                        {option && option.map((data) => (
                            <option key={data}>{data}</option>
                        ))}
                    </select>
                    <div className={style.triangle}></div>
                </div>
            </label>
        </div>
    )
}

export default SelectFiled;