/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import gstyle from '../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import * as useJwt from '../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import WarehouseModal from '../../components/common/modal/WarehouseUserModal';
import IconSearch from '../../components/common/icons/icon_search.png';
import InputDatepicker from '../../components/common/input/InputDatepicker';
import { Input, Button } from "antd";

import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

const Subscription = (props) => {

    const authentication = useJwt.isShipperUserLoggedIn();
    const userData = JSON.parse(useJwt.getShipperUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {

        if (shipperToken == "") {
            toast.error("申請用トークンを入力してください。", { autoClose: 3000 });
            return;
        }

        if (messageContent == "") {
            toast.error("メッセージを入力してください。", { autoClose: 3000 });
            return;
        }
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const csLoading = (flag) => {
        if (flag) {
            document.getElementById('csLoader').style.display = "flex";
        } else {
            document.getElementById('csLoader').style.display = "none";
        }
    }



    // slip date
    const [subscriptionDate, setSubscriptionDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeSubscriptionDate = (date) => {
        setSubscriptionDate(useJwt.formatDate(date));
    };

    // warehouse --------------------------------------------------
    const [shipperToken, setShipperToken] = useState("");
    const handleChangeShipperToken = (event) => {
        setShipperToken(event.target.value);
    };
    // =================================================================================

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            setLoaded(true);
        }
    }, [props]);

    const [messageContent, setMessageContent] = useState("");
    const onChangeMessageContent = (e) => {
        setMessageContent(e.target.value);
    }

    const saveData = () => {
        let data = {};
        data['userID'] = userData['id'];
        data['token'] = shipperToken;
        data['shipper_user_id'] = userData['id'];
        data['message'] = messageContent;
        data['subscription_date'] = subscriptionDate;

        setOpen(false);
        csLoading(true);
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setWarehouseSubscriptionEndpoint;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                csLoading(false);
                if (response.data == 1) {
                    toast.success("登録致しました。", { autoClose: 3000 });
                } else if (response.data == 3) {
                    toast.error("すでに申請されている倉庫会社です。", { autoClose: 3000 });
                } else if (response.data == 4) {
                    toast.error("倉庫会社情報が存在しません。", { autoClose: 3000 });
                }
                setShipperToken("")
                setMessageContent("")
            })
            .catch((error) => {
                csLoading(false);
                toast.error("登録に失敗しました。", { autoClose: 3000 });
            });

    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w220px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const { TextArea } = Input;
    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csLoader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />

            <div className={gstyle.border}>
                <p className={gstyle.text}>倉庫会社申請</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1} style={{ paddingLeft: 100, paddingRight: 100 }}>
                    {/* 申請日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>申請日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker value={subscriptionDate} onChange={handleChangeSubscriptionDate} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>申請用トークン</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")}
                                            value={shipperToken} onChange={handleChangeShipperToken} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>メッセージ</span>
                                    <div className={gstyle.cs_flex}>
                                        <TextArea rows={6} style={{ borderRadius: 0, marginTop: 20 }} value={messageContent} onChange={onChangeMessageContent} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button type="primary" onClick={handleOpen} style={{ marginTop: 50 }}>申請する</Button>
                        </div>
                    </div>
                </div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>
                        {" "}
                        <div style={{ fontSize: "1.1rem" }}>
                            こちらの内容で登録しますか？
                        </div>{" "}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose} className={gstyle.save_confirm_no}>
                            いいえ
                        </Button>
                        <Button
                            onClick={saveData}
                            className={gstyle.save_confirm_yes}
                            autoFocus
                        >
                            はい
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default Subscription;