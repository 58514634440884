/* eslint-disable eqeqeq */
import style from './StockHistoryByItem.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import RotModal from '../../../components/common/modal/RotModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import {Select} from 'antd'


const StockHistoryByItem = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    // slip start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };
    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('warehouse_code2').focus();
            }else{
                document.getElementById('type1').focus();
            }
        }
    };
    const getWarehouseData = (code,param) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result,param);
                    }else{
                        setWarehouseData([],param);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setWarehouseData([],param);
        }
    }
    const setWarehouseData = (data,param) => {
        if(data['warehouse_code']) {
            if(param == 1) {
                setWarehouseCode1(data['warehouse_code']);
                setWarehouseName1(data['warehouse_name']);
            }else{
                setWarehouseCode2(data['warehouse_code']);
                setWarehouseName2(data['warehouse_name']);
            }
        }else{
            if(param == 1) {
                setWarehouseCode1("");
                setWarehouseName1("");
            }else{
                setWarehouseCode2("");
                setWarehouseName2("");
            }
        }
    }
    // shipper ---------------------------------------------------
    const [ShipperCode, setShipperCode] = useState("");
    const handleChangeShipperCode = (event) => {
        setShipperCode(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode, setShipperACode] = useState("");
    const handleChangeShipperACode = (event) => {
        setShipperACode(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName, setShipperName] = useState("");
    
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            if(param2 == 2) {
                if(event.target.value != ""){
                    getShipperData(event.target.value,param1,param2);
                }
                document.getElementById('item_code').focus();
            }else{
                getShipperData(event.target.value,param1,param2);
                document.getElementById('shipper_a_code').focus();
            }
            event.preventDefault();
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ShipperACode;
            }else{
                code = ShipperCode;
                acode = value;
            }
        }

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode(data['shipper_code']);
                setShipperACode(data['shipper_a_code']);
                setShipperName(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode("");
                setShipperACode("");
                setShipperName("");
            }
        }
    }
    // item ----------------------------------------------------
    const [ItemCode, setItemCode] = useState("");
    const handleChangeItemCode = (event) => {
        setItemCode(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode, setItemACode] = useState("");
    const handleChangeItemACode = (event) => {
        setItemACode(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName, setItemName] = useState("");
    
    const onKeyDownItemCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getItemData(event.target.value,param1,param2);
            event.preventDefault();

            if(param2 == 1){
                document.getElementById('item_a_code').focus();
            }else{
                document.getElementById('warehouse_code1').focus();
            }
        }
    };
    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ItemACode;
            }else{
                code = ItemCode;
                acode = value;
            }
        }

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result,param1);
                    }else{
                        setItemData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([],param1);
        }
    }
    const setItemData = (data,param) => {
        if(data['item_code']) {
            if(param == 1) {
                setItemCode(data['item_code']);
                setItemACode(data['item_a_code']);
                setItemName(data['item_name']);
            }
        }else{
            if(param == 1) {
                setItemCode("");
                setItemACode("");
                setItemName("");
            }
        }
    }
    // item type -----------------------------------------------
    const [ItemType, setItemType] = useState(0);
    const handleChangeItemType = (event) => {
        setItemType(event);
        gridMainTable([]);
        if(event == 0) {
            setRotData([]);
        }
    };
    // item ----------------------------------------------------
    const [RotCode, setRotCode] = useState("");
    const handleChangeRotCode = (event) => {
        setRotCode(useJwt.validateString(event.target.value,15));
    };
    const [RotName, setRotName] = useState("");
    
    const onKeyDownRotCode = (event) => {
        if(event.keyCode === 13) { // enter key
            getRotData(event.target.value);
            event.preventDefault();
        }
    };
    const getRotData = (value) => {
        let code = value;
        if(code != "" && ShipperCode != "" && ItemCode != "" && ItemACode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['rot_code'] = code;
            data['shipper_code'] = ShipperCode;
            data['item_code'] = ItemCode;
            data['item_a_code'] = ItemACode;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstRotEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setRotData(result);
                    }else{
                        setRotData([]);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setRotData([]);
        }
    }
    const setRotData = (data) => {
        if(data['rot_code']) {
                setRotCode(data['rot_code']);
                setRotName(data['rot_name']);
        }else{
            setRotCode("");
            setRotName("");
        }
    }
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_stock_flag_2'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        data['start_slip_date'] = StartDate;
        data['end_slip_date'] = EndDate;
        if(WarehouseCode1 != "") {
            data['start_warehouse_code'] = WarehouseCode1;
        }
        if(WarehouseCode2 != "") {
            data['end_warehouse_code'] = WarehouseCode2;
        }
        if(ShipperCode != "") {
            data['shipper_code'] = ShipperCode;
        }
        if(ShipperACode != "") {
            data['shipper_a_code'] = ShipperACode;
        }
        if(ItemCode != "") {
            data['item_code'] = ItemCode;
        }
        if(ItemACode != "") {
            data['item_a_code'] = ItemACode;
        }
        if(RotCode != "") {
            data['rot_code'] = RotCode;
        }
        gridMainTable([],0);
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockHistoryByItemEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            if(result.length>0){
                getItemRemainNum(result);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                gridMainTable([],0);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
        
    }

    const getItemRemainNum = (ret) => {
        let data = {};
        data['userID'] = userData['id'];
        data['end_slip_date'] = useJwt.getPreviousDay(StartDate);
        if(WarehouseCode1 != "") {
            data['start_warehouse_code'] = WarehouseCode1;
        }
        if(WarehouseCode2 != "") {
            data['end_warehouse_code'] = WarehouseCode2;
        }
        if(ShipperCode != "") {
            data['shipper_code'] = ShipperCode;
        }
        if(ShipperACode != "") {
            data['shipper_a_code'] = ShipperACode;
        }
        if(ItemCode != "") {
            data['item_code'] = ItemCode;
        }
        if(ItemACode != "") {
            data['item_a_code'] = ItemACode;
        }
        if(RotCode != "") {
            data['rot_code'] = RotCode;
        }

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockHistoryByItemEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            if(result.length>0) {
                let remainAry = [];
                for(var i=0; i<result.length;i++){
                    let remain_num = 0;
                    if(i>0){
                        if(result[i]['arrival_num'] != "" && result[i]['leaving_num'] != ""){
                            remain_num += (remainAry[i-1]*1+1*result[i]['arrival_num']-1*result[i]['leaving_num']);
                        }else{
                            if(result[i]['arrival_num'] != ""){
                                remain_num += (remainAry[i-1]*1+1*result[i]['arrival_num']);
                            }else if(result[i]['leaving_num'] != "") {
                                remain_num += (remainAry[i-1]*1-1*result[i]['leaving_num']);
                            }else{
                                remain_num = remainAry[i-1];
                            }
                        }
                    }else{
                        if(result[i]['arrival_num'] != "" && result[i]['leaving_num'] != ""){
                            remain_num = 1*result[i]['arrival_num']-1*result[i]['leaving_num'];
                        }else{
                            if(result[i]['arrival_num'] != ""){
                                remain_num = 1*result[i]['arrival_num'];
                            }else if(result[i]['leaving_num'] != "") {
                                remain_num = (0*1-result[i]['leaving_num']);
                            }
                        }
                    }
                    remainAry.push(remain_num);
                }
                gridMainTable(ret,remainAry[remainAry.length-1]);
            }else{
                gridMainTable(ret,0);
            }
        })
        .catch((error) => {
        });
    }
    const [mainTableData, setMainTableData] = useState([]);
    const [remainData, setRemainData] = useState([]);

    const gridMainTable = (result,prev_remin_num) => {
        let data = result;
        setMainTableData(data);
        // for remain num
        let remainAry = [];
        for(var i=0; i<result.length;i++){
            let remain_num = 0;
            if(i>0){
                if(result[i]['arrival_num'] != "" && result[i]['leaving_num'] != ""){
                    remain_num += (remainAry[i-1]*1+1*result[i]['arrival_num']-1*result[i]['leaving_num']);
                }else{
                    if(result[i]['arrival_num'] != ""){
                        remain_num += (remainAry[i-1]*1+1*result[i]['arrival_num']);
                    }else if(result[i]['leaving_num'] != "") {
                        remain_num += (remainAry[i-1]*1-1*result[i]['leaving_num']);
                    }else{
                        remain_num = remainAry[i-1];
                    }
                }
            }else{
                if(result[i]['arrival_num'] != "" && result[i]['leaving_num'] != ""){
                    remain_num = 1*prev_remin_num+1*result[i]['arrival_num']-1*result[i]['leaving_num'];
                }else{
                    if(result[i]['arrival_num'] != ""){
                        remain_num = 1*prev_remin_num+1*result[i]['arrival_num'];
                    }else if(result[i]['leaving_num'] != "") {
                        remain_num = 1*prev_remin_num+-result[i]['leaving_num'];
                    }
                }
            }
            remainAry.push(remain_num);
        }
        
        setRemainData(remainAry);
        // for pagenation
        setItemOffset(0);
        const endOffset = 0 + itemsPerPage;
        setCurrentItems(data.slice(0, endOffset));
        setCurrentRemainData(remainAry.slice(0, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }
    // starts pagenation code -----------------------------------------
    const itemsPerPage = 50;
    const [currentItems, setCurrentItems] = useState([]);
    const [currentRemainData, setCurrentRemainData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % mainTableData.length;
        // console.log( `User requested page number ${event.selected}, which is offset ${newOffset}` );
        setItemOffset(newOffset);
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(mainTableData.slice(newOffset, endOffset));
        setCurrentRemainData(remainData.slice(newOffset, endOffset));
    };
    // ends pagenation code ---------------------------------------------
    // ========================== starts code for search ===============================
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWarehouseModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode1;
        if(param==2){
            code = WarehouseCode2;
        }
        setWarehouseSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['warehouse_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode;
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemCode;
        let acode = ItemACode;
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode)
        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    // ------------------------------ rot modal ---------------------------------------
    const [showModalRot, setShowModalRot] = useState(false);
    const [RotSearchValue, setRotSearchValue] = useState("");
    const [RotSearchValue2, setRotSearchValue2] = useState("");
    const [RotSearchValue3, setRotSearchValue3] = useState("");
    const [RotSearchValue4, setRotSearchValue4] = useState("");
    const [RotTableData, setRotTableData] = useState([]);
    const loadRotModalData = (event) => {
        setRotSearchValue(RotCode);
        setRotSearchValue2(ShipperCode);
        setRotSearchValue3(ItemCode);
        setRotSearchValue4(ItemACode);
        if(RotCode != "" && ShipperCode != "" && ItemCode != "" && ItemACode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['rot_code'] = RotCode;
            data['shipper_code'] = ShipperCode;
            data['item_code'] = ItemCode;
            data['item_a_code'] = ItemACode;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstRotEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setRotTableData(response.data.data);
                setShowModalRot(true);
            })
            .catch((error) => {
            });
        }else{
            setRotTableData([]);
            if(ShipperCode != "" && ItemCode != "" && ItemACode != "") {
                setShowModalRot(true);
            }else{
                setShowModalRot(false);
            }
        }
    }

    const [type1Open, setType1Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    
    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap3 = [style.grp_tbl_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} SearchValue3={ItemSearchValue3} onChange2={setItemSearchValue2}
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
            <RotModal show={showModalRot} onClose={()=>setShowModalRot(false)} width={'70%'}
                SearchValue={RotSearchValue} onChange={setRotSearchValue} 
                SearchValue2={RotSearchValue2} SearchValue3={RotSearchValue3} SearchValue4={RotSearchValue4}
                tableData={RotTableData} setTableData={setRotTableData}
                target={setRotData} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>商品受払画面参照</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={StartDate} onChange={handleChangeStartDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={EndDate} onChange={handleChangeEndDate} id='date2' focusId='shipper_code' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        
                        
                    </div>
                    { /* 荷主, 商品 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code'
                                        value={ShipperCode} onChange={handleChangeShipperCode} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code'
                                        value={ShipperACode} onChange={handleChangeShipperACode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>商品</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,1,1)} id='item_code'
                                        value={ItemCode} onChange={handleChangeItemCode} />
                                        <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,1,2)} id='item_a_code'
                                        value={ItemACode} onChange={handleChangeItemACode} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ItemName} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 倉庫 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,1)} id='warehouse_code1'
                                            value={WarehouseCode1} onChange={handleChangeWarehouseCode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={WarehouseName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,2)} id='warehouse_code2'
                                            value={WarehouseCode2} onChange={handleChangeWarehouseCode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={WarehouseName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 品番区分, ロット No, 検索 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="品番区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeItemType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={[
                                                {value:'0', label:'品番集計'},
                                                {value:'1', label:'ロット別'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        {ItemType == 1 && 
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>ロット No</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownRotCode(e)}
                                            value={RotCode} onChange={handleChangeRotCode} />
                                            <input type={'text'} className={gstyle.cs_input} disabled
                                            value={RotName} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadRotModalData(e)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        }
                        { /* 検索 */}
                        <div className={style_col_4.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")}>
                                <button className={gstyle.btn_new} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                            </div>
                        </div>
                    </div>

                    <div className={style.nw_container}>
                        { /* Table */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th >年月日</th>
                                                <th >入力区分</th>
                                                <th >伝票No</th>
                                                <th >倉庫</th>
                                                <th >入庫</th>
                                                <th >出庫</th>
                                                <th >在庫</th>
                                            </tr>
                                        </thead>
                                        <tbody id="tbody_wrap">
                                            {  currentItems.map((data,index) => (
                                                <tr key={index}>
                                                    <td >{useJwt.formatDate(new Date(data['slip_date']))}</td>
                                                    <td >{data['input_type']}</td>
                                                    <td >{data['slip_no']}</td>
                                                    <td >{data['warehouse_code']}</td>
                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data['arrival_num'])}</td>
                                                    <td className={style.td_right}>{useJwt.numberWithCommas(data['leaving_num'])}</td>
                                                    <td className={style.td_right}>{useJwt.numberWithCommas(currentRemainData[index])}</td>
                                                </tr>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {mainTableData.length>0 && (
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
                    
        </div>
    )
}

export default StockHistoryByItem;