import style from './Header.module.css';

const Header = ({isSidebarOpen, setIsSidebarOpen}) => {
    const handleClick = () => {
		setIsSidebarOpen(!isSidebarOpen);
	}

    return(
        <div className={style.header}>
            {/* <div className={isSidebarOpen ? style.left_area : style.left_area_moved}>
				<div className={isSidebarOpen ? style.btn_trigger_clicked : style.btn_trigger} onClick={handleClick}>
					<span className={style.bar1}></span>
					<span className={style.bar2}></span>
					<span className={style.bar3}></span>
				</div>
			</div> */}
            <div className={style.right_area}>
                {/* <div className={style.link}>
                    <p className={style.top_text}>NEWS</p>
                    <p className={style.bottom_text}>お知らせ</p>
                </div>
                <div className={style.link}>
                    <p className={style.top_text}>FAQ</p>
                    <p className={style.bottom_text}>よくあるご質問</p>
                </div>
                <div className={style.link}>
                    <p className={style.top_text}>HELP</p>
                    <p className={style.bottom_text}>サポート</p>
                </div>
                <div className={style.user}>
                    <p className={style.user_top_text}>会員ID・荷主ID</p>
                    <p className={style.user_bottom_text}>ユーザー名</p>
                </div> */}
            </div>
        </div>
    )
}

export default Header;