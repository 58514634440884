/* eslint-disable eqeqeq */
import style from './CarryForward.module.css'
import HeadingText from '../../components/administrator/headingText/HeadingText';
import LinkButton from '../../components/administrator/linkButton/LinkButton';
import * as useJwt  from '../../auth/jwt/useJwt.js';

const App = () => {
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const userData = JSON.parse(useJwt.getUserData());
    return (
        <>
		<label className='bodyLabel'>管理業務</label>
		<div className='contentBody'>
            <HeadingText color='#F8C461' title='月時繰越' />
            <div className={style.links}>
                {	(menuPatternData['menu_carried_over_flag_1'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/carryForward/monthCarryForward' title='月時繰越処理' />
				}
                
            </div>
            <HeadingText color='#73A776' title='履歴参照' />
            <div className={style.links}>
                {	(menuPatternData['menu_carried_over_flag_2'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/carryForward/yearHistoryMake' title='年次履歴作成処理' />
				}
                {	(menuPatternData['menu_carried_over_flag_3'] == 1 || userData['sys_role_id'] == 10000) &&
					<LinkButton link='/carryForward/yearHistoryReference' title='年次履歴参照処理' />
				}
            </div>
            </div>
        </>
    );
};

export default App;