/* eslint-disable eqeqeq */
import  { React, useState, useEffect } from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja'
import 'react-datepicker/dist/react-datepicker.css';
import "./InputCalender.css";
registerLocale('ja', ja);

const InputDatepicker2 = props => {
    const [date, setDate] = useState(null);
    const handleChange = (param) => {
        props.onChange(param);
        setDate(param);
    }
    return (
        <>
            <label className="datepicker_wrapper" style={{width: props.width}}>
                <DatePicker className="formDatePicker"
                    onChange = {selectedDate => {handleChange(selectedDate || '')}}
                    locale="ja"
                    selected={props.value==''?new Date():new Date(props.value)}
                    dateFormat="yyyy/MM"
                />
            </label>
        </>
    )
}

export default InputDatepicker2;
