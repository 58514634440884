/* eslint-disable eqeqeq */
import style from "./BrokenList.module.css";
import gstyle from "../../css/GeneralStyle.module.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as useJwt from "../../auth/jwt/useJwt.js";
import jwtDefaultConfig from "../../auth/jwt/jwtDefaultConfig";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import InputDatepicker from "../../components/common/input/InputDatepicker";
import ReactPaginate from "react-paginate";
import "./pagenation.css";
import { Button, Modal } from "antd";
import WorkerModal from '../../components/common/modal/WorkerModal';
import IconSearch from '../../components/common/icons/icon_search.png';

const BrokenList = () => {
  const authentication = useJwt.isUserLoggedIn();
  const userData = JSON.parse(useJwt.getUserData());
  const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);

  const csLoading = (flag) => {
    if (flag) {
      document.getElementById("csLoader").style.display = "flex";
    } else {
      document.getElementById("csLoader").style.display = "none";
    }
  };

  useEffect(() => {
    if (authentication !== true) navigate("/login");
    if (!loaded) {
      setLoaded(true);
      if (menuPatternData["menu_broken_flag"] == 0 && userData['sys_role_id'] != 10000) {
        navigate("/");
      }
    }
  });
  // ================================= New Window ====================================
  const OpenSearchWindow = (event) => {
    event.preventDefault();

    let data = {};
    data["userID"] = userData["id"];
    data["worker_id"] = WorkerId;
    data["report_date"] = reportDate;
    gridMainTable([]);
    let url =
      jwtDefaultConfig.backendUrl + jwtDefaultConfig.getBrokenReportEndPoint;
    csLoading(true);
    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
        },
      })
      .then((response) => {
        console.log(response.data);
        let result = response.data.data;
        if (result.length > 0) {
          gridMainTable(result);
        } else {
          toast.error("検索結果はありません。", { autoClose: 3000 });
          gridMainTable([]);
        }
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
      });
  };
  const [mainTableData, setMainTableData] = useState([]);

  const gridMainTable = (result) => {
    let data = result;
    setMainTableData(data);
    // for pagenation
    setItemOffset(0);
    const endOffset = 0 + itemsPerPage;
    setCurrentItems(data.slice(0, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  };
  // starts pagenation code -----------------------------------------
  const itemsPerPage = 50;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % mainTableData.length;
    // console.log( `User requested page number ${event.selected}, which is offset ${newOffset}` );
    setItemOffset(newOffset);
    const endOffset = newOffset + itemsPerPage;
    setCurrentItems(mainTableData.slice(newOffset, endOffset));
  };
  // ends pagenation code ---------------------------------------------
  // ========================== starts code for search ===============================

  const [reportUser, setReportUser] = useState("");
  const handleChangeReportUser = (event) => {
    setReportUser(event.target.value);
  };

  const [reportDate, setReportDate] = useState(useJwt.formatDate(new Date()));
  const handleChangeReportDate = (date) => {
    setReportDate(useJwt.formatDate(date));
  };
  const [name, setName] = useState("");
  const [itemName, setItemName] = useState("");
  const [tempReportDate, setTempReportDate] = useState("");
  const [reportContent, setReportContent] = useState("");
  const [reportImage, setReportImage] = useState("");

  const reportDetail = (e, index) => {
    setModalOpen(true);
    let currentData = currentItems[index];
    setName(currentData["user_family_nm_kanji"] + ' ' + currentData['user_first_nm_kanji'])
    setItemName(currentData['item_name'])
    setTempReportDate(currentData['report_date'])
    setReportContent(currentData['report_content'])
    setReportImage(currentData['item_image'])
    // navigate("/broken/report/detail", {
    //   state: {
    //     data: report_id,
    //   },
    //   replace: false,
    // });
  };

  const [modalOpen, setModalOpen] = useState(false);

      // ----------------------------- Worker modal -------------------------------------
    const [showModalWorker, setShowModalWorker] = useState(false);
    const [WorkerSearchValue, setWorkerSearchValue] = useState("");
    const [WorkerTableData, setWorkerTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWorkerModalData = (event, param) => {
        event.preventDefault();
        setTargetParam(param);
        let name = WorkerName;
        setWorkerSearchValue(name);

        if (name != "") {
            let data = {};
            data['userID'] = userData['id'];

            if (name != "") {
                data['name'] = name;
            }

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWorkerListEndPoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setWorkerTableData(response.data.data);
                    setShowModalWorker(true);
                })
                .catch((error) => {
                });
        } else {
            setWorkerTableData([]);
            setShowModalWorker(true);
        }
    }
    // worker --------------------------------------------------
    const [WorkerName, setWorkerName] = useState("");
    const [WorkerId, setWorkerId] = useState("");
    const handleChangeWorkerName = (event) => {
        setWorkerName(event.target.value);
    };

    const onKeyDownWorkerName = (event, param) => {
        if (event.keyCode === 13) { // enter key
            getWorkerData(event.target.value);
            event.preventDefault();
        }
    };
    const getWorkerData = (name) => {
        if (name != "") {
            const data = {
                userID: userData['id'],
                name: name
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstWorkerListEndPoint;
            axios
                .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
                .then((response) => {
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        if (response.data.data.length > 0) {
                            let result = response.data.data[0];
                            setWorkerData(result);
                        } else {
                            setWorkerData([]);
                        }
                    }
                })
                .catch((error) => {
                });
        } else {
            setWorkerData([]);
        }
    }
    const setWorkerData = (data) => {
        if (data['user_family_nm_kanji']) {
            setWorkerId(data['id'])
            setWorkerName(data['user_family_nm_kanji'] + ' ' + data['user_first_nm_kanji']);
        } else {
            setWorkerName("");
        }
    }

  // ========================== style =================================================
  // style array
  const style_row = [gstyle.cs_row];
  const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
  const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
  const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
  const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
  const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
  const style_input_wrap = [gstyle.p0015];
  const style_input_wrap3 = [style.grp_tbl_wrap];
  const style_input_100px = [gstyle.cs_input, gstyle.w100px];
  const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];
  const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];
  const style_input_bl_n = [gstyle.cs_input, style.job_time];

  return (
    <div className={gstyle.display}>
      <WorkerModal show={showModalWorker} onClose={() => setShowModalWorker(false)} width={'70%'}
          SearchValue={WorkerSearchValue} onChange={setWorkerSearchValue}
          tableData={WorkerTableData} setTableData={setWorkerTableData}
          target={setWorkerData} targetParam={TargetParam} />
      <div className={gstyle.loader_container} id="csLoader">
        <ClipLoader color={"#fff"} size={60} />
      </div>
      <ToastContainer />
      <input type={"hidden"} id="selectedId" />
      <div className={gstyle.border}>
        <p className={gstyle.text}>破損報告</p>
      </div>
      <div className={gstyle.filed}>
        <div className={gstyle.cs_card_1}>
          {/* 年月日, 荷主, 倉庫 */}
          <div className={style_row.join(" ")}>
            <div className={style_col_2.join(" ")}></div>
            <div className={style_col_2.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>年月日</span>
                  <div className={style_date_wrap.join(" ")}>
                    <InputDatepicker
                      value={reportDate}
                      onChange={handleChangeReportDate}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")} style={{marginTop:12}}>
                <div className={style_input_wrap.join(" ")}>
                    <label className={style_label_wrap.join(" ")}>作業者
                        <div className={gstyle.cs_flex} style={{marginTop:3}}>
                            <input type={'text'} className={style_input_bl_n.join(" ")} style={{margin:0}}
                                onKeyDown={(e) => onKeyDownWorkerName(e, 1, 1)}
                                value={WorkerName} onChange={handleChangeWorkerName} />
                            <div className={gstyle.icon_search_wrap} onClick={(e) => loadWorkerModalData(e, 1)}>
                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                            </div>
                        </div>
                    </label>
                </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_btn_new_wrap.join(" ")}>
                <button className={gstyle.btn_new} onClick={OpenSearchWindow}>
                  検索
                </button>
              </div>
            </div>
          </div>

          <div className={style.nw_container}>
            {/* Table */}
            <div className={style_row.join(" ")}>
              <div className={style_col_12.join(" ")}>
                <div className={style_input_wrap3.join(" ")}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "15%" }}>報告者名</th>
                        <th style={{ width: "15%" }}>日付</th>
                        <th style={{ width: "20%" }}>品名</th>
                        <th style={{ width: "30%" }}>報告内容</th>
                        <th style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody id="tbody_wrap">
                      {currentItems.map((data, index) => (
                        <tr key={index}>
                          <td>{data["user_family_nm_kanji"] + ' ' + data['user_first_nm_kanji']}</td>
                          <td>{data["created_at"]}</td>
                          <td style={{textAlign:'left', lineBreak:'anywhere'}}>{data["item_name"]}</td>
                          <td style={{textAlign:'left', lineBreak:'anywhere'}}>
                            {(data["report_content"] || "").substring(0, 30)}
                          </td>
                          <td>
                            {" "}
                            <Button
                              type="primary"
                              onClick={(e) => reportDetail(e, index)}
                            >
                              詳細
                            </Button>{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {mainTableData.length > 0 && (
              <div className={style_row.join(" ")}>
                <div className={style_col_12.join(" ")}>
                  <div className={style.nw_title_wrap}>
                    <ReactPaginate
                      previousLabel={"前へ"}
                      nextLabel={"次へ"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        title="破損報告詳細"
        style={{}}
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        width={900}
        footer={null}
      >
        <div className={gstyle.filed}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={reportImage} alt="Red dot" style={{height:200}}/>
            </div>
            <div className={style_row.join(" ")} style={{ marginTop: 30 }}>
              <div
                className={style_col_6.join(" ")}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                <div className={style_row.join(" ")} style={{ width: "80%" }}>
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <span style={{ marginRight: 20, fontWeight: 600 }}>
                      報告者名:
                    </span>
                  </div>
                  <div style={{ width: "70%" }}>{name}</div>
                </div>
                <div
                  className={style_row.join(" ")}
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <span style={{ marginRight: 20, fontWeight: 600 }}>
                      日付:
                    </span>
                  </div>
                  <div style={{ width: "70%" }}>{tempReportDate}</div>
                </div>
                <div
                  className={style_row.join(" ")}
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <span style={{ marginRight: 20, fontWeight: 600 }}>
                      品名:
                    </span>
                  </div>
                  <div style={{ width: "70%" }}>{itemName}</div>
                </div>
              </div>
              <div className={style_col_6.join(" ")}>
                <p style={{ fontWeight: 600 }}>報告内容</p>
                <div>{reportContent}</div>
              </div>
            </div>
            <div
              className={style_row.join(" ")}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
              }}
            >
            </div>
          </div>
      </Modal>
    </div>
  );
};

export default BrokenList;
