/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './StockListByWarehouse.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import CategoryModal from '../../../components/common/modal/CategoryModal';
import ItemTypeModal from '../../../components/common/modal/ItemTypeModal';
import StartTableModal from '../../../components/common/modal/TableModal';
import EndTableModal from '../../../components/common/modal/TableModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import {Select} from 'antd';


const StockListByWarehouse = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    // publish date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    
    // start and end date ---------------------------------------
    const [SlipDate, setSlipDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeSlipDate = (date) => {
        setSlipDate(useJwt.formatDate(date));
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
    };
    const OrderTypeData = [ { value:'0', label:'品種順' }, { value:'1', label:'分類順' } ];
    // category --------------------------------------------------------
    const [CategoryCode1, setCategoryCode1] = useState("");
    const [CategoryName1, setCategoryName1] = useState("");
    const handleChangeCategoryCode1 = (event) => {
        setCategoryCode1(useJwt.validateNum(event.target.value,3));
    };
    const [CategoryCode2, setCategoryCode2] = useState("");
    const [CategoryName2, setCategoryName2] = useState("");
    const handleChangeCategoryCode2 = (event) => {
        setCategoryCode2(useJwt.validateNum(event.target.value,3));
    };
    const onKeyDownCategoryCode =(event,param) => {
        if(event.keyCode === 13) { // enter key
            getCategoryData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('category_code2').focus();
            }else{
                document.getElementById('type2').focus();
            }
        }
    };
    const getCategoryData = (code,param) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let t_data = response.data;
                if(t_data.length > 0) {
                    setCategoryData(t_data[0],param);
                }else{
                    setCategoryData([],param);
                }
            })
            .catch((error) => {
            });
        }else{
            setCategoryData([],param);
        }
    }
    const setCategoryData = (data,param) => {
        if(data['category_code']) {
            if(param == 1) {
                setCategoryCode1(data['category_code']);
                setCategoryName1(data['category_name']);
            }else{
                setCategoryCode2(data['category_code']);
                setCategoryName2(data['category_name']);
            }
        }else{
            if(param == 1) {
                setCategoryCode1("");
                setCategoryName1("");
            }else{
                setCategoryCode2("");
                setCategoryName2("");
            }
        }
    }
    // item type ---------------------------------------------------
    const [ItemTypeCode1, setItemTypeCode1] = useState("");
    const handleChangeItemTypeCode1 = (event) => {
        setItemTypeCode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemTypeName1, setItemTypeName1] = useState("");
    const [ItemTypeCode2, setItemTypeCode2] = useState("");
    const handleChangeItemTypeCode2 = (event) => {
        setItemTypeCode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemTypeName2, setItemTypeName2] = useState("");
    const onKeyDownItemTypeCode =(event,param) => {
        if(event.keyCode === 13) { // enter key
            getItemTypeData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('item_type_code2').focus();
            }else{
                document.getElementById('type2').focus();
            }
        }
    };
    const getItemTypeData = (code,param) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                item_type_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let t_data = response.data.data;
                if(t_data.length > 0) {
                    setItemTypeData(t_data[0],param);
                }else{
                    setItemTypeData([],param);
                }
            })
            .catch((error) => {
            });
        }else{
            setItemTypeData([],param);
        }
    }
    const setItemTypeData = (data,param) => {
        if(data['item_type_code']) {
            if(param == 1) {
                setItemTypeCode1(data['item_type_code']);
                setItemTypeName1(data['item_type_name']);
            }else{
                setItemTypeCode2(data['item_type_code']);
                setItemTypeName2(data['item_type_name']);
            }
        }else{
            if(param == 1) {
                setItemTypeCode1("");
                setItemTypeName1("");
            }else{
                setItemTypeCode2("");
                setItemTypeName2("");
            }
        }
    }
    // warehouse type -----------------------------------------------
    const [WarehouseType, setWarehouseType] = useState(0);
    const handleChangeWarehouseType = (event) => {
        setWarehouseType(event);
    };
    const WarehouseTypeData = [ { value:'0', label:'倉庫別' }, { value:'1', label:'倉庫別棚別' } ];
    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('warehouse_code2').focus();
            }else{
                setTimeout(()=>{
                    if(document.getElementById('location_code1')){
                        document.getElementById('location_code1').focus();
                    }else{
                        document.getElementById('shipper_code1').focus();
                    }
                })
            }

        }
    };
    const getWarehouseData = (code,param) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result,param);
                    }else{
                        setWarehouseData([],param);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setWarehouseData([],param);
        }
    }
    const setWarehouseData = (data,param) => {
        if(data['warehouse_code']) {
            if(param == 1) {
                setWarehouseCode1(data['warehouse_code']);
                setWarehouseName1(data['warehouse_name']);
            }else{
                setWarehouseCode2(data['warehouse_code']);
                setWarehouseName2(data['warehouse_name']);
            }
        }else{
            if(param == 1) {
                setWarehouseCode1("");
                setWarehouseName1("");
            }else{
                setWarehouseCode2("");
                setWarehouseName2("");
            }
        }
    }
    // location ------------------------------------------------
    const [LocationNo1, setLocationNo1] = useState("");
    const handleChangeLocationNo1 = (event) => {
        setLocationNo1(event.target.value);
    };
    const [LocationData1, setLocationData1] = useState([]);
    const [LocationNo2, setLocationNo2] = useState("");
    const handleChangeLocationNo2 = (event) => {
        setLocationNo2(event.target.value);
    };
    const [LocationData2, setLocationData2] = useState([]);

    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('type3').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ShipperACode1;
            }else{
                code = ShipperCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ShipperACode2;
            }else{
                code = ShipperCode2;
                acode = value;
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // weight unit type -----------------------------------------------
    const [WeightUnitType, setWeightUnitType] = useState(0);
    const handleChangeWeightUnitType = (event) => {
        setWeightUnitType(event);
    };
    const WeightUnitTypeData = [ { value:'0', label:'1kg 単位' }, { value:'1', label:'10kg 単位' } 
    , { value:'2', label:'100kg 単位' }, { value:'3', label:'1000kg 単位' }];
    // weight round type -----------------------------------------------
    const [WeightRoundType, setWeightRoundType] = useState(0);
    const handleChangeWeightRoundType = (event) => {
        setWeightRoundType(event);
    };
    // volume unit type -----------------------------------------------
    const [VolumeUnitType, setVolumeUnitType] = useState(0);
    const handleChangeVolumeUnitType = (event) => {
        setVolumeUnitType(event);
    };
    const VolumeUnitTypeData = [ { value:'0', label:'1 単位' }, { value:'1', label:'10 単位' } 
    , { value:'2', label:'100 単位' }, { value:'3', label:'1000 単位' }];
    // volume round type -----------------------------------------------
    const [VolumeRoundType, setVolumeRoundType] = useState(0);
    const handleChangeVolumeRoundType = (event) => {
        setVolumeRoundType(event);
    };
    // Price unit type -----------------------------------------------
    const [PriceUnitType, setPriceUnitType] = useState(0);
    const handleChangePriceUnitType = (event) => {
        setPriceUnitType(event);
    };
    const PriceUnitTypeData = [ { value:'0', label:'1円 単位' }, { value:'1', label:'10円 単位' } 
    , { value:'2', label:'100円 単位' }, { value:'3', label:'1000円 単位' }];
    // Price round type -----------------------------------------------
    const [PriceRoundType, setPriceRoundType] = useState(0);
    const handleChangePriceRoundType = (event) => {
        setPriceRoundType(event);
    };
    // filter type -----------------------------------------------
    const [FilterType, setFilterType] = useState(0);
    const handleChangeFilterType = (event) => {
        setFilterType(event);
    };

    const RoundVal = (type,val) => {
        if(type == 1){
            if(WeightUnitType == 1 ) {
                val = val/10;
            }else if(WeightUnitType == 2 ) {
                val = val/100;
            }else if(WeightUnitType == 3 ){
                val = val/1000;
            }
            if(WeightUnitType > 0){
                if(WeightRoundType == 0 ) {
                    val = Math.round(val);
                }else if(WeightRoundType == 1 ) {
                    val = Math.ceil(val);
                }else{
                    val = Math.floor(val);
                }
            }
        }else if(type == 2) {
            if(VolumeUnitType == 1 ) {
                val = val/10;
            }else if(VolumeUnitType == 2 ) {
                val = val/100;
            }else if(VolumeUnitType == 3 ){
                val = val/1000;
            }
            if(VolumeUnitType > 0){
                if(VolumeRoundType == 0 ) {
                    val = Math.round(val);
                }else if(VolumeRoundType == 1 ) {
                    val = Math.ceil(val);
                }else{
                    val = Math.floor(val);
                }
            }
        }else{
            if(PriceUnitType == 1 ) {
                val = val/10;
            }else if(PriceUnitType == 2 ) {
                val = val/100;
            }else if(PriceUnitType == 3 ){
                val = val/1000;
            }
            if(PriceUnitType > 0){
                if(PriceRoundType == 0 ) {
                    val = Math.round(val);
                }else if(PriceRoundType == 1 ) {
                    val = Math.ceil(val);
                }else{
                    val = Math.floor(val);
                }
            }
        }
        
        
        return val;
    }
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_stock_flag_7'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        
        // data['start_slip_date'] = SlipDate;
        data['end_slip_date'] = SlipDate;
        data['sort_type'] = OrderType;

        data['start_warehouse_code'] = WarehouseCode1;
        data['end_warehouse_code'] = WarehouseCode2;
        if(WarehouseType == 1){
            if(LocationNo1 != "") {
                data['start_table_code'] = LocationNo1;
            }
            if(LocationNo2 != "") {
                data['end_table_code'] = LocationNo2;
            }
        }
        data['warehouse_type'] = WarehouseType;

        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        
        data['start_category_code'] = CategoryCode1;
        data['end_category_code'] = CategoryCode2;
        data['start_item_type_code'] = ItemTypeCode1;
        data['end_item_type_code'] = ItemTypeCode2;

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockListByWarehouseEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            // console.log(result)
            if(result.length>0){
                gridMainTable(result);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                gridMainTable([]);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
        
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        console.log("result",result);
        let data = [];
        let itemCodes = [];
        let header_info = [];
        for(var i=0; i<result.length; i++) {
            let filter_code = ''+result[i]['warehouse_code'];
            if(WarehouseType == 1){
                filter_code += '/'+result[i]['table_code'];
            }

            if(!itemCodes.includes(filter_code)){
                itemCodes.push(filter_code);
                let row = {};
                row['warehouse_code'] = result[i]['warehouse_code'];
                row['warehouse_name'] = result[i]['warehouse_name'];
                row['table_code'] = result[i]['table_code'];
                row['table_row'] = result[i]['table_row'];
                row['table_column'] = result[i]['table_column'];
                row['table_floor'] = result[i]['table_floor'];
                header_info.push(row);
            }
        }
        for(var i=0; i<itemCodes.length; i++) {
            let row = {};
            row['filter_code'] = itemCodes[i];
            row['header_info'] = header_info[i];
            row['item_info'] = [];
            row['stockNum'] = 0;
            row['stockVolume'] = 0;
            row['stockWeight'] = 0;
            row['stockPrice'] = 0;
            row['total_label'] = "【倉 庫 計】";
            if(WarehouseType == 1){
                row['total_label'] = "【棚計】";
            }
            for(var j=0;j<result.length;j++) {
                let filter_code = ''+result[j]['warehouse_code'];
                if(WarehouseType == 1){
                    filter_code += '/'+result[j]['table_code'];
                }

                if(itemCodes[i] == filter_code) {
                    let tmp_row = result[j];
                    row['item_info'].push(tmp_row);
                    row['stockNum'] += (1*result[j]['stock_ary']['stockNum']);
                    row['stockVolume'] += (1*result[j]['stock_ary']['stockVolume']);
                    row['stockWeight'] += (1*result[j]['stock_ary']['stockWeight']);
                    row['stockPrice'] += (1*result[j]['stock_ary']['stockPrice']);
                }
            }
            data.push(row);
        }
        // console.log("data",data);

        setMainTableData(data);
        if(data.length>0){
            setOpend(true);
        }
    }
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------- Category Modal ------------------------------------------
    const [showModalCategory, setShowModalCategory] = useState(false);
    const [CategorySearchValue, setCategorySearchValue] = useState("");
    const [CategoryTableData, setCategoryTableData] = useState([]);
    const loadCategoryCodeModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = CategoryCode1;
        if(param==2){
            code = CategoryCode2;
        }
        setCategorySearchValue(code);
        if(code != ""){
            const data = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setCategoryTableData(response.data);
                setShowModalCategory(true);
            })
            .catch((error) => {
            });
        }else{
            setCategoryTableData([]);
            setShowModalCategory(true);
        }
    }
    // ----------------------------- item type modal -----------------------------------
    const [showModalItemType, setShowModalItemType] = useState(false);
    const [ItemTypeSearchValue, setItemTypeSearchValue] = useState("");
    const [ItemTypeTableData, setItemTypeTableData] = useState([]);
    const loadItemTypeModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemTypeCode1;
        if(param==2){
            code = ItemTypeCode2;
        }
        setItemTypeSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['item_type_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTypeTableData(response.data.data);
                setShowModalItemType(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTypeTableData([]);
            setShowModalItemType(true);
        }
    }
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    
    const loadWarehouseModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode1;
        if(param==2){
            code = WarehouseCode2;
        }
        setWarehouseSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['warehouse_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

    // -----------------------------start Table modal -------------------------------------
    const [showModalStartTable, setShowModalStartTable] = useState(false);
    const [startTableSearchValue, setStartTableSearchValue] = useState("");
    const [startTableSearchValue2, setStartTableSearchValue2] = useState("");
    const [startTableData, setStartTableData] = useState([]);
    const loadStartTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = WarehouseCode1;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setStartTableSearchValue(table_code);
        setStartTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setStartTableData(response.data.data);
                setShowModalStartTable(true);
            })
            .catch((error) => {
            });
        }else{
            setStartTableData([]);
            setShowModalStartTable(true);
        }
    }
    const setStartTableCode = (data) => {

        setLocationData1(data);
        setLocationNo1(data['table_code']);
    }
    
    // -----------------------------end Table modal -------------------------------------
    const [showModalEndTable, setShowModalEndTable] = useState(false);
    const [endTableSearchValue, setEndTableSearchValue] = useState("");
    const [endTableSearchValue2, setEndTableSearchValue2] = useState("");
    const [endTableData, setEndTableData] = useState([]);
    const loadEndTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = WarehouseCode1;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setEndTableSearchValue(table_code);
        setEndTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setEndTableData(response.data.data);
                setShowModalEndTable(true);
            })
            .catch((error) => {
            });
        }else{
            setEndTableData([]);
            setShowModalEndTable(true);
        }
    }
    const setEndTableCode = (data) => {
        setLocationData2(data);
        setLocationNo2(data['table_code']);
    }

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);
    const [type5Open, setType5Open] = useState(false);
    const [type6Open, setType6Open] = useState(false);
    const [type7Open, setType7Open] = useState(false);
    const [type8Open, setType8Open] = useState(false);
    const [type9Open, setType9Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('category_code1')){
                    document.getElementById('category_code1').focus();
                }else{
                    document.getElementById('item_type_code1').focus();
                }
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('warehouse_code1').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type4').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onType4KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type5').focus();
            event.preventDefault();
        }
    }
    const onFocusType4 = (e, type) => {
        if(type == 1){
            setType4Open(true);
        }else{
            setType4Open(false);
        }
    }

    const onType5KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type6').focus();
            event.preventDefault();
        }
    }
    const onFocusType5 = (e, type) => {
        if(type == 1){
            setType5Open(true);
        }else{
            setType5Open(false);
        }
    }

    const onType6KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type7').focus();
            event.preventDefault();
        }
    }
    const onFocusType6 = (e, type) => {
        if(type == 1){
            setType6Open(true);
        }else{
            setType6Open(false);
        }
    }

    const onType7KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type8').focus();
            event.preventDefault();
        }
    }
    const onFocusType7 = (e, type) => {
        if(type == 1){
            setType7Open(true);
        }else{
            setType7Open(false);
        }
    }

    const onType8KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type9').focus();
            event.preventDefault();
        }
    }
    const onFocusType8 = (e, type) => {
        if(type == 1){
            setType8Open(true);
        }else{
            setType8Open(false);
        }
    }

    const onType9KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType9 = (e, type) => {
        if(type == 1){
            setType9Open(true);
        }else{
            setType9Open(false);
        }
    }

    const onLocationKeyDown = (e, param) => {
        if(e.keyCode == 13){
            if(param == 1){
                document.getElementById('location_code2').focus();
            }else{
                document.getElementById('shipper_code1').focus();
            }
        }
    }


    const onSelectData = (e, index)=>{
        document.getElementById('focus').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            case 4:
                setType4Open(false);
                break;
            case 5:
                setType5Open(false);
                break;
            case 6:
                setType6Open(false);
                break;
            case 7:
                setType7Open(false);
                break;
            case 8:
                setType8Open(false);
                break;
            case 9:
                setType9Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_label_wrap3 = [gstyle.cs_label, gstyle.cs_flex_2, style.float_right];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <CategoryModal show={showModalCategory} onClose={()=>setShowModalCategory(false)} width={'70%'}
                SearchValue={CategorySearchValue} onChange={setCategorySearchValue} 
                tableData={CategoryTableData} setTableData={setCategoryTableData}
                target={setCategoryData} targetParam={TargetParam} />
            <ItemTypeModal show={showModalItemType} onClose={()=>setShowModalItemType(false)} width={'70%'}
                SearchValue={ItemTypeSearchValue} onChange={setItemTypeSearchValue} 
                tableData={ItemTypeTableData} setTableData={setItemTypeTableData}
                target={setItemTypeData} targetParam={TargetParam} />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <StartTableModal show={showModalStartTable} onClose={()=>setShowModalStartTable(false)} width={'70%'}
                SearchValue={startTableSearchValue} SearchValue2={startTableSearchValue2}  onChange={setStartTableSearchValue} 
                tableData={startTableData} setTableData={setStartTableData}
                target={setStartTableCode} />
            <EndTableModal show={showModalEndTable} onClose={()=>setShowModalEndTable(false)} width={'70%'}
                SearchValue={endTableSearchValue} SearchValue2={endTableSearchValue2}  onChange={setEndTableSearchValue} 
                tableData={endTableData} setTableData={setEndTableData}
                target={setEndTableCode} />

            <div className={gstyle.border}>
                <p className={gstyle.text}>倉庫別在庫評価一覧表</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='date2'/>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={SlipDate} onChange={handleChangeSlipDate} id='date2' focusId='type1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 出力順位 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={OrderTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 品種 */}
                    {OrderType ==0 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_4.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始品種</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemTypeCode(e,1)}  id='item_type_code1'
                                            value={ItemTypeCode1} onChange={handleChangeItemTypeCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemTypeName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemTypeModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_4.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了品種</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemTypeCode(e,2)} id='item_type_code2'
                                            value={ItemTypeCode2} onChange={handleChangeItemTypeCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemTypeName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemTypeModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 分類 */}
                    {OrderType ==1 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始分類</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownCategoryCode(e,1)} id='category_code1'
                                                value={CategoryCode1} onChange={handleChangeCategoryCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={CategoryName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCategoryCodeModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了分類</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownCategoryCode(e,2)} id='category_code2'
                                                value={CategoryCode2} onChange={handleChangeCategoryCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={CategoryName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCategoryCodeModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 倉庫区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>倉庫区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="倉庫区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeWarehouseType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={WarehouseTypeData}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,1)} id='warehouse_code1'
                                            value={WarehouseCode1} onChange={handleChangeWarehouseCode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={WarehouseName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,2)} id='warehouse_code2'
                                            value={WarehouseCode2} onChange={handleChangeWarehouseCode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={WarehouseName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <input type='text' style={{width:0, outline:'white', border:'none'}} id="focus"/>
                    {/* 棚 */}
                    {WarehouseCode1 != "" && WarehouseCode1 == WarehouseCode2 && <> 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始棚コード</span>
                                        <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                            <input type={'num'} className={style.cs_tbl_input} 
                                                value={LocationNo1} id='location_code1' onKeyDown={(e)=>onLocationKeyDown(e, 1)}
                                                onChange={(e) => handleChangeLocationNo1(e)} />
                                                <div className={gstyle.icon_search_wrap} 
                                                    onClick={(e)=>loadStartTableModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了棚コード</span>
                                        <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                            <input type={'num'} className={style.cs_tbl_input} 
                                                value={LocationNo2} id='location_code2' onKeyDown={(e)=>onLocationKeyDown(e, 2)}
                                                onChange={(e) => handleChangeLocationNo2(e)} />
                                                <div className={gstyle.icon_search_wrap} 
                                                    onClick={(e)=>loadEndTableModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>}
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  重量単位, 端数区分, */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>重量単位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="重量単位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeWeightUnitType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={WeightUnitTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>端数区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type4Open}
                                            onSelect={(e)=>onSelectData(e, 4)}
                                            placeholder="端数区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeWeightRoundType}
                                            onKeyDown={onType4KeyDown}
                                            onFocus={(e) => onFocusType4(e, 1)}
                                            onBlur={(e) => onFocusType4(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type4"
                                            options={[
                                                {value:'0', label:'四捨五入'},
                                                {value:'1', label:'切り上げ'},
                                                {value:'2', label:'切り捨て'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  容積単位, 端数区分, */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>容積単位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type5Open}
                                            onSelect={(e)=>onSelectData(e, 5)}
                                            placeholder="容積単位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeVolumeUnitType}
                                            onKeyDown={onType5KeyDown}
                                            onFocus={(e) => onFocusType5(e, 1)}
                                            onBlur={(e) => onFocusType5(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type5"
                                            options={VolumeUnitTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>端数区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type6Open}
                                            onSelect={(e)=>onSelectData(e, 6)}
                                            placeholder="端数区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeVolumeRoundType}
                                            onKeyDown={onType6KeyDown}
                                            onFocus={(e) => onFocusType6(e, 1)}
                                            onBlur={(e) => onFocusType6(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type6"
                                            options={[
                                                {value:'0', label:'四捨五入'},
                                                {value:'1', label:'切り上げ'},
                                                {value:'2', label:'切り捨て'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  金額単位, 端数区分, */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>金額単位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type7Open}
                                            onSelect={(e)=>onSelectData(e, 7)}
                                            placeholder="金額単位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangePriceUnitType}
                                            onKeyDown={onType7KeyDown}
                                            onFocus={(e) => onFocusType7(e, 1)}
                                            onBlur={(e) => onFocusType7(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type7"
                                            options={PriceUnitTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>端数区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type8Open}
                                            onSelect={(e)=>onSelectData(e, 8)}
                                            placeholder="端数区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangePriceRoundType}
                                            onKeyDown={onType8KeyDown}
                                            onFocus={(e) => onFocusType8(e, 1)}
                                            onBlur={(e) => onFocusType8(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type8"
                                            options={[
                                                {value:'0', label:'四捨五入'},
                                                {value:'1', label:'切り上げ'},
                                                {value:'2', label:'切り捨て'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 打出区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>打出区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type9Open}
                                            onSelect={(e)=>onSelectData(e, 9)}
                                            placeholder="打出区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeFilterType}
                                            onKeyDown={onType9KeyDown}
                                            onFocus={(e) => onFocusType9(e, 1)}
                                            onBlur={(e) => onFocusType9(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type9"
                                            options={[
                                                {value:'0', label:'発生又は在庫有り'},
                                                {value:'1', label:'発生有りのみ'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>倉庫別在庫評価一覧表</h2>
                                </div>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {/* 出力順位 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_8.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>{useJwt.formatDate2(new Date(SlipDate))}  現在</span>
                                        </div>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>
                                                [出力順位]:{OrderTypeData[OrderType]['value']}   
                                            </span>
                                            <span className={style.cs_span_3}>
                                                [倉庫区分]:{WarehouseTypeData[WarehouseType]['value']}    
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_4.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap3.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>重量 : </span>
                                            <span className={style.cs_span_3}>{WeightUnitTypeData[WeightUnitType]['value']}</span>
                                        </div>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>容積 : </span>
                                            <span className={style.cs_span_3}>{VolumeUnitTypeData[VolumeUnitType]['value']}</span>
                                        </div>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>金額 : </span>
                                            <span className={style.cs_span_3}>￥{useJwt.numberWithCommas(PriceUnitTypeData[PriceUnitType]['value'])}</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        {  mainTableData.map((data,index) => (
                            <div key={index}>
                                {/* header */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_8.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {'倉庫ｺｰﾄﾞ : '+data['header_info']['warehouse_code']}   
                                                    </span>
                                                    <span className={style.cs_span_3}>
                                                        {'倉庫名 : '+data['header_info']['warehouse_name']}    
                                                    </span>
                                                </div>
                                                {WarehouseType == 1 &&
                                                    <div className={gstyle.cs_flex}>
                                                        <span className={style.cs_span_3}>
                                                            {'棚番号 : '+(data['header_info']['table_row']?data['header_info']['table_row']:'') }
                                                        </span>
                                                        <span className={style.cs_span_3}>
                                                            {'番地 : '+(data['header_info']['table_column']?data['header_info']['table_column']:'')}
                                                        </span>
                                                        <span className={style.cs_span_3}>
                                                            {'段 : '+(data['header_info']['table_floor']?data['header_info']['table_floor']:'')}   
                                                        </span>
                                                    </div>
                                                }
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* table */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap3.join(" ")}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        {OrderType == 0 &&
                                                            <th>品種ｺｰﾄﾞ</th>
                                                        }
                                                        {OrderType == 0 &&
                                                            <th>品種名</th>
                                                        }
                                                        {OrderType == 1 &&
                                                            <th>分類ｺｰﾄﾞ</th>
                                                        }
                                                        {OrderType == 1 &&
                                                            <th>分類名</th>
                                                        }
                                                        <th>在庫重量</th>
                                                        <th>在庫容積</th>
                                                        <th>在庫金額</th>                                      
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {  data['item_info'].map((data1,index1) => (
                                                        <React.Fragment key={index1}>
                                                            <tr>
                                                                {OrderType == 0 &&
                                                                    <td>{data1['item_type_code']}</td>
                                                                }
                                                                {OrderType == 0 &&
                                                                    <td className={style.td_left}>{data1['item_type_name']}</td>
                                                                }
                                                                {OrderType == 1 &&
                                                                    <td>{data1['category_code']}</td>
                                                                }
                                                                {OrderType == 1 &&
                                                                    <td className={style.td_left}>{data1['category_name']}</td>
                                                                }
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(RoundVal(1,data1['stock_ary']['stockWeight']))}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(RoundVal(2,data1['stock_ary']['stockVolume']))}</td>
                                                                <td className={style.td_right}>￥{useJwt.numberWithCommas(RoundVal(3,data1['stock_ary']['stockPrice']))}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )) }
                                                    <tr className={gstyle.bg_tbl_total}>
                                                        <td colSpan={2}>{data['total_label']}</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(RoundVal(1,data['stockWeight']))}</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(RoundVal(2,data['stockVolume']))}</td>
                                                        <td className={style.td_right}>￥{useJwt.numberWithCommas(RoundVal(3,data['stockPrice']))}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'倉庫別在庫評価一覧表_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default StockListByWarehouse;