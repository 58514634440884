/* eslint-disable eqeqeq */
import style from './Bus.module.css';
import gstyle from '../../css/GeneralStyle.module.css';
import React, {useCallback, useMemo, useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import IconSearch from '../../components/common/icons/icon_search.png';
import WarehouseModal from '../../components/common/modal/WarehouseModal';
import ScheduleModal from '../../components/common/modal/ScheduleModal';
import BusModal from '../../components/common/modal/BusModal';
import CarrierModal from '../../components/common/modal/CarrierModal';
import DriverModal from '../../components/common/modal/DriverModal';
import InputDatepicker from '../../components/common/input/InputDatepicker';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';

// import DialogActions from '@material-ui/core/DialogActions';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
import { Button } from 'antd';

const BusScheduling = () => {
    
    let urlParams = useLocation();
    const navigate = useNavigate();

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());

    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [itemName, setItemName] = useState("");
    const [busScheduleId, setBusScheduleId] = useState("");
    const [commandId, setCommandId] = useState("");

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['bus_flag_3'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            setLoaded(true);

            if(urlParams.state){
                if(urlParams.state.data.flag == 1 || urlParams.state.data.flag == 3)  setBusScheduleId(urlParams.state.data.id);
                setCarrierName(urlParams.state.data.carrier_name);
                setCarrierCode(urlParams.state.data.carrier_code);
                setCarrierACode(urlParams.state.data.carrier_a_code);
                setWarehouseCode(urlParams.state.data.warehouse_code);
                if(urlParams.state.data.flag == 1 || urlParams.state.data.flag == 3)  setWarehouseName(urlParams.state.data.warehouse_name);
                if(urlParams.state.data.flag == 1 || urlParams.state.data.flag == 3)  setWarehouseId(urlParams.state.data.warehouse_id);
                setCarNumber(urlParams.state.data.car_number);
                setCarStandard(urlParams.state.data.car_standard);
                setPhoneNumber(urlParams.state.data.phone_number);
                setItemName(urlParams.state.data.item_name);
                setCarrierId(urlParams.state.data.transport_id);
                setDriverId(urlParams.state.data.driver_id);
                if(urlParams.state.data.flag == 2)  setCommandId(urlParams.state.data.command_id);
                if(urlParams.state.data.flag == 3)  {
                    setBusId(urlParams.state.data.bus_id);
                    setBusName(urlParams.state.data.bus_name);

                    let standardList = JSON.parse(urlParams.state.data.standard);
                    let tempStandard = "";
                    for(var j = 0; j < standardList.length; j++){
                        if(j != 0)  tempStandard += '、'
                        if(standardList[j] == "1")  tempStandard += '4t';
                        if(standardList[j] == "2")  tempStandard += '10t';
                        if(standardList[j] == "3")  tempStandard += 'トレーラー';
                    }
                    setBusStandard(tempStandard);
                    setShipperName(urlParams.state.data.shipper_name);
                    if(urlParams.state.data.user_family_nm_kanji){
                        setWorkerName(urlParams.state.data.user_family_nm_kanji + ' ' + urlParams.state.data.user_first_nm_kanji);
                    }
                    setScheduleId(urlParams.state.data.schedule_id);
                    setScheduleDate(urlParams.state.data.schedule_date);

                    var start_date_time = urlParams.state.data.start_date_time;
                    var startList = start_date_time.split(' ');
                    if(startList.length > 1){
                        setStartDate(startList[0]);
                        setStartTime(startList[1]);
                    }

                    var end_date_time = urlParams.state.data.end_date_time;
                    var endList = end_date_time.split(' ');
                    if(endList.length > 1){
                        setEndDate(endList[0]);
                        setEndTime(endList[1]);
                    }

                    var workType = urlParams.state.data.work_type;
                    if(workType == 0){
                        setIn("0");
                        setOut("");
                    }else{
                        setOut("1");
                        setIn("");
                    }
                }
            }
        }
	});

    // warehouse 
    const [warehouseId, setWarehouseId] = useState(0);
    const [warehouseCode, setWarehouseCode] = useState("");
    const handleChangeWarehouseCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>2) {
            str = str.substring(0, 2);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setWarehouseCode(str);
    };
    const onKeyDownWarehouseCode = (event) => {
        if(event.keyCode === 13) { // enter key
            document.getElementById('input3').focus();
            if(event.target.value != "") {
                const data_r = {
                    userID: userData['id'],
                    warehouse_code: event.target.value,
                    warehouse_company_id: userData['id']
                };
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
                axios
                .post(url, data_r, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if(t_data.length > 0) {
                        setWarehouseData(t_data[0]);
                    }else{
                        setWarehouseData([]);
                    }
                })
                .catch((error) => {
                });
            }else{
                setWarehouseData([]);
            }
            event.preventDefault();
        }
    };
    
    const [warehouseName, setWarehouseName] = useState("");
    const handleChangeWarehouseName = (event) => {
        let str = event.target.value;
        if(str.length>10) {
            str = str.substring(0, 10);
        }
        setWarehouseName(str);
    };

    const setWarehouseData = (result) => {
        if(result['sys_warehouse_id']) {
            setWarehouseId(result['sys_warehouse_id']);
            setWarehouseCode(result['warehouse_code']);
            setWarehouseName(result['warehouse_name']);
        }else{
            setWarehouseId(0);
            setWarehouseCode("");
            setWarehouseName("");
        }
    }
    // ----------------------- Warehouse Modal -----------------------------------------
    const [SearchValue, setSearchValue] = useState("");
    const [SearchValueWarehouse, setSearchValueWarehouse] = useState("");
    const [tableData, setTableData] = useState([]);
    const loadModalData = (event) => {
        event.preventDefault();
        let code = warehouseCode;
        setSearchValue(code);

        if(code != ""){
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData(response.data.data);
                setShowModal(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData([]);
            setShowModal(true);
        }
    }

    // in and out set
    const [In, setIn] = useState("0");
    const handleChangeIn = (event, param) => {
        setIn(param);
        setOut("");
    }

    const [Out, setOut] = useState("");
    const handleChangeOut = (event, param) => {
        setIn("");
        setOut(param);
    }

    const [scheduleDate, setScheduleDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeScheduleDate = (date) => {
        setScheduleDate(useJwt.formatDate(date));
    };
    // start date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        var current_date = new Date();
        var diff_day = moment(current_date, "YYYY-MM-DD").diff(moment(date, "YYYY-MM-DD 00:00:00"), 'days');
        if(diff_day > 0){
            toast.error("過去の日付は入力できません。", { autoClose: 3000 });
        }else{
                setStartDate(useJwt.formatDate(date));
            }
    };
    // start time ---------------------------------------
    const [StartTime, setStartTime] = useState("");
    const handleChangeStartTime = (event) => {
        setStartTime(event.target.value);
    };

    // end date ---------------------------------------
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));

    const handleChangeEndDate = (date) => {
        var current_date = new Date();
        var diff_day = moment(current_date, "YYYY-MM-DD").diff(moment(date, "YYYY-MM-DD 00:00:00"), 'days');
        if(diff_day > 0){
            toast.error("過去の日付は入力できません。", { autoClose: 3000 });
        }else{
            setEndDate(useJwt.formatDate(date));
        }
    };
    // end time ---------------------------------------
    const [EndTime, setEndTime] = useState("");
    const handleChangeEndTime = (event) => {
        setEndTime(event.target.value);
    };

    //schedule data
    const [scheduleSearchValue3, setScheduleSearchValue3] = useState("");
    const [scheduleModalShow, setScheduleModalShow] = useState(false);
    const [scheduleTableData, setScheduleTableData] = useState([]);
    const [shipperName, setShipperName] = useState("");
    const [scheduleId, setScheduleId] = useState("");
    const [workerName, setWorkerName] = useState("");
    const loadScheduleModalData = (event) => {
        event.preventDefault();
        let code = warehouseId;
        setScheduleSearchValue3(workerName);
        if(code != ""){
            const data = {
                userID: userData['id'],
                warehouse_id: code,
                schedule_date : scheduleDate
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getScheduleListByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setScheduleTableData(response.data.data);
                setScheduleModalShow(true);
            })
            .catch((error) => {
            });
        }else{
            setScheduleTableData([]);
            toast.error("倉庫を選択してください。", {autoClose : 3000});
        }
    }

    
    const setScheduleData = (result) => {
        if(result['sys_schedule_id']) {
            setScheduleId(result['sys_schedule_id']);
            setShipperName(result['shipper_name']);
            setWorkerName(result['user_family_nm_kanji'] + ' ' + result['user_first_nm_kanji']);
        }else{
            setScheduleId("");
            setShipperName("");
            setWorkerName("");
        }
    }

    //bus data
    const [busModalShow, setBusModalShow] = useState(false);
    const [busTableData, setBusTableData] = useState([]);
    const [busName, setBusName] = useState("");
    const [busStandard, setBusStandard] = useState("");
    const [busId, setBusId] = useState("");
    const [busSearchValue2, setBusSearchValue2] = useState("");
    const loadBusModalData = (event) => {
        event.preventDefault();
        let code = warehouseId;
        setBusSearchValue2(busName);
        if(code != ""){
            const data = {
                userID: userData['id'],
                warehouse_id: code,
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getBusSettingEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let result = response.data.data;
                let tempList = [];
                for(var i = 0; i < result.length; i++){
                    let temp = {};
                    temp['id'] = result[i]['id'];
                    temp['warehouse_id'] = result[i]['warehouse_id'];
                    temp['bus_name'] = result[i]['bus_name'];

                    let standardList = JSON.parse(result[i]['standard']);
                    let tempStandard = "";
                    for(var j = 0; j < standardList.length; j++){
                        if(j != 0)  tempStandard += '、'
                        if(standardList[j] == "1")  tempStandard += '4t';
                        if(standardList[j] == "2")  tempStandard += '10t';
                        if(standardList[j] == "3")  tempStandard += 'トレーラー';
                    }
                    temp['standard'] = tempStandard;
                    tempList.push(temp);
                }

                setBusTableData(tempList);
                setBusModalShow(true);
            })
            .catch((error) => {
                setBusTableData([]);
            });
        }else{
            setBusTableData([]);
            toast.error("倉庫を選択してください。", {autoClose : 3000});
        }
    }

    
    const setBusData = (result) => {
        console.log(result);
        if(result['id']) {
            setBusId(result['id']);
            setBusName(result['bus_name']);
            setBusStandard(result['standard']);
        }else{
            setBusId("");
            setBusName("");
            setBusStandard("");
        }
    }

    const handleChangeBusName = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>2) {
            str = str.substring(0, 2);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setBusName(str);
    };

    const onKeyDownBusName = (event) => {
        if(event.keyCode == 13){
            document.getElementById('input5').focus();
        }
        if(event.keyCode === 13 && warehouseId != "") { // enter key
            document.getElementById('input5').focus();
            if(event.target.value != "") {
                const data_r = {
                    userID: userData['id'],
                    bus_name: event.target.value,
                    warehouse_id: warehouseId
                };
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getBusSettingEndpoint;
                axios
                .post(url, data_r, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if(t_data.length > 0) {
                        let result = t_data[0];
                        let temp = {};
                        temp['id'] = result['id'];
                        temp['warehouse_id'] = result['warehouse_id'];
                        temp['bus_name'] = result['bus_name'];
    
                        let standardList = JSON.parse(result['standard']);
                        let tempStandard = "";
                        for(var j = 0; j < standardList.length; j++){
                            if(j != 0)  tempStandard += '、'
                            if(standardList[j] == "1")  tempStandard += '4t';
                            if(standardList[j] == "2")  tempStandard += '10t';
                            if(standardList[j] == "3")  tempStandard += 'トレーラー';
                        }
                        temp['standard'] = tempStandard;
                        setBusData(temp);
                    }else{
                        setBusData([]);
                    }
                })
                .catch((error) => {
                });
            }else{
                setBusData([]);
            }
            event.preventDefault();
        }
    };

        // Carrier data----------------------------------------------------------
        const [CarrierCode, setCarrierCode] = useState("");
        const handleChangeCarrierCode = (event) => {
            setCarrierCode(useJwt.validateNum(event.target.value,6));
        };
        const [CarrierACode, setCarrierACode] = useState("");
        const handleChangeCarrierACode = (event) => {
            setCarrierACode(useJwt.validateNum(event.target.value,3));
        };
        const [CarrierName, setCarrierName] = useState("");
        const [CarrierId, setCarrierId] = useState("");
        const onKeyDownCarrierCode = (event,param) => {
            if(event.keyCode === 13) { // enter key
                if(param == 1) {
                    document.getElementById('input6').focus();
                    getCarrierData(event.target.value, CarrierACode);
                }else{
                    document.getElementById('input7').focus();
                    getCarrierData(CarrierCode, event.target.value);
                }
                event.preventDefault();
            }
        };
    
        const getCarrierData = (code,acode) => {
            if(code != "") {
                let data = {};
                data['userID'] = userData['id'];
                data['carrier_code'] = code;
                if(acode != "") {
                    data['carrier_a_code'] = acode;
                }
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierEndpoint;
                axios
                .post(url, data, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if(t_data.length > 0) {
                        setCarrierData(t_data[0]);
                    }else{
                        setCarrierData([]);
                    }
                })
                .catch((error) => {
                });
            }else{
                setCarrierData([]);
            }
        }
        const setCarrierData = (result) => {
            if(result['sys_carrier_id']) {
                setCarrierCode(result['carrier_code']);
                setCarrierACode(result['carrier_a_code']);
                setCarrierName(result['carrier_name']);
                setCarrierId(result['sys_carrier_id']);
            }else{
                setCarrierName("");
                setCarrierCode("");
                setCarrierACode("");
                setCarrierId("");
            }
        }
    // ------------------------------- Carrier Modal ------------------------------------
    const [showModalCarrier, setShowModalCarrier] = useState(false);
    const [CarrierSearchValue, setCarrierSearchValue] = useState("");
    const [CarrierSearchValue2, setCarrierSearchValue2] = useState("");
    const [CarrierTableData, setCarrierTableData] = useState([]);
    const loadCarrierModalData = (event) => {
        event.preventDefault();
        setCarrierSearchValue(CarrierCode);
        setCarrierSearchValue2(CarrierACode);

        if(CarrierCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = CarrierCode;
            if(CarrierACode != "") {
                data['carrier_a_code'] = CarrierACode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setCarrierTableData(response.data.data);
                setShowModalCarrier(true);
            })
            .catch((error) => {
            });
        }else{
            setCarrierTableData([]);
            setShowModalCarrier(true);
        }
    }

    
    //driver data
    const [driverModalShow, setDriverModalShow] = useState(false);
    const [driverTableData, setDriverTableData] = useState([]);
    const [driverId, setDriverId] = useState("");
    const [carNumber, setCarNumber] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [carStandard, setCarStandard] = useState("");
    const [driverSearchValue2, setDriverSearchValue2] = useState("");
    const loadDriverModalData = (event) => {
        event.preventDefault();
        let code = CarrierId;
        setDriverSearchValue2(carNumber);
        if(code != ""){
            const data = {
                userID: userData['id'],
                transport: code,
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getDriverEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setDriverTableData(response.data.data);
                setDriverModalShow(true);
            })
            .catch((error) => {
                setDriverTableData([]);
            });
        }else{
            setDriverTableData([]);
            toast.error("運送会社を選択してください。", {autoClose : 3000});
        }
    }

    
    const setDriverData = (result) => {
        if(result['id']) {
            setDriverId(result['id']);
            setCarNumber(result['car_number']);
            setPhoneNumber(result['phone_number']);
            setCarStandard(result['car_standard']);
        }else{
            setDriverId("");
            setCarNumber("");
            setPhoneNumber("");
            setCarStandard("");
        }
    }

    const handleChangeCarNumber = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        // if(str.length>2) {
        //     str = str.substring(0, 2);
        // }
        // if(!z1.test(str)) { 
        //     str = "";
        // }
        setCarNumber(str);
    };

    const onKeyDownCarNumber = (event) => {
        if(event.keyCode == 13){
            document.getElementById('input8').focus();
        }
        if(event.keyCode === 13 && CarrierId != "") { // enter key
            if(event.target.value != "") {
                const data_r = {
                    userID: userData['id'],
                    car_number: event.target.value,
                    transport: CarrierId
                };
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getDriverEndpoint;
                axios
                .post(url, data_r, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if(t_data.length > 0) {
                        setDriverData(t_data[0]);
                    }else{
                        setDriverData([]);
                    }
                })
                .catch((error) => {
                });
            }else{
                setDriverData([]);
            }
            event.preventDefault();
        }
    };

    
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {

        if(warehouseId == ""){
            toast.error("倉庫を選択してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }

        if(driverId == ""){
            toast.error("ドライバーを選択してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }

        var length_ms = moment(EndDate + EndTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(StartDate + StartTime, 'YYYY-MM-DD HH:mm:ss'), 'seconds');

        if (StartDate == "") {
            toast.error("開始予定時間を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (StartTime == "") {
            toast.error("開始予定時間を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (EndDate == "") {
            toast.error("終了予定時間を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (EndTime == "") {
            toast.error("終了予定時間を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }

        if (length_ms < 0) {
            toast.error("時間の設定が正しくありません。", { autoClose: 3000 });
            setOpen(false);
            return;
        }

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const csLoading = (flag) => {
        if (flag) {
            document.getElementById('csLoader').style.display = "flex";
        } else {
            document.getElementById('csLoader').style.display = "none";
        }
    }

    const scheduleSave = () =>{
        let data = {};

        if(busScheduleId != ""){
            data['id'] = busScheduleId;
        }
        data['userID'] = userData['id'];
        data['work_type'] = Out == "1" ? "1" : "0";
        data['warehouse_id'] = warehouseId;
        data['bus_id'] = busId;
        data['schedule_id'] = scheduleId;
        data['schedule_date'] = scheduleDate;
        data['transport_id'] = CarrierId;
        data['driver_id'] = driverId;
        data['start_date_time'] = StartDate + ' ' + StartTime;
        data['end_date_time'] = EndDate + ' ' + EndTime;
        if(commandId != "") data['command_id'] = commandId;

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setDriverScheduleEndpoint;
        csLoading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                toast.success("登録致しました。", {autoClose:3000});
                setBusScheduleId("");
                setWarehouseCode("");
                setWarehouseName("");
                setWarehouseId("");
                setCarrierCode("");
                setCarrierACode("");
                setCarrierName("");
                setCarrierId("");
                setBusName("");
                setBusId("");
                setBusStandard("");
                setStartTime("");
                setEndTime("");
                setItemName("");
                setShipperName("");
                setWorkerName("");
                setScheduleId("");
                setCarNumber("");
                setCarStandard("");
                setDriverId("");
            }

            csLoading(false);
            navigate('/bus/schedule/index');
        })
        .catch((error) => {
            csLoading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else if(error.response.status === 302){
                toast.error("時間が重複しています。 作業時間を再設定してください。登録致しました。", {autoClose:3000});
            }
        });

        setOpen(false);
    }
    
    const onDataKeyDown = (e, param) => {
        if(e.keyCode == 13){
            switch(param){
                case 1 :
                    document.getElementById('input1').focus();
                    break;
                case 2 :
                    document.getElementById('input2').focus();
                    break;
                case 3 :
                    document.getElementById('input3').focus();
                    break;
                case 4 :
                    document.getElementById('input4').focus();
                    break;
                case 5 :
                    document.getElementById('input5').focus();
                    break;
                case 6 :
                    document.getElementById('input6').focus();
                    break;
                case 7 :
                    document.getElementById('input7').focus();
                    break;
                case 8 :
                    document.getElementById('input8').focus();
                    break;
                case 9 :
                    document.getElementById('input9').focus();
                    break;
                case 10 :
                    document.getElementById('input10').focus();
                    break;
                case 11 :
                    document.getElementById('input11').focus();
                    break;
                default :
            }
        }
    }

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
  
    const handleCreateNewRow = (values) => {
      tableData.push(values);
      setTableData([...tableData]);
    };
  
    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        tableData[row.index] = values;
        //send/receive api updates here, then refetch or update local table data for re-render
        setTableData([...tableData]);
        exitEditingMode(); //required to exit editing mode and close modal
      }
    };
  
    const handleCancelRowEdits = () => {
      setValidationErrors({});
    };
  
    const handleDeleteRow = useCallback(
      (row) => {
        //send api delete request here, then refetch or update local table data for re-render
        historyData.splice(row.index, 1);
        setHistoryData([...tableData]);
      },
      [tableData],
    );
  
    const getCommonEditTextFieldProps = useCallback(
      (cell) => {
        return {
          error: !!validationErrors[cell.id],
          helperText: validationErrors[cell.id],
        };
      },
      [validationErrors],
    );
  
    const columns = useMemo(
      () => [
        {
          accessorKey: 'id',
          header: 'ID',
          enableColumnOrdering: false,
          enableEditing: false, //disable editing on this column
          enableSorting: false,
          size: 80,
        },
        {
          accessorKey: 'firstName',
          header: 'First Name',
          size: 140,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'lastName',
          header: 'Last Name',
          size: 140,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'email',
          header: 'Email',
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
            type: 'email',
          }),
        },
        {
          accessorKey: 'age',
          header: 'Age',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
            type: 'number',
          }),
        },
        {
          accessorKey: 'state',
          header: 'State',
          muiTableBodyCellEditTextFieldProps: {
            select: true, //change to select for a dropdown
          },
        },
      ],
      [getCommonEditTextFieldProps],
    );
  
    //style
    const style_input_140px = [style.cs_input, gstyle.w140px];
    const style_input_140_nlb = [style.cs_input, gstyle.w140px, style.input_left_border_none];
    const style_input_bl_n = [style.cs_input, style.job_time];


    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csLoader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <WarehouseModal show={showModal} onClose={()=>setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} SearchValueWarehouse={SearchValueWarehouse} onChange={setSearchValue} 
                tableData={tableData} setTableData={setTableData}
                target={setWarehouseData} />
            <ScheduleModal show={scheduleModalShow} onClose={()=>setScheduleModalShow(false)} width={'70%'}
                SearchValue={warehouseId} SearchValue2={scheduleDate} SearchValue3={scheduleSearchValue3} onChange={setScheduleSearchValue3} 
                scheduleTableData={scheduleTableData} setScheduleTableData={setScheduleTableData}
                target={setScheduleData} />
            <BusModal show={busModalShow} onClose={()=>setBusModalShow(false)} width={'40%'}
                SearchValue={warehouseId} SearchValue2={busSearchValue2} onChange={setBusSearchValue2} 
                busTableData={busTableData} setBusTableData={setBusTableData}
                target={setBusData} />
            <CarrierModal show={showModalCarrier} onClose={()=>setShowModalCarrier(false)} width={'70%'}
                SearchValue={CarrierSearchValue} onChange={setCarrierSearchValue} 
                SearchValue2={CarrierSearchValue2} onChange2={setCarrierSearchValue2}
                tableData={CarrierTableData} setTableData={setCarrierTableData}
                target={setCarrierData} />
            <DriverModal show={driverModalShow} onClose={()=>setDriverModalShow(false)} width={'60%'}
                SearchValue={CarrierId} SearchValue2={driverSearchValue2} onChange={setDriverSearchValue2} 
                driverTableData={driverTableData} setDriverTableData={setDriverTableData}
                target={setDriverData} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>バース予約</p>
            </div>

            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    <div className={style.div_flex}>
                        <div className={style.div_body_flex}>
                            <div className={style.div_flex} style={{gap:100}}>
                                <div className={style.div_flex}>
                                    <input type={'radio'} name='inout' onChange={(e) => handleChangeIn(e, 0)} 
                                    checked={In == "0" ? true : false} id="checkIn"/>
                                    <label htmlFor='checkIn'>入庫</label>
                                </div>
                                <div className={style.div_flex}>
                                    <input type={'radio'} name='inout' onChange={(e) => handleChangeOut(e, 1)} 
                                    checked={Out == "1" ? true : false} id="checkOut"/>
                                    <label htmlFor='checkOut'>出庫</label>
                                </div>
                            </div>
                            <div className={style.div_flex_left}>
                                <div className={style.div_label}>バース予約日</div>
                                <div>
                                    <InputDatepicker  value={scheduleDate} onChange={handleChangeScheduleDate} focusId='input2' />
                                </div>
                            </div>
                            <div className={style.div_flex_left}>
                                <div className={style.div_label}>倉 庫</div>
                                <div className={style.div_sub_flex_left}>
                                {
                                    urlParams.state ? 
                                    <>
                                        {
                                        urlParams.state.data.flag == 1 ?
                                            <input type={'number'} className={style_input_140px.join(" ")}
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e)}
                                            value={warehouseCode} onChange={handleChangeWarehouseCode} disabled/>
                                        :
                                            <input type={'number'} className={style_input_140px.join(" ")}
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e)} id='input2'
                                            value={warehouseCode} onChange={handleChangeWarehouseCode} />
                                        }
                                    </>
                                    :
                                    <input type={'number'} className={style_input_140px.join(" ")}
                                        onKeyDown={(e) => onKeyDownWarehouseCode(e)} id='input2'
                                        value={warehouseCode} onChange={handleChangeWarehouseCode} />
                                }
                                    <input type={'text'} className={style_input_140_nlb.join(" ")} value={warehouseName} disabled/>
                                    {
                                        urlParams.state ?
                                            (urlParams.state.data.flag == 2 || urlParams.state.data.flag == 3) &&
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalData(e)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        :
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalData(e)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className={style.div_flex_left}>
                                <div className={style.div_label}>入出庫スケジュール</div>
                                <div className={style.div_sub_flex_left}>
                                    <input type={'text'} className={style_input_140px.join(" ")} 
                                    id='input3' onKeyDown={(e)=>onDataKeyDown(e, 4)} value={shipperName}/>
                                    <input type={'text'} className={style_input_140_nlb.join(" ")} value={workerName} disabled/>
                                    <div className={gstyle.icon_search_wrap} onClick={(e)=>loadScheduleModalData(e)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                </div>
                            </div>
                            <div className={style.div_flex_left}>
                                <div className={style.div_label}>バース</div>
                                <div className={style.div_sub_flex_left}>
                                    <input type={'number'} className={style_input_140px.join(" ")}
                                    onKeyDown={(e) => onKeyDownBusName(e)} id='input4'
                                    value={busName} onChange={handleChangeBusName} />
                                    <input type={'text'} className={style_input_140_nlb.join(" ")} 
                                    value={busStandard} disabled/>
                                    <div className={gstyle.icon_search_wrap} onClick={(e)=>loadBusModalData(e)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                </div>
                            </div>
                            <div className={style.div_flex_left}>
                                <div className={style.div_label}>運送会社名</div>
                                    <div className={style.div_sub_flex_left}>
                                    {
                                        (urlParams.state && urlParams.state.data.flag != 3) ?
                                        <>
                                            <input type={'number'} className={style_input_140px.join(" ")}
                                                value={CarrierCode}
                                                onKeyDown={(e) => onKeyDownCarrierCode(e,1)}
                                                onChange={(e) => handleChangeCarrierCode(e)} disabled/>
                                            <input type={'number'} className={style_input_140_nlb.join(" ")}
                                            value={CarrierACode}
                                            onKeyDown={(e) => onKeyDownCarrierCode(e,2)}
                                            onChange={(e) => handleChangeCarrierACode(e)} disabled/>
                                        </>
                                        :
                                        <>
                                            <input type={'number'} className={style_input_140px.join(" ")}
                                                value={CarrierCode} id='input5'
                                                onKeyDown={(e) => onKeyDownCarrierCode(e,1)}
                                                onChange={(e) => handleChangeCarrierCode(e)} />
                                            <input type={'number'} className={style_input_140_nlb.join(" ")}
                                            value={CarrierACode} id='input6'
                                            onKeyDown={(e) => onKeyDownCarrierCode(e,2)}
                                            onChange={(e) => handleChangeCarrierACode(e)} />
                                        </>
                                    }
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={CarrierName} />
                                        {
                                            urlParams.state == null ?
                                                <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCarrierModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                            :
                                            urlParams.state.data.flag == 3 &&
                                                <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCarrierModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                        }
                                    </div>
                            </div>
                            <div className={style.div_flex_left}>
                                <div className={style.div_label}>ドライバー</div>
                                <div className={style.div_sub_flex_left}>
                                {
                                    urlParams.state && urlParams.state.data.flag != 3 ? 
                                    <input type={'text'} className={style_input_140px.join(" ")}
                                    onKeyDown={(e) => onKeyDownCarNumber(e)}
                                    value={carNumber} onChange={handleChangeCarNumber} disabled/>
                                    :
                                    <input type={'text'} className={style_input_140px.join(" ")}
                                    onKeyDown={(e) => onKeyDownCarNumber(e)} id='input7'
                                    value={carNumber} onChange={handleChangeCarNumber} />
                                }
                                    <input type={'text'} className={style_input_140_nlb.join(" ")} 
                                    value={carStandard == 1 ? '4t' : (carStandard == 2 ? '10t' : (carStandard != "" ? 'トレーラー' : ''))} disabled/>
                                    {
                                        urlParams.state == null ?
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadDriverModalData(e)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        :
                                        urlParams.state.data.flag == 3 && 
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadDriverModalData(e)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className={style.div_flex_left}>
                                <div className={style.div_label}>開始予定時間</div>
                                <div style={{display:'flex'}}>
                                    <InputDatepicker value={StartDate} onChange={handleChangeStartDate} width={105} focusId='input9' id='input8'/>
                                    <input type="time" className={style_input_bl_n.join(" ")} 
                                    id='input9' onKeyDown={(e)=>onDataKeyDown(e, 10)}
                                    value={StartTime} onChange={(e) => handleChangeStartTime(e)} style={{minWidth:110, margin:0}}/>
                                </div>
                            </div>
                            <div className={style.div_flex_left}>
                                <div className={style.div_label}>終了予定時間</div>
                                <div style={{display:'flex'}}>
                                    <InputDatepicker value={EndDate} onChange={handleChangeEndDate} width={105} focusId='input11' id='input10'/>
                                    <input type="time" className={style_input_bl_n.join(" ")}
                                    id='input11' onKeyDown={(e)=>onDataKeyDown(e, 12)}
                                     value={EndTime} onChange={(e) => handleChangeEndTime(e)} style={{minWidth:110, margin:0}}/>
                                </div>
                            </div>
                            <div className={style.div_flex_left}>
                                <div className={style.div_label}>商品名</div>
                                <div style={{display:'flex', width:'100%'}}>
                                    <input type="text" className={style_input_140px.join(" ")} value={itemName} disabled/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* button 登録 */}
                    <div className={gstyle.btn_update_wrap} style={{gap:50}}>
                        <Button type={"primary"} onClick={handleOpen} style={{width:130}}>登 録</Button>
                        <Button type={"primary"} onClick={(e) => navigate('/bus/schedule/index')} style={{width:130}} danger>戻 る</Button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={scheduleSave} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>

                </div>
            </div>
        </div>
    )
}

export default BusScheduling;

export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Account</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => (
              <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};
