/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './StockListByWarehouse2.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import CategoryModal from '../../../components/common/modal/CategoryModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import StartTableModal from '../../../components/common/modal/TableModal';
import EndTableModal from '../../../components/common/modal/TableModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import {Select} from 'antd';

const StockListByWarehouse2 = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    // publish date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    
    // start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
    };
    const OrderTypeData = [ { value:'0', label:'倉庫順' }, { value:'1', label:'分類順' } ];
    // category --------------------------------------------------------
    const [CategoryCode1, setCategoryCode1] = useState("");
    const [CategoryName1, setCategoryName1] = useState("");
    const handleChangeCategoryCode1 = (event) => {
        setCategoryCode1(useJwt.validateNum(event.target.value,3));
    };
    const [CategoryCode2, setCategoryCode2] = useState("");
    const [CategoryName2, setCategoryName2] = useState("");
    const handleChangeCategoryCode2 = (event) => {
        setCategoryCode2(useJwt.validateNum(event.target.value,3));
    };
    const onKeyDownCategoryCode =(event,param) => {
        if(event.keyCode === 13) { // enter key
            getCategoryData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('category_code2').focus();
            }else{
                document.getElementById('shipper_code1').focus();
            }
        }
    };
    const getCategoryData = (code,param) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let t_data = response.data;
                if(t_data.length > 0) {
                    setCategoryData(t_data[0],param);
                }else{
                    setCategoryData([],param);
                }
            })
            .catch((error) => {
            });
        }else{
            setCategoryData([],param);
        }
    }
    const setCategoryData = (data,param) => {
        if(data['category_code']) {
            if(param == 1) {
                setCategoryCode1(data['category_code']);
                setCategoryName1(data['category_name']);
            }else{
                setCategoryCode2(data['category_code']);
                setCategoryName2(data['category_name']);
            }
        }else{
            if(param == 1) {
                setCategoryCode1("");
                setCategoryName1("");
            }else{
                setCategoryCode2("");
                setCategoryName2("");
            }
        }
    }
    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('warehouse_code2').focus();
            }else{
                setTimeout(()=>{
                    if(document.getElementById('location_code1')){
                        document.getElementById('location_code1').focus();
                    }else{
                        document.getElementById('shipper_code1').focus();
                    }
                })
            }
        }
    };
    const getWarehouseData = (code,param) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result,param);
                    }else{
                        setWarehouseData([],param);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setWarehouseData([],param);
        }
    }
    const setWarehouseData = (data,param) => {
        if(data['warehouse_code']) {
            if(param == 1) {
                setWarehouseCode1(data['warehouse_code']);
                setWarehouseName1(data['warehouse_name']);
            }else{
                setWarehouseCode2(data['warehouse_code']);
                setWarehouseName2(data['warehouse_name']);
            }
        }else{
            if(param == 1) {
                setWarehouseCode1("");
                setWarehouseName1("");
            }else{
                setWarehouseCode2("");
                setWarehouseName2("");
            }
        }
    }
    // location ------------------------------------------------
    const [LocationNo1, setLocationNo1] = useState("");
    const handleChangeLocationNo1 = (event) => {
        setLocationNo1(event.target.value);
    };
    const [LocationData1, setLocationData1] = useState([]);
    const [LocationNo2, setLocationNo2] = useState("");
    const handleChangeLocationNo2 = (event) => {
        setLocationNo2(event.target.value);
    };
    const [LocationData2, setLocationData2] = useState([]);

    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
        setItemData([],1);
        setItemData([],2);
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
        setItemData([],1);
        setItemData([],2);
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('type2').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                if(ShipperACode1 != ""){
                    acode = ShipperACode1;
                }else{
                    acode = "000";
                }
            }else{
                code = ShipperCode1;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }else{
            if(param2==1){
                code = value;
                if(ShipperACode2 != ""){
                    acode = ShipperACode2;
                }else{
                    acode = "000";
                }
            }else{
                code = ShipperCode2;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // item ----------------------------------------------------
    const [ItemCode1, setItemCode1] = useState("");
    const handleChangeItemCode1 = (event) => {
        setItemCode1(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode1, setItemACode1] = useState("");
    const handleChangeItemACode1 = (event) => {
        setItemACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName1, setItemName1] = useState("");
    const [ItemCode2, setItemCode2] = useState("");
    const handleChangeItemCode2 = (event) => {
        setItemCode2(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode2, setItemACode2] = useState("");
    const handleChangeItemACode2 = (event) => {
        setItemACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName2, setItemName2] = useState("");
    const onKeyDownItemCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getItemData(event.target.value,param1,param2);
            event.preventDefault();
        }
    };
    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                if(ItemACode1 != ""){
                    acode = ItemACode1;
                }else{
                    acode = "000";
                }
            }else{
                code = ItemCode1;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }else{
            if(param2==1){
                code = value;
                if(ItemACode2 != ""){
                    acode = ItemACode2;
                }else{
                    acode = "000";
                }
            }else{
                code = ItemCode2;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode1;
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result,param1);
                    }else{
                        setItemData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([],param1);
        }
    }
    const setItemData = (data,param) => {
        if(data['item_code']) {
            if(param == 1) {
                setItemCode1(data['item_code']);
                setItemACode1(data['item_a_code']);
                setItemName1(data['item_name']);
            }else{
                setItemCode2(data['item_code']);
                setItemACode2(data['item_a_code']);
                setItemName2(data['item_name']);
            }
        }else{
            if(param == 1) {
                setItemCode1("");
                setItemACode1("");
                setItemName1("");
            }else{
                setItemCode2("");
                setItemACode2("");
                setItemName2("");
            }
        }
    }
    // item type -----------------------------------------------
    const [ItemType, setItemType] = useState(0);
    const handleChangeItemType = (event) => {
        setItemType(event);
    };
    const ItemTypeData = [ { value:'0', label:'品番集計' }, { value:'1', label:'ロット別' } ];
    // filter type -----------------------------------------------
    const [FilterType, setFilterType] = useState(0);
    const handleChangeFilterType = (event) => {
        setFilterType(event);
    };
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_stock_flag_8'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        
        data['start_slip_date'] = StartDate;
        data['end_slip_date'] = EndDate;
        data['sort_type'] = OrderType;

        data['start_warehouse_code'] = WarehouseCode1;
        data['end_warehouse_code'] = WarehouseCode2;
        if(LocationNo1 != "") {
            data['start_table_code'] = LocationNo1;
        }
        if(LocationNo2 != "") {
            data['end_table_code'] = LocationNo2;
        }

        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        
        data['start_category_code'] = CategoryCode1;
        data['end_category_code'] = CategoryCode2;

        data['start_item_code'] = ItemCode1;
        data['end_item_code'] = ItemCode2;
        data['start_item_a_code'] = ItemACode1;
        data['end_item_a_code'] = ItemACode2;

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockListByWarehouse2Endpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            // console.log(result)
            if(result.length>0){
                gridMainTable(result);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                gridMainTable([]);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
        
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let data = result;
        setMainTableData(data);
        if(data.length>0){
            setOpend(true);
        }
    }
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------- Category Modal ------------------------------------------
    const [showModalCategory, setShowModalCategory] = useState(false);
    const [CategorySearchValue, setCategorySearchValue] = useState("");
    const [CategoryTableData, setCategoryTableData] = useState([]);
    const loadCategoryCodeModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = CategoryCode1;
        if(param==2){
            code = CategoryCode2;
        }
        setCategorySearchValue(code);
        if(code != ""){
            const data = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setCategoryTableData(response.data);
                setShowModalCategory(true);
            })
            .catch((error) => {
            });
        }else{
            setCategoryTableData([]);
            setShowModalCategory(true);
        }
    }
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    
    const loadWarehouseModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode1;
        if(param==2){
            code = WarehouseCode2;
        }
        setWarehouseSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['warehouse_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemCode1;
        let acode = ItemACode1;
        if(param == 2) {
            code = ItemCode2;
            acode = ItemACode2;
        }
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode1);
        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode1;
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

    
    // -----------------------------start Table modal -------------------------------------
    const [showModalStartTable, setShowModalStartTable] = useState(false);
    const [startTableSearchValue, setStartTableSearchValue] = useState("");
    const [startTableSearchValue2, setStartTableSearchValue2] = useState("");
    const [startTableData, setStartTableData] = useState([]);
    const loadStartTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = WarehouseCode1;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setStartTableSearchValue(table_code);
        setStartTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setStartTableData(response.data.data);
                setShowModalStartTable(true);
            })
            .catch((error) => {
            });
        }else{
            setStartTableData([]);
            setShowModalStartTable(true);
        }
    }
    const setStartTableCode = (data) => {

        setLocationData1(data);
        setLocationNo1(data['table_code']);
    }
    
    // -----------------------------end Table modal -------------------------------------
    const [showModalEndTable, setShowModalEndTable] = useState(false);
    const [endTableSearchValue, setEndTableSearchValue] = useState("");
    const [endTableSearchValue2, setEndTableSearchValue2] = useState("");
    const [endTableData, setEndTableData] = useState([]);
    const loadEndTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = WarehouseCode1;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setEndTableSearchValue(table_code);
        setEndTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setEndTableData(response.data.data);
                setShowModalEndTable(true);
            })
            .catch((error) => {
            });
        }else{
            setEndTableData([]);
            setShowModalEndTable(true);
        }
    }
    const setEndTableCode = (data) => {
        setLocationData2(data);
        setLocationNo2(data['table_code']);
    }

    
    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('category_code1')){
                    document.getElementById('category_code1').focus();
                }else{
                    document.getElementById('warehouse_code1').focus();
                }
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type3').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onLocationKeyDown = (e, param) => {
        if(e.keyCode == 13){
            if(param == 1){
                document.getElementById('location_code2').focus();
            }else{
                document.getElementById('shipper_code1').focus();
            }
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            default:
                break;
        }
    }

    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <CategoryModal show={showModalCategory} onClose={()=>setShowModalCategory(false)} width={'70%'}
                SearchValue={CategorySearchValue} onChange={setCategorySearchValue} 
                tableData={CategoryTableData} setTableData={setCategoryTableData}
                target={setCategoryData} targetParam={TargetParam} />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} 
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
            <StartTableModal show={showModalStartTable} onClose={()=>setShowModalStartTable(false)} width={'70%'}
                SearchValue={startTableSearchValue} SearchValue2={startTableSearchValue2}  onChange={setStartTableSearchValue} 
                tableData={startTableData} setTableData={setStartTableData}
                target={setStartTableCode} />
            <EndTableModal show={showModalEndTable} onClose={()=>setShowModalEndTable(false)} width={'70%'}
                SearchValue={endTableSearchValue} SearchValue2={endTableSearchValue2}  onChange={setEndTableSearchValue} 
                tableData={endTableData} setTableData={setEndTableData}
                target={setEndTableCode} />

            <div className={gstyle.border}>
                <p className={gstyle.text}>倉庫別在庫一覧表</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={StartDate} onChange={handleChangeStartDate} id='date2' focusId='date3' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={EndDate} onChange={handleChangeEndDate} id='date3' focusId='type1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 出力順位 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={OrderTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* 倉庫, 棚 */}
                    {OrderType == 0 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始倉庫</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownWarehouseCode(e,1)} id='warehouse_code1'
                                                value={WarehouseCode1} onChange={handleChangeWarehouseCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了倉庫</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownWarehouseCode(e,2)} id='warehouse_code2'
                                                value={WarehouseCode2} onChange={handleChangeWarehouseCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={WarehouseName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            {WarehouseCode1 != "" && WarehouseCode1 == WarehouseCode2 && <>
                                <div className={style_col_3.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>開始棚コード</span>
                                            <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                                <input type={'num'} className={style.cs_tbl_input} 
                                                    value={LocationNo1} id='location_code1'
                                                    onChange={(e) => handleChangeLocationNo1(e)} 
                                                    onKeyDown={(e)=>onLocationKeyDown(e, 1)}
                                                    />
                                                    <div className={gstyle.icon_search_wrap} 
                                                        onClick={(e)=>loadStartTableModalData(e)}>
                                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                    </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className={style_col_3.join(" ")}>
                                    <div className={style_input_wrap.join(" ")}>
                                        <label className={style_label_wrap.join(" ")}>
                                            <span className={gstyle.cs_span}>終了棚コード</span>
                                            <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                                <input type={'num'} className={style.cs_tbl_input} 
                                                    value={LocationNo2} id='location_code2'
                                                    onKeyDown={(e)=>onLocationKeyDown(e, 2)}
                                                    onChange={(e) => handleChangeLocationNo2(e)} />
                                                    <div className={gstyle.icon_search_wrap} 
                                                        onClick={(e)=>loadEndTableModalData(e)}>
                                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                    </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </>}
                        </div>
                    }
                    { /* 分類 */}
                    {OrderType == 1 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始分類</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownCategoryCode(e,1)} id='category_code1'
                                                value={CategoryCode1} onChange={handleChangeCategoryCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={CategoryName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCategoryCodeModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了分類</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownCategoryCode(e,2)} id='category_code2'
                                                value={CategoryCode2} onChange={handleChangeCategoryCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={CategoryName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCategoryCodeModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 商品 */}
                    {ShipperCode1 != "" && ShipperCode1 == ShipperCode2 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,1,1)} id='item_code1'
                                            value={ItemCode1} onChange={handleChangeItemCode1} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,1,2)} id='item_a_code1'
                                            value={ItemACode1} onChange={handleChangeItemACode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,2,1)} id='item_code2'
                                            value={ItemCode2} onChange={handleChangeItemCode2} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,2,2)} id='item_a_code2'
                                            value={ItemACode2} onChange={handleChangeItemACode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 品番区分, 打出区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="品番区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeItemType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={ItemTypeData}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>打出区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="打出区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeFilterType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={[
                                                {value:'0', label:'発生又は在庫有り'},
                                                {value:'1', label:'発生有りのみ'},
                                            ]}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>倉庫別在庫一覧表</h2>
                                </div>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {/* 出力順位 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_8.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>
                                                {useJwt.formatDate2(new Date(StartDate))+' ~ '+useJwt.formatDate2(new Date(EndDate))}
                                            </span>
                                        </div>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>
                                                [出力順位]:{OrderTypeData[OrderType]['value']}   
                                            </span>
                                            <span className={style.cs_span_3}>
                                                [品番区分]:{ItemTypeData[ItemType]['value']}    
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* table */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <table>
                                        <thead>
                                            <tr>
                                                {OrderType == 0 && 
                                                    <th>倉庫ｺｰﾄﾞ</th>
                                                }
                                                {OrderType == 0 && 
                                                    <>
                                                    <th rowSpan={2}>棚番号</th>
                                                    <th rowSpan={2}>番地</th>
                                                    <th rowSpan={2}>段</th>
                                                    </>
                                                }
                                                {OrderType == 1 && 
                                                    <th>分類ｺｰﾄﾞ</th>
                                                }
                                                <th>荷主ｺｰﾄﾞ</th>
                                                <th>品番</th>
                                                <th rowSpan={2}>ﾛｯﾄNo</th>
                                                <th>単位重量</th>
                                                <th rowSpan={2}>在庫重量</th>
                                                <th rowSpan={2}>在庫容積</th>
                                                <th rowSpan={2}>在庫金額</th>
                                            </tr>
                                            <tr>
                                                {OrderType == 0 && 
                                                    <th>倉庫名</th>
                                                }
                                                {OrderType == 1 && 
                                                    <th>分類名</th>
                                                }
                                                <th>荷主名</th>
                                                <th>品名</th>
                                                <th>単位容積</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {  mainTableData.map((data,index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        {OrderType == 0 && 
                                                            <td>{data['warehouse_code']}</td>
                                                        }
                                                        {OrderType == 0 && 
                                                        <>
                                                            <td rowSpan={2}>{data['table_row']}</td>
                                                            <td rowSpan={2}>{data['table_column']}</td>
                                                            <td rowSpan={2}>{data['table_floor']}</td>
                                                        </>
                                                        }
                                                        {OrderType == 1 && 
                                                            <td>{data['category_code']}</td>
                                                        }
                                                        <td>{data['shipper_code']}-{data['shipper_a_code']}</td>
                                                        <td>{data['item_code']}-{data['item_a_code']}</td>
                                                        <td rowSpan={2}>{data['rot_code']}</td>
                                                        <td className={style.td_right}>{data['unit_weight']}</td>
                                                        <td rowSpan={2} className={style.td_right}>{useJwt.numberWithCommas(data['stock_ary']['stockWeight'])}</td>
                                                        <td rowSpan={2} className={style.td_right}>{useJwt.numberWithCommas(data['stock_ary']['stockVolume'])}</td>
                                                        <td rowSpan={2} className={style.td_right}>￥{useJwt.numberWithCommas(data['stock_ary']['stockPrice'])}</td>
                                                    </tr>
                                                    <tr>
                                                        {OrderType == 0 && 
                                                            <td className={style.td_left}>{data['warehouse_name']}</td>
                                                        }
                                                        {OrderType == 1 && 
                                                            <td className={style.td_left}>{data['category_name']}</td>
                                                        }
                                                        <td className={style.td_left}>{data['shipper_name']}</td>
                                                        <td className={style.td_left}>{data['item_name']}</td>
                                                        <td className={style.td_right}>{data['unit_volume']}</td>
                                                    </tr>
                                                </React.Fragment>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'倉庫別在庫一覧表_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default StockListByWarehouse2;