/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import style from './InvoiceItemList.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ConsignorModal from '../../../components/common/modal/ConsignorModal';
import CarrierModal from '../../../components/common/modal/CarrierModal';
import DistinationModal from '../../../components/common/modal/DistinationModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import { Select } from 'antd';


const InvoiceItemList = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };

    // slip no -------------------------------------------------
    const [SlipNo1, setSlipNo1] = useState("");
    const handleChangeSlipNo1 = (event) => {
        setSlipNo1(useJwt.validateNum(event.target.value,6));
    };
    const [SlipNo2, setSlipNo2] = useState("");
    const handleChangeSlipNo2 = (event) => {
        setSlipNo2(useJwt.validateNum(event.target.value,6));
    };
    // question no -------------------------------------------------
    const [QuestionNo1, setQuestionNo1] = useState("");
    const handleChangeQuestionNo1 = (event) => {
        setQuestionNo1(useJwt.validateNum(event.target.value,6));
    };
    const [QuestionNo2, setQuestionNo2] = useState("");
    const handleChangeQuestionNo2 = (event) => {
        setQuestionNo2(useJwt.validateNum(event.target.value,6));
    };
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('consignor_code1').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                if(ShipperACode1 != ""){
                    acode = ShipperACode1;
                }else{
                    acode = "000";
                }
            }else{
                code = ShipperCode1;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }else{
            if(param2==1){
                code = value;
                if(ShipperACode2 != ""){
                    acode = ShipperACode2;
                }else{
                    acode = "000";
                }
            }else{
                code = ShipperCode2;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // Consignor -----------------------------------------------------
    const [ConsignorCode1, setConsignorCode1] = useState("");
    const handleChangeConsignorCode1 = (event) => {
        setConsignorCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ConsignorACode1, setConsignorACode1] = useState("");
    const handleChangeConsignorACode1 = (event) => {
        setConsignorACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ConsignorName1, setConsignorName1] = useState("");
    const [ConsignorCode2, setConsignorCode2] = useState("");
    const handleChangeConsignorCode2 = (event) => {
        setConsignorCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ConsignorACode2, setConsignorACode2] = useState("");
    const handleChangeConsignorACode2 = (event) => {
        setConsignorACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ConsignorName2, setConsignorName2] = useState("");
    
    const onKeyDownConsignorCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getConsignorData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('consignor_a_code1').focus();
                }else{
                    document.getElementById('consignor_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('consignor_a_code2').focus();
                }else{
                    document.getElementById('dis_code1').focus();
                }
            }
        }
    };
    const getConsignorData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ConsignorACode1;
            }else{
                code = ConsignorCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ConsignorACode2;
            }else{
                code = ConsignorCode2;
                acode = value;
            }
        }
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['consignor_code'] = code;
            if(acode != "") {
                data['consignor_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstConsignorEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setConsignorData(t_data[0],param1);
                }else{
                    setConsignorData([],param1);
                }
            })
            .catch((error) => {
            });
        }else{
            setConsignorData([],param1);
        }
    }
    const setConsignorData = (data,param) => {
        if(data['consignor_code']) {
            if(param == 1) {
                setConsignorCode1(data['consignor_code']);
                setConsignorACode1(data['consignor_a_code']);
                setConsignorName1(data['consignor_name']);
            }else{
                setConsignorCode2(data['consignor_code']);
                setConsignorACode2(data['consignor_a_code']);
                setConsignorName2(data['consignor_name']);
            }
        }else{
            if(param == 1) {
                setConsignorCode1("");
                setConsignorACode1("");
                setConsignorName1("");
            }else{
                setConsignorCode2("");
                setConsignorACode2("");
                setConsignorName2("");
            }
        }
    }
    // Carrier ----------------------------------------------------------
    const [CarrierCode1, setCarrierCode1] = useState("");
    const handleChangeCarrierCode1 = (event) => {
        setCarrierCode1(useJwt.validateNum(event.target.value,6));
    };
    const [CarrierACode1, setCarrierACode1] = useState("");
    const handleChangeCarrierACode1 = (event) => {
        setCarrierACode1(useJwt.validateNum(event.target.value,3));
    };
    const [CarrierName1, setCarrierName1] = useState("");
    const [CarrierCode2, setCarrierCode2] = useState("");
    const handleChangeCarrierCode2 = (event) => {
        setCarrierCode2(useJwt.validateNum(event.target.value,6));
    };
    const [CarrierACode2, setCarrierACode2] = useState("");
    const handleChangeCarrierACode2 = (event) => {
        setCarrierACode2(useJwt.validateNum(event.target.value,3));
    };
    const [CarrierName2, setCarrierName2] = useState("");
    const onKeyDownCarrierCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getCarrierData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('carrier_a_code1').focus();
                }else{
                    document.getElementById('carrier_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('carrier_a_code2').focus();
                }else{
                    document.getElementById('type1').focus();
                }
            }
        }
    };
    const getCarrierData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = CarrierACode1;
            }else{
                code = CarrierCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = CarrierACode2;
            }else{
                code = CarrierCode2;
                acode = value;
            }
        }
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = code;
            if(acode != "") {
                data['carrier_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setCarrierData(t_data[0],param1);
                }else{
                    setCarrierData([],param1);
                }
            })
            .catch((error) => {
            });
        }else{
            setCarrierData([],param1);
        }
    }
    const setCarrierData = (data,param) => {
        if(data['carrier_code']) {
            if(param == 1) {
                setCarrierCode1(data['carrier_code']);
                setCarrierACode1(data['carrier_a_code']);
                setCarrierName1(data['carrier_name']);
            }else{
                setCarrierCode2(data['carrier_code']);
                setCarrierACode2(data['carrier_a_code']);
                setCarrierName2(data['carrier_name']);
            }
        }else{
            if(param == 1) {
                setCarrierCode1("");
                setCarrierACode1("");
                setCarrierName1("");
            }else{
                setCarrierCode2("");
                setCarrierACode2("");
                setCarrierName2("");
            }
        }
    }
    // Distination -----------------------------------------------------------
    const [DistinationCode1, setDistinationCode1] = useState("");
    const handleChangeDistinationCode1 = (event) => {
        setDistinationCode1(useJwt.validateNum(event.target.value,6));
    };
    const [DistinationACode1, setDistinationACode1] = useState("");
    const handleChangeDistinationACode1 = (event) => {
        setDistinationACode1(useJwt.validateNum(event.target.value,3));
    };
    const [DistinationName1, setDistinationName1] = useState("");
    const [DistinationCode2, setDistinationCode2] = useState("");
    const handleChangeDistinationCode2 = (event) => {
        setDistinationCode2(useJwt.validateNum(event.target.value,6));
    };
    const [DistinationACode2, setDistinationACode2] = useState("");
    const handleChangeDistinationACode2 = (event) => {
        setDistinationACode2(useJwt.validateNum(event.target.value,3));
    };
    const [DistinationName2, setDistinationName2] = useState("");
    
    const onKeyDownDistinationCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getDistinationData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('dis_a_code1').focus();
                }else{
                    document.getElementById('dis_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('dis_a_code2').focus();
                }else{
                    document.getElementById('carrier_code1').focus();
                }
            }
        }
    };
    const getDistinationData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = DistinationACode1;
            }else{
                code = DistinationCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = DistinationACode2;
            }else{
                code = DistinationCode2;
                acode = value;
            }
        }
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = code;
            if(acode != "") {
                data['distination_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setDistinationData(t_data[0],param1);
                }else{
                    setDistinationData([],param1);
                }
            })
            .catch((error) => {
            });
        }else{
            setDistinationData([],param1);
        }
    }

    const setDistinationData = (data,param) => {
        if(data['distination_code']) {
            if(param == 1) {
                setDistinationCode1(data['distination_code']);
                setDistinationACode1(data['distination_a_code']);
                setDistinationName1(data['distination_name']);
            }else{
                setDistinationCode2(data['distination_code']);
                setDistinationACode2(data['distination_a_code']);
                setDistinationName2(data['distination_name']);
            }
        }else{
            if(param == 1) {
                setDistinationCode1("");
                setDistinationACode1("");
                setDistinationName1("");
            }else{
                setDistinationCode2("");
                setDistinationACode2("");
                setDistinationName2("");
            }
        }
    }
    // print type ------------------------------------------------
    const [PrintType1, setPrintType1] = useState(1);
    const handleChangePrintType1 = (event) => {
        setPrintType1(event);
    };
    const [PrintType2, setPrintType2] = useState(2);
    const handleChangePrintType2 = (event) => {
        setPrintType2(event);
    };
    const [PrintType3, setPrintType3] = useState(3);
    const handleChangePrintType3 = (event) => {
        setPrintType3(event);
    };
    const PrintTypeData = [ { value:'0', label:'送り状(控)' }, { value:'1', label:'出庫指示書' }
        , { value:'2', label:'送り状' }, { value:'3', label:'受領書' }];
    // invoice type data ------------------------------------------
    const InviceTypeData = [ { value:'0', label:'上段' }, { value:'1', label:'中段' }, { value:'2', label:'下段' }];
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_daily_flag_16'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        data['start_slip_date'] = StartDate;
        data['end_slip_date'] = EndDate;
        if(SlipNo1 != "") {
            data['start_slip_no'] = SlipNo1;
        }
        if(SlipNo2 != "") {
            data['end_slip_no'] = SlipNo2;
        }

        if(QuestionNo1 != "") {
            data['start_question_no'] = QuestionNo1;
        }
        if(QuestionNo2 != "") {
            data['end_question_no'] = QuestionNo2;
        }
        
        if(ShipperCode1 != "") {
            data['start_shipper_code'] = ShipperCode1;
        }
        if(ShipperCode2 != "") {
            data['end_shipper_code'] = ShipperCode2;
        }
        if(ShipperACode1 != "") {
            data['start_shipper_a_code'] = ShipperACode1;
        }
        if(ShipperACode2 != "") {
            data['end_shipper_a_code'] = ShipperACode2;
        }

        if(ConsignorCode1 != "") {
            data['start_consignor_code'] = ConsignorCode1;
        }
        if(ConsignorCode2 != "") {
            data['end_consignor_code'] = ConsignorCode2;
        }
        if(ConsignorACode1 != "") {
            data['start_consignor_a_code'] = ConsignorACode1;
        }
        if(ConsignorACode2 != "") {
            data['end_consignor_a_code'] = ConsignorACode2;
        }

        if(CarrierCode1 != "") {
            data['start_carrier_code'] = CarrierCode1;
        }
        if(CarrierCode2 != "") {
            data['end_carrier_code'] = CarrierCode2;
        }
        if(CarrierACode1 != "") {
            data['start_carrier_a_code'] = CarrierACode1;
        }
        if(CarrierACode2 != "") {
            data['end_carrier_a_code'] = CarrierACode2;
        }
        
        if(DistinationCode1 != "") {
            data['start_distination_code'] = DistinationCode1;
        }
        if(DistinationCode2 != "") {
            data['end_distination_code'] = DistinationCode2;
        }
        if(DistinationACode1 != "") {
            data['start_distination_a_code'] = DistinationACode1;
        }
        if(DistinationACode2 != "") {
            data['end_distination_a_code'] = DistinationACode2;
        }

        csloading(true);
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getInvoiceItemsEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            if(result.length>0){
                gridMainTable(result);
                setOpend(true);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                gridMainTable([]);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
        
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }
    const [mainTableData, setMainTableData] = useState([]);
    const [totalData, setTotalData] = useState([]);

    const gridMainTable = (result) => {
        let data = result;
        setMainTableData(data);
        initTotalData(data);

        // for pagenation
        setItemOffset(0);
        const endOffset = 0 + itemsPerPage;
        setCurrentItems(data.slice(0, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }
    const initTotalData = (result) => {
        let totalAry = [];
        result.map((data, index) => {
            let row = {};
            row['total_num'] = 0;
            row['total_weight'] = 0;
            row['total_volume'] = 0;
            data.map((data1, index1) => {
                if(data1['item_num'] != null && data1['item_num']>0) {
                    row['total_num'] += (data1['item_num']*1);
                }
                if(data1['item_num'] != null && data1['item_num']>0 && data1['unit_weight'] != null && data1['unit_weight']>0) {
                    row['total_weight'] += (data1['item_num']*data1['unit_weight']);
                }
                if(data1['item_num'] != null && data1['item_num']>0 && data1['unit_volume'] != null && data1['unit_volume']>0) {
                    row['total_volume'] += (data1['item_num']*data1['unit_volume']);
                }
            });
            totalAry.push(row);
        });
        setTotalData(totalAry);
    }
    // starts pagenation code -----------------------------------------
    const itemsPerPage = 1;
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % mainTableData.length;
        // console.log( `User requested page number ${event.selected}, which is offset ${newOffset}` );
        setItemOffset(newOffset);
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(mainTableData.slice(newOffset, endOffset));
    };
    // ends pagenation code ---------------------------------------------
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------- Consignor Modal ---------------------------------
    const [showModalConsignor, setShowModalConsignor] = useState(false);
    const [ConsignorSearchValue, setConsignorSearchValue] = useState("");
    const [ConsignorSearchValue2, setConsignorSearchValue2] = useState("");
    const [ConsignorTableData, setConsignorTableData] = useState([]);
    const loadConsignorModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ConsignorCode1;
        let acode = ConsignorACode1;
        if(param == 2) {
            code = ConsignorCode2;
            acode = ConsignorACode2;
        }
        setConsignorSearchValue(code);
        setConsignorSearchValue2(acode);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['consignor_code'] = code;
            if(acode != "") {
                data['consignor_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstConsignorEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setConsignorTableData(response.data.data);
                setShowModalConsignor(true);
            })
            .catch((error) => {
            });
        }else{
            setConsignorTableData([]);
            setShowModalConsignor(true);
        }
    }
    // ------------------------------- Carrier Modal ------------------------------------
    const [showModalCarrier, setShowModalCarrier] = useState(false);
    const [CarrierSearchValue, setCarrierSearchValue] = useState("");
    const [CarrierSearchValue2, setCarrierSearchValue2] = useState("");
    const [CarrierTableData, setCarrierTableData] = useState([]);
    const loadCarrierModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = CarrierCode1;
        let acode = CarrierACode1;
        if(param == 2) {
            code = CarrierCode2;
            acode = CarrierACode2;
        }
        setCarrierSearchValue(code);
        setCarrierSearchValue2(acode);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = code;
            if(acode != "") {
                data['carrier_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setCarrierTableData(response.data.data);
                setShowModalCarrier(true);
            })
            .catch((error) => {
            });
        }else{
            setCarrierTableData([]);
            setShowModalCarrier(true);
        }
    }
    // ------------------------------- Distination Modal --------------------------------
    const [showModalDistination, setShowModalDistination] = useState(false);
    const [DistinationSearchValue, setDistinationSearchValue] = useState("");
    const [DistinationSearchValue2, setDistinationSearchValue2] = useState("");
    const [DistinationTableData, setDistinationTableData] = useState([]);
    const loadDistinationModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = DistinationCode1;
        let acode = DistinationACode1;
        if(param == 2) {
            code = DistinationCode2;
            acode = DistinationACode2;
        }
        setDistinationSearchValue(code);
        setDistinationSearchValue2(acode);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = code;
            if(acode != "") {
                data['distination_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setDistinationTableData(response.data.data);
                setShowModalDistination(true);
            })
            .catch((error) => {
            });
        }else{
            setDistinationTableData([]);
            setShowModalDistination(true);
        }
    }
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        setCurrentItems(mainTableData);
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText, setCurrentItems, mainTableData]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

    
    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type2').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type3').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onSlipNoKeyDown = (e, param) =>{
        if(e.keyCode == 13){
            if(param == 1){
                document.getElementById('slipNo2').focus();
            }else if(param == 2){
                document.getElementById('question1').focus();
            }else if(param == 3){
                document.getElementById('question2').focus();
            }else if(param == 4){
                document.getElementById('shipper_code1').focus();
            }
        }
    }
    
    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_9 = [gstyle.cs_col, gstyle.cs_col_9];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap4 = [gstyle.p00300015];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ConsignorModal show={showModalConsignor} onClose={()=>setShowModalConsignor(false)} width={'70%'}
                SearchValue={ConsignorSearchValue} onChange={setConsignorSearchValue} 
                SearchValue2={ConsignorSearchValue2} onChange2={setConsignorSearchValue2}
                tableData={ConsignorTableData} setTableData={setConsignorTableData}
                target={setConsignorData} targetParam={TargetParam} />
            <CarrierModal show={showModalCarrier} onClose={()=>setShowModalCarrier(false)} width={'70%'}
                SearchValue={CarrierSearchValue} onChange={setCarrierSearchValue} 
                SearchValue2={CarrierSearchValue2} onChange2={setCarrierSearchValue2}
                tableData={CarrierTableData} setTableData={setCarrierTableData}
                target={setCarrierData} targetParam={TargetParam} />
            <DistinationModal show={showModalDistination} onClose={()=>setShowModalDistination(false)} width={'70%'}
                SearchValue={DistinationSearchValue} onChange={setDistinationSearchValue} 
                SearchValue2={DistinationSearchValue2} onChange2={setDistinationSearchValue2}
                tableData={DistinationTableData} setTableData={setDistinationTableData}
                target={setDistinationData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>送り状発行【標準】（BP2013）</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={StartDate} onChange={handleChangeStartDate} id='date2' focusId='date3' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={EndDate} onChange={handleChangeEndDate} id='date3' focusId='slipNo1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 伝票 No, 問合せNo */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始伝票 No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        value={SlipNo1} onChange={handleChangeSlipNo1} id='slipNo1' 
                                        onKeyDown={(e)=>onSlipNoKeyDown(e,1)} 
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了伝票 No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e)=>onSlipNoKeyDown(e,2)}
                                        value={SlipNo2} onChange={handleChangeSlipNo2} id='slipNo2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始問合せNo</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e)=>onSlipNoKeyDown(e,3)} id='question1'
                                        value={QuestionNo1} onChange={handleChangeQuestionNo1} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了問合せNo</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e)=>onSlipNoKeyDown(e,4)} id='question2'
                                        value={QuestionNo2} onChange={handleChangeQuestionNo2} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 荷送人 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷送人</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                        value={ConsignorCode1} id='consignor_code1'
                                        onKeyDown={(e) => onKeyDownConsignorCode(e,1,1)}
                                        onChange={(e) => handleChangeConsignorCode1(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={ConsignorACode1} id='consignor_a_code1'
                                        onKeyDown={(e) => onKeyDownConsignorCode(e,1,2)}
                                        onChange={(e) => handleChangeConsignorACode1(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ConsignorName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadConsignorModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷送人</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                        value={ConsignorCode2} id='consignor_code2'
                                        onKeyDown={(e) => onKeyDownConsignorCode(e,2,1)}
                                        onChange={(e) => handleChangeConsignorCode2(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={ConsignorACode2} id='consignor_a_code2'
                                        onKeyDown={(e) => onKeyDownConsignorCode(e,2,2)}
                                        onChange={(e) => handleChangeConsignorACode2(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ConsignorName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadConsignorModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 届け先 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始届け先</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={DistinationCode1} id='dis_code1'
                                            onKeyDown={(e) => onKeyDownDistinationCode(e,1,1)}
                                            onChange={(e) => handleChangeDistinationCode1(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={DistinationACode1} id='dis_a_code1'
                                        onKeyDown={(e) => onKeyDownDistinationCode(e,1,2)}
                                        onChange={(e) => handleChangeDistinationACode1(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={DistinationName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadDistinationModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了届け先</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={DistinationCode2} id='dis_code2'
                                            onKeyDown={(e) => onKeyDownDistinationCode(e,2,1)}
                                            onChange={(e) => handleChangeDistinationCode2(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={DistinationACode2} id='dis_a_code2'
                                        onKeyDown={(e) => onKeyDownDistinationCode(e,2,2)}
                                        onChange={(e) => handleChangeDistinationACode2(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={DistinationName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadDistinationModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 運送業者 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始運送業者</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={CarrierCode1} id='carrier_code1'
                                            onKeyDown={(e) => onKeyDownCarrierCode(e,1,1)}
                                            onChange={(e) => handleChangeCarrierCode1(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={CarrierACode1} id='carrier_a_code1'
                                        onKeyDown={(e) => onKeyDownCarrierCode(e,1,2)}
                                        onChange={(e) => handleChangeCarrierACode1(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={CarrierName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCarrierModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了運送業者</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={CarrierCode2} id='carrier_code2'
                                            onKeyDown={(e) => onKeyDownCarrierCode(e,2,1)}
                                            onChange={(e) => handleChangeCarrierCode2(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={CarrierACode2} id='carrier_a_code2'
                                        onKeyDown={(e) => onKeyDownCarrierCode(e,2,2)}
                                        onChange={(e) => handleChangeCarrierACode2(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={CarrierName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCarrierModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 上段･中段･下段 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>上段</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder=""
                                            optionFilterProp="children"
                                            onChange={handleChangePrintType1}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={PrintTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>中段</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder=""
                                            optionFilterProp="children"
                                            onChange={handleChangePrintType2}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={PrintTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>下段</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder=""
                                            optionFilterProp="children"
                                            onChange={handleChangePrintType3}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={PrintTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        {  currentItems.map((data,index) => (
                            <div key={index} className={style.invoice_item_wrap}>
                                {
                                    InviceTypeData.map((data1,index1) => (
                                        <div key={index1}>
                                            {/* Title */}
                                            <div className={style_row.join(" ")}>
                                                <div className={style_col_12.join(" ")}>
                                                    <div className={style.nw_title_wrap}>
                                                        <h2>{
                                                            index1 == 0 ?PrintTypeData[PrintType1]['value']
                                                            :index1 == 1 ?PrintTypeData[PrintType2]['value']
                                                            :PrintTypeData[PrintType3]['value']
                                                        }</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 出庫日, 伝票No, 届け先, 荷送人 */}
                                            <div className={style_row.join(" ")}>
                                                <div className={style_col_3.join(" ")}>
                                                    <div className={style_input_wrap.join(" ")}>
                                                        <label className={style_label_wrap.join(" ")}>
                                                            <div className={gstyle.cs_flex}>
                                                                <span className={style.cs_span_2}>出庫日</span>
                                                                <span className={style.cs_span_2}>{useJwt.formatDate2(new Date(data[0]['slip_date']))}</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className={style_input_wrap.join(" ")}>
                                                        <label className={style_label_wrap.join(" ")}>
                                                            <div className={gstyle.cs_flex}>
                                                                <span className={style.cs_span_2}>伝票No</span>
                                                                <span className={style.cs_span_2}>{data[0]['slip_no']}</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className={style_col_9.join(" ")}>
                                                    <div className={style_input_wrap4.join(" ")}>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <th className={gstyle.w30px}>届け先</th>
                                                                    <td className={style.invoice_txt_left}>
                                                                        <p>
                                                                            {data[0]['distination_address_1']} {data[0]['distination_address_2']} {data[0]['distination_address_3']}
                                                                        </p>
                                                                        <p>( 〒{data[0]['distination_zip']} )</p>
                                                                        <p>{data[0]['distination_name']}</p>
                                                                        <p>
                                                                            <span>TEL : {data[0]['distination_tel']}</span>
                                                                            <span className={style.cs_span_4}>FAX : {data[0]['distination_fax']}</span>
                                                                        </p>
                                                                    </td>
                                                                    <th className={gstyle.w30px}>荷送人</th>
                                                                    <td className={style.invoice_txt_left}>
                                                                        <p>
                                                                            {data[0]['consignor_address_1']} {data[0]['consignor_address_2']} {data[0]['consignor_address_3']}
                                                                        </p>
                                                                        <p>( 〒{data[0]['consignor_zip']} )</p>
                                                                        <p>{data[0]['consignor_name']}</p>
                                                                        <p>
                                                                            <span>TEL : {data[0]['consignor_tel']}</span>
                                                                            <span className={style.cs_span_4}>FAX : {data[0]['consignor_fax']}</span>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* item table */}
                                            <div className={style_row.join(" ")}>
                                                <div className={style_col_12.join(" ")}>
                                                    <div className={style_input_wrap3.join(" ")}>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>品番・品名・規格</th>
                                                                    <th>数　量</th>
                                                                    <th>単位</th>
                                                                    <th>単位重量 / 容積</th>
                                                                    <th>重量・容積</th>
                                                                    <th>備考・倉庫</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {  data.map((data1,index1) => (
                                                                    <tr key={index1}>
                                                                        <td>
                                                                            <p className={style.cs_min_h_20}>{data1['item_code']} - {data1['item_a_code']}</p>
                                                                            <p className={style.cs_min_h_20}>{data1['item_name']}</p>
                                                                        </td>
                                                                        <td className={style.td_right}>{data1['item_num']!=null&&data1['item_num']>0?useJwt.numberWithCommas(data1['item_num']):''}</td>
                                                                        <td>{data1['item_unit']}</td>
                                                                        <td className={style.td_right}>
                                                                            <p className={style.cs_min_h_20}>
                                                                                {data1['unit_weight']!=null&&data1['unit_weight']>0?useJwt.numberWithCommas(data1['unit_weight']):'-'}
                                                                            </p>
                                                                            <p className={style.cs_min_h_20}>
                                                                                {data1['unit_volume']!=null&&data1['unit_volume']>0?useJwt.numberWithCommas(data1['unit_volume']):'-'}
                                                                            </p>
                                                                        </td>
                                                                        <td className={style.td_right}>
                                                                            <p className={style.cs_min_h_20}>
                                                                                {data1['unit_weight']!=null&&data1['unit_weight']>0&&data1['item_num']!=null&&data1['item_num']>0?
                                                                                useJwt.numberWithCommas(data1['unit_weight']*data1['item_num']):'-'}
                                                                            </p>
                                                                            <p className={style.cs_min_h_20}>
                                                                                {data1['unit_volume']!=null&&data1['unit_volume']>0&&data1['item_num']!=null&&data1['item_num']>0?
                                                                                useJwt.numberWithCommas(data1['unit_volume']*data1['item_num']):'-'}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p className={style.cs_min_h_20}>{data1['remark']}</p>
                                                                            <p className={style.cs_min_h_20}>{data1['warehouse_name']}</p>
                                                                        </td>
                                                                    </tr>
                                                                )) }
                                                                
                                                                <tr>
                                                                    <td className={style.border_lb_none}></td>
                                                                    <td className={style.td_right}>{totalData[index]['total_num']>0?useJwt.numberWithCommas(totalData[index]['total_num']):''}</td>
                                                                    <th colSpan={2}>合　計</th>
                                                                    <td className={style.td_right}>
                                                                        <p className={style.cs_min_h_20}>
                                                                            {totalData[index]['total_weight']>0?useJwt.numberWithCommas(totalData[index]['total_weight']):'-'}
                                                                        </p>
                                                                        <p className={style.cs_min_h_20}>
                                                                            {totalData[index]['total_volume']>0?useJwt.numberWithCommas(totalData[index]['total_volume']):'-'}
                                                                        </p>
                                                                    </td>
                                                                    <td className={style.border_rb_none}></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 摘 要, 運送便, 出庫元 */}
                                            <div className={style_row.join(" ")}>
                                                <div className={style_col_6.join(" ")}>
                                                    <div className={style_input_wrap3.join(" ")}>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <th className={gstyle.w100px}>摘 要</th>
                                                                    <td className={style.td_txt_left}>
                                                                        <p>{data[0]['summary_name']}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th className={gstyle.w100px}>運送便</th>
                                                                    <td className={style.td_txt_left}>
                                                                        <p>{data[0]['carrier_name']} ({data[0]['carrier_stool_code']})</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className={style_col_6.join(" ")}>
                                                    <div className={style_input_wrap.join(" ")}>
                                                        <label className={style_label_wrap.join(" ")}>
                                                            <div className={gstyle.cs_flex}>
                                                                <span className={style.cs_span_2}>出庫元:</span>
                                                                <span className={style.cs_span_2}>{data[0]['shipper_name']}</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className={style_input_wrap.join(" ")}>
                                                        <label className={style_label_wrap.join(" ")}>
                                                            <div className={gstyle.cs_flex}>
                                                                <span className={style.cs_span_5}>
                                                                    {data[0]['shipper_address_1']}
                                                                </span>
                                                                <span className={style.cs_span_4}>
                                                                    {data[0]['shipper_address_2']}
                                                                </span>
                                                                <span className={style.cs_span_4}>
                                                                    {data[0]['shipper_address_3']}
                                                                </span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className={style_input_wrap.join(" ")}>
                                                        <label className={style_label_wrap.join(" ")}>
                                                            <div className={gstyle.cs_flex}>
                                                                <span className={style.cs_span_5}>TEL:</span>
                                                                <span className={style.cs_span_4}>
                                                                    {data[0]['shipper_tel']}
                                                                </span>
                                                                <span className={style.cs_span_4}>FAX:</span>
                                                                <span className={style.cs_span_4}>
                                                                    {data[0]['shipper_fax']}
                                                                </span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )) }
                    </div>
                    <div className={style.invoice_item_wrap2}>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style.nw_pagenation_wrap}>
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <ReactToPrint
                                        content={reactToPrintContent}
                                        documentTitle={'送り状発行【標準】（BP2013）_'+useJwt.formatDateTime(new Date())}
                                        onAfterPrint={handleAfterPrint}
                                        onBeforeGetContent={handleOnBeforeGetContent}
                                        onBeforePrint={handleBeforePrint}
                                        removeAfterPrint
                                        trigger={reactToPrintTrigger}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default InvoiceItemList;