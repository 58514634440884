import style from './LinkButton.module.css'
import { Link } from 'react-router-dom'

const LinkButton = ({ link, title, underTitle }) => {
	return (
		<>
			<Link to={link} className={style.link}>
				<p>{title}</p>
				<p className={style.under_text}>{underTitle}</p>
			</Link>
		</>
	);
};

export default LinkButton;