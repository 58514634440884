/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './BillingQuantityList.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import CategoryModal from '../../../components/common/modal/CategoryModal';
import ItemTypeModal from '../../../components/common/modal/ItemTypeModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import {Select} from 'antd'

const BillingQuantityList = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    
    
    // slip date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // date type -----------------------------------------------
    const [DateType, setDateType] = useState(0);
    const handleChangeDateType = (event) => {
        setDateType(event);
    };
    const DateTypeData = [ { value:'0', label:'締日指定' }, { value:'1', label:'期締範囲指定' }, ];
    // deadline day ---------------------------------------------
    const [DeadLineDay, setDeadLineDay] = useState(useJwt.formatDate(new Date()));
    const handleChangeDeadLineDay = (date) => {
        setDeadLineDay(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
    };
    const OrderTypeData = [ { value:'0', label:'荷主順' }, { value:'1', label:'分類①' },
    { value:'2', label:'分類②' }, { value:'3', label:'分類③' }, { value:'4', label:'品種順' } ];
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('item_code1').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ShipperACode1;
            }else{
                code = ShipperCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ShipperACode2;
            }else{
                code = ShipperCode2;
                acode = value;
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // item ----------------------------------------------------
    const [ItemCode1, setItemCode1] = useState("");
    const handleChangeItemCode1 = (event) => {
        setItemCode1(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode1, setItemACode1] = useState("");
    const handleChangeItemACode1 = (event) => {
        setItemACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName1, setItemName1] = useState("");
    const [ItemCode2, setItemCode2] = useState("");
    const handleChangeItemCode2 = (event) => {
        setItemCode2(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode2, setItemACode2] = useState("");
    const handleChangeItemACode2 = (event) => {
        setItemACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName2, setItemName2] = useState("");
    const onKeyDownItemCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getItemData(event.target.value,param1,param2);
            event.preventDefault();
            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('item_a_code1').focus();
                }else{
                    document.getElementById('item_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('item_a_code2').focus();
                }else{
                    document.getElementById('item_type_code1').focus();
                }
            }
        }
    };
    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ItemACode1;
            }else{
                code = ItemCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ItemACode2;
            }else{
                code = ItemCode2;
                acode = value;
            }
        }

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result,param1);
                    }else{
                        setItemData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([],param1);
        }
    }
    const setItemData = (data,param) => {
        if(data['item_code']) {
            if(param == 1) {
                setItemCode1(data['item_code']);
                setItemACode1(data['item_a_code']);
                setItemName1(data['item_name']);
            }else{
                setItemCode2(data['item_code']);
                setItemACode2(data['item_a_code']);
                setItemName2(data['item_name']);
            }
        }else{
            if(param == 1) {
                setItemCode1("");
                setItemACode1("");
                setItemName1("");
            }else{
                setItemCode2("");
                setItemACode2("");
                setItemName2("");
            }
        }
    }
    // category --------------------------------------------------------
    const [CategoryCode1, setCategoryCode1] = useState("");
    const [CategoryName1, setCategoryName1] = useState("");
    const handleChangeCategoryCode1 = (event) => {
        setCategoryCode1(useJwt.validateNum(event.target.value,3));
    };
    const [CategoryCode2, setCategoryCode2] = useState("");
    const [CategoryName2, setCategoryName2] = useState("");
    const handleChangeCategoryCode2 = (event) => {
        setCategoryCode2(useJwt.validateNum(event.target.value,3));
    };
    const onKeyDownCategoryCode =(event,param) => {
        if(event.keyCode === 13) { // enter key
            getCategoryData(event.target.value,param);
            event.preventDefault();
            if(param == 1){
                document.getElementById('category_code2').focus();
            }else{
                document.getElementById('item_type_code1').focus();
            }
        }
    };
    const getCategoryData = (code,param) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let t_data = response.data;
                if(t_data.length > 0) {
                    setCategoryData(t_data[0],param);
                }else{
                    setCategoryData([],param);
                }
            })
            .catch((error) => {
            });
        }else{
            setCategoryData([],param);
        }
    }
    const setCategoryData = (data,param) => {
        if(data['category_code']) {
            if(param == 1) {
                setCategoryCode1(data['category_code']);
                setCategoryName1(data['category_name']);
            }else{
                setCategoryCode2(data['category_code']);
                setCategoryName2(data['category_name']);
            }
        }else{
            if(param == 1) {
                setCategoryCode1("");
                setCategoryName1("");
            }else{
                setCategoryCode2("");
                setCategoryName2("");
            }
        }
    }
    // item type ---------------------------------------------------
    const [ItemTypeCode1, setItemTypeCode1] = useState("");
    const handleChangeItemTypeCode1 = (event) => {
        setItemTypeCode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemTypeName1, setItemTypeName1] = useState("");
    const [ItemTypeCode2, setItemTypeCode2] = useState("");
    const handleChangeItemTypeCode2 = (event) => {
        setItemTypeCode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemTypeName2, setItemTypeName2] = useState("");
    const onKeyDownItemTypeCode =(event,param) => {
        if(event.keyCode === 13) { // enter key
            getItemTypeData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('item_type_code2').focus();
            }else{
                document.getElementById('type3').focus();
            }
        }
    };
    const getItemTypeData = (code,param) => {
        if(code != "") {
            const data_r = {
                userID: userData['id'],
                item_type_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
            .post(url, data_r, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                let t_data = response.data.data;
                if(t_data.length > 0) {
                    setItemTypeData(t_data[0],param);
                }else{
                    setItemTypeData([],param);
                }
            })
            .catch((error) => {
            });
        }else{
            setItemTypeData([],param);
        }
    }
    const setItemTypeData = (data,param) => {
        if(data['item_type_code']) {
            if(param == 1) {
                setItemTypeCode1(data['item_type_code']);
                setItemTypeName1(data['item_type_name']);
            }else{
                setItemTypeCode2(data['item_type_code']);
                setItemTypeName2(data['item_type_name']);
            }
        }else{
            if(param == 1) {
                setItemTypeCode1("");
                setItemTypeName1("");
            }else{
                setItemTypeCode2("");
                setItemTypeName2("");
            }
        }
    }
    // sub code type -----------------------------------------------
    const [SubCodeType, setSubCodeType] = useState(0);
    const handleChangeSubCodeType = (event) => {
        setSubCodeType(event);
    };
    // rot type -----------------------------------------------
    const [RotType, setRotType] = useState(0);
    const handleChangeRotType = (event) => {
        setRotType(event);
    };
    // filter type -----------------------------------------------
    const [FilterType, setFilterType] = useState(0);
    const handleChangeFilterType = (event) => {
        setFilterType(event);
    };
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_invoice_flag_6'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        if(DateType == 0) {
            data['deadline_day'] = useJwt.getDay(DeadLineDay);
            data['deadline_year_month'] = useJwt.getYearMonth(DeadLineDay);
        }else{
            // data['start_slip_date'] = StartDate;
            // data['end_slip_date'] = EndDate;
            data['deadline_year_month'] = useJwt.getYearMonth(EndDate);
        }
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        data['sub_code_type'] = SubCodeType;
        data['prev_flg'] = 0;
        
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMonthInvoiceQtyEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let itemData = response.data.data.itemData;
            // console.log("itemData", itemData);
            if(itemData.length>0){
                getItemRemainNum(itemData);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);
    const getItemRemainNum = (result) => {
        let data = {};
        data['userID'] = userData['id'];
        if(DateType == 0) {
            data['deadline_day'] = useJwt.getDay(DeadLineDay);
            data['deadline_year_month'] = useJwt.getPreviousMonth(DeadLineDay);
        }else{
            data['deadline_year_month'] = useJwt.getPreviousMonth(EndDate);
        }
        data['start_shipper_code'] = ShipperCode1;
        data['end_shipper_code'] = ShipperCode2;
        data['start_shipper_a_code'] = ShipperACode1;
        data['end_shipper_a_code'] = ShipperACode2;
        data['sub_code_type'] = SubCodeType;
        data['prev_flg'] = 1;
        
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMonthInvoiceQtyEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let prev_result = response.data.data.itemData;
            gridMainTable(result,prev_result);
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
    }

    const gridMainTable = (result,prev_result) => {
        // console.log("result",result);
        // console.log("prev_result",prev_result);
        let data = [];
        let itemCodes = [];
        let header_info = [];
        for(var i=0; i<result.length; i++) {
            let filter_code = ''+result[i]['shipper_code'];
            if(!itemCodes.includes(filter_code)){
                itemCodes.push(filter_code);
                let row = {};
                row['shipper_code'] = result[i]['shipper_code'];
                row['shipper_a_code'] = result[i]['shipper_a_code'];
                row['shipper_name'] = result[i]['shipper_name'];
                row['shipper_address_1'] = result[i]['shipper_address_1'];
                row['shipper_address_2'] = result[i]['shipper_address_2'];
                row['shipper_address_3'] = result[i]['shipper_address_3'];
                row['shipper_zip'] = result[i]['shipper_zip'];
                row['shipper_tel'] = result[i]['shipper_tel'];
                row['shipper_fax'] = result[i]['shipper_fax'];
                row['storage_fee_type'] = result[i]['storage_fee_type'];
                row['cargo_handling_fee_type'] = result[i]['cargo_handling_fee_type'];
                header_info.push(row);
            }
        }

        for(var i=0; i<itemCodes.length; i++) {
            let row = {};
            row['filter_code'] = itemCodes[i];
            row['header_info'] = header_info[i];
            row['item_info'] = [];
            row['freightage_info'] = [];
            let total_arrivalNum_1 = 0; 
            let total_arrivalNum_2 = 0; 
            let total_arrivalNum_3 = 0; 
            let total_leavingNum_1 = 0;
            let total_leavingNum_2 = 0;
            let total_leavingNum_3 = 0;
            let total_stockMoveNum_1 = 0;
            let total_stockMoveNum_2 = 0;
            let total_stockMoveNum_3 = 0;
            for(var j=0;j<result.length;j++) {
                let filter_code = ''+result[j]['shipper_code'];
                if(itemCodes[i] == filter_code) {
                    let tmp_row = result[j];

                    let prev_code = '' + tmp_row['shipper_code'] + '/' + tmp_row['item_code'];
                    if(SubCodeType == 1) {
                        prev_code += '/' + tmp_row['item_a_code'];
                    }
                    // 繰越数量
                    let prev_month_item_num = 0;
                    for(var k=0; k<prev_result.length; k++) {
                        let prev_filter_code = '' + prev_result[k]['shipper_code'] + '/' + prev_result[k]['item_code'];
                        if(SubCodeType == 1) {
                            prev_filter_code += '/' + prev_result[k]['item_a_code'];
                        }
                        if(prev_code == prev_filter_code) {
                            let prev_e_1 = prev_result[k]['item_ary']['arrivalNum_1'];
                            let prev_e_2 = prev_result[k]['item_ary']['arrivalNum_2'];
                            let prev_e_3 = prev_result[k]['item_ary']['arrivalNum_3'];
                            let prev_f_1 = prev_result[k]['item_ary']['leavingNum_1'];
                            let prev_f_2 = prev_result[k]['item_ary']['leavingNum_2'];
                            let prev_f_3 = prev_result[k]['item_ary']['leavingNum_3'];
                            prev_month_item_num += (1*prev_e_1+1*prev_e_2+1*prev_e_3-prev_f_1-prev_f_2-prev_f_3);
                        }
                    }
                    tmp_row['col_a'] = 1*prev_month_item_num;
                    // total 上期入庫数量
                    total_arrivalNum_1 += 1*tmp_row['item_ary']['arrivalNum_1'];
                    // 上期出庫数量
                    total_leavingNum_1 += 1*tmp_row['item_ary']['leavingNum_1'];
                    // 上期調整数量
                    total_stockMoveNum_1 += 1*tmp_row['item_ary']['stockMoveNum_1'];
                    // 中期入庫数量
                    total_arrivalNum_2 += 1*tmp_row['item_ary']['arrivalNum_2'];
                    // 中期出庫数量
                    total_leavingNum_2 += 1*tmp_row['item_ary']['leavingNum_2'];
                    // 中期調整数量
                    total_stockMoveNum_2 += 1*tmp_row['item_ary']['stockMoveNum_2'];
                    // 下期入庫数量
                    total_arrivalNum_3 += 1*tmp_row['item_ary']['arrivalNum_3'];
                    // 下期出庫数量
                    total_leavingNum_3 += 1*tmp_row['item_ary']['leavingNum_3'];
                    // 下期調整数量
                    total_stockMoveNum_3 += 1*tmp_row['item_ary']['stockMoveNum_3'];

                    row['item_info'].push(tmp_row);
                }
            }
            row['header_info']['total_arrivalNum_1'] = total_arrivalNum_1;
            row['header_info']['total_leavingNum_1'] = total_leavingNum_1;
            row['header_info']['total_stockMoveNum_1'] = total_stockMoveNum_1;
            row['header_info']['total_arrivalNum_2'] = total_arrivalNum_2;
            row['header_info']['total_leavingNum_2'] = total_leavingNum_2;
            row['header_info']['total_stockMoveNum_2'] = total_stockMoveNum_2;
            row['header_info']['total_arrivalNum_3'] = total_arrivalNum_3;
            row['header_info']['total_leavingNum_3'] = total_leavingNum_3;
            row['header_info']['total_stockMoveNum_3'] = total_stockMoveNum_3;
            data.push(row);
        }
        // console.log("data",data);
        setMainTableData(data);
        if(data.length>0){
            setOpend(true);
        }
    }
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemCode1;
        let acode = ItemACode1;
        if(param == 2) {
            code = ItemCode2;
            acode = ItemACode2;
        }
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    // ----------------------- Category Modal ------------------------------------------
    const [showModalCategory, setShowModalCategory] = useState(false);
    const [CategorySearchValue, setCategorySearchValue] = useState("");
    const [CategoryTableData, setCategoryTableData] = useState([]);
    const loadCategoryCodeModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = CategoryCode1;
        if(param==2){
            code = CategoryCode2;
        }
        setCategorySearchValue(code);
        if(code != ""){
            const data = {
                userID: userData['id'],
                category_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCategoryEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setCategoryTableData(response.data.data);
                setShowModalCategory(true);
            })
            .catch((error) => {
            });
        }else{
            setCategoryTableData([]);
            setShowModalCategory(true);
        }
    }
    // ----------------------------- item type modal -----------------------------------
    const [showModalItemType, setShowModalItemType] = useState(false);
    const [ItemTypeSearchValue, setItemTypeSearchValue] = useState("");
    const [ItemTypeTableData, setItemTypeTableData] = useState([]);
    const loadItemTypeModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemTypeCode1;
        if(param==2){
            code = ItemTypeCode2;
        }
        setItemTypeSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['item_type_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemTypeEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTypeTableData(response.data.data);
                setShowModalItemType(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTypeTableData([]);
            setShowModalItemType(true);
        }
    }
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);
    const [type5Open, setType5Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('date2')){
                    document.getElementById('date2').focus();
                }else{
                    document.getElementById('date3').focus();
                }
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            setTimeout(()=>{
                if(document.getElementById('shipper_code1'))
                    document.getElementById('shipper_code1').focus();
                else
                    document.getElementById('category_code1').focus();
            }, 10)
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type4').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onType4KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type5').focus();
            event.preventDefault();
        }
    }
    const onFocusType4 = (e, type) => {
        if(type == 1){
            setType4Open(true);
        }else{
            setType4Open(false);
        }
    }

    const onType5KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType5 = (e, type) => {
        if(type == 1){
            setType5Open(true);
        }else{
            setType5Open(false);
        }
    }
    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            case 4:
                setType4Open(false);
                break;
            case 5:
                setType5Open(false);
                break;
            default:
                break;
        }
    }

    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
            <CategoryModal show={showModalCategory} onClose={()=>setShowModalCategory(false)} width={'70%'}
                SearchValue={CategorySearchValue} onChange={setCategorySearchValue} 
                tableData={CategoryTableData} setTableData={setCategoryTableData}
                target={setCategoryData} targetParam={TargetParam} />
            <ItemTypeModal show={showModalItemType} onClose={()=>setShowModalItemType(false)} width={'70%'}
                SearchValue={ItemTypeSearchValue} onChange={setItemTypeSearchValue} 
                tableData={ItemTypeTableData} setTableData={setItemTypeTableData}
                target={setItemTypeData} targetParam={TargetParam} />    
            <div className={gstyle.border}>
                <p className={gstyle.text}>請求数量確認表</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 締日区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='type1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>締日区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="締日区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeDateType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={DateTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 締切年月日 */}
                    {DateType==0 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>締切年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={DeadLineDay} onChange={handleChangeDeadLineDay} id='date2' focusId='type2' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 年月日 */}
                    {DateType==1 && 
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={StartDate} onChange={handleChangeStartDate} id='date3' focusId='date4' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了年月日</span>
                                        <div className={style_date_wrap.join(" ")}>
                                            <InputDatepicker  value={EndDate} onChange={handleChangeEndDate} id='date4' focusId='type2' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 出力順位 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={OrderTypeData}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    {OrderType == 0 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始荷主</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                            value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                            <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                            value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ShipperName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了荷主</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                            value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                            <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                            value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ShipperName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 商品 */}
                    {OrderType == 0 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,1,1)} id='item_code1'
                                            value={ItemCode1} onChange={handleChangeItemCode1} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,1,2)} id='item_a_code1'
                                            value={ItemACode1} onChange={handleChangeItemACode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,2,1)} id='item_code2'
                                            value={ItemCode2} onChange={handleChangeItemCode2} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,2,2)} id='item_a_code2'
                                            value={ItemACode2} onChange={handleChangeItemACode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 分類 */}
                    {OrderType > 0 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始分類</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownCategoryCode(e,1)} id='category_code1'
                                                value={CategoryCode1} onChange={handleChangeCategoryCode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={CategoryName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCategoryCodeModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了分類</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'number'} className={style_input_100px.join(" ")} 
                                                onKeyDown={(e) => onKeyDownCategoryCode(e,2)} id='category_code2'
                                                value={CategoryCode2} onChange={handleChangeCategoryCode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={CategoryName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadCategoryCodeModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    { /* 品種 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始品種</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemTypeCode(e,1)} id='item_type_code1'
                                        value={ItemTypeCode1} onChange={handleChangeItemTypeCode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ItemTypeName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemTypeModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了品種</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemTypeCode(e,2)} id='item_type_code2'
                                        value={ItemTypeCode2} onChange={handleChangeItemTypeCode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ItemTypeName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemTypeModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  補助区分, 品番区分, 打出区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>補助区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="補助区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeSubCodeType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={[
                                                {value:'0', label:'一括'},
                                                {value:'1', label:'補助別'},
                                            ]}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>品番区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type4Open}
                                            onSelect={(e)=>onSelectData(e, 4)}
                                            placeholder="品番区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeRotType}
                                            onKeyDown={onType4KeyDown}
                                            onFocus={(e) => onFocusType4(e, 1)}
                                            onBlur={(e) => onFocusType4(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type4"
                                            options={[
                                                {value:'0', label:'品番集計'},
                                                {value:'1', label:'ロット別'},
                                            ]}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>打出区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type5Open}
                                            onSelect={(e)=>onSelectData(e, 5)}
                                            placeholder="打出区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeFilterType}
                                            onKeyDown={onType5KeyDown}
                                            onFocus={(e) => onFocusType5(e, 1)}
                                            onBlur={(e) => onFocusType5(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type5"
                                            options={[
                                                {value:'0', label:'発生又は在庫有り'},
                                                {value:'1', label:'発生有りのみ'},
                                                {value:'2', label:'在庫有りのみ'},
                                                {value:'3', label:'全部'},
                                            ]}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        {/* Title, 発行年月日 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_2.join(" ")}></div>
                            <div className={style_col_8.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>請求数量確認表</h2>
                                </div>
                            </div>
                            <div className={style_col_2.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {/* 出力順位 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_3}>
                                                [出力順位]:{OrderTypeData[OrderType]['value']}   
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {  mainTableData.map((data,index) => (
                            <div key={index}>
                                {/* header  */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {data['header_info']['shipper_code']}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className={style_input_wrap.join(" ")}>
                                            <label className={style_label_wrap.join(" ")}>
                                                <div className={gstyle.cs_flex}>
                                                    <span className={style.cs_span_3}>
                                                        {data['header_info']['shipper_name']}
                                                    </span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* table */}
                                <div className={style_row.join(" ")}>
                                    <div className={style_col_12.join(" ")}>
                                        <div className={style_input_wrap3.join(" ")}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th rowSpan={2}>品名・規格</th>
                                                        <th rowSpan={2}>繰越数量</th>
                                                        <th>上期入庫数量</th>
                                                        <th>上期出庫数量</th>
                                                        <th>中期入庫数量</th>
                                                        <th>中期出庫数量</th>
                                                        <th>下期入庫数量</th>
                                                        <th>下期出庫数量</th>
                                                    </tr>
                                                    <tr>
                                                        <th>上期調整数量</th>
                                                        <th>上期末在庫数量</th>
                                                        <th>中期調整数量</th>
                                                        <th>中期末在庫数量</th>
                                                        <th>下期調整数量</th>
                                                        <th>下期末在庫数量</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {  data['item_info'].map((data1,index1) => (
                                                        <React.Fragment key={index1}>
                                                            <tr>
                                                                <td>
                                                                    <p>{SubCodeType==1?
                                                                        data1['item_code']+'-'+data1['item_a_code']
                                                                        :data1['item_code']+'-'+'000'}
                                                                    </p>
                                                                </td>
                                                                <td rowSpan={2} className={style.td_right}>{data1['col_a']}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['item_ary']['arrivalNum_1'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['item_ary']['leavingNum_1'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['item_ary']['arrivalNum_2'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['item_ary']['leavingNum_2'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['item_ary']['arrivalNum_3'])}</td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['item_ary']['leavingNum_3'])}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><p>{data1['item_name']}</p></td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['item_ary']['stockMoveNum_1'])}</td>
                                                                <td></td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['item_ary']['stockMoveNum_2'])}</td>
                                                                <td></td>
                                                                <td className={style.td_right}>{useJwt.numberWithCommas(data1['item_ary']['stockMoveNum_3'])}</td>
                                                                <td></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )) }
                                                    <tr>
                                                        <td rowSpan={2} colSpan={2}>【荷 主 計】</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_arrivalNum_1'])}</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_leavingNum_1'])}</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_arrivalNum_2'])}</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_leavingNum_2'])}</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_arrivalNum_3'])}</td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_leavingNum_3'])}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_stockMoveNum_1'])}</td>
                                                        <td></td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_stockMoveNum_2'])}</td>
                                                        <td></td>
                                                        <td className={style.td_right}>{useJwt.numberWithCommas(data['header_info']['total_stockMoveNum_3'])}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'請求数量確認表_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default BillingQuantityList;