/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './DestinationOutList.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import DistinationModal from '../../../components/common/modal/DistinationModal';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import $ from 'jquery';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import { Select } from 'antd';

const DestinationOutList = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    // publish date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };
    // slip no -------------------------------------------------
    const [SlipNo1, setSlipNo1] = useState("");
    const handleChangeSlipNo1 = (event) => {
        setSlipNo1(useJwt.validateNum(event.target.value,6));
    };
    const [SlipNo2, setSlipNo2] = useState("");
    const handleChangeSlipNo2 = (event) => {
        setSlipNo2(useJwt.validateNum(event.target.value,6));
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
    };
    const OrderTypeData = [ { value:'0', label:'届け先別商品順' }, { value:'1', label:'商品別届け先順' } ];
    // Distination -----------------------------------------------------------
    const [DistinationCode1, setDistinationCode1] = useState("");
    const handleChangeDistinationCode1 = (event) => {
        setDistinationCode1(useJwt.validateNum(event.target.value,6));
    };
    const [DistinationACode1, setDistinationACode1] = useState("");
    const handleChangeDistinationACode1 = (event) => {
        setDistinationACode1(useJwt.validateNum(event.target.value,3));
    };
    const [DistinationName1, setDistinationName1] = useState("");
    const [DistinationCode2, setDistinationCode2] = useState("");
    const handleChangeDistinationCode2 = (event) => {
        setDistinationCode2(useJwt.validateNum(event.target.value,6));
    };
    const [DistinationACode2, setDistinationACode2] = useState("");
    const handleChangeDistinationACode2 = (event) => {
        setDistinationACode2(useJwt.validateNum(event.target.value,3));
    };
    const [DistinationName2, setDistinationName2] = useState("");
    
    const onKeyDownDistinationCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getDistinationData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('dis_a_code1').focus();
                }else{
                    document.getElementById('dis_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('dis_a_code2').focus();
                }else{
                    document.getElementById('shipper_code1').focus();
                }
            }

        }
    };
    const getDistinationData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = DistinationACode1;
            }else{
                code = DistinationCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = DistinationACode2;
            }else{
                code = DistinationCode2;
                acode = value;
            }
        }
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = code;
            if(acode != "") {
                data['distination_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setDistinationData(t_data[0],param1);
                }else{
                    setDistinationData([],param1);
                }
            })
            .catch((error) => {
            });
        }else{
            setDistinationData([],param1);
        }
    }

    const setDistinationData = (data,param) => {
        if(data['distination_code']) {
            if(param == 1) {
                setDistinationCode1(data['distination_code']);
                setDistinationACode1(data['distination_a_code']);
                setDistinationName1(data['distination_name']);
            }else{
                setDistinationCode2(data['distination_code']);
                setDistinationACode2(data['distination_a_code']);
                setDistinationName2(data['distination_name']);
            }
        }else{
            if(param == 1) {
                setDistinationCode1("");
                setDistinationACode1("");
                setDistinationName1("");
            }else{
                setDistinationCode2("");
                setDistinationACode2("");
                setDistinationName2("");
            }
        }
    }
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('item_code1').focus();
                }
            }

        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ShipperACode1;
            }else{
                code = ShipperCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ShipperACode2;
            }else{
                code = ShipperCode2;
                acode = value;
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // item ----------------------------------------------------
    const [ItemCode1, setItemCode1] = useState("");
    const handleChangeItemCode1 = (event) => {
        setItemCode1(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode1, setItemACode1] = useState("");
    const handleChangeItemACode1 = (event) => {
        setItemACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName1, setItemName1] = useState("");
    const [ItemCode2, setItemCode2] = useState("");
    const handleChangeItemCode2 = (event) => {
        setItemCode2(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode2, setItemACode2] = useState("");
    const handleChangeItemACode2 = (event) => {
        setItemACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName2, setItemName2] = useState("");
    const onKeyDownItemCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getItemData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('item_a_code1').focus();
                }else{
                    document.getElementById('item_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('item_a_code2').focus();
                }else{
                    document.getElementById('type2').focus();
                }
            }
        }
    };

    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ItemACode1;
            }else{
                code = ItemCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ItemACode2;
            }else{
                code = ItemCode2;
                acode = value;
            }
        }

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result,param1);
                    }else{
                        setItemData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([],param1);
        }
    }
    const setItemData = (data,param) => {
        if(data['item_code']) {
            if(param == 1) {
                setItemCode1(data['item_code']);
                setItemACode1(data['item_a_code']);
                setItemName1(data['item_name']);
            }else{
                setItemCode2(data['item_code']);
                setItemACode2(data['item_a_code']);
                setItemName2(data['item_name']);
            }
        }else{
            if(param == 1) {
                setItemCode1("");
                setItemACode1("");
                setItemName1("");
            }else{
                setItemCode2("");
                setItemACode2("");
                setItemName2("");
            }
        }
    }
    // page type -----------------------------------------------
    const [PageType, setPageType] = useState(0);
    const handleChangePageType = (event) => {
        setPageType(event);
    };
    const PageTypeData = [ { value:'0', label:'改頁無し' }, { value:'1', label:'改頁有り' } ];
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_manage_flag_7'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        /*
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockHistoryByDateEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            if(result.length>0){
                
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                
            }
        })
        .catch((error) => {
        });
        */
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        setMainTableData(result);
        if(result.length>0){
            setOpend(true);
        }
    }
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ------------------------------- Distination Modal --------------------------------
    const [showModalDistination, setShowModalDistination] = useState(false);
    const [DistinationSearchValue, setDistinationSearchValue] = useState("");
    const [DistinationSearchValue2, setDistinationSearchValue2] = useState("");
    const [DistinationTableData, setDistinationTableData] = useState([]);
    const loadDistinationModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = DistinationCode1;
        let acode = DistinationACode1;
        if(param == 2) {
            code = DistinationCode2;
            acode = DistinationACode2;
        }
        setDistinationSearchValue(code);
        setDistinationSearchValue2(acode);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['distination_code'] = code;
            if(acode != "") {
                data['distination_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstDistinationEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setDistinationTableData(response.data.data);
                setShowModalDistination(true);
            })
            .catch((error) => {
            });
        }else{
            setDistinationTableData([]);
            setShowModalDistination(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemCode1;
        let acode = ItemACode1;
        if(param == 2) {
            code = ItemCode2;
            acode = ItemACode2;
        }
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

        
    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('dis_code1').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onSlipKeyDown  = (e, param) => {
        if(e.keyCode == 13){
            if(param == 1){
                document.getElementById('slipNo2').focus();
            }else{
                document.getElementById('type1').focus();
            }
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <DistinationModal show={showModalDistination} onClose={()=>setShowModalDistination(false)} width={'70%'}
                SearchValue={DistinationSearchValue} onChange={setDistinationSearchValue} 
                SearchValue2={DistinationSearchValue2} onChange2={setDistinationSearchValue2}
                tableData={DistinationTableData} setTableData={setDistinationTableData}
                target={setDistinationData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
              
            <div className={gstyle.border}>
                <p className={gstyle.text}>届け先出荷集計表</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 締日区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={StartDate} onChange={handleChangeStartDate} id='date2' focusId='date3' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={EndDate} onChange={handleChangeEndDate} id='date3' focusId='slipNo1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 伝票 No */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始伝票 No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        id='slipNo1' onKeyDown={(e)=>onSlipKeyDown(e, 1)}
                                        value={SlipNo1} onChange={handleChangeSlipNo1} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了伝票 No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        id='slipNo2' onKeyDown={(e)=>onSlipKeyDown(e, 2)}
                                        value={SlipNo2} onChange={handleChangeSlipNo2} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 出力順位 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={OrderTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 届け先 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始届け先</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={DistinationCode1} id='dis_code1'
                                            onKeyDown={(e) => onKeyDownDistinationCode(e,1,1)}
                                            onChange={(e) => handleChangeDistinationCode1(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={DistinationACode1} id='dis_a_code1'
                                        onKeyDown={(e) => onKeyDownDistinationCode(e,1,2)}
                                        onChange={(e) => handleChangeDistinationACode1(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={DistinationName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadDistinationModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了届け先</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")}
                                            value={DistinationCode2} id='dis_code2'
                                            onKeyDown={(e) => onKeyDownDistinationCode(e,2,1)}
                                            onChange={(e) => handleChangeDistinationCode2(e)} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")}
                                        value={DistinationACode2} id='dis_a_code2'
                                        onKeyDown={(e) => onKeyDownDistinationCode(e,2,2)}
                                        onChange={(e) => handleChangeDistinationACode2(e)} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={DistinationName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadDistinationModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 商品 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始商品</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,1,1)} id='item_code1'
                                        value={ItemCode1} onChange={handleChangeItemCode1} />
                                        <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,1,2)} id='item_a_code1'
                                        value={ItemACode1} onChange={handleChangeItemACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ItemName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了商品</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,2,1)} id='item_code2'
                                        value={ItemCode2} onChange={handleChangeItemCode2} />
                                        <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,2,2)} id='item_a_code2'
                                        value={ItemACode2} onChange={handleChangeItemACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ItemName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*  改頁区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>改頁区分</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="改頁区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangePageType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={PageTypeData}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>届け先出荷集計表</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'届け先出荷集計表_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default DestinationOutList;