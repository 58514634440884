/* eslint-disable eqeqeq */
import style from './Bus.module.css';
import gstyle from '../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal2 from '../../components/common/modal/Modal2';
import IconSearch from '../../components/common/icons/icon_search.png';
import CarrierModal from '../../components/common/modal/CarrierModal';
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

const DriverRegistry = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        for(var i = 0; i<mainTableData.length; i++){

            if(mainTableData[i]['bus_name'] == ""){
                toast.error("バースを入力してください。", {autoClose:3000});
                return;
            }
            if(mainTableData[i]['car_number'] == ""){
                toast.error("バースを入力してください。", {autoClose:3000});
                return;
            }
            if(mainTableData[i]['car_standard'] == ""){
                toast.error("バースを入力してください。", {autoClose:3000});
                return;
            }

        }
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Carrier ID
    const [carrierId, setCarrierId] = useState(0);
    const [CarrierCode, setCarrierCode] = useState("");
    const handleChangeCarrierCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>6) {
            str = str.substring(0, 6);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierCode(str);
    };
    const [CarrierACode, setCarrierACode] = useState("");
    const handleChangeCarrierACode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>3) {
            str = str.substring(0, 3);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierACode(str);
    };
    const onKeyDownCarrierCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            if(param == 1) {
                getCarrierData(event.target.value, CarrierACode);
            }else{
                getCarrierData(CarrierCode, event.target.value);
            }
            event.preventDefault();
        }
    };
    const getCarrierData = (code,acode) => {
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = code;
            if(acode != "") {
                data['carrier_a_code'] = acode;
            }
            gridMainTable([]);
            csloading(true);

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setCarrierData(t_data[0]);
                }else{
                    setCarrierData([]);
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });
        }else{
            setCarrierData([]);
        }
    }
    
    const [CarrierName, setCarrierName] = useState("");
    const [CarrierAName, setCarrierAName] = useState("");


    const setCarrierData = (result) => {
      if(result['sys_carrier_id']) {
          setCarrierId(result['sys_carrier_id']);
          setCarrierCode(result['carrier_code']);
          setCarrierACode(result['carrier_a_code']);
          setCarrierName(result['carrier_name']);
          setCarrierAName(result['carrier_a_name']);
          getDriverData(result['sys_carrier_id']);
      }else{
          setCarrierId(0);
          setCarrierName("");
          setCarrierAName("");
      }
  }

    const getDriverData = (result) => {
        let data = {};
        data['userID'] = userData['id'];
        data['transport'] = result;
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getDriverEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        }).then((response) => {
            csloading(false);

            if(response.status == 200) {
                if(response.data.data.length>0) {
                    let result = response.data.data;
                    gridMainTable(result);
                    var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
                    for (let i = 0; i < checkboxes.length; i++) {
                        checkboxes[i].checked = false;
                    }
                }else{
                    gridMainTable([]);
                }
            }
        })
        .catch((error) => {
            csloading(false);
        });
    }

    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        setMainTableData([]);
        setMainTableData(result);
    }

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['bus_flag_2'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const newRecord = (event) => {
        if(carrierId == ""){
            toast.error("運送会社コードを入力してください。", {autoClose:3000}); 
            return;
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            data[i] = row;
        }
        let row = {};
        row['id'] = 0;
        row['transport'] = carrierId;
        row['driver_name'] = "";
        row['phone_number'] = "";
        row['car_number'] = "";
        row['car_standard'] = "1";

        data[i] = row; 
        gridMainTable(data);
    }

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];

        let driverData = [];
        for(var i = 0; i<mainTableData.length; i++){
            let row = {};
            if(mainTableData[i]['id'] != 0) {
                row['id'] = mainTableData[i]['id'];
            }

            row['transport'] = mainTableData[i]['transport'];
            row['driver_name'] = mainTableData[i]['driver_name'];
            row['phone_number'] = mainTableData[i]['phone_number'];
            row['car_number'] = mainTableData[i]['car_number'];
            row['car_standard'] = mainTableData[i]['car_standard'];

            driverData[i] = row;

        }

        data['item'] = driverData;

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setDriverEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data == 1) {
                    toast.success("登録致しました。", {autoClose:3000});

                    getDriverData(carrierId);
                }else if(response.data.data == 2){
                    toast.error("すでに登録済みです。", {autoClose:3000});
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
                    
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else{
                toast.error("登録に失敗しました。", {autoClose:3000});
            }
        });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if(carrierId>0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        let delIds = [];
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(checkboxes[i].checked){
                let id = document.getElementById('id_'+i).value;
                if(id>0){
                    delIds.push(id);
                }
            }else{
                remainIndexs.push(i);
            }
        }
        
        if(delIds.length>0) {
            let data = {};
            data['userID'] = userData['id'];
            data['delIds'] = delIds;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.delDriverEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    getDriverData(carrierId);
                }
            })
            .catch((error) => {
            });
        }else{
            let remainData = [];
            for(var i=0; i<mainTableData.length; i++) {
                for(var j=0; j<remainIndexs.length; j++){
                    if(i==remainIndexs[j]){
                        let row = {};
                        row = mainTableData[i];
                        remainData.push(row);
                    }
                }
            }
            gridMainTable(remainData);
            $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', false);
        }
    }
    // ========================== starts code for search ===============================
    const [SearchValue, setSearchValue] = useState("");
    const [SearchValue2, setSearchValue2] = useState("");
    const [tableData, setTableData] = useState([]);
    
    const loadModalData = (event) => {
        event.preventDefault();
        setSearchValue(CarrierCode);
        setSearchValue2(CarrierACode);

        if(CarrierCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = CarrierCode;
            if(CarrierACode != "") {
                data['carrier_a_code'] = CarrierACode;
            }

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData(response.data.data);
                setShowModal(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData([]);
            setShowModal(true);
        }
    }
    
    const allCheckData = (event) => {
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', event.target.checked);
    }

    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };

    const handleChangePhoneNumber = (event, index) => {
      let value = event.target.value;

      let data = [];
      for(var i=0; i<mainTableData.length; i++) {
          let row = {};
          row = mainTableData[i];
          if(i == index){
              row['phone_number'] = value;
          }
          data[i] = row;
      }
      gridMainTable(data);

  }

  const handleChangeCarNumber = (event, index) => {
    let value = event.target.value;

    let data = [];
    for(var i=0; i<mainTableData.length; i++) {
        let row = {};
        row = mainTableData[i];
        if(i == index){
            row['car_number'] = value;
        }
        data[i] = row;
    }
    gridMainTable(data);
  }

  const handleChangeCarStandard = (event, index) => {
    let value = event.target.value;

    let data = [];
    for(var i=0; i<mainTableData.length; i++) {
        let row = {};
        row = mainTableData[i];
        if(i == index){
            row['car_standard'] = value;
        }
        data[i] = row;
    }
    gridMainTable(data);
  }

  // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w140px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_3 = [style.cs_th, gstyle.cs_col_3, style.td_item_align];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p, style.td_item_align];
    const style_td_col_3 = [style.cs_td, gstyle.cs_col_3, style.td_item_align];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p, style.td_item_align];
    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_select_40 = [gstyle.cs_select, gstyle.w140];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <CarrierModal show={showModal} onClose={()=>setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} onChange={setSearchValue} 
                SearchValue2={SearchValue2} onChange2={setSearchValue2}
                tableData={tableData} setTableData={setTableData}
                target={setCarrierData} />
            <Modal2 title="運送会社リスト削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>運送会社リスト</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* コード */}
                    <div className={style_row.join(" ")} style={{justifyContent:'center'}}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>運送会社コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownCarrierCode(e,1)}
                                        value={CarrierCode} onChange={handleChangeCarrierCode} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownCarrierCode(e,2)}
                                        value={CarrierACode} onChange={handleChangeCarrierACode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalData(e)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>運送会社名称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}  
                                        value={CarrierName} disabled/>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>運送会社略称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        value={CarrierAName} disabled/>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_2.join(" ")} style={{marginTop:33}}>
                            <Button type="primary" style={{width:100}} onClick={newRecord} >追 加</Button>
                        </div>
                    </div>
                    <div className={gstyle.hyfiled} style={{marginTop:30, justifyContent:'center'}}>
                        <div style={{minWidth:750}}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_col_3.join(" ")}>携帯電話</div>
                                    <div className={style_th_col_3.join(" ")}>車両番号</div>
                                    <div className={style_th_col_3.join(" ")}>車種</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                            id={'chk_'+index} onChange={handleChangeChk} />
                                            <input type="hidden" id={'id_'+index} value={data['id']} />
                                        </div>
                                        <div className={style_td_col_3.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['phone_number']} 
                                                onChange={(e) => handleChangePhoneNumber(e, index)} />
                                        </div>
                                        <div className={style_td_col_3.join(" ")}>
                                          <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['car_number']} 
                                                onChange={(e) => handleChangeCarNumber(e, index)} />
                                        </div>
                                        <div className={style_td_col_3.join(" ")}>
                                        <select className={style_select_40.join(" ")} id="SearchFieldType"
                                            value={data['car_standard']}
                                            onChange={(e) => handleChangeCarStandard(e, index)}>
                                                <option value="1">4t</option>
                                                <option value="2">10t</option>
                                                <option value="3">トレーラー</option>
                                            </select>
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={handleOpen} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData}>削除</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default DriverRegistry;