/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import axios from "axios";
import style from './Modal.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import Modal from './Modal';
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { jaJP } from '@mui/material/locale';
import InputDatepicker from '../../../components/common/input/InputDatepicker';

const InoutHistoryModal = props => {

    const [loaded, setLoaded] = useState(false);
    const [startDate, setStartDate] = useState(props.startDate)
    const [endDate, setEndDate] = useState(props.endDate)

    useEffect(() => {
        setStartDate(props.startDate)
        setEndDate(props.endDate)
    }, [props]);

    const userData = JSON.parse(useJwt.getUserData());
    const theme = useTheme();

    const columns = [
        {
            id: 'ioType',
            accessorKey: 'ioType',
            enableColumnActions: false,
            header: '区分',
            size: 80
        },
        {
            id: 'slip_date',
            accessorKey: 'slip_date',
            enableColumnActions: false,
            header: '入出庫日',
            size: 100
        },
        {
            id: 'item_name',
            accessorKey: 'item_name',
            enableColumnActions: false,
            header: '品名',
            size: 200
        },
        {
            id: 'rot_code',
            accessorKey: 'rot_code',
            enableColumnActions: false,
            header: 'ロットNo',
            size: 100
        },
        {
            id: 'item_num',
            accessorKey: 'item_num',
            enableColumnActions: false,
            header: '入出庫数量',
            size: 100
        },
        {
            id: 'distination_name',
            accessorKey: 'distination_name',
            enableColumnActions: false,
            header: '配送先',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.distination_name}</div>
            ),
            size: 100
        },
        {
            id: 'warehouse_name',
            accessorKey: 'warehouse_name',
            enableColumnActions: false,
            header: '入出庫倉庫',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.warehouse_name}</div>
            ),
            size: 100
        },
        {
            id: 'name',
            accessorKey: 'name',
            enableColumnActions: false,
            header: '入出庫者',
            Cell: ({ row }) => (
                <div style={{ display: 'flex' }}>{row.original.name}</div>
            ),
            size: 100
        },
    ];

    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];

    const searchData = () => {
        let data = {};
        data['userID'] = userData['id'];
        data['shipper_code'] = props.shipperCode;
        data['warehouse_id'] = props.warehouseId;
        data['item_code'] = props.itemCode;
        data['start_date'] = startDate;
        data['end_date'] = endDate;

        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getInoutHistoryEndpoint;
        setTableData([]);
        setLoaded(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(false);
                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        setTableData(response.data.data);
                    }
                    if (response.data.data.length == 0) toast.error("検索結果はありません。", { autoClose: 3000 });
                }
            })
            .catch((error) => {
                setLoaded(false);
                toast.error("検索結果はありません。", { autoClose: 3000 });
            });
    }

    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date))
    }

    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date))
    }

    const [tableData, setTableData] = useState([])
    const style_row = [gstyle.cs_row];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_input_wrap = [gstyle.p0015];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    return (
        <Modal title="商品検索" show={props.show} onClose={props.onClose} width={props.width}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                <div className={style_row.join(" ")}>
                    <div className={style_col_6.join(" ")}>
                        <div className={style_input_wrap.join(" ")}>
                            <label className={style_label_wrap.join(" ")}>
                                <span className={gstyle.cs_span}>開始年月日</span>
                                <div className={style_date_wrap.join(" ")}>
                                    <InputDatepicker value={startDate} onChange={handleChangeStartDate} />
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={style_col_6.join(" ")}>
                        <div className={style_input_wrap.join(" ")}>
                            <label className={style_label_wrap.join(" ")}>
                                <span className={gstyle.cs_span}>終了年月日</span>
                                <div className={style_date_wrap.join(" ")}>
                                    <InputDatepicker value={endDate} onChange={handleChangeEndDate} />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <Button onClick={searchData} loading={loaded} type={'primary'} style={{ marginLeft: 30, marginTop:20, width: 120, height: 35, borderRadius: 'unset' }}>
                    {loaded ? '検索中' : '検索'}
                </Button>
            </div>
            <div className={gstyle.hyfiled}>
                <div className={style.cs_tbl} style={{ minHeight: 610 }}>
                    <ThemeProvider theme={createTheme(theme, jaJP)}>
                        <MaterialReactTable
                            columns={columns}
                            data={tableData}
                            enableTopToolbar={true}
                            enableSelectAll={false}
                            enableGlobalFilter={true}
                            enableColumnFilters={false}
                            initialState={{
                                showColumnFilters: true,
                                showGlobalFilter: true,
                            }}
                            localization={MRT_Localization_JA}
                            muiTableBodyProps={{
                                sx: {
                                    '& tr:nth-of-type(odd)': {
                                        backgroundColor: '#f5f5f5',
                                        border: '1px solid #e0e0e0',
                                    },
                                    'td': {
                                        padding: '0.4rem'
                                    }
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    border: '1px solid #e0e0e0',
                                    textAlign: 'center'
                                },
                            }}
                            muiTableHeadCellProps={{
                                sx: {
                                    border: '1px solid #e0e0e0',
                                    '.Mui-TableHeadCell-Content': {
                                        display: 'flex',
                                        justifyContent: 'center',

                                    },
                                    backgroundColor: '#cfcfcf'
                                }
                            }}
                        />
                    </ThemeProvider>
                </div>
            </div>
        </Modal>
    )
}
export default InoutHistoryModal;




