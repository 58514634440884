import React, { CSSProperties, useState } from 'react';
import axios from "axios";
import * as useJwt from '../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig';
import './CsvImportInput.css'
import { useCSVReader } from 'react-papaparse';
import $ from 'jquery';

const styles = {
  progressBarBackgroundColor: {
    backgroundColor: 'blue',
    width: '80%',
  } as CSSProperties,
};

export default function CsvImportOrderDataInput(props) {
  const { CSVReader } = useCSVReader();

  const [csvData, setCsvData] = useState<String[][]>([]);
  const [importStatus, setImportStatus] = useState(true)
  const userData = JSON.parse(useJwt.getUserData() as any);

  const sendData = () => {

    props.setLoadFlag(true)
    let url = jwtDefaultConfig.backendUrl + props.url
    let data = {};
    data["userID"] = userData["id"];
    data["data"] = csvData;

    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
        },
      })
      .then((response) => {
        let responseData = response.data.data;
        if (responseData.length == 0) {
          responseData.push("success")
        }
        props.setErrorData(responseData)
        props.setLoadFlag(false)
        setImportStatus(true)
        // $('#fileName').text("");
      })
      .catch((error) => {
        props.setLoadFlag(false)
        // $('#fileName').text("");
        let netError = ['インポートに失敗しました。'];
        props.setErrorData(netError)
        setImportStatus(true)
      });
  }
  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        props.setErrorData([])
        var warehouseCodeIndex = -1, warehouseNameIndex = -1, shipperCodeIndex = -1, shipperNameIndex = -1, itemCodeIndex = -1, itemACodeIndex = -1
            , itemNameIndex = -1, shipperItemCodeIndex = -1, rotCodeIndex = -1, rotNameIndex = -1, tableCodeIndex = -1
            , casesIndex = -1, piecesIndex = -1, itemNumIndex = -1, dateIndex = -1;
        let headerArray = results.data[0];
        for(var i = 0; i<headerArray.length; i++){
          var tempName = headerArray[i];
          if(tempName === "倉庫コード")  warehouseCodeIndex = i;
          if(tempName === "倉庫名")  warehouseNameIndex = i;
          if(tempName === "荷主コード")  shipperCodeIndex = i;
          if(tempName === "荷主名")  shipperNameIndex = i;
          if(tempName === "品番")  itemCodeIndex = i;
          if(tempName === "品名")  itemNameIndex = i;
          if(tempName === "荷主品番")  shipperItemCodeIndex = i;
          if(tempName === "ロットNo" || tempName === "ﾛｯﾄNo")  rotCodeIndex = i;
          if(tempName === "ロット名")  rotNameIndex = i;
          if(tempName === "棚コード")  tableCodeIndex = i;
          if(tempName === "ケース数")  casesIndex = i;
          if(tempName === "バラ数")  piecesIndex = i;
          if(tempName === "在庫数量")  itemNumIndex = i;
          if(tempName === "入庫日")  dateIndex = i;
          if(tempName === "補助コード")  itemACodeIndex = i;
        }
        let totalArray : String[][] = []; 
        for(var i = 1; i<results.data.length; i++){
          let tempArray : String[] = results.data[i];
          let resultArray : String[] = [];
          if(warehouseCodeIndex !== -1){
            resultArray.push(tempArray[warehouseCodeIndex]);
          }else{
            resultArray.push("");
          }
          if(warehouseNameIndex !== -1){
            resultArray.push(tempArray[warehouseNameIndex]);
          }else{
            resultArray.push("");
          }
          if(shipperCodeIndex !== -1) {
            resultArray.push(tempArray[shipperCodeIndex]);
          }else{
            resultArray.push("");
          }
          if(shipperNameIndex !== -1) {
            resultArray.push(tempArray[shipperNameIndex]);
          }else{
            resultArray.push("");
          }
          if(itemCodeIndex !== -1) {
            resultArray.push(tempArray[itemCodeIndex]);
          }else{
            resultArray.push("");
          }
          if(itemNameIndex !== -1) {
            resultArray.push(tempArray[itemNameIndex]);
          }else{
            resultArray.push("");
          }
          if(shipperItemCodeIndex !== -1) {
            resultArray.push(tempArray[shipperItemCodeIndex]);
          }else{
            resultArray.push("");
          }
          if(rotCodeIndex !== -1) {
            resultArray.push(tempArray[rotCodeIndex]);
          }else{
            resultArray.push("");
          }
          if(rotNameIndex !== -1) {
            resultArray.push(tempArray[rotNameIndex]);
          }else{
            resultArray.push("");
          }
          if(tableCodeIndex !== -1) {
            resultArray.push(tempArray[tableCodeIndex]);
          }else{
            resultArray.push("");
          }
          if(casesIndex !== -1) {
            resultArray.push(tempArray[casesIndex]);
          }else{
            resultArray.push("");
          }
          if(piecesIndex !== -1) {
            resultArray.push(tempArray[piecesIndex]);
          }else{
            resultArray.push("");
          }
          if(itemNumIndex !== -1) {
            resultArray.push(tempArray[itemNumIndex]);
          }else{
            resultArray.push("");
          }
          if(dateIndex !== -1) {
            resultArray.push(tempArray[dateIndex]);
          }else{
            resultArray.push("");
          }
          if(itemACodeIndex !== -1) {
            resultArray.push(tempArray[itemACodeIndex]);
          }else{
            resultArray.push("");
          }
          totalArray.push(resultArray);
        }
        // console.log(totalArray);
        // if (results.data[0].toString() !== props.csvHeader.toString()) {
        //   props.setErrorData(["ファイル形式が間違っています。"])
        //   setImportStatus(true);
        //   return false;
        // }

        setCsvData(totalArray);
        setImportStatus(false)
      }}
      config={{
        encoding: 'windows-31j',
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
      }: any) => (
        <div style={{ display: 'flex', justifyContent: 'center', width:'80%' }} >
          <div style={{ width: '100%' }}>
            <div className="csvReader">
              <div id="fileName" className='acceptedFile'>
                {acceptedFile && acceptedFile.name}
              </div>
              <button type='button' {...getRootProps()} className="browseFile">
                ファイルを参照
              </button>
              {
                importStatus ?
                  <button onClick={sendData} className="import" disabled>インポート</button>
                  :
                  <button onClick={sendData} className="import">インポート</button>
              }

            </div>
            <ProgressBar style={styles.progressBarBackgroundColor} />
          </div>
        </div>
      )}
    </CSVReader>
  );
}