/* eslint-disable eqeqeq */
import style from './InvoiceSetting.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select} from 'antd';

const InvoiceSetting = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // InvoceSetting ID
    const [SysInvoceSettingsId, setSysInvoceSettingsId] = useState(0);
    // 用紙区分
    const [PaperType, setPaperType] = useState('0');
    const handleChangePaperType = (event) => {
        setPaperType(event);
    };
    // 種類区分
    const [PatternType, setPatternType] = useState('0');
    const handleChangePatternType = (event) => {
        setPatternType(event);
    };
    // 帳票No
    const [ReportNo, setReportNo] = useState("");
    const [ReportName, setReportName] = useState("");
    const handleChangeReportNo = (event, param) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>2) {
            str = str.substring(0, 2);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setReportNo(str);
    };
    // 送り状（BP2013）
    const [InvoicePrintFlagBp2013, setInvoicePrintFlagBp2013] = useState('0');
    const handleChangeInvoicePrintFlagBp2013 = (event) => {
        setInvoicePrintFlagBp2013(event);
    };
    // 送り状（50013CP）
    const [InvoicePrintFlag50013cp, setInvoicePrintFlag50013cp] = useState('0');
    const handleChangeInvoicePrintFlag50013cp = (event) => {
        setInvoicePrintFlag50013cp(event);
    };

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            csloading(true);
            if(menuPatternData['menu_start_setting_flag_2'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            const data = {
                userID: userData['id']
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getInvoiceSettingEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(true);
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    // MainSetting ID
                    if(response.data.data.sys_invoice_settings_id != null) {
                        setSysInvoceSettingsId(response.data.data.sys_invoice_settings_id);
                    }
                    // 用紙区分
                    if(response.data.data.paper_type != null) {
                        setPaperType(response.data.data.paper_type);
                    }
                    // 種類区分
                    if(response.data.data.pattern_type != null) {
                        setPatternType(response.data.data.pattern_type);
                    }
                    // 帳票No
                    if(response.data.data.report_no != null) {
                        setReportNo(response.data.data.report_no);
                    }
                    // 送り状（BP2013）
                    if(response.data.data.invoice_print_flag_bp2013 != null) {
                        setInvoicePrintFlagBp2013(response.data.data.invoice_print_flag_bp2013);
                    }
                    // 送り状（50013CP）
                    if(response.data.data.invoice_print_flag_50013cp != null) {
                        setInvoicePrintFlag50013cp(response.data.data.invoice_print_flag_50013cp);
                    }
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }
            });
        }
	});

    const saveData = (event) => {
        let data = {};
        data['userID'] = userData['id'];
        data['sys_invoice_settings_id'] = SysInvoceSettingsId;      // InvoiceSetting ID
        data['paper_type'] = PaperType;                             // 用紙区分
        data['pattern_type'] = PatternType;                         // 種類区分
        if(ReportNo != "") {
            data['report_no'] = ReportNo;                           // 帳票No
        }else{
            toast.error("帳票Noを入力してください。", {autoClose:3000});       // please enter report no
            setOpen(false);
            return;
        }
        data['invoice_print_flag_bp2013'] = InvoicePrintFlagBp2013;     // 送り状（BP2013）
        data['invoice_print_flag_50013cp'] = InvoicePrintFlag50013cp;   // 送り状（50013CP）
        
        data['update_user_id'] = userData['id'];
        csloading(true);
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setInvoiceSettingEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                toast.success("登録致しました。", {autoClose:3000});
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else{
                toast.error("登録に失敗しました。", {autoClose:3000});
            }
        });
        setOpen(false);
        event.preventDefault();
    }

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);

    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onFocusType4 = (e, type) => {
        if(type == 1){
            setType4Open(true);
        }else{
            setType4Open(false);
        }
    }


    const onDataKeyDown = (e, param) => {
        if(e.keyCode == 13){
            switch(param){
                case 1 :
                    document.getElementById('input1').focus();
                    break;
                case 2 :
                    document.getElementById('input2').focus();
                    break;
                case 3 :
                    document.getElementById('input3').focus();
                    break;
                case 4 :
                    document.getElementById('input4').focus();
                    break;
                case 5 :
                    document.getElementById('input5').focus();
                    break;
                case 6 :
                    document.getElementById('registerBut').focus();
                    break;
                    default :
            }
        }
    }

    
    const onSelectData = (e, index)=>{
        
        document.getElementById('registerBut').focus();
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            case 4:
                setType4Open(false);
                break;
            default:
            break;
        }
    }

    // style array
    const style_input_20_wrap = [gstyle.cs_input_wrap, gstyle.w20];
    const style_input_30_wrap = [gstyle.cs_input_wrap, gstyle.w30];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_30 = [gstyle.cs_input, gstyle.w30];

    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <div className={gstyle.border}>
                <p className={gstyle.text}>送り状出力設定</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 出庫処理からの送り状設定 */}
                    <div className={gstyle.hyfiled}>
                        <div className={style_input_20_wrap.join(" ")}>
                            <label className={gstyle.cs_label_blue}>出庫処理からの送り状設定
                            </label>
                        </div>
                    </div>
                    {/* 用紙区分, 種類区分, 帳票No */}
                    <div className={gstyle.hyfiled}>
                        <div className={style_input_20_wrap.join(" ")}>
                            <label className={style_label_wrap.join(" ")}>
                                <span className={gstyle.cs_span}>用紙区分</span>
                                    <Select
                                        value={PaperType}
                                        open={type1Open}
                                        onSelect={(e)=>onSelectData(e, 1)}
                                        placeholder="用紙区分を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangePaperType}
                                        onKeyDown={(e)=>onDataKeyDown(e, 2)}
                                        onFocus={(e) => onFocusType1(e, 1)}
                                        onBlur={(e) => onFocusType1(e, 2)}
                                        filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop:14, width:'100%'}}
                                        id="input1"
                                        options={[
                                            {value:"0", label:'レーザー'},
                                            {value:"1", label:'専用用紙'},
                                        ]}
                                    />
                            </label>
                        </div>
                        <div className={style_input_20_wrap.join(" ")}>
                            <label className={style_label_wrap.join(" ")}>
                                <span className={gstyle.cs_span}>種類区分</span>
                                    <Select
                                        value={PatternType}
                                        open={type2Open}
                                        onSelect={(e)=>onSelectData(e, 2)}
                                        placeholder="種類区分を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangePatternType}
                                        onKeyDown={(e)=>onDataKeyDown(e, 3)}
                                        onFocus={(e) => onFocusType2(e, 1)}
                                        onBlur={(e) => onFocusType2(e, 2)}
                                        filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop:14, width:'100%'}}
                                        id="input2"
                                        options={[
                                            {value:"0", label:'通常'},
                                            {value:"1", label:'重量'},
                                            {value:"2", label:'容積'},
                                        ]}
                                    />
                            </label>
                        </div>
                        <div className={style_input_30_wrap.join(" ")}>
                            <label className={style_label_wrap.join(" ")}>
                                <span className={gstyle.cs_span}>帳票No</span>
                                <div className={gstyle.cs_flex}>
                                    <input type={'number'} className={style_input_30.join(" ")} 
                                        value={ReportNo}
                                        id='input3' onKeyDown={(e)=>onDataKeyDown(e, 4)}
                                        onChange={handleChangeReportNo}/>
                                    <input type={'text'} className={style_input_bl_n.join(" ")} disabled 
                                        value={ReportName} />
                                </div>
                            </label>
                        </div>
                    </div>
                    {/* ロットNo印字設定 */}
                    <div className={gstyle.hyfiled}>
                        <div className={style_input_20_wrap.join(" ")}>
                            <label className={gstyle.cs_label_blue}>ロットNo印字設定
                            </label>
                        </div>
                    </div>
                    {/* 送り状（BP2013）, 送り状（50013CP） */}
                    <div className={gstyle.hyfiled}>
                        <div className={style_input_20_wrap.join(" ")}>
                            <label className={style_label_wrap.join(" ")}>
                                <span className={gstyle.cs_span}>送り状（BP2013）</span>
                                    <Select
                                        value={InvoicePrintFlagBp2013}
                                        open={type3Open}
                                        onSelect={(e)=>onSelectData(e, 3)}
                                        placeholder="送り状（BP2013）を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeInvoicePrintFlagBp2013}
                                        onKeyDown={(e)=>onDataKeyDown(e, 5)}
                                        onFocus={(e) => onFocusType3(e, 1)}
                                        onBlur={(e) => onFocusType3(e, 2)}
                                        filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop:14, width:'100%'}}
                                        id="input4"
                                        options={[
                                            {value:"0", label:'印字有'},
                                            {value:"1", label:'印字無し'},
                                        ]}
                                    />
                            </label>
                        </div>
                        <div className={style_input_20_wrap.join(" ")}>
                            <label className={style_label_wrap.join(" ")}>
                                <span className={gstyle.cs_span}>送り状（50013CP）</span>
                                    <Select
                                        value={InvoicePrintFlag50013cp}
                                        open={type4Open}
                                        onSelect={(e)=>onSelectData(e, 4)}
                                        placeholder="送り状（50013CP）を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeInvoicePrintFlag50013cp}
                                        onKeyDown={(e)=>onDataKeyDown(e, 6)}
                                        onFocus={(e) => onFocusType4(e, 1)}
                                        onBlur={(e) => onFocusType4(e, 2)}
                                        filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop:14, width:'100%'}}
                                        id="input5"
                                        options={[
                                            {value:"0", label:'印字有'},
                                            {value:"1", label:'印字無し'},
                                        ]}
                                    />
                            </label>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} id='registerBut' onClick={handleOpen} >設定</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default InvoiceSetting;