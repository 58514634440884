/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import style from './WarehouseInOutDailyList.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import StartTableModal from '../../../components/common/modal/TableModal';
import EndTableModal from '../../../components/common/modal/TableModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import $ from 'jquery';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink, CSVDownload } from "react-csv";
import { Select } from 'antd';

const WarehouseInOutDailyList = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpend] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 
    
    // publish date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };
    // slip no -------------------------------------------------
    const [SlipNo1, setSlipNo1] = useState("");
    const handleChangeSlipNo1 = (event) => {
        setSlipNo1(useJwt.validateNum(event.target.value,6));
    };
    const [SlipNo2, setSlipNo2] = useState("");
    const handleChangeSlipNo2 = (event) => {
        setSlipNo2(useJwt.validateNum(event.target.value,6));
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
        if(event == 0) {
            setSumTypeData([]);
        }else if(event == 1) {
            setSumTypeData([ { value:'0', label:'倉庫計有り' }, { value:'1', label:'倉庫計無し' }]);
        }else{
            setSumTypeData(
                [ { value:'0', label:'倉庫計･荷主計有り' }, { value:'1', label:'倉庫計のみ' }
                , { value:'2', label:'荷主計のみ' }, { value:'3', label:'倉庫計･荷主計無し' }]
            );
        }
    };
    const OrderTypeData = [ { value:'0', label:'日付順' }, { value:'1', label:'倉庫順' }, { value:'2', label:'倉庫別荷主別商品順' } ];
    // output order type -----------------------------------------------
    const [DisplayType, setDisplayType] = useState(0);
    const handleChangeDisplayType = (event) => {
        setDisplayType(event);
    };
    const DisplayTypeData = [ { value:'0', label:'明細別' }, { value:'1', label:'日別集計' } ];
    // Arrival type -----------------------------------------------
    const [ArrivalPrintType, setArrivalPrintType] = useState(0);
    const handleChangeArrivalPrintType = (event) => {
        setArrivalPrintType(event);
    };
    const ArrivalPrintTypeData = [ { value:'0', label:'印字有り' }, { value:'1', label:'印字無し' } ];
    // Leaving type -----------------------------------------------
    const [LeavingPrintType, setLeavingPrintType] = useState(0);
    const handleChangeLeavingPrintType = (event) => {
        setLeavingPrintType(event);
    };
    const LeavingPrintTypeData = [ { value:'0', label:'印字有り' }, { value:'1', label:'印字無し' } ];
    // StockMove type -----------------------------------------------
    const [StockMovePrintType, setStockMovePrintType] = useState(0);
    const handleChangeStockMovePrintType = (event) => {
        setStockMovePrintType(event);
    };
    const StockMovePrintTypeData = [ { value:'0', label:'印字有り' }, { value:'1', label:'印字無し' } ];
    // ChangeAmount type -----------------------------------------------
    const [ChangeAmountPrintType, setChangeAmountPrintType] = useState(0);
    const handleChangeChangeAmountPrintType = (event) => {
        setChangeAmountPrintType(event);
    };
    const ChangeAmountPrintTypeData = [ { value:'0', label:'印字有り' }, { value:'1', label:'印字無し' } ];
    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('warehouse_code2').focus();
            }else{
                setTimeout(()=>{
                    if(document.getElementById('location_code1')){
                        document.getElementById('location_code1').focus();
                    }else{
                        document.getElementById('shipper_code1').focus();
                    }
                })
            }
        }
    };
    const getWarehouseData = (code,param) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result,param);
                    }else{
                        setWarehouseData([],param);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setWarehouseData([],param);
        }
    }
    const setWarehouseData = (data,param) => {
        if(data['warehouse_code']) {
            if(param == 1) {
                setWarehouseCode1(data['warehouse_code']);
                setWarehouseName1(data['warehouse_name']);
            }else{
                setWarehouseCode2(data['warehouse_code']);
                setWarehouseName2(data['warehouse_name']);
            }
        }else{
            if(param == 1) {
                setWarehouseCode1("");
                setWarehouseName1("");
            }else{
                setWarehouseCode2("");
                setWarehouseName2("");
            }
        }
    }
    // location ------------------------------------------------
    const [LocationNo1, setLocationNo1] = useState("");
    const handleChangeLocationNo1 = (event) => {
        setLocationNo1(event.target.value);
    };
    const [LocationData1, setLocationData1] = useState([]);
    const [LocationNo2, setLocationNo2] = useState("");
    const handleChangeLocationNo2 = (event) => {
        setLocationNo2(event.target.value);
    };
    const [LocationData2, setLocationData2] = useState([]);

    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    document.getElementById('item_code1').focus();
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ShipperACode1;
            }else{
                code = ShipperCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ShipperACode2;
            }else{
                code = ShipperCode2;
                acode = value;
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // item ----------------------------------------------------
    const [ItemCode1, setItemCode1] = useState("");
    const handleChangeItemCode1 = (event) => {
        setItemCode1(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode1, setItemACode1] = useState("");
    const handleChangeItemACode1 = (event) => {
        setItemACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName1, setItemName1] = useState("");
    const [ItemCode2, setItemCode2] = useState("");
    const handleChangeItemCode2 = (event) => {
        setItemCode2(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode2, setItemACode2] = useState("");
    const handleChangeItemACode2 = (event) => {
        setItemACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName2, setItemName2] = useState("");
    const onKeyDownItemCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getItemData(event.target.value,param1,param2);
            event.preventDefault();
            
            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('item_a_code1').focus();
                }else{
                    document.getElementById('item_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('item_a_code2').focus();
                }else{
                    setTimeout(()=>{
                        if(document.getElementById('type7')){
                            document.getElementById('type7').focus();
                        }else{
                            document.getElementById('type8').focus();
                        }
                    },10)
                }
            }

        }
    };
    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                acode = ItemACode1;
            }else{
                code = ItemCode1;
                acode = value;
            }
        }else{
            if(param2==1){
                code = value;
                acode = ItemACode2;
            }else{
                code = ItemCode2;
                acode = value;
            }
        }

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result,param1);
                    }else{
                        setItemData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([],param1);
        }
    }
    const setItemData = (data,param) => {
        if(data['item_code']) {
            if(param == 1) {
                setItemCode1(data['item_code']);
                setItemACode1(data['item_a_code']);
                setItemName1(data['item_name']);
            }else{
                setItemCode2(data['item_code']);
                setItemACode2(data['item_a_code']);
                setItemName2(data['item_name']);
            }
        }else{
            if(param == 1) {
                setItemCode1("");
                setItemACode1("");
                setItemName1("");
            }else{
                setItemCode2("");
                setItemACode2("");
                setItemName2("");
            }
        }
    }
    // item type -----------------------------------------------
    const [ItemType, setItemType] = useState(0);
    const handleChangeItemType = (event) => {
        setItemType(event);
    };
    const ItemTypeData = [ { value:'0', label:'品番計無し' }, { value:'1', label:'品番計有り' } ];
    // location type -----------------------------------------------
    const [LocationType, setLocationType] = useState(0);
    const handleChangeLocationType = (event) => {
        setLocationType(event);
    };
    const LocationTypeData = [ { value:'0', label:'倉庫別' }, { value:'1', label:'棚別' } ];
    // invoice type -----------------------------------------------
    const [InvoiceType, setInvoiceType] = useState(0);
    const handleChangeInvoiceType = (event) => {
        setInvoiceType(event);
    };
    const InvoiceTypeData = [ { value:'0', label:'全部' }, { value:'1', label:'請求対象' }, { value:'2', label:'請求対象外' } ];
    // sum type -----------------------------------------------
    const [SumType, setSumType] = useState(0);
    const handleChangeSumType = (event) => {
        setSumType(event);
    };
    const [SumTypeData, setSumTypeData] = useState([]);
    // page type -----------------------------------------------
    const [PageType, setPageType] = useState(0);
    const handleChangePageType = (event) => {
        setPageType(event);
    };
    const PageTypeData = [ { value:'0', label:'改頁無し' }, { value:'1', label:'改頁有り' } ];
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_manage_flag_6'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        data['start_date'] = StartDate;
        data['end_date'] = EndDate;
        data['slip_no_start'] = SlipNo1;
        data['slip_no_end'] = SlipNo2;
        data['warehouse_code_start'] = WarehouseCode1;
        data['warehouse_code_end'] = WarehouseCode2;
        data['shipper_code_start'] = ShipperCode1;
        data['shipper_a_code_start'] = ShipperACode1;
        data['shipper_code_end'] = ShipperCode2;
        data['shipper_a_code_end'] = ShipperACode2;
        data['item_code_start'] = ItemCode1;
        data['item_code_end'] = ItemCode2;
        data['item_a_code_start'] = ItemACode1;
        data['item_a_code_end'] = ItemACode2;
        data['invoice_type'] = InvoiceType;

        csloading(true);
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getWarehouseStockBySlipDay;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            if(result.length>0){
                gridMainTable(result);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
    }
    const CloseSearchWidow =()=> {
        setOpend(false);
    }

    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let totalArray = [];
        let inNum = 0, outNum = 0;

        var kindName = "";
        for(let i = 0; i < result.length; i++){
            let temp = result[i];

            if(ArrivalPrintType == "1" && temp['dis'] == "入庫")    continue;
            if(LeavingPrintType == "1" && temp['dis'] == "出庫")    continue;
            if(StockMovePrintType == "1" && temp['dis'] == "倉替")    continue;
            if(ChangeAmountPrintType == "1" && temp['dis'] == "単変")    continue;
            if(kindName != temp['dis']){
                kindName = temp['dis'];
                totalArray.push(temp);
            }else{
                temp['dis'] = "";
                totalArray.push(temp);
            }

            if(temp['order1'] == 1){
                inNum += temp['inNum']*1;
                outNum += temp['outNum']*1.000;
            }
        }
        let totalRow = {};
        totalRow['dis'] = '';totalRow['inNum'] = inNum;totalRow['outNum'] = outNum;totalRow['cdate']='';totalRow['shipper_code']='';
        totalRow['shipper_a_code'] = '';totalRow['item_code'] = '【総 合 計】';totalRow['item_a_code'] = '';totalRow['order1']='';totalRow['orderFlag']='';
        totalRow['rot_code'] = '';totalRow['warehouse_code'] = '';totalRow['warehouse_code'] = '';totalRow['slip_no'] = '';totalRow['table_code']='';totalRow['shipper_name']='';
        totalRow['shipper_a_name']='';totalRow['item_name']='';
        totalArray.push(totalRow);
        setMainTableData(totalArray);
        if(result.length>0){
            setOpend(true);
        }
        // for pagenation
        setItemOffset(0);
        const endOffset = 0 + itemsPerPage;
        setCurrentItems(totalArray.slice(0, endOffset));
        setPageCount(Math.ceil(totalArray.length / itemsPerPage));
    };
    // starts pagenation code -----------------------------------------
    const itemsPerPage = 50;
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % mainTableData.length;
        // console.log( `User requested page number ${event.selected}, which is offset ${newOffset}` );
        setItemOffset(newOffset);
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(mainTableData.slice(newOffset, endOffset));
    };
    // ends pagenation code ---------------------------------------------
    // ========================== starts code for search ===============================
    const [TargetParam, setTargetParam] = useState(1);
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    
    const loadWarehouseModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode1;
        if(param==2){
            code = WarehouseCode2;
        }
        setWarehouseSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['warehouse_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemCode1;
        let acode = ItemACode1;
        if(param == 2) {
            code = ItemCode2;
            acode = ItemACode2;
        }
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

            
    // -----------------------------start Table modal -------------------------------------
    const [showModalStartTable, setShowModalStartTable] = useState(false);
    const [startTableSearchValue, setStartTableSearchValue] = useState("");
    const [startTableSearchValue2, setStartTableSearchValue2] = useState("");
    const [startTableData, setStartTableData] = useState([]);
    const loadStartTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = WarehouseCode1;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setStartTableSearchValue(table_code);
        setStartTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setStartTableData(response.data.data);
                setShowModalStartTable(true);
            })
            .catch((error) => {
            });
        }else{
            setStartTableData([]);
            setShowModalStartTable(true);
        }
    }
    const setStartTableCode = (data) => {

        setLocationData1(data);
        setLocationNo1(data['table_code']);
    }
    
    // -----------------------------end Table modal -------------------------------------
    const [showModalEndTable, setShowModalEndTable] = useState(false);
    const [endTableSearchValue, setEndTableSearchValue] = useState("");
    const [endTableSearchValue2, setEndTableSearchValue2] = useState("");
    const [endTableData, setEndTableData] = useState([]);
    const loadEndTableModalData = (event,idx,table_code) => {
        event.preventDefault();
        document.getElementById('selectedId').value=idx;
        var warehouse_code = WarehouseCode1;

        if(warehouse_code == ""){
            toast.error("倉庫コードを入力してください。", {autoClose:3000});
            return;
        }
        setEndTableSearchValue(table_code);
        setEndTableSearchValue2(warehouse_code);

        if(warehouse_code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['warehouse_code'] = warehouse_code;
            if(table_code != "")    data['table_code'] = table_code;

            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstTableEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setEndTableData(response.data.data);
                setShowModalEndTable(true);
            })
            .catch((error) => {
            });
        }else{
            setEndTableData([]);
            setShowModalEndTable(true);
        }
    }
    const setEndTableCode = (data) => {
        setLocationData2(data);
        setLocationNo2(data['table_code']);
    }

    
    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);
    const [type5Open, setType5Open] = useState(false);
    const [type6Open, setType6Open] = useState(false);
    const [type7Open, setType7Open] = useState(false);
    const [type8Open, setType8Open] = useState(false);
    const [type9Open, setType9Open] = useState(false);
    const [type10Open, setType10Open] = useState(false);
    const [type11Open, setType11Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type2').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type3').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type4').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onType4KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type5').focus();
            event.preventDefault();
        }
    }
    const onFocusType4 = (e, type) => {
        if(type == 1){
            setType4Open(true);
        }else{
            setType4Open(false);
        }
    }

    const onType5KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type6').focus();
            event.preventDefault();
        }
    }
    const onFocusType5 = (e, type) => {
        if(type == 1){
            setType5Open(true);
        }else{
            setType5Open(false);
        }
    }

    const onType6KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('warehouse_code1').focus();
            event.preventDefault();
        }
    }
    const onFocusType6 = (e, type) => {
        if(type == 1){
            setType6Open(true);
        }else{
            setType6Open(false);
        }
    }

    const onType7KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('type8').focus();
            event.preventDefault();
        }
    }
    const onFocusType7 = (e, type) => {
        if(type == 1){
            setType7Open(true);
        }else{
            setType7Open(false);
        }
    }

    const onType8KeyDown = (event) =>{
        if(event.keyCode == 13){
            if(document.getElementById('type9')){
                document.getElementById('type9').focus();
            }else{
                document.getElementById('type11').focus();
            }
            event.preventDefault();
        }
    }
    const onFocusType8 = (e, type) => {
        if(type == 1){
            setType8Open(true);
        }else{
            setType8Open(false);
        }
    }

    const onType9KeyDown = (event) =>{
        if(event.keyCode == 13){
            if(document.getElementById('type10')){
                document.getElementById('type10').focus();
            }else{
                document.getElementById('type11').focus();
            }
            event.preventDefault();
        }
    }
    const onFocusType9 = (e, type) => {
        if(type == 1){
            setType9Open(true);
        }else{
            setType9Open(false);
        }
    }

    const onType10KeyDown = (event) =>{
        if(event.keyCode == 13){
            if(document.getElementById('type11')){
                document.getElementById('type11').focus();
            }else{
                document.getElementById('searchBut').focus();
            }
            event.preventDefault();
        }
    }
    const onFocusType10 = (e, type) => {
        if(type == 1){
            setType10Open(true);
        }else{
            setType10Open(false);
        }
    }

    const onType11KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType11 = (e, type) => {
        if(type == 1){
            setType11Open(true);
        }else{
            setType11Open(false);
        }
    }

    const onLocationKeyDown  = (e, param) => {
        if(e.keyCode == 13){
            if(param == 1){
                document.getElementById('location_code2').focus();
            }else{
                document.getElementById('shipper_code1').focus();
            }
        }
    }

    const onSlipKeyDown  = (e, param) => {
        if(e.keyCode == 13){
            if(param == 1){
                document.getElementById('slipNo2').focus();
            }else{
                document.getElementById('type1').focus();
            }
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('focus').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            case 4:
                setType4Open(false);
                break;
            case 5:
                setType5Open(false);
                break;
            case 6:
                setType6Open(false);
                break;
            case 7:
                setType7Open(false);
                break;
            case 8:
                setType8Open(false);
                break;
            case 9:
                setType9Open(false);
                break;
            case 10:
                setType10Open(false);
                break;
            case 11:
                setType11Open(false);
                break;
            default:
                break;
        }
    }

    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_60px = [gstyle.cs_input, gstyle.w60px];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    const print_row = [gstyle.cs_row, style.title_row];
    const print_col_4_left = [gstyle.cs_col, gstyle.cs_col_4, style.title_left];
    const print_col_4_right = [gstyle.cs_col, gstyle.cs_col_4, style.title_right];
    const print_col_8_left_other = [gstyle.cs_col, gstyle.cs_col_8, style.title_left_other];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
            <StartTableModal show={showModalStartTable} onClose={()=>setShowModalStartTable(false)} width={'70%'}
                SearchValue={startTableSearchValue} SearchValue2={startTableSearchValue2}  onChange={setStartTableSearchValue} 
                tableData={startTableData} setTableData={setStartTableData}
                target={setStartTableCode} />
            <EndTableModal show={showModalEndTable} onClose={()=>setShowModalEndTable(false)} width={'70%'}
                SearchValue={endTableSearchValue} SearchValue2={endTableSearchValue2}  onChange={setEndTableSearchValue} 
                tableData={endTableData} setTableData={setEndTableData}
                target={setEndTableCode} />
              
            <div className={gstyle.border}>
                <p className={gstyle.text}>倉庫別入出庫日計表</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 締日区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId='date2' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={StartDate} onChange={handleChangeStartDate} id='date2' focusId='date3' />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={EndDate} onChange={handleChangeEndDate} id='date3' focusId='slipNo1' />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 伝票 No */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始伝票 No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        value={SlipNo1} onChange={handleChangeSlipNo1} 
                                        id='slipNo1' onKeyDown={(e)=>onSlipKeyDown(e, 1)}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了伝票 No</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        id='slipNo2' onKeyDown={(e)=>onSlipKeyDown(e, 2)}
                                        value={SlipNo2} onChange={handleChangeSlipNo2} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 出力順位, 表示区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={OrderTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>表示区分</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="表示区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeDisplayType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={DisplayTypeData}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 入庫明細, 出庫明細, 在庫移動明細, 単位変更明細 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>入庫明細</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="入庫明細を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeArrivalPrintType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={ArrivalPrintTypeData}
                                            />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出庫明細</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type4Open}
                                            onSelect={(e)=>onSelectData(e, 4)}
                                            placeholder="出庫明細を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeLeavingPrintType}
                                            onKeyDown={onType4KeyDown}
                                            onFocus={(e) => onFocusType4(e, 1)}
                                            onBlur={(e) => onFocusType4(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type4"
                                            options={LeavingPrintTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>在庫移動明細</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type5Open}
                                            onSelect={(e)=>onSelectData(e, 5)}
                                            placeholder="在庫移動明細を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeStockMovePrintType}
                                            onKeyDown={onType5KeyDown}
                                            onFocus={(e) => onFocusType5(e, 1)}
                                            onBlur={(e) => onFocusType5(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type5"
                                            options={StockMovePrintTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>単位変更明細</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type6Open}
                                            onSelect={(e)=>onSelectData(e, 6)}
                                            placeholder="単位変更明細を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeChangeAmountPrintType}
                                            onKeyDown={onType6KeyDown}
                                            onFocus={(e) => onFocusType6(e, 1)}
                                            onBlur={(e) => onFocusType6(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type6"
                                            options={ChangeAmountPrintTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫, 棚 */}
                    <div className={style_row.join(" ")}>
                    <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_60px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,1)} id='warehouse_code1'
                                            value={WarehouseCode1} onChange={handleChangeWarehouseCode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={WarehouseName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_60px.join(" ")} id='warehouse_code2'
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,2)}
                                            value={WarehouseCode2} onChange={handleChangeWarehouseCode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={WarehouseName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <input type='text' style={{width:0, outline:'white', border:'none'}} id="focus"/>
                        </div>
                        {WarehouseCode1 != "" && WarehouseCode1 == WarehouseCode2 && <>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始棚コード</span>
                                        <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                            <input type={'num'} className={style.cs_tbl_input} 
                                                value={LocationNo1} id='location_code1'
                                                onKeyDown={(e)=>onLocationKeyDown(e, 1)}
                                                onChange={(e) => handleChangeLocationNo1(e)} />
                                                <div className={gstyle.icon_search_wrap} 
                                                    onClick={(e)=>loadStartTableModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了棚コード</span>
                                        <div className={gstyle.cs_flex} style={{marginTop:14}}>
                                            <input type={'num'} className={style.cs_tbl_input} 
                                                value={LocationNo2} id='location_code2'
                                                onKeyDown={(e)=>onLocationKeyDown(e, 2)}
                                                onChange={(e) => handleChangeLocationNo2(e)} />
                                                <div className={gstyle.icon_search_wrap} 
                                                    onClick={(e)=>loadEndTableModalData(e)}>
                                                    <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                                </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </>}
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                    <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 商品 */}
                    <div className={style_row.join(" ")}>
                    <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始商品</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,1,1)} id='item_code1'
                                        value={ItemCode1} onChange={handleChangeItemCode1} />
                                        <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,1,2)} id='item_a_code1'
                                        value={ItemACode1} onChange={handleChangeItemACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ItemName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了商品</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,2,1)} id='item_code2'
                                        value={ItemCode2} onChange={handleChangeItemCode2} />
                                        <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownItemCode(e,2,2)} id='item_a_code2'
                                        value={ItemACode2} onChange={handleChangeItemACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ItemName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 棚区分 */}
                    {OrderType > 0 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>棚区分</span>
                                        <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type7Open}
                                            onSelect={(e)=>onSelectData(e, 7)}
                                            placeholder="棚区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeLocationType}
                                            onKeyDown={onType7KeyDown}
                                            onFocus={(e) => onFocusType7(e, 1)}
                                            onBlur={(e) => onFocusType7(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type7"
                                            options={LocationTypeData}
                                        />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 請求区分, 計印字区分, 品番計区分, 改頁区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>請求区分</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type8Open}
                                            onSelect={(e)=>onSelectData(e, 8)}
                                            placeholder="請求区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeInvoiceType}
                                            onKeyDown={onType8KeyDown}
                                            onFocus={(e) => onFocusType8(e, 1)}
                                            onBlur={(e) => onFocusType8(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type8"
                                            options={InvoiceTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        {OrderType > 0 &&
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>計印字区分</span>
                                        <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type9Open}
                                            onSelect={(e)=>onSelectData(e, 9)}
                                            placeholder="計印字区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeSumType}
                                            onKeyDown={onType9KeyDown}
                                            onFocus={(e) => onFocusType9(e, 1)}
                                            onBlur={(e) => onFocusType9(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type9"
                                            options={SumTypeData}
                                        />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        }
                        {OrderType == 2 &&
                            <div className={style_col_3.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>品番計区分</span>
                                        <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type10Open}
                                            onSelect={(e)=>onSelectData(e, 10)}
                                            placeholder="品番計区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeItemType}
                                            onKeyDown={onType10KeyDown}
                                            onFocus={(e) => onFocusType10(e, 1)}
                                            onBlur={(e) => onFocusType10(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type10"
                                            options={ItemTypeData}
                                        />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        }
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>改頁区分</span>
                                    <div className={gstyle.cs_flex}>
                                    <Select
                                            showSearch
                                            open={type11Open}
                                            onSelect={(e)=>onSelectData(e, 11)}
                                            placeholder="改頁区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangePageType}
                                            onKeyDown={onType11KeyDown}
                                            onFocus={(e) => onFocusType11(e, 1)}
                                            onBlur={(e) => onFocusType11(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type11"
                                            options={PageTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut' >検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        <div className={print_row.join(" ")}>
                            <div className={print_col_4_left.join(" ")}>
                            </div>
                            <div className={style_col_4.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>倉庫別入出庫日計表</h2>
                                </div>
                            </div>
                            <div className={print_col_4_right.join(" ")}>
                                <div >
                                    <p className={style.title_font}>{useJwt.formatDate2(PublishDate)}</p>
                                </div>
                            </div>
                        </div>
                        <div className={print_row.join(" ")}>
                            <div className={print_col_8_left_other.join(" ")}>
                                <div>
                                <p className={style.title_font}>[日付範囲]:({StartDate} ～ {EndDate})</p>
                                <p className={style.title_font}>
                                    [出力順位]:{OrderType == 0 ? '日付順' : OrderType == 1 ? '荷主順' :'荷主別商品順'}&nbsp;&nbsp;
                                    [表示区分]:{DisplayType == 0 ? '明細別' : '日別集計'}&nbsp;&nbsp;
                                    [請求区分]:{InvoiceType == 0 ? '全部' : InvoiceType == 1 ? '請求対象' : '請求対象外'}
                                </p>
                                </div>
                            </div>
                        </div>
                        <div className={print_row.join(' ')}>
                            <table>
                                <thead>
                                    <tr>
                                        <th rowSpan={2}>区分</th>
                                        <th rowSpan={2} style={{width:'15%'}}>年月日</th>
                                        <th rowSpan={2}>伝票No</th>
                                        <th>倉庫</th>
                                        <th>棚コード</th>
                                        <th>荷主ｺｰﾄﾞ</th>
                                        <th>補助</th>
                                        <th>品番</th>
                                        <th>補助</th>
                                        <th>ﾛｯﾄNo</th>
                                        <th rowSpan={2}>入庫数量</th>
                                        <th rowSpan={2}>出庫数量</th>
                                        <th rowSpan={2}>在庫数量</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>倉庫名</th>
                                        <th colSpan={2}>荷主名</th>
                                        <th colSpan={3}>品名</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {  currentItems.map((data,index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td rowSpan={2}>{data['dis']}</td>
                                                <td rowSpan={2}>{data['order1'] == 1 ? data['cdate'] : ''}</td>
                                                <td rowSpan={2}>{data['order1'] == 1 ? data['slip_no'] : ''}</td>
                                                <td>{data['warehouse_code']}</td>
                                                <td>{data['table_code']}</td>
                                                <td>{data['order1'] == 1 ? data['shipper_code'] : ''}</td>
                                                <td>{data['order1'] == 1 ? data['shipper_a_code'] : ''}</td>
                                                <td>{data['item_code']}</td>
                                                <td>{data['order1'] == 1 ? data['item_a_code'] : ''}</td>
                                                <td>{data['rot_code']}</td>
                                                <td rowSpan={2}>{data['inNum']}</td>
                                                <td rowSpan={2}>{data['outNum']}</td>
                                                <td rowSpan={2}></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>{data['warehouse_name']}</td>
                                                <td colSpan={2}>{data['shipper_name']}</td>
                                                <td colSpan={3}>{data['order1'] == 1 ? data['item_name'] : ''}</td>
                                            </tr>
                                        </React.Fragment>
                                    )) }
                                </tbody>
                            </table>
                            {mainTableData.length > 0 && (
                                <div className={style_row.join(" ")} style={{width:'100%'}}>
                                    <div className={style_col_12.join(" ")}>
                                    <div className={style.nw_title_wrap}>
                                        <ReactPaginate
                                        previousLabel={"前へ"}
                                        nextLabel={"次へ"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        />
                                    </div>
                                    </div>
                                </div>
                                )}
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'倉庫別入出庫日計表_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default WarehouseInOutDailyList;