//====================================================================================================
//	作成日:2022/08/26		最終更新日:2022/08/26
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
import style from './MyButtonSquare.module.css'
import { Link } from 'react-router-dom'

const MyButton = ({link, text}) => {
	return (
		<div className={style.button_area}>
			<Link to={link} className={style.link}>
				<div className={text}>{text}</div>
			</Link>
		</div>
	);
};

export default MyButton;