/* eslint-disable eqeqeq */
import style from './MenuPatternSetting.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from '../../../components/common/modal/Modal';
import MenuPatternModal from '../../../components/common/modal/MenuPatternModal';
import IconSearch from '../../../components/common/icons/icon_search.png';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button} from 'antd';

const MenuPatternSetting = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {

        if(PatternCode == "") {
            toast.error("パターンコードを入力してください。", {autoClose:3000});       // please enter pattern code
            return;
        }
        if(PatternName == "") {
            toast.error("パターン名称を入力してください。", {autoClose:3000});
            return;
        }
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // MenuPattern ID
    const [SysMenuPatternId, setSysMenuPatternId] = useState(0);
    const [PatternCode, setPatternCode] = useState("");
    const handleChangePatternCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setPatternCode(str);
    };
    const onKeyDownPatternCode = (event) => {
        if(event.keyCode === 13) { // enter key
            if(event.target.value != "") {
                const data_r = {
                    userID: userData['id'],
                    pattern_code: event.target.value
                };
                let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMenuPatternEndpoint;
                axios
                .post(url, data_r, {
                    headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if(t_data.length > 0) {
                        setPatternData(t_data[0]);
                    }else{
                        setPatternData([]);
                    }
                })
                .catch((error) => {
                });
            }else{
                setPatternData([]);
            }
            event.preventDefault();
        }
    };
    const [PatternName, setPatternName] = useState("");
    const handleChangePatternName = (event) => {
        setPatternName(event.target.value);
    };
    const [BackupDatabaseFlag, setBackupDatabaseFlag] = useState(0);
    
    const [Menu5, setMenu5] = useState(1);   const [Menu6, setMenu6] = useState(1);
    const [Menu7, setMenu7] = useState(1);   const [Menu8, setMenu8] = useState(1);
    const [Menu9, setMenu9] = useState(1);   const [Menu10, setMenu10] = useState(1);
    const [Menu11, setMenu11] = useState(1); const [Menu12, setMenu12] = useState(1);
    const [Menu13, setMenu13] = useState(1); const [Menu14, setMenu14] = useState(1);
    const [Menu15, setMenu15] = useState(1); const [Menu16, setMenu16] = useState(1);
    const [Menu17, setMenu17] = useState(1); const [Menu18, setMenu18] = useState(1);
    const [Menu19, setMenu19] = useState(1); const [Menu20, setMenu20] = useState(1);
    const [Menu21, setMenu21] = useState(1); const [Menu22, setMenu22] = useState(1);
    const [Menu23, setMenu23] = useState(1); const [Menu24, setMenu24] = useState(1);
    const [Menu25, setMenu25] = useState(1); const [Menu26, setMenu26] = useState(1);
    const [Menu27, setMenu27] = useState(1); const [Menu28, setMenu28] = useState(1);
    const [Menu29, setMenu29] = useState(1); const [Menu30, setMenu30] = useState(1);
    const [Menu31, setMenu31] = useState(1); const [Menu32, setMenu32] = useState(1);
    const [Menu33, setMenu33] = useState(1); const [Menu34, setMenu34] = useState(1);
    const [Menu35, setMenu35] = useState(1); const [Menu36, setMenu36] = useState(1);
    const [Menu37, setMenu37] = useState(1); const [Menu38, setMenu38] = useState(1);
    const [Menu39, setMenu39] = useState(1); const [Menu40, setMenu40] = useState(1);
    const [Menu41, setMenu41] = useState(1); const [Menu42, setMenu42] = useState(1);
    const [Menu43, setMenu43] = useState(1); const [Menu44, setMenu44] = useState(1);
    const [Menu45, setMenu45] = useState(1); const [Menu46, setMenu46] = useState(1);
    const [Menu47, setMenu47] = useState(1); const [Menu48, setMenu48] = useState(1);
    const [Menu49, setMenu49] = useState(1); const [Menu50, setMenu50] = useState(1);
    const [Menu51, setMenu51] = useState(1); const [Menu52, setMenu52] = useState(1);
    const [Menu53, setMenu53] = useState(1); const [Menu54, setMenu54] = useState(1);
    const [Menu55, setMenu55] = useState(1); const [Menu56, setMenu56] = useState(1);
    const [Menu57, setMenu57] = useState(1); const [Menu58, setMenu58] = useState(1);
    const [Menu59, setMenu59] = useState(1); const [Menu60, setMenu60] = useState(1);
    const [Menu61, setMenu61] = useState(1); const [Menu62, setMenu62] = useState(1);
    const [Menu63, setMenu63] = useState(1); const [Menu64, setMenu64] = useState(1);
    const [Menu65, setMenu65] = useState(1); const [Menu66, setMenu66] = useState(1);
    const [Menu67, setMenu67] = useState(1); const [Menu68, setMenu68] = useState(1);
    const [Menu69, setMenu69] = useState(1); const [Menu70, setMenu70] = useState(1);
    const [Menu71, setMenu71] = useState(1); const [Menu72, setMenu72] = useState(1);
    const [Menu73, setMenu73] = useState(1); const [Menu74, setMenu74] = useState(1);
    const [Menu75, setMenu75] = useState(1); const [Menu76, setMenu76] = useState(1);
    const [Menu77, setMenu77] = useState(1); const [Menu78, setMenu78] = useState(1);
    const [Menu79, setMenu79] = useState(1); const [Menu80, setMenu80] = useState(1);
    const [Menu81, setMenu81] = useState(1); const [Menu82, setMenu82] = useState(1);
    const [Menu83, setMenu83] = useState(1); const [Menu84, setMenu84] = useState(1);
    const [Menu85, setMenu85] = useState(1);
    const [Menu86, setMenu86] = useState(1);
    const [Menu87, setMenu87] = useState(1);
    const [Menu88, setMenu88] = useState(1);
    const [Menu89, setMenu89] = useState(1);
    const [Menu90, setMenu90] = useState(1);
    const [Menu91, setMenu91] = useState(1);
    const [Menu92, setMenu92] = useState(1);
    const [Menu93, setMenu93] = useState(1);
    const [Menu94, setMenu94] = useState(1);
    const [Menu95, setMenu95] = useState(1);
    const [Menu96, setMenu96] = useState(1);
    const [Menu97, setMenu97] = useState(1);
    const [Menu98, setMenu98] = useState(1);
    const [Menu99, setMenu99] = useState(1);
    const [Menu100, setMenu100] = useState(1);
    const [Menu101, setMenu101] = useState(1);
    const [Menu102, setMenu102] = useState(1);
    const [Menu103, setMenu103] = useState(1);
    const [Menu104, setMenu104] = useState(1);
    const [Menu105, setMenu105] = useState(1);
    const [Menu106, setMenu106] = useState(1);
    const [Menu107, setMenu107] = useState(1);
    const [Menu108, setMenu108] = useState(1);
    const [Menu109, setMenu109] = useState(1);
    const [Menu110, setMenu110] = useState(1);
    const [Menu111, setMenu111] = useState(1);
    const [Menu112, setMenu112] = useState(1);
    const [Menu113, setMenu113] = useState(1);

    const handleChange = (event, index) => {
        let val = 0;
        if(event.target.checked) {
            val = 1;
        }
        switch (index) {
            case 5 : setMenu5(val); break;
            case 6 : setMenu6(val); break;
            case 7 : setMenu7(val); break;
            case 8 : setMenu8(val); break;
            case 9 : setMenu9(val); break;
            case 10 : setMenu10(val); break;
            case 11 : setMenu11(val); break;
            case 12 : setMenu12(val); break;
            case 13 : setMenu13(val); break;
            case 14 : setMenu14(val); break;
            case 15 : setMenu15(val); break;
            case 16 : setMenu16(val); break;
            case 17 : setMenu17(val); break;
            case 18 : setMenu18(val); break;
            case 19 : setMenu19(val); break;
            case 20 : setMenu20(val); break;
            case 21 : setMenu21(val); break;
            case 22 : setMenu22(val); break;
            case 23 : setMenu23(val); break;
            case 24 : setMenu24(val); break;
            case 25 : setMenu25(val); break;
            case 26 : setMenu26(val); break;
            case 27 : setMenu27(val); break;
            case 28 : setMenu28(val); break;
            case 29 : setMenu29(val); break;
            case 30 : setMenu30(val); break;
            case 31 : setMenu31(val); break;
            case 32 : setMenu32(val); break;
            case 33 : setMenu33(val); break;
            case 34 : setMenu34(val); break;
            case 35 : setMenu35(val); break;
            case 36 : setMenu36(val); break;
            case 37 : setMenu37(val); break;
            case 38 : setMenu38(val); break;
            case 39 : setMenu39(val); break;
            case 40 : setMenu40(val); break;
            case 41 : setMenu41(val); break;
            case 42 : setMenu42(val); break;
            case 43 : setMenu43(val); break;
            case 44 : setMenu44(val); break;
            case 45 : setMenu45(val); break;
            case 46 : setMenu46(val); break;
            case 47 : setMenu47(val); break;
            case 48 : setMenu48(val); break;
            case 49 : setMenu49(val); break;
            case 50 : setMenu50(val); break;
            case 51 : setMenu51(val); break;
            case 52 : setMenu52(val); break;
            case 53 : setMenu53(val); break;
            case 54 : setMenu54(val); break;
            case 55 : setMenu55(val); break;
            case 56 : setMenu56(val); break;
            case 57 : setMenu57(val); break;
            case 58 : setMenu58(val); break;
            case 59 : setMenu59(val); break;
            case 60 : setMenu60(val); break;
            case 61 : setMenu61(val); break;
            case 62 : setMenu62(val); break;
            case 63 : setMenu63(val); break;
            case 64 : setMenu64(val); break;
            case 65 : setMenu65(val); break;
            case 66 : setMenu66(val); break;
            case 67 : setMenu67(val); break;
            case 68 : setMenu68(val); break;
            case 69 : setMenu69(val); break;
            case 70 : setMenu70(val); break;
            case 71 : setMenu71(val); break;
            case 72 : setMenu72(val); break;
            case 73 : setMenu73(val); break;
            case 74 : setMenu74(val); break;
            case 75 : setMenu75(val); break;
            case 76 : setMenu76(val); break;
            case 77 : setMenu77(val); break;
            case 78 : setMenu78(val); break;
            case 79 : setMenu79(val); break;
            case 80 : setMenu80(val); break;
            case 81 : setMenu81(val); break;
            case 82 : setMenu82(val); break;
            case 83 : setMenu83(val); break;
            case 84 : setMenu84(val); break;
            case 85 : setMenu85(val); break;
            case 86 : setMenu86(val); break;
            case 87 : setMenu87(val); break;
            case 88 : setMenu88(val); break;
            case 89 : setMenu89(val); break;
            case 90 : setMenu90(val); break;
            case 91 : setMenu91(val); break;
            case 92 : setMenu92(val); break;
            case 93 : setMenu93(val); break;
            case 94 : setMenu94(val); break;
            case 95 : setMenu95(val); break;
            case 96 : setMenu96(val); break;
            case 97 : setMenu97(val); break;
            case 98 : setMenu98(val); break;
            case 99 : setMenu99(val); break;
            case 100 : setMenu100(val); break;
            case 101 : setMenu101(val); break;
            case 102 : setMenu102(val); break;
            case 103 : setMenu103(val); break;
            case 104 : setMenu104(val); break;
            case 105 : setMenu105(val); break;
            case 106 : setMenu106(val); break;
            case 107 : setMenu107(val); break;
            case 108 : setMenu108(val); break;
            case 109 : setMenu109(val); break;
            case 110 : setMenu110(val); break;
            case 111 : setMenu111(val); break;
            case 112 : setMenu112(val); break;
            case 113 : setMenu113(val); break;
            default: setMenu5(val);
        }
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#patternCheckDataWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };

    const allCheckPattern = (event) => {
        var checkboxes = document.querySelectorAll('#patternCheckDataWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = event.target.checked;
        }
        for (let i=5; i<=105; i++) {
            handleChange(event,i);
        }
    }

    const setPatternData = (result) => {
        if(result['sys_menu_pattern_id']) {
            setSysMenuPatternId(result['sys_menu_pattern_id']);
            setPatternCode(result['pattern_code']);
            setPatternName(result['pattern_name']);
            setBackupDatabaseFlag(result['backup_database_flag']);
            // 日時業務
            setMenu5(result['menu_daily_flag_1']); setMenu6(result['menu_daily_flag_2']); 
            setMenu7(result['menu_daily_flag_3']); setMenu8(result['menu_daily_flag_4']); 
            setMenu9(result['menu_daily_flag_5']); setMenu10(result['menu_daily_flag_6']);
            setMenu11(result['menu_daily_flag_7']); setMenu12(result['menu_daily_flag_8']); 
            setMenu13(result['menu_daily_flag_9']); setMenu14(result['menu_daily_flag_10']); 
            setMenu15(result['menu_daily_flag_11']); setMenu16(result['menu_daily_flag_12']);
            setMenu17(result['menu_daily_flag_13']); setMenu18(result['menu_daily_flag_14']); 
            setMenu19(result['menu_daily_flag_15']); setMenu20(result['menu_daily_flag_16']); 
            setMenu21(result['menu_daily_flag_17']); setMenu22(result['menu_daily_flag_18']);
            setMenu23(result['menu_daily_flag_19']); setMenu24(result['menu_daily_flag_20']); 
            setMenu25(result['menu_daily_flag_21']); setMenu26(result['menu_daily_flag_22']); 
            setMenu27(result['menu_daily_flag_23']); setMenu28(result['menu_daily_flag_24']);
            setMenu29(result['menu_daily_flag_25']); setMenu84(result['menu_daily_flag_26']); 
            setMenu85(result['menu_daily_flag_27']); setMenu111(result['menu_daily_flag_28']); 
            // 請求業務
            setMenu30(result['menu_invoice_flag_1']); setMenu31(result['menu_invoice_flag_2']); 
            setMenu32(result['menu_invoice_flag_3']); setMenu33(result['menu_invoice_flag_4']); 
            setMenu34(result['menu_invoice_flag_5']); setMenu35(result['menu_invoice_flag_6']); 
            // 在庫管理
            setMenu36(result['menu_stock_flag_1']); setMenu37(result['menu_stock_flag_2']); 
            setMenu38(result['menu_stock_flag_3']); setMenu39(result['menu_stock_flag_4']); 
            setMenu40(result['menu_stock_flag_5']); setMenu41(result['menu_stock_flag_6']); 
            setMenu42(result['menu_stock_flag_7']); setMenu43(result['menu_stock_flag_8']); 
            // 管理業務
            setMenu44(result['menu_manage_flag_1']); setMenu45(result['menu_manage_flag_2']); 
            setMenu46(result['menu_manage_flag_3']); setMenu47(result['menu_manage_flag_4']); 
            setMenu48(result['menu_manage_flag_5']); setMenu49(result['menu_manage_flag_6']);
            setMenu50(result['menu_manage_flag_7']); 
            // 繰越
            setMenu51(result['menu_carried_over_flag_1']); setMenu52(result['menu_carried_over_flag_2']); 
            setMenu53(result['menu_carried_over_flag_3']); 
            // マスタ管理
            setMenu54(result['menu_master_flag_1']); setMenu55(result['menu_master_flag_2']); 
            setMenu56(result['menu_master_flag_3']); setMenu57(result['menu_master_flag_4']); 
            setMenu58(result['menu_master_flag_5']); setMenu59(result['menu_master_flag_6']); 
            setMenu60(result['menu_master_flag_7']); setMenu61(result['menu_master_flag_8']); 
            setMenu62(result['menu_master_flag_9']); setMenu63(result['menu_master_flag_10']); 
            setMenu64(result['menu_master_flag_11']); setMenu65(result['menu_master_flag_12']); 
            setMenu66(result['menu_master_flag_13']); setMenu67(result['menu_master_flag_14']); 
            setMenu68(result['menu_master_flag_15']); setMenu69(result['menu_master_flag_16']); 
            // 導入時設定
            setMenu70(result['menu_start_setting_flag_1']); setMenu71(result['menu_start_setting_flag_2']); 
            setMenu72(result['menu_start_setting_flag_3']); setMenu73(result['menu_start_setting_flag_4']);
            setMenu86(result['menu_start_setting_flag_5']);
            setMenu74(result['menu_register_flag']); 
            // サポート
            setMenu75(result['menu_support_flag_1']); setMenu76(result['menu_support_flag_2']); 
            setMenu77(result['menu_support_flag_3']); setMenu78(result['menu_support_flag_4']); 
            setMenu79(result['menu_support_flag_5']); setMenu80(result['menu_restore_flag']); 
            // 作業員管理
            setMenu81(result['menu_worker_manage_flag_1']); setMenu82(result['menu_worker_manage_flag_2']);
            setMenu83(result['menu_worker_manage_flag_1']);
            //broken
            setMenu87(result['menu_broken_flag']);
            //csv
            setMenu88(result['csv_flag_1']);
            setMenu89(result['csv_flag_2']);
            setMenu90(result['csv_flag_3']);
            setMenu91(result['csv_flag_4']);
            setMenu92(result['csv_flag_5']);
            setMenu93(result['csv_flag_6']);
            setMenu94(result['csv_flag_7']);
            setMenu95(result['csv_flag_8']);
            setMenu96(result['csv_flag_9']);
            setMenu97(result['csv_flag_10']);
            setMenu98(result['csv_flag_11']);
            setMenu99(result['csv_flag_12']);
            setMenu100(result['csv_flag_13']);
            setMenu101(result['csv_flag_14']);
            setMenu102(result['csv_flag_15']);
            setMenu103(result['csv_flag_16']);
            setMenu104(result['csv_flag_17']);
            setMenu105(result['csv_flag_18']);
            setMenu113(result['csv_flag_19']);

            //bus
            setMenu106(result['bus_flag_1']);
            setMenu107(result['bus_flag_2']);
            setMenu108(result['bus_flag_3']);
            setMenu109(result['bus_flag_4']);
            setMenu112(result['bus_flag_5']);
            setMenu110(result['contact_flag_1']);

        }else{
            setSysMenuPatternId(0);
            setPatternName("");
            setBackupDatabaseFlag(0);
            // 日時業務
            setMenu5(1); setMenu6(1); setMenu7(1); setMenu8(1); 
            setMenu9(1); setMenu10(1); setMenu11(1); setMenu12(1); 
            setMenu13(1); setMenu14(1); setMenu15(1); setMenu16(1);
            setMenu17(1); setMenu18(1); setMenu19(1); setMenu20(1); 
            setMenu21(1); setMenu22(1); setMenu23(1); setMenu24(1); 
            setMenu25(1); setMenu26(1); setMenu27(1); setMenu28(1);
            setMenu29(1); setMenu84(1); setMenu85(1); 
            // 請求業務
            setMenu30(1); setMenu31(1); setMenu32(1); setMenu33(1); 
            setMenu34(1); setMenu35(1); 
            // 在庫管理
            setMenu36(1); setMenu37(1); setMenu38(1); setMenu39(1); 
            setMenu40(1); setMenu41(1); setMenu42(1); setMenu43(1); 
            // 管理業務
            setMenu44(1); setMenu45(1); setMenu46(1); setMenu47(1); 
            setMenu48(1); setMenu49(1); setMenu50(1); 
            // 繰越
            setMenu51(1); setMenu52(1); setMenu53(1); 
            // マスタ管理
            setMenu54(1); setMenu55(1); setMenu56(1); setMenu57(1); 
            setMenu58(1); setMenu59(1); setMenu60(1); setMenu61(1); 
            setMenu62(1); setMenu63(1); setMenu64(1); setMenu65(1); 
            setMenu66(1); setMenu67(1); setMenu68(1); setMenu69(1); 
            // 導入時設定
            setMenu70(1); setMenu71(1); setMenu72(1); setMenu73(1);
            setMenu74(1); setMenu86(1); 
            // サポート
            setMenu75(1); setMenu76(1); setMenu77(1); setMenu78(1); 
            setMenu79(1); setMenu80(1); 
            // 作業員管理
            setMenu81(1); setMenu82(1); setMenu83(1);
            //broken
            setMenu87(1);
            //csv
            setMenu88(1);
            setMenu89(1);
            setMenu90(1);
            setMenu91(1);
            setMenu92(1);
            setMenu93(1);
            setMenu94(1);
            setMenu95(1);
            setMenu96(1);
            setMenu97(1);
            setMenu98(1);
            setMenu99(1);
            setMenu100(1);
            setMenu101(1);
            setMenu102(1);
            setMenu103(1);
            setMenu104(1);
            setMenu105(1);
            setMenu106(1);
            setMenu107(1);
            setMenu108(1);
            setMenu109(1);
            setMenu110(1);
            setMenu111(1);
            setMenu112(1);
            setMenu113(1);
        }
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#patternCheckDataWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    }

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            csloading(true);
            const data = {
                userID: userData['id']
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMenuPatternEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(true);
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setPatternData(result);
                    }
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if(error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                }
            });
        }
	});

    const saveData = (event) => {
        let data = {};
        data['userID'] = userData['id'];
        if(SysMenuPatternId>0) {
            data['sys_menu_pattern_id'] = SysMenuPatternId;
        }

        data['pattern_code'] = PatternCode;
        data['pattern_name'] = PatternName;
        
        data['backup_database_flag'] = BackupDatabaseFlag;

        data['menu_daily_flag_1'] = Menu5; data['menu_daily_flag_2'] = Menu6; 
        data['menu_daily_flag_3'] = Menu7; data['menu_daily_flag_4'] = Menu8; 
        data['menu_daily_flag_5'] = Menu9; data['menu_daily_flag_6'] = Menu10;
        data['menu_daily_flag_7'] = Menu11; data['menu_daily_flag_8'] = Menu12; 
        data['menu_daily_flag_9'] = Menu13; data['menu_daily_flag_10'] = Menu14; 
        data['menu_daily_flag_11'] = Menu15; data['menu_daily_flag_12'] = Menu16;
        data['menu_daily_flag_13'] = Menu17; data['menu_daily_flag_14'] = Menu18; 
        data['menu_daily_flag_15'] = Menu19; data['menu_daily_flag_16'] = Menu20; 
        data['menu_daily_flag_17'] = Menu21; data['menu_daily_flag_18'] = Menu22;
        data['menu_daily_flag_19'] = Menu23; data['menu_daily_flag_20'] = Menu24; 
        data['menu_daily_flag_21'] = Menu25; data['menu_daily_flag_22'] = Menu26; 
        data['menu_daily_flag_23'] = Menu27; data['menu_daily_flag_24'] = Menu28;
        data['menu_daily_flag_25'] = Menu29; data['menu_daily_flag_26'] = Menu84; 
        data['menu_daily_flag_27'] = Menu85; data['menu_daily_flag_28'] = Menu111; 

        data['menu_invoice_flag_1'] = Menu30; data['menu_invoice_flag_2'] = Menu31;
        data['menu_invoice_flag_3'] = Menu32; data['menu_invoice_flag_4'] = Menu33; 
        data['menu_invoice_flag_5'] = Menu34; data['menu_invoice_flag_6'] = Menu35; 

        data['menu_stock_flag_1'] = Menu36; data['menu_stock_flag_2'] = Menu37;
        data['menu_stock_flag_3'] = Menu38; data['menu_stock_flag_4'] = Menu39; 
        data['menu_stock_flag_5'] = Menu40; data['menu_stock_flag_6'] = Menu41; 
        data['menu_stock_flag_7'] = Menu42; data['menu_stock_flag_8'] = Menu43;

        data['menu_manage_flag_1'] = Menu44; data['menu_manage_flag_2'] = Menu45; 
        data['menu_manage_flag_3'] = Menu46; data['menu_manage_flag_4'] = Menu47; 
        data['menu_manage_flag_5'] = Menu48; data['menu_manage_flag_6'] = Menu49;
        data['menu_manage_flag_7'] = Menu50; 

        data['menu_carried_over_flag_1'] = Menu51; data['menu_carried_over_flag_2'] = Menu52;
        data['menu_carried_over_flag_3'] = Menu53; 

        data['menu_master_flag_1'] = Menu54; data['menu_master_flag_2'] = Menu55;
        data['menu_master_flag_3'] = Menu56; data['menu_master_flag_4'] = Menu57; 
        data['menu_master_flag_5'] = Menu58; data['menu_master_flag_6'] = Menu59; 
        data['menu_master_flag_7'] = Menu60; data['menu_master_flag_8'] = Menu61;
        data['menu_master_flag_9'] = Menu62; data['menu_master_flag_10'] = Menu63; 
        data['menu_master_flag_11'] = Menu64; data['menu_master_flag_12'] = Menu65; 
        data['menu_master_flag_13'] = Menu66; data['menu_master_flag_14'] = Menu67;
        data['menu_master_flag_15'] = Menu68; data['menu_master_flag_16'] = Menu69; 

        data['menu_start_setting_flag_1'] = Menu70; data['menu_start_setting_flag_2'] = Menu71; 
        data['menu_start_setting_flag_3'] = Menu72; data['menu_start_setting_flag_4'] = Menu73;
        data['menu_register_flag'] = Menu74; data['menu_start_setting_flag_5'] = Menu86; 

        data['menu_support_flag_1'] = Menu75; data['menu_support_flag_2'] = Menu76;
        data['menu_support_flag_3'] = Menu77; data['menu_support_flag_4'] = Menu78; 
        data['menu_support_flag_5'] = Menu79; data['menu_restore_flag'] = Menu80; 

        data['menu_worker_manage_flag_1'] = Menu81; 
        data['menu_worker_manage_flag_2'] = Menu82;
        data['menu_worker_manage_flag_3'] = Menu83;

        data['menu_broken_flag'] = Menu87;

        data['csv_flag_1'] = Menu88;
        data['csv_flag_2'] = Menu89;
        data['csv_flag_3'] = Menu90;
        data['csv_flag_4'] = Menu91;
        data['csv_flag_5'] = Menu92;
        data['csv_flag_6'] = Menu93;
        data['csv_flag_7'] = Menu94;
        data['csv_flag_8'] = Menu95;
        data['csv_flag_9'] = Menu96;
        data['csv_flag_10'] = Menu97;
        data['csv_flag_11'] = Menu98;
        data['csv_flag_12'] = Menu99;
        data['csv_flag_13'] = Menu100;
        data['csv_flag_14'] = Menu101;
        data['csv_flag_15'] = Menu102;
        data['csv_flag_16'] = Menu103;
        data['csv_flag_17'] = Menu104;
        data['csv_flag_18'] = Menu105;
        data['csv_flag_19'] = Menu113;
        data['bus_flag_1'] = Menu106;
        data['bus_flag_2'] = Menu107;
        data['bus_flag_3'] = Menu108;
        data['bus_flag_4'] = Menu109;
        data['bus_flag_5'] = Menu112;
        data['contact_flag_1'] = Menu110;

        csloading(true);
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setMenuPatternEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data == 1) {
                    toast.success("登録致しました。", {autoClose:3000});
                }else if(response.data.data == 2){
                    toast.error("既に登録されたメニューパターンNO.です。", {autoClose:3000});
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
                    
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else{
                toast.error("登録に失敗しました。", {autoClose:3000});
            }
        });
        setOpen(false);
        event.preventDefault();
        
    }
    // ========================== starts code for search ===============================
    // ----------------------------- item type modal -----------------------------------
    const [showModalMenuPattern, setShowModalMenuPattern] = useState(false);
    const [MenuPatternSearchValue, setMenuPatternSearchValue] = useState("");
    const [MenuPatternTableData, setMenuPatternTableData] = useState([]);
    const loadMenuPatternModalData = (event,param) => {
        event.preventDefault();
        let code = PatternCode;
        setMenuPatternSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['item_type_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMenuPatternEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setMenuPatternTableData(response.data.data);
                setShowModalMenuPattern(true);
            })
            .catch((error) => {
            });
        }else{
            setMenuPatternTableData([]);
            setShowModalMenuPattern(true);
        }
    }
    // ========================== ends code for search ==================================
    // style array
    const style_input_20_wrap = [gstyle.cs_input_wrap, gstyle.w20];
    const style_row = [gstyle.cs_row];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_cat_wrap = [style.cs_cat_wrap];
    const style_input_30_wrap = [gstyle.cs_input_wrap, gstyle.w30];
    const style_all_check_wrap = [gstyle.cs_input_wrap, style.allcheckwrap];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_30 = [gstyle.cs_input, gstyle.w30];
    const style_check_box_1 = [style.cs_item_checkbox];
    const style_cat_item = [style.cs_cat_item];
    const style_cat_item_title = [style.cs_cat_item_title];
    const style_cat_item_name = [style.cs_item_name];

    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <MenuPatternModal show={showModalMenuPattern} onClose={()=>setShowModalMenuPattern(false)} width={'70%'}
                SearchValue={MenuPatternSearchValue} onChange={setMenuPatternSearchValue} 
                tableData={MenuPatternTableData} setTableData={setMenuPatternTableData}
                target={setPatternData} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>メニューパターン設定</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    <div className={gstyle.hyfiled}>
                        <div className={style_input_30_wrap.join(" ")}>
                            <div className={style_row.join(" ")}>
                                <div className={style_col_3.join(" ")}>
                                    <span className={gstyle.cs_span}>NO.</span>
                                </div>
                                <div className={style_col_3.join(" ")}>
                                    <span className={gstyle.cs_span}>名称</span>
                                </div>
                            </div>
                            <label className={style_label_wrap.join(" ")}>
                                <div className={gstyle.cs_flex}>
                                    <input type={'number'} className={style_input_30.join(" ")} id="PatternCode"
                                        value={PatternCode}
                                        onKeyDown={(e) => onKeyDownPatternCode(e)}
                                        onChange={handleChangePatternCode}/>
                                    <input type={'text'} className={style_input_bl_n.join(" ")} id="PatternName"
                                        value={PatternName} 
                                        onChange={handleChangePatternName} />
                                    <div className={gstyle.icon_search_wrap} onClick={(e)=>loadMenuPatternModalData(e,1)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className={style_all_check_wrap.join(" ")} style={{display:'flex', alignItems:'center', marginTop:20}}>
                            <input type={"checkbox"} className={style.cs_item_all_check} id="allCheckBtn" onChange={allCheckPattern}/>
                            <label className={style_label_wrap.join(" ")} htmlFor="allCheckBtn">
                                すべてチェック
                            </label>
                        </div>
                    </div>
                </div>
                <div id="patternCheckDataWrap">
                    {/* 日時業務 */}
                    <div className={gstyle.cs_card_1}>
                        <div className={gstyle.hyfiled}>
                            <div className={style_input_20_wrap.join(" ")}>
                                <label className={gstyle.cs_label_blue}>日時業務
                                </label>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            {/* 日時入力 5~10 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>日時入力</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu5==1?true:false}
                                                value={Menu5} onChange={(e)=>handleChange(e,5)} />
                                            <div className={style_cat_item_name.join(" ")}>入出庫指示登録</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu6==1?true:false}
                                                value={Menu6} onChange={(e)=>handleChange(e,6)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主申請承認</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu7==1?true:false}
                                                value={Menu7} onChange={(e)=>handleChange(e,7)} />
                                            <div className={style_cat_item_name.join(" ")}>在庫移動処理</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu8==1?true:false}
                                                value={Menu8} onChange={(e)=>handleChange(e,8)} />
                                            <div className={style_cat_item_name.join(" ")}>単位変更処理</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu9==1?true:false}
                                                value={Menu9} onChange={(e)=>handleChange(e,9)} />
                                            <div className={style_cat_item_name.join(" ")}>諸掛入力</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu10==1?true:false}
                                                value={Menu10} onChange={(e)=>handleChange(e,10)} />
                                            <div className={style_cat_item_name.join(" ")}>入金入力</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu84==1?true:false}
                                                value={Menu84} onChange={(e)=>handleChange(e,84)} />
                                            <div className={style_cat_item_name.join(" ")}>商品QRコード印刷</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu111==1?true:false}
                                                value={Menu111} onChange={(e)=>handleChange(e,111)} />
                                            <div className={style_cat_item_name.join(" ")}>倉庫QRコード印刷</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu85==1?true:false}
                                                value={Menu85} onChange={(e)=>handleChange(e,85)} />
                                            <div className={style_cat_item_name.join(" ")}>棚番QRコード印刷</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 日時帳票 11~19*/}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>日時帳票</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu11==1?true:false}
                                                value={Menu11} onChange={(e)=>handleChange(e,11)} />
                                            <div className={style_cat_item_name.join(" ")}>入庫リスト</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu12==1?true:false}
                                                value={Menu12} onChange={(e)=>handleChange(e,12)} />
                                            <div className={style_cat_item_name.join(" ")}>出庫リスト</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu13==1?true:false}
                                                value={Menu13} onChange={(e)=>handleChange(e,13)} />
                                            <div className={style_cat_item_name.join(" ")}>在庫移動リスト</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu14==1?true:false}
                                                value={Menu14} onChange={(e)=>handleChange(e,14)} />
                                            <div className={style_cat_item_name.join(" ")}>単位変更リスト</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu15==1?true:false}
                                                value={Menu15} onChange={(e)=>handleChange(e,15)} />
                                            <div className={style_cat_item_name.join(" ")}>諸掛リスト</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu16==1?true:false}
                                                value={Menu16} onChange={(e)=>handleChange(e,16)} />
                                            <div className={style_cat_item_name.join(" ")}>入金リスト</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu17==1?true:false}
                                                value={Menu17} onChange={(e)=>handleChange(e,17)} />
                                            <div className={style_cat_item_name.join(" ")}>入庫確認兼指示書</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu18==1?true:false}
                                                value={Menu18} onChange={(e)=>handleChange(e,18)} />
                                            <div className={style_cat_item_name.join(" ")}>ピッキングリスト</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu19==1?true:false}
                                                value={Menu19} onChange={(e)=>handleChange(e,19)} />
                                            <div className={style_cat_item_name.join(" ")}>出荷明細表</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 送り状 20~25 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>送り状</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu20==1?true:false}
                                                value={Menu20} onChange={(e)=>handleChange(e,20)} />
                                            <div className={style_cat_item_name.join(" ")}>送り状発行【標準】 (BP2013)</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu21==1?true:false}
                                                value={Menu21} onChange={(e)=>handleChange(e,21)} />
                                            <div className={style_cat_item_name.join(" ")}>送り状発行【重量】 (BP2013)</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu22==1?true:false}
                                                value={Menu22} onChange={(e)=>handleChange(e,22)} />
                                            <div className={style_cat_item_name.join(" ")}>送り状発行【容積】 (BP2013)</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu23==1?true:false}
                                                value={Menu23} onChange={(e)=>handleChange(e,23)} />
                                            <div className={style_cat_item_name.join(" ")}>送り状発行【標準】 (50013CP)</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu24==1?true:false}
                                                value={Menu24} onChange={(e)=>handleChange(e,24)} />
                                            <div className={style_cat_item_name.join(" ")}>送り状発行【重量】 (50013CP)</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu25==1?true:false}
                                                value={Menu25} onChange={(e)=>handleChange(e,25)} />
                                            <div className={style_cat_item_name.join(" ")}>送り状発行【容積】 (50013CP)</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 荷札 26~29 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>荷札</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu26==1?true:false}
                                                value={Menu26} onChange={(e)=>handleChange(e,26)} />
                                            <div className={style_cat_item_name.join(" ")}>荷札発行【出庫伝票】 (M11BP)</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu27==1?true:false}
                                                value={Menu27} onChange={(e)=>handleChange(e,27)} />
                                            <div className={style_cat_item_name.join(" ")}>荷札発行【出庫伝票】 (M11BPT)</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu28==1?true:false}
                                                value={Menu28} onChange={(e)=>handleChange(e,28)} />
                                            <div className={style_cat_item_name.join(" ")}>荷札発行【単独】 (M11BP)</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu29==1?true:false}
                                                value={Menu29} onChange={(e)=>handleChange(e,29)} />
                                            <div className={style_cat_item_name.join(" ")}>荷札発行【単独】 (M11BPT)</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 請求業務 */}
                    <div className={gstyle.cs_card_1}>
                        <div className={gstyle.hyfiled}>
                            <div className={style_input_20_wrap.join(" ")}>
                                <label className={gstyle.cs_label_blue}>請求業務
                                </label>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            {/* 請求 30~35 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>請求</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu30==1?true:false}
                                                value={Menu30} onChange={(e)=>handleChange(e,30)} />
                                            <div className={style_cat_item_name.join(" ")}>請求一覧表</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu31==1?true:false}
                                                value={Menu31} onChange={(e)=>handleChange(e,31)} />
                                            <div className={style_cat_item_name.join(" ")}>請求明細書</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu32==1?true:false}
                                                value={Menu32} onChange={(e)=>handleChange(e,32)} />
                                            <div className={style_cat_item_name.join(" ")}>請求明細書(三期縦型)</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu33==1?true:false}
                                                value={Menu33} onChange={(e)=>handleChange(e,33)} />
                                            <div className={style_cat_item_name.join(" ")}>請求明細書(50003W)</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu34==1?true:false}
                                                value={Menu34} onChange={(e)=>handleChange(e,34)} />
                                            <div className={style_cat_item_name.join(" ")}>諸掛明細表</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu35==1?true:false}
                                                value={Menu35} onChange={(e)=>handleChange(e,35)} />
                                            <div className={style_cat_item_name.join(" ")}>請求数量確認表</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 在庫管理 */}
                    <div className={gstyle.cs_card_1}>
                        <div className={gstyle.hyfiled}>
                            <div className={style_input_20_wrap.join(" ")}>
                                <label className={gstyle.cs_label_blue}>在庫管理
                                </label>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            {/* 在庫確認 36~39 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>在庫確認</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu36==1?true:false}
                                                value={Menu36} onChange={(e)=>handleChange(e,36)} />
                                            <div className={style_cat_item_name.join(" ")}>在庫画面参照</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu37==1?true:false}
                                                value={Menu37} onChange={(e)=>handleChange(e,37)} />
                                            <div className={style_cat_item_name.join(" ")}>商品受払画面参照</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu38==1?true:false}
                                                value={Menu38} onChange={(e)=>handleChange(e,38)} />
                                            <div className={style_cat_item_name.join(" ")}>商品受払帳</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu39==1?true:false}
                                                value={Menu39} onChange={(e)=>handleChange(e,39)} />
                                            <div className={style_cat_item_name.join(" ")}>在庫日報</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 報告書 40 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>報告書</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu40==1?true:false} 
                                                value={Menu40} onChange={(e)=>handleChange(e,40)} />
                                            <div className={style_cat_item_name.join(" ")}>在庫報告書</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 在庫一覧表 41~43 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>在庫一覧表</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu41==1?true:false}
                                                value={Menu41} onChange={(e)=>handleChange(e,41)} />
                                            <div className={style_cat_item_name.join(" ")}>在庫評価一覧表</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu42==1?true:false}
                                                value={Menu42} onChange={(e)=>handleChange(e,42)} />
                                            <div className={style_cat_item_name.join(" ")}>倉庫別在庫評価一覧表</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu43==1?true:false}
                                                value={Menu43} onChange={(e)=>handleChange(e,43)} />
                                            <div className={style_cat_item_name.join(" ")}>商品別在庫評価一覧表</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 管理業務 */}
                    <div className={gstyle.cs_card_1}>
                        <div className={gstyle.hyfiled}>
                            <div className={style_input_20_wrap.join(" ")}>
                                <label className={gstyle.cs_label_blue}>管理業務
                                </label>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            {/* 保管月報 44~45 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>保管月報</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu44==1?true:false}
                                                value={Menu44} onChange={(e)=>handleChange(e,44)} />
                                            <div className={style_cat_item_name.join(" ")}>品種別保管月報</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu45==1?true:false}
                                                value={Menu45} onChange={(e)=>handleChange(e,45)} />
                                            <div className={style_cat_item_name.join(" ")}>倉庫別保管月報</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 荷主管理 46~47 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>荷主管理</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu46==1?true:false}
                                                value={Menu46} onChange={(e)=>handleChange(e,46)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主別入出庫明細表</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu47==1?true:false}
                                                value={Menu47} onChange={(e)=>handleChange(e,47)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主別入出庫日計表</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 倉庫管理 48~49 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>倉庫管理</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu48==1?true:false}
                                                value={Menu48} onChange={(e)=>handleChange(e,48)} />
                                            <div className={style_cat_item_name.join(" ")}>倉庫別入出庫明細表</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu49==1?true:false}
                                                value={Menu49} onChange={(e)=>handleChange(e,49)} />
                                            <div className={style_cat_item_name.join(" ")}>倉庫別入出庫日計表</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 届け先管理 50 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>届け先管理</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu50==1?true:false}
                                                value={Menu50} onChange={(e)=>handleChange(e,50)} />
                                            <div className={style_cat_item_name.join(" ")}>届け先出荷集計表</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 繰越 */}
                    <div className={gstyle.cs_card_1}>
                        <div className={gstyle.hyfiled}>
                            <div className={style_input_20_wrap.join(" ")}>
                                <label className={gstyle.cs_label_blue}>繰越
                                </label>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            {/* 月次繰越 51 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>月次繰越</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu51==1?true:false}
                                                value={Menu51} onChange={(e)=>handleChange(e,51)} />
                                            <div className={style_cat_item_name.join(" ")}>月次繰越処理</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 履歴参照 52~53 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>履歴参照</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu52==1?true:false}
                                                value={Menu52} onChange={(e)=>handleChange(e,52)} />
                                            <div className={style_cat_item_name.join(" ")}>年次履歴作成処理</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu53==1?true:false}
                                                value={Menu53} onChange={(e)=>handleChange(e,53)} />
                                            <div className={style_cat_item_name.join(" ")}>年次履歴参照処理</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* マスタ管理 */}
                    <div className={gstyle.cs_card_1}>
                        <div className={gstyle.hyfiled}>
                            <div className={style_input_20_wrap.join(" ")}>
                                <label className={gstyle.cs_label_blue}>マスタ管理
                                </label>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            {/* 基本マスタ 54~67 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>基本マスタ</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu54==1?true:false}
                                                value={Menu54} onChange={(e)=>handleChange(e,54)} />
                                            <div className={style_cat_item_name.join(" ")}>倉庫マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu55==1?true:false}
                                                value={Menu55} onChange={(e)=>handleChange(e,55)} />
                                            <div className={style_cat_item_name.join(" ")}>棚マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu56==1?true:false}
                                                value={Menu56} onChange={(e)=>handleChange(e,56)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu57==1?true:false}
                                                value={Menu57} onChange={(e)=>handleChange(e,57)} />
                                            <div className={style_cat_item_name.join(" ")}>荷送人マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu58==1?true:false}
                                                value={Menu58} onChange={(e)=>handleChange(e,58)} />
                                            <div className={style_cat_item_name.join(" ")}>届け先マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu59==1?true:false}
                                                value={Menu59} onChange={(e)=>handleChange(e,59)} />
                                            <div className={style_cat_item_name.join(" ")}>品種マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu60==1?true:false}
                                                value={Menu60} onChange={(e)=>handleChange(e,60)} />
                                            <div className={style_cat_item_name.join(" ")}>商品マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu61==1?true:false}
                                                value={Menu61} onChange={(e)=>handleChange(e,61)} />
                                            <div className={style_cat_item_name.join(" ")}>ロットマスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu62==1?true:false}
                                                value={Menu62} onChange={(e)=>handleChange(e,62)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主品番マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu63==1?true:false}
                                                value={Menu63} onChange={(e)=>handleChange(e,63)} />
                                            <div className={style_cat_item_name.join(" ")}>運送業者マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu64==1?true:false}
                                                value={Menu64} onChange={(e)=>handleChange(e,64)} />
                                            <div className={style_cat_item_name.join(" ")}>諸掛マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu65==1?true:false}
                                                value={Menu65} onChange={(e)=>handleChange(e,65)} />
                                            <div className={style_cat_item_name.join(" ")}>摘要マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu66==1?true:false}
                                                value={Menu66} onChange={(e)=>handleChange(e,66)} />
                                            <div className={style_cat_item_name.join(" ")}>地区マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu67==1?true:false}
                                                value={Menu67} onChange={(e)=>handleChange(e,67)} />
                                            <div className={style_cat_item_name.join(" ")}>分類マスタ</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 価格改定 68 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>価格改定</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu68==1?true:false} 
                                                value={Menu68} onChange={(e)=>handleChange(e,68)} />
                                            <div className={style_cat_item_name.join(" ")}>価格改定処理</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 商品複写 69 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>商品複写</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu69==1?true:false} 
                                                value={Menu69} onChange={(e)=>handleChange(e,69)} />
                                            <div className={style_cat_item_name.join(" ")}>商品複写処理</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 導入時設定 */}
                    <div className={gstyle.cs_card_1}>
                        <div className={gstyle.hyfiled}>
                            <div className={style_input_20_wrap.join(" ")}>
                                <label className={gstyle.cs_label_blue}>導入時設定
                                </label>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            {/* 基本設定 70~71 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>基本設定</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu70==1?true:false}
                                                value={Menu70} onChange={(e)=>handleChange(e,70)} />
                                            <div className={style_cat_item_name.join(" ")}>基本事項メンテナンス</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu71==1?true:false}
                                                value={Menu71} onChange={(e)=>handleChange(e,71)} />
                                            <div className={style_cat_item_name.join(" ")}>送り状出力設定メンテナンス</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 残高設定 72~73 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>残高設定</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu72==1?true:false} 
                                                value={Menu72} onChange={(e)=>handleChange(e,72)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主残高設定処理</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu73==1?true:false}
                                                value={Menu73} onChange={(e)=>handleChange(e,73)} />
                                            <div className={style_cat_item_name.join(" ")}>商品残数設定処理</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* 管理 74 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>管理</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu86==1?true:false}
                                                value={Menu86} onChange={(e)=>handleChange(e,86)} />
                                            <div className={style_cat_item_name.join(" ")}>メニューパターン設定</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu74==1?true:false}
                                                value={Menu74} onChange={(e)=>handleChange(e,74)} />
                                            <div className={style_cat_item_name.join(" ")}>ユーザー設定登録</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* サポート */}
                    <div className={gstyle.cs_card_1}>
                        <div className={gstyle.hyfiled}>
                            <div className={style_input_20_wrap.join(" ")}>
                                <label className={gstyle.cs_label_blue}>サポート
                                </label>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            {/* サポート処理 75~79 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>サポート処理</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")} 
                                                checked={Menu75==1?true:false}
                                                value={Menu75} onChange={(e)=>handleChange(e,75)} />
                                            <div className={style_cat_item_name.join(" ")}>データ再計算処理</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu76==1?true:false}
                                                value={Menu76} onChange={(e)=>handleChange(e,76)} />
                                            <div className={style_cat_item_name.join(" ")}>マスタコード変更処理</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu77==1?true:false}
                                                value={Menu77} onChange={(e)=>handleChange(e,77)} />
                                            <div className={style_cat_item_name.join(" ")}>住所メンテナンス</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu78==1?true:false}
                                                value={Menu78} onChange={(e)=>handleChange(e,78)} />
                                            <div className={style_cat_item_name.join(" ")}>消費税率マスタc</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu79==1?true:false}
                                                value={Menu79} onChange={(e)=>handleChange(e,79)} />
                                            <div className={style_cat_item_name.join(" ")}>消費税率マスタ</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* データ復旧 80 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>データ復旧</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu80==1?true:false} 
                                                value={Menu80} onChange={(e)=>handleChange(e,80)} />
                                            <div className={style_cat_item_name.join(" ")}>データリストア</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 作業員管理 */}
                    <div className={gstyle.cs_card_1}>
                        <div className={gstyle.hyfiled}>
                            <div className={style_input_20_wrap.join(" ")}>
                                <label className={gstyle.cs_label_blue}>作業員管理
                                </label>
                            </div>
                        </div>
                        <div className={style_row.join(" ")}>
                            {/* 作業管理 81~82 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>作業管理</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu81==1?true:false}  
                                                value={Menu81} onChange={(e)=>handleChange(e,81)} />
                                            <div className={style_cat_item_name.join(" ")}>スケジュール表</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu82==1?true:false} 
                                                value={Menu82} onChange={(e)=>handleChange(e,82)} />
                                            <div className={style_cat_item_name.join(" ")}>社員リスト</div>
                                        </label> 
                                    </div>
                                </div>
                            </div>
                            {/* 87 */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>破損報告</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu87==1?true:false}  
                                                value={Menu87} onChange={(e)=>handleChange(e,87)} />
                                            <div className={style_cat_item_name.join(" ")}>破損報告</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* csv */}
                            <div className={style_col_3.join(" ")}>
                                <div className={style_cat_wrap.join(" ")}>
                                    <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>CSVインポート</span>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu88==1?true:false}  
                                                value={Menu88} onChange={(e)=>handleChange(e,88)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu89==1?true:false}  
                                                value={Menu89} onChange={(e)=>handleChange(e,89)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主（適用）マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu104==1?true:false}  
                                                value={Menu104} onChange={(e)=>handleChange(e,104)} />
                                            <div className={style_cat_item_name.join(" ")}>倉庫マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu105==1?true:false}  
                                                value={Menu105} onChange={(e)=>handleChange(e,105)} />
                                            <div className={style_cat_item_name.join(" ")}>棚マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu90==1?true:false}  
                                                value={Menu90} onChange={(e)=>handleChange(e,90)} />
                                            <div className={style_cat_item_name.join(" ")}>運送業者マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu91==1?true:false}  
                                                value={Menu91} onChange={(e)=>handleChange(e,91)} />
                                            <div className={style_cat_item_name.join(" ")}>分類マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu92==1?true:false}  
                                                value={Menu92} onChange={(e)=>handleChange(e,92)} />
                                            <div className={style_cat_item_name.join(" ")}>品種マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu93==1?true:false}  
                                                value={Menu93} onChange={(e)=>handleChange(e,93)} />
                                            <div className={style_cat_item_name.join(" ")}>商品マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu94==1?true:false}  
                                                value={Menu94} onChange={(e)=>handleChange(e,94)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主品番マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu95==1?true:false}  
                                                value={Menu95} onChange={(e)=>handleChange(e,95)} />
                                            <div className={style_cat_item_name.join(" ")}>荷送人マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu96==1?true:false}  
                                                value={Menu96} onChange={(e)=>handleChange(e,96)} />
                                            <div className={style_cat_item_name.join(" ")}>諸掛マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu97==1?true:false}  
                                                value={Menu97} onChange={(e)=>handleChange(e,97)} />
                                            <div className={style_cat_item_name.join(" ")}>地区マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu98==1?true:false}  
                                                value={Menu98} onChange={(e)=>handleChange(e,98)} />
                                            <div className={style_cat_item_name.join(" ")}>摘要マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu99==1?true:false}  
                                                value={Menu99} onChange={(e)=>handleChange(e,99)} />
                                            <div className={style_cat_item_name.join(" ")}>届け先マスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu100==1?true:false}  
                                                value={Menu100} onChange={(e)=>handleChange(e,100)} />
                                            <div className={style_cat_item_name.join(" ")}>ﾛｯﾄマスタ</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu101==1?true:false}  
                                                value={Menu101} onChange={(e)=>handleChange(e,101)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主別改定日順</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu102==1?true:false}  
                                                value={Menu102} onChange={(e)=>handleChange(e,102)} />
                                            <div className={style_cat_item_name.join(" ")}>荷主別商品順</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu103==1?true:false}  
                                                value={Menu103} onChange={(e)=>handleChange(e,103)} />
                                            <div className={style_cat_item_name.join(" ")}>入出庫</div>
                                        </label>
                                    </div>
                                    <div className={style_cat_item.join(" ")}>
                                        <label>
                                            <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                                checked={Menu113==1?true:false}
                                                value={Menu113} onChange={(e)=>handleChange(e,113)} />
                                            <div className={style_cat_item_name.join(" ")}>在庫</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* 作業員管理 */}
                <div className={gstyle.cs_card_1}>
                    <div className={style_row.join(" ")}>
                        {/* バース 106~109 */}
                        <div className={style_col_3.join(" ")}>
                            <div className={style_cat_wrap.join(" ")}>
                                <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>バース</span>
                                    </div>
                                <div className={style_cat_item.join(" ")}>
                                    <label>
                                        <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                            checked={Menu106==1?true:false}  
                                            value={Menu106} onChange={(e)=>handleChange(e,106)} />
                                        <div className={style_cat_item_name.join(" ")}>バース設定</div>
                                    </label>
                                </div>
                                <div className={style_cat_item.join(" ")}>
                                    <label>
                                        <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                            checked={Menu108==1?true:false}  
                                            value={Menu108} onChange={(e)=>handleChange(e,108)} />
                                        <div className={style_cat_item_name.join(" ")}>バース予約</div>
                                    </label>
                                </div>
                                <div className={style_cat_item.join(" ")}>
                                    <label>
                                        <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                            checked={Menu109==1?true:false} 
                                            value={Menu109} onChange={(e)=>handleChange(e,109)} />
                                        <div className={style_cat_item_name.join(" ")}>スケジュール表</div>
                                    </label> 
                                </div>
                                <div className={style_cat_item.join(" ")}>
                                    <label>
                                        <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                            checked={Menu112==1?true:false}  
                                            value={Menu112} onChange={(e)=>handleChange(e,112)} />
                                        <div className={style_cat_item_name.join(" ")}>通知</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* バース 105~108 */}
                        <div className={style_col_3.join(" ")}>
                            <div className={style_cat_wrap.join(" ")}>
                                <div className={style_cat_item.join(" ")}>
                                        <span className={style_cat_item_title.join(" ")}>荷主からの連絡</span>
                                    </div>
                                <div className={style_cat_item.join(" ")}>
                                    <label>
                                        <input type={'checkbox'} className={style_check_box_1.join(" ")}
                                            checked={Menu110==1?true:false}  
                                            value={Menu110} onChange={(e)=>handleChange(e,110)} />
                                        <div className={style_cat_item_name.join(" ")}>運送会社通知報告</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* button 設定 */}
                <div className={gstyle.btn_update_wrap}>
                    <button className={gstyle.btn_update} onClick={handleOpen} >設定</button>
                </div>
                <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
            </div>
        </div>
    )
}

export default MenuPatternSetting;