import { Menu } from "antd";
import style from './Sidebar.module.css';
import './Sidebar.css';
import Logo from './logo.png';
import LogoShort from './logo_short.png';
import { Link } from 'react-router-dom'
import * as useJwt from '../../../auth/jwt/useJwt.js';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from "axios";
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { Modal, Button } from "antd";

var isFlag = false;
var isFocus = true;

const App = ({ isSidebarOpen, setIsSidebarOpen }) => {

  const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
  const userData = JSON.parse(useJwt.getUserData());
  const [loaded, setLoaded] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);

  const [openKeys, setOpenKeys] = useState([]);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);

  const rootKeys = ["1", "31", "37", "49", "65", "69", "88", "140", "150", "99", "98"];
  const subRootKeys = ["2", "9", "19", "26", "38", "43", "45", "50", "53", "56", "70", "89", "92", "95", "100", "114"];

  const onOpenChange = items => {
    const latestOpenKey = items.find(key => openKeys.indexOf(key) === -1);
    let temp = [];
    if (rootKeys.indexOf(latestOpenKey) === -1) {
      if (subRootKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(items);
      } else {
        temp.push(openKeys[0]);
        temp.push(latestOpenKey);
        setOpenKeys(latestOpenKey ? temp : defaultOpenKeys);
      }
    } else {
      temp.push(latestOpenKey);
      setOpenKeys(latestOpenKey ? temp : defaultOpenKeys);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      let data = {};
      data['userID'] = userData['id'];
      // data['ddd'] = '123';
      let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getBadgeCount;

      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          let data = response.data.data;
          setDriverBadge(data.driver);
          setCommandBadge(data.inout);
          setApprovalBadge(data.unApproval);
          if (useJwt.getPopupCount() < data.total + data.driverTotal) {
            setModal2Open(true);
            useJwt.setPopupCount(data.total + data.driverTotal);
          }
        })
        .catch((error) => {
          if(error.response.status == "401"){
            useJwt.logout();
            navigate("/login");
          }  
        });
      setLoaded(!loaded);
    }, 10000);
  })

  const handleClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
    isFlag = isSidebarOpen;
  };
  const navigate = useNavigate();
  const logout = (event) => {
    useJwt.logout();
    navigate("/login");
  }

  const changeFocus = (e) => {
    e.preventDefault();

    if (isFlag == true && isFocus == true) {
      setIsSidebarOpen(!isSidebarOpen);
      isFocus = false;
    }
  }

  const changeFocusOut = (e) => {

    if (e.clientX < 185) return;
    if (isFlag) {
      setIsSidebarOpen(!isSidebarOpen);
      isFocus = true;
    }
  }
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const [driverBadge, setDriverBadge] = useState(0);
  const [commandBadge, setCommandBadge] = useState(0);
  const [approvalBadge, setApprovalBadge] = useState(0);
  const subItems = [];
console.log(menuPatternData)
  if (menuPatternData['menu_daily_flag_1'] == 1 || userData['sys_role_id'] == 10000) {
    subItems.push(getItem(<Link to='/DailyWork/jobRegistry' className={style.link}>入出庫指示登録</Link>, "3",));
  }
  if (menuPatternData['menu_daily_flag_3'] == 1 || userData['sys_role_id'] == 10000) {
    subItems.push(getItem(<Link to='/DailyWork/stockMoveSlip' className={style.link}>在庫移動処理</Link>, "5"));
  }
  if (menuPatternData['menu_daily_flag_4'] == 1 || userData['sys_role_id'] == 10000) {
    subItems.push(getItem(<Link to='/DailyWork/changeAmountSlip' className={style.link}>単位変更処理</Link>, "6"));
  }
  if (menuPatternData['menu_daily_flag_5'] == 1 || userData['sys_role_id'] == 10000) {
    subItems.push(getItem(<Link to='/DailyWork/freightageSlip' className={style.link}>諸掛入力</Link>, "7"));
  }
  if (menuPatternData['menu_daily_flag_6'] == 1 || userData['sys_role_id'] == 10000) {
    subItems.push(getItem(<Link to='/DailyWork/deposits' className={style.link}>入金入力</Link>, "8"));
  }
  if (menuPatternData['menu_daily_flag_26'] == 1 || userData['sys_role_id'] == 10000) {
    subItems.push(getItem(<Link to='/DailyWork/JobQRcode/qrCode' className={style.link}>商品QRコード印刷</Link>, "120"));
  }
  if (menuPatternData['menu_daily_flag_28'] == 1 || userData['sys_role_id'] == 10000) {
    subItems.push(getItem(<Link to='/DailyWork/warehouse/qrCode' className={style.link}>倉庫QRコード印刷</Link>, "221",));
  }
  if (menuPatternData['menu_daily_flag_27'] == 1 || userData['sys_role_id'] == 10000) {
    subItems.push(getItem(<Link to='/DailyWork/location/qrCode' className={style.link}>棚番QRコード印刷</Link>, "121",));
  }
  if (menuPatternData['menu_daily_flag_2'] == 1 || userData['sys_role_id'] == 10000) {
    subItems.push(getItem(<Link to='/DailyWork/jobRegistry' className={style.link}>荷主申請承認荷主申請承認</Link>, "4",));
  }

  const subItems_2 = [];

  if (menuPatternData['menu_daily_flag_7'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_2.push(getItem(<Link to='/DailyWork/arrivalSlipList' className={style.link}>入庫リスト</Link>, "10"));
  }
  if (menuPatternData['menu_daily_flag_8'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_2.push(getItem(<Link to='/DailyWork/leavingSlipList' className={style.link}>出庫リスト</Link>, "11"));
  }
  if (menuPatternData['menu_daily_flag_9'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_2.push(getItem(<Link to='/DailyWork/stockMoveSlipList' className={style.link}>在庫移動リスト</Link>, "12"));
  }
  if (menuPatternData['menu_daily_flag_10'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_2.push(getItem(<Link to='/DailyWork/changeAmountList' className={style.link}>単位変更リスト</Link>, "13"));
  }
  if (menuPatternData['menu_daily_flag_11'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_2.push(getItem(<Link to='/DailyWork/freightageSlipList' className={style.link}>諸掛リスト</Link>, "14"));
  }
  if (menuPatternData['menu_daily_flag_12'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_2.push(getItem(<Link to='/DailyWork/depositList' className={style.link}>入金リスト</Link>, "15"));
  }
  if (menuPatternData['menu_daily_flag_13'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_2.push(getItem(<Link to='/DailyWork/arrivalSlipItemList' className={style.link}>入庫確認兼指示書</Link>, "16"));
  }
  if (menuPatternData['menu_daily_flag_14'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_2.push(getItem(<Link to='/DailyWork/pickingList' className={style.link}>ピッキングリスト</Link>, "17"));
  }
  if (menuPatternData['menu_daily_flag_15'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_2.push(getItem(<Link to='/DailyWork/leavingItemList' className={style.link}>出荷明細表</Link>, "18"));
  }

  const subItems_3 = [];

  if (menuPatternData['menu_daily_flag_16'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_3.push(getItem(<Link to='/DailyWork/invoiceItemList' className={style.link}>【標準】(BP2013)</Link>, "20"));
  }
  if (menuPatternData['menu_daily_flag_17'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_3.push(getItem(<Link to='/DailyWork/invoiceItemWeightList' className={style.link}>【重量】(BP2013)</Link>, "21"));
  }
  if (menuPatternData['menu_daily_flag_18'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_3.push(getItem(<Link to='/DailyWork/invoiceItemVolumeList' className={style.link}>【容積】(BP2013)</Link>, "22"));
  }
  if (menuPatternData['menu_daily_flag_19'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_3.push(getItem(<Link to='#' className={style.link}>【標準】(50013CP)</Link>, "23"));
  }
  if (menuPatternData['menu_daily_flag_20'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_3.push(getItem(<Link to='#' className={style.link}>【重要】(50013CP)</Link>, "24"));
  }
  if (menuPatternData['menu_daily_flag_21'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_3.push(getItem(<Link to='#' className={style.link}>【容積】(50013CP)</Link>, "25"));
  }

  const subItems_4 = [];

  if (menuPatternData['menu_daily_flag_22'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_4.push(getItem(<Link to='/DailyWork/tagItemList' className={style.link}>【出庫伝票】(M11BP)</Link>, "27"));
  }
  if (menuPatternData['menu_daily_flag_23'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_4.push(getItem(<Link to='#' className={style.link}>【出庫伝票】(M11BPT)</Link>, "28"));
  }
  if (menuPatternData['menu_daily_flag_24'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_4.push(getItem(<Link to='#' className={style.link}>【単独】(M11BP)</Link>, "29"));
  }
  if (menuPatternData['menu_daily_flag_25'] == 1 || userData['sys_role_id'] == 10000) {
    subItems_4.push(getItem(<Link to='#' className={style.link}>【単独】(M11BPT)</Link>, "30"));
  }

  const subItems2_1 = [];

  if (menuPatternData['menu_invoice_flag_1'] == 1 || userData['sys_role_id'] == 10000) {
    subItems2_1.push(getItem(<Link to='/billing/billingList' className={style.link}>請求一覧表</Link>, "32", <i className="pointer material-icons">format_list_bulleted</i>));
  }
  if (menuPatternData['menu_invoice_flag_2'] == 1 || userData['sys_role_id'] == 10000) {
    subItems2_1.push(getItem(<Link to='/billing/billingStatement' className={style.link}>/(50003W)</Link>, "33", <i className="pointer material-icons">description</i>));
  }
  if (menuPatternData['menu_invoice_flag_3'] == 1 || userData['sys_role_id'] == 10000) {
    subItems2_1.push(getItem(<Link to='/billing/billingStatement2' className={style.link}>三期縦型</Link>, "34", <i className="pointer material-icons">description</i>));
  }
  if (menuPatternData['menu_invoice_flag_5'] == 1 || userData['sys_role_id'] == 10000) {
    subItems2_1.push(getItem(<Link to='/billing/freightageList' className={style.link}>諸掛明細書</Link>, "35", <i className="pointer material-icons">description</i>));
  }
  if (menuPatternData['menu_invoice_flag_6'] == 1 || userData['sys_role_id'] == 10000) {
    subItems2_1.push(getItem(<Link to='/billing/billingQuantityList' className={style.link}>請求数量確認表</Link>, "36", <i className="pointer material-icons">description</i>));
  }

  const subItems3_1 = [];

  if (menuPatternData['menu_stock_flag_1'] == 1 || userData['sys_role_id'] == 10000) {
    subItems3_1.push(getItem(<Link to='/Stock/stockListByItem' className={style.link}>在庫画面</Link>, "39"));
  }
  if (menuPatternData['menu_stock_flag_2'] == 1 || userData['sys_role_id'] == 10000) {
    subItems3_1.push(getItem(<Link to='/Stock/stockHistoryByItem' className={style.link}>商品受払画面</Link>, "40"));
  }
  if (menuPatternData['menu_stock_flag_3'] == 1 || userData['sys_role_id'] == 10000) {
    subItems3_1.push(getItem(<Link to='/Stock/stockHistoryByDate' className={style.link}>商品受払帳</Link>, "41"));
  }
  if (menuPatternData['menu_stock_flag_4'] == 1 || userData['sys_role_id'] == 10000) {
    subItems3_1.push(getItem(<Link to='/Stock/stockDailyReport' className={style.link}>在庫日報</Link>, "42"));
  }

  const subItems3_2 = [];

  if (menuPatternData['menu_stock_flag_5'] == 1 || userData['sys_role_id'] == 10000) {
    subItems3_2.push(getItem(<Link to='/Stock/stockListByShipper' className={style.link}>在庫報告書</Link>, "44"));
  }

  const subItems3_3 = [];

  if (menuPatternData['menu_stock_flag_6'] == 1 || userData['sys_role_id'] == 10000) {
    subItems3_3.push(getItem(<Link to='/Stock/stockListByType' className={style.link}>在庫評価一覧表</Link>, "46"));
  }

  if (menuPatternData['menu_stock_flag_7'] == 1 || userData['sys_role_id'] == 10000) {
    subItems3_3.push(getItem(<Link to='/Stock/stockListByWarehouse' className={style.link}>倉庫別在庫評価一覧表</Link>, "47"));
  }

  if (menuPatternData['menu_stock_flag_8'] == 1 || userData['sys_role_id'] == 10000) {
    subItems3_3.push(getItem(<Link to='/Stock/stockListByWarehouse2' className={style.link}>倉庫別在庫一覧表</Link>, "48"));
  }

  const subItems4_1 = [];

  if (menuPatternData['menu_manage_flag_1'] == 1 || userData['sys_role_id'] == 10000) {
    subItems4_1.push(getItem(<Link to='/administrator/itemStorageMonthReport' className={style.link}>品種別保管月報</Link>, "51"));
  }

  if (menuPatternData['menu_manage_flag_2'] == 1 || userData['sys_role_id'] == 10000) {
    subItems4_1.push(getItem(<Link to='/administrator/warehouseStorageMonthReport' className={style.link}>倉庫別保管月報</Link>, "52"));
  }

  const subItems4_2 = [];

  if (menuPatternData['menu_manage_flag_3'] == 1 || userData['sys_role_id'] == 10000) {
    subItems4_2.push(getItem(<Link to='/administrator/shipperInOutList' className={style.link}>荷主別入出庫明細表</Link>, "54"));
  }

  if (menuPatternData['menu_manage_flag_4'] == 1 || userData['sys_role_id'] == 10000) {
    subItems4_2.push(getItem(<Link to='/administrator/shipperInOutDailyList' className={style.link}>荷主別入出庫日計表</Link>, "55"));
  }

  const subItems4_3 = [];

  if (menuPatternData['menu_manage_flag_5'] == 1 || userData['sys_role_id'] == 10000) {
    subItems4_3.push(getItem(<Link to='/administrator/warehouseInOutList' className={style.link}>倉庫別入出庫明細表</Link>, "57"));
  }

  if (menuPatternData['menu_manage_flag_6'] == 1 || userData['sys_role_id'] == 10000) {
    subItems4_3.push(getItem(<Link to='/administrator/warehouseInOutDailyList' className={style.link}>倉庫別在庫評価一覧表</Link>, "58"));
  }

  const subItems5 = [];

  if (menuPatternData['menu_carried_over_flag_2'] == 1 || userData['sys_role_id'] == 10000) {
    subItems5.push(getItem(<Link to='/carryForward/yearHistoryMake' className={style.link}>年次履歴作成処理</Link>, "63"));
  }

  if (menuPatternData['menu_carried_over_flag_3'] == 1 || userData['sys_role_id'] == 10000) {
    subItems5.push(getItem(<Link to='/carryForward/yearHistoryReference' className={style.link}>年次履歴参照処理</Link>, "64"));
  }

  const subItems6 = [];

  if (menuPatternData['menu_worker_manage_flag_1'] == 1 || userData['sys_role_id'] == 10000) {
    subItems6.push(getItem(<Link to='/workermanagement/schedulemanagement' className={style.link}>スケジュール表</Link>, "66", <i className="pointer material-icons">calendar_month</i>));
  }

  if (menuPatternData['menu_worker_manage_flag_2'] == 1 || userData['sys_role_id'] == 10000) {
    subItems6.push(getItem(<Link to='/workerManagement/userIndex' className={style.link}>社員リスト</Link>, "67", <i className="pointer material-icons">groups</i>));
  }

  const subItems7 = [];

  if (menuPatternData['menu_master_flag_1'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/warehouse' className={style.link}>倉庫マスタ</Link>, "71"));
  }

  if (menuPatternData['menu_master_flag_2'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/location' className={style.link}>棚マスタ</Link>, "72"));
  }

  if (menuPatternData['menu_master_flag_3'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/shipper' className={style.link}>荷主マスタ</Link>, "73"));
  }

  if (menuPatternData['menu_master_flag_4'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/consignor' className={style.link}>荷送人マスタ</Link>, "74"));
  }

  if (menuPatternData['menu_master_flag_5'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/distination' className={style.link}>届け先マスタ</Link>, "75"));
  }

  if (menuPatternData['menu_master_flag_6'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/item-type' className={style.link}>品種マスタ</Link>, "76"));
  }

  if (menuPatternData['menu_master_flag_7'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/item' className={style.link}>商品マスタ</Link>, "77"));
  }

  if (menuPatternData['menu_master_flag_8'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/rot' className={style.link}>ロットマスタ</Link>, "78"));
  }

  if (menuPatternData['menu_master_flag_9'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/shipperItem' className={style.link}>荷主品番マスタ</Link>, "79"));
  }

  if (menuPatternData['menu_master_flag_10'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/carrier' className={style.link}>運送業者マスタ</Link>, "80"));
  }

  if (menuPatternData['menu_master_flag_11'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/freightage' className={style.link}>諸掛マスタ</Link>, "81"));
  }

  if (menuPatternData['menu_master_flag_12'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/summary' className={style.link}>概要マスタ</Link>, "82"));
  }

  if (menuPatternData['menu_master_flag_13'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/area' className={style.link}>地区マスタ</Link>, "83"));
  }

  if (menuPatternData['menu_master_flag_14'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/category' className={style.link}>分類マスタ</Link>, "84"));
  }

  if (menuPatternData['menu_master_flag_15'] == 1 || userData['sys_role_id'] == 10000) {
    subItems7.push(getItem(<Link to='/master/tax' className={style.link}>消費税率マスタ</Link>, "85"));
  }

  const subItems8_1 = [];

  if (menuPatternData['menu_start_setting_flag_1'] == 1 || userData['sys_role_id'] == 10000) {
    subItems8_1.push(getItem(<Link to='/main-setting' className={style.link}>基本設定メンテナンス</Link>, "90"));
  }

  if (menuPatternData['menu_start_setting_flag_2'] == 1 || userData['sys_role_id'] == 10000) {
    subItems8_1.push(getItem(<Link to='/invoice-setting' className={style.link}>送り状出力設定メンテナンス</Link>, "91"));
  }

  const subItems8_2 = [];

  if (menuPatternData['menu_start_setting_flag_3'] == 1 || userData['sys_role_id'] == 10000) {
    subItems8_2.push(getItem(<Link to='/shipper-balance-setting' className={style.link}>荷主残高設定処理</Link>, "93"));
  }

  if (menuPatternData['menu_start_setting_flag_4'] == 1 || userData['sys_role_id'] == 10000) {
    subItems8_2.push(getItem(<Link to='/stock-balance-setting' className={style.link}>商品残数設定処理</Link>, "94"));
  }

  const subItems8_3 = [];

  if (menuPatternData['menu_start_setting_flag_5'] == 1 || userData['sys_role_id'] == 10000) {
    subItems8_3.push(getItem(<Link to='/menu-pattern-setting' className={style.link}>メニューパターン設定</Link>, "96"));
  }

  if (menuPatternData['menu_register_flag'] == 1 || userData['sys_role_id'] == 10000) {
    subItems8_3.push(getItem(<Link to='/userRegister' className={style.link}>ユーザー設定登録</Link>, "97"));
  }

  const subItems9_1 = [];

  if (menuPatternData['csv_flag_1'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/shipperImport' className={style.link}>荷主マスタ</Link>, "101"));
  }

  if (menuPatternData['csv_flag_2'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/shipperItemImport' className={style.link}>荷主（適用）マスタ</Link>, "102"));
  }

  if (menuPatternData['csv_flag_17'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/warehouse' className={style.link}>倉庫マスタ</Link>, "130"));
  }

  if (menuPatternData['csv_flag_18'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/location' className={style.link}>棚マスタ</Link>, "131"));
  }

  if (menuPatternData['csv_flag_3'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/carrier' className={style.link}>運送業者マスタ</Link>, "103"));
  }

  if (menuPatternData['csv_flag_4'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/category' className={style.link}>分類マスタ</Link>, "104"));
  }

  if (menuPatternData['csv_flag_5'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/itemType' className={style.link}>品種マスタ</Link>, "105"));
  }

  if (menuPatternData['csv_flag_6'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/item' className={style.link}>商品マスタ</Link>, "106"));
  }

  if (menuPatternData['csv_flag_7'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/shipperItemCode' className={style.link}>荷主品番マスタ</Link>, "107"));
  }

  if (menuPatternData['csv_flag_8'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/consignor' className={style.link}>荷送人マスタ</Link>, "108"));
  }

  if (menuPatternData['csv_flag_9'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/freightage' className={style.link}>諸掛マスタ</Link>, "109"));
  }

  if (menuPatternData['csv_flag_10'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/area' className={style.link}>地区マスタ</Link>, "110"));
  }

  if (menuPatternData['csv_flag_11'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/summary' className={style.link}>摘要マスタ</Link>, "111"));
  }

  if (menuPatternData['csv_flag_12'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/destination' className={style.link}>届け先マスタ</Link>, "112"));
  }

  if (menuPatternData['csv_flag_13'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_1.push(getItem(<Link to='/csvImport/rot' className={style.link}>ﾛｯﾄマスタ</Link>, "113"));
  }

  const subItems9_2 = [];

  if (menuPatternData['csv_flag_14'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_2.push(getItem(<Link to='/csvImport/priceDay' className={style.link}>荷主別改定日順</Link>, "115"));
  }

  if (menuPatternData['csv_flag_15'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_2.push(getItem(<Link to='/csvImport/priceItem' className={style.link}>荷主別商品順</Link>, "116"));
  }

  const subItems9_3 = [];

  if (menuPatternData['csv_flag_16'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_3.push(getItem(<Link to='/csvImport/items' className={style.link}>入出庫</Link>, "1151"));
  }

  if (menuPatternData['csv_flag_19'] == 1 || userData['sys_role_id'] == 10000) {
    subItems9_3.push(getItem(<Link to='/csvImport/stock' className={style.link}>在庫</Link>, "166"));
  }

  const subItems10 = [];

  if (menuPatternData['bus_flag_1'] == 1 || userData['sys_role_id'] == 10000) {
    subItems10.push(getItem(<Link to='/bus/setting' className={style.link}>バース設定</Link>, "117"));
  }

  if (menuPatternData['bus_flag_2'] == 1 || userData['sys_role_id'] == 10000) {
    subItems10.push(getItem(<Link to='/bus/driver' className={style.link}>運送会社リスト</Link>, "118"));
  }

  if (menuPatternData['bus_flag_3'] == 1 || userData['sys_role_id'] == 10000) {
    subItems10.push(getItem(<Link to='/bus/schedule/registry' className={style.link}>バース予約</Link>, "120"));
  }

  if (menuPatternData['bus_flag_4'] == 1 || userData['sys_role_id'] == 10000) {
    subItems10.push(getItem(<Link to='/bus/schedule/list' className={style.link}>スケジュール表</Link>, "121"));
  }

  if (menuPatternData['bus_flag_5'] == 1 || userData['sys_role_id'] == 10000) {
    subItems10.push(getItem(<Link to='/bus/notification' className={style.link}>通知</Link>, "161"));
  }

  const subItems11 = [];

  if (menuPatternData['contact_flag_1'] == 1 || userData['sys_role_id'] == 10000) {
    subItems11.push(getItem(<Link to='/contact/transport' className={style.link}>バース設定</Link>, "151"));
  }

  const items = [
    subItems.length + subItems_2.length + subItems_3.length + subItems_4.length > 0 &&
    getItem(<Link to='/' className={style.link}>日次業務
      {
        approvalBadge != 0 && <div className={style.div_badge}><div className={style.badge}>{approvalBadge}</div></div>
      }
    </Link>, "1", <i className="pointer material-icons">home</i>, [
      subItems.length > 0 && getItem("日時入力", "2", <i className="pointer material-icons">work</i>, subItems),
      subItems_2.length > 0 && getItem("日時帳票", "9", <i className="pointer material-icons">table_view</i>, subItems_2),
      subItems_3.length > 0 && getItem("送り状", "19", <i className="pointer material-icons">receipt</i>, subItems_3),
      subItems_4.length > 0 && getItem("荷札", "26", <i className="pointer material-icons">tag</i>, subItems_4),
    ]),
    subItems2_1.length > 0 &&
    getItem(<Link to='/billing' className={style.link}>請求管理</Link>, "31", <i className="pointer material-icons">attach_money</i>, subItems2_1),
    subItems3_1.length + subItems3_2.length + subItems3_3.length > 0 &&
    getItem(<Link to='/inventory' className={style.link}>在庫管理</Link>, "37", <i className="pointer material-icons">inventory</i>, [
      subItems3_1.length > 0 && getItem("在庫確認", "38", <i className="pointer material-icons">factory</i>, subItems3_1),
      subItems3_2.length > 0 && getItem("報告書", "43", <i className="pointer material-icons">description</i>, subItems3_2),
      subItems3_3.length > 0 && getItem("倉庫一覧管理", "45", <i className="pointer material-icons">format_list_bulleted</i>, subItems3_3),
    ]),
    (subItems4_1.length + subItems4_2.length + subItems4_3.length > 0 && menuPatternData['menu_manage_flag_7'] == 1) &&
    getItem(<Link to='/administrator' className={style.link}>管理業務</Link>, "49", <i className="pointer material-icons">manage_accounts</i>, [
      subItems4_1.length > 0 && getItem("保管月報", "50", <i className="pointer material-icons">report</i>, subItems4_1),
      subItems4_2.length > 0 && getItem("荷主管理", "53", <i className="pointer material-icons">category</i>, subItems4_2),
      subItems4_3.length > 0 && getItem("倉庫管理", "56", <i className="pointer material-icons">warehouse</i>, subItems4_3),
      (menuPatternData['menu_manage_flag_7'] == 1 || userData['sys_role_id'] == 10000) ? getItem(<Link to='/administrator/destinationOutList' className={style.link}>届け先出荷集計表</Link>, "59", <i className="pointer material-icons">smartphone</i>) : '',
    ]),
    // (subItems5.length > 0 || menuPatternData['menu_carried_over_flag_1'] == 1) && 
    // getItem(<Link to='/carryForward' className={style.link}>繰越処理</Link>, "60", <i className="pointer material-icons">turn_right</i>, [
    //   (menuPatternData['menu_carried_over_flag_1'] == 1 || userData['sys_role_id'] == 10000) ? getItem(<Link to='/carryForward/monthCarryForward' className={style.link}>月時繰越処理</Link>, "61", <i className="pointer material-icons">turn_right</i>) : '',
    //   subItems5.length > 0 &&  getItem("履歴参照", "62", <i className="pointer material-icons">manage_search</i>, subItems5),
    // ]),
    subItems6.length > 0 &&
    getItem(<Link to='/workerManagement' className={style.link}>作業管理</Link>, "65", <i className="pointer material-icons">directions_walk</i>, subItems6),
    (subItems7.length > 0 || menuPatternData['menu_master_flag_15'] == 1 || menuPatternData['menu_master_flag_16'] == 1 || userData['sys_role_id'] == 10000) &&
    getItem(<Link to='/master' className={style.link}>マスタ管理</Link>, "69", <i className="pointer material-icons">dataset</i>, [
      subItems7.length > 0 && getItem("基本マスタ", "70", <i className="pointer material-icons">indeterminate_check_box</i>, subItems7),
      (menuPatternData['menu_master_flag_15'] == 1 || userData['sys_role_id'] == 10000) ? getItem(<Link to='/master/priceRevision' className={style.link}>価格改定処理</Link>, "86", <i className="pointer material-icons">price_change</i>) : '',
      (menuPatternData['menu_master_flag_16'] == 1 || userData['sys_role_id'] == 10000) ? getItem(<Link to='/master/itemCopy' className={style.link}>商品複写体処理</Link>, "87", <i className="pointer material-icons">copy_all</i>) : '',
    ]),
    (subItems8_1.length + subItems8_2.length + subItems8_3.length > 0) &&
    getItem(<Link to='/setting' className={style.link}>導入時設定</Link>, "88", <i className="pointer material-icons">settings_suggest</i>, [
      subItems8_1.length > 0 && getItem("基本設定", "89", <i className="pointer material-icons">settings_suggest</i>, subItems8_1),
      subItems8_2.length > 0 && getItem("残高設定", "92", <i className="pointer material-icons">attach_money</i>, subItems8_2),
      subItems8_3.length > 0 && getItem("管理", "95", <i className="pointer material-icons">manage_accounts</i>, subItems8_3),
    ]),
    (subItems10.length > 0) &&
    getItem(<Link to='/bus' className={style.link} style={{ display: 'flex', alignItems: 'center' }}>バース設定
      {
        driverBadge != 0 && <div className={style.div_badge}><div className={style.badge}>{driverBadge}</div></div>
      }
    </Link>, "140", <i className="pointer material-icons">local_shipping</i>, [
      (menuPatternData['bus_flag_1'] == 1 || userData['sys_role_id'] == 10000) ?
        getItem(<Link to='/bus/setting' className={style.link}>バース設定</Link>, "141",
          <i className="pointer material-icons">pallet</i>) : '',
      // (menuPatternData['bus_flag_2'] == 1 || userData['sys_role_id'] == 10000) ? 
      // getItem(<Link to='/bus/driver' className={style.link}>運送会社リスト</Link>, "142", 
      // <i className="pointer material-icons">recent_actors</i>) : '',    
      (menuPatternData['bus_flag_3'] == 1 || userData['sys_role_id'] == 10000) ?
        getItem(<Link to='/bus/schedule/index' className={style.link}>バース予約</Link>, "143",
          <i className="pointer material-icons">departure_board</i>) : '',
      (menuPatternData['bus_flag_4'] == 1 || userData['sys_role_id'] == 10000) ?
        getItem(<Link to='/bus/schedule/list' className={style.link}>スケジュール表</Link>, "144",
          <i className="pointer material-icons">list_alt</i>) : '',
      (menuPatternData['bus_flag_5'] == 1 || userData['sys_role_id'] == 10000) ?
        getItem(<Link to='/bus/notification' className={style.link}>通知</Link>, "145",
          <i className="pointer material-icons">notifications_active</i>) : '',

    ]),
    (subItems11.length > 0) &&
    getItem(<Link to='/contact' className={style.link} style={{ display: 'flex', alignItems: 'center' }}>荷主連絡
      {
        commandBadge != 0 && <div className={style.div_badge}><div className={style.badge}>{commandBadge}</div></div>
      }
    </Link>, "150", <i className="pointer material-icons">contacts</i>, [
      (menuPatternData['bus_flag_1'] == 1 || userData['sys_role_id'] == 10000) ?
        getItem(<Link to='/contact/transport' className={style.link}>運送会社通知報告</Link>, "151",
          <i className="pointer material-icons">contact_mail</i>) : '',
    ]),
    menuPatternData['menu_broken_flag'] == 1 ? getItem(<Link to='/broken/report' className={style.link}>破損報告</Link>, "98", <i className="pointer material-icons">bug_report</i>) : '',
    (subItems9_1.length + subItems9_2.length + subItems9_3.length > 0 || userData['sys_role_id'] == 10000) &&
    getItem(<Link to='/csvImport' className={style.link}>CSVインポート</Link>, "99", <i className="pointer material-icons">publish</i>, [
      subItems9_1.length > 0 ? getItem("マスターインポート", "100", <i className="pointer material-icons">article</i>, subItems9_1) : '',
      subItems9_2.length > 0 ? getItem("価格改定インポート", "114", <i className="pointer material-icons">price_change</i>, subItems9_2) : '',
      subItems9_3.length > 0 ? getItem("入出庫インポート", "117", <i className="pointer material-icons">list_alt</i>, subItems9_3) : '',
    ]),
    getItem(<a onClick={logout}>ログアウト</a>, "118", <i className="pointer material-icons">logout</i>),
  ];

  const commandLink = (e) => {
    setModal2Open(false);

    navigate("DailyWork/jobRegistry");
  }

  const driverCommandLink = (e) => {
    setModal2Open(false);

    navigate("bus/notification");
  }

  return (
    <div>
      <div className={style.logo_area} style={{ display: 'flex', position: 'inherit' }}>
        <div style={{ display: 'flex', justifyContent: 'center', minWidth: 55, alignItems: 'center' }} id="logo">
          <img src={LogoShort} className={style.logo_close} alt="logo" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 160 }}>
          <img src={Logo} className={style.logo} alt="logo" />
        </div>
        <div className={isSidebarOpen ? style.left_area : style.left_area_moved}>
          <div className={isSidebarOpen ? style.btn_trigger_clicked : style.btn_trigger} onClick={handleClick}>
            <span className={style.bar1}></span>
            <span className={style.bar2}></span>
            <span className={style.bar3}></span>
          </div>
        </div>
      </div>
      <div className="sideBar">
        <Menu
          defaultSelectedKeys={["1"]}
          // defaultOpenKeys={["sub1"]}
          mode={"inline"}
          theme="dark"
          inlineCollapsed={!isSidebarOpen}
          items={items}
          onMouseOver={changeFocus}
          onMouseOut={changeFocusOut}
          forceSubMenuRender={false}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        />
      </div>
      <Modal
        centered
        closable={false}
        open={modal2Open}
        footer={[<Button type="primary" onClick={(e) => setModal2Open(false)}>確認</Button>]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 20 }}>
          <p>荷主から<a onClick={(e) => commandLink(e)}>指示</a>を受けました。 よろしくお願いします。</p>
          {driverBadge > 0 &&
            <p>荷主から<a onClick={(e) => driverCommandLink(e)}>ドライバーの予約</a>依頼がありました。 よろしくお願いします。</p>
          }
        </div>
      </Modal>
    </div>
  );
};
export default App;
