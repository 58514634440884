/* eslint-disable eqeqeq */
import style from './Carrier.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import CarrierModal from '../../../components/common/modal/CarrierModal';
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select } from 'antd';

const Carrier = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    const [open, setOpen] = React.useState(false);

    const handleOpen = (e) => {
        if(CarrierCode == "") {
            toast.error("コードを入力してください。", {autoClose:3000});       // please enter code
            setOpen(false);
            return;
        }

        if(CarrierName == "") {
            toast.error("名称を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }

        if(CarrierNameKana == "") {
            toast.error("フリガナを入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        
        if(CarrierZip1 == "" || CarrierZip2 == "") {
            toast.error("郵便番号を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }

        if(CarrierAddress1 == "") {
            toast.error("住所（都道府県）を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }
        if(CarrierAddress2 == "") {
            toast.error("市区町村を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }

        if(CarrierTel1 == "" || CarrierTel2 == "" || CarrierTel3 == "") {
            toast.error("電話番号を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }

        if(CarrierFax1 == "" || CarrierFax2 == "" || CarrierFax3 == "") {
            toast.error("FAX番号を入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }

        if(CarrierEmail != "") {
            var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!CarrierEmail.match(mailFormat)){
                toast.error("正しいメールアドレスを入力してください。", {autoClose:3000});
                setOpen(false);
                return;
            }
        }else{
            toast.error("メールアドレスを入力してください。", {autoClose:3000});
            setOpen(false);
            return;
        }

        for(var i = 0; i<mainTableData.length; i++){

            if(mainTableData[i]['bus_name'] == ""){
                toast.error("バースを入力してください。", {autoClose:3000});
                return;
            }
            if(mainTableData[i]['car_number'] == ""){
                toast.error("バースを入力してください。", {autoClose:3000});
                return;
            }
            if(mainTableData[i]['car_standard'] == ""){
                toast.error("バースを入力してください。", {autoClose:3000});
                return;
            }

        }

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // Carrier ID
    const [SysCarrierId, setSysCarrierId] = useState(0);
    const [CarrierCode, setCarrierCode] = useState("");
    const handleChangeCarrierCode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>6) {
            str = str.substring(0, 6);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierCode(str);
    };
    const [CarrierACode, setCarrierACode] = useState("");
    const handleChangeCarrierACode = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>3) {
            str = str.substring(0, 3);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierACode(str);
    };
    const onKeyDownCarrierCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            var deg = useJwt.beforeDeg(7, event.target.value)
            setCarrierCode(deg)
            if(param == 1) {
                getCarrierData(deg, CarrierACode);
                document.getElementById('input2').focus();
            }else{
                getCarrierData(CarrierCode, deg);
                document.getElementById('input3').focus();
            }
            event.preventDefault();
        }
    };
    const getCarrierData = (code,acode) => {
        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = code;
            if(acode != "") {
                data['carrier_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response1) => {
                let t_data = response1.data.data;
                if(t_data.length > 0) {
                    setCarrierData(t_data[0]);
                    getDriverData(t_data[0]['sys_carrier_id']);
                }else{
                    setCarrierData([]);
                    gridMainTable([]);
                }
            })
            .catch((error) => {
            });
        }else{
            setCarrierData([]);
        }
    }
    
    const [CarrierName, setCarrierName] = useState("");
    const handleChangeCarrierName = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 3);
        }
        setCarrierName(str);
    };
    const [CarrierNameKana, setCarrierNameKana] = useState("");
    const handleChangeCarrierNameKana = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setCarrierNameKana(str);
    };
    const [CarrierAName, setCarrierAName] = useState("");
    const handleChangeCarrierAName = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setCarrierAName(str);
    };

    const [CarrierZip1, setCarrierZip1] = useState("");
    const handleChangeCarrierZip1 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierZip1(str);
    };
    const [CarrierZip2, setCarrierZip2] = useState("");
    const handleChangeCarrierZip2 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierZip2(str);
    };
    const getAddress = (event) => {
        if(CarrierZip1 != "" && CarrierZip2 != "") {
            let googleApiUrl = "https://maps.googleapis.com/maps/api/geocode/json";
            let postcodeParam = "?components=postal_code:"+CarrierZip1+"-"+CarrierZip2+"&language=ja";
            let apiKey = "&key="+useJwt.getGoogleApiKey();
            let url = googleApiUrl + postcodeParam + apiKey;
            csloading(true);
            axios
            .get(url, "")
            .then((response1) => {
                if(response1.data.status == "OK") {
                    let address_components = response1.data.results[0]['address_components'];
                    console.log(address_components)
                    let code_len = address_components.length;
                    let address1 = "";
                    if(address_components[code_len-2]['long_name']) {
                        address1 = address_components[code_len-2]['long_name'];
                    }
                    let address2 = "";
                    if(address_components[code_len-3]['long_name'] && address_components[code_len-3]['types'][0] != "postal_code") {
                        address2 = address_components[code_len-3]['long_name'];
                    }

                    let town = "";
                    if(code_len > 4){
                      town = address_components[code_len - 4]['long_name'];
                    }else{
                        town = "工業団地";
                    }
                    setCarrierAddress1(address1);
                    setCarrierAddress2(address2);
                    setCarrierTown(town);
                }else{ // ZERO_RESULTS
                    setCarrierAddress1("");
                    setCarrierAddress2("");
                    setCarrierTown("");
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
            });
        }
        event.preventDefault();
    }

    const [CarrierAddress1, setCarrierAddress1] = useState("");
    const handleChangeCarrierAddress1 = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setCarrierAddress1(str);
    };
    const [CarrierAddress2, setCarrierAddress2] = useState("");
    const handleChangeCarrierAddress2 = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setCarrierAddress2(str);
    };
    const [CarrierAddress3, setCarrierAddress3] = useState("");
    const handleChangeCarrierAddress3 = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setCarrierAddress3(str);
    };

    const [CarrierTown, setCarrierTown] = useState("");
    const handleChangeCarrierTown = (event) => {
        let str = event.target.value;
        if(str.length>30) {
            str = str.substring(0, 30);
        }
        setCarrierTown(str);
    };

    const [CarrierTel1, setCarrierTel1] = useState("");
    const handleChangeCarrierTel1 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierTel1(str);
    };
    const [CarrierTel2, setCarrierTel2] = useState("");
    const handleChangeCarrierTel2 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierTel2(str);
    };
    const [CarrierTel3, setCarrierTel3] = useState("");
    const handleChangeCarrierTel3 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierTel3(str);
    };
    const [CarrierFax1, setCarrierFax1] = useState("");
    const handleChangeCarrierFax1 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierFax1(str);
    };
    const [CarrierFax2, setCarrierFax2] = useState("");
    const handleChangeCarrierFax2 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierFax2(str);
    };
    const [CarrierFax3, setCarrierFax3] = useState("");
    const handleChangeCarrierFax3 = (event) => {
        let z1= /^[0-9]*$/;
        let str = event.target.value;
        if(str.length>4) {
            str = str.substring(0, 4);
        }
        if(!z1.test(str)) { 
            str = "";
        }
        setCarrierFax3(str);
    };
    const [CarrierEmail, setCarrierEmail] = useState("");
    const handleChangeCarrierEmail = (event) => {
        let str = event.target.value;
        setCarrierEmail(str);
    };

    const setCarrierData = (result) => {
        if(result['sys_carrier_id']) {
            setSysCarrierId(result['sys_carrier_id']);
            setCarrierCode(result['carrier_code']);
            setCarrierACode(result['carrier_a_code']);
            setCarrierName(result['carrier_name']);
            setCarrierAName(result['carrier_a_name']);
            setCarrierNameKana(result['carrier_name_kana']);
            if(result['carrier_zip'] != null){
                const postcode_ary = result['carrier_zip'].split("-");
                if(postcode_ary[0]) {
                    setCarrierZip1(postcode_ary[0]);
                }
                if(postcode_ary[1]) {
                    setCarrierZip2(postcode_ary[1]);
                }
            }
            if(result['carrier_address_1'] != null) {
                setCarrierAddress1(result['carrier_address_1']);
            }
            if(result['carrier_address_2'] != null) {
                setCarrierAddress2(result['carrier_address_2']);
            }
            if(result['carrier_address_3'] != null) {
                setCarrierAddress3(result['carrier_address_3']);
            }
            if(result['carrier_town'] != null) {
                setCarrierTown(result['carrier_town']);
            }else{
                setCarrierTown("");
            }
            if(result['carrier_tel'] != null){
                const phone_ary = result['carrier_tel'].split("-");
                if(phone_ary[0]) {
                    setCarrierTel1(phone_ary[0]);
                }
                if(phone_ary[1]) {
                    setCarrierTel2(phone_ary[1]);
                }
                if(phone_ary[2]) {
                    setCarrierTel3(phone_ary[2]);
                }
            }
            if(result['carrier_fax'] != null){
                const fax_ary = result['carrier_fax'].split("-");
                if(fax_ary[0]) {
                    setCarrierFax1(fax_ary[0]);
                }
                if(fax_ary[1]) {
                    setCarrierFax2(fax_ary[1]);
                }
                if(fax_ary[2]) {
                    setCarrierFax3(fax_ary[2]);
                }
            }
            if(result['carrier_email'] != null) {
                setCarrierEmail(result['carrier_email']);
            }

            getDriverData(result['sys_carrier_id']);
        }else{
            setSysCarrierId(0);
            setCarrierName("");
            setCarrierAName("");
            setCarrierNameKana("");
            setCarrierZip1(""); setCarrierZip2("");
            setCarrierAddress1("");setCarrierAddress2("");setCarrierAddress3("");setCarrierTown("");
            setCarrierTel1(""); setCarrierTel2(""); setCarrierTel3("");
            setCarrierFax1(""); setCarrierFax2(""); setCarrierFax3("");
            setCarrierEmail(""); 
            setMainTableData([]);
        }
    }

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            if(menuPatternData['menu_master_flag_10'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});

    const saveData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        if(SysCarrierId>0) {
            data['sys_carrier_id'] = SysCarrierId;
        }

        data['carrier_code'] = CarrierCode;
        data['carrier_a_code'] = CarrierACode;
        data['carrier_name'] = CarrierName;
        data['carrier_a_name'] = CarrierAName;
        data['carrier_name_kana'] = CarrierNameKana;
        data['carrier_zip'] = CarrierZip1 + "-" + CarrierZip2;  //郵便番号
        data['carrier_address_1'] = CarrierAddress1;
        data['carrier_address_2'] = CarrierAddress2;
        data['carrier_address_3'] = CarrierAddress3;
        data['carrier_town'] = CarrierTown;
        data['carrier_tel'] = CarrierTel1 + "-" + CarrierTel2 + "-" + CarrierTel3;  //電話番号
        data['carrier_fax'] = CarrierFax1 + "-" + CarrierFax2 + "-" + CarrierTel3;  //Fax番号
        data['carrier_email'] = CarrierEmail;

        let driverData = [];
        for(var i = 0; i<mainTableData.length; i++){
            let row = {};
            if(mainTableData[i]['id'] != 0) {
                row['id'] = mainTableData[i]['id'];
            }

            row['transport'] = mainTableData[i]['transport'];
            row['driver_name'] = mainTableData[i]['driver_name'];
            row['phone_number'] = mainTableData[i]['phone_number'];
            row['car_number'] = mainTableData[i]['car_number'];
            row['car_standard'] = mainTableData[i]['car_standard'];

            driverData[i] = row;

        }

        data['item'] = driverData;

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.setMstCarrierEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            if(response.status == 200) {
                useJwt.setToken(response.data.token);
                if(response.data.data) {
                    let temp = response.data.data;

                    getDriverData(temp['sys_carrier_id']);
                    toast.success("登録致しました。", {autoClose:3000});
                }else if(response.data.data == 2){
                    toast.error("すでに登録済みです。", {autoClose:3000});
                }else{
                    toast.error("登録に失敗しました。", {autoClose:3000});
                }
                    
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
            if(error.response && error.response.status && error.response.status === 401) {
                useJwt.logout();
                navigate("/login");
            }else{
                toast.error("登録に失敗しました。", {autoClose:3000});
            }
        });
        setOpen(false);
    }
    const loadModalDelData = (event) => {
        event.preventDefault();
        if(SysCarrierId>0) {
            setShowModalDel(true);
        }
    }
    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        let delIds = [];
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(checkboxes[i].checked){
                let id = document.getElementById('id_'+i).value;
                if(id>0){
                    delIds.push(id);
                }
            }else{
                remainIndexs.push(i);
            }
        }
        
        if(delIds.length>0) {
            let data = {};
            data['userID'] = userData['id'];
            data['delIds'] = delIds;
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.delDriverEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if(response.status == 200) {
                    getDriverData(SysCarrierId);
                }
            })
            .catch((error) => {
            });
        }else{
            let remainData = [];
            for(var i=0; i<mainTableData.length; i++) {
                for(var j=0; j<remainIndexs.length; j++){
                    if(i==remainIndexs[j]){
                        let row = {};
                        row = mainTableData[i];
                        remainData.push(row);
                    }
                }
            }
            gridMainTable(remainData);
            $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', false);
        }
    }
    
    const getDriverData = (result) => {
        let data = {};
        data['userID'] = userData['id'];
        data['transport'] = result;

        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getDriverEndpoint;
        csloading(true);
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        }).then((response) => {
            csloading(false);

            if(response.status == 200) {
                if(response.data.data.length>0) {
                    let result = response.data.data;
                    gridMainTable(result);
                    var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
                    for (let i = 0; i < checkboxes.length; i++) {
                        checkboxes[i].checked = false;
                    }
                }else{
                    gridMainTable([]);
                }
            }
        })
        .catch((error) => {
            csloading(false);
        });
    }

    // ========================== starts code for search ===============================
    const [SearchValue, setSearchValue] = useState("");
    const [SearchValue2, setSearchValue2] = useState("");
    const [tableData, setTableData] = useState([]);
    
    const loadModalData = (event) => {
        event.preventDefault();
        setSearchValue(CarrierCode);
        setSearchValue2(CarrierACode);

        if(CarrierCode != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['carrier_code'] = CarrierCode;
            if(CarrierACode != "") {
                data['carrier_a_code'] = CarrierACode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstCarrierEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setTableData(response.data.data);
                setShowModal(true);
            })
            .catch((error) => {
            });
        }else{
            setTableData([]);
            setShowModal(true);
        }
    }
    const [mainTableData, setMainTableData] = useState([]);
    const gridMainTable = (result) => {
        setMainTableData([]);
        setMainTableData(result);
    }

    const newRecord = (event) => {

        if(CarrierCode == ""){
            toast.error("運送会社コードを入力してください。", {autoClose:3000}); 
            return;
        }
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            data[i] = row;
        }
        let row = {};
        row['id'] = 0;
        row['transport'] = SysCarrierId;
        row['driver_name'] = "";
        row['phone_number'] = "";
        row['car_number'] = "";
        row['car_standard'] = "1";
        row['car_standard_flag'] = false;

        data[i] = row; 
        gridMainTable(data);
        
        setTimeout(()=>{
            let endRow = mainTableData.length;
            document.getElementById('driver_name'+endRow).focus();
        },10)
          }
    
    const allCheckData = (event) => {
        $( '#tbodyWrap input[type="checkbox"]' ).prop('checked', event.target.checked);
    }

    const handleChangeChk = (event) => {
        let all_check_flg = true;
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if(!checkboxes[i].checked) {
                all_check_flg = false;
                break;
            }
        }
        document.getElementById('allCheckBtn').checked = all_check_flg;
    };

    const handleChangeDriverName = (event, index) => {
        let value = event.target.value;
  
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row['driver_name'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
  
    }

    const handleChangePhoneNumber = (event, index) => {
        let value = event.target.value;
  
        let data = [];
        for(var i=0; i<mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if(i == index){
                row['phone_number'] = value;
            }
            data[i] = row;
        }
        gridMainTable(data);
  
    }
  
    const handleChangeCarNumber = (event, index) => {
      let value = event.target.value;
  
      let data = [];
      for(var i=0; i<mainTableData.length; i++) {
          let row = {};
          row = mainTableData[i];
          if(i == index){
              row['car_number'] = value;
          }
          data[i] = row;
      }
      gridMainTable(data);
    }
  
    const handleChangeCarStandard = (event, index, type) => {
      let value = event;
  
      let data = [];
      for(var i=0; i<mainTableData.length; i++) {
          let row = {};
          row = mainTableData[i];
          if(i == index){
            if(type == 1)   row['car_standard'] = value;
            else if(type == 2)  row['car_standard_flag'] = true;
            else if(type == 3)  row['car_standard_flag'] = false;
          }
          data[i] = row;
      }
      gridMainTable(data);
    }
  
    
  const onDataKeyDown = (e, param) => {
    if(e.keyCode == 13){
        switch(param){
            case 1 :
                document.getElementById('input1').focus();
                break;
            case 2 :
                document.getElementById('input2').focus();
                break;
            case 3 :
                document.getElementById('input3').focus();
                break;
            case 4 :
                document.getElementById('input4').focus();
                break;
            case 5 :
                document.getElementById('input5').focus();
                break;
            case 6 :
                document.getElementById('input6').focus();
                break;
            case 7 :
                document.getElementById('input7').focus();
                break;
            case 8 :
                document.getElementById('input8').focus();
                break;
            case 9 :
                document.getElementById('input9').focus();
                break;
            case 10 :
                document.getElementById('input10').focus();
                break;
            case 101 :
                document.getElementById('input101').focus();
                break;
            case 11 :
                document.getElementById('input11').focus();
                break;
            case 12 :
                document.getElementById('input12').focus();
                break;
            case 13 :
                document.getElementById('input13').focus();
                break;
            case 14 :
                document.getElementById('input14').focus();
                break;
            case 15 :
                document.getElementById('input15').focus();
                break;
            case 16 :
                document.getElementById('input16').focus();
                break;
            case 17 :
                document.getElementById('input17').focus();
                break;
            case 18 :
                document.getElementById('input18').focus();
                break;
            case 19 :
                document.getElementById('CategoryCode1').focus();
                break;
            case 20 :
            document.getElementById('CategoryCode2').focus();
            break;
            case 21 :
            document.getElementById('CategoryCode3').focus();
            break;
            case 22 :
            document.getElementById('type2').focus();
            break;
                default :
        }
    }
}

const tableKeyDown = ( e, id ) =>{
    if(e.keyCode === 13){
        e.preventDefault();
        document.getElementById(id).focus();
    }
}

const tableEndKeyDown = ( e, id, index ) =>{
    let dataLength = mainTableData.length;

    if(dataLength <= index + 1)  return;
    
    if(e.keyCode === 13){
        e.preventDefault();
        document.getElementById(id).focus();
    }
}

    // ========================== ends code for search ==================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_input_220px = [gstyle.cs_input, gstyle.w220px];
    const style_input_80 = [gstyle.cs_input, gstyle.w80];
    const style_input_80px = [gstyle.cs_input, gstyle.w80px];
    const style_split_span = [style.split_span];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_th_col_3 = [style.cs_th, style.wp_25, style.td_item_align];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p, style.td_item_align];
    const style_td_col_3 = [style.cs_td, style.wp_25, style.td_item_align];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p, style.td_item_align];
    const style_select_40 = [gstyle.cs_select, gstyle.w140];
    const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <CarrierModal show={showModal} onClose={()=>setShowModal(false)} width={'70%'}
                SearchValue={SearchValue} onChange={setSearchValue} 
                SearchValue2={SearchValue2} onChange2={setSearchValue2}
                tableData={tableData} setTableData={setTableData}
                target={setCarrierData} />
            <Modal2 title="運送業者削除" show={showModalDel} onClose={()=>setShowModalDel(false)} width={'40%'} 
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <div className={gstyle.border}>
                <p className={gstyle.text}>運送業者マスタ</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>コード</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownCarrierCode(e,1)}
                                        value={CarrierCode} onChange={handleChangeCarrierCode} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownCarrierCode(e,2)} id='input2'
                                        value={CarrierACode} onChange={handleChangeCarrierACode} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadModalData(e)}>
                                        <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                    </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>名称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}  
                                        id='input3' onKeyDown={((e)=>onDataKeyDown(e, 4))}
                                        value={CarrierName} onChange={handleChangeCarrierName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>フリガナ</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input4' onKeyDown={((e)=>onDataKeyDown(e, 5))}
                                        value={CarrierNameKana} onChange={handleChangeCarrierNameKana}  />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>略称</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input5' onKeyDown={((e)=>onDataKeyDown(e, 6))}
                                        value={CarrierAName} onChange={handleChangeCarrierAName} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>郵便番号</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input6' onKeyDown={((e)=>onDataKeyDown(e, 7))}
                                        value={CarrierZip1} onChange={handleChangeCarrierZip1} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input7' onKeyDown={((e)=>onDataKeyDown(e, 8))}
                                        value={CarrierZip2} onChange={handleChangeCarrierZip2} />
                                        <div className={style.postcode_btn_wrap}>
                                            <button className={style.postcode_btn} onClick={getAddress} >郵便検索</button>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>住所（都道府県）</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input8' onKeyDown={((e)=>onDataKeyDown(e, 9))}
                                        value={CarrierAddress1} onChange={handleChangeCarrierAddress1} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>市区町村</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input9' onKeyDown={((e)=>onDataKeyDown(e, 101))}
                                        value={CarrierAddress2} onChange={handleChangeCarrierAddress2} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>町名</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input101' onKeyDown={((e)=>onDataKeyDown(e, 11))}
                                        value={CarrierTown} onChange={handleChangeCarrierTown} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>丁目・番地</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_220px.join(" ")}
                                        id='input10' onKeyDown={((e)=>onDataKeyDown(e, 11))}
                                        value={CarrierAddress3} onChange={handleChangeCarrierAddress3} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>電話番号</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input11' onKeyDown={((e)=>onDataKeyDown(e, 12))}
                                        value={CarrierTel1} onChange={handleChangeCarrierTel1} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input12' onKeyDown={((e)=>onDataKeyDown(e, 13))}
                                        value={CarrierTel2} onChange={handleChangeCarrierTel2} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input13' onKeyDown={((e)=>onDataKeyDown(e, 14))}
                                        value={CarrierTel3} onChange={handleChangeCarrierTel3} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>FAX番号</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input14' onKeyDown={((e)=>onDataKeyDown(e, 15))}
                                        value={CarrierFax1} onChange={handleChangeCarrierFax1} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input15' onKeyDown={((e)=>onDataKeyDown(e, 16))}
                                        value={CarrierFax2} onChange={handleChangeCarrierFax2} />
                                        <span className={style_split_span.join(" ")}>-</span>
                                        <input type={'number'} className={style_input_80px.join(" ")}
                                        id='input16' onKeyDown={((e)=>onDataKeyDown(e, 17))}
                                        value={CarrierFax3} onChange={handleChangeCarrierFax3} />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>メールアドレス</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'text'} className={style_input_80.join(" ")}
                                        id='input17' onKeyDown={((e)=>onDataKeyDown(e, 18))}
                                        value={CarrierEmail} onChange={handleChangeCarrierEmail} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_4.join(" ")}>
                            <div className={style_btn_new_wrap.join(" ")}>
                                <button className={gstyle.btn_new} id='input18' onClick={newRecord}>
                                追加
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={gstyle.hyfiled} style={{marginTop:30, justifyContent:'center'}}>
                        <div style={{minWidth:750}}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn"
                                            onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_col_3.join(" ")}>ドライバー名</div>
                                    <div className={style_th_col_3.join(" ")}>携帯</div>
                                    <div className={style_th_col_3.join(" ")}>車番</div>
                                    <div className={style_th_col_3.join(" ")}>車種</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {  mainTableData.map((data,index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                            id={'chk_'+index} onChange={handleChangeChk} />
                                            <input type="hidden" id={'id_'+index} value={data['id']} />
                                        </div>
                                        <div className={style_td_col_3.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['driver_name']} 
                                                id={'driver_name'+index} onKeyDown={(e)=>tableKeyDown(e, "phone_number" + index)}
                                                onChange={(e) => handleChangeDriverName(e, index)} />
                                        </div>
                                        <div className={style_td_col_3.join(" ")}>
                                            <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['phone_number']} 
                                                id={'phone_number'+index} onKeyDown={(e)=>tableKeyDown(e, "car_number" + index)}
                                                onChange={(e) => handleChangePhoneNumber(e, index)} />
                                        </div>
                                        <div className={style_td_col_3.join(" ")}>
                                          <input type={'text'} className={style.cs_tbl_input} 
                                                value={data['car_number']} 
                                                id={'car_number'+index} onKeyDown={(e)=>tableKeyDown(e, "car_standard" + index)}
                                                onChange={(e) => handleChangeCarNumber(e, index)} />
                                        </div>
                                        <div className={style_td_col_3.join(" ")}>
                                            <Select
                                                showSearch
                                                open={data['car_standard_flag']}
                                                value={data['car_standard']}
                                                optionFilterProp="children"
                                                onChange={(e)=>handleChangeCarStandard(e, index, 1)}
                                                onKeyDown={(e)=>tableEndKeyDown(e, 'driver_name' + (index + 1), index)}
                                                onFocus={(e) => handleChangeCarStandard(e, index, 2)}
                                                onBlur={(e) => handleChangeCarStandard(e, index, 3)}
                                                filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                style={{ width:'100%'}}
                                                id={"car_standard" + index}
                                                options={[
                                                    {value:'1', label:'4t'},
                                                    {value:'2', label:'10t'},
                                                    {value:'3', label:'トレーラー'},
                                                ]}
                                            />
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={(e)=>handleOpen(e)} >登録する</button>
                        <button className={gstyle.btn_delete} onClick={loadModalDelData} disabled={SysCarrierId>0?false:true}>削除</button>
                    </div>
					<Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{fontSize:"1.1rem"}}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Carrier;