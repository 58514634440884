/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import axios from "axios";
import style from './Modal.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import Modal from './Modal';
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { toast } from 'react-toastify';
import { Button } from 'antd';

const ShipperDriverModal = props => {

    const userData = JSON.parse(useJwt.getUserData());
    const [loaded, setLoaded] = useState(false);

    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_50 = [gstyle.cs_input, gstyle.w50];

    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_4 = [style.cs_th, gstyle.cs_col_4];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_4 = [style.cs_td, gstyle.cs_col_4];

    const handleChangeSearchValue = (event) => {
        props.onChange(event.target.value);
    }

    const [driverData, setDriverData] = useState(props.driverTableData)

    const searchData = (event) => {
        event.preventDefault();
        let data = {};
        data['userID'] = userData['id'];
        data['transport'] = props.SearchValue;
        if (props.SearchValue2) data['car_number'] = props.SearchValue2;

        if (document.getElementById("RotFilterByLike").checked) {
            data['filterByLike'] = 1;
        } else {
            data['filterByLike'] = 0;
        }
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getShipperCarrierDriverEndpoint;
        setLoaded(true);
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setLoaded(false);
                if (response.status == 200) {
                    console.log(response.data.data)
                    if (response.data.data.length > 0) {
                        setDriverData(response.data.data)
                    }
                    if (response.data.data.length == 0) toast.error("検索結果はありません。", { autoClose: 3000 });
                }
            })
            .catch((error) => {
                setLoaded(false);
                toast.error("検索結果はありません。", { autoClose: 3000 });
            });
    }
    const handleChangeData = (event, data) => {
        event.preventDefault();

        props.target(data, props.targetParam != null ? props.targetParam : '');
        props.onClose(true);
    }

    return (
        <Modal title="ドライバー検索" show={props.show} onClose={props.onClose} width={props.width}>
            <div className={gstyle.hyfiled}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className={style_label_wrap.join(" ")}>
                        <div className={gstyle.cs_flex}>
                            <span style={{ width: 90, marginLeft: 20 }}>車番号</span>
                            <input type={'text'} className={style_input_50.join(" ")}
                                value={props.SearchValue2} onChange={handleChangeSearchValue} />
                        </div>
                    </label>
                    <Button onClick={searchData} loading={loaded} type={'primary'} style={{ marginLeft: 30, width: 120, height: 35, borderRadius: 'unset' }}>
                        {loaded ? '検索中' : '検索'}
                    </Button>
                    <label className={gstyle.cs_label}>
                        <div className={gstyle.cs_flex}>
                            <input type={"checkbox"} className={style.cs_input_check} id="RotFilterByLike" />
                            <span>部分検索</span>
                        </div>
                    </label>
                </div>
            </div>
            <div className={gstyle.hyfiled} style={{ justifyContent: 'center' }}>
                <div className={style.cs_tbl}>
                    <div className={style.cs_thead} >
                        <div className={style.cs_tr} style={{ justifyContent: 'center' }}>
                            <div className={style_th_col_2.join(" ")}>ドライバー名</div>
                            <div className={style_th_col_2.join(" ")}>車番号</div>
                            <div className={style_th_col_4.join(" ")}>携帯電話</div>
                            <div className={style_th_col_2.join(" ")}>車種</div>
                        </div>
                    </div>
                    <div className={style.cs_tbody}>
                        {driverData.map((data) => (
                            <div className={style.cs_tr} key={data['id']} style={{ justifyContent: 'center' }}
                                onClick={(e) => handleChangeData(e, data)}>
                                <div className={style_td_col_2.join(" ")}>{data['driver_name']}</div>
                                <div className={style_td_col_2.join(" ")}>{data['car_number']}</div>
                                <div className={style_td_col_4.join(" ")}>{data['phone_number']}</div>
                                <div className={style_td_col_2.join(" ")}>{data['car_standard'] == 1 ? '4t' : (data['car_standard'] == 2 ? '10t' : 'トレーラー')}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default ShipperDriverModal;
