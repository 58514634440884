/* eslint-disable eqeqeq */
import style from './StockMoveSlipList.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, {useState, useEffect} from 'react';
import axios from "axios";
import * as useJwt  from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import ShipperModal from '../../../components/common/modal/ShipperByWarehouseModal';
import ItemModal from '../../../components/common/modal/ItemModal';
import WarehouseModal from '../../../components/common/modal/WarehouseModal';
import IconSearch from '../../../components/common/icons/icon_search.png';
import InputDatepicker from '../../../components/common/input/InputDatepicker';
import NewWindow from 'react-new-window';
import ReactPaginate from 'react-paginate';
import "./pagenation.css";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { Select } from 'antd';


const StockMoveSlipList = () => {

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [opened, setOpened] = useState(false);

    const csloading = (flag) => {
        if(flag) {
            document.getElementById('csloader').style.display="flex";
        }else{
            document.getElementById('csloader').style.display="none";
        }
    } 

    // publish date
    const [PublishDate, setPublishDate] = useState(useJwt.formatDate(new Date()));
    const handleChangePublishDate = (date) => {
        setPublishDate(useJwt.formatDate(date));
    };
    // date type -----------------------------------------------
    const [DateType, setDateType] = useState(0);
    const handleChangeDateType = (event) => {
        setDateType(event);
    };
    const DateTypeData = [ { value:'0', label:'伝票日付' }, { value:'1', label:'入力日付' }, ];
    // start and end date ---------------------------------------
    const [StartDate, setStartDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeStartDate = (date) => {
        setStartDate(useJwt.formatDate(date));
    };
    const [EndDate, setEndDate] = useState(useJwt.formatDate(new Date()));
    const handleChangeEndDate = (date) => {
        setEndDate(useJwt.formatDate(date));
    };
    // output order type -----------------------------------------------
    const [OrderType, setOrderType] = useState(0);
    const handleChangeOrderType = (event) => {
        setOrderType(event);
    };
    const OrderTypeData = [ { value:'0', label:'日付順' }, { value:'1', label:'荷主別' }];
    // warehouse --------------------------------------------------
    const [WarehouseCode1, setWarehouseCode1] = useState("");
    const handleChangeWarehouseCode1 = (event) => {
        setWarehouseCode1(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName1, setWarehouseName1] = useState("");
    const [WarehouseCode2, setWarehouseCode2] = useState("");
    const handleChangeWarehouseCode2 = (event) => {
        setWarehouseCode2(useJwt.validateNum(event.target.value,2));
    };
    const [WarehouseName2, setWarehouseName2] = useState("");
    const onKeyDownWarehouseCode = (event,param) => {
        if(event.keyCode === 13) { // enter key
            getWarehouseData(event.target.value,param);
            event.preventDefault();

            if(param == 1){
                document.getElementById('warehouse2').focus();
            }else{
                document.getElementById('shipper_code1').focus();
            }
        }
    };
    const getWarehouseData = (code,param) => {
        if(code != "") {
            const data = {
                userID: userData['id'],
                warehouse_code: code
            };
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setWarehouseData(result,param);
                    }else{
                        setWarehouseData([],param);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setWarehouseData([],param);
        }
    }
    const setWarehouseData = (data,param) => {
        if(data['warehouse_code']) {
            if(param == 1) {
                setWarehouseCode1(data['warehouse_code']);
                setWarehouseName1(data['warehouse_name']);
            }else{
                setWarehouseCode2(data['warehouse_code']);
                setWarehouseName2(data['warehouse_name']);
            }
        }else{
            if(param == 1) {
                setWarehouseCode1("");
                setWarehouseName1("");
            }else{
                setWarehouseCode2("");
                setWarehouseName2("");
            }
        }
    }
    // shipper ---------------------------------------------------
    const [ShipperCode1, setShipperCode1] = useState("");
    const handleChangeShipperCode1 = (event) => {
        setShipperCode1(useJwt.validateNum(event.target.value,6));
        setItemData([],1);
        setItemData([],2);
    };
    const [ShipperACode1, setShipperACode1] = useState("");
    const handleChangeShipperACode1 = (event) => {
        setShipperACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName1, setShipperName1] = useState("");
    const [ShipperCode2, setShipperCode2] = useState("");
    const handleChangeShipperCode2 = (event) => {
        setShipperCode2(useJwt.validateNum(event.target.value,6));
        setItemData([],1);
        setItemData([],2);
    };
    const [ShipperACode2, setShipperACode2] = useState("");
    const handleChangeShipperACode2 = (event) => {
        setShipperACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ShipperName2, setShipperName2] = useState("");
    const onKeyDownShipperCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getShipperData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('shipper_a_code1').focus();
                }else{
                    document.getElementById('shipper_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('shipper_a_code2').focus();
                }else{
                    if(ShipperCode1 != "" && ShipperCode1 == ShipperCode2){
                        document.getElementById('item_code1').focus();
                    }else{
                        document.getElementById('type3').focus();
                    }
                }
            }
        }
    };
    const getShipperData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                if(ShipperACode1 != ""){
                    acode = ShipperACode1;
                }else{
                    acode = "000";
                }
            }else{
                code = ShipperCode1;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }else{
            if(param2==1){
                code = value;
                if(ShipperACode2 != ""){
                    acode = ShipperACode2;
                }else{
                    acode = "000";
                }
            }else{
                code = ShipperCode2;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }
        

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            if(acode != ""){
                data['shipper_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            }).then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setShipperData(result,param1);
                    }else{
                        setShipperData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setShipperData([],param1);
        }
    }
    const setShipperData = (data,param) => {
        if(data['shipper_code'] != null) {
            if(param == 1) {
                setShipperCode1(data['shipper_code']);
                setShipperACode1(data['shipper_a_code']);
                setShipperName1(data['shipper_name']);
            }else{
                setShipperCode2(data['shipper_code']);
                setShipperACode2(data['shipper_a_code']);
                setShipperName2(data['shipper_name']);
            }
        }else{
            if(param == 1) {
                setShipperCode1("");
                setShipperACode1("");
                setShipperName1("");
            }else{
                setShipperCode2("");
                setShipperACode2("");
                setShipperName2("");
            }
        }
    }
    // item ----------------------------------------------------
    const [ItemCode1, setItemCode1] = useState("");
    const handleChangeItemCode1 = (event) => {
        setItemCode1(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode1, setItemACode1] = useState("");
    const handleChangeItemACode1 = (event) => {
        setItemACode1(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName1, setItemName1] = useState("");
    const [ItemCode2, setItemCode2] = useState("");
    const handleChangeItemCode2 = (event) => {
        setItemCode2(useJwt.validateString(event.target.value,15));
    };
    const [ItemACode2, setItemACode2] = useState("");
    const handleChangeItemACode2 = (event) => {
        setItemACode2(useJwt.validateNum(event.target.value,3));
    };
    const [ItemName2, setItemName2] = useState("");
    const onKeyDownItemCode = (event,param1, param2) => {
        if(event.keyCode === 13) { // enter key
            getItemData(event.target.value,param1,param2);
            event.preventDefault();

            if(param1 == 1){
                if(param2 == 1){
                    document.getElementById('item_a_code1').focus();
                }else{
                    document.getElementById('item_code2').focus();
                }
            }else{
                if(param2 == 1){
                    document.getElementById('item_a_code2').focus();
                }else{
                    document.getElementById('type3').focus();
                }
            }
        }
    };
    const getItemData = (value, param1, param2) => {
        let code;
        let acode;
        if(param1 == 1){
            if(param2==1){
                code = value;
                if(ItemACode1 != ""){
                    acode = ItemACode1;
                }else{
                    acode = "000";
                }
            }else{
                code = ItemCode1;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }else{
            if(param2==1){
                code = value;
                if(ItemACode2 != ""){
                    acode = ItemACode2;
                }else{
                    acode = "000";
                }
            }else{
                code = ItemCode2;
                if(value != ""){
                    acode = value;
                }else{
                    acode = "000";
                }
            }
        }

        if(code != "") {
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode1;
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, { headers: { Authorization: "Bearer " + useJwt.getToken(), }, })
            .then((response) => {
                if(response.status == 200) {
                    useJwt.setToken(response.data.token);
                    if(response.data.data.length>0) {
                        let result = response.data.data[0];
                        setItemData(result,param1);
                    }else{
                        setItemData([],param1);
                    }
                }
            })
            .catch((error) => {
            });
        }else{
            setItemData([],param1);
        }
    }
    const setItemData = (data,param) => {
        if(data['item_code']) {
            if(param == 1) {
                setItemCode1(data['item_code']);
                setItemACode1(data['item_a_code']);
                setItemName1(data['item_name']);
            }else{
                setItemCode2(data['item_code']);
                setItemACode2(data['item_a_code']);
                setItemName2(data['item_name']);
            }
        }else{
            if(param == 1) {
                setItemCode1("");
                setItemACode1("");
                setItemName1("");
            }else{
                setItemCode2("");
                setItemACode2("");
                setItemName2("");
            }
        }
    }
    // move type --------------------------------------------------------------
    const MoveTypeData = [
        {value:'0', label:'全部'}, {value:'1', label:'倉替'},{value:'2', label:'滅失'},{value:'3', label:'調整'},{value:'4', label:'荷主間移動'},
    ];
    const [MoveType, setMoveType] = useState(0);
    const handleChangeMoveType = (event) => {
        setMoveType(event);
    };
    // =================================================================================

    useEffect(() => {
        if(authentication !== true)
			navigate("/login");
        if(!loaded) {
            setLoaded(true);
            if(menuPatternData['menu_daily_flag_9'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
        }
	});
    // ================================= New Window ====================================
    const OpenSearchWindow = (event) => {
        event.preventDefault();
            
        let data = {};
        data['userID'] = userData['id'];
        if(DateType == 0) {
            data['start_slip_date'] = StartDate;
            data['end_slip_date'] = EndDate;
        }else{
            data['start_updated_slip_date'] = StartDate;
            data['end_updated_slip_date'] = EndDate;
        }

        data['output_order'] = OrderType;

        if(WarehouseCode1 != "") {
            data['start_warehouse_code'] = WarehouseCode1;
        }
        if(WarehouseCode2 != "") {
            data['end_warehouse_code'] = WarehouseCode2;
        }

        if(ShipperCode1 != "") {
            data['start_shipper_code'] = ShipperCode1;
        }
        if(ShipperCode2 != "") {
            data['end_shipper_code'] = ShipperCode2;
        }
        if(ShipperACode1 != "") {
            data['start_shipper_a_code'] = ShipperACode1;
        }
        if(ShipperACode2 != "") {
            data['end_shipper_a_code'] = ShipperACode2;
        }
        
        if(ShipperCode1!="" && ShipperCode1 == ShipperCode2) {
            data['start_item_code'] = ItemCode1;
            data['end_item_code'] = ItemCode2;
            data['start_item_a_code'] = ItemACode1;
            data['end_item_a_code'] = ItemACode2;
        }

        if(MoveType>0) {
            data['move_type'] = MoveType*1-1;
        }
        csloading(true);
        let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getStockMoveSlipItemsEndpoint;
        axios
        .post(url, data, {
            headers: {
            Authorization: "Bearer " + useJwt.getToken(),
            },
        })
        .then((response) => {
            let result = response.data.data;
            if(result.length>0){
                let data = [];
                let itemCodes = [];
                for(let i=0; i<result.length; i++) {
                    let filter_code = '';
                    if(OrderType == 0) {
                        filter_code = ''+useJwt.formatDate(new Date(result[i]['stock_move_date']));
                        filter_code += '-'+result[i]['slip_no'];
                    } else if(OrderType == 1) {
                        filter_code += ''+result[i]['shipper_code'];
                    }
                    if(!itemCodes.includes(filter_code)){
                        itemCodes.push(filter_code);
                    }
                }
                
                let total_num = 0;
                let sub_total_label = "";
                let total_label = "【総 合 計】";
                if(OrderType == 0) {
                    sub_total_label = "【伝 票 計】";
                } else if(OrderType == 1) {
                    sub_total_label = "【荷 主 計】";
                }
                for(let i=0; i<itemCodes.length; i++) {
                    let subtotal_num = 0;
                    for(let j=0;j<result.length;j++) {
                        let filter_code = '';
                        if(OrderType == 0) {
                            filter_code = ''+useJwt.formatDate(new Date(result[j]['stock_move_date']));
                            filter_code += '-'+result[j]['slip_no'];
                        } else if(OrderType == 1) {
                            filter_code += ''+result[j]['shipper_code'];
                        }
                        if(itemCodes[i] == filter_code) {
                            let row = result[j];
                            row['total_flg'] = 0;
                            row['total_label'] = "";
                            data.push(row);
                            subtotal_num += (result[j]['item_num']*1);
                        }
                    }
                    let sub_total_row = {};
                    sub_total_row['total_flg'] = 1;
                    sub_total_row['total_label'] = sub_total_label;
                    sub_total_row['item_num'] = subtotal_num.toFixed(3);
                    data.push(sub_total_row);

                    total_num += (subtotal_num*1);
                }

                let total_row = {};
                total_row['total_flg'] = 1;
                total_row['total_label'] = total_label;
                total_row['item_num'] = total_num.toFixed(3);
                data.push(total_row);

                gridMainTable(data);
                setOpened(true);
            }else{
                toast.error("検索結果はありません。", {autoClose:3000}); 
                gridMainTable([]);
            }
            csloading(false);
        })
        .catch((error) => {
            csloading(false);
        });
        
    }
    const CloseSearchWidow =()=> {
        setOpened(false);
    }
    const [mainTableData, setMainTableData] = useState([]);

    const gridMainTable = (result) => {
        let data = result;
        setMainTableData(data);
        // for pagenation
        setItemOffset(0);
        const endOffset = 0 + itemsPerPage;
        setCurrentItems(data.slice(0, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }
    // starts pagenation code -----------------------------------------
    const itemsPerPage = 50;
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % mainTableData.length;
        // console.log( `User requested page number ${event.selected}, which is offset ${newOffset}` );
        setItemOffset(newOffset);
        const endOffset = newOffset + itemsPerPage;
        setCurrentItems(mainTableData.slice(newOffset, endOffset));
    };
    // ends pagenation code ---------------------------------------------
    // ========================== starts code for search ===============================
    // ----------------------------- Warehouse modal -------------------------------------
    const [showModalWarehouse, setShowModalWarehouse] = useState(false);
    const [WarehouseSearchValue, setWarehouseSearchValue] = useState("");
    const [WarehouseTableData, setWarehouseTableData] = useState([]);
    const [TargetParam, setTargetParam] = useState(1);
    const loadWarehouseModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = WarehouseCode1;
        if(param==2){
            code = WarehouseCode2;
        }
        setWarehouseSearchValue(code);

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            if(code != "") {
                data['warehouse_code'] = code;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setWarehouseTableData(response.data.data);
                setShowModalWarehouse(true);
            })
            .catch((error) => {
            });
        }else{
            setWarehouseTableData([]);
            setShowModalWarehouse(true);
        }
    }
    // ----------------------------- shipper modal -------------------------------------
    const [showModalShipper, setShowModalShipper] = useState(false);
    const [ShipperSearchValue, setShipperSearchValue] = useState("");
    const [ShipperSearchValue2, setShipperSearchValue2] = useState("");
    const [ShipperTableData, setShipperTableData] = useState([]);
    const loadShipperModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ShipperCode1;
        if(param == 2) {
            code = ShipperCode2;
        }
        setShipperSearchValue(code);
        setShipperSearchValue2("");

        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = code;
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstShipperByWarehouseEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setShipperTableData(response.data.data);
                setShowModalShipper(true);
            })
            .catch((error) => {
            });
        }else{
            setShipperTableData([]);
            setShowModalShipper(true);
        }
    }
    // ------------------------------ item modal ---------------------------------------
    const [showModalItem, setShowModalItem] = useState(false);
    const [ItemSearchValue, setItemSearchValue] = useState("");
    const [ItemSearchValue2, setItemSearchValue2] = useState("");
    const [ItemSearchValue3, setItemSearchValue3] = useState("");
    const [ItemTableData, setItemTableData] = useState([]);
    const loadItemModalData = (event,param) => {
        event.preventDefault();
        setTargetParam(param);
        let code = ItemCode1;
        let acode = ItemACode1;
        if(param == 2) {
            code = ItemCode2;
            acode = ItemACode2;
        }
        setItemSearchValue(code);
        setItemSearchValue2(acode);
        setItemSearchValue3(ShipperCode1);
        if(code != ""){
            let data = {};
            data['userID'] = userData['id'];
            data['shipper_code'] = ShipperCode1;
            data['item_code'] = code;
            if(acode != "") {
                data['item_a_code'] = acode;
            }
            
            let url = jwtDefaultConfig.backendUrl+jwtDefaultConfig.getMstItemEndpoint;
            axios
            .post(url, data, {
                headers: {
                Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                setItemTableData(response.data.data);
                setShowModalItem(true);
            })
            .catch((error) => {
            });
        }else{
            setItemTableData([]);
            setShowModalItem(true);
        }
    }
    // ========================== print =================================================
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");

    const handleAfterPrint = React.useCallback(() => {
        // console.log("`onAfterPrint` called");
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        // console.log("`onBeforePrint` called");
        CloseSearchWidow();
    }, []);

    const handleOnBeforeGetContent = React.useCallback( () => {
        setCurrentItems(mainTableData);
        // console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setText("New, Updated Text!");
                resolve();
            }, 1000);
        });
    }, [setLoading, setText, setCurrentItems, mainTableData]);

    React.useEffect(() => {
        if ( text === "New, Updated Text!" &&  typeof onBeforeGetContentResolve.current === "function" ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        return <button className={style.btn_print}>ﾌﾟﾘﾝﾀ</button>;
    }, []);

    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);

    const onType1KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('date2').focus();
            event.preventDefault();
        }
    }
    const onFocusType1 = (e, type) => {
        if(type == 1){
            setType1Open(true);
        }else{
            setType1Open(false);
        }
    }

    const onType2KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('warehouse1').focus();
            event.preventDefault();
        }
    }
    const onFocusType2 = (e, type) => {
        if(type == 1){
            setType2Open(true);
        }else{
            setType2Open(false);
        }
    }

    const onType3KeyDown = (event) =>{
        if(event.keyCode == 13){
            document.getElementById('searchBut').focus();
            event.preventDefault();
        }
    }
    const onFocusType3 = (e, type) => {
        if(type == 1){
            setType3Open(true);
        }else{
            setType3Open(false);
        }
    }

    const onSelectData = (e, index)=>{
        document.getElementById('searchBut').focus();
        
        switch (index) {
            case 1:
                setType1Open(false);
                break;
            case 2:
                setType2Open(false);
                break;
            case 3:
                setType3Open(false);
                break;
            default:
                break;
        }
    }
    // ========================== style =================================================
    // style array
    const style_row = [gstyle.cs_row];
    const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
    const style_col_8 = [gstyle.cs_col, gstyle.cs_col_8];
    const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
    const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
    const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
    const style_col_2 = [gstyle.cs_col, gstyle.cs_col_2];
    const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
    const style_input_wrap = [gstyle.p0015];
    const style_input_wrap2 = [style.p2030];
    const style_input_wrap3 = [style.grp_print_wrap];
    const style_input_100px = [gstyle.cs_input, gstyle.w100px];
    const style_date_wrap = [gstyle.cs_flex, gstyle.cs_date_wrap];

    const style_input_100px_bl_n = [gstyle.cs_input, gstyle.w100px, gstyle.cs_bl_n];
    const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <input type={'hidden'} id="selectedId" />
            <WarehouseModal show={showModalWarehouse} onClose={()=>setShowModalWarehouse(false)} width={'70%'}
                SearchValue={WarehouseSearchValue} onChange={setWarehouseSearchValue} 
                tableData={WarehouseTableData} setTableData={setWarehouseTableData}
                target={setWarehouseData} targetParam={TargetParam} />
            <ShipperModal show={showModalShipper} onClose={()=>setShowModalShipper(false)} width={'70%'}
                SearchValue={ShipperSearchValue} onChange={setShipperSearchValue} 
                SearchValue2={ShipperSearchValue2} onChange2={setShipperSearchValue2}
                tableData={ShipperTableData} setTableData={setShipperTableData}
                target={setShipperData} targetParam={TargetParam} />
            <ItemModal show={showModalItem} onClose={()=>setShowModalItem(false)} width={'70%'}
                SearchValue={ItemSearchValue} onChange={setItemSearchValue} 
                SearchValue2={ItemSearchValue2} onChange2={setItemSearchValue2}
                SearchValue3={ItemSearchValue3} 
                tableData={ItemTableData} setTableData={setItemTableData}
                target={setItemData} targetParam={TargetParam} />
            <div className={gstyle.border}>
                <p className={gstyle.text}>在庫移動リスト</p>
            </div>
            <div className={gstyle.filed}>
                <div className={gstyle.cs_card_1}>
                    {/* 発行年月日, 日付区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>発行年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={PublishDate} onChange={handleChangePublishDate} focusId="type1"/>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>日付区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type1Open}
                                            onSelect={(e)=>onSelectData(e, 1)}
                                            placeholder="日付区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeDateType}
                                            onKeyDown={onType1KeyDown}
                                            onFocus={(e) => onFocusType1(e, 1)}
                                            onBlur={(e) => onFocusType1(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type1"
                                            options={DateTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 年月日 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={StartDate} onChange={handleChangeStartDate} id="date2" focusId="date3" />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了年月日</span>
                                    <div className={style_date_wrap.join(" ")}>
                                        <InputDatepicker  value={EndDate} onChange={handleChangeEndDate} id="date3" focusId="type2" />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 出力順位 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>出力順位</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type2Open}
                                            onSelect={(e)=>onSelectData(e, 2)}
                                            placeholder="出力順位を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeOrderType}
                                            onKeyDown={onType2KeyDown}
                                            onFocus={(e) => onFocusType2(e, 1)}
                                            onBlur={(e) => onFocusType2(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type2"
                                            options={OrderTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* 倉庫 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,1)} id='warehouse1'
                                            value={WarehouseCode1} onChange={handleChangeWarehouseCode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={WarehouseName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了倉庫</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownWarehouseCode(e,2)} id='warehouse2'
                                            value={WarehouseCode2} onChange={handleChangeWarehouseCode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={WarehouseName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadWarehouseModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 荷主 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>開始荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,1)} id='shipper_code1'
                                        value={ShipperCode1} onChange={handleChangeShipperCode1} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,1,2)} id='shipper_a_code1'
                                        value={ShipperACode1} onChange={handleChangeShipperACode1} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className={style_col_6.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>終了荷主</span>
                                    <div className={gstyle.cs_flex}>
                                        <input type={'number'} className={style_input_100px.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,1)} id='shipper_code2'
                                        value={ShipperCode2} onChange={handleChangeShipperCode2} />
                                        <input type={'number'} className={style_input_100px_bl_n.join(" ")} 
                                        onKeyDown={(e) => onKeyDownShipperCode(e,2,2)} id='shipper_a_code2'
                                        value={ShipperACode2} onChange={handleChangeShipperACode2} />
                                        <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                        value={ShipperName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e)=>loadShipperModalData(e,2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    { /* 商品 */}
                    {ShipperCode1 != "" && ShipperCode1 == ShipperCode2 &&
                        <div className={style_row.join(" ")}>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>開始商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,1,1)} id='item_code1'
                                            value={ItemCode1} onChange={handleChangeItemCode1} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,1,2)} id='item_a_code1'
                                            value={ItemACode1} onChange={handleChangeItemACode1} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemName1} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,1)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className={style_col_6.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <span className={gstyle.cs_span}>終了商品</span>
                                        <div className={gstyle.cs_flex}>
                                            <input type={'text'} className={style_input_100px.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,2,1)} id='item_code2'
                                            value={ItemCode2} onChange={handleChangeItemCode2} />
                                            <input type={'text'} className={style_input_100px_bl_n.join(" ")} 
                                            onKeyDown={(e) => onKeyDownItemCode(e,2,2)} id='item_a_code2'
                                            value={ItemACode2} onChange={handleChangeItemACode2} />
                                            <input type={'text'} className={style_input_bl_n.join(" ")} disabled
                                            value={ItemName2} />
                                            <div className={gstyle.icon_search_wrap} onClick={(e)=>loadItemModalData(e,2)}>
                                                <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                    {/* 在庫移動区分 */}
                    <div className={style_row.join(" ")}>
                        <div className={style_col_3.join(" ")}>
                            <div className={style_input_wrap.join(" ")}>
                                <label className={style_label_wrap.join(" ")}>
                                    <span className={gstyle.cs_span}>在庫移動区分</span>
                                    <div className={gstyle.cs_flex}>
                                        <Select
                                            showSearch
                                            open={type3Open}
                                            onSelect={(e)=>onSelectData(e, 3)}
                                            placeholder="在庫移動区分を選択します。"
                                            optionFilterProp="children"
                                            onChange={handleChangeMoveType}
                                            onKeyDown={onType3KeyDown}
                                            onFocus={(e) => onFocusType3(e, 1)}
                                            onBlur={(e) => onFocusType3(e, 2)}
                                            filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            style={{ marginTop:14, width:'100%'}}
                                            id="type3"
                                            options={MoveTypeData}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* button 設定 */}
                    <div className={gstyle.btn_update_wrap}>
                        <button className={gstyle.btn_update} onClick={OpenSearchWindow} id='searchBut'>検索</button>
                    </div>
                </div>
            </div>
            {opened && (
            <NewWindow  onUnload={() => CloseSearchWidow()} 
                features={{ left: 30, top: 15, width: 1200, height: 700 }} >
                <div className={style.nw_container}>
                    <div ref={componentRef}>
                        {/* Title, 発行年月日 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_2.join(" ")}></div>
                            <div className={style_col_8.join(" ")}>
                                <div className={style.nw_title_wrap}>
                                    <h2>在庫移動リスト</h2>
                                </div>
                            </div>
                            <div className={style_col_2.join(" ")}>
                                <div className={style.publish_wrap}>
                                    <p>{PublishDate}</p>
                                </div>
                            </div>
                        </div>
                        {/* 日付区分 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_2}>[日付区分]:</span>
                                            <span className={style.cs_span_2}>{DateTypeData[DateType]['value']}</span>
                                            <span className={style.cs_span_2}>
                                                ({StartDate} ~ {EndDate})
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* 出力順位 */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap.join(" ")}>
                                    <label className={style_label_wrap.join(" ")}>
                                        <div className={gstyle.cs_flex}>
                                            <span className={style.cs_span_2}>[出力順位]:</span>
                                            <span className={style.cs_span_2}>{OrderTypeData[OrderType]['value']}</span>
                                            <span className={style.cs_span_2}>[在庫移動区分]:</span>
                                            <span className={style.cs_span_2}>{MoveTypeData[MoveType]['value']}</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        { /* Table */}
                        <div className={style_row.join(" ")}>
                            <div className={style_col_12.join(" ")}>
                                <div className={style_input_wrap3.join(" ")}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th rowSpan={2}>明細区分</th>
                                                <th >移動年月日</th>
                                                <th rowSpan={2}>請求区分</th>
                                                <th >荷主ｺｰﾄﾞ</th>
                                                <th >出庫倉庫</th>
                                                <th >出庫棚コード</th>
                                                <th >品番</th>
                                                <th >ﾛｯﾄNo</th>
                                                <th colSpan="2">相手先荷主名</th>
                                            </tr>
                                            <tr>
                                                <th >伝票No</th>
                                                <th >荷主名</th>
                                                <th >入庫倉庫</th>
                                                <th >入庫棚コード</th>
                                                <th >品名</th>
                                                <th >備考</th>
                                                <th >単位</th>
                                                <th >数量</th>
                                            </tr>
                                        </thead>
                                        <tbody id="tbody_wrap" className={loading?'tbl_hide':'tbl_show'}>
                                            {  currentItems.map((data,index) => (
                                                <React.Fragment key={index}>
                                                    {data['total_flg'] == 0 ?<>
                                                        <tr>
                                                            <td rowSpan={2}>
                                                                {MoveTypeData[data['move_type']*1+1]['value']}
                                                            </td>
                                                            <td>
                                                                {useJwt.formatDate(new Date(data['stock_move_date']))}
                                                            </td>
                                                            <td rowSpan={2}>{data['invoice_type']==0?'請求対象':'請求対象外'}</td>
                                                            <td>{data['leaving_shipper_code']+"-"+data['leaving_shipper_a_code']}</td>
                                                            <td>{data['leaving_warehouse_code']}</td>
                                                            <td>{data['leaving_table_code']}</td>
                                                            <td>{data['item_code']+"-"+data['item_a_code']}</td>
                                                            <td>{data['rot_code']}</td>
                                                            <td className={style.td_left} colSpan="2">{data['arrival_shipper_name']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{data['slip_no']}</td>
                                                            <td className={style.td_left}>{data['leaving_shipper_name']}</td>
                                                            <td>{data['arrival_warehouse_code']}</td>
                                                            <td>{data['arrival_table_code']}</td>
                                                            <td className={style.td_left}>{data['item_name']}</td>
                                                            <td>{data['remark']}</td>
                                                            <td>{data['item_unit']}</td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(data['item_num'])}</td>
                                                        </tr>
                                                    </>:<>
                                                        <tr className={style.bg_tbl_total}>
                                                            <td colSpan={9} height="52">{data['total_label']}</td>
                                                            <td className={style.td_right}>{useJwt.numberWithCommas(data['item_num'])}</td>
                                                        </tr>
                                                    </>}
                                                </React.Fragment>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style.nw_title_wrap}>
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style_row.join(" ")}>
                        <div className={style_col_12.join(" ")}>
                            <div className={style_input_wrap3.join(" ")}>
                                <ReactToPrint
                                    content={reactToPrintContent}
                                    documentTitle={'在庫移動リスト_'+useJwt.formatDateTime(new Date())}
                                    onAfterPrint={handleAfterPrint}
                                    onBeforeGetContent={handleOnBeforeGetContent}
                                    onBeforePrint={handleBeforePrint}
                                    removeAfterPrint
                                    trigger={reactToPrintTrigger}
                                />
                                <CSVLink data={mainTableData} filename={'在庫移動リスト_'+useJwt.formatDateTime(new Date())+'.csv'} 
                                className={style.btn_csv}>ﾌｧｲﾙ</CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
            )}
        </div>
    )
}

export default StockMoveSlipList;