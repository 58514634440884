/* eslint-disable eqeqeq */
import style from "./Shipper.module.css";
import gstyle from "../../../css/GeneralStyle.module.css";
import "./ShipperTbl.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as useJwt from "../../../auth/jwt/useJwt.js";
import jwtDefaultConfig from "../../../auth/jwt/jwtDefaultConfig.js";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import Modal2 from "../../../components/common/modal/Modal2.js";
import IconSearch from "../../../components/common/icons/icon_search.png";
import ShipperModal from "../../../components/common/modal/ShipperModal.js";
import CategoryModal from "../../../components/common/modal/CategoryModal.js";
import $ from "jquery";

import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Button, Select } from "antd";
import JwtService from "../../../auth/jwt/jwtService.js";

const Shipper = () => {
  const authentication = useJwt.isUserLoggedIn();
  const userData = JSON.parse(useJwt.getUserData());
  const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDel, setShowModalDel] = useState(false);
  const [showModalDel2, setShowModalDel2] = useState(false);
  let urlParams = useLocation();

  const csLoading = (flag) => {
    if (flag) {
      document.getElementById("csLoader").style.display = "flex";
    } else {
      document.getElementById("csLoader").style.display = "none";
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Shipper ID
  const [SysShipperId, setSysShipperId] = useState(0);
  const [ShipperCode, setShipperCode] = useState("");
  const handleChangeShipperCode = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 6) {
      str = str.substring(0, 6);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperCode(str);
  };
  const [ShipperACode, setShipperACode] = useState("");
  const handleChangeShipperACode = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 6) {
      str = str.substring(0, 6);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperACode(str);
  };
  const onKeyDownShipperCode = (event, param) => {
    if (event.keyCode === 13) {
      // enter key
      if (param == 1) {
        getShipperData(event.target.value, ShipperACode);
        document.getElementById('input2').focus();
      } else {
        getShipperData(ShipperCode, event.target.value);
        document.getElementById('input3').focus();
      }
      event.preventDefault();
    }
  };

  const getShipperData = (code, acode) => {
    if (code != "") {
      let data = {};
      data["userID"] = userData["id"];
      data["shipper_code"] = code;
      if (acode != "") {
        data["shipper_a_code"] = acode;
      }
      let url =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperEndpoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response1) => {
          let t_data = response1.data;
          if (t_data.length > 0) {
            setShipperData(t_data[0]);
          } else {
            setShipperData([]);
          }
        })
        .catch((error) => { });
    } else {
      setShipperData([]);
    }
  };

  const [ShipperName, setShipperName] = useState("");
  const handleChangeShipperName = (event) => {
    let str = event.target.value;
    if (str.length > 30) {
      str = str.substring(0, 30);
    }
    setShipperName(str);
  };
  const [ShipperNameKana, setShipperNameKana] = useState("");
  const handleChangeShipperNameKana = (event) => {
    let str = event.target.value;
    if (str.length > 30) {
      str = str.substring(0, 30);
    }
    setShipperNameKana(str);
  };
  const [ShipperAName, setShipperAName] = useState("");
  const handleChangeShipperAName = (event) => {
    let str = event.target.value;
    if (str.length > 30) {
      str = str.substring(0, 30);
    }
    setShipperAName(str);
  };

  const [ShipperHonor, setShipperHonor] = useState();
  const handleChangeShipperHonor = (event) => {
    setShipperHonor(event);
  };

  const [ShipperZip1, setShipperZip1] = useState("");
  const handleChangeShipperZip1 = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 3) {
      str = str.substring(0, 4);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperZip1(str);
  };
  const [ShipperZip2, setShipperZip2] = useState("");
  const handleChangeShipperZip2 = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 4) {
      str = str.substring(0, 4);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperZip2(str);
  };
  const getAddress = (event) => {
    if (ShipperZip1 != "" && ShipperZip2 != "") {
      let googleApiUrl = "https://maps.googleapis.com/maps/api/geocode/json";
      let postcodeParam =
        "?components=postal_code:" +
        ShipperZip1 +
        "-" +
        ShipperZip2 +
        "&language=ja";
      let apiKey = "&key=" + useJwt.getGoogleApiKey();
      let url = googleApiUrl + postcodeParam + apiKey;
      csLoading(true);
      axios
        .get(url, "")
        .then((response1) => {
          if (response1.data.status == "OK") {
            let address_components =
              response1.data.results[0]["address_components"];
            console.log(address_components);
            let code_len = address_components.length;
            let address1 = "";
            if (address_components[code_len - 2]["long_name"]) {
              address1 = address_components[code_len - 2]["long_name"];
            }
            let address2 = "";
            if (
              address_components[code_len - 3]["long_name"] &&
              address_components[code_len - 3]["types"][0] != "postal_code"
            ) {
              address2 = address_components[code_len - 3]["long_name"];
            }

            let town = "";
            if (code_len > 4) {
              town = address_components[code_len - 4]['long_name'];
            } else {
              town = "工業団地";
            }

            setShipperAddress1(address1);
            setShipperAddress2(address2);
            setShipperTown(town);
          } else {
            // ZERO_RESULTS
            setShipperAddress1("");
            setShipperAddress2("");
            setShipperTown("");
          }
          csLoading(false);
        })
        .catch((error) => {
          csLoading(false);
        });
      event.preventDefault();
    }
  };

  const [ShipperAddress1, setShipperAddress1] = useState("");
  const handleChangeShipperAddress1 = (event) => {
    let str = event.target.value;
    if (str.length > 30) {
      str = str.substring(0, 30);
    }
    setShipperAddress1(str);
  };
  const [ShipperAddress2, setShipperAddress2] = useState("");
  const handleChangeShipperAddress2 = (event) => {
    let str = event.target.value;
    if (str.length > 30) {
      str = str.substring(0, 30);
    }
    setShipperAddress2(str);
  };
  const [ShipperAddress3, setShipperAddress3] = useState("");
  const handleChangeShipperAddress3 = (event) => {
    let str = event.target.value;
    if (str.length > 30) {
      str = str.substring(0, 30);
    }
    setShipperAddress3(str);
  };

  const [ShipperTown, setShipperTown] = useState("");
  const handleChangeShipperTown = (event) => {
    let str = event.target.value;
    if (str.length > 30) {
      str = str.substring(0, 30);
    }
    setShipperTown(str);
  };

  const [ShipperTel1, setShipperTel1] = useState("");
  const handleChangeShipperTel1 = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 3) {
      str = str.substring(0, 4);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperTel1(str);
  };
  const [ShipperTel2, setShipperTel2] = useState("");
  const handleChangeShipperTel2 = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 4) {
      str = str.substring(0, 4);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperTel2(str);
  };
  const [ShipperTel3, setShipperTel3] = useState("");
  const handleChangeShipperTel3 = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 4) {
      str = str.substring(0, 4);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperTel3(str);
  };
  const [ShipperFax1, setShipperFax1] = useState("");
  const handleChangeShipperFax1 = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 3) {
      str = str.substring(0, 4);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperFax1(str);
  };
  const [ShipperFax2, setShipperFax2] = useState("");
  const handleChangeShipperFax2 = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 4) {
      str = str.substring(0, 4);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperFax2(str);
  };
  const [ShipperFax3, setShipperFax3] = useState("");
  const handleChangeShipperFax3 = (event) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 4) {
      str = str.substring(0, 4);
    }
    if (!z1.test(str)) {
      str = "";
    }
    setShipperFax3(str);
  };
  const [ShipperToken, setShipperToken] = useState("");
  const [ShipperEmail, setShipperEmail] = useState("");
  const handleChangeShipperEmail = (event) => {
    let str = event.target.value;
    setShipperEmail(str);
  };

  const [CategoryCode1, setCategoryCode1] = useState("");
  const [CategoryName1, setCategoryName1] = useState("");
  const [CategoryCode2, setCategoryCode2] = useState("");
  const [CategoryName2, setCategoryName2] = useState("");
  const [CategoryCode3, setCategoryCode3] = useState("");
  const [CategoryName3, setCategoryName3] = useState("");
  const handleChangeCategoryCode = (event, param) => {
    let z1 = /^[0-9]*$/;
    let str = event.target.value;
    if (str.length > 3) {
      str = str.substring(0, 3);
    }
    if (!z1.test(str)) {
      str = "";
    }
    if (param == 1) {
      setCategoryCode1(str);
    } else if (param == 2) {
      setCategoryCode2(str);
    } else {
      setCategoryCode3(str);
    }
  };

  const [MultiArrivalType, setMultiArrivalType] = useState();
  const handleChangeMultiArrivalType = (event) => {
    setMultiArrivalType(event);
  };
  const [StockEmptyType, setStockEmptyType] = useState();
  const handleChangeStockEmptyType = (event) => {
    setStockEmptyType(event);
  };
  const [ZeroContainItemFlag, setZeroContainItemFlag] = useState();
  const handleChangeZeroContainItemFlag = (event) => {
    setZeroContainItemFlag(event);
  };
  const [ZeroContainFlag, setZeroContainFlag] = useState();
  const handleChangeZeroContainFlag = (event) => {
    setZeroContainFlag(event);
  };
  const [ShipperSetType, setShipperSetType] = useState();
  const handleChangeShipperSetType = (event) => {
    setShipperSetType(event);
  };
  const [ShipperPrintFlag, setShipperPrintFlag] = useState();
  const handleChangeShipperPrintFlag = (event) => {
    setShipperPrintFlag(event);
  };

  const setShipperData = (result) => {
    if (result["id"]) {
      setSysShipperId(result["id"]);
      setShipperCode(result["shipper_code"]);
      setShipperACode(result["shipper_a_code"]);
      if (result["category_code_1"] != null) {
        setCategoryCode1(result["category_code_1"]);
        const data3 = {
          // userID: userData["id"],
          category_code: result["category_code_1"],
        };
        let url3 =
          jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
        axios
          .post(url3, data3, {
            headers: {
              Authorization: "Bearer " + useJwt.getToken(),
            },
          })
          .then((response) => {
            if (response.status == 200) {
              if (response.data.length > 0) {
                let result1 = response.data[0];
                setCategoryName1(result1["category_name"]);
              }
            }
          })
          .catch((error) => { });
      }
      if (result["category_code_2"] != null) {
        setCategoryCode2(result["category_code_2"]);
        const data3 = {
          // userID: userData["id"],
          category_code: result["category_code_2"],
        };
        let url3 =
          jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
        axios
          .post(url3, data3, {
            headers: {
              Authorization: "Bearer " + useJwt.getToken(),
            },
          })
          .then((response) => {
            if (response.status == 200) {
              if (response.data.length > 0) {
                let result1 = response.data[0];
                setCategoryName2(result1["category_name"]);
              }
            }
          })
          .catch((error) => { });
      }
      if (result["category_code_3"] != null) {
        setCategoryCode3(result["category_code_3"]);
        const data3 = {
          // userID: userData["id"],
          category_code: result["category_code_3"],
        };
        let url3 =
          jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
        axios
          .post(url3, data3, {
            headers: {
              Authorization: "Bearer " + useJwt.getToken(),
            },
          })
          .then((response) => {
            if (response.status == 200) {
              if (response.data.length > 0) {
                let result1 = response.data[0];
                setCategoryName3(result1["category_name"]);
              }
            }
          })
          .catch((error) => { });
      }

      getShipperItemData(result["id"]);
    } else {
      setSysShipperId(0);
    }
  };
  const timeTypeData = [
    { key: "0", value: "1" },
    { key: "1", value: "2" },
    { key: "2", value: "3" },
  ];
  // ====================================== table ====================================
  const [mainTableData, setMainTableData] = useState([]);
  const getShipperItemData = (shipper_id) => {
    let data = {};
    data["userID"] = userData["id"];
    data["shipper_id"] = shipper_id;
    let url =
      jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperItemsEndpoint;
    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
        },
      })
      .then((response1) => {
        let t_data = response1.data.data;
        if (t_data.length > 0) {
          gridMainTable(t_data);
        } else {
          gridMainTable([]);
        }
      })
      .catch((error) => { });
  };
  const gridMainTable = (result) => {
    setMainTableData(result);
  };
  const [TaxNoData, setTaxNoData] = useState([{ value: 0, label: "非課税" }]);
  const formatDate = (date) => {
    let year = date.getFullYear();
    let month = "" + (date.getMonth() * 1 + 1);
    let day = "" + date.getDate();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return year + "-" + month + "-" + day;
  };
  const onKeyDownItem = (event, control_type, col, index, focusId) => {
    if (event.keyCode === 13) {
      // enter key
      let value = event.target.value;
      if (
        col == "deadline_day_1" ||
        col == "deadline_day_2" ||
        col == "deadline_day_3"
      ) {
        if (value.length < 2) {
          value = "0" + value;
          let data = [];
          for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
              row[col] = value;
            }
            data[i] = row;
          }
          gridMainTable(data);
        }
      }
      if (focusId == "apply_date") {
        document.getElementById(focusId + (index + 1)).focus();
      } else {
        document.getElementById(focusId + index).focus();
      }
    }
  };

  const handlChangeItemData = (event, control_type, col, index) => {
    let value = "";
    if (control_type == 2) {
      value = formatDate(event);
    } else if (control_type == 5) {
      value = event;
    } else {
      value = event.target.value;
    }

    if (col == "apply_date") {
      if (value.length > 7) {
        value = value.substring(0, 7);
      }
      var regexp = /^(\d{1,4})[/](\d{1,2})?$/;
      if (value.length < 5) {
        regexp = /^(\d{1,4})?$/;
      }
      if (!regexp.test(value)) {
        value = value.substring(0, value.length - 1);
      }
    } else if (col == "deadline_times") {
      if (value.length > 1) {
        value = value.substring(0, 1);
      }
      if (value < 0 || value > 3) {
        value = 1;
      }
    } else if (
      col == "deadline_day_1" ||
      col == "deadline_day_2" ||
      col == "deadline_day_3"
    ) {
      if (value.length > 2) {
        value = value.substring(0, 2);
      }
      if (value > 28) {
        value = 31;
      }
    } else if (col == "receive_schedule_date") {
      if (value.length > 3) {
        value = value.substring(0, 3);
      }
    } else if (col == "deadline_date") {
      if (value.length > 7) {
        value = value.substring(0, 7);
      }
    }

    let data = [];
    for (var i = 0; i < mainTableData.length; i++) {
      let row = {};
      row = mainTableData[i];
      if (i == index) {
        if (control_type == 3) {
          row[col] = true;
        } else if (control_type == 4) {
          row[col] = false;
        } else {
          row[col] = value;
        }
      }
      data[i] = row;
    }
    gridMainTable(data);
  };

  const allCheckData = (event) => {
    $('#tbodyWrap input[type="checkbox"]').prop(
      "checked",
      event.target.checked
    );
  };
  const handleChangeChk = (event) => {
    let all_check_flg = true;
    var checkboxes = document.querySelectorAll(
      '#tbodyWrap input[type="checkbox"]'
    );
    for (let i = 0; i < checkboxes.length; i++) {
      if (!checkboxes[i].checked) {
        all_check_flg = false;
        break;
      }
    }
    document.getElementById("allCheckBtn").checked = all_check_flg;
  };
  const newRecord = (event) => {
    let data = [];
    for (var i = 0; i < mainTableData.length; i++) {
      let row = {};
      row = mainTableData[i];
      data[i] = row;
    }
    let row = {};
    row["sys_shipper_item_id"] = 0;
    row["shipper_id"] = SysShipperId;
    row["apply_date"] = "";
    row["deadline_times"] = 1;
    row["deadline_day_1"] = "";
    row["deadline_day_2"] = "";
    row["deadline_day_3"] = "";
    row["receive_schedule_date"] = "";
    row["storage_fee_type"] = "";
    row["cargo_handling_fee_type"] = "";
    row["sales_fraction_type"] = 0;
    row["invoice_cal_type"] = 0;
    row["invoice_cal_fraction_type"] = 0;
    row["invoice_print_type"] = 0;
    row["tax_type"] = 0;
    row["tax_no"] = 0;
    row["tax_fraction_type"] = 0;
    row["deadline_date"] = "";
    data[i] = row;
    gridMainTable(data);

    setTimeout(() => {
      let endRow = mainTableData.length;
      document.getElementById('apply_date' + endRow).focus();
    }, 10)
  };
  // =================================================================================

  const [shipperUserId, setShipperUserId] = useState("")
  useEffect(() => {
    if (authentication !== true) navigate("/login");
    if (!loaded) {
      if (menuPatternData["menu_master_flag_3"] == 0 && userData['sys_role_id'] != 10000) {
        navigate("/");
      }
      const data = {
        userID: userData["id"],
      };

      let url =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstTaxEndpoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          setLoaded(true);
          if (response.status == 200) {
            useJwt.setToken(response.data.token);
            if (response.data.data.length > 0) {
              let result = response.data.data[0];
              let TaxNoDataAry = [];
              TaxNoDataAry = [
                { value: 0, label: "非課税" },
                { value: 1, label: result["tax_1"] },
                { value: 2, label: result["tax_2"] },
                { value: 3, label: result["tax_3"] },
                { value: 4, label: result["tax_4"] },
                { value: 5, label: result["tax_5"] },
                { value: 6, label: result["tax_6"] },
                { value: 7, label: result["tax_7"] },
                { value: 8, label: result["tax_8"] },
                { value: 9, label: result["tax_9"] },
              ];
              setTaxNoData(TaxNoDataAry);
            }
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 401
          ) {
            useJwt.logout();
            navigate("/login");
          }
        });
    }
    console.log(urlParams.state)
    if(urlParams.state){
      let result = urlParams.state.data
      setShipperUserId(result["id"]);
      setShipperName(result["shipper_name"]);
      setShipperAName(result["shipper_a_name"]);
      setShipperNameKana(result["shipper_name_kana"]);
      setShipperHonor(result["shipper_honor"]);
      if (result["shipper_zip"] != null) {
        const postcode_ary = result["shipper_zip"].split("-");
        if (postcode_ary[0]) {
          setShipperZip1(postcode_ary[0]);
        }
        if (postcode_ary[1]) {
          setShipperZip2(postcode_ary[1]);
        }
      }
      if (result["shipper_address_1"] != null) {
        setShipperAddress1(result["shipper_address_1"]);
      }
      if (result["shipper_address_2"] != null) {
        setShipperAddress2(result["shipper_address_2"]);
      }
      if (result["shipper_address_3"] != null) {
        setShipperAddress3(result["shipper_address_3"]);
      }
      if (result["shipper_town"] != null) {
        setShipperTown(result["shipper_town"]);
      } else {
        setShipperTown("");
      }
      if (result["shipper_tel"] != null) {
        const phone_ary = result["shipper_tel"].split("-");
        if (phone_ary[0]) {
          setShipperTel1(phone_ary[0]);
        }
        if (phone_ary[1]) {
          setShipperTel2(phone_ary[1]);
        }
        if (phone_ary[2]) {
          setShipperTel3(phone_ary[2]);
        }
      }
      if (result["shipper_fax"] != null) {
        const fax_ary = result["shipper_fax"].split("-");
        if (fax_ary[0]) {
          setShipperFax1(fax_ary[0]);
        }
        if (fax_ary[1]) {
          setShipperFax2(fax_ary[1]);
        }
        if (fax_ary[2]) {
          setShipperFax3(fax_ary[2]);
        }
      }
      if (result["email"] != null) {
        setShipperEmail(result["email"]);
      }

      if (result["multi_arrival_type"] != null) {
        setMultiArrivalType(result["multi_arrival_type"]);
      }
      if (result["stock_empty_type"] != null) {
        setStockEmptyType(result["stock_empty_type"]);
      }
      if (result["zero_contain_item_flag"] != null) {
        setZeroContainItemFlag(result["zero_contain_item_flag"]);
      }
      if (result["zero_contain_flag"] != null) {
        setZeroContainFlag(result["zero_contain_flag"]);
      }
      if (result["shipper_set_type"] != null) {
        setShipperSetType(result["shipper_set_type"]);
      }
      if (result["shipper_print_flag"] != null) {
        setShipperPrintFlag(result["shipper_print_flag"]);
      }

    }
  }, []);

  const saveData = (event) => {
    event.preventDefault();
    let data = {};
    data["userID"] = userData["id"];
    if (SysShipperId > 0) {
      data["id"] = SysShipperId;
    }

    if (ShipperCode != "") {
      data["shipper_code"] = ShipperCode;
    } else {
      toast.error("コードを入力してください。", { autoClose: 3000 }); // please enter code
      setOpen(false);
      return;
    }
    data["shipper_a_code"] = ShipperACode;
    if (ShipperName != "") {
      data["shipper_name"] = ShipperName;
    } else {
      toast.error("名称を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    data["shipper_a_name"] = ShipperAName;
    if (ShipperNameKana != "") {
      data["shipper_name_kana"] = ShipperNameKana;
    } else {
      toast.error("フリガナを入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    data["shipper_honor"] = ShipperHonor;

    if (ShipperZip1 != "" && ShipperZip2 != "") {
      data["shipper_zip"] = ShipperZip1 + "-" + ShipperZip2; //郵便番号
    } else {
      toast.error("郵便番号を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (ShipperAddress1 != "") {
      data["shipper_address_1"] = ShipperAddress1;
    } else {
      toast.error("住所（都道府県）を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (ShipperAddress2 != "") {
      data["shipper_address_2"] = ShipperAddress2;
    } else {
      toast.error("市区町村を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    data["shipper_address_3"] = ShipperAddress3;
    data['shipper_town'] = ShipperTown;
    if (ShipperTel1 != "" && ShipperTel2 != "" && ShipperTel3 != "") {
      data["shipper_tel"] = ShipperTel1 + "-" + ShipperTel2 + "-" + ShipperTel3; //電話番号
    } else {
      toast.error("電話番号を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (ShipperFax1 != "" && ShipperFax2 != "" && ShipperFax3 != "") {
      data["shipper_fax"] = ShipperFax1 + "-" + ShipperFax2 + "-" + ShipperTel3; //Fax番号
    } else {
      toast.error("FAX番号を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (ShipperEmail != "") {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (ShipperEmail.match(mailformat)) {
        data["shipper_email"] = ShipperEmail;
      } else {
        toast.error("正しいメールアドレスを入力してください。", {
          autoClose: 3000,
        });
        setOpen(false);
        return;
      }
    } else {
      toast.error("メールアドレスを入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }
    if (ShipperSetType) {
      data["shipper_set_type"] = ShipperSetType;
    } else {
      toast.error("荷送人設定を入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }

    data["category_code_1"] = CategoryCode1;
    data["category_code_2"] = CategoryCode2;
    data["category_code_3"] = CategoryCode3;

    data["multi_arrival_type"] = MultiArrivalType;
    data["stock_empty_type"] = StockEmptyType;
    data["zero_contain_item_flag"] = ZeroContainItemFlag;
    data["zero_contain_flag"] = ZeroContainFlag;
    data["shipper_print_flag"] = ShipperPrintFlag;
    data['subscription'] = "1";
    data['shipper_user_id'] = shipperUserId;

    let url =
      jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstShipperUserMatchEndpoint;
    csLoading(true);
    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
        },
      })
      .then((response) => {
        if (response.status == 200) {
          let ret = false;
          if (SysShipperId > 0) {
            if (response.data) {
              ret = true;
            } else if (response.data) {
              toast.error("すでに登録済みです。", { autoClose: 3000 });
            } else {
              toast.error("登録に失敗しました。", { autoClose: 3000 });
            }
          } else {
            if (response.data["id"] != null) {
              setSysShipperId(response.data["id"]);
              ret = true;
            } else {
              toast.error("登録に失敗しました。", { autoClose: 3000 });
            }
          }
          navigate('/warehouse/subscription')
          if (ret) {
            saveShipperItems();
          }
        }
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          useJwt.logout();
          navigate("/login");
        } else {
          toast.error("登録に失敗しました。", { autoClose: 3000 });
        }
      });
    setOpen(false);
  };
  const saveShipperItems = () => {
    let data = {};
    data["userID"] = userData["id"];

    let validate_flg = true;
    let vmsg = "";
    let itemData = [];
    for (var i = 0; i < mainTableData.length; i++) {
      let row = {};
      if (mainTableData[i]["sys_shipper_item_id"] != 0) {
        row["sys_shipper_item_id"] = mainTableData[i]["sys_shipper_item_id"];
      }
      if (mainTableData[i]["shipper_id"] != "") {
        row["shipper_id"] = mainTableData[i]["shipper_id"];
      } else {
        row["shipper_id"] = SysShipperId;
      }
      if (mainTableData[i]["apply_date"] != "") {
        var regexp = /^(\d{1,4})[./-](\d{1,2})?$/;
        if (!regexp.test(mainTableData[i]["apply_date"])) {
          vmsg = "正しい適用年月を入力してください。";
          validate_flg = false;
          break;
        } else {
          row["apply_date"] = mainTableData[i]["apply_date"];
        }
      } else {
        vmsg = "適用年月を入力してください。";
        validate_flg = false;
        break;
      }
      if (mainTableData[i]["deadline_times"] != "") {
        row["deadline_times"] = mainTableData[i]["deadline_times"];
        row["deadline_day_1"] = mainTableData[i]["deadline_day_1"];
        if (mainTableData[i]["deadline_times"] == 1) {
          row["deadline_day_2"] = mainTableData[i]["deadline_day_2"];
          row["deadline_day_3"] = mainTableData[i]["deadline_day_3"];
        } else {
          row["deadline_day_2"] = 0;
          row["deadline_day_3"] = 0;
        }
      } else {
        vmsg = "期締を入力してください。";
        validate_flg = false;
        break;
      }
      if (mainTableData[i]["receive_schedule_date"] != "") {
        row["receive_schedule_date"] =
          mainTableData[i]["receive_schedule_date"];
      } else {
        vmsg = "入金予定日を入力してください。";
        validate_flg = false;
        break;
      }
      if (mainTableData[i]["storage_fee_type"] != "") {
        row["storage_fee_type"] = mainTableData[i]["storage_fee_type"];
      } else {
        vmsg = "保管料を入力してください。";
        validate_flg = false;
        break;
      }
      console.log(mainTableData[i]["cargo_handling_fee_type"]);
      if (mainTableData[i]["cargo_handling_fee_type"] != "") {
        row["cargo_handling_fee_type"] =
          mainTableData[i]["cargo_handling_fee_type"];
      } else {
        vmsg = "荷役料入力してください。";
        validate_flg = false;
        break;
      }
      row["sales_fraction_type"] = mainTableData[i]["sales_fraction_type"];
      row["invoice_cal_type"] = mainTableData[i]["invoice_cal_type"];
      row["invoice_cal_fraction_type"] =
        mainTableData[i]["invoice_cal_fraction_type"];
      row["invoice_print_type"] = mainTableData[i]["invoice_print_type"];
      row["tax_type"] = mainTableData[i]["tax_type"];
      row["tax_no"] = mainTableData[i]["tax_no"];
      row["tax_fraction_type"] = mainTableData[i]["tax_fraction_type"];
      row["deadline_date"] = mainTableData[i]["deadline_date"];

      itemData[i] = row;
    }

    if (validate_flg) {
      data["data"] = itemData;
      let url =
        jwtDefaultConfig.backendUrl +
        jwtDefaultConfig.setMstShipperItemsEndpoint;
      csLoading(true);
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            useJwt.setToken(response.data.token);
            if (response.data.data == 1) {
              toast.success("登録致しました。", { autoClose: 3000 });
              getShipperItemData(SysShipperId);
            } else if (response.data.data == 2) {
              toast.error("すでに登録済みです。", { autoClose: 3000 });
            } else {
              toast.error("登録に失敗しました。", { autoClose: 3000 });
            }
          }
          csLoading(false);
        })
        .catch((error) => {
          csLoading(false);
          if (
            error.response &&
            error.response.status &&
            error.response.status === 401
          ) {
            useJwt.logout();
            navigate("/login");
          } else {
            toast.error("登録に失敗しました。", { autoClose: 3000 });
          }
        });
    } else {
      toast.error(vmsg, { autoClose: 3000 });
    }
  };
  const loadModalDelData = (event) => {
    event.preventDefault();
    if (SysShipperId > 0) {
      setShowModalDel(true);
    }
  };
  const loadModalDelData2 = (event) => {
    event.preventDefault();
    setShowModalDel2(true);
  };
  const deleteData = (event) => {
    event.preventDefault();
    setShowModalDel(false);
    if (SysShipperId > 0) {
      // delete shipper items ----------------------------------
      let delIds = [];
      var checkboxes = document.querySelectorAll(
        '#tbodyWrap input[type="checkbox"]'
      );
      for (let i = 0; i < checkboxes.length; i++) {
        let sys_shipper_item_id = document.getElementById(
          "sys_shipper_item_id_" + i
        ).value;
        if (sys_shipper_item_id > 0) {
          delIds.push(sys_shipper_item_id);
        }
      }
      if (delIds.length > 0) {
        let data = {};
        data["userID"] = userData["id"];
        data["delIds"] = delIds;
        let url =
          jwtDefaultConfig.backendUrl +
          jwtDefaultConfig.delMstShipperItemsEndpoint;
        axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + useJwt.getToken(),
            },
          })
          .then((response) => {
            gridMainTable([]);
          })
          .catch((error) => { });
      }
      // delete shiper -----------------------------------------
      let data2 = {};
      data2["userID"] = userData["id"];
      data2["id"] = SysShipperId;
      let url =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.delMstShipperEndpoint;
      axios
        .post(url, data2, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            setShipperCode("");
            setShipperData([]);
          }
        })
        .catch((error) => { });

      setShipperCode("");
      setShipperData([]);
    }
  };
  const deleteItem = (event) => {
    setShowModalDel2(false);
    let delIds = [];
    let remainIndexs = [];
    var checkboxes = document.querySelectorAll(
      '#tbodyWrap input[type="checkbox"]'
    );
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        let sys_shipper_item_id = document.getElementById(
          "sys_shipper_item_id_" + i
        ).value;
        if (sys_shipper_item_id > 0) {
          delIds.push(sys_shipper_item_id);
        }
      } else {
        remainIndexs.push(i);
      }
    }

    if (delIds.length > 0) {
      let data = {};
      data["userID"] = userData["id"];
      data["delIds"] = delIds;
      let url =
        jwtDefaultConfig.backendUrl +
        jwtDefaultConfig.delMstShipperItemsEndpoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            getShipperItemData(SysShipperId);
          }
        })
        .catch((error) => { });
    } else {
      let remainData = [];
      for (var i = 0; i < mainTableData.length; i++) {
        for (var j = 0; j < remainIndexs.length; j++) {
          if (i == remainIndexs[j]) {
            let row = {};
            row = mainTableData[i];
            remainData.push(row);
          }
        }
      }
      gridMainTable(remainData);
      $('#tbodyWrap input[type="checkbox"]').prop("checked", false);
    }
  };
  // ========================== starts code for search ===============================
  const [SearchValue, setSearchValue] = useState("");
  const [SearchValue2, setSearchValue2] = useState("");
  const [tableData, setTableData] = useState([]);

  const loadModalData = (event) => {
    event.preventDefault();
    setSearchValue(ShipperCode);
    setSearchValue2(ShipperACode);

    if (ShipperCode != "") {
      let data = {};
      data["userID"] = userData["id"];
      data["shipper_code"] = ShipperCode;
      if (ShipperACode != "") {
        data["shipper_a_code"] = ShipperACode;
      }
      let url =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstShipperEndpoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          setTableData(response.data);
          setShowModal(true);
        })
        .catch((error) => { });
    } else {
      setTableData([]);
      setShowModal(true);
    }
  };
  // ----------------------------- category modal -------------------------------------
  const [showModalCategory, setShowModalCategory] = useState(false);
  const [CategorySearchValue, setCategorySearchValue] = useState("");
  const [CategoryTableData, setCategoryTableData] = useState([]);
  const [mstType, setMstType] = useState(0);
  const [categoryType, setCategoryType] = useState(0);

  const loadCategoryModalData = (event, param) => {
    event.preventDefault();
    let code = document.getElementById("CategoryCode" + param).value;
    setCategorySearchValue(code);
    if (param == 1) {
      setCategoryType(0);
    } else if (param == 2) {
      setCategoryType(1);
    } else {
      setCategoryType(2);
    }
    document.getElementById("selectedId").value = "CategoryCode" + param;
    if (code != "") {
      let data = {};
      data["userID"] = userData["id"];
      if (code != "") {
        data["category_code"] = code;
      }

      data['mst_type'] = 0;
      data['category_type'] = param - 1;

      let url =
        jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstCategoryEndpoint;
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + useJwt.getToken(),
          },
        })
        .then((response) => {
          setCategoryTableData(response.data);
          setShowModalCategory(true);
        })
        .catch((error) => { });
    } else {
      setCategoryTableData([]);
      setShowModalCategory(true);
    }
  };
  const setCategoryData = (data) => {
    let resultCode = data["category_code"];
    let resultName = data["category_name"];
    if (document.getElementById("selectedId").value == "CategoryCode1") {
      setCategoryCode1(resultCode);
      setCategoryName1(resultName);
    } else if (document.getElementById("selectedId").value == "CategoryCode2") {
      setCategoryCode2(resultCode);
      setCategoryName2(resultName);
    } else {
      setCategoryCode3(resultCode);
      setCategoryName3(resultName);
    }
  };
  // ========================== ends code for search ==================================
  // style array
  const style_row = [gstyle.cs_row];
  const style_col_12 = [gstyle.cs_col, gstyle.cs_col_12];
  const style_col_6 = [gstyle.cs_col, gstyle.cs_col_6];
  const style_col_4 = [gstyle.cs_col, gstyle.cs_col_4];
  const style_col_3 = [gstyle.cs_col, gstyle.cs_col_3];
  const style_label_wrap = [gstyle.cs_label, gstyle.cs_flex_2];
  const style_input_wrap = [gstyle.p0015];
  const style_input_100px = [gstyle.cs_input, gstyle.w100px];
  const style_input_220px = [gstyle.cs_input, gstyle.w220px];
  const style_input_80 = [gstyle.cs_input, gstyle.w80];
  const style_input_80px = [gstyle.cs_input, gstyle.w80px];
  const style_split_span = [style.split_span];
  const style_select_245px = [gstyle.cs_select, gstyle.w245px];

  const style_input_100px_bl_n = [
    gstyle.cs_input,
    gstyle.w100px,
    gstyle.cs_bl_n,
  ];
  const style_input_bl_n = [gstyle.cs_input, gstyle.cs_bl_n];

  const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
  const style_th_col_1 = [style.cs_th, gstyle.cs_col_1];
  const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
  const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
  const style_td_col_1 = [style.cs_td, gstyle.cs_col_1];
  const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
  const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];
  const style_btn_new_wrap = [gstyle.p0015, gstyle.btn_new_wrap];

  const tblLabel = [
    { key: 0, value: "適用年月", class: "cs_th w1p" },
    { key: 1, value: "期締", class: "cs_th" },
    { key: 2, value: "締1", class: "cs_th" },
    { key: 3, value: "締2", class: "cs_th" },
    { key: 4, value: "締3", class: "cs_th" },
    { key: 5, value: "入金予定日", class: "cs_th w2p" },
    { key: 6, value: "保管料", class: "cs_th w5p" },
    { key: 7, value: "荷役料", class: "cs_th w5p" },
    { key: 8, value: "売上端数区分", class: "cs_th w5p" },
    { key: 9, value: "計算区分", class: "cs_th w5p" },
    { key: 10, value: "集計区分", class: "cs_th w5p" },
    { key: 11, value: "品番印字", class: "cs_th w5p" },
    { key: 12, value: "税区分", class: "cs_th w5p" },
    { key: 13, value: "税 No", class: "cs_th w4p" },
    { key: 14, value: "税端数区分", class: "cs_th w5p" },
    { key: 15, value: "末締年月", class: "cs_th w4p" },
  ];
  const tblColData = [
    {
      key: 0,
      value: "apply_date",
      nextValue: 'deadline_times',
      class: "cs_td w1p",
      class2: "cs_tbl_input",
      type: "text",
      p: "2022/09",
    },
    {
      key: 1,
      value: "deadline_times",
      valueFlag: "deadline_times_flag",
      nextValue: 'deadline_day_1',
      class: "cs_td",
      class2: "cs_tbl_input",
      type: "select",
      option: [
        { value: '1', label: "1" },
        { value: '2', label: "2" },
        { value: '3', label: "3" },
      ],
    },
    // { key:1, value:'deadline_times',            class:'cs_td',      class2:'cs_tbl_input', type:'number',p:'' },
    {
      key: 2,
      value: "deadline_day_1",
      nextValue: 'deadline_day_2',
      class: "cs_td",
      class2: "cs_tbl_input",
      type: "number",
      p: "",
    },
    {
      key: 3,
      value: "deadline_day_2",
      nextValue: 'deadline_day_3',
      class: "cs_td",
      class2: "cs_tbl_input",
      type: "number",
      p: "",
    },
    {
      key: 4,
      value: "deadline_day_3",
      nextValue: 'receive_schedule_date',
      class: "cs_td",
      class2: "cs_tbl_input",
      type: "number",
      p: "",
    },
    {
      key: 5,
      value: "receive_schedule_date",
      nextValue: 'storage_fee_type',
      class: "cs_td w2p",
      class2: "cs_tbl_input",
      type: "number",
      p: "",
    },
    {
      key: 6,
      value: "storage_fee_type",
      valueFlag: "storage_fee_type_flag",
      nextValue: 'cargo_handling_fee_type',
      class: "cs_td w5p",
      class2: "cs_tbl_input",
      type: "select",
      option: [
        { value: '0', label: "前残+入庫" },
        { value: '1', label: "当残+入庫" },
        { value: '2', label: "前残" },
        { value: '3', label: "当残" },
        { value: '9', label: "計算無し" },
      ],
    },
    {
      key: 7,
      value: "cargo_handling_fee_type",
      valueFlag: "cargo_handling_fee_type_flag",
      nextValue: 'sales_fraction_type',
      class: "cs_td w5p",
      class2: "cs_tbl_input",
      type: "select",
      option: [
        { value: '0', label: "入庫＋出庫" },
        { value: '1', label: "入庫" },
        { value: '2', label: "出庫" },
        { value: '9', label: "計算無し" },
      ],
    },
    {
      key: 8,
      value: "sales_fraction_type",
      valueFlag: "sales_fraction_type_flag",
      nextValue: 'invoice_cal_type',
      class: "cs_td w5p",
      class2: "cs_tbl_input",
      type: "select",
      option: [
        { value: '0', label: "四捨五入" },
        { value: '1', label: "切り上げ" },
        { value: '2', label: "切り捨て" },
      ],
    },
    {
      key: 9,
      value: "invoice_cal_type",
      valueFlag: "invoice_cal_type_flag",
      nextValue: 'invoice_cal_fraction_type',
      class: "cs_td w5p",
      class2: "cs_tbl_input",
      type: "select",
      option: [
        { value: '0', label: "期締単位毎" },
        { value: '1', label: "締日一括" },
      ],
    },
    {
      key: 10,
      value: "invoice_cal_fraction_type",
      valueFlag: "invoice_cal_fraction_type_flag",
      nextValue: 'invoice_print_type',
      class: "cs_td w5p",
      class2: "cs_tbl_input",
      type: "select",
      option: [
        { value: '0', label: "品番集計" },
        { value: '1', label: "ロット集計" },
      ],
    },
    {
      key: 11,
      value: "invoice_print_type",
      valueFlag: "invoice_print_type_flag",
      nextValue: 'tax_type',
      class: "cs_td w5p",
      class2: "cs_tbl_input",
      type: "select",
      option: [
        { value: '0', label: "品番別" },
        { value: '1', label: "ロット別" },
      ],
    },
    {
      key: 12,
      value: "tax_type",
      valueFlag: "tax_type_flag",
      nextValue: 'tax_no',
      class: "cs_td w5p",
      class2: "cs_tbl_input",
      type: "select",
      option: [
        { value: '0', label: "商品毎" },
        { value: '1', label: "荷主一括" },
      ],
    },
    {
      key: 13,
      value: "tax_no",
      nextValue: 'tax_fraction_type',
      class: "cs_td w4p",
      class2: "cs_tbl_input",
      type: "select",
      option: TaxNoData,
    },
    {
      key: 14,
      value: "tax_fraction_type",
      valueFlag: "tax_fraction_type_flag",
      nextValue: 'deadline_date',
      class: "cs_td w5p",
      class2: "cs_tbl_input",
      type: "select",
      option: [
        { value: '0', label: "四捨五入" },
        { value: '1', label: "切り上げ" },
        { value: '2', label: "切り捨て" },
      ],
    },
    {
      key: 15,
      value: "deadline_date",
      nextValue: 'apply_date',
      class: "cs_td w4p",
      class2: "cs_tbl_input",
      type: "text",
      p: "",
    },
  ];

  const onDataKeyDown = (e, param) => {
    if (e.keyCode == 13) {
      switch (param) {
        case 1:
          document.getElementById('input1').focus();
          break;
        case 2:
          document.getElementById('input2').focus();
          break;
        case 3:
          document.getElementById('input3').focus();
          break;
        case 4:
          document.getElementById('input4').focus();
          break;
        case 5:
          document.getElementById('input5').focus();
          break;
        case 6:
          document.getElementById('input6').focus();
          break;
        case 7:
          document.getElementById('input7').focus();
          break;
        case 8:
          document.getElementById('input8').focus();
          break;
        case 9:
          document.getElementById('input9').focus();
          break;
        case 10:
          document.getElementById('input10').focus();
          break;
        case 11:
          document.getElementById('input11').focus();
          break;
        case 111:
          document.getElementById('input111').focus();
          break;
        case 12:
          document.getElementById('input12').focus();
          break;
        case 13:
          document.getElementById('input13').focus();
          break;
        case 14:
          document.getElementById('input14').focus();
          break;
        case 15:
          document.getElementById('input15').focus();
          break;
        case 16:
          document.getElementById('input16').focus();
          break;
        case 17:
          document.getElementById('input17').focus();
          break;
        case 18:
          document.getElementById('input18').focus();
          break;
        case 19:
          document.getElementById('CategoryCode1').focus();
          break;
        case 20:
          document.getElementById('CategoryCode2').focus();
          break;
        case 21:
          document.getElementById('CategoryCode3').focus();
          break;
        case 22:
          document.getElementById('type2').focus();
          break;
        default:
      }
    }
  }

  const [type1Open, setType1Open] = useState(false);
  const [type2Open, setType2Open] = useState(false);
  const [type3Open, setType3Open] = useState(false);
  const [type4Open, setType4Open] = useState(false);
  const [type5Open, setType5Open] = useState(false);
  const [type6Open, setType6Open] = useState(false);
  const [type7Open, setType7Open] = useState(false);

  const selectData = (e, index, id) => {
    switch (index) {
      case 1:
        setType1Open(false);
        break;
      case 2:
        setType2Open(false);
        break;
      case 3:
        setType3Open(false);
        break;
      case 4:
        setType4Open(false);
        break;
      case 5:
        setType5Open(false);
        break;
      case 6:
        setType6Open(false);
        break;
      case 7:
        setType7Open(false);
        break;
      default:
    }
    document.getElementById(id).focus();
  }
  const onType1KeyDown = (event) => {
    if (event.keyCode == 13) {
      document.getElementById('type2').focus();
      event.preventDefault();
    }
  }
  const onFocusType1 = (e, type) => {
    if (type == 1) {
      setType1Open(true);
    } else {
      setType1Open(false);
    }
  }

  const onType2KeyDown = (event) => {
    if (event.keyCode == 13) {
      document.getElementById('type3').focus();
      event.preventDefault();
    }
  }
  const onFocusType2 = (e, type) => {
    if (type == 1) {
      setType2Open(true);
    } else {
      setType2Open(false);
    }
  }

  const onType3KeyDown = (event) => {
    if (event.keyCode == 13) {
      document.getElementById('type4').focus();
      event.preventDefault();
    }
  }
  const onFocusType3 = (e, type) => {
    if (type == 1) {
      setType3Open(true);
    } else {
      setType3Open(false);
    }
  }

  const onType4KeyDown = (event) => {
    if (event.keyCode == 13) {
      document.getElementById('type5').focus();
      event.preventDefault();
    }
  }
  const onFocusType4 = (e, type) => {
    if (type == 1) {
      setType4Open(true);
    } else {
      setType4Open(false);
    }
  }

  const onType5KeyDown = (event) => {
    if (event.keyCode == 13) {
      document.getElementById('type6').focus();
      event.preventDefault();
    }
  }
  const onFocusType5 = (e, type) => {
    if (type == 1) {
      setType5Open(true);
    } else {
      setType5Open(false);
    }
  }

  const onType6KeyDown = (event) => {
    if (event.keyCode == 13) {
      document.getElementById('type7').focus();
      event.preventDefault();
    }
  }
  const onFocusType6 = (e, type) => {
    if (type == 1) {
      setType6Open(true);
    } else {
      setType6Open(false);
    }
  }

  const onType7KeyDown = (event) => {
    if (event.keyCode == 13) {
      document.getElementById('addBut').focus();
      event.preventDefault();
    }
  }
  const onFocusType7 = (e, type) => {
    if (type == 1) {
      setType7Open(true);
    } else {
      setType7Open(false);
    }
  }

  const tableKeyDown = (e, id) => {
    if (e.keyCode === 13) {
      console.log(id);
      e.preventDefault();
      document.getElementById(id).focus();
    }
  }

  const tableEndKeyDown = (e, id, index) => {
    let dataLength = mainTableData.length;

    if (dataLength <= index + 1) return;

    if (e.keyCode === 13) {
      e.preventDefault();
      document.getElementById(id).focus();
    }
  }

  const tokenCreate = () => {
    var uuid = Math.random().toString().replace("0.", "")
    var token = useJwt.createToken(uuid)
    setShipperToken(token)
  }

  const tokenSend = (event) => {
    event.preventDefault();
    let data = {};
    data["userID"] = userData["id"];

    if (ShipperName != "") {
      data["shipper_name"] = ShipperName;
    } else {
      toast.error("名称を入力してください。", { autoClose: 3000 });
      return;
    }
    if (ShipperEmail != "") {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (ShipperEmail.match(mailformat)) {
        data["shipper_email"] = ShipperEmail;
      } else {
        toast.error("正しいメールアドレスを入力してください。", {
          autoClose: 3000,
        });
        setOpen(false);
        return;
      }
    } else {
      toast.error("メールアドレスを入力してください。", { autoClose: 3000 });
      setOpen(false);
      return;
    }

    if (ShipperToken != "") {
      data["token"] = ShipperToken;
    } else {
      toast.error("トークンを入力してください。", { autoClose: 3000 });
      return;
    }

    let url =
      jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMstShipperTokenSendEndpoint;
    csLoading(true);
    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + useJwt.getToken(),
        },
      })
      .then((response) => {
        console.log(response.data)
        if (response.status == 200) {
          if(response.data == "failed"){
            toast.error("送信に失敗しました。", { autoClose: 3000 });
          }else{
            toast.success("送信されました。", {autoClose: 3000});
          }
        }
        csLoading(false);
      })
      .catch((error) => {
        csLoading(false);
        toast.error("送信に失敗しました。", { autoClose: 3000 });
      });
    setOpen(false);
  }

  return (
    <div className={gstyle.display}>
      <div className={gstyle.loader_container} id="csLoader">
        <ClipLoader color={"#fff"} size={60} />
      </div>
      <ToastContainer />
      <input type={"hidden"} id="selectedId" />
      <ShipperModal
        show={showModal}
        onClose={() => setShowModal(false)}
        width={"70%"}
        SearchValue={SearchValue}
        onChange={setSearchValue}
        SearchValue2={SearchValue2}
        onChange2={setSearchValue2}
        tableData={tableData}
        setTableData={setTableData}
        target={setShipperData}
      />
      <CategoryModal
        show={showModalCategory}
        onClose={() => setShowModalCategory(false)}
        width={"70%"}
        SearchValue={CategorySearchValue}
        onChange={setCategorySearchValue}
        tableData={CategoryTableData}
        setTableData={setCategoryTableData}
        target={setCategoryData}
        mstType={mstType}
        categoryType={categoryType}
      />
      <Modal2
        title="荷主削除"
        show={showModalDel}
        onClose={() => setShowModalDel(false)}
        width={"40%"}
        onConfirm={deleteData}
      >
        <p>選択したデータを削除しますか？</p>
      </Modal2>
      <Modal2
        title="荷主削除"
        show={showModalDel2}
        onClose={() => setShowModalDel2(false)}
        width={"40%"}
        onConfirm={deleteItem}
      >
        <p>選択したデータを削除しますか？</p>
      </Modal2>
      <div className={gstyle.border}>
        <p className={gstyle.text}>荷主マスタ</p>
      </div>
      <div className={gstyle.filed}>
        <div className={gstyle.cs_card_1}>
          {/* コード */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>コード</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"number"}
                      className={style_input_100px.join(" ")}
                      onKeyDown={(e) => onKeyDownShipperCode(e, 1)}
                      value={ShipperCode} id="input1"
                      onChange={handleChangeShipperCode}
                    />
                    <input
                      type={"number"}
                      className={style_input_100px_bl_n.join(" ")}
                      onKeyDown={(e) => onKeyDownShipperCode(e, 2)}
                      value={ShipperACode} id="input2"
                      onChange={handleChangeShipperACode}
                    />
                    <div
                      className={gstyle.icon_search_wrap}
                      onClick={(e) => loadModalData(e)}
                    >
                      <img
                        src={IconSearch}
                        className={gstyle.icon_search}
                        alt="icon"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* 名称 */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>名称</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"text"}
                      className={style_input_80.join(" ")}
                      value={ShipperName}
                      id="input3" onKeyDown={(e) => onDataKeyDown(e, 4)}
                      onChange={handleChangeShipperName}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>フリガナ</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"text"}
                      className={style_input_80.join(" ")}
                      id="input4" onKeyDown={(e) => onDataKeyDown(e, 5)}
                      value={ShipperNameKana}
                      onChange={handleChangeShipperNameKana}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* 略称 */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>略称</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"text"}
                      className={style_input_80.join(" ")}
                      value={ShipperAName}
                      id="input5" onKeyDown={(e) => onDataKeyDown(e, 6)}
                      onChange={handleChangeShipperAName}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>敬称</span>
                  <div className={gstyle.cs_flex}>
                    <Select
                      showSearch
                      open={type1Open}
                      onSelect={(e) => selectData(e, 1, 'input7')}
                      placeholder="敬称を選択します。"
                      optionFilterProp="children"
                      onChange={handleChangeShipperHonor}
                      onKeyDown={(e) => onDataKeyDown(e, 7)}
                      onFocus={(e) => onFocusType1(e, 1)}
                      onBlur={(e) => onFocusType1(e, 2)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ marginTop: 14, width: '100%' }}
                      value={ShipperHonor}
                      id="input6"
                      options={[
                        { value: '0', label: '御中' },
                        { value: '1', label: '様' },
                      ]}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* 郵便番号 */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>郵便番号</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"number"}
                      className={style_input_80px.join(" ")}
                      id="input7" onKeyDown={(e) => onDataKeyDown(e, 8)}
                      value={ShipperZip1}
                      onChange={handleChangeShipperZip1}
                    />
                    <span className={style_split_span.join(" ")}>-</span>
                    <input
                      type={"number"}
                      className={style_input_80px.join(" ")}
                      value={ShipperZip2}
                      id="input8" onKeyDown={(e) => onDataKeyDown(e, 9)}
                      onChange={handleChangeShipperZip2}
                    />
                    <div className={style.postcode_btn_wrap}>
                      <button
                        className={style.postcode_btn}
                        onClick={getAddress}
                      >
                        郵便検索
                      </button>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* 住所 */}
          <div className={style_row.join(" ")}>
            <div className={style_col_3.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>住所（都道府県）</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"text"}
                      className={style_input_220px.join(" ")}
                      id="input9" onKeyDown={(e) => onDataKeyDown(e, 10)}
                      value={ShipperAddress1}
                      onChange={handleChangeShipperAddress1}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_3.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>市区町村</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"text"}
                      className={style_input_220px.join(" ")}
                      id="input10" onKeyDown={(e) => onDataKeyDown(e, 111)}
                      value={ShipperAddress2}
                      onChange={handleChangeShipperAddress2}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_3.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>町名</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"text"}
                      className={style_input_220px.join(" ")}
                      value={ShipperTown}
                      id="input111" onKeyDown={(e) => onDataKeyDown(e, 11)}
                      onChange={handleChangeShipperTown}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_3.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>丁目・番地</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"text"}
                      className={style_input_220px.join(" ")}
                      value={ShipperAddress3}
                      id="input11" onKeyDown={(e) => onDataKeyDown(e, 12)}
                      onChange={handleChangeShipperAddress3}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* 電話番号, FAX番号 */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>電話番号</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"number"}
                      className={style_input_80px.join(" ")}
                      id="input12" onKeyDown={(e) => onDataKeyDown(e, 13)}
                      value={ShipperTel1}
                      onChange={handleChangeShipperTel1}
                    />
                    <span className={style_split_span.join(" ")}>-</span>
                    <input
                      type={"number"}
                      className={style_input_80px.join(" ")}
                      id="input13" onKeyDown={(e) => onDataKeyDown(e, 14)}
                      value={ShipperTel2}
                      onChange={handleChangeShipperTel2}
                    />
                    <span className={style_split_span.join(" ")}>-</span>
                    <input
                      type={"number"}
                      className={style_input_80px.join(" ")}
                      id="input14" onKeyDown={(e) => onDataKeyDown(e, 15)}
                      value={ShipperTel3}
                      onChange={handleChangeShipperTel3}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>FAX番号</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"number"}
                      className={style_input_80px.join(" ")}
                      id="input15" onKeyDown={(e) => onDataKeyDown(e, 16)}
                      value={ShipperFax1}
                      onChange={handleChangeShipperFax1}
                    />
                    <span className={style_split_span.join(" ")}>-</span>
                    <input
                      type={"number"}
                      className={style_input_80px.join(" ")}
                      value={ShipperFax2}
                      id="input16" onKeyDown={(e) => onDataKeyDown(e, 17)}
                      onChange={handleChangeShipperFax2}
                    />
                    <span className={style_split_span.join(" ")}>-</span>
                    <input
                      type={"number"}
                      className={style_input_80px.join(" ")}
                      value={ShipperFax3}
                      id="input17" onKeyDown={(e) => onDataKeyDown(e, 18)}
                      onChange={handleChangeShipperFax3}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* メールアドレス */}
          <div className={style_row.join(" ")}>
            <div className={style_input_wrap.join(" ")} style={{ display: "flex" }}>
              <label className={style_label_wrap.join(" ")}>
                <span className={gstyle.cs_span}>メールアドレス</span>
                <div className={gstyle.cs_flex}>
                  <input
                    type={"text"}
                    className={style_input_80.join(" ")}
                    value={ShipperEmail}
                    id="input18" onKeyDown={(e) => onDataKeyDown(e, 19)}
                    onChange={handleChangeShipperEmail}
                  />
                </div>
              </label>
            </div>
            {/* <div className={style_input_wrap.join(" ")}>
              <label className={style_label_wrap.join(" ")}>
                <span className={gstyle.cs_span}>トークン</span>
                <div className={gstyle.cs_flex}>
                  <input
                    type={"text"}
                    className={style_input_80.join(" ")}
                    value={ShipperToken} readOnly
                  />
                </div>
              </label>
            </div> */}
            {/* <div style={{ display: "flex" }}>
              <button className={gstyle.btn_new} onClick={tokenCreate} id="tokenCreate">
                トークン生成
              </button>
              <button style={{ marginLeft: 20 }}
                className={gstyle.btn_new}
                onClick={tokenSend}
              >
                送信
              </button>
            </div> */}

          </div>
          {/* 分類コード */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>分類コード①</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"number"}
                      className={style_input_100px.join(" ")}
                      id="CategoryCode1"
                      value={CategoryCode1}
                      onKeyDown={(e) => onDataKeyDown(e, 20)}
                      onChange={(e) => handleChangeCategoryCode(e, 1)}
                    />
                    <input
                      type={"text"}
                      className={style_input_bl_n.join(" ")}
                      disabled
                      id="CategoryName1"
                      value={CategoryName1}
                    />
                    <div
                      className={gstyle.icon_search_wrap}
                      onClick={(e) => loadCategoryModalData(e, 1)}
                    >
                      <img
                        src={IconSearch}
                        className={gstyle.icon_search}
                        alt="icon"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>分類コード②</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"number"}
                      className={style_input_100px.join(" ")}
                      id="CategoryCode2"
                      value={CategoryCode2}
                      onKeyDown={(e) => onDataKeyDown(e, 21)}
                      onChange={(e) => handleChangeCategoryCode(e, 2)}
                    />
                    <input
                      type={"text"}
                      className={style_input_bl_n.join(" ")}
                      disabled
                      id="CategoryName2"
                      onKeyDown={(e) => onDataKeyDown(e, 23)}
                      value={CategoryName2}
                    />
                    <div
                      className={gstyle.icon_search_wrap}
                      onClick={(e) => loadCategoryModalData(e, 2)}
                    >
                      <img
                        src={IconSearch}
                        className={gstyle.icon_search}
                        alt="icon"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>分類コード③</span>
                  <div className={gstyle.cs_flex}>
                    <input
                      type={"number"}
                      className={style_input_100px.join(" ")}
                      id="CategoryCode3"
                      value={CategoryCode3}
                      onKeyDown={(e) => onDataKeyDown(e, 22)}
                      onChange={(e) => handleChangeCategoryCode(e, 3)}
                    />
                    <input
                      type={"text"}
                      className={style_input_bl_n.join(" ")}
                      disabled
                      id="CategoryName1"
                      value={CategoryName3}
                    />
                    <div
                      className={gstyle.icon_search_wrap}
                      onClick={(e) => loadCategoryModalData(e, 3)}
                    >
                      <img
                        src={IconSearch}
                        className={gstyle.icon_search}
                        alt="icon"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* 重複入庫, 在庫無出庫 */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>重複入庫</span>
                  <div className={gstyle.cs_flex}>
                    <Select
                      showSearch
                      open={type2Open}
                      onSelect={(e) => selectData(e, 2, 'type3')}
                      placeholder="重複入庫を選択します。"
                      optionFilterProp="children"
                      onChange={handleChangeMultiArrivalType}
                      onKeyDown={onType2KeyDown}
                      onFocus={(e) => onFocusType2(e, 1)}
                      onBlur={(e) => onFocusType2(e, 2)}
                      value={MultiArrivalType}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ marginTop: 14, width: '100%' }}
                      id="type2"
                      options={[
                        { value: '0', label: '許可' },
                        { value: '1', label: '警告' },
                        { value: '2', label: '禁止' },
                      ]}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>在庫無出庫</span>
                  <div className={gstyle.cs_flex}>
                    <Select
                      showSearch
                      open={type3Open}
                      onSelect={(e) => selectData(e, 3, 'type4')}
                      placeholder="在庫無出庫を選択します。"
                      optionFilterProp="children"
                      onChange={handleChangeStockEmptyType}
                      onKeyDown={onType3KeyDown}
                      onFocus={(e) => onFocusType3(e, 1)}
                      onBlur={(e) => onFocusType3(e, 2)}
                      value={StockEmptyType}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ marginTop: 14, width: '100%' }}
                      id="type3"
                      options={[
                        { value: '0', label: '許可' },
                        { value: '1', label: '警告' },
                        { value: '2', label: '禁止' },
                      ]}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* 品番 ロット No */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>品番</span>
                  <div className={gstyle.cs_flex}>
                    <Select
                      showSearch
                      open={type4Open}
                      onSelect={(e) => selectData(e, 4, 'type5')}
                      placeholder="品番を選択します。"
                      optionFilterProp="children"
                      onChange={handleChangeZeroContainItemFlag}
                      value={ZeroContainItemFlag}
                      onKeyDown={onType4KeyDown}
                      onFocus={(e) => onFocusType4(e, 1)}
                      onBlur={(e) => onFocusType4(e, 2)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ marginTop: 14, width: '100%' }}
                      id="type4"
                      options={[
                        { value: '0', label: 'しない' },
                        { value: '1', label: 'する' },
                      ]}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>ロット No</span>
                  <div className={gstyle.cs_flex}>
                    <Select
                      showSearch
                      open={type5Open}
                      onSelect={(e) => selectData(e, 5, 'type6')}
                      placeholder="ロット Noを選択します。"
                      optionFilterProp="children"
                      onChange={handleChangeZeroContainFlag}
                      value={ZeroContainFlag}
                      onKeyDown={onType5KeyDown}
                      onFocus={(e) => onFocusType5(e, 1)}
                      onBlur={(e) => onFocusType5(e, 2)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ marginTop: 14, width: '100%' }}
                      id="type5"
                      options={[
                        { value: '0', label: 'しない' },
                        { value: '1', label: 'する' },
                      ]}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/* 荷送人設定, 荷主印字 */}
          <div className={style_row.join(" ")}>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>荷送人設定</span>
                  <div className={gstyle.cs_flex}>
                    <Select
                      showSearch
                      open={type6Open}
                      onSelect={(e) => selectData(e, 6, 'type7')}
                      placeholder="荷送人設定を選択します。"
                      optionFilterProp="children"
                      onChange={handleChangeShipperSetType}
                      value={ShipperSetType}
                      onKeyDown={onType6KeyDown}
                      onFocus={(e) => onFocusType6(e, 1)}
                      onBlur={(e) => onFocusType6(e, 2)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ marginTop: 14, width: '100%' }}
                      id="type6"
                      options={[
                        { value: '0', label: '荷主' },
                        { value: '1', label: '荷送人' },
                        { value: '2', label: '自社' },
                      ]}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_input_wrap.join(" ")}>
                <label className={style_label_wrap.join(" ")}>
                  <span className={gstyle.cs_span}>荷主印字</span>
                  <div className={gstyle.cs_flex}>
                    <Select
                      showSearch
                      open={type7Open}
                      onSelect={(e) => selectData(e, 7, 'addBut')}
                      placeholder="荷主印字を選択します。"
                      optionFilterProp="children"
                      onChange={handleChangeShipperPrintFlag}
                      value={ShipperPrintFlag}
                      onKeyDown={onType7KeyDown}
                      onFocus={(e) => onFocusType7(e, 1)}
                      onBlur={(e) => onFocusType7(e, 2)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ marginTop: 14, width: '100%' }}
                      id="type7"
                      options={[
                        { value: '0', label: '印字有り' },
                        { value: '1', label: '印字無し' },
                      ]}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className={style_col_4.join(" ")}>
              <div className={style_btn_new_wrap.join(" ")}>
                <button className={gstyle.btn_new} onClick={newRecord} id="addBut">
                  追加
                </button>
                <button
                  className={gstyle.btn_delete2}
                  onClick={loadModalDelData2}
                >
                  削除
                </button>
              </div>
            </div>
          </div>
          <div className={style.tbl_wrap}>
            <div className={style_item_tbl.join(" ")}>
              <div className={style.cs_thead}>
                <div className={style.cs_tr}>
                  <div className={style_th_col_ck.join(" ")}>
                    <input
                      type={"checkbox"}
                      className={style.cs_tbl_check}
                      id="allCheckBtn"
                      onChange={allCheckData}
                    />
                  </div>
                  {tblLabel.map((data, i) => (
                    <div key={i} className={data["class"]}>
                      {data["value"]}
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.cs_tbody} id="tbodyWrap">
                {mainTableData.map((data, index) => (
                  <div className={style.cs_tr} key={index}>
                    <div className={style_td_col_ck.join(" ")}>
                      <input
                        type={"checkbox"}
                        className={style.cs_tbl_check}
                        id={"chk_" + index}
                        onChange={handleChangeChk}
                      />
                      <input
                        type="hidden"
                        id={"sys_shipper_item_id_" + index}
                        value={data["sys_shipper_item_id"]}
                      />
                    </div>
                    {tblColData.map((col, i) => (
                      <div key={i} className={col["class"]}>
                        {col["type"] == "number" || col["type"] == "text" ? (
                          <>
                            <input
                              type={col["type"]}
                              className={col["class2"]}
                              placeholder={col["p"]}
                              id={col["value"] + index}
                              value={
                                (col["value"] == "deadline_day_2" ||
                                  col["value"] == "deadline_day_3") &&
                                  (data["deadline_times"] == 2 ||
                                    data["deadline_times"] == 3)
                                  ? "0"
                                  : data[col["value"]]
                              }
                              disabled={
                                (col["value"] == "deadline_day_2" ||
                                  col["value"] == "deadline_day_3") &&
                                  (data["deadline_times"] == 2 ||
                                    data["deadline_times"] == 3)
                                  ? true
                                  : false
                              }
                              onKeyDown={(e) =>
                                onKeyDownItem(e, 1, col["value"], index, col['nextValue'])
                              }
                              onChange={(e) =>
                                handlChangeItemData(e, 1, col["value"], index)
                              }
                            />
                          </>
                        ) : (
                          <>
                            <Select
                              open={data[col["valueFlag"]]}
                              value={data[col["value"]]}
                              optionFilterProp="children"
                              onChange={(e) => handlChangeItemData(e, 5, col["value"], index)}
                              onKeyDown={(e) => tableKeyDown(e, col['nextValue'] + index)}
                              onFocus={(e) => handlChangeItemData(e, 3, col["valueFlag"], index)}
                              onBlur={(e) => handlChangeItemData(e, 4, col["valueFlag"], index)}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              style={{ width: '100%' }}
                              id={col["value"] + index}
                              options={col["option"]}
                            />
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* button 設定 */}
          <div className={gstyle.btn_update_wrap}>
            <button className={gstyle.btn_update} onClick={handleOpen}>
              登録する
            </button>
          </div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              {" "}
              <div style={{ fontSize: "1.1rem" }}>
                こちらの内容で登録しますか？
              </div>{" "}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} className={gstyle.save_confirm_no}>
                いいえ
              </Button>
              <Button
                onClick={saveData}
                className={gstyle.save_confirm_yes}
                autoFocus
              >
                はい
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Shipper;
