/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import style from "./RegistryBoth.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegistryWarehouseEnd = () => {
  //useJwt.logout();
  // パスワード表示制御ようのstate
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  return (
    <div className={style.background}>
      <form className={style.endForm}>
        <div className={style.endLayout}>
          <p>会員登録ありがとうございます。</p>
          <p>引き続きロジストックを宜しくお願い致します。</p>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <Link to={"/"} className={style.registryBut}>
          OK
          </Link>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default RegistryWarehouseEnd;
