/* eslint-disable eqeqeq */
import style from './MainSetting.module.css';
import gstyle from '../../../css/GeneralStyle.module.css';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from "axios";
import * as useJwt from '../../../auth/jwt/useJwt.js';
import jwtDefaultConfig from '../../../auth/jwt/jwtDefaultConfig';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal2 from '../../../components/common/modal/Modal2';
import IconSearch from '../../../components/common/icons/icon_search.png';
import FreightageModal from '../../../components/common/modal/FreightageModal';
import $ from 'jquery';

import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select } from 'antd';

const MainSetting = (props) => {
    const { register, handleSubmit } = useForm();

    const authentication = useJwt.isUserLoggedIn();
    const userData = JSON.parse(useJwt.getUserData());
    const menuPatternData = JSON.parse(useJwt.getMenuPatternData());
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [mainTableData, setMainTableData] = useState([]);
    const [showModalDel, setShowModalDel] = useState(false);

    const csloading = (flag) => {
        if (flag) {
            document.getElementById('csloader').style.display = "flex";
        } else {
            document.getElementById('csloader').style.display = "none";
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // MainSetting ID
    const [sysMainSettingsId, setSysMainSettingsId] = useState(0);
    // 会社名
    const [companyName, setCompanyName] = useState("");
    const handleChangeCompanyName = (event) => {
        setCompanyName(event.target.value);
    };
    // 郵便番号
    const [distinationZip1, setDistinationZip1] = useState("");
    const handleChangeDistinationZip1 = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setDistinationZip1(str);
    };
    const [distinationZip2, setDistinationZip2] = useState("");
    const handleChangeDistinationZip2 = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setDistinationZip2(str);
    };
    const getAddress = (event) => {
        event.preventDefault();
        if (distinationZip1 != "" && distinationZip2 != "") {
            let googleApiUrl = "https://maps.googleapis.com/maps/api/geocode/json";
            let postcodeParam = "?components=postal_code:" + distinationZip1 + "-" + distinationZip2 + "&language=ja";
            let apiKey = "&key=" + useJwt.getGoogleApiKey();
            let url = googleApiUrl + postcodeParam + apiKey;
            csloading(true);
            axios
                .get(url, "")
                .then((response1) => {
                    if (response1.data.status == "OK") {
                        let address_components = response1.data.results[0]['address_components'];
                        let code_len = address_components.length;
                        let address1 = "";
                        if (address_components[code_len - 2]['long_name']) {
                            address1 = address_components[code_len - 2]['long_name'];
                        }
                        let address2 = "";
                        if (address_components[code_len - 3]['long_name'] && address_components[code_len - 3]['types'][0] != "postal_code") {
                            address2 = address_components[code_len - 3]['long_name'];
                        }
                        // document.getElementById('distinationAddress1').value = address1;
                        // document.getElementById('distinationAddress2').value = address2;
                        setDistinationAddress1(address1);
                        setDistinationAddress2(address2);
                    } else { // ZERO_RESULTS
                        // document.getElementById('distinationAddress1').value = "";
                        // document.getElementById('distinationAddress2').value = "";
                        setDistinationAddress1("");
                        setDistinationAddress2("");
                    }
                    csloading(false);
                })
                .catch((error) => {
                    csloading(false);
                });
        }
    }
    // 住所（都道府県）
    const [publishId, setPublishId] = useState("");
    const handleChangePublishId = (event) => {
        setPublishId(event.target.value);
    };
    // 市区町村
    const [publishPass, setPublishPass] = useState("");
    const handleChangePublishPass = (event) => {
        event.preventDefault();
        setPublishPass(event.target.value);
    };
    // 住所（都道府県）
    const [distinationAddress1, setDistinationAddress1] = useState("");
    const handleChangeDistinationAddress1 = (event) => {
        setDistinationAddress1(event.target.value);
    };
    // 市区町村
    const [distinationAddress2, setDistinationAddress2] = useState("");
    const handleChangeDistinationAddress2 = (event) => {
        setDistinationAddress2(event.target.value);
    };
    // 番地
    const [distinationAddress3, setDistinationAddress3] = useState("");
    const handleChangeDistinationAddress3 = (event) => {
        setDistinationAddress3(event.target.value);
    };
    // 電話番号
    const [distinationTel1, setDistinationTel1] = useState("");
    const handleChangeDistinationTel1 = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setDistinationTel1(str);
    };
    const [distinationTel2, setDistinationTel2] = useState("");
    const handleChangeDistinationTel2 = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setDistinationTel2(str);
    };
    const [distinationTel3, setDistinationTel3] = useState("");
    const handleChangeDistinationTel3 = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setDistinationTel3(str);
    };
    // FAX番号
    const [distinationFax1, setDistinationFax1] = useState("");
    const handleChangeDistinationFax1 = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setDistinationFax1(str);
    };
    const [distinationFax2, setDistinationFax2] = useState("");
    const handleChangeDistinationFax2 = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setDistinationFax2(str);
    };
    const [distinationFax3, setDistinationFax3] = useState("");
    const handleChangeDistinationFax3 = (event) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        setDistinationFax3(str);
    };
    // 適用1
    const [summary1, setSummary1] = useState("");
    const handleChangeSummary1 = (event) => {
        setSummary1(event.target.value);
    };
    // 適用2
    const [summary2, setSummary2] = useState("");
    const handleChangeSummary2 = (event) => {
        setSummary2(event.target.value);
    };
    // 保有Nヶ月
    const [saveMonth, setSaveMonth] = useState("");
    const handleChangeSaveMonth = (event) => {
        let min = 0;
        let max = 13;
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str > max || str < min) {
            str = value;
            event.target.value = str;
        } else {
            if (str.length > 2) {
                str = str.substring(0, 2);
            }
        }
        if (!z1.test(str)) {
            str = "";
        }
        setSaveMonth(str);
    };
    // 入力Nヶ月
    const [inputMonth, setInputMonth] = useState("");
    const handleChangeInputMonth = (event) => {
        let min = 0;
        let max = 13;
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str > max || str < min) {
            str = value;
            event.target.value = str;
        } else {
            if (str.length > 2) {
                str = str.substring(0, 2);
            }
        }
        if (!z1.test(str)) {
            str = "";
        }
        setInputMonth(str);
    };
    // 歴対応区分
    const [calenderType, setCalenderType] = useState("0");
    const handleChangeCalenderType = (event) => {
        setCalenderType(event);
    };
    // 期首月日
    const [startDate, setStartDate] = useState("");
    const handleChangeStartDate = (event) => {
        setStartDate(event.target.value);
    };
    // 適用月
    const [operationMonth, setOperationMonth] = useState("");
    const handleChangeOperationMonth = (event) => {
        setOperationMonth(event.target.value);
    };
    // 年次履歴 - 作成予定月1
    const [historyScheduleMonth1, setistoryScheduleMonth1] = useState("");
    const handleChangeHistoryScheduleMonth1 = (event) => {
        let min = 0;
        let max = 12;
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str > max || str < min) {
            str = value;
            event.target.value = str;
        } else {
            if (str.length > 2) {
                str = str.substring(0, 2);
            }
        }
        if (!z1.test(str)) {
            str = "";
        }
        setistoryScheduleMonth1(str);
    };
    // 年次履歴 - 作成予定月2
    const [historyScheduleMonth2, setistoryScheduleMonth2] = useState("");
    const handleChangeHistoryScheduleMonth2 = (event) => {
        let min = 0;
        let max = 12;
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str > max || str < min) {
            str = value;
            event.target.value = str;
        } else {
            if (str.length > 2) {
                str = str.substring(0, 2);
            }
        }
        if (!z1.test(str)) {
            str = "";
        }
        setistoryScheduleMonth2(str);
    };
    // ロット名複写
    const [rotCopyFlag, setRotCopyFlag] = useState("");
    const handleChangeRotCopyFlag = (event) => {
        setRotCopyFlag(event);
    };
    // 重複入庫 設定
    const [multiArrivalSettingType, setMultiArrivalSettingType] = useState("0");
    const handleChangeMultiArrivalSettingType = (event) => {
        setMultiArrivalSettingType(event);
    };
    // 重複入庫 許可
    const [multiArrivalAgreeType, setMultiArrivalAgreeType] = useState("0");
    const handleChangeMultiArrivalAgreeType = (event) => {
        setMultiArrivalAgreeType(event);
    };
    // 在庫表示区分
    const [stockDisplayFlag, setStockDisplayFlag] = useState("0");
    const handleChangeStockDisplayFlag = (event) => {
        setStockDisplayFlag(event);
    };
    // 在庫無出庫 設定
    const [stockEmptySettingType, setStockEmptySettingType] = useState("0");
    const handleChangeStockEmptySettingType = (event) => {
        setStockEmptySettingType(event);
    };
    // 在庫無出庫 許可
    const [stockEmptyAgreeType, setStockEmptyAgreeType] = useState("0");
    const handleChangeStockEmptyAgreeType = (event) => {
        setStockEmptyAgreeType(event);
    };
    // 出庫諸掛使用
    const [leavingFreightageUseFlag, setLeavingFreightageUseFlag] = useState("0");
    const handleChangeLeavingFreightageUseFlag = (event) => {
        setLeavingFreightageUseFlag(event);
        let freightageCodeWrap = document.getElementById('freightageCodeWrap');
        if (event == 0) {
            freightageCodeWrap.style.display = "none";
        } else {
            freightageCodeWrap.style.display = "flex";
        }
    };
    // 諸掛①
    const [freightageCode1, setFreightageCode1] = useState("");
    const [freightageName1, setFreightageName1] = useState("");
    const handleChangeFreightageCode = (event, param) => {
        let z1 = /^[0-9]*$/;
        let str = event.target.value;
        if (str.length > 4) {
            str = str.substring(0, 4);
        }
        if (!z1.test(str)) {
            str = "";
        }
        if (param == 1) {
            setFreightageCode1(str);
        } else if (param == 2) {
            setFreightageCode2(str);
        } else {
            setFreightageCode3(str);
        }
    };
    const onKeyDownFreightageCode = (event, param) => {
        if (event.keyCode === 13) { // enter key
            if (param == 1) document.getElementById('freightageCode2').focus();
            if (param == 2) document.getElementById('freightageCode3').focus();
            if (param == 3) document.getElementById('input31').focus();
            getFreightageData(event, param);
        }
    };
    const getFreightageData = (event, param) => {
        if (event.target.value != "") {
            const data_r = {
                userID: userData['id'],
                freightage_code: event.target.value
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstFreightageEndpoint;
            axios
                .post(url, data_r, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response1) => {
                    let t_data = response1.data.data;
                    if (t_data.length > 0) {
                        if (param == 1) {
                            setFreightageName1(t_data[0]['freightage_name']);
                        } else if (param == 2) {
                            setFreightageName2(t_data[0]['freightage_name']);
                        } else {
                            setFreightageName3(t_data[0]['freightage_name']);
                        }
                    } else {
                        if (param == 1) {
                            setFreightageCode1("");
                            setFreightageName1("");
                        } else if (param == 2) {
                            setFreightageCode2("");
                            setFreightageName2("");
                        } else {
                            setFreightageCode3("");
                            setFreightageName3("");
                        }
                    }
                    event.preventDefault();
                })
                .catch((error) => {
                });
        } else {
            if (param == 1) {
                setFreightageName1("");
            } else if (param == 2) {
                setFreightageName2("");
            } else {
                setFreightageName3("");
            }
            event.preventDefault();
        }
    }
    // 諸掛②
    const [freightageCode2, setFreightageCode2] = useState("");
    const [freightageName2, setFreightageName2] = useState("");
    // 諸掛③
    const [freightageCode3, setFreightageCode3] = useState("");
    const [freightageName3, setFreightageName3] = useState("");
    // 入金処理 項目名1 ~ 項目名9
    const [depositItemName1, setDepositItemName1] = useState("");
    const handleChangeDepositItemName1 = (event) => {
        setDepositItemName1(event.target.value);
    };
    const [depositItemName2, setDepositItemName2] = useState("");
    const handleChangeDepositItemName2 = (event) => {
        setDepositItemName2(event.target.value);
    };
    const [depositItemName3, setDepositItemName3] = useState("");
    const handleChangeDepositItemName3 = (event) => {
        setDepositItemName3(event.target.value);
    };
    const [depositItemName4, setDepositItemName4] = useState("");
    const handleChangeDepositItemName4 = (event) => {
        setDepositItemName4(event.target.value);
    };
    const [depositItemName5, setDepositItemName5] = useState("");
    const handleChangeDepositItemName5 = (event) => {
        setDepositItemName5(event.target.value);
    };
    const [depositItemName6, setDepositItemName6] = useState("");
    const handleChangeDepositItemName6 = (event) => {
        setDepositItemName6(event.target.value);
    };
    const [depositItemName7, setDepositItemName7] = useState("");
    const handleChangeDepositItemName7 = (event) => {
        setDepositItemName7(event.target.value);
    };
    const [depositItemName8, setDepositItemName8] = useState("");
    const handleChangeDepositItemName8 = (event) => {
        setDepositItemName8(event.target.value);
    };
    const [depositItemName9, setDepositItemName9] = useState("");
    const handleChangeDepositItemName9 = (event) => {
        setDepositItemName9(event.target.value);
    };
    // 自社名印字
    const [companyNamePrintFlag, setCompanyNamePrintFlag] = useState("0");
    const handleChangeCompanyNamePrintFlag = (event) => {
        setCompanyNamePrintFlag(event);
    };
    // 伝票No管理
    const [orderNoManageType, setOrderNoManageType] = useState("0");
    const handleChangeOrderNoManageType = (event) => {
        setOrderNoManageType(event);
    };
    // パスワード
    const [password, setPassword] = useState("");
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const saveData = (event) => {
        let data = {};
        data['userID'] = userData['id'];
        data['sys_main_settings_id'] = sysMainSettingsId;                   // MainSetting ID
        if (companyName != "") {
            data['company_name'] = companyName;                             // 会社名
        } else {
            toast.error("会社名を入力してください。", { autoClose: 3000 });       // please enter company name
            setOpen(false);
            return;
        }
        if (distinationZip1 != "" && distinationZip2 != "") {
            data['distination_zip'] = distinationZip1 + "-" + distinationZip2;  //郵便番号
        } else {
            toast.error("郵便番号を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (distinationAddress1 != "") {
            data['distination_address_1'] = distinationAddress1;            // 住所（都道府県）
        } else {
            toast.error("住所を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (distinationAddress2 != "") {
            data['distination_address_2'] = distinationAddress2;            // 市区町村
        } else {
            toast.error("住所を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (distinationAddress3 != "") {
            data['distination_address_3'] = distinationAddress3;            // 番地
        }
        if (distinationTel1 != "" && distinationTel2 != "" && distinationTel3 != "") {
            data['distination_tel'] = distinationTel1 + "-" + distinationTel2 + "-" + distinationTel3;  //電話番号
        } else {
            toast.error("電話番号を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (distinationFax1 != "" && distinationFax2 != "" && distinationFax3 != "") {
            data['distination_fax'] = distinationFax1 + "-" + distinationFax2 + "-" + distinationFax3;  //FAX番号
        } else {
            toast.error("FAX番号を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (summary1 != "") {
            data['summary_1'] = summary1;               // 適用1
        }
        if (summary2 != "") {
            data['summary_2'] = summary2;               // 適用2
        }
        if (saveMonth != "") {
            data['save_month'] = saveMonth;             // 保有Nヶ月
        }
        if (inputMonth != "") {
            data['input_month'] = inputMonth;           // 入力Nヶ月
        }
        data['calender_type'] = calenderType;           // 歴対応区分
        if (startDate != "") {
            data['start_date'] = startDate;             // 期首月日
        } else {
            toast.error("期首月日を入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }
        if (operationMonth != "") {
            data['operation_month'] = operationMonth;   // 適用月
        }
        if (historyScheduleMonth1 != "") {
            data['history_schedule_month_1'] = historyScheduleMonth1;       // 年次履歴 - 作成予定月1
        }
        if (historyScheduleMonth2 != "") {
            data['history_schedule_month_2'] = historyScheduleMonth2;       // 年次履歴 - 作成予定月2
        }
        data['rot_copy_flag'] = rotCopyFlag;                                // ロット名複写
        data['multi_arrival_setting_type'] = multiArrivalSettingType;       // 重複入庫 設定
        data['multi_arrival_agree_type'] = multiArrivalAgreeType;           // 重複入庫 許可
        data['stock_display_flag'] = stockDisplayFlag;                      // 在庫表示区分
        data['stock_empty_setting_type'] = stockEmptySettingType;           // 在庫無出庫 設定
        data['stock_empty_agree_type'] = stockEmptyAgreeType;               // 在庫無出庫 許可
        data['leaving_freightage_use_flag'] = leavingFreightageUseFlag;     // 出庫諸掛使用
        if (leavingFreightageUseFlag == 1) {
            data['freightage_code_1'] = freightageCode1;        // 諸掛1
            data['freightage_code_2'] = freightageCode2;        // 諸掛2
            data['freightage_code_3'] = freightageCode3;        // 諸掛3
        } else {
            data['freightage_code_1'] = "";
            data['freightage_code_2'] = "";
            data['freightage_code_3'] = "";
        }
        if (depositItemName1 != "") {
            data['deposit_item_name_1'] = depositItemName1;     // 項目名1
        }
        if (depositItemName2 != "") {
            data['deposit_item_name_2'] = depositItemName2;     // 項目名2
        }
        if (depositItemName3 != "") {
            data['deposit_item_name_3'] = depositItemName3;     // 項目名3
        }
        if (depositItemName4 != "") {
            data['deposit_item_name_4'] = depositItemName4;     // 項目名4
        }
        if (depositItemName5 != "") {
            data['deposit_item_name_5'] = depositItemName5;     // 項目名5
        }
        if (depositItemName6 != "") {
            data['deposit_item_name_6'] = depositItemName6;     // 項目名6
        }
        if (depositItemName7 != "") {
            data['deposit_item_name_7'] = depositItemName7;     // 項目名7
        }
        if (depositItemName8 != "") {
            data['deposit_item_name_8'] = depositItemName8;     // 項目名8
        }
        if (depositItemName9 != "") {
            data['deposit_item_name_9'] = depositItemName9;     // 項目名9
        }
        if (companyNamePrintFlag != "") {
            data['company_name_print_flag'] = companyNamePrintFlag; // 自社名印字
        }
        if (orderNoManageType != "") {
            data['order_no_manage_type'] = orderNoManageType;   // 伝票No管理
        }
        if (password != "") {
            data['password'] = password;                        // パスワード
        } else {
            toast.error("パスワードを入力してください。", { autoClose: 3000 });
            setOpen(false);
            return;
        }

        let validate_flg = true;
        let vmsg = "";

        let locationData = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            if (mainTableData[i]['id'] != 0) {
                row['id'] = mainTableData[i]['id'];
            }

            if (mainTableData[i]['rot_unit'] != "") {
                row['rot_unit'] = mainTableData[i]['rot_unit'];
            } else {
                vmsg = "ロットの規格を入力してください。";
                validate_flg = false;
                break;
            }

            if (mainTableData[i]['limit_amount'] != "") {
                row['limit_amount'] = mainTableData[i]['limit_amount'];
            } else {
                vmsg = "制限数を入力してください。";
                validate_flg = false;
                break;
            }

            locationData[i] = row;
        }

        if (validate_flg) {
            data['rot_data'] = locationData;
        } else {
            toast.error(vmsg, { autoClose: 3000 });
            setOpen(false);
            return;
        }

        data['update_user_id'] = userData['id'];
        csloading(true);
        let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.setMainSettingEndpoint;
        axios
            .post(url, data, {
                headers: {
                    Authorization: "Bearer " + useJwt.getToken(),
                },
            })
            .then((response) => {
                if (response.status == 200) {
                    useJwt.setToken(response.data.token);
                    toast.success("登録致しました。", { autoClose: 3000 });
                }
                if (response.status == 300) {
                    useJwt.setToken(response.data.token);
                    toast.success("識別子が存在します。", { autoClose: 3000 });
                }
                csloading(false);
            })
            .catch((error) => {
                csloading(false);
                if (error.response && error.response.status && error.response.status === 401) {
                    useJwt.logout();
                    navigate("/login");
                } else {
                    toast.error("登録に失敗しました。", { autoClose: 3000 });
                }
            });
        setOpen(false);
        event.preventDefault();
    }

    // ========================== starts code for search ===============================
    const [searchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState([]);

    const setFreightageData = (data) => {
        let resultCode = data['freightage_code'];
        let resultName = data['freightage_name'];
        if (document.getElementById('selectedId').value == 'freightageCode1') {
            setFreightageCode1(resultCode);
            document.getElementById('freightageCode1').value = resultCode;
            setFreightageName1(resultName);
            document.getElementById('freightageName1').value = resultName;
        } else if (document.getElementById('selectedId').value == 'freightageCode2') {
            setFreightageCode2(resultCode);
            document.getElementById('freightageCode2').value = resultCode;
            setFreightageName2(resultName);
            document.getElementById('freightageName2').value = resultName;
        } else {
            setFreightageCode3(resultCode);
            document.getElementById('freightageCode3').value = resultCode;
            setFreightageName3(resultName);
            document.getElementById('freightageName3').value = resultName;
        }
    }
    const loadModalData = (event, param) => {
        event.preventDefault();
        let code = document.getElementById("freightageCode" + param).value;
        setSearchValue(code);
        document.getElementById('selectedId').value = "freightageCode" + param;

        if (code != "") {
            const data = {
                userID: userData['id'],
                freightage_code: code
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstFreightageEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setTableData(response.data.data);
                    setShowModal(true);
                })
                .catch((error) => {
                });
        } else {
            setTableData([]);
            setShowModal(true);
        }
    }
    // ========================== ends code for search ==================================

    const onSubmit = (data) => {
        // console.log(data);
    }

    useEffect(() => {
        if (authentication !== true)
            navigate("/login");
        if (!loaded) {
            csloading(true);
            if (menuPatternData['menu_start_setting_flag_1'] == 0 && userData['sys_role_id'] != 10000) {
                navigate("/");
            }
            const data = {
                userID: userData['id']
            };
            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMainSettingEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    setLoaded(true);
                    if (response.status == 200) {
                        useJwt.setToken(response.data.token);
                        // MainSetting ID
                        if (response.data.data.sys_main_settings_id != null)
                            setSysMainSettingsId(response.data.data.sys_main_settings_id);
                        // 会社名
                        if (response.data.data.company_name != null)
                            setCompanyName(response.data.data.company_name);
                        // // 会社ID
                        // if (response.data.data.company_id != null)
                        //     setPublishId(response.data.data.company_id);
                        // // 申請用パスワード
                        // if (response.data.data.company_password != null)
                        //     setPublishPass(response.data.data.company_password);
                        // 郵便番号
                        if (response.data.data.distination_zip != null) {
                            const postcode_ary = response.data.data.distination_zip.split("-");
                            if (postcode_ary[0]) {
                                setDistinationZip1(postcode_ary[0]);
                            }
                            if (postcode_ary[1]) {
                                setDistinationZip2(postcode_ary[1]);
                            }
                        }

                        // 住所（都道府県）, 市区町村, 番地
                        if (response.data.data.distination_address_1 != null)
                            setDistinationAddress1(response.data.data.distination_address_1);
                        if (response.data.data.distination_address_2 != null)
                            setDistinationAddress2(response.data.data.distination_address_2);
                        if (response.data.data.distination_address_3 != null)
                            setDistinationAddress3(response.data.data.distination_address_3);
                        // 電話番号, FAX番号
                        if (response.data.data.distination_tel != null) {
                            const phone_ary = response.data.data.distination_tel.split("-");
                            if (phone_ary[0]) {
                                setDistinationTel1(phone_ary[0]);
                            }
                            if (phone_ary[1]) {
                                setDistinationTel2(phone_ary[1]);
                            }
                            if (phone_ary[2]) {
                                setDistinationTel3(phone_ary[2]);
                            }
                        }
                        if (response.data.data.distination_fax != null) {
                            const fax_ary = response.data.data.distination_fax.split("-");
                            if (fax_ary[0]) {
                                setDistinationFax1(fax_ary[0]);
                            }
                            if (fax_ary[1]) {
                                setDistinationFax2(fax_ary[1]);
                            }
                            if (fax_ary[2]) {
                                setDistinationFax3(fax_ary[2]);
                            }
                        }
                        // 適用1 , 適用2
                        if (response.data.data.summary_1 != null)
                            setSummary1(response.data.data.summary_1);
                        if (response.data.data.summary_2 != null)
                            setSummary2(response.data.data.summary_2);
                        // 保有Nヶ月, 入力Nヶ月
                        if (response.data.data.save_month != null)
                            setSaveMonth(response.data.data.save_month);
                        if (response.data.data.input_month != null)
                            setInputMonth(response.data.data.input_month);
                        // 歴対応区分
                        if (response.data.data.calender_type != null)
                            setCalenderType(response.data.data.calender_type);
                        // 期首月日, 適用月
                        if (response.data.data.start_date != null)
                            setStartDate(response.data.data.start_date);
                        if (response.data.data.operation_month != null)
                            setOperationMonth(response.data.data.operation_month);
                        // 年次履歴 - 作成予定月1, 作成予定月2
                        if (response.data.data.history_schedule_month_1 != null)
                            setistoryScheduleMonth1(response.data.data.history_schedule_month_1);
                        if (response.data.data.history_schedule_month_2 != null)
                            setistoryScheduleMonth2(response.data.data.history_schedule_month_2);
                        // ロット名複写, 重複入庫 設定, 重複入庫 許可
                        if (response.data.data.rot_copy_flag != null)
                            setRotCopyFlag(response.data.data.rot_copy_flag);
                        if (response.data.data.multi_arrival_setting_type != null)
                            setMultiArrivalSettingType(response.data.data.multi_arrival_setting_type);
                        if (response.data.data.multi_arrival_agree_type != null)
                            setMultiArrivalAgreeType(response.data.data.multi_arrival_agree_type);
                        // 在庫表示区分, 在庫無出庫 設定, 在庫無出庫 許可
                        if (response.data.data.stock_display_flag != null)
                            setStockDisplayFlag(response.data.data.stock_display_flag);
                        if (response.data.data.stock_empty_setting_type != null)
                            setStockEmptySettingType(response.data.data.stock_empty_setting_type);
                        if (response.data.data.stock_empty_agree_type != null)
                            setStockEmptyAgreeType(response.data.data.stock_empty_agree_type);
                        // 出庫諸掛使用
                        if (response.data.data.leaving_freightage_use_flag != null) {
                            setLeavingFreightageUseFlag(response.data.data.leaving_freightage_use_flag);
                            if (response.data.data.leaving_freightage_use_flag == 0) {
                                let freightageCodeWrap = document.getElementById('freightageCodeWrap');
                                freightageCodeWrap.style.display = "none";
                            } else {
                                // 諸掛1 ~ 諸掛3
                                if (response.data.data.freightage_code_1 != null) {
                                    setFreightageCode1(response.data.data.freightage_code_1);
                                    const data_r = {
                                        userID: userData['id'],
                                        freightage_code: response.data.data.freightage_code_1
                                    };
                                    let url2 = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstFreightageEndpoint;
                                    axios
                                        .post(url2, data_r, {
                                            headers: {
                                                Authorization: "Bearer " + useJwt.getToken(),
                                            },
                                        })
                                        .then((response1) => {
                                            let t_data = response1.data.data;
                                            if (t_data.length > 0) {
                                                setFreightageName1(t_data[0]['freightage_name']);
                                            }
                                        })
                                        .catch((error) => {
                                        });
                                }

                                if (response.data.data.freightage_code_2 != null) {
                                    setFreightageCode2(response.data.data.freightage_code_2);
                                    const data_r = {
                                        userID: userData['id'],
                                        freightage_code: response.data.data.freightage_code_2
                                    };
                                    let url2 = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstFreightageEndpoint;
                                    axios
                                        .post(url2, data_r, {
                                            headers: {
                                                Authorization: "Bearer " + useJwt.getToken(),
                                            },
                                        })
                                        .then((response1) => {
                                            let t_data = response1.data.data;
                                            if (t_data.length > 0) {
                                                setFreightageName2(t_data[0]['freightage_name']);
                                            }
                                        })
                                        .catch((error) => {
                                        });
                                }

                                if (response.data.data.freightage_code_3 != null) {
                                    setFreightageCode3(response.data.data.freightage_code_3);
                                    const data_r = {
                                        userID: userData['id'],
                                        freightage_code: response.data.data.freightage_code_3
                                    };
                                    let url2 = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstFreightageEndpoint;
                                    axios
                                        .post(url2, data_r, {
                                            headers: {
                                                Authorization: "Bearer " + useJwt.getToken(),
                                            },
                                        })
                                        .then((response1) => {
                                            let t_data = response1.data.data;
                                            if (t_data.length > 0) {
                                                setFreightageName3(t_data[0]['freightage_name']);
                                            }
                                        })
                                        .catch((error) => {
                                        });
                                }

                            }
                        }
                        // 入金処理 項目名1 ~ 項目名9 
                        if (response.data.data.deposit_item_name_1 != null)
                            setDepositItemName1(response.data.data.deposit_item_name_1);
                        if (response.data.data.deposit_item_name_2 != null)
                            setDepositItemName2(response.data.data.deposit_item_name_2);
                        if (response.data.data.deposit_item_name_3 != null)
                            setDepositItemName3(response.data.data.deposit_item_name_3);
                        if (response.data.data.deposit_item_name_4 != null)
                            setDepositItemName4(response.data.data.deposit_item_name_4);
                        if (response.data.data.deposit_item_name_5 != null)
                            setDepositItemName5(response.data.data.deposit_item_name_5);
                        if (response.data.data.deposit_item_name_6 != null)
                            setDepositItemName6(response.data.data.deposit_item_name_6);
                        if (response.data.data.deposit_item_name_7 != null)
                            setDepositItemName7(response.data.data.deposit_item_name_7);
                        if (response.data.data.deposit_item_name_8 != null)
                            setDepositItemName8(response.data.data.deposit_item_name_8);
                        if (response.data.data.deposit_item_name_9 != null)
                            setDepositItemName9(response.data.data.deposit_item_name_9);
                        // 自社名印字, 伝票No管理
                        if (response.data.data.company_name_print_flag != null)
                            setCompanyNamePrintFlag(response.data.data.company_name_print_flag);
                        if (response.data.data.order_no_manage_type != null)
                            setOrderNoManageType(response.data.data.order_no_manage_type);
                        // パスワード 
                        if (response.data.data.password != null)
                            setPassword(response.data.data.password);
                    }
                    csloading(false);
                })
                .catch((error) => {
                    csloading(false);
                    if (error.response && error.response.status && error.response.status === 401) {
                        useJwt.logout();
                        navigate("/login");
                    }
                });

            const rotData = {
                userID: userData['id']
            };
            url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.getMstRotStandardEndpoint;
            axios
                .post(url, rotData, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    let t_data = response.data.data;
                    if (t_data.length > 0) {
                        setMainTableData(t_data);
                    }
                })
                .catch((error) => {
                    csloading(false);
                    if (error.response && error.response.status && error.response.status === 401) {
                        useJwt.logout();
                        navigate("/login");
                    }
                });
        }
    }, [props]);

    const rotUnitNew = () => {
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            data[i] = row;
        }
        let row = {};
        row['id'] = 0;
        row['rot_unit'] = "";
        row['limit_amount'] = "";
        data[i] = row;

        setMainTableData(data);
    }

    const deleteData = (event) => {
        event.preventDefault();
        setShowModalDel(false);
        let delIds = [];
        let remainIndexs = [];
        var checkboxes = document.querySelectorAll('#tbodyWrap input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                let id = document.getElementById('id_' + i).value;
                if (id > 0) {
                    delIds.push(id);
                }
            } else {
                remainIndexs.push(i);
            }
        }

        if (delIds.length > 0) {
            let data = {};
            data['userID'] = userData['id'];
            data['delIds'] = delIds;
            csloading(true);

            let url = jwtDefaultConfig.backendUrl + jwtDefaultConfig.delMstRotStandardEndpoint;
            axios
                .post(url, data, {
                    headers: {
                        Authorization: "Bearer " + useJwt.getToken(),
                    },
                })
                .then((response) => {
                    csloading(false);

                    if (response.status == 200) {
                        console.log(response.data.data);
                        setMainTableData(response.data.data);
                        $('#tbodyWrap input[type="checkbox"]').prop('checked', false);
                        $('#allCheckBtn').prop('checked', false);
                    }
                })
                .catch((error) => {
                    csloading(false);
                    $('#allCheckBtn').prop('checked', false);
                });
        } else {
            let remainData = [];
            for (var i = 0; i < mainTableData.length; i++) {
                for (var j = 0; j < remainIndexs.length; j++) {
                    if (i == remainIndexs[j]) {
                        let row = {};
                        row = mainTableData[i];
                        remainData.push(row);
                    }
                }
            }
            setMainTableData(remainData);
            $('#tbodyWrap input[type="checkbox"]').prop('checked', false);
            $('#allCheckBtn').prop('checked', false);
        }
    }

    const rotUnitDel = (event) => {
        event.preventDefault();
        setShowModalDel(true);
    }


    const allCheckData = (event) => {
        $('#tbodyWrap input[type="checkbox"]').prop('checked', event.target.checked);
    }

    const onChangeRotUnit = (event, index) => {
        let value = event.target.value;

        if (value.length > 8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row['rot_unit'] = value;
            }
            data[i] = row;
        }
        setMainTableData(data);
    }
    const isNumber = (n) => {
        return !isNaN(n - 0);
    }
    const onChangeLimitAmount = (event, index) => {
        let value = event.target.value;

        if (!isNumber(value)) return;

        if (value.length > 8) {
            value = value.substring(0, 8);
        }
        let data = [];
        for (var i = 0; i < mainTableData.length; i++) {
            let row = {};
            row = mainTableData[i];
            if (i == index) {
                row['limit_amount'] = value;
            }
            data[i] = row;
        }
        setMainTableData(data);
    }


    const [type1Open, setType1Open] = useState(false);
    const [type2Open, setType2Open] = useState(false);
    const [type3Open, setType3Open] = useState(false);
    const [type4Open, setType4Open] = useState(false);
    const [type5Open, setType5Open] = useState(false);
    const [type6Open, setType6Open] = useState(false);
    const [type7Open, setType7Open] = useState(false);
    const [type8Open, setType8Open] = useState(false);
    const [type9Open, setType9Open] = useState(false);
    const [type10Open, setType10Open] = useState(false);

    const onFocusType1 = (e, type) => {
        if (type == 1) {
            setType1Open(true);
        } else {
            setType1Open(false);
        }
    }

    const onFocusType2 = (e, type) => {
        if (type == 1) {
            setType2Open(true);
        } else {
            setType2Open(false);
        }
    }

    const onFocusType3 = (e, type) => {
        if (type == 1) {
            setType3Open(true);
        } else {
            setType3Open(false);
        }
    }

    const onFocusType4 = (e, type) => {
        if (type == 1) {
            setType4Open(true);
        } else {
            setType4Open(false);
        }
    }

    const onFocusType5 = (e, type) => {
        if (type == 1) {
            setType5Open(true);
        } else {
            setType5Open(false);
        }
    }

    const onFocusType6 = (e, type) => {
        if (type == 1) {
            setType6Open(true);
        } else {
            setType6Open(false);
        }
    }

    const onFocusType7 = (e, type) => {
        if (type == 1) {
            setType7Open(true);
        } else {
            setType7Open(false);
        }
    }

    const onFocusType8 = (e, type) => {
        if (type == 1) {
            setType8Open(true);
        } else {
            setType8Open(false);
        }
    }

    const onFocusType9 = (e, type) => {
        if (type == 1) {
            setType9Open(true);
        } else {
            setType9Open(false);
        }
    }

    const onFocusType10 = (e, type) => {
        if (type == 1) {
            setType10Open(true);
        } else {
            setType10Open(false);
        }
    }

    const onDataKeyDown = (e, param) => {
        if (e.keyCode == 13) {
            switch (param) {
                case 1:
                    document.getElementById('input1').focus();
                    break;
                case 2:
                    document.getElementById('input2').focus();
                    break;
                case 3:
                    document.getElementById('input3').focus();
                    break;
                case 4:
                    document.getElementById('input4').focus();
                    break;
                case 5:
                    document.getElementById('input5').focus();
                    break;
                case 6:
                    document.getElementById('input6').focus();
                    break;
                case 7:
                    document.getElementById('input7').focus();
                    break;
                case 8:
                    document.getElementById('input8').focus();
                    break;
                case 9:
                    document.getElementById('input9').focus();
                    break;
                case 10:
                    document.getElementById('input10').focus();
                    break;
                case 11:
                    document.getElementById('input11').focus();
                    break;
                case 12:
                    document.getElementById('input12').focus();
                    break;
                case 13:
                    document.getElementById('input13').focus();
                    break;
                case 14:
                    document.getElementById('input14').focus();
                    break;
                case 15:
                    document.getElementById('input15').focus();
                    break;
                case 16:
                    document.getElementById('input16').focus();
                    break;
                case 17:
                    document.getElementById('input17').focus();
                    break;
                case 18:
                    document.getElementById('input18').focus();
                    break;
                case 19:
                    document.getElementById('input19').focus();
                    break;
                case 20:
                    document.getElementById('input20').focus();
                    break;
                case 21:
                    document.getElementById('input21').focus();
                    break;
                case 22:
                    document.getElementById('input22').focus();
                    break;
                case 23:
                    document.getElementById('input23').focus();
                    break;
                case 24:
                    document.getElementById('input24').focus();
                    break;
                case 25:
                    document.getElementById('input25').focus();
                    break;
                case 26:
                    document.getElementById('input26').focus();
                    break;
                case 27:
                    document.getElementById('input27').focus();
                    break;
                case 28:
                    document.getElementById('freightageCode1').focus();
                    break;
                case 29:
                    document.getElementById('input29').focus();
                    break;
                case 30:
                    document.getElementById('input30').focus();
                    break;
                case 31:
                    document.getElementById('input31').focus();
                    break;
                case 32:
                    document.getElementById('input32').focus();
                    break;
                case 33:
                    document.getElementById('input33').focus();
                    break;
                case 34:
                    document.getElementById('input34').focus();
                    break;
                case 35:
                    document.getElementById('input35').focus();
                    break;
                case 36:
                    document.getElementById('input36').focus();
                    break;
                case 37:
                    document.getElementById('input37').focus();
                    break;
                case 38:
                    document.getElementById('input38').focus();
                    break;
                case 39:
                    document.getElementById('input39').focus();
                    break;
                case 40:
                    document.getElementById('input40').focus();
                    break;
                case 41:
                    document.getElementById('input41').focus();
                    break;
                case 42:
                    document.getElementById('input42').focus();
                    break;
                case 43:
                    document.getElementById('input43').focus();
                    break;
                case 44:
                    document.getElementById('input44').focus();
                    break;
                case 45:
                    document.getElementById('input45').focus();
                    break;
                case 46:
                    document.getElementById('input46').focus();
                    break;
                case 47:
                    document.getElementById('input47').focus();
                    break;
                case 48:
                    document.getElementById('registerBut').focus();
                    break;
                case 49:
                    document.getElementById('input49').focus();
                    break;
                // case 333:
                //     document.getElementById('input333').focus();
                //     break;
                // case 444:
                //     document.getElementById('input444').focus();
                //     break;
                default:
            }
        }
    }


    const tableKeyDown = (e, id) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            document.getElementById(id).focus();
        }
    }

    const onSelectData = (e, index) => {

        switch (index) {
            case 1:
                setType1Open(false);
                document.getElementById('focus1').focus();
                break;
            case 2:
                setType2Open(false);
                document.getElementById('focus2').focus();
                break;
            case 3:
                setType3Open(false);
                document.getElementById('focus2').focus();
                break;
            case 4:
                setType4Open(false);
                document.getElementById('focus2').focus();
                break;
            case 5:
                setType5Open(false);
                document.getElementById('focus3').focus();
                break;
            case 6:
                setType6Open(false);
                document.getElementById('focus3').focus();
                break;
            case 7:
                setType7Open(false);
                document.getElementById('focus3').focus();
                break;
            case 8:
                setType8Open(false);
                document.getElementById('focus3').focus();
                break;
            case 9:
                setType9Open(false);
                document.getElementById('registerBut').focus();
                break;
            case 10:
                setType10Open(false);
                document.getElementById('registerBut').focus();
                break;
            default:
                break;
        }
    }

    // style array
    const arr_style_1 = [style.distination_address_wrap, gstyle.w20];
    const arr_style_2 = [style.distination_address_wrap, gstyle.w30];
    const arr_style_3 = [gstyle.cs_flex];
    const arr_style_4 = [style.distination_address_wrap, gstyle.w30, style.cs_ml_100];
    const arr_style_5 = [gstyle.cs_label, gstyle.cs_flex_2];
    const arr_style_6 = [style.distination_address_wrap, gstyle.w22, style.mr_18];
    const arr_style_7 = [gstyle.cs_input, gstyle.w30];

    // style array

    const style_item_tbl = [style.cs_tbl_2, style.item_tbl];
    const style_th_col_2 = [style.cs_th, gstyle.cs_col_2];
    const style_th_col_ck = [style.cs_th, gstyle.cs_col_1p];
    const style_td_col_2 = [style.cs_td, gstyle.cs_col_2];
    const style_td_col_ck = [style.cs_td, gstyle.cs_col_1p];


    return (
        <div className={gstyle.display}>
            <div className={gstyle.loader_container} id="csloader">
                <ClipLoader color={'#fff'} size={60} />
            </div>
            <ToastContainer />
            <FreightageModal show={showModal} onClose={() => setShowModal(false)} width={'70%'}
                SearchValue={searchValue} onChange={setSearchValue}
                tableData={tableData} setTableData={setTableData}
                target={setFreightageData} />
            <Modal2 title="ロットの規格削除" show={showModalDel} onClose={() => setShowModalDel(false)} width={'40%'}
                onConfirm={deleteData}>
                <p>選択したデータを削除しますか？</p>
            </Modal2>
            <input type={'hidden'} id="selectedId" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={gstyle.border}>
                    <p className={gstyle.text}>基本事項メンテナンス</p>
                </div>
                <div className={gstyle.filed}>
                    <div className={gstyle.cs_card_1}>
                        {/*会社名*/}
                        <div className={gstyle.hyfiled}>
                            <div className={style.company_wrap}>
                                <label className={gstyle.cs_label}>会社名
                                    <input type={'text'} className={gstyle.cs_input} {...register('company_name')}
                                        value={companyName} autoFocus
                                        id='input1' onKeyDown={(e) => onDataKeyDown(e, 2)}
                                        onChange={handleChangeCompanyName} />
                                </label>
                            </div>
                        </div>
                        {/*郵便番号*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>郵便番号
                                    <div className={arr_style_3.join(" ")}>
                                        <input type={'number'} className={gstyle.cs_input}
                                            value={distinationZip1}
                                            id='input2' onKeyDown={(e) => onDataKeyDown(e, 49)}
                                            onChange={handleChangeDistinationZip1} />
                                        <span className={style.cs_min_span}>-</span>
                                        <input type={'number'} className={gstyle.cs_input}
                                            id='input49' onKeyDown={(e) => onDataKeyDown(e, 3)}
                                            value={distinationZip2}
                                            onChange={handleChangeDistinationZip2} />
                                    </div>
                                </label>
                            </div>
                            <div className={style.distination_zip_btn_wrap}>
                                <button type='button' className={style.distination_zip_btn} onClick={(e) => getAddress(e)} >郵便検索</button>
                            </div>
                        </div>
                        {/*住所（都道府県）, 市区町村, 番地*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>住所（都道府県）
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={distinationAddress1}
                                        id='input3' onKeyDown={(e) => onDataKeyDown(e, 4)}
                                        onChange={handleChangeDistinationAddress1} />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>市区町村
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={distinationAddress2}
                                        id='input4' onKeyDown={(e) => onDataKeyDown(e, 5)}
                                        onChange={handleChangeDistinationAddress2} />
                                </label>
                            </div>
                            <div className={arr_style_2.join(" ")}>
                                <label className={gstyle.cs_label}>番地
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={distinationAddress3}
                                        id='input5' onKeyDown={(e) => onDataKeyDown(e, 6)}
                                        onChange={handleChangeDistinationAddress3} />
                                </label>
                            </div>
                        </div>
                        {/*電話番号, FAX番号*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_2.join(" ")}>
                                <label className={gstyle.cs_label}>電話番号
                                    <div className={arr_style_3.join(" ")}>
                                        <input type={'number'} className={gstyle.cs_input}
                                            value={distinationTel1}
                                            id='input6' onKeyDown={(e) => onDataKeyDown(e, 7)}
                                            onChange={handleChangeDistinationTel1} />
                                        <span className={style.cs_min_span}>-</span>
                                        <input type={'number'} className={gstyle.cs_input}
                                            value={distinationTel2}
                                            id='input7' onKeyDown={(e) => onDataKeyDown(e, 8)}
                                            onChange={handleChangeDistinationTel2} />
                                        <span className={style.cs_min_span}>-</span>
                                        <input type={'number'} className={gstyle.cs_input}
                                            value={distinationTel3}
                                            id='input8' onKeyDown={(e) => onDataKeyDown(e, 9)}
                                            onChange={handleChangeDistinationTel3} />
                                    </div>
                                </label>
                            </div>
                            <div className={arr_style_4.join(" ")}>
                                <label className={gstyle.cs_label}>FAX番号
                                    <div className={arr_style_3.join(" ")}>
                                        <input type={'number'} className={gstyle.cs_input}
                                            value={distinationFax1}
                                            id='input9' onKeyDown={(e) => onDataKeyDown(e, 10)}
                                            onChange={handleChangeDistinationFax1} />
                                        <span className={style.cs_min_span}>-</span>
                                        <input type={'number'} className={gstyle.cs_input}
                                            value={distinationFax2}
                                            id='input10' onKeyDown={(e) => onDataKeyDown(e, 11)}
                                            onChange={handleChangeDistinationFax2} />
                                        <span className={style.cs_min_span}>-</span>
                                        <input type={'number'} className={gstyle.cs_input}
                                            value={distinationFax3}
                                            id='input11' onKeyDown={(e) => onDataKeyDown(e, 12)}
                                            onChange={handleChangeDistinationFax3} />
                                    </div>
                                </label>
                            </div>
                        </div>
                        {/*適用1, 適用2*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_2.join(" ")}>
                                <label className={gstyle.cs_label}>適用1
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={summary1}
                                        id='input12' onKeyDown={(e) => onDataKeyDown(e, 13)}
                                        onChange={handleChangeSummary1} />
                                </label>
                            </div>
                            <div className={arr_style_4.join(" ")}>
                                <label className={gstyle.cs_label}>適用2
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={summary2}
                                        id='input13' onKeyDown={(e) => onDataKeyDown(e, 14)}
                                        onChange={handleChangeSummary2} />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={gstyle.cs_card_1}>
                        {/*保有Nヶ月, 入力Nヶ月*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>保有Nヶ月
                                    <input type={'number'} className={gstyle.cs_input}
                                        value={saveMonth}
                                        id='input14' onKeyDown={(e) => onDataKeyDown(e, 15)}
                                        onChange={handleChangeSaveMonth} />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>入力Nヶ月
                                    <input type={'number'} className={gstyle.cs_input}
                                        value={inputMonth}
                                        id='input15' onKeyDown={(e) => onDataKeyDown(e, 16)}
                                        onChange={handleChangeInputMonth} />
                                </label>
                            </div>
                        </div>
                        {/*歴対応区分*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_6.join(" ")}>
                                <label className={gstyle.cs_label}>歴対応区分
                                    <Select
                                        open={type1Open}
                                        onSelect={(e) => onSelectData(e, 1)}
                                        placeholder="歴対応区分を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeCalenderType}
                                        onKeyDown={(e) => onDataKeyDown(e, 17)}
                                        onFocus={(e) => onFocusType1(e, 1)}
                                        onBlur={(e) => onFocusType1(e, 2)}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop: 14, width: '100%' }}
                                        id="input16"
                                        options={[
                                            { value: "0", label: '西暦' },
                                            { value: "1", label: '和暦' },
                                        ]}
                                    />
                                </label>
                            </div>
                            <input type='text' style={{ width: 0, outline: 'white', border: 'none' }} id="focus1" />
                        </div>
                        {/*期首月日, 適用月*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>期首月日
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={startDate}
                                        id='input17' onKeyDown={(e) => onDataKeyDown(e, 18)}
                                        onChange={handleChangeStartDate} />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>適用月
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={operationMonth}
                                        id='input18' onKeyDown={(e) => onDataKeyDown(e, 19)}
                                        onChange={handleChangeOperationMonth} />
                                </label>
                            </div>
                        </div>
                        {/*年次履歴-作成予定月1, 作成予定月2*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>年次履歴 - 作成予定月①
                                    <input type={'number'} className={gstyle.cs_input}
                                        value={historyScheduleMonth1}
                                        id='input19' onKeyDown={(e) => onDataKeyDown(e, 20)}
                                        onChange={handleChangeHistoryScheduleMonth1} />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>年次履歴 - 作成予定月②
                                    <input type={'number'} className={gstyle.cs_input}
                                        value={historyScheduleMonth2}
                                        id='input20' onKeyDown={(e) => onDataKeyDown(e, 21)}
                                        onChange={handleChangeHistoryScheduleMonth2} />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={gstyle.cs_card_1}>
                        {/*【入庫処理】*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={style.cs_blue_label}>【入庫処理】
                                </label>
                            </div>
                        </div>
                        {/*ロット名複写, 重複入庫 設定, 重複入庫 許可*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_6.join(" ")}>
                                <label className={arr_style_5.join(" ")}>
                                    <span className={style.mt_30}>ロット名複写</span>
                                    <Select
                                        open={type2Open}
                                        onSelect={(e) => onSelectData(e, 2)}
                                        value={rotCopyFlag}
                                        placeholder="ロット名複写を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeRotCopyFlag}
                                        onKeyDown={(e) => onDataKeyDown(e, 22)}
                                        onFocus={(e) => onFocusType2(e, 1)}
                                        onBlur={(e) => onFocusType2(e, 2)}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop: 14, width: '100%' }}
                                        id="input21"
                                        options={[
                                            { value: '0', label: 'しない' },
                                            { value: '1', label: 'する' },
                                        ]}
                                    />
                                </label>
                            </div>
                            <div className={arr_style_6.join(" ")}>
                                <label className={arr_style_5.join(" ")}>
                                    <span className={style.cs_span_1}>重複入庫</span>
                                    <span className={style.mt_12}>設定</span>
                                    <Select
                                        open={type3Open}
                                        onSelect={(e) => onSelectData(e, 3)}
                                        value={multiArrivalSettingType}
                                        placeholder="設定を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeMultiArrivalSettingType}
                                        onKeyDown={(e) => onDataKeyDown(e, 23)}
                                        onFocus={(e) => onFocusType3(e, 1)}
                                        onBlur={(e) => onFocusType3(e, 2)}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop: 14, width: '100%' }}
                                        id="input22"
                                        options={[
                                            { value: '0', label: '荷主別' },
                                            { value: '1', label: '一括' },
                                        ]}
                                    />
                                </label>
                            </div>
                            <div className={arr_style_6.join(" ")}>
                                <label className={arr_style_5.join(" ")}>
                                    <span className={style.mt_30}>許可</span>
                                    <Select
                                        value={multiArrivalAgreeType}
                                        open={type4Open}
                                        onSelect={(e) => onSelectData(e, 4)}
                                        placeholder="許可を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeMultiArrivalAgreeType}
                                        onKeyDown={(e) => onDataKeyDown(e, 24)}
                                        onFocus={(e) => onFocusType4(e, 1)}
                                        onBlur={(e) => onFocusType4(e, 2)}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop: 14, width: '100%' }}
                                        id="input23"
                                        options={[
                                            { value: '0', label: '許可' },
                                            { value: '1', label: '警告' },
                                            { value: '2', label: '禁止' },
                                        ]}
                                    />
                                </label>
                                <input type='text' style={{ width: 0, outline: 'white', border: 'none' }} id="focus2" />
                            </div>
                        </div>
                    </div>
                    <div className={gstyle.cs_card_1}>
                        {/*【出庫処理・在庫】*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={style.cs_blue_label}>【出庫処理・在庫】
                                </label>
                            </div>
                        </div>
                        {/*在庫表示区分, 在庫無出庫 設定, 在庫無出庫 許可*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_6.join(" ")}>
                                <label className={arr_style_5.join(" ")}>
                                    <span className={style.mt_30}>在庫表示区分</span>
                                    <Select
                                        value={stockDisplayFlag}
                                        open={type5Open}
                                        onSelect={(e) => onSelectData(e, 5)}
                                        placeholder="在庫表示区分を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeStockDisplayFlag}
                                        onKeyDown={(e) => onDataKeyDown(e, 25)}
                                        onFocus={(e) => onFocusType5(e, 1)}
                                        onBlur={(e) => onFocusType5(e, 2)}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop: 14, width: '100%' }}
                                        id="input24"
                                        options={[
                                            { value: '0', label: '表示' },
                                            { value: '1', label: '非表示' },
                                        ]}
                                    />
                                </label>
                            </div>
                            <div className={arr_style_6.join(" ")}>
                                <label className={arr_style_5.join(" ")}>
                                    <span className={style.cs_span_1}>在庫無出庫</span>
                                    <span className={style.mt_12}>設定</span>
                                    <Select
                                        value={stockEmptySettingType}
                                        open={type6Open}
                                        onSelect={(e) => onSelectData(e, 6)}
                                        placeholder="設定を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeStockEmptySettingType}
                                        onKeyDown={(e) => onDataKeyDown(e, 26)}
                                        onFocus={(e) => onFocusType6(e, 1)}
                                        onBlur={(e) => onFocusType6(e, 2)}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop: 14, width: '100%' }}
                                        id="input25"
                                        options={[
                                            { value: '0', label: '荷主別' },
                                            { value: '1', label: '一括' },
                                        ]}
                                    />
                                </label>
                            </div>
                            <div className={arr_style_6.join(" ")}>
                                <label className={arr_style_5.join(" ")}>
                                    <span className={style.mt_30}>許可</span>
                                    <Select
                                        value={stockEmptyAgreeType}
                                        open={type7Open}
                                        onSelect={(e) => onSelectData(e, 7)}
                                        placeholder="許可を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeStockEmptyAgreeType}
                                        onKeyDown={(e) => onDataKeyDown(e, 27)}
                                        onFocus={(e) => onFocusType7(e, 1)}
                                        onBlur={(e) => onFocusType7(e, 2)}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop: 14, width: '100%' }}
                                        id="input26"
                                        options={[
                                            { value: '0', label: '許可' },
                                            { value: '1', label: '警告' },
                                            { value: '2', label: '禁止' },
                                        ]}
                                    />
                                </label>
                            </div>
                        </div>
                        {/*出庫諸掛使用*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_6.join(" ")}>
                                <label className={gstyle.cs_label}>出庫諸掛使用
                                    <Select
                                        value={leavingFreightageUseFlag}
                                        open={type8Open}
                                        onSelect={(e) => onSelectData(e, 8)}
                                        placeholder="出庫諸掛使用を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeLeavingFreightageUseFlag}
                                        onKeyDown={(e) => onDataKeyDown(e, 28)}
                                        onFocus={(e) => onFocusType8(e, 1)}
                                        onBlur={(e) => onFocusType8(e, 2)}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop: 14, width: '100%' }}
                                        id="input27"
                                        options={[
                                            { value: '0', label: '未使用' },
                                            { value: '1', label: '使用' },
                                        ]}
                                    />
                                </label>
                            </div>
                            <input type='text' style={{ width: 0, outline: 'white', border: 'none' }} id="focus3" />
                        </div>
                        {/* 諸掛1 ~ 諸掛3 */}
                        <div className={gstyle.hyfiled} id={'freightageCodeWrap'}>
                            <div className={arr_style_2.join(" ")}>
                                <label className={gstyle.cs_label}>諸掛①
                                    <div className={arr_style_3.join(" ")}>
                                        <input type={'number'} className={arr_style_7.join(" ")} id="freightageCode1"
                                            value={freightageCode1}
                                            onKeyDown={(e) => onKeyDownFreightageCode(e, 1)}
                                            onChange={(e) => handleChangeFreightageCode(e, 1)} />
                                        <input type={'text'} className={gstyle.cs_input} disabled id="freightageName1"
                                            value={freightageName1} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadModalData(e, 1)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className={arr_style_2.join(" ")}>
                                <label className={gstyle.cs_label}>諸掛②
                                    <div className={arr_style_3.join(" ")}>
                                        <input type={'number'} className={arr_style_7.join(" ")} id="freightageCode2"
                                            value={freightageCode2}
                                            onKeyDown={(e) => onKeyDownFreightageCode(e, 2)}
                                            onChange={(e) => handleChangeFreightageCode(e, 2)} />
                                        <input type={'text'} className={gstyle.cs_input} disabled id="freightageName2"
                                            value={freightageName2} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadModalData(e, 2)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className={arr_style_2.join(" ")}>
                                <label className={gstyle.cs_label}>諸掛③
                                    <div className={arr_style_3.join(" ")}>
                                        <input type={'number'} className={arr_style_7.join(" ")} id="freightageCode3"
                                            value={freightageCode3}
                                            onKeyDown={(e) => onKeyDownFreightageCode(e, 3)}
                                            onChange={(e) => handleChangeFreightageCode(e, 3)} />
                                        <input type={'text'} className={gstyle.cs_input} disabled id="freightageName3"
                                            value={freightageName3} />
                                        <div className={gstyle.icon_search_wrap} onClick={(e) => loadModalData(e, 3)}>
                                            <img src={IconSearch} className={gstyle.icon_search} alt="icon" />
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={gstyle.cs_card_1}>
                        {/*【入金処理】*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={style.cs_blue_label}>【入金処理】
                                </label>
                            </div>
                        </div>
                        {/* 項目名1 ~ 項目名3 */}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>項目名①
                                    <input type={'text'} className={gstyle.cs_input}
                                        id='input31' onKeyDown={(e) => onDataKeyDown(e, 32)}
                                        value={depositItemName1}
                                        onChange={handleChangeDepositItemName1} />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>項目名②
                                    <input type={'text'} className={gstyle.cs_input}
                                        id='input32' onKeyDown={(e) => onDataKeyDown(e, 33)}
                                        value={depositItemName2}
                                        onChange={handleChangeDepositItemName2} />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>項目名③
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={depositItemName3}
                                        id='input33' onKeyDown={(e) => onDataKeyDown(e, 34)}
                                        onChange={handleChangeDepositItemName3} />
                                </label>
                            </div>
                        </div>
                        {/* 項目名4 ~ 項目名6 */}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>項目名④
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={depositItemName4}
                                        id='input34' onKeyDown={(e) => onDataKeyDown(e, 35)}
                                        onChange={handleChangeDepositItemName4} />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>項目名⑤
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={depositItemName5}
                                        id='input35' onKeyDown={(e) => onDataKeyDown(e, 36)}
                                        onChange={handleChangeDepositItemName5} />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>項目名⑥
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={depositItemName6}
                                        id='input36' onKeyDown={(e) => onDataKeyDown(e, 37)}
                                        onChange={handleChangeDepositItemName6} />
                                </label>
                            </div>
                        </div>
                        {/* 項目名7 ~ 項目名9 */}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>項目名⑦
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={depositItemName7}
                                        id='input37' onKeyDown={(e) => onDataKeyDown(e, 38)}
                                        onChange={handleChangeDepositItemName7} />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>項目名⑧
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={depositItemName8}
                                        id='input38' onKeyDown={(e) => onDataKeyDown(e, 39)}
                                        onChange={handleChangeDepositItemName8} />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>項目名⑨
                                    <input type={'text'} className={gstyle.cs_input}
                                        value={depositItemName9}
                                        id='input39' onKeyDown={(e) => onDataKeyDown(e, 40)}
                                        onChange={handleChangeDepositItemName9} />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={gstyle.cs_card_1}>
                        {/*ロット*/}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={style.cs_blue_label}>【ロット設定】
                                </label>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginBottom: 15 }}>
                            <div>
                                <button type='button' className={gstyle.btn_new} style={{ marginTop: 10 }} onClick={rotUnitNew}>追加</button>
                            </div>
                            <div>
                                <button type='button' className={gstyle.btn_delete2} style={{ marginLeft: 30, marginTop: 10 }} onClick={rotUnitDel}>削除</button>
                            </div>
                        </div>
                        <div className={style_item_tbl.join(" ")}>
                            <div className={style.cs_thead}>
                                <div className={style.cs_tr}>
                                    <div className={style_th_col_ck.join(" ")}>
                                        <input type={"checkbox"} className={style.cs_tbl_check} id="allCheckBtn" onChange={allCheckData} />
                                    </div>
                                    <div className={style_th_col_2.join(" ")}>ロットの規格</div>
                                    <div className={style_th_col_2.join(" ")}>制限数</div>
                                </div>
                            </div>
                            <div className={style.cs_tbody} id="tbodyWrap">
                                {mainTableData.map((data, index) => (
                                    <div className={style.cs_tr} key={index} >
                                        <div className={style_td_col_ck.join(" ")}>
                                            <input type={"checkbox"} className={style.cs_tbl_check}
                                                id={'chk_' + index} />
                                            <input type="hidden" id={'id_' + index} value={data['id']} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type="text" id={"rot_unit_" + index} className={style.cs_tbl_input}
                                                onKeyDown={(e) => tableKeyDown(e, "limit_amount_" + index)}
                                                value={data['rot_unit']} onChange={(e) => onChangeRotUnit(e, index)} />
                                        </div>
                                        <div className={style_td_col_2.join(" ")}>
                                            <input type="text" id={"limit_amount_" + index} className={style.cs_tbl_input}
                                                onKeyDown={(e) => tableKeyDown(e, "rot_unit_" + (index + 1))}
                                                value={data['limit_amount']} onChange={(e) => onChangeLimitAmount(e, index)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={gstyle.cs_card_1}>
                        {/*【その他】 */}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={style.cs_blue_label}>【その他】
                                </label>
                            </div>
                        </div>
                        {/* 自社名印字, 伝票No管理 */}
                        <div className={gstyle.hyfiled} id={'freightageCodeWrap'}>
                            <div className={arr_style_6.join(" ")}>
                                <label className={gstyle.cs_label}>自社名印字
                                    <Select
                                        value={companyNamePrintFlag}
                                        open={type9Open}
                                        onSelect={(e) => onSelectData(e, 9)}
                                        placeholder="自社名印字を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeCompanyNamePrintFlag}
                                        onKeyDown={(e) => onDataKeyDown(e, 41)}
                                        onFocus={(e) => onFocusType9(e, 1)}
                                        onBlur={(e) => onFocusType9(e, 2)}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop: 14, width: '100%' }}
                                        id="input40"
                                        options={[
                                            { value: '0', label: '印字有り' },
                                            { value: '1', label: '印字無し' },
                                        ]}
                                    />
                                </label>
                            </div>
                            <div className={arr_style_6.join(" ")}>
                                <label className={gstyle.cs_label}>伝票No管理
                                    <Select
                                        value={orderNoManageType}
                                        open={type10Open}
                                        onSelect={(e) => onSelectData(e, 10)}
                                        placeholder="伝票No管理を選択します。"
                                        optionFilterProp="children"
                                        onChange={handleChangeOrderNoManageType}
                                        onKeyDown={(e) => onDataKeyDown(e, 42)}
                                        onFocus={(e) => onFocusType10(e, 1)}
                                        onBlur={(e) => onFocusType10(e, 2)}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ marginTop: 14, width: '100%' }}
                                        id="input41"
                                        options={[
                                            { value: '0', label: '日別' },
                                            { value: '1', label: '月別' },
                                            { value: '2', label: '連番' },
                                        ]}
                                    />
                                </label>
                            </div>
                        </div>
                        {/* 出庫, 入庫, 在庫移動, 諸掛 */}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>出庫
                                    <input type={'text'} className={gstyle.cs_input}
                                        id='input42' onKeyDown={(e) => onDataKeyDown(e, 43)}
                                    />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>入庫
                                    <input type={'text'} className={gstyle.cs_input}
                                        id='input43' onKeyDown={(e) => onDataKeyDown(e, 44)}
                                    />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>在庫移動
                                    <input type={'text'} className={gstyle.cs_input}
                                        id='input44' onKeyDown={(e) => onDataKeyDown(e, 45)}
                                    />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>諸掛
                                    <input type={'text'} className={gstyle.cs_input}
                                        id='input45' onKeyDown={(e) => onDataKeyDown(e, 46)}
                                    />
                                </label>
                            </div>
                        </div>
                        {/* 単位変更, パスワード */}
                        <div className={gstyle.hyfiled}>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>単位変更
                                    <input type={'text'} className={gstyle.cs_input}
                                        id='input46' onKeyDown={(e) => onDataKeyDown(e, 47)}
                                    />
                                </label>
                            </div>
                            <div className={arr_style_1.join(" ")}>
                                <label className={gstyle.cs_label}>パスワード
                                    <input type={'password'} className={gstyle.cs_input} value={password}
                                        id='input47' onKeyDown={(e) => onDataKeyDown(e, 48)}
                                        onChange={handleChangePassword} />
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*button 更新する*/}
                    <div className={style.buttonBox}>
                        <button type='button' className={style.button} id='registerBut' onClick={handleOpen}>更新する</button>
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle > <div style={{ fontSize: "1.1rem" }}>こちらの内容で登録しますか？</div> </DialogTitle>
                        <DialogActions>
                            <Button type='button' onClick={handleClose} className={gstyle.save_confirm_no}>いいえ</Button>
                            <Button type='button' onClick={saveData} className={gstyle.save_confirm_yes} autoFocus>はい</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </form>
        </div>
    )
}

export default MainSetting;