/* eslint-disable eqeqeq */
import  { React, useState, useRef } from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja'
import 'react-datepicker/dist/react-datepicker.css';
import "./InputCalenderTable.css";
registerLocale('ja', ja);

const InputDatepicker = props => {
    const [date, setDate] = useState(null);
    const handleChange = (param) => {
        props.onChange(param);
        setDate(param);
        calendarRef.current.setFocus(false);
    }
    const calendarRef = useRef(null);
    
    const onKeyDownDate = (e, id) =>{
        if(e.keyCode == 13 && id != '')
            document.getElementById(id).focus();
    }

    return (
        <>
                <DatePicker style={{width:100, zIndex:2}} className="tableDatePicker"  id={props.id}
                    onChange = {selectedDate => {handleChange(selectedDate || '')}}
                    locale="ja"
                    selected={props.value==''?'':new Date(props.value)}
                    dateFormat="yyyy-MM-dd" ref={calendarRef}
                    onKeyDown={(e)=>onKeyDownDate(e, props.focusId || '')}
                />
        </>
    )
}

export default InputDatepicker;
