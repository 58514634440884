//====================================================================================================
//	在庫移動処理 -> AdobeXD 23ページ
//	作成日:2022/08/25		最終更新日:2022/08/26
//	作成者:GFTD天神-徳渕	最終更新者:GFTD天神-徳渕
//====================================================================================================

import React from 'react';
// ---------- テーブルの見出し 下段 ----------
import style from './TableLabelBottom.module.css';
import ExportLocationLabel from '../13_export_location/label/Label';
import ImportLocationLabel from '../14_import_location/label/Label';
import LotLabel from '../15_lot/label/Label';
import CaseNumLabel from '../16_case_num/label/Label';
import SingleNumLabel from '../17_single_num/label/Label';
import VolumeLabel from '../18_volume/label/Label';
import MemoLabel from '../19_memo/label/Label';

const TableLabelBottom = ()=> {
    return (
        <thead>
            <tr className={ style.flex }>
                <ExportLocationLabel />
                <ImportLocationLabel />
                <LotLabel />
                <CaseNumLabel />
                <SingleNumLabel />
                <VolumeLabel />
                <MemoLabel />
            </tr>
        </thead>
    )
}

export default TableLabelBottom;